import ContentLayout from '../components/ContentLayout';
import RouterLink from '../components/RouterLink';
import routes from '../routes';

const NotFoundErrorPage = (): JSX.Element => (
  <ContentLayout>
    <h1>Page not found</h1>
    <p>The URL you have requested is not associated with a page.</p>
    <RouterLink to={routes.home}>Go to the homepage</RouterLink>
  </ContentLayout>
);

export default NotFoundErrorPage;

import {
  Backdrop, Checkbox, CircularProgress, FormControlLabel,
} from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { skipToken } from '@reduxjs/toolkit/query';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import DataGrid from '../../../components/DataGrid';
import ToolbarEditionFilter from '../../../components/DataGridToolbar/ToolbarEditionFilter';
import ExpandingSpace from '../../../components/ExpandingSpace';
import PageMetaTitle from '../../../components/PageMetaTitle';
import { uniqueItems } from '../../../helpers/arrays';
import { getPayloadValidationErrors, isApiPayloadValidationError } from '../../../helpers/errorTypes';
import keys from '../../../helpers/typedKeys';
import { useDefaultEditionForUser } from '../../../helpers/useDefaultEdition';
import useErrorHandler from '../../../helpers/useErrorHandler';
import { useUrlBooleanParam } from '../../../helpers/useUrlParam';
import { userHasRole } from '../../../helpers/user';
import { ActivityAllocationForPerson } from '../../../models/activityAllocationForPerson';
import { PersonStatus } from '../../../models/personStatus';
import {
  useGetActivityAllocationsQuery,
  useUpdateActivityAllocationsMutation,
} from '../../../state/protectedApi/activityAllocations';
import { useGetEditionsForCurrentUserQuery } from '../../../state/protectedApi/editions';
import { useGetCurrentUserQuery } from '../../../state/protectedApi/user';
import {
  useGetActivitiesQuery,
  useGetSessionsForEditionQuery,
} from '../../../state/publicApi/activities';
import EditLeaderRoleDialog from './EditLeaderRoleDialog';
import columns from './columns';

const ActivityAllocationPage = (): JSX.Element => {
  const [editAssignment, setEditAssignment] = useState<ActivityAllocationForPerson>();
  const [includeScores, setIncludeScores] = useUrlBooleanParam('scores', false);
  const [edition, setEditionById] = useDefaultEditionForUser();

  const {
    data: activities,
    error: activitiesError,
  } = useGetActivitiesQuery();
  useErrorHandler(activitiesError, 'API Error: Failed to load activities');

  const {
    currentData: sessions,
    error: sessionsError,
  } = useGetSessionsForEditionQuery(edition?.id ?? skipToken);
  useErrorHandler(sessionsError, 'API Error: Failed to load session times');

  const {
    data: people,
    isFetching,
    error: peopleError,
    refetch: refetchPeople,
  } = useGetActivityAllocationsQuery(edition ? {
    includeScores,
    editionId: edition.id,
  } : skipToken);
  useErrorHandler(peopleError, 'API Error: Failed to load activity allocations for people attending');

  const [updateAllocations] = useUpdateActivityAllocationsMutation();

  const { data: user } = useGetCurrentUserQuery();
  const { data: editions } = useGetEditionsForCurrentUserQuery();
  const allowEditing = userHasRole(user, ['Admin', 'Activities']);

  const processRowUpdate = useCallback(
    async (
      newRow: GridRowModel<ActivityAllocationForPerson>,
      oldRow: GridRowModel<ActivityAllocationForPerson>,
    ) => {
      // If nothing is different between the session dictionaries, don't call the protectedApi
      if (keys(newRow.sessions).every(
        (sessionId) => newRow.sessions[sessionId] === oldRow.sessions[sessionId]
        || (newRow.sessions[sessionId] === null
          && oldRow.sessions[sessionId] === undefined),
      )) {
        return newRow;
      }

      if (!edition) {
        throw new Error('Edition not set');
      }

      return updateAllocations({ editionId: edition.id, data: [newRow] }).then(
        (response) => {
          const updateSuccessful = 'data' in response;
          let message = `Update of ${newRow.personName}'s activities ${updateSuccessful ? 'succeeded' : 'failed'}`;
          if (!updateSuccessful && isApiPayloadValidationError(response.error)) {
            message += ` with api payload validation errors: \n - ${getPayloadValidationErrors(response.error).join('\n - ')}`;
          }
          enqueueSnackbar(
            message,
            {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              variant: updateSuccessful ? 'success' : 'error',
              style: { whiteSpace: 'pre-line' },
              persist: !updateSuccessful,
            },
          );
          if (!updateSuccessful) {
            throw new Error('Update failed');
          }
          return newRow;
        },
      );
    },
    [edition, updateAllocations],
  );

  if (!activities || !sessions || !people || !edition || !user) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  const statusOrder: PersonStatus[] = ['Cub', 'OtherChild', 'YoungLeader', 'Leader'];
  const sortedPeople = people.slice().sort((a, b) => {
    // Order by pack ID
    if (a.packId !== b.packId) {
      return a.packId - b.packId;
    }
    // Order by status
    if (a.status !== b.status) {
      return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
    }
    // Order by name
    return a.personName < b.personName ? -1 : 1;
  });
  const packReferences = uniqueItems(sortedPeople.map((person) => person.packReference));

  return (
    <>
      <PageMetaTitle title="Activity Allocation" />
      <DataGrid
        columns={columns(
          packReferences,
          statusOrder,
          sessions,
          activities,
          allowEditing,
          setEditAssignment,
          includeScores,
        )}
        loading={isFetching}
        rows={sortedPeople ?? []}
        getRowId={(person: ActivityAllocationForPerson) => person.personId}
        editMode="cell"
        processRowUpdate={processRowUpdate}
        disableRowSelectionOnClick
        slotProps={{
          toolbar: {
            refetch: refetchPeople,
            children: (
              <>
                <ExpandingSpace />
                <FormControlLabel
                  label="Include scores"
                  control={(
                    <Checkbox
                      checked={includeScores}
                      onChange={(event) => setIncludeScores(event.target.checked)}
                    />
                  )}
                />
                {editions && editions.length > 1 && (
                  <ToolbarEditionFilter
                    editionId={edition.id}
                    onEditionChange={async (newEditionId) => {
                      if (newEditionId) {
                        setEditionById(newEditionId);
                        await refetchPeople();
                      }
                    }}
                  />
                )}
              </>
            ),
          },
        }}
      />
      {allowEditing && editAssignment && edition && (
        <EditLeaderRoleDialog
          editionId={edition.id}
          assignment={editAssignment}
          activities={activities}
          sessions={sessions}
          onClose={() => setEditAssignment(undefined)}
        />
      )}
    </>
  );
};

export default ActivityAllocationPage;

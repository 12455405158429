import { useGetPacksQuery } from '../state/protectedApi/packs';

type PackReferenceFormatter = (packId: number) => string;

const maxReference = 999999;

export const getIdFromReference = (reference: string | null): number => {
  if (!reference) {
    return maxReference;
  }
  const match = reference.match(/\d+$/);
  return match ? parseInt(match[0], 10) : maxReference;
};

export const usePackReferenceFormatter = (): PackReferenceFormatter => {
  const { data: packs } = useGetPacksQuery();

  return (packId: number): string => {
    const pack = packs?.find((p) => p.id === packId);
    if (!pack) {
      return '';
    }
    return pack.reference;
  };
};

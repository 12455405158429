import { Stack } from '@mui/material';
import styled from 'styled-components';
import { responsiveSpacing } from '../../../helpers/responsiveSpacing';

export const SectionWrapper = styled.div`
  ${responsiveSpacing('padding-bottom', 'm')}
`;

export const StackWithBottomMargin = styled(Stack)`
  ${responsiveSpacing('margin-bottom', 'm')}
`;

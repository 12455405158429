import { OptionList } from '../helpers/options';

const registrationStatuses = [
  'Closed',
  'Open',
  'Complete',
] as const;

export type RegistrationStatus = (typeof registrationStatuses)[number];

export const isValidRegistrationStatus = (
  value: string,
): value is RegistrationStatus => registrationStatuses.includes(value as never);

export const registrationStatusOptions: OptionList<RegistrationStatus> = registrationStatuses.map(
  (status) => ({
    label: status,
    value: status,
  }),
);

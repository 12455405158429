import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NewRegistration } from './types';
import { selectRegistration, update } from './index';

const useRegistration = (): [
  NewRegistration,
  (newRegistration: Partial<NewRegistration>) => void,
] => {
  const registration = useSelector(selectRegistration);
  const dispatch = useDispatch();

  const updateRegistration = useCallback(
    (newRegistration: Partial<NewRegistration>): void => {
      dispatch(update(newRegistration));
    },
    [dispatch],
  );

  return [registration, updateRegistration];
};

export default useRegistration;

import { objectToCamel, objectToSnake } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { Contact, CreateContact } from '../../models/contact';
import { protectedApi } from './index';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getContactsForPerson: builder.query<Contact[], number>({
      query: (personId: number) => `contacts/list/${personId}`,
      transformResponse: (response: ObjectToSnake<Contact[]>) => objectToCamel(response),
      providesTags: (result) => (result
        ? result.map((contact) => ({ type: 'contacts', id: contact.id }))
        : ['contacts']),
    }),
    createContact: builder.mutation<Contact, CreateContact>({
      query: (args) => {
        const body = objectToSnake(args);
        return ({
          url: 'contacts/',
          method: 'POST',
          body,
        });
      },
      transformResponse: (response: ObjectToSnake<Contact>) => objectToCamel(response),
      invalidatesTags: (result) => (
        result ? [{ type: 'contacts', id: result?.id }] : []
      ),
    }),
    updateContact: builder.mutation<Contact, Contact>({
      query: (args) => {
        const { id, ...update } = args;
        const body = objectToSnake(update);
        return ({
          url: `contacts/${id}`,
          method: 'PATCH',
          body,
        });
      },
      transformResponse: (response: ObjectToSnake<Contact>) => objectToCamel(response),
      invalidatesTags: (result) => (
        result ? [{ type: 'contacts', id: result?.id }] : []
      ),
    }),
    deleteContact: builder.mutation<void, number>({
      query: (contactId) => ({
        url: `contacts/${contactId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, contactId) => (
        [{ type: 'contacts', id: contactId }]
      ),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetContactsForPersonQuery,
  useCreateContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
} = extendedApi;

import { Button } from '@mui/material';
import { ActionType, MutateActionType } from '../../../helpers/actionType';
import { AlertTitleWithoutMargin, AlertWithBottomMargin } from '../../Alert';

const getSuccessAlertTitle = (actionType: ActionType, modelName: string): string => {
  // TODO: make the model name title case
  switch (actionType) {
    case 'create':
      return `${modelName} successfully created`;
    case 'update':
      return `${modelName} successfully updated`;
    case 'delete':
      return `${modelName} successfully deleted`;
    default:
      return 'Success!';
  }
};

type SuccessAlertProps = {
  modelName: string
  type: MutateActionType
  onCreateClick?: () => void
};

const SuccessAlert = (props: SuccessAlertProps): JSX.Element => {
  const { modelName, type, onCreateClick } = props;

  return (
    <AlertWithBottomMargin
      severity="success"
      action={onCreateClick && (
        <Button color="inherit" onClick={onCreateClick} size="small">
          Create
        </Button>
      )}
    >
      <AlertTitleWithoutMargin>{getSuccessAlertTitle(type, modelName)}</AlertTitleWithoutMargin>
    </AlertWithBottomMargin>
  );
};

export default SuccessAlert;

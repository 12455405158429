import DashboardPieChart from '../../../../../components/DashboardPieChart';
import { getWideningAccessStatusLabel } from '../../../../../models/wideningAccessStatus';
import { useGetBallotStatisticsQuery } from '../../../../../state/protectedApi/statistics';
import colours from '../../../../../theme/colours';

const BallotByWideningAccess = (): JSX.Element => {
  const { data } = useGetBallotStatisticsQuery(undefined);

  return (
    <DashboardPieChart
      title="Widening Access"
      data={data?.wideningAccess.slice()
        .sort((a, b) => (b.status === 'DidNotApply' ? -1 : 0))
        .map((status) => ({
          name: getWideningAccessStatusLabel(status.status),
          count: status.packCount,
          colour: status.status === 'DidNotApply' ? colours.grey : undefined,
        })) ?? []}
    />
  );
};

export default BallotByWideningAccess;

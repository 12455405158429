import { CircularProgress } from '@mui/material';
import { FC } from 'react';
import DashboardCard from '../../../../../components/DashboardCard';
import DashboardCardLegend from '../../../../../components/DashboardCardLegend';
import DashboardCardTitle from '../../../../../components/DashboardCardTitle';
import { Statistics } from '../../../../../models/statistics';

type StaffRegisteredProps = {
  stats?: Statistics
};

const StaffRegistered: FC<StaffRegisteredProps> = (props) => {
  const { stats } = props;
  const title = stats && stats.registered.totalStaff;

  return (
    <DashboardCard>
      <DashboardCardTitle>
        {title || (
          <CircularProgress />
        )}
      </DashboardCardTitle>
      <DashboardCardLegend>Staff registered</DashboardCardLegend>
    </DashboardCard>
  );
};

export default StaffRegistered;

import * as Yup from 'yup';
import personRules from '../../../../../helpers/validation/person';

// TODO: validate that the selected person is not a child status type (this validation
//  should be server side too)
export const validationSchema = Yup.object().shape({
  personId: Yup.number()
    .typeError('You must specify a number')
    .min(1, 'Person ID must be greater than 0')
    .required('Person ID is required')
    .notOneOf([Yup.ref('relatedPersonId')], 'Person ID cannot be the same as Related Person ID'),
  relationship: personRules.relationship.required('Relationship is required'),
  emergency: Yup.mixed().required('Response is required'),
  updates: Yup.mixed().required('Response is required'),
  possiblyAttending: Yup.mixed().required('Response is required'),
});

import { FC } from 'react';
import {
  ComposableMap, Geographies, Geography, Marker,
} from 'react-simple-maps';
import { getHeightPx } from '../../../../../components/Dashboard/config';
import DashboardCard from '../../../../../components/DashboardCard';
import { asDefined } from '../../../../../helpers/typeValidation';
import { useGetAttendeeLocationsQuery } from '../../../../../state/protectedApi/people';
import { useGetEventConfigQuery } from '../../../../../state/publicApi';
import colours from '../../../../../theme/colours';

type RegistrationsMapProps = {
  editionId: number | undefined;
};

const RegistrationsMap: FC<RegistrationsMapProps> = (props) => {
  const { editionId } = props;

  const rows = 3;
  const padding = 20;
  const mapHeight = getHeightPx(rows) - padding;
  const mapWidth = getHeightPx(2) - padding;

  const { data: eventConfig } = useGetEventConfigQuery();
  const { data: people } = useGetAttendeeLocationsQuery({ editionId, attendeesOnly: true });

  // const excludingShetland = {
  //   rotate: [4.85, -57.1, 0] as [number, number, number],
  //   scale: 5000,
  // };

  const includingShetland = {
    rotate: [4.85, -57.8, 0] as [number, number, number],
    scale: 3850,
  };

  const filteredPeople: { id: number, longitude: number, latitude: number }[] = people?.slice()
    .filter((person) => person.latitude && person.longitude)
    .map((person) => ({
      id: person.id,
      longitude: asDefined(person.longitude),
      latitude: asDefined(person.latitude),
    })) ?? [];

  return (
    <DashboardCard columns={2} rows={rows}>
      <ComposableMap
        projection="geoAzimuthalEquidistant"
        projectionConfig={includingShetland}
        height={mapHeight}
        width={mapWidth}
      >
        <Geographies geography="/scotland.json">
          {({ geographies }) => geographies.map((geo: { rsmKey: string }) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill={colours.lightGreen}
              stroke={colours.grey}
            />
          ))}
        </Geographies>
        {filteredPeople.map((person) => (
          <Marker key={person.id} coordinates={[person.longitude, person.latitude]}>
            <circle r={2} fill={colours.blue} opacity={0.2} />
          </Marker>
        ))}
        {eventConfig && (
          <Marker coordinates={[eventConfig.longitude, eventConfig.latitude]}>
            <circle r={5} fill={colours.red} />
          </Marker>
        )}
      </ComposableMap>
    </DashboardCard>
  );
};

export default RegistrationsMap;

import { objectToCamel } from 'ts-case-convert';
import { ObjectToSnake, objectToSnake } from 'ts-case-convert/lib/caseConvert';
import { ActivityAllocation, RawActivityAllocation } from '../../models/activityAllocation';
import { ActivitySession } from '../../models/activitySession';
import { protectedApi } from './index';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getSession: builder.query<ActivitySession, number>({
      query: (id) => `activities/sessions/${id}`,
      transformResponse: (response: ObjectToSnake<ActivitySession>) => objectToCamel(response),
      providesTags: (result) => (result ? [{ type: 'sessions', id: result.id }] : ['sessions']),
    }),
    updateSession: builder.mutation<ActivitySession, ActivitySession>({
      query: ({ id, ...body }) => ({
        url: `activities/sessions/${id}`,
        method: 'PATCH',
        body: objectToSnake(body),
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'sessions', id }],
    }),
    getAllocationForSession: builder.query<ActivityAllocation[], number>({
      query: (sessionId) => `activities/allocations-per-session/${sessionId}`,
      transformResponse: (
        response: ObjectToSnake<RawActivityAllocation>[],
      ) => objectToCamel(response).map((allocation) => ({
        ...allocation,
        checkedInAt: allocation.checkedInAt ? new Date(allocation.checkedInAt) : null,
      })),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSessionQuery,
  useUpdateSessionMutation,
  useGetAllocationForSessionQuery,
} = extendedApi;

import {
  Backdrop, Button, CircularProgress, TextField,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import emailsClient from '../../../api/emails';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import { FormGrid } from '../../../components/FormGrid';
import IconLink from '../../../components/IconLink';
import { useFormikWithStateSync } from '../../../helpers/form';
import routes from '../../../routes';
import { selectBooking, update } from '../../../state/booking';
import sanitiseOnSubmit from './sanitise';
import { validationSchema } from './schema';

const BookingContactFormPage = (): JSX.Element => {
  const navigate = useNavigate();

  const [externalEmailError, setExternalEmailError] = useState<string | undefined>();
  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormikWithStateSync(
    selectBooking,
    update,
    {
      validationSchema,
      onSubmit: async ({ contact }) => {
        if (contact?.email) {
          await emailsClient.validate(contact.email)
            .then(async (fullResponse) => {
              const response = fullResponse
                .find((validation) => validation.input_email === contact.email);
              if (response?.valid) {
                await setFieldValue('contact.email', response.normalised_email ?? '');
                navigate(routes.bookings.address);
              } else {
                setExternalEmailError(response?.error);
              }
            })
            .catch(() => {
              console.error('API Error: Could not check if email is valid');
              navigate(routes.bookings.address);
            });
        }
      },
    },
    sanitiseOnSubmit,
  );

  return (
    <Card>
      <form onSubmit={handleSubmit} noValidate>
        <CardHeading>Your details</CardHeading>
        <FormGrid columns={3}>
          <TextField
            label="First name"
            value={values.contact.firstName}
            onBlur={() => setFieldTouched('contact.firstName')}
            onChange={async (event) => {
              await setFieldValue('contact.firstName', event.target.value);
            }}
            error={touched.contact?.firstName && Boolean(errors.contact?.firstName)}
            helperText={(touched.contact?.firstName && errors.contact?.firstName) || ' '}
          />
          <TextField
            label="Known as (optional)"
            value={values.contact.knownAs}
            onBlur={() => setFieldTouched('contact.knownAs')}
            onChange={async (event) => {
              await setFieldValue('contact.knownAs', event.target.value);
            }}
            error={touched.contact?.knownAs && Boolean(errors.contact?.knownAs)}
            helperText={(touched.contact?.knownAs && errors.contact?.knownAs) || ' '}
          />
          <TextField
            label="Last name"
            value={values.contact.lastName}
            onBlur={() => setFieldTouched('contact.lastName')}
            onChange={async (event) => {
              await setFieldValue('contact.lastName', event.target.value);
            }}
            error={touched.contact?.lastName && Boolean(errors.contact?.lastName)}
            helperText={(touched.contact?.lastName && errors.contact?.lastName) || ' '}
          />
        </FormGrid>
        <FormGrid columns={2}>
          <TextField
            label="Scouting role"
            value={values.contact.scoutingAppointment}
            onBlur={() => setFieldTouched('contact.scoutingAppointment')}
            onChange={async (event) => {
              await setFieldValue('contact.scoutingAppointment', event.target.value);
            }}
            error={touched.contact?.scoutingAppointment
              && Boolean(errors.contact?.scoutingAppointment)}
            helperText={(touched.contact?.scoutingAppointment && errors.contact?.scoutingAppointment) || ' '}
          />
          <TextField
            label="Membership number"
            value={values.contact.membershipNumber}
            onBlur={() => setFieldTouched('contact.membershipNumber')}
            onChange={async (event) => {
              await setFieldValue('contact.membershipNumber', event.target.value.trim());
            }}
            error={touched.contact?.membershipNumber
              && Boolean(errors.contact?.membershipNumber)}
            helperText={(touched.contact?.membershipNumber && errors.contact?.membershipNumber) || ' '}
            inputProps={{ pattern: '[0-9]*' }}
          />
          <TextField
            label="Mobile number"
            value={values.contact.mobile}
            onBlur={() => setFieldTouched('contact.mobile')}
            onChange={async (event) => {
              await setFieldValue('contact.mobile', event.target.value);
            }}
            error={touched.contact?.mobile && Boolean(errors.contact?.mobile)}
            helperText={(touched.contact?.mobile && errors.contact?.mobile) || ' '}
            inputProps={{ inputMode: 'tel' }}
          />
          <TextField
            label="Email address"
            value={values.contact.email}
            onBlur={() => setFieldTouched('contact.email')}
            onChange={async (event) => {
              await setFieldValue('contact.email', event.target.value.trim());
            }}
            error={Boolean(externalEmailError)
              || (touched.contact?.email && Boolean(errors.contact?.email))}
            helperText={externalEmailError || (touched.contact?.email && errors.contact?.email) || ' '}
            inputProps={{ inputMode: 'email' }}
          />
        </FormGrid>
        <CardFooter>
          <IconLink icon="Back" to={routes.bookings.group}>Back</IconLink>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Next
          </Button>
        </CardFooter>
      </form>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </Card>
  );
};

export default BookingContactFormPage;

import DashboardPieChart from '../../../../../components/DashboardPieChart';
import { getColourRange } from '../../../../../helpers/colourRange';
import { useGetBallotStatisticsQuery } from '../../../../../state/protectedApi/statistics';
import colours from '../../../../../theme/colours';

const lastYearsComparison: { decile: number, packCount: number }[] = [
  { decile: 1, packCount: 2 },
  { decile: 2, packCount: 5 },
  { decile: 3, packCount: 8 },
  { decile: 4, packCount: 10 },
  { decile: 5, packCount: 6 },
  { decile: 6, packCount: 7 },
  { decile: 7, packCount: 12 },
  { decile: 8, packCount: 16 },
  { decile: 9, packCount: 18 },
  { decile: 10, packCount: 12 },
];

const getDecileLabel = (decile: number | null, year: string): string => {
  if (decile === null) {
    return 'Unknown';
  }
  return `Decile ${decile} (${year})`;
};

const BallotBySimdDecile = (): JSX.Element => {
  const { data } = useGetBallotStatisticsQuery(undefined);

  const colourRange = getColourRange(colours.red, colours.green, 10);

  return (
    <DashboardPieChart
      title="SIMD Decile"
      halfCircle
      data={data?.simdDeciles.map((item) => ({
        name: getDecileLabel(item.decile, '2024'),
        count: item.packCount,
        colour: item.decile ? colourRange[item.decile] : colours.grey,
      })) ?? []}
      dataOuterCircle={lastYearsComparison.map((item) => ({
        name: getDecileLabel(item.decile, '2023'),
        count: item.packCount,
        colour: colourRange[item.decile],
      }))}
      showLegend={false}
    />
  );
};

export default BallotBySimdDecile;

import {
  FormControl, MenuItem, Select,
} from '@mui/material';
import { FC } from 'react';
import useErrorHandler from '../../../helpers/useErrorHandler';
import { useGetEditionsForCurrentUserQuery } from '../../../state/protectedApi/editions';

type ToolbarEditionFilterProps = {
  allowAll?: boolean;
  editionId: number | undefined;
  onEditionChange: (editionId: number | undefined) => void;
};

const ToolbarEditionFilter: FC<ToolbarEditionFilterProps> = (props) => {
  const { allowAll = false, editionId, onEditionChange } = props;

  const {
    data: editions,
    isLoading,
    error,
  } = useGetEditionsForCurrentUserQuery();
  useErrorHandler(error, 'API Error: Failed to load editions');

  if (!editions) {
    return null;
  }

  return (
    <FormControl sx={{ m: 0, minWidth: 120 }} size="small">
      <Select
        sx={{ m: 0 }}
        value={editionId ?? 'All'}
        onChange={(event) => {
          if (typeof event.target.value === 'number') {
            onEditionChange(event.target.value);
          } else if (event.target.value === 'All' && allowAll) {
            onEditionChange(undefined);
          }
        }}
        disabled={isLoading}
      >
        {allowAll && (
          <MenuItem value="All">
            All
          </MenuItem>
        )}
        {editions?.map((edition) => (
          <MenuItem key={edition.id} value={edition.id}>
            {edition.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ToolbarEditionFilter;

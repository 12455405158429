import * as Yup from 'yup';
import personRules from '../../../helpers/validation/person';
import { NewRegistration } from '../../../state/registration/types';

export const summaryValidate = (values: NewRegistration): {
  valid: true,
  error: undefined,
} | {
  valid: false,
  error: string,
} => {
  const bookerIsOffsiteContact = values.meta.registeringSelf === false
    && values.mainContact.emergency === true
    && values.mainContact.attending === false;

  const offsiteContactsCount = values.contacts
    .filter((contact) => contact.attending === false)
    .length;

  if (!bookerIsOffsiteContact && offsiteContactsCount === 0) {
    return {
      valid: false,
      error: 'You must provide at least one emergency contact that is not attending themselves.',
    };
  }

  return { valid: true, error: undefined };
};

const baseContactShape = {
  firstName: personRules.firstName.required('First name is required'),
  lastName: personRules.lastName.required('Last name is required'),
  relationship: personRules.relationship.required('Relationship is required'),
  email: personRules.email,
  mobile: personRules.mobile.required('Mobile number is required'),
  attending: Yup.mixed().required('Response is required'),
};

// TODO: validate that multiple contacts are not the same as the booking contact - mobile is unique
export const validationSchema = Yup.object().shape({
  mainContact: Yup.object().when('meta.registeringSelf', {
    is: false,
    then: Yup.object().shape({
      emergency: Yup.mixed().required('Response is required'),
      attending: Yup.mixed().required('Response is required'),
    }),
  }),
  contacts: Yup.array().when('meta.registeringSelf', {
    is: true,
    then: Yup.array().of(
      Yup.object().shape(baseContactShape),
    ),
    otherwise: Yup.array().of(
      Yup.object().shape({
        ...baseContactShape,
        updates: Yup.mixed().when('email', {
          is: (email: string) => Boolean(email),
          then: Yup.mixed().required('Response is required'),
        }),
      }),
    ),
  }),
});

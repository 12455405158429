import { Chip, TableBody } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ContentLayout from '../../../components/ContentLayout';
import DataPanel, { DataPanelDisplayProps } from '../../../components/DataPanel';
import LoadingText from '../../../components/LoadingText';
import PageMetaTitle from '../../../components/PageMetaTitle';
import RouterLink from '../../../components/RouterLink';
import Table from '../../../components/Table';
import TableKeyValueRow from '../../../components/TableKeyValueRow';
import { formatTimeRange } from '../../../helpers/datesStrings';
import { userHasRole } from '../../../helpers/user';
import { Edition } from '../../../models/edition';
import { Meal } from '../../../models/meal';
import routes from '../../../routes';
import {
  useCreateMealMutation,
  useDeleteMealMutation,
  useUpdateMealMutation,
} from '../../../state/protectedApi/meals';
import { useGetCurrentUserQuery } from '../../../state/protectedApi/user';
import { publicApi } from '../../../state/publicApi';
import { useGetEditionQuery } from '../../../state/publicApi/editions';
import { useGetMealQuery } from '../../../state/publicApi/meals';
import MealForm from './mealForm';
import { validationSchema } from './schema';

const DisplayMeal = (props: DataPanelDisplayProps<Meal>): JSX.Element => {
  const { data: meal } = props;
  const { data: edition } = useGetEditionQuery(meal.editionId ?? skipToken);

  return (
    <Table size="small" sx={{ marginBottom: '30px' }}>
      <TableBody>
        <TableKeyValueRow name="ID">{meal.id}</TableKeyValueRow>
        <TableKeyValueRow name="Time">{formatTimeRange(meal.start, meal.end)}</TableKeyValueRow>
        <TableKeyValueRow name="Edition">{edition?.name}</TableKeyValueRow>
        <TableKeyValueRow name="Location">{meal.location}</TableKeyValueRow>
        <TableKeyValueRow name="Check in status">
          <RouterLink to={routes.meals.checkIn(meal.id)}>{meal.status}</RouterLink>
        </TableKeyValueRow>
        <TableKeyValueRow name="Bottle">{meal.bottle ? 'Yes' : 'No'}</TableKeyValueRow>
        <TableKeyValueRow name="Bowl">{meal.bowl ? 'Yes' : 'No'}</TableKeyValueRow>
        <TableKeyValueRow name="Plate">{meal.plate ? 'Yes' : 'No'}</TableKeyValueRow>
        <TableKeyValueRow name="Fork">{meal.fork ? 'Yes' : 'No'}</TableKeyValueRow>
        <TableKeyValueRow name="Knife">{meal.knife ? 'Yes' : 'No'}</TableKeyValueRow>
        <TableKeyValueRow name="Spoon">{meal.spoon ? 'Yes' : 'No'}</TableKeyValueRow>
        <TableKeyValueRow name="Description">{meal.description}</TableKeyValueRow>
        <TableKeyValueRow name="Review Tags">
          {meal.reviewTags.length === 0 && 'None'}
          {meal.reviewTags.map((tag) => (
            <Chip key={tag.text} label={tag.text} />
          ))}
        </TableKeyValueRow>
      </TableBody>
    </Table>
  );
};

const getTitle = (meal: Meal | undefined, edition: Edition | undefined): string => {
  if (meal === undefined) {
    return 'Create Meal';
  }
  return edition ? `${edition.name}: ${meal.name}` : meal.name;
};

const MealAdminPage = (): JSX.Element => {
  const { mealId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: user } = useGetCurrentUserQuery();

  const fetch = useGetMealQuery(Number(mealId), { skip: mealId === 'new' });
  const [create] = useCreateMealMutation();
  const [update] = useUpdateMealMutation();
  const [remove] = useDeleteMealMutation();

  const { data: meal } = fetch;
  const { data: edition } = useGetEditionQuery(meal?.editionId ?? skipToken);

  const title = getTitle(meal, edition);
  const canCreate = userHasRole(user, ['Catering', 'Admin']);
  const canUpdate = userHasRole(user, ['Catering', 'Admin']);
  const canDelete = userHasRole(user, 'SuperUser');

  const onSuccessfulMutation = (): void => {
    // TODO: this is not working as expected when returning back to grid page post edit
    dispatch({
      type: `${publicApi.reducerPath}/invalidateTags`,
      payload: ['meals'],
    });
  };

  const onSuccessfulCreation = (newMeal: Meal): void => {
    onSuccessfulMutation();
    navigate(routes.meals.adminDetail(newMeal.id), { replace: true });
  };

  const onSuccessfulDeletion = (): void => {
    onSuccessfulMutation();
    navigate(routes.meals.grid, { replace: true });
  };

  return (
    <ContentLayout>
      <PageMetaTitle title={title} />
      <DataPanel
        title={title}
        titleTag="h1"
        modelName="Meal"
        fetch={fetch}
        prepareInitialValues={({ order, publicId, ...data }) => ({
          ...data,
          name: data.name ?? '',
          location: data.location ?? '',
          status: data.status ?? undefined,
          reviewTags: data.reviewTags ?? [],
        })}
        formikConfig={{
          validationSchema,
        }}
        initialMode={mealId === 'new' ? 'form' : 'display'}
        canCreate={canCreate}
        create={{
          mutation: create,
          initialValues: {
            name: '',
            editionId: null,
            location: '',
            start: null,
            end: null,
            status: 'Closed',
            bottle: false,
            fork: false,
            knife: false,
            spoon: false,
            bowl: false,
            plate: false,
            description: '',
            reviewTags: [],
          },
          onSuccess: onSuccessfulCreation,
        }}
        canUpdate={canUpdate}
        update={{
          mutation: (newValues) => update({ ...newValues, id: Number(mealId) }),
        }}
        canDelete={canDelete}
        deletion={{
          mutation: (a) => remove(a.id),
          dialogTitle: 'Delete Meal',
          dialogDescription: 'Are you sure you want to delete this meal?',
          onSuccess: onSuccessfulDeletion,
        }}
        LoadingComponent={<LoadingText lines={12} />}
        DisplayComponent={DisplayMeal}
        FormComponent={MealForm}
      />
    </ContentLayout>
  );
};

export default MealAdminPage;

import styled from 'styled-components';

export const TooltipContainer = styled.div`
  background-color: white;
  color: black;
  padding: 10px;
  text-align: left;
  border: 1px #ccc solid;
`;

export const TooltipTitle = styled.div`
  color: black;
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 15px;
`;

import { User } from '../models/user';
import { UserRole } from '../models/userRole';
import { coerceToList } from './arrays';

export const userHasRole = (
  user: User | undefined,
  roles: UserRole | UserRole[],
  includeSuperUser?: boolean,
): boolean => {
  if (!user) {
    return false;
  }

  const rolesList = coerceToList(roles);
  if (user.role && rolesList.includes(user.role)) {
    return true;
  }
  return includeSuperUser !== false && Boolean(user.role) && user.role === 'SuperUser';
};

import { Link } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { getRegistrationLink } from '../../../helpers/pack';
import { Pack } from '../../../models/pack';

export const renderRegistrationStatusLink = (
  params: GridRenderCellParams<Pack, string | null>,
): JSX.Element | null => (params.value ? (
  <Link href={getRegistrationLink(params.row)} target="_blank" rel="noreferrer">
    {params.row.registrationStatus}
  </Link>
) : null);

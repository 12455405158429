import {
  Backdrop, Button, CircularProgress, TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import { FormGrid } from '../../../components/FormGrid';
import IconLink from '../../../components/IconLink';
import { useFormikWithStateSync } from '../../../helpers/form';
import routes from '../../../routes';
import { selectBooking, update } from '../../../state/booking';
import { useGetEventConfigQuery } from '../../../state/publicApi';
import { getValidationSchema } from './schema';

const BookingEstimatesFormPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { data: eventConfig } = useGetEventConfigQuery();

  const validationSchema = getValidationSchema(eventConfig);

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormikWithStateSync(
    selectBooking,
    update,
    {
      validationSchema,
      onSubmit: () => {
        navigate(routes.bookings.travel);
      },
    },
  );

  return (
    <Card>
      <form onSubmit={handleSubmit} noValidate>
        <CardHeading>Estimated numbers attending</CardHeading>
        <FormGrid columns={2}>
          <TextField
            label="Total cubs"
            value={values.pack.cubs}
            onBlur={() => setFieldTouched('pack.cubs')}
            onChange={async (event) => {
              const newValue = Number(event.target.value);
              if (!Number.isNaN(newValue)) {
                await setFieldValue('pack.cubs', newValue);
              }
            }}
            error={touched.pack?.cubs && Boolean(errors.pack?.cubs)}
            helperText={(touched.pack?.cubs && errors.pack?.cubs) || ' '}
            inputProps={{ pattern: '[0-9]*' }}
          />
          <TextField
            label="Total adults"
            value={values.pack.leaders}
            onBlur={() => setFieldTouched('pack.leaders')}
            onChange={async (event) => {
              const newValue = Number(event.target.value);
              if (!Number.isNaN(newValue)) {
                await setFieldValue('pack.leaders', newValue);
              }
            }}
            error={touched.pack?.leaders && Boolean(errors.pack?.leaders)}
            helperText={(touched.pack?.leaders && errors.pack?.leaders) || ' '}
            inputProps={{ pattern: '[0-9]*' }}
          />
          <TextField
            label="Total young leaders"
            value={values.pack.youngLeaders}
            onBlur={() => setFieldTouched('pack.youngLeaders')}
            onChange={async (event) => {
              const newValue = Number(event.target.value);
              if (!Number.isNaN(newValue)) {
                await setFieldValue('pack.youngLeaders', newValue);
              }
            }}
            error={touched.pack?.youngLeaders && Boolean(errors.pack?.youngLeaders)}
            helperText={(touched.pack?.youngLeaders && errors.pack?.youngLeaders) || ' '}
            inputProps={{ pattern: '[0-9]*' }}
          />
          <TextField
            label="Total other children"
            value={values.pack.otherChildren}
            onBlur={() => setFieldTouched('pack.otherChildren')}
            onChange={async (event) => {
              const newValue = Number(event.target.value);
              if (!Number.isNaN(newValue)) {
                await setFieldValue('pack.otherChildren', newValue);
              }
            }}
            error={touched.pack?.otherChildren && Boolean(errors.pack?.otherChildren)}
            helperText={(touched.pack?.otherChildren && errors.pack?.otherChildren) || ' '}
            inputProps={{ pattern: '[0-9]*' }}
          />
        </FormGrid>
        <CardFooter>
          <IconLink icon="Back" to={routes.bookings.address}>Back</IconLink>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Next
          </Button>
        </CardFooter>
      </form>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </Card>
  );
};

export default BookingEstimatesFormPage;

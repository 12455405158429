import { TableBody } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ContentLayout from '../../../components/ContentLayout';
import DataPanel, { DataPanelDisplayProps } from '../../../components/DataPanel';
import LoadingText from '../../../components/LoadingText';
import PageMetaTitle from '../../../components/PageMetaTitle';
import Register from '../../../components/Register';
import Table from '../../../components/Table';
import TableKeyValueRow from '../../../components/TableKeyValueRow';
import { formatTimeRange } from '../../../helpers/datesStrings';
import { userHasRole } from '../../../helpers/user';
import { Duty } from '../../../models/duty';
import { Edition } from '../../../models/edition';
import { UpdateRegisterPayload } from '../../../models/register';
import routes from '../../../routes';
import {
  useAddPersonToDutyMutation,
  useCreateDutyMutation,
  useDeleteDutyMutation,
  useDeletePersonFromDutyMutation,
  useGetDutyQuery,
  useGetDutyRegisterQuery,
  useUpdateDutyMutation,
  useUpdateDutyRegisterMutation,
} from '../../../state/protectedApi/duties';
import { useGetCurrentUserQuery } from '../../../state/protectedApi/user';
import { publicApi } from '../../../state/publicApi';
import { useGetEditionQuery } from '../../../state/publicApi/editions';
import DutyForm from './dutyForm';
import { validationSchema } from './schema';

const DisplayDuty: FC<DataPanelDisplayProps<Duty>> = (props) => {
  const { data: duty } = props;
  const { data: edition } = useGetEditionQuery(duty.editionId ?? skipToken);

  return (
    <Table size="small" sx={{ marginBottom: '30px' }}>
      <TableBody>
        <TableKeyValueRow name="ID">{duty.id}</TableKeyValueRow>
        <TableKeyValueRow name="Time">{formatTimeRange(duty.start, duty.end)}</TableKeyValueRow>
        <TableKeyValueRow name="Edition">{edition?.name}</TableKeyValueRow>
        <TableKeyValueRow name="Target Adults">{duty.targetAdultLeaders}</TableKeyValueRow>
        <TableKeyValueRow name="Target Leaders">{duty.targetLeaders}</TableKeyValueRow>
        <TableKeyValueRow name="Optional">{duty.isTargetOptional ? 'Yes' : 'No'}</TableKeyValueRow>
        <TableKeyValueRow name="YLs Only">{duty.youngLeaderOnly ? 'Yes' : 'No'}</TableKeyValueRow>
        <TableKeyValueRow name="Adults Only">{duty.adultsOnly ? 'Yes' : 'No'}</TableKeyValueRow>
        <TableKeyValueRow name="Only Once">{duty.onlyOnce ? 'Yes' : 'No'}</TableKeyValueRow>
        <TableKeyValueRow name="Catering">{duty.isCatering ? 'Yes' : 'No'}</TableKeyValueRow>
      </TableBody>
    </Table>
  );
};

const getTitle = (duty: Duty | undefined, edition: Edition | undefined): string => {
  if (duty === undefined) {
    return 'Create Duty';
  }
  return edition ? `${edition.name}: ${duty.name}` : duty.name;
};

const DutyDetailsPage = (): JSX.Element => {
  const { dutyId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: user } = useGetCurrentUserQuery();

  const fetch = useGetDutyQuery(Number(dutyId), { skip: dutyId === 'new' });
  const [create] = useCreateDutyMutation();
  const [update] = useUpdateDutyMutation();
  const [remove] = useDeleteDutyMutation();

  const { data: duty } = fetch;
  const { data: edition } = useGetEditionQuery(duty?.editionId ?? skipToken);

  const title = getTitle(duty, edition);
  const canCreate = userHasRole(user, 'Admin');
  const canUpdate = userHasRole(user, 'Admin');
  const canDelete = userHasRole(user, 'SuperUser');

  const onSuccessfulMutation = (): void => {
    // TODO: this is not working as expected when returning back to grid page post edit
    dispatch({
      type: `${publicApi.reducerPath}/invalidateTags`,
      payload: ['duties'],
    });
  };

  const onSuccessfulCreation = (newDuty: Duty): void => {
    onSuccessfulMutation();
    navigate(routes.duties.detail(newDuty.id), { replace: true });
  };

  const onSuccessfulDeletion = (): void => {
    onSuccessfulMutation();
    navigate(routes.duties.grid, { replace: true });
  };

  const fetchRegister = useGetDutyRegisterQuery(Number(dutyId), { skip: dutyId === 'new' });
  const [updateRegister] = useUpdateDutyRegisterMutation();
  const [addPersonToDuty] = useAddPersonToDutyMutation();
  const [deletePersonFromDuty] = useDeletePersonFromDutyMutation();

  return (
    <ContentLayout>
      <PageMetaTitle title={title} />
      <DataPanel
        title={title}
        titleTag="h1"
        modelName="Duty"
        fetch={fetch}
        prepareInitialValues={({
          order, publicId, allocationSummary, ...data
        }) => ({
          ...data,
          name: data.name ?? '',
        })}
        formikConfig={{
          validationSchema,
        }}
        initialMode={dutyId === 'new' ? 'form' : 'display'}
        canCreate={canCreate}
        create={{
          mutation: create,
          initialValues: {
            name: '',
            editionId: null,
            start: null,
            end: null,
            targetLeaders: 0,
            targetAdultLeaders: 0,
            isTargetOptional: false,
            youngLeaderOnly: false,
            adultsOnly: false,
            onlyOnce: false,
            isCatering: false,
          },
          onSuccess: onSuccessfulCreation,
        }}
        canUpdate={canUpdate}
        update={{
          mutation: (newValues) => update({ ...newValues, id: Number(dutyId) }),
        }}
        canDelete={canDelete}
        deletion={{
          mutation: (a) => remove(a.id),
          dialogTitle: 'Delete Duty',
          dialogDescription: 'Are you sure you want to delete this duty?',
          onSuccess: onSuccessfulDeletion,
        }}
        LoadingComponent={<LoadingText lines={12} />}
        DisplayComponent={DisplayDuty}
        FormComponent={DutyForm}
      />
      {dutyId !== 'new' && (
        <>
          <h2>Register</h2>
          <Register
            editionId={duty?.editionId ?? undefined}
            fetch={fetchRegister}
            updateCheckIn={(
              formValue: UpdateRegisterPayload,
            ) => updateRegister({ dutyId: Number(dutyId), ...formValue })}
            addPerson={(personId) => addPersonToDuty({
              dutyId: Number(dutyId),
              personId,
            })}
            removePerson={(personId) => deletePersonFromDuty({
              dutyId: Number(dutyId),
              personId,
            })}
          />
        </>
      )}
    </ContentLayout>
  );
};

export default DutyDetailsPage;

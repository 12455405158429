import { objectToCamel } from 'ts-case-convert';
import { ObjectToSnake, objectToSnake } from 'ts-case-convert/lib/caseConvert';
import { Activity } from '../../models/activity';
import { ActivitySession } from '../../models/activitySession';
import { RegisterItem, UpdateActivityRegisterPayload } from '../../models/register';
import { publicApi } from './index';

const extendedApi = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    getActivities: builder.query<Activity[], void>({
      query: () => 'activities/',
      transformResponse: (response: ObjectToSnake<Activity[]>) => objectToCamel(response),
      providesTags: (activities) => (activities && activities.length
        ? activities.map((activity) => ({ type: 'activities', id: activity.id }))
        : ['activities']
      ),
    }),
    getActivity: builder.query<Activity, number>({
      query: (id: number) => `activities/${id}`,
      transformResponse: (response: ObjectToSnake<Activity>) => objectToCamel(response),
      providesTags: (activity) => (activity ? [{ type: 'activities', id: activity.id }] : ['activities']),
    }),
    getActivityRegister: builder.query<RegisterItem[], {
      activityId: number, sessionId: number,
    }>({
      query: (payload) => `activities/register/${payload.activityId}/${payload.sessionId}`,
      transformResponse: (
        response: ObjectToSnake<RegisterItem[]>,
      ) => objectToCamel(response),
      providesTags: (result, error, { activityId, sessionId }) => (result
        ? [{ type: 'activityRegister', id: `${activityId}-${sessionId}` }]
        : ['activityRegister']),
    }),
    updateActivityRegister: builder.mutation<void, UpdateActivityRegisterPayload>({
      query: ({ activityId, sessionId, ...body }) => ({
        url: `activities/register/${activityId}/${sessionId}`,
        method: 'PUT',
        body: objectToSnake(body),
      }),
      invalidatesTags: (result, error, { activityId, sessionId }) => [{ type: 'activityRegister', id: `${activityId}-${sessionId}` }],
    }),
    // TODO: filter sessions by editionId
    getSessions: builder.query<ActivitySession[], void>({
      query: () => 'activities/sessions/',
      transformResponse: (response: ObjectToSnake<ActivitySession[]>) => objectToCamel(response),
    }),
    getSessionsForPack: builder.query<ActivitySession[], number>({
      query: (packId) => `activities/sessions/pack/${packId}`,
      transformResponse: (response: ObjectToSnake<ActivitySession[]>) => objectToCamel(response),
    }),
    getSessionsForEdition: builder.query<ActivitySession[], number>({
      query: (editionId) => `activities/sessions/edition/${editionId}`,
      transformResponse: (response: ObjectToSnake<ActivitySession[]>) => objectToCamel(response),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetActivitiesQuery,
  useGetActivityQuery,
  useGetActivityRegisterQuery,
  useUpdateActivityRegisterMutation,
  useGetSessionsQuery,
  useGetSessionsForPackQuery,
  useGetSessionsForEditionQuery,
} = extendedApi;

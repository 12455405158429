import { Theme, createTheme, useMediaQuery } from '@mui/material';
import { useMemo } from 'react';
import colours from './colours';

// Let StyledComponents know the real type for our theme to enable strict type checking.
declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

export const useTheme = (): Theme => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  return useMemo(
    () => createTheme({
      components: {
        MuiTextField: {
          defaultProps: {
            fullWidth: true,
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'unset',
            },
          },
        },
      },
      palette: {
        mode: prefersDarkMode ? 'dark' : 'light',
        primary: {
          main: colours.blue,
        },
        secondary: {
          main: colours.purple,
        },
        error: {
          main: colours.red,
        },
        warning: {
          main: colours.orange,
        },
        info: {
          main: colours.teal,
        },
        success: {
          main: colours.green,
        },
      },
      typography: {
        fontFamily: 'Nunito Sans',
      },
      shape: {
        borderRadius: 5,
      },
    }),
    [prefersDarkMode],
  );
};

import apiService from '../apiService';

export type EmailsValidationResponse = {
  input_email: string
  valid: boolean
  error?: string
  normalised_email?: string
}[];

type EmailsClient = {
  validate: (email: string | string[]) => Promise<EmailsValidationResponse>;
};

const emailsClient: EmailsClient = {
  validate: async (emails: string | string[]) => {
    const emailsList = Array.isArray(emails) ? emails : [emails];
    return apiService.post('email/validate', { emails: emailsList });
  },
};

export default emailsClient;

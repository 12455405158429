import { OptionList } from '../../../../helpers/options';
import { Subcamp } from '../../../../models/subcamp';
import { WideningAccessStatus } from '../../../../models/wideningAccessStatus';

export const subcampBulkOptions: OptionList<Subcamp | 'Unset' | 'No change'> = [
  {
    label: 'No change',
    value: 'No change',
  },
  {
    label: 'Unset existing',
    value: 'Unset',
  },
  {
    label: 'Blue',
    value: 'Blue',
  },
  {
    label: 'Green',
    value: 'Green',
  },
  {
    label: 'Red',
    value: 'Red',
  },
  {
    label: 'Yellow',
    value: 'Yellow',
  },
];

export const wideningAccessBulkOptions: OptionList<WideningAccessStatus | 'No change'> = [
  {
    label: 'No change',
    value: 'No change',
  },
  {
    label: 'Did not apply',
    value: 'DidNotApply',
  },
  {
    label: 'Pending',
    value: 'Pending',
  },
  {
    label: 'Not eligible',
    value: 'NotEligible',
  },
  {
    label: 'Offered',
    value: 'Offered',
  },
  {
    label: 'Unsuccessful',
    value: 'Unsuccessful',
  },
  {
    label: 'Accepted',
    value: 'Accepted',
  },
];

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { objectToCamel } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { EventConfig } from '../../models/eventConfig';

const baseUrl = process.env.REACT_APP_API_BASE_URL ?? '';

export const publicApi = createApi({
  reducerPath: 'publicApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/api/`,
  }),
  tagTypes: ['activities', 'activityRegister', 'meals'],
  endpoints: (builder) => ({
    getEventConfig: builder.query<EventConfig, void>({
      query: () => 'event-config/',
      transformResponse: (response: ObjectToSnake<EventConfig>) => objectToCamel(response),
    }),
  }),
});

export const {
  useGetEventConfigQuery,
} = publicApi;

import * as Yup from 'yup';
import { Region } from '../../../models/region';

export const validationSchema = Yup.object().shape({
  meta: Yup.object().shape({
    region: Yup.mixed().required('Region is required'),
    district: Yup.mixed().when('region', {
      is: (region: Region | null) => region !== null,
      then: Yup.mixed().required('District is required'),
    }),
  }),
  pack: Yup.object().shape({
    groupName: Yup.string().required('Group name is required'),
    packName: Yup.string(),
    addressStreet: Yup.string().required('Meeting place street address is required'),
    addressTown: Yup.string().required('Meeting place town is required'),
    addressPostcode: Yup.string().required('Meeting place postcode is required'),
  }),
});

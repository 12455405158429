import { skipToken } from '@reduxjs/toolkit/query';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import ContentLayout from '../../../components/ContentLayout';
import DataPanel from '../../../components/DataPanel';
import LoadingText from '../../../components/LoadingText';
import PageMetaTitle from '../../../components/PageMetaTitle';
import { ActivitySession } from '../../../models/activitySession';
import { useGetSessionQuery, useUpdateSessionMutation } from '../../../state/protectedApi/activitySessions';
import { useGetEditionQuery } from '../../../state/publicApi/editions';
import SessionRegister from './SessionRegister';
import DisplaySession from './displaySession';
import SessionForm from './sessionForm';

const SessionDetailsPage: FC = () => {
  const { sessionId } = useParams();
  const sessionIdNumber = Number(sessionId);
  if (Number.isNaN(sessionIdNumber)) {
    throw new Error('Invalid session ID');
  }

  const fetchSession = useGetSessionQuery(sessionIdNumber);
  const [updateSession] = useUpdateSessionMutation();
  const { data: edition } = useGetEditionQuery(fetchSession.data?.editionId ?? skipToken);

  const formatTitle = (session?: ActivitySession): string => {
    if (!session) {
      return 'Loading...';
    }
    const name = `Session ${session.order}`;
    return edition ? `${edition.name}: ${name}` : name;
  };

  return (
    <ContentLayout>
      <PageMetaTitle title={formatTitle(fetchSession.data)} />
      <DataPanel
        title={formatTitle}
        modelName="session"
        fetch={fetchSession}
        update={{
          mutation: updateSession,
        }}
        LoadingComponent={<LoadingText lines={7} />}
        DisplayComponent={DisplaySession}
        FormComponent={SessionForm}
      />
      <SessionRegister sessionId={sessionIdNumber} />
    </ContentLayout>
  );
};

export default SessionDetailsPage;

import {
  Autocomplete,
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { skipToken } from '@reduxjs/toolkit/query';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import emailsClient from '../../../api/emails';
import BlocksRenderer from '../../../components/BlocksRenderer';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import CardIntroduction from '../../../components/CardIntroduction';
import { FormGrid } from '../../../components/FormGrid';
import IconLink from '../../../components/IconLink';
import { useRegistrationsContent } from '../../../helpers/content';
import { getEditionSummary } from '../../../helpers/edition';
import { useFormikWithStateSync } from '../../../helpers/form';
import useErrorHandler from '../../../helpers/useErrorHandler';
import { genderOptions, isValidGender } from '../../../models/gender';
import { isValidAttendeeStatus, nonAdultStatusOptions } from '../../../models/personStatus';
import { isValidStaffTeam, staffTeamOptions } from '../../../models/staffTeam';
import routes from '../../../routes';
import { useRegistrationCodeOrError } from '../../../routes/registrationsRoutes';
import { useGetEventConfigQuery } from '../../../state/publicApi';
import { useGetEditionsForRegistrationCodeQuery } from '../../../state/publicApi/editions';
import { useGetDistrictsByRegionQuery, useGetRegionsQuery } from '../../../state/publicApi/reference';
import { useCheckRegistrationCodeQuery } from '../../../state/publicApi/registration';
import { selectRegistration, update } from '../../../state/registration';
import sanitiseOnSubmit from './sanitise';
import { getValidationSchema } from './schema';

const RegistrationAttendeeFormPage = (): JSX.Element => {
  const { content } = useRegistrationsContent();
  const registrationCode = useRegistrationCodeOrError();
  const navigate = useNavigate();
  const [externalEmailError, setExternalEmailError] = useState<string | undefined>();
  const [cubActivityWarningOpen, setCubActivityWarningOpen] = useState(false);

  const { data: eventConfig } = useGetEventConfigQuery();
  const {
    currentData: editions,
  } = useGetEditionsForRegistrationCodeQuery(registrationCode ?? skipToken);
  const { currentData: codeCheck } = useCheckRegistrationCodeQuery(registrationCode ?? skipToken);

  const validationSchema = getValidationSchema(editions);

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormikWithStateSync(
    selectRegistration,
    update,
    {
      validationSchema,
      onSubmit: async ({ attendee }) => {
        if (attendee?.status === 'YoungLeader' && attendee?.email) {
          await emailsClient.validate(attendee.email)
            .then(async (fullResponse) => {
              const response = fullResponse
                .find((validation) => validation.input_email === attendee.email);
              if (response?.valid) {
                await setFieldValue('attendee.email', response.normalised_email ?? '');
                navigate(routes.registrations.media(registrationCode));
              } else {
                setExternalEmailError(response?.error);
              }
            })
            .catch(() => {
              console.error('API Error: Could not check if email is valid');
              navigate(routes.registrations.media(registrationCode));
            });
        } else {
          navigate(routes.registrations.media(registrationCode));
        }
      },
    },
    sanitiseOnSubmit,
  );

  const {
    data: regions,
    isLoading: regionsLoading,
    error: regionsError,
  } = useGetRegionsQuery();
  useErrorHandler(regionsError, 'API Error: Failed to fetch regions');

  const {
    currentData: districts,
    isLoading: districtsLoading,
    error: districtsError,
  } = useGetDistrictsByRegionQuery(values.meta.region?.id ?? skipToken);
  useErrorHandler(districtsError, 'API Error: Failed to fetch districts');

  if (!content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  const contentVersion = values.meta.registeringSelf
    ? content.attendee.self : content.attendee.other;

  const allowOtherChildren = !!codeCheck && codeCheck.valid && codeCheck.allowOtherChildren;
  const allowYoungLeaders = !!codeCheck && codeCheck.valid && codeCheck.allowYoungLeaders;
  const helpMessage = (!allowOtherChildren || !allowYoungLeaders)
    ? 'Some options are unavailable as zero places were booked for the status.' : ' ';

  return (
    <Card>
      <CardHeading>{contentVersion.title}</CardHeading>
      <CardIntroduction>
        <BlocksRenderer content={contentVersion.body} />
      </CardIntroduction>
      <form onSubmit={handleSubmit} noValidate>
        <FormGrid columns={2}>
          {values.meta.type === 'staff' && (
            <FormControl error={touched.attendee?.staffTeam && Boolean(errors.attendee?.staffTeam)}>
              <FormLabel id="staff-team-label">Team</FormLabel>
              <RadioGroup
                aria-labelledby="staff-team-label"
                value={values.attendee.staffTeam}
                onChange={async (event) => {
                  if (isValidStaffTeam(event.target.value)) {
                    await setFieldValue('attendee.staffTeam', event.target.value);
                  }
                }}
                onBlur={() => setFieldTouched('attendee.staffTeam')}
              >
                {staffTeamOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
              <FormHelperText>
                {(touched.attendee?.staffTeam && errors.attendee?.staffTeam) || ' '}
              </FormHelperText>
            </FormControl>
          )}
          {!values.meta.registeringSelf && (
            <FormControl error={touched.attendee?.status && Boolean(errors.attendee?.status)}>
              <FormLabel id="status-label">Status</FormLabel>
              <RadioGroup
                aria-labelledby="status-label"
                value={values.attendee.status}
                onChange={async (event) => {
                  if (isValidAttendeeStatus(event.target.value)) {
                    await setFieldValue('attendee.status', event.target.value);
                    setCubActivityWarningOpen(event.target.value === 'Cub');
                  }
                }}
                onBlur={() => setFieldTouched('attendee.status')}
              >
                {nonAdultStatusOptions.filter((option) => values.meta.type === 'pack'
                  || option.value !== 'Cub').map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                      disabled={(option.value === 'YoungLeader' && !allowYoungLeaders)
                        || (option.value === 'OtherChild' && !allowOtherChildren)}
                    />
                ))}
              </RadioGroup>
              <FormHelperText>
                {(touched.attendee?.status && errors.attendee?.status) || helpMessage}
              </FormHelperText>
            </FormControl>
          )}
        </FormGrid>
        {editions && editions.length > 1 && eventConfig && (
          <FormGrid>
            <FormControl
              error={touched.attendee?.editions && !!errors.attendee?.editions}
              component="fieldset"
            >
              <FormLabel component="legend">Which weekends are you attending?</FormLabel>
              <FormGroup>
                {editions.map((edition) => (
                  <FormControlLabel
                    key={edition.id}
                    control={(
                      <Checkbox
                        checked={values.attendee?.editions.includes(edition.id)}
                        onChange={async (event) => {
                          const newValue = event.target.checked;
                          if (newValue && values.attendee.editions) {
                            await setFieldValue('attendee.editions', [...values.attendee.editions, edition.id]);
                          } else {
                            await setFieldValue('attendee.editions', values.attendee?.editions.filter((id) => id !== edition.id));
                          }
                        }}
                        onBlur={() => setFieldTouched('attendee.editions')}
                        name={edition.name}
                      />
                    )}
                    label={getEditionSummary(eventConfig, edition)}
                  />
                ))}
              </FormGroup>
              <FormHelperText>{(touched.attendee?.editions && errors.attendee?.editions) || ' '}</FormHelperText>
            </FormControl>
          </FormGrid>
        )}
        {(!values.meta.registeringSelf || values.meta.type === 'staff') && (
          <h2>Personal details</h2>
        )}
        {!values.meta.registeringSelf && (
          <FormGrid columns={3}>
            <TextField
              label="First name"
              value={values.attendee.firstName}
              onBlur={() => setFieldTouched('attendee.firstName')}
              onChange={async (event) => {
                await setFieldValue('attendee.firstName', event.target.value);
              }}
              error={touched.attendee?.firstName && Boolean(errors.attendee?.firstName)}
              helperText={(touched.attendee?.firstName && errors.attendee?.firstName) || ' '}
            />
            <TextField
              label="Known as (optional)"
              value={values.attendee.knownAs}
              onBlur={() => setFieldTouched('attendee.knownAs')}
              onChange={async (event) => {
                await setFieldValue('attendee.knownAs', event.target.value);
              }}
              error={touched.attendee?.knownAs && Boolean(errors.attendee?.knownAs)}
              helperText={(touched.attendee?.knownAs && errors.attendee?.knownAs) || ' '}
            />
            <TextField
              label="Last name"
              value={values.attendee.lastName}
              onBlur={() => setFieldTouched('attendee.lastName')}
              onChange={async (event) => {
                await setFieldValue('attendee.lastName', event.target.value);
              }}
              error={touched.attendee?.lastName && Boolean(errors.attendee?.lastName)}
              helperText={(touched.attendee?.lastName && errors.attendee?.lastName) || ' '}
            />
          </FormGrid>
        )}
        <FormGrid columns={2}>
          <TextField
            select
            label="Gender"
            value={values.attendee.gender}
            onChange={async (event) => {
              if (isValidGender(event.target.value)) {
                await setFieldValue('attendee.gender', event.target.value);
              }
            }}
            onBlur={() => setFieldTouched('attendee.gender')}
            helperText={(touched.attendee?.gender && errors.attendee?.gender) || ' '}
            error={touched.attendee?.gender && Boolean(errors.attendee?.gender)}
          >
            {genderOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <DatePicker
            label="Date of birth"
            value={values.attendee.dateOfBirth ? moment(values.attendee.dateOfBirth) : null}
            onChange={async (newValue) => {
              await setFieldValue(
                'attendee.dateOfBirth',
                newValue?.format('YYYY-MM-DD') ?? null,
              );
            }}
            slotProps={{
              textField: {
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onBlur: () => setFieldTouched('attendee.dateOfBirth'),
                error: touched.attendee?.dateOfBirth && Boolean(errors.attendee?.dateOfBirth),
                helperText: (touched.attendee?.dateOfBirth && errors.attendee?.dateOfBirth) || ' ',
              },
            }}
          />
        </FormGrid>
        {(values.meta.registeringSelf || values.meta.type === 'staff') && (
          <h2>Scouting</h2>
        )}
        {values.meta.registeringSelf && (
          <FormGrid columns={2}>
            <TextField
              label="Scouting role"
              value={values.attendee.scoutingAppointment}
              onBlur={() => setFieldTouched('attendee.scoutingAppointment')}
              onChange={async (event) => {
                await setFieldValue('attendee.scoutingAppointment', event.target.value);
              }}
              error={touched.attendee?.scoutingAppointment
                && Boolean(errors.attendee?.scoutingAppointment)}
              helperText={(touched.attendee?.scoutingAppointment
                && errors.attendee?.scoutingAppointment) || ' '}
            />
            <TextField
              label="Membership number"
              value={values.attendee.membershipNumber}
              onBlur={() => setFieldTouched('attendee.membershipNumber')}
              onChange={async (event) => {
                await setFieldValue('attendee.membershipNumber', event.target.value.trim());
              }}
              error={touched.attendee?.membershipNumber
                && Boolean(errors.attendee?.membershipNumber)}
              helperText={(touched.attendee?.membershipNumber
                && errors.attendee?.membershipNumber) || ' '}
              inputProps={{ pattern: '[0-9]*' }}
            />
          </FormGrid>
        )}
        {values.meta.type === 'staff' && (
          <FormGrid columns={2}>
            <Autocomplete
              options={regions ?? []}
              loading={regionsLoading}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Region"
                  error={touched.meta?.region && Boolean(errors.meta?.region)}
                  helperText={(touched.meta?.region && errors.meta?.region) || ' '}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {regionsLoading ? <CircularProgress size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              value={values.meta.region}
              onChange={async (event, value) => {
                await setFieldValue('meta.district', null);
                await setFieldValue('meta.region', value);
              }}
              onBlur={() => setFieldTouched('meta.region')}
            />
            <Autocomplete
              options={districts ?? []}
              loading={districtsLoading}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="District"
                  helperText={(!values.meta.region && 'Select a region first') || ' '}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {districtsLoading ? <CircularProgress size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              value={values.meta.district}
              onChange={(event, value) => setFieldValue('meta.district', value)}
              disabled={values.meta?.region === null}
            />
            <TextField
              label="Group or explorer unit name"
              value={values.attendee.groupName}
              onBlur={() => setFieldTouched('attendee.groupName')}
              onChange={async (event) => {
                await setFieldValue('attendee.groupName', event.target.value);
              }}
              error={touched.attendee?.groupName && Boolean(errors.attendee?.groupName)}
              helperText={(touched.attendee?.groupName && errors.attendee?.groupName) || ' '}
            />
          </FormGrid>
        )}
        <Collapse in={values.attendee.status === 'YoungLeader'}>
          <h2>Contact details</h2>
          <FormGrid columns={2}>
            <TextField
              label="Mobile number"
              value={values.attendee.mobile}
              onBlur={() => setFieldTouched('attendee.mobile')}
              onChange={async (event) => {
                await setFieldValue('attendee.mobile', event.target.value);
              }}
              error={touched.attendee?.mobile && Boolean(errors.attendee?.mobile)}
              helperText={(touched.attendee?.mobile && errors.attendee?.mobile) || ' '}
              inputProps={{ inputMode: 'tel' }}
            />
            <TextField
              label="Email address"
              value={values.attendee.email}
              onBlur={() => setFieldTouched('attendee.email')}
              onChange={async (event) => {
                await setFieldValue('attendee.email', event.target.value.trim());
              }}
              error={Boolean(externalEmailError)
                || (touched.attendee?.email && Boolean(errors.attendee?.email))}
              helperText={externalEmailError || (touched.attendee?.email && errors.attendee?.email) || ' '}
              inputProps={{ inputMode: 'email' }}
            />
          </FormGrid>
        </Collapse>
        <h2>Postal address</h2>
        <TextField
          label="Street address"
          value={values.attendee.addressStreet}
          onBlur={() => setFieldTouched('attendee.addressStreet')}
          onChange={async (event) => {
            await setFieldValue('attendee.addressStreet', event.target.value);
          }}
          error={touched.attendee?.addressStreet && Boolean(errors.attendee?.addressStreet)}
          helperText={(touched.attendee?.addressStreet
            && errors.attendee?.addressStreet) || ' '}
          multiline
          minRows={3}
        />
        <FormGrid columns={2}>
          <TextField
            label="Town"
            value={values.attendee.addressTown}
            onBlur={() => setFieldTouched('attendee.addressTown')}
            onChange={async (event) => {
              await setFieldValue('attendee.addressTown', event.target.value);
            }}
            error={touched.attendee?.addressTown && Boolean(errors.attendee?.addressTown)}
            helperText={(touched.attendee?.addressTown && errors.attendee?.addressTown) || ' '}
          />
          <TextField
            label="Postcode"
            value={values.attendee.addressPostcode}
            onBlur={() => setFieldTouched('attendee.addressPostcode')}
            onChange={async (event) => {
              await setFieldValue('attendee.addressPostcode', event.target.value);
            }}
            error={touched.attendee?.addressPostcode
              && Boolean(errors.attendee?.addressPostcode)}
            helperText={(touched.attendee?.addressPostcode
              && errors.attendee?.addressPostcode) || ' '}
          />
        </FormGrid>
        <CardFooter>
          <IconLink icon="Back" to={routes.registrations.contact(registrationCode)}>Back</IconLink>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Next
          </Button>
        </CardFooter>
      </form>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
      <Dialog
        open={cubActivityWarningOpen}
        onClose={() => setCubActivityWarningOpen(false)}
        aria-labelledby="cub-activity-alert-title"
        aria-describedby="cub-activity-alert-description"
      >
        <DialogTitle id="cub-activity-alert-title">
          Are you currently with your Cub?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="cub-activity-alert-description">
            <p>
              Later in this registration form your Cub will rank all of the activities in their
              order of preference. This ranking will be used to select the activities they are
              allocated for the weekend.
            </p>
            <p>
              <Link href={routes.documents.activity.preferences} target="_blank">
                Download the activities list
              </Link>
            </p>
            <p>
              Please do not proceed with the registration form until you are with your young person.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            variant="outlined"
            onClick={() => setCubActivityWarningOpen(false)}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default RegistrationAttendeeFormPage;

import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment/moment';
import { FC } from 'react';
import CardFooter from '../../../../../components/CardFooter';
import { FormGrid } from '../../../../../components/FormGrid';
import ReviewTagsSelector from '../../../../../components/ReviewTagsSelector';
import { FormProps } from '../../../../../components/forms/types';
import { genderOptions, isValidGender } from '../../../../../models/gender';
import { Registration } from '../../../../../models/registration';
import { getReviewStatusLabel, isValidReviewStatus, reviewStatuses } from '../../../../../models/reviewStatus';

const RegistrationForm: FC<FormProps<Registration>> = (props) => {
  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = props;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <FormGrid columns={2}>
        <TextField
          select
          label="Gender"
          value={values.gender}
          onChange={async (event) => {
            if (isValidGender(event.target.value)) {
              await setFieldValue('gender', event.target.value);
            }
          }}
          onBlur={() => setFieldTouched('gender')}
          helperText={(touched.gender && errors.gender) || ' '}
          error={touched.gender && Boolean(errors.gender)}
          disabled={isSubmitting}
        >
          {genderOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <DatePicker
          label="Date of birth"
          value={values.dateOfBirth ? moment(values.dateOfBirth) : null}
          onChange={async (newValue) => {
            await setFieldValue(
              'dateOfBirth',
              newValue?.format('YYYY-MM-DD') ?? '',
            );
          }}
          disabled={isSubmitting}
          slotProps={{
            textField: {
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onBlur: () => setFieldTouched('dateOfBirth'),
              error: touched.dateOfBirth && Boolean(errors.dateOfBirth),
              helperText: (touched.dateOfBirth && errors.dateOfBirth) || ' ',
            },
          }}
        />
      </FormGrid>
      <FormGrid columns={2}>
        <FormControl
          error={touched.mediaConsent && Boolean(errors.mediaConsent)}
          disabled={isSubmitting}
        >
          <FormLabel id="media-label">
            Media consent
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="media-label"
            value={values.mediaConsent}
            onChange={async (event) => {
              await setFieldValue('mediaConsent', event.target.value === 'true');
            }}
            onBlur={() => setFieldTouched('mediaConsent')}
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
          <FormHelperText>
            {(touched.mediaConsent && errors.mediaConsent) || ' '}
          </FormHelperText>
        </FormControl>
      </FormGrid>
      <FormGrid columns={2}>
        <FormControl
          error={touched.antihistamineConsent && Boolean(errors.antihistamineConsent)}
          disabled={isSubmitting}
        >
          <FormLabel id="antihistamine-consent-label">
            Anti-histamine consent
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="antihistamine-consent-label"
            value={values.antihistamineConsent}
            onChange={async (event) => {
              await setFieldValue('antihistamineConsent', event.target.value === 'true');
            }}
            onBlur={() => setFieldTouched('antihistamineConsent')}
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
          <FormHelperText>
            {(touched.antihistamineConsent && errors.antihistamineConsent) || ' '}
          </FormHelperText>
        </FormControl>
        <FormControl
          error={touched.painkillerConsent && Boolean(errors.painkillerConsent)}
          disabled={isSubmitting}
        >
          <FormLabel id="painkiller-consent-label">
            Paracetamol consent
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="painkiller-consent-label"
            value={values.painkillerConsent}
            onChange={async (event) => {
              await setFieldValue('painkillerConsent', event.target.value === 'true');
            }}
            onBlur={() => setFieldTouched('painkillerConsent')}
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
          <FormHelperText>
            {(touched.painkillerConsent && errors.painkillerConsent) || ' '}
          </FormHelperText>
        </FormControl>
        <FormControl
          error={touched.suncreamConsent && Boolean(errors.suncreamConsent)}
          disabled={isSubmitting}
        >
          <FormLabel id="suncream-consent-label">
            Suncream consent
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="suncream-consent-label"
            value={values.suncreamConsent}
            onChange={async (event) => {
              await setFieldValue('suncreamConsent', event.target.value === 'true');
            }}
            onBlur={() => setFieldTouched('suncreamConsent')}
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
          <FormHelperText>
            {(touched.suncreamConsent && errors.suncreamConsent) || ' '}
          </FormHelperText>
        </FormControl>
        <FormControl
          error={touched.afterSunConsent && Boolean(errors.afterSunConsent)}
          disabled={isSubmitting}
        >
          <FormLabel id="after-sun-consent-label">
            After sun consent
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="after-sun-consent-label"
            value={values.afterSunConsent}
            onChange={async (event) => {
              await setFieldValue('afterSunConsent', event.target.value === 'true');
            }}
            onBlur={() => setFieldTouched('afterSunConsent')}
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
          <FormHelperText>
            {(touched.afterSunConsent && errors.afterSunConsent) || ' '}
          </FormHelperText>
        </FormControl>
      </FormGrid>
      <FormGrid>
        <TextField
          label="Additional needs"
          value={values.additionalNeeds}
          onChange={async (event) => {
            await setFieldValue('additionalNeeds', event.target.value);
          }}
          onBlur={() => setFieldTouched('additionalNeeds')}
          helperText={(touched.additionalNeeds && errors.additionalNeeds) || ' '}
          error={touched.additionalNeeds && Boolean(errors.additionalNeeds)}
          disabled={isSubmitting}
          multiline
          minRows={3}
        />
        <TextField
          label="Medications"
          value={values.medications}
          onChange={async (event) => {
            await setFieldValue('medications', event.target.value);
          }}
          onBlur={() => setFieldTouched('medications')}
          helperText={(touched.medications && errors.medications) || ' '}
          error={touched.medications && Boolean(errors.medications)}
          disabled={isSubmitting}
          multiline
          minRows={3}
        />
        <TextField
          label="Allergies"
          value={values.allergies}
          onChange={async (event) => {
            await setFieldValue('allergies', event.target.value);
          }}
          onBlur={() => setFieldTouched('allergies')}
          helperText={(touched.allergies && errors.allergies) || ' '}
          error={touched.allergies && Boolean(errors.allergies)}
          disabled={isSubmitting}
          multiline
          minRows={3}
        />
        <TextField
          label="Dietary requirements"
          value={values.dietaryRequirements}
          onChange={async (event) => {
            await setFieldValue('dietaryRequirements', event.target.value);
          }}
          onBlur={() => setFieldTouched('dietaryRequirements')}
          helperText={(touched.dietaryRequirements && errors.dietaryRequirements) || ' '}
          error={touched.dietaryRequirements && Boolean(errors.dietaryRequirements)}
          disabled={isSubmitting}
          multiline
          minRows={3}
        />
        <TextField
          label="Message"
          value={values.message}
          onChange={async (event) => {
            await setFieldValue('message', event.target.value);
          }}
          onBlur={() => setFieldTouched('message')}
          helperText={(touched.message && errors.message) || ' '}
          error={touched.message && Boolean(errors.message)}
          disabled={isSubmitting}
          multiline
          minRows={3}
        />
        <TextField
          label="Internal Notes"
          value={values.internalNotes}
          onChange={async (event) => {
            await setFieldValue('internalNotes', event.target.value);
          }}
          onBlur={() => setFieldTouched('internalNotes')}
          helperText={(touched.internalNotes && errors.internalNotes) || ' '}
          error={touched.internalNotes && Boolean(errors.internalNotes)}
          disabled={isSubmitting}
          multiline
          minRows={3}
        />
      </FormGrid>
      <FormGrid columns={2}>
        <TextField
          label="Sandwich preference"
          value={values.sandwichPreference}
          onChange={async (event) => {
            await setFieldValue('sandwichPreference', event.target.value);
          }}
          onBlur={() => setFieldTouched('sandwichPreference')}
          helperText={(touched.sandwichPreference && errors.sandwichPreference) || ' '}
          error={touched.sandwichPreference && Boolean(errors.sandwichPreference)}
          disabled={isSubmitting}
        />
      </FormGrid>
      <FormGrid>
        <TextField
          label="Catering message"
          value={values.cateringMessage}
          onChange={async (event) => {
            await setFieldValue('cateringMessage', event.target.value);
          }}
          onBlur={() => setFieldTouched('cateringMessage')}
          helperText={(touched.cateringMessage && errors.cateringMessage) || ' '}
          error={touched.cateringMessage && Boolean(errors.cateringMessage)}
          disabled={isSubmitting}
          multiline
          minRows={3}
        />
        <TextField
          label="Leader activities message"
          value={values.activitiesMessage}
          onChange={async (event) => {
            await setFieldValue('activitiesMessage', event.target.value);
          }}
          onBlur={() => setFieldTouched('activitiesMessage')}
          helperText={(touched.activitiesMessage && errors.activitiesMessage) || ' '}
          error={touched.activitiesMessage && Boolean(errors.activitiesMessage)}
          disabled={isSubmitting}
          multiline
          minRows={3}
        />
      </FormGrid>

      {values.adminReview && (
        <>
          <h4>Core team review</h4>
          <FormGrid columns={2}>
            <TextField
              select
              label="Review status"
              value={values.adminReview.status}
              onChange={async (event) => {
                if (isValidReviewStatus(event.target.value)) {
                  await setFieldValue('adminReview.status', event.target.value);
                }
              }}
              onBlur={() => setFieldTouched('adminReview.status')}
              helperText={(touched.adminReview?.status && errors.adminReview?.status) || ' '}
              error={touched.adminReview?.status && Boolean(errors.adminReview?.status)}
              disabled={isSubmitting}
            >
              {reviewStatuses.map((option) => (
                <MenuItem key={option} value={option}>
                  {getReviewStatusLabel(option)}
                </MenuItem>
              ))}
            </TextField>
            <ReviewTagsSelector
              tags={values.adminReview.tags ?? []}
              onChange={(update) => setFieldValue('adminReview.tags', update)}
              type="Admin"
              disabled={isSubmitting}
            />
          </FormGrid>
        </>
      )}

      {values.compassReview && (
        <>
          <h4>Compass review</h4>
          <FormGrid columns={2}>
            <TextField
              select
              label="Review status"
              value={values.compassReview.status}
              onChange={async (event) => {
                if (isValidReviewStatus(event.target.value)) {
                  await setFieldValue('compassReview.status', event.target.value);
                }
              }}
              onBlur={() => setFieldTouched('compassReview.status')}
              helperText={(touched.compassReview?.status && errors.compassReview?.status) || ' '}
              error={touched.compassReview?.status && Boolean(errors.compassReview?.status)}
              disabled={isSubmitting}
            >
              {reviewStatuses.map((option) => (
                <MenuItem key={option} value={option}>
                  {getReviewStatusLabel(option)}
                </MenuItem>
              ))}
            </TextField>
            <ReviewTagsSelector
              tags={values.compassReview.tags ?? []}
              onChange={(update) => setFieldValue('compassReview.tags', update)}
              type="Compass"
              disabled={isSubmitting}
            />
          </FormGrid>
        </>
      )}

      {values.cateringReview && (
        <>
          <h4>Catering review</h4>
          <FormGrid columns={2}>
            <TextField
              select
              label="Review status"
              value={values.cateringReview.status}
              onChange={async (event) => {
                if (isValidReviewStatus(event.target.value)) {
                  await setFieldValue('cateringReview.status', event.target.value);
                }
              }}
              onBlur={() => setFieldTouched('cateringReview.status')}
              helperText={(touched.cateringReview?.status && errors.cateringReview?.status) || ' '}
              error={touched.cateringReview?.status && Boolean(errors.cateringReview?.status)}
              disabled={isSubmitting}
            >
              {reviewStatuses.map((option) => (
                <MenuItem key={option} value={option}>
                  {getReviewStatusLabel(option)}
                </MenuItem>
              ))}
            </TextField>
            <ReviewTagsSelector
              tags={values.cateringReview.tags ?? []}
              onChange={(update) => setFieldValue('cateringReview.tags', update)}
              type="Catering"
              disabled={isSubmitting}
            />
          </FormGrid>
        </>
      )}

      {values.activitiesReview && (
        <>
          <h4>Activities review</h4>
          <FormGrid columns={2}>
            <TextField
              select
              label="Review status"
              value={values.activitiesReview.status}
              onChange={async (event) => {
                if (isValidReviewStatus(event.target.value)) {
                  await setFieldValue('activitiesReview.status', event.target.value);
                }
              }}
              onBlur={() => setFieldTouched('activitiesReview.status')}
              helperText={(touched.activitiesReview?.status && errors.activitiesReview?.status) || ' '}
              error={touched.activitiesReview?.status && Boolean(errors.activitiesReview?.status)}
              disabled={isSubmitting}
            >
              {reviewStatuses.map((option) => (
                <MenuItem key={option} value={option}>
                  {getReviewStatusLabel(option)}
                </MenuItem>
              ))}
            </TextField>
            <ReviewTagsSelector
              tags={values.activitiesReview.tags ?? []}
              onChange={(update) => setFieldValue('activitiesReview.tags', update)}
              type="Activities"
              disabled={isSubmitting}
            />
          </FormGrid>
        </>
      )}

      {values.subcampReview && (
        <>
          <h4>Subcamp review</h4>
          <FormGrid columns={2}>
            <TextField
              select
              label="Review status"
              value={values.subcampReview.status}
              onChange={async (event) => {
                if (isValidReviewStatus(event.target.value)) {
                  await setFieldValue('subcampReview.status', event.target.value);
                }
              }}
              onBlur={() => setFieldTouched('subcampReview.status')}
              helperText={(touched.subcampReview?.status && errors.subcampReview?.status) || ' '}
              error={touched.subcampReview?.status && Boolean(errors.subcampReview?.status)}
              disabled={isSubmitting}
            >
              {reviewStatuses.map((option) => (
                <MenuItem key={option} value={option}>
                  {getReviewStatusLabel(option)}
                </MenuItem>
              ))}
            </TextField>
            <ReviewTagsSelector
              tags={values.subcampReview.tags ?? []}
              onChange={(update) => setFieldValue('subcampReview.tags', update)}
              type="Subcamp"
              disabled={isSubmitting}
            />
          </FormGrid>
        </>
      )}

      {values.packLeaderReview && (
        <>
          <h4>Pack leader review</h4>
          <FormGrid columns={2}>
            <TextField
              select
              label="Review status"
              value={values.packLeaderReview.status}
              onChange={async (event) => {
                if (isValidReviewStatus(event.target.value)) {
                  await setFieldValue('packLeaderReview.status', event.target.value);
                }
              }}
              onBlur={() => setFieldTouched('packLeaderReview.status')}
              helperText={(touched.packLeaderReview?.status && errors.packLeaderReview?.status) || ' '}
              error={touched.packLeaderReview?.status && Boolean(errors.packLeaderReview?.status)}
              disabled={isSubmitting}
            >
              {reviewStatuses.map((option) => (
                <MenuItem key={option} value={option}>
                  {getReviewStatusLabel(option)}
                </MenuItem>
              ))}
            </TextField>
            <ReviewTagsSelector
              tags={values.packLeaderReview.tags ?? []}
              onChange={(update) => setFieldValue('packLeaderReview.tags', update)}
              type="PackLeader"
              disabled={isSubmitting}
            />
          </FormGrid>
        </>
      )}

      <CardFooter>
        <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </CardFooter>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </form>
  );
};

export default RegistrationForm;

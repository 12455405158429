import { TableBody, TableRow } from '@mui/material';
import { FC } from 'react';
import RouterLink from '../../../../../components/RouterLink';
import Table from '../../../../../components/Table';
import TableCell from '../../../../../components/TableCell';
import { isLeader } from '../../../../../helpers/person';
import { Activity } from '../../../../../models/activity';
import { getActivityLeaderRoleLabel } from '../../../../../models/activityLeaderRole';
import { ActivityScheduleItem } from '../../../../../models/activitySchedule';
import { ActivitySession } from '../../../../../models/activitySession';
import { Person } from '../../../../../models/person';
import routes from '../../../../../routes';

type ScheduleTableProps = {
  person: Person
  activities: Activity[]
  sessions: ActivitySession[]
  schedule: ActivityScheduleItem[]
};

const ScheduleTable: FC<ScheduleTableProps> = (props) => {
  const {
    person,
    activities,
    sessions,
    schedule,
  } = props;

  // TODO: add checked in time column
  return (
    <Table size="small" sx={{ marginBottom: '30px' }}>
      <TableBody>
        {schedule.map((item) => (
          <TableRow key={item.sessionId}>
            <TableCell>{sessions.find((s) => s.id === item.sessionId)?.order}</TableCell>
            <TableCell>
              {activities.find((a) => a.id === item.activityId) && item.activityId ? (
                <RouterLink to={routes.activities.adminDetail(item.activityId, item.sessionId)}>
                  {activities.find((a) => a.id === item.activityId)?.name}
                </RouterLink>
              ) : ''}
            </TableCell>
            {isLeader(person) && (
              <TableCell>
                {(item.leaderRole && getActivityLeaderRoleLabel(item.leaderRole))}
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ScheduleTable;

export type MenuPageLink = {
  label: string
  route: string
};

type MenuDivider = {
  id: number
  type: 'divider'
};

export type MenuItem = MenuPageLink | MenuDivider;

export const isDivider = (menuItem: MenuItem): menuItem is MenuDivider => 'type' in menuItem;

export const isPageLink = (menuItem: MenuItem): menuItem is MenuPageLink => !isDivider(menuItem);

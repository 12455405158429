import {
  Backdrop, Button, CircularProgress,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BlocksRenderer from '../../../components/BlocksRenderer';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import CardIntroduction from '../../../components/CardIntroduction';
import { useRegistrationsContent } from '../../../helpers/content';
import useErrorHandler from '../../../helpers/useErrorHandler';
import routes from '../../../routes';
import { useRegistrationCodeOrError } from '../../../routes/registrationsRoutes';
import { useGetEventConfigQuery } from '../../../state/publicApi';
import { useGetActivitiesQuery } from '../../../state/publicApi/activities';
import { reset } from '../../../state/registration';
import { isPackRegistration } from '../../../state/registration/types';
import useRegistration from '../../../state/registration/useRegistration';

const RegistrationStartPage = (): JSX.Element => {
  const { content } = useRegistrationsContent();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [registration] = useRegistration();
  const registrationCode = useRegistrationCodeOrError();

  const { data: eventConfig, error: eventConfigError } = useGetEventConfigQuery();
  useErrorHandler(eventConfigError, 'API Error: failed to load the event configuration');
  const { data: activities, error: activitiesError } = useGetActivitiesQuery();
  useErrorHandler(activitiesError, 'API Error: failed to load the activities');

  if (!eventConfig || !activities || !registration || !content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  const startRegistration = (): void => {
    dispatch(reset(activities));
    navigate(routes.registrations.contact(registrationCode));
  };

  const mainContent = isPackRegistration(registration) ? content.start.pack : content.start.staff;

  return (
    <Card>
      <CardHeading>{mainContent.title}</CardHeading>
      <CardIntroduction>
        <BlocksRenderer content={mainContent.body} />
      </CardIntroduction>
      <CardFooter>
        <Button variant="contained" size="large" onClick={startRegistration}>Start</Button>
      </CardFooter>
    </Card>
  );
};

export default RegistrationStartPage;

import { Pack } from '../models/pack';
import { PersonStatus } from '../models/personStatus';
import { Staff } from '../models/staff';
import { getStaffTeamLabel } from '../models/staffTeam';

export const getShortFullName = (
  person: { firstName: string, knownAs?: string, lastName: string },
): string => {
  if (person.knownAs) {
    return `${person.knownAs} ${person.lastName}`;
  }
  return `${person.firstName} ${person.lastName}`;
};

export const getFullName = (
  person: { firstName: string, knownAs?: string, lastName: string },
): string => {
  if (person.knownAs) {
    return `${person.firstName} (${person.knownAs}) ${person.lastName}`;
  }
  return `${person.firstName} ${person.lastName}`;
};

export const getFullNamePossessive = (
  person: { firstName: string, knownAs?: string, lastName: string },
): string => {
  const fullName = getFullName(person);
  const suffix = fullName.endsWith('s') ? "'" : "'s";
  return fullName + suffix;
};

export const getIntroduction = (staff?: Staff, pack?: Pack): string => {
  if (staff && staff.customRole) {
    return `Staff - ${staff.customRole}`;
  }
  if (staff) {
    return `Staff - ${getStaffTeamLabel(staff.staffTeam)}`;
  }
  if (pack) {
    return `${pack.reference}: ${pack.name}`;
  }
  return '';
};

export const isAttending = (
  person: { status: PersonStatus },
): boolean => ['Cub', 'YoungLeader', 'Leader', 'OtherChild'].includes(person.status);

export const isParticipant = (
  person: { status: PersonStatus },
): boolean => ['Cub', 'OtherChild'].includes(person.status);

export const isAdult = (
  person: { status: PersonStatus },
): boolean => ['Leader', 'Booker', 'Contact'].includes(person.status);

export const isAdultInScouting = (
  person: { status: PersonStatus },
): boolean => ['Leader', 'Booker'].includes(person.status);

export const isLeader = (
  person: { status: PersonStatus },
): boolean => ['YoungLeader', 'Leader'].includes(person.status);

export const isLeaderOrAdult = (
  person: { status: PersonStatus },
): boolean => ['YoungLeader', 'Leader', 'Booker', 'Contact'].includes(person.status);

export const sortByStatus = (a: { status: PersonStatus }, b: { status: PersonStatus }): number => {
  const statuses = ['Cub', 'OtherChild', 'YoungLeader', 'Leader', 'Visitor', 'Booker', 'Contact'];
  return statuses.indexOf(a.status) - statuses.indexOf(b.status);
};

export const sortByFullName = (
  a: { firstName: string, lastName: string },
  b: { firstName: string, lastName: string },
): number => {
  const aName = getShortFullName(a);
  const bName = getShortFullName(b);
  return aName.localeCompare(bName);
};

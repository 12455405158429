import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NewBooking, selectBooking, update } from './index';

const useBooking = (): [
  NewBooking,
  (newBooking: NewBooking) => void,
] => {
  const booking = useSelector(selectBooking);
  const dispatch = useDispatch();

  const setBooking = useCallback(
    (newBooking: NewBooking): void => {
      dispatch(update(newBooking));
    },
    [dispatch],
  );

  return [booking, setBooking];
};

export default useBooking;

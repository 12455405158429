import {
  Alert,
  AlertTitle,
  Backdrop,
  Button,
  CircularProgress,
  Collapse,
} from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import CardFooter from '../../../../../components/CardFooter';
import RankingInput from '../../../../../components/RankingInput';
import { Activity } from '../../../../../models/activity';
import { ActivityPreference } from '../../../../../models/activityPreference';
import { useUpdateActivityPreferencesMutation } from '../../../../../state/protectedApi/activityPreferences';

type ActivityPreferencesFormProps = {
  personId: number
  activities: Activity[]
  preferences: ActivityPreference[]
  disabled: boolean
  onSuccess: () => void
  onError: (error: SerializedError | FetchBaseQueryError) => void
};

const ActivityPreferencesForm: FC<ActivityPreferencesFormProps> = (props) => {
  const {
    personId,
    activities,
    preferences,
    disabled,
    onSuccess,
    onError,
  } = props;

  const [updateActivityPreferences] = useUpdateActivityPreferencesMutation();
  const [valuesUpdated, setValuesUpdated] = useState(false);

  const initialValues = {
    data: preferences.slice().sort((a, b) => {
      if (a.rank === null) {
        return 1;
      }
      if (b.rank === null) {
        return -1;
      }
      return a.rank - b.rank;
    }).map((p) => ({
      id: p.activityId,
      name: activities.find((a) => a.id === p.activityId)?.name ?? 'Not found',
    })),
  };

  const noRankSet = preferences.filter((pref) => pref.rank === null).map((pref) => activities.find((activity) => activity.id === pref.activityId)?.name ?? 'Not found');

  const {
    handleSubmit,
    values,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues,
    onSubmit: async (formValues) => {
      const data = formValues.data.map((option, index) => ({
        activityId: option.id,
        rank: index + 1,
      }));
      await updateActivityPreferences({ personId, data }).then(async (result) => {
        if ('error' in result) {
          onError(result.error);
        } else {
          setValuesUpdated(true);
          onSuccess();
        }
      });
    },
  });

  return (
    <form onSubmit={handleSubmit} noValidate>
      {disabled && noRankSet.length > 0 && !valuesUpdated && (
        <Alert severity="warning" variant="outlined" sx={{ marginBottom: '12px' }}>
          <AlertTitle>Some activities have not been ranked:</AlertTitle>
          {noRankSet.map((activityName) => (
            <div key={activityName}>{activityName}</div>
          ))}
        </Alert>
      )}
      {values.data.length > 0 && (
        <RankingInput
          options={values.data}
          onChange={(newValue) => setFieldValue('data', newValue)}
          disabled={disabled}
        />
      )}
      <Collapse in={!disabled}>
        <CardFooter>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </CardFooter>
      </Collapse>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </form>
  );
};

export default ActivityPreferencesForm;

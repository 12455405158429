import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useFormik } from 'formik';
import { FC } from 'react';
import CardFooter from '../../../../../components/CardFooter';
import { FormGrid } from '../../../../../components/FormGrid';
import { isLeader, isParticipant } from '../../../../../helpers/person';
import { Activity } from '../../../../../models/activity';
import { activityLeaderRoleOptions, isValidActivityLeaderRole } from '../../../../../models/activityLeaderRole';
import { ActivityScheduleItem } from '../../../../../models/activitySchedule';
import { ActivitySession } from '../../../../../models/activitySession';
import { Person } from '../../../../../models/person';
import { useUpdateActivityScheduleMutation } from '../../../../../state/protectedApi/activitySchedules';
import sanitiseOnSubmit from './sanitise';

type ScheduleFormProps = {
  person: Person
  activities: Activity[]
  sessions: ActivitySession[]
  schedule: ActivityScheduleItem[]
  onSuccess: () => void
  onError: (error: SerializedError | FetchBaseQueryError) => void
  editionId: number
};

const ScheduleForm: FC<ScheduleFormProps> = (props) => {
  const {
    person,
    activities,
    sessions,
    schedule,
    onSuccess,
    onError,
    editionId,
  } = props;

  const [updateActivitySchedule] = useUpdateActivityScheduleMutation();

  const initialValues = {
    data: schedule.slice().sort((a, b) => {
      const sessionA = sessions.find((s) => s.id === a.sessionId);
      const sessionB = sessions.find((s) => s.id === b.sessionId);
      if (sessionA === undefined) {
        return 1;
      }
      if (sessionB === undefined) {
        return -1;
      }
      return sessionA.order - sessionB.order;
    }),
  };

  const {
    handleSubmit,
    values,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
  } = useFormik({
    initialValues,
    onSubmit: async (formValues) => {
      const data = sanitiseOnSubmit(formValues.data);
      await updateActivitySchedule({
        personId: person.id,
        editionId,
        data,
      }).then(async (result) => {
        if ('error' in result) {
          onError(result.error);
        } else {
          onSuccess();
        }
      });
    },
  });

  const getErrorForIndex = (
    index: number,
    field: keyof ActivityScheduleItem,
  ): string | undefined => {
    if (errors.data === undefined || errors.data[index] === undefined) {
      return undefined;
    }
    const errorObj = errors.data[index];
    if (typeof errorObj === 'string') {
      return errorObj;
    }
    return errorObj[field];
  };

  // TODO: add complex validation for leader role for the activity type
  // TODO: add validation for the number of participants (perhaps warnings rather than a hard error)
  // TODO: add validation all sessions being filled for cubs
  // TODO: add validation for all sessions being unique for cubs
  // TODO: add input to set or clear checked in time for an activity session
  return (
    <form onSubmit={handleSubmit} noValidate>
      {values.data.map((item, index) => (
        <FormGrid columns={2} key={item.sessionId}>
          <Autocomplete
            options={activities.map((activity) => activity.id)}
            getOptionLabel={(option) => {
              const activity = activities.find((a) => a.id === option);
              if (activity === undefined) {
                return 'None';
              }
              if (isParticipant(person)) {
                const targetParticipants = activity.targetParticipants ?? 0;
                const currentParticipants = (activity.sessions
                  && activity.sessions[item.sessionId].participants) ?? 0;
                const remaining = targetParticipants - currentParticipants;

                // TODO: Consider the selected state to adjust the capacity up or down live
                if (remaining > 0) {
                  return `${activity.name}, +${remaining}`;
                }
                if (remaining < 0) {
                  return `${activity.name}, ${remaining}`;
                }
              }
              if (person.status === 'YoungLeader') {
                const currentYoungLeaders = (activity.sessions
                  && activity.sessions[item.sessionId].youngLeaders) ?? 0;
                return `${activity.name}, ${currentYoungLeaders}`;
              }
              if (person.status === 'Leader') {
                const currentAdultLeaders = (activity.sessions
                  && activity.sessions[item.sessionId].adultLeaders) ?? 0;
                return `${activity.name}, ${currentAdultLeaders}`;
              }
              return activity.name;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`Session ${sessions.find((s) => s.id === item.sessionId)?.order ?? ''}`}
                error={touched.data
                  && touched.data[index]?.activityId
                  && Boolean(getErrorForIndex(index, 'activityId'))}
                helperText={(touched.data
                  && touched.data[index]?.activityId
                  && getErrorForIndex(index, 'activityId')) || ' '}
              />
            )}
            value={(values.data[index] && values.data[index].activityId) || null}
            onChange={async (event, value) => {
              await setFieldValue(`data.${index}.activityId`, value);
            }}
            onBlur={() => setFieldTouched(`data.${index}.activityId`)}
          />
          {isLeader(person) && (
            <FormControl
              error={touched.data && touched.data[index]?.leaderRole
                && Boolean(getErrorForIndex(index, 'leaderRole'))}
            >
              <FormLabel id={`role-session-${index}-label`}>
                Leader Role
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby={`role-session-${index}-label`}
                value={(values.data[index] && values.data[index].leaderRole) || ''}
                onChange={async (event) => {
                  if (isValidActivityLeaderRole(event.target.value)) {
                    await setFieldValue(`data.${index}.leaderRole`, event.target.value);
                  }
                }}
                onBlur={() => setFieldTouched(`data.${index}.leaderRole`)}
              >
                {activityLeaderRoleOptions.map((option) => (
                  <FormControlLabel
                    key={`leadership-role-input-${item.sessionId}-option-${option.label}`}
                    value={values.data[index] && values.data[index].activityId !== null
                      ? option.value : ''}
                    control={<Radio />}
                    label={option.label}
                    disabled={values.data[index] && values.data[index].activityId === null}
                    // TODO: Deal with activities that cannot be lead (Archery)
                    // disabled={option.value === 'Lead' && activity.delivery !== 'PackLeader'}
                  />
                ))}
              </RadioGroup>
              <FormHelperText>
                {(touched.data
                    && touched.data[index]?.leaderRole
                    && getErrorForIndex(index, 'leaderRole'))
                  || ' '}
              </FormHelperText>
            </FormControl>
          )}
        </FormGrid>
      ))}
      <CardFooter>
        <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </CardFooter>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </form>
  );
};

export default ScheduleForm;

import { NewBooking } from '../../../state/booking';

const sanitiseOnSubmit = (rawValues: NewBooking): NewBooking => {
  const pack = {
    ...rawValues.pack,
    groupName: rawValues.pack.groupName.trim(),
    packName: rawValues.pack.packName.trim(),
    addressStreet: rawValues.pack.addressStreet.trim(),
    addressTown: rawValues.pack.addressTown.trim(),
    addressPostcode: rawValues.pack.addressPostcode.trim(),
  };

  return { ...rawValues, pack };
};

export default sanitiseOnSubmit;

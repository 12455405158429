import * as Yup from 'yup';
import personRules from '../../../../../helpers/validation/person';

export const validationSchema = Yup.object().shape({
  status: Yup.string().required('Status is required'),
  firstName: personRules.firstName.required('First name is required'),
  knownAs: personRules.knownAs,
  lastName: personRules.lastName.required('Last name is required'),
  scoutingAppointment: personRules.scoutingAppointment.nullable(),
  membershipNumber: personRules.membershipNumber.nullable(),
  mobile: personRules.mobile.nullable(),
  email: personRules.email.nullable(),
  addressStreet: personRules.addressStreet.nullable(),
  addressTown: personRules.addressTown.nullable(),
  addressPostcode: personRules.addressPostcode.nullable(),
  onsite: Yup.string().required('Onsite status is required'),
});

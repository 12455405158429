export const activityLeaderRoles = [
  'Lead',
  'Support',
  'OneToOne',
  'AdultLeadForSafety',
] as const;

export type ActivityLeaderRole = (typeof activityLeaderRoles)[number];

export const isValidActivityLeaderRole = (
  value: string,
): value is ActivityLeaderRole => activityLeaderRoles.includes(value as never);

export const getActivityLeaderRoleLabel = (role: ActivityLeaderRole): string => {
  switch (role) {
    case 'OneToOne':
      return 'Individual';
    case 'Support':
      return 'Assistant';
    case 'AdultLeadForSafety':
      return 'Adult safety cover for YL';
    default:
      return role;
  }
};

export const activityLeaderRoleOptions = activityLeaderRoles.map(
  (role) => ({
    label: getActivityLeaderRoleLabel(role),
    value: role,
  }),
);

export const onsiteStatuses = [
  'NotAttending',
  'Expected',
  'Arrived',
  'Departed',
] as const;

export type OnsiteStatus = typeof onsiteStatuses[number];

export const isValidOnsiteStatus = (
  value: string,
): value is OnsiteStatus => onsiteStatuses.includes(value as never);

export const getOnsiteStatusLabel = (status: OnsiteStatus): string => {
  switch (status) {
    case 'NotAttending':
      return 'Not attending';
    default:
      return status;
  }
};

import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { FC, useEffect, useState } from 'react';
import searchClient from '../../../api/search';
import useDebounce from '../../../helpers/useDebounce';
import { PersonSearchResult } from '../../../models/search';

type PersonSearchInputProps = {
  edition?: number;
  onChange: (personId: number) => void;
  onOpen?: () => void;
  onReset?: () => void;
  disabled?: boolean;
};

const PersonSearchInput: FC<PersonSearchInputProps> = (props) => {
  const {
    edition,
    onChange,
    disabled,
    onOpen,
    onReset,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [options, setOptions] = useState<readonly PersonSearchResult[]>([]);

  const debouncedInputValue = useDebounce(inputValue, 500);

  useEffect(() => {
    if (debouncedInputValue.trim() === '') {
      setOptions([]);
      return undefined;
    }
    const timeoutId = setTimeout(() => {
      setIsSearching(true);
      searchClient.people(debouncedInputValue, 1, 50, edition).then((response) => {
        setOptions(response.results.map((result) => ({ type: 'People', ...result })));
      }).catch(console.error).finally(() => {
        setIsSearching(false);
      });
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [debouncedInputValue, edition]);

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
      filterOptions={(x) => x}
      autoComplete
      blurOnSelect
      forcePopupIcon={false}
      options={options}
      value={null}
      freeSolo
      disabled={disabled}
      noOptionsText={inputValue.trim() === '' ? 'Enter a search term to see results' : 'No people found'}
      loading={isSearching}
      fullWidth
      onOpen={onOpen}
      onReset={onReset}
      onChange={(
        event,
        newValue: string | PersonSearchResult | null,
      ) => {
        if (newValue !== null && typeof newValue !== 'string') {
          onChange(newValue.id);
        } else {
          onReset?.();
        }
      }}
      onInputChange={(event, newInputValue) => {
        if (newInputValue.trim() === '') {
          setOptions([]);
          onReset?.();
        }
        setInputValue(newInputValue.trim());
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search"
          value={inputValue}
          fullWidth
        />
      )}
      renderOption={(renderProps, option) => {
        const nameMatches = match(option.name, inputValue, { insideWords: true });
        const nameParts = parse(option.name, nameMatches);
        const detail = option.role;
        const detailMatches = match(detail, inputValue, { insideWords: true });
        const detailParts = parse(detail, detailMatches);

        return (
          <li {...renderProps}>
            <Grid container alignItems="center">
              <Grid item sx={{ wordWrap: 'break-word' }}>
                <Typography variant="body1">
                  {nameParts.map((part, index) => (
                    <span
                      key={`search-${option.id}-name-${index + 1}`}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <span>ID: </span>
                  <span
                    style={{ fontWeight: option.id.toString() === inputValue.trim() ? 700 : 400 }}
                  >
                    {option.id}
                  </span>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {detailParts.map((part, index) => (
                    <span
                      key={`search-${option.id}-role-${index + 1}`}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default PersonSearchInput;

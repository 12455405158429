export const reviewTypes = [
  'Admin',
  'Compass',
  'Activities',
  'Catering',
  'Subcamp',
  'PackLeader',
] as const;

export type ReviewType = (typeof reviewTypes)[number];

import { objectToCamel, objectToSnake } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { User, UserFormValues } from '../../models/user';
import { protectedApi } from './index';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<User, void>({
      query: () => 'users/who-am-i',
      transformResponse: (response: ObjectToSnake<User>) => objectToCamel(response),
    }),
    getUser: builder.query<User, number>({
      query: (personId: number) => `users/${personId}`,
      transformResponse: (response: ObjectToSnake<User>) => objectToCamel(response),
    }),
    createUser: builder.mutation<User, UserFormValues & { personId: number }>({
      query: (args) => {
        const { personId, ...create } = args;
        const body = objectToSnake(create);
        return ({
          url: `users/${personId}`,
          method: 'POST',
          body,
        });
      },
      invalidatesTags: (result, error, args) => [{ type: 'users', id: args.personId }],
      transformResponse: (response: ObjectToSnake<User>) => objectToCamel(response),
    }),
    updateUser: builder.mutation<User, UserFormValues & { personId: number }>({
      query: (args) => {
        const { personId, ...update } = args;
        const body = objectToSnake(update);
        return ({
          url: `users/${personId}`,
          method: 'PATCH',
          body,
        });
      },
      invalidatesTags: (result, error, args) => [{ type: 'users', id: args.personId }],
      transformResponse: (response: ObjectToSnake<User>) => objectToCamel(response),
    }),
    deleteUser: builder.mutation<void, number>({
      query: (personId) => ({
        url: `users/${personId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, personId) => [{ type: 'users', id: personId }],
    }),
    checkUserCode: builder.query<void, string>({
      query: (userCode) => `user-sign-ups/${userCode}/check`,
    }),
    attachFirebaseId: builder.mutation<void, { firebaseId: string, code: string }>({
      query: (body) => ({
        url: 'user-sign-ups/attach',
        method: 'POST',
        body: objectToSnake(body),
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCurrentUserQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useCheckUserCodeQuery,
  useAttachFirebaseIdMutation,
} = extendedApi;

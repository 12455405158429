import styled from 'styled-components';
import { mediaQuery } from '../../../helpers/responsive';
import { responsiveSpacing } from '../../../helpers/responsiveSpacing';

export const RemoveButtonWrapper = styled.div`
  ${responsiveSpacing('margin-bottom', 's')}
  ${mediaQuery.medium`
    margin: auto 0 auto auto;
  `}
`;

export const AddButtonWrapper = styled.div`
  ${responsiveSpacing('margin', 's', '0', 's', '0')}
`;

export const NewContactFormWrapper = styled.div`
  ${responsiveSpacing('margin-bottom', 'm')}
`;

import { objectToCamel, objectToSnake } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { CreateMeal, Meal, UpdateMeal } from '../../models/meal';
import { protectedApi } from './index';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    createMeal: builder.mutation<Meal, CreateMeal>({
      query: (args) => {
        const body = objectToSnake(args);
        return ({
          url: 'meals/',
          method: 'POST',
          body,
        });
      },
      transformResponse: (response: ObjectToSnake<Meal>) => objectToCamel(response),
    }),
    updateMeal: builder.mutation<Meal, UpdateMeal>({
      query: (args) => {
        const { id, ...update } = args;
        const body = objectToSnake(update);
        return ({
          url: `meals/${id}`,
          method: 'PATCH',
          body,
        });
      },
      transformResponse: (response: ObjectToSnake<Meal>) => objectToCamel(response),
    }),
    deleteMeal: builder.mutation<void, number>({
      query: (id) => ({
        url: `meals/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateMealMutation,
  useUpdateMealMutation,
  useDeleteMealMutation,
} = extendedApi;

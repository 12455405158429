import EditIcon from '@mui/icons-material/Edit';
import {
  Backdrop,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
} from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useState } from 'react';
import ErrorAlert from '../../../../../components/DataPanel/ErrorAlert';
import { FormGridWithTopPadding } from '../../../../../components/FormGrid';
import { useTypeSafeFormik } from '../../../../../helpers/form';
import { ActivityLeadership } from '../../../../../models/activityLeadership';
import { useUpdateActivityLeadershipsForPersonMutation } from '../../../../../state/protectedApi/activityLeadership';
import { useGetActivitiesQuery } from '../../../../../state/publicApi/activities';
import sanitiseOnSubmit from './sanitise';

type ActivityLeadershipFormDialogProps = {
  personId: number
  activityLeadership: ActivityLeadership[]
  onOpen: () => void
  onSuccess: () => void
};

const ActivityLeadershipFormDialog = (props: ActivityLeadershipFormDialogProps): JSX.Element => {
  const {
    personId,
    activityLeadership,
    onOpen: afterOpen,
    onSuccess,
  } = props;

  const [open, setOpen] = useState(false);
  const [error, setError] = useState<SerializedError | FetchBaseQueryError>();

  const onOpen = (): void => {
    setError(undefined);
    setOpen(true);
    afterOpen();
  };

  const onClose = (): void => {
    setOpen(false);
  };

  const { data: activities } = useGetActivitiesQuery();
  const [updateActivityLeadership] = useUpdateActivityLeadershipsForPersonMutation();

  const getActivityName = (activityId: number): string => activities?.find((a) => a.id === activityId)?.name ?? 'Loading';

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    dirty,
  } = useTypeSafeFormik<ActivityLeadership[]>({
    initialValues: activityLeadership,
    onSubmit: async (formValues) => {
      const sanitisedValues = sanitiseOnSubmit(formValues);
      await updateActivityLeadership({
        personId,
        data: sanitisedValues,
      }).then(async (result) => {
        if ('error' in result) {
          setError(result.error);
        } else {
          onSuccess();
          onClose();
        }
      });
    },
  });

  return (
    <>
      <IconButton onClick={onOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <form onSubmit={handleSubmit} noValidate>
          <DialogTitle>
            Can the person lead the following activities?
          </DialogTitle>
          <DialogContent sx={{ paddingTop: '5px' }}>
            <Collapse in={error !== undefined}>
              {error && (
                <ErrorAlert modelName="activity leadership" type="update" error={error} />
              )}
            </Collapse>
            <FormGridWithTopPadding columns={2}>
              {values.map((leadership, index) => (
                <FormControl
                  key={leadership.activityId}
                  error={touched[index]?.canLead && Boolean(errors[index]?.canLead)}
                  disabled={isSubmitting}
                >
                  <FormLabel id={`activity-${leadership.activityId}-leadership-input-label`}>
                    {getActivityName(leadership.activityId)}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby={`activity-${leadership.activityId}-leadership-input-label`}
                    value={values[index].canLead}
                    onChange={async (event) => {
                      await setFieldValue(`${index}.canLead`, event.target.value === 'true');
                    }}
                    onBlur={() => setFieldTouched(`${index}.canLead`)}
                  >
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                  <FormHelperText>
                    {(touched[index]?.canLead && errors[index]?.canLead) || ' '}
                  </FormHelperText>
                </FormControl>
              ))}
            </FormGridWithTopPadding>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting || !dirty}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
          </DialogActions>
          <Backdrop open={isSubmitting}>
            <CircularProgress />
          </Backdrop>
        </form>
      </Dialog>
    </>
  );
};

export default ActivityLeadershipFormDialog;

import { objectToCamel } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { BallotStatistics } from '../../models/ballotStatistics';
import { Statistics } from '../../models/statistics';
import { protectedApi } from './index';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getStatistics: builder.query<Statistics, number | undefined>({
      query: (editionId) => {
        const route = 'statistics/';
        return editionId ? `${route}?edition_id=${editionId}` : route;
      },
      transformResponse: (response: ObjectToSnake<Statistics>) => objectToCamel(response),
    }),
    getBallotStatistics: builder.query<BallotStatistics, number | undefined>({
      query: (editionId) => {
        const route = 'statistics/ballot/';
        return editionId ? `${route}?edition_id=${editionId}` : route;
      },
      transformResponse: (response: ObjectToSnake<BallotStatistics>) => objectToCamel(response),
      providesTags: ['ballotStats'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetStatisticsQuery, useGetBallotStatisticsQuery } = extendedApi;

import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetEventConfigQuery } from '../../state/publicApi';

const PageMetaTitle: FC<{ title: string }> = (props) => {
  const { title } = props;
  const location = useLocation();

  const { data: eventConfig } = useGetEventConfigQuery();
  const defaultTitle = eventConfig ? eventConfig.name : 'Loading...';

  useEffect(() => {
    document.title = `${title} | ${defaultTitle}`;

    return () => {
      document.title = defaultTitle;
    };
  }, [defaultTitle, location, title]);

  return null;
};

export default PageMetaTitle;

import { Alert, AlertTitle } from '@mui/material';
import styled from 'styled-components';
import { responsiveSpacing } from '../../helpers/responsiveSpacing';

export const AlertWithMargin = styled(Alert)`
  ${responsiveSpacing('margin-top', 'm')}
  ${responsiveSpacing('margin-bottom', 'm')}
`;

export const AlertWithBottomMargin = styled(Alert)`
  ${responsiveSpacing('margin-bottom', 'm')}
`;

export const AlertWithSmallBottomMargin = styled(Alert)`
  ${responsiveSpacing('margin-bottom', 'xxs')}
`;

export const AlertTitleWithoutMargin = styled(AlertTitle)`
  margin-bottom: 0;
`;

import {
  Alert,
  AlertTitle,
  Autocomplete,
  Button,
  CircularProgress,
  Stack,
  TextField,
} from '@mui/material';
import { FC, useState } from 'react';
import mealCheckInService, { MealCheckInResponse } from '../../api/mealCheckIn';
import Card from '../../components/Card';
import CardHeading from '../../components/CardHeading';
import CardIntroduction from '../../components/CardIntroduction';
import CheckIn from '../../components/CheckIn';
import { BasePayload } from '../../components/CheckIn/types';
import { FormGrid } from '../../components/FormGrid';
import PageMetaTitle from '../../components/PageMetaTitle';
import { addNotification } from '../../helpers/notification';
import { useDefaultEdition } from '../../helpers/useDefaultEdition';
import useErrorHandler from '../../helpers/useErrorHandler';
import { useLocalStorage } from '../../helpers/useLocalStorage';
import { useUrlOptionalNumberParam } from '../../helpers/useUrlParam';
import { useGetEditionsQuery } from '../../state/publicApi/editions';
import { useGetMealsQuery } from '../../state/publicApi/meals';

const HistoryItem: FC<MealCheckInResponse> = (props) => {
  const { message, status } = props;

  return (
    <Alert severity={status} sx={{ marginBottom: '10px' }}>
      <AlertTitle>{message}</AlertTitle>
    </Alert>
  );
};

const MealCheckIn = (): JSX.Element => {
  const {
    data: rawMeals,
    error: mealsError,
    isLoading: mealsIsLoading,
  } = useGetMealsQuery();
  useErrorHandler(mealsError, 'API Error: Failed to load meals');

  const [history, setHistory] = useLocalStorage<MealCheckInResponse[]>('meals', []);
  const [isFetching, setIsFetching] = useState(false);
  const [mealId, setMealId] = useUrlOptionalNumberParam('meal');
  const currentMeal = rawMeals?.find((meal) => meal.id === mealId);
  const [edition, setEditionId] = useDefaultEdition();

  const {
    data: editions,
    isLoading: editionsIsLoading,
  } = useGetEditionsQuery();

  const meals = rawMeals?.filter((meal) => meal.editionId === edition?.id);

  const handleCheckIn = async (basePayload: BasePayload): Promise<void> => {
    if (mealId === undefined) {
      addNotification('Meal not selected - select a meal to check people into', 'error');
    } else {
      setIsFetching(true);
      mealCheckInService.notify({ ...basePayload, mealId }).then((response) => {
        addNotification(response.message, response.status);
        setHistory((prevHistory) => [
          response,
          ...prevHistory,
        ]);
      }).catch((error) => {
        // eslint-disable-next-line max-len
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        const message: string | undefined = error?.response?.data?.message;
        if (message) {
          addNotification(message, 'error');
        } else {
          addNotification('An unexpected error occurred', 'error');
        }
      }).finally(() => {
        setIsFetching(false);
      });
    }
  };

  // TODO allow the user to undo a notification if they do so within 1 minute (scanning the
  //  wrong lanyard).
  return (
    <Card>
      <PageMetaTitle title="Meal Check-In" />
      {currentMeal === undefined ? (
        <>
          <CardHeading>
            Meal Check-In
          </CardHeading>
          <FormGrid columns={2}>
            <Autocomplete
              options={editions ?? []}
              loading={editionsIsLoading}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Edition"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {mealsIsLoading ? <CircularProgress size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              value={edition ?? null}
              onChange={(event, value) => {
                if (value) {
                  setEditionId(value.id);
                }
              }}
            />
            <Autocomplete
              options={meals ?? []}
              loading={mealsIsLoading}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Meal"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {mealsIsLoading ? <CircularProgress size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              value={meals?.find((meal) => meal.id === mealId) ?? null}
              onChange={(event, value) => {
                setMealId(value?.id ?? undefined);
              }}
            />
          </FormGrid>
        </>
      ) : (
        <>
          <Stack direction="row" justifyContent="space-between">
            <CardHeading>{currentMeal.name}</CardHeading>
            <div>
              <Button onClick={() => setMealId(undefined)} variant="outlined">Change Meal</Button>
            </div>
          </Stack>
          <CardIntroduction>
            Scan the QR code on the attendees lanyard to check them into the meal.
          </CardIntroduction>
          <CheckIn
            trackingId={`meal-${mealId ?? ''}`}
            editionId={edition?.id ?? undefined}
            checkInPerson={handleCheckIn}
            isFetching={isFetching}
            history={history ?? []}
            RenderHistoryItem={HistoryItem}
            resetHistory={() => setHistory([])}
            getResponseId={(item) => item.id?.toString() ?? 'x'}
          />
        </>
      )}
    </Card>
  );
};

export default MealCheckIn;

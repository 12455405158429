import styled from 'styled-components';
import { columnWidthPx, getHeightPx, gutterPx } from './config';

type GridStyleProps = {
  columns: number
};

export const Grid = styled.div<GridStyleProps>`
  display: grid;
  grid-gap: ${gutterPx}px;
  grid-template-columns: repeat(${({ columns }) => columns}, ${columnWidthPx}px);
  justify-content: center;
`;

type SubGridStyleProps = {
  columns: number
  rows: number
};

export const SubGrid = styled.div<SubGridStyleProps>`
  height: ${({ rows }) => getHeightPx(rows)}px;
  grid-row: span ${({ rows }) => rows};
  grid-column: span ${({ columns }) => columns};
  
  display: grid;
  grid-gap: ${gutterPx}px;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  grid-template-rows: repeat(${({ rows }) => rows}, 1fr);
`;

export const Wrapper = styled.div`
  width: 100%;
`;

type FilterBarProps = {
  columns: number
};

export const FilterBar = styled.div<FilterBarProps>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${({ theme }) => (theme.palette.mode === 'light' ? theme.palette.grey.A200 : theme.palette.grey[800])};
  border-radius: 5px;
  padding: 10px;
  grid-column: span ${({ columns }) => columns};
`;

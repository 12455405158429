import { GridCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { isNumber } from './typeValidation';

export const numberCellClassIfPositive = <TRow extends GridValidRowModel>(className: string) => (
  params: GridCellParams<TRow, number>,
): string => (params.value !== undefined && params.value > 0 ? className : '');

export const numberCellClassComparison = <TRow extends GridValidRowModel>(
  comparisonField: string | ((params: GridCellParams<TRow, number>) => number),
  classNames: {
    greaterThan?: string,
    equal?: string,
    lessThan?: string,
  },
  additionalCheck?: (params: GridCellParams<TRow, number>) => boolean,
) => (
    params: GridCellParams<TRow, number>,
  ): string => {
    const { greaterThan, equal, lessThan } = classNames;
    const fieldValue = params.value;
    const comparisonValue: unknown = typeof comparisonField === 'string'
      ? params.row[comparisonField]
      : comparisonField(params);
    if (!isNumber(fieldValue) || !isNumber(comparisonValue)) {
      return '';
    }
    if (additionalCheck && !additionalCheck(params)) {
      return '';
    }
    if (greaterThan && fieldValue > comparisonValue) {
      return greaterThan;
    }
    if (equal && fieldValue === comparisonValue) {
      return equal;
    }
    if (lessThan && fieldValue < comparisonValue) {
      return lessThan;
    }
    return '';
  };

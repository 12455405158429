import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { objectToCamel } from 'ts-case-convert';
import searchClient from '../../../../api/search';
import useDebounce from '../../../../helpers/useDebounce';
import { ActivitySearchResult, PackSearchResult, PersonSearchResult } from '../../../../models/search';
import routes from '../../../../routes';
import * as Styled from './styles';

const searchEnabled = process.env.REACT_APP_SEARCH_ENABLED === 'true';

const getDetail = (
  result: ActivitySearchResult | PackSearchResult | PersonSearchResult,
): string => {
  switch (result.type) {
    case 'Activities':
      return result.category;
    case 'Packs':
      return result.reference;
    case 'People':
      return result.role;
    default:
      return '';
  }
};

const SearchBar = (): JSX.Element => {
  const [inputValue, setInputValue] = useState('');
  const [activityOptions, setActivityOptions] = useState<readonly ActivitySearchResult[]>([]);
  const [packOptions, setPackOptions] = useState<readonly PackSearchResult[]>([]);
  const [peopleOptions, setPeopleOptions] = useState<readonly PersonSearchResult[]>([]);
  const navigate = useNavigate();

  const debouncedInputValue = useDebounce(inputValue, 500);

  useEffect(() => {
    if (debouncedInputValue.trim() === '') {
      setActivityOptions([]);
      setPackOptions([]);
      setPeopleOptions([]);
      return undefined;
    }

    // TODO: handle search response errors
    // TODO: add a loading spinner to the search input when results are pending
    const timeoutId = setTimeout(() => {
      searchClient.activities(debouncedInputValue, 1, 4).then((response) => {
        setActivityOptions(response.results.map((result) => ({ type: 'Activities', ...objectToCamel(result) })));
      }).catch(console.error);
      searchClient.packs(debouncedInputValue, 1, 4).then((response) => {
        setPackOptions(response.results.map((result) => ({ type: 'Packs', ...objectToCamel(result) })));
      }).catch(console.error);
      searchClient.people(debouncedInputValue, 1, 50).then((response) => {
        setPeopleOptions(response.results.map((result) => ({ type: 'People', ...result })));
      }).catch(console.error);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [debouncedInputValue]);

  const allOptions = [...activityOptions, ...packOptions, ...peopleOptions];

  return (
    <Autocomplete
      sx={{ width: 320 }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
      filterOptions={(x) => x}
      autoComplete
      blurOnSelect
      forcePopupIcon={false}
      options={allOptions}
      groupBy={(option) => option.type}
      value={null}
      freeSolo
      disabled={!searchEnabled}
      noOptionsText={inputValue.trim() === '' ? 'Enter a search term to see results' : 'No activities, packs or people found'}
      onChange={(
        event,
        newValue: string | ActivitySearchResult | PackSearchResult | PersonSearchResult | null,
      ) => {
        if (newValue !== null && typeof newValue !== 'string') {
          switch (newValue.type) {
            case 'Activities':
              navigate(routes.activities.adminDetail(newValue.id));
              break;
            case 'Packs':
              navigate(routes.packs.detail(newValue.id));
              break;
            case 'People':
              navigate(routes.people.detail(newValue.id));
              break;
            default:
              break;
          }
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <Styled.TextField
          {...params}
          placeholder={searchEnabled ? 'Search...' : 'Disabled to save costs'}
          value={inputValue}
          size="small"
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        const nameMatches = match(option.name, inputValue, { insideWords: true });
        const nameParts = parse(option.name, nameMatches);
        const detail = getDetail(option);
        const detailMatches = match(detail, inputValue, { insideWords: true });
        const detailParts = parse(detail, detailMatches);

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ wordWrap: 'break-word' }}>
                <Typography variant="body1">
                  {nameParts.map((part, index) => (
                    <span
                      key={`search-${option.id}-name-${index + 1}`}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Typography>
                {option.type === 'People' && (
                  <Typography variant="body2" color="text.secondary">
                    <span>ID: </span>
                    <span
                      style={{ fontWeight: option.id.toString() === inputValue.trim() ? 700 : 400 }}
                    >
                      {option.id}
                    </span>
                  </Typography>
                )}
                <Typography variant="body2" color="text.secondary">
                  {detailParts.map((part, index) => (
                    <span
                      key={`search-${option.id}-role-${index + 1}`}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default SearchBar;

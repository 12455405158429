import {
  Autocomplete,
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { FC } from 'react';
import CardFooter from '../../../../../components/CardFooter';
import { FormGrid } from '../../../../../components/FormGrid';
import { FormProps } from '../../../../../components/forms/types';
import { getEditionSummary } from '../../../../../helpers/edition';
import { Staff } from '../../../../../models/staff';
import { isValidStaffTeam, staffTeams } from '../../../../../models/staffTeam';
import { isValidSubcamp, subcamps } from '../../../../../models/subcamp';
import { useGetEventConfigQuery } from '../../../../../state/publicApi';
import { useGetEditionsQuery } from '../../../../../state/publicApi/editions';
import { useGetDistrictsByRegionQuery, useGetRegionsQuery } from '../../../../../state/publicApi/reference';

const StaffForm: FC<FormProps<Staff>> = (props) => {
  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = props;

  const { data: eventConfig } = useGetEventConfigQuery();
  const { data: editions } = useGetEditionsQuery();
  const {
    data: regions,
    isLoading: regionsLoading,
  } = useGetRegionsQuery();
  const {
    currentData: districts,
    isLoading: districtsLoading,
  } = useGetDistrictsByRegionQuery(values.regionId ?? skipToken);

  return (
    <form onSubmit={handleSubmit} noValidate>
      {editions && editions.length > 1 && eventConfig && (
        <FormGrid>
          <FormControl
            error={touched.editionIds && !!errors.editionIds}
            component="fieldset"
          >
            <FormLabel component="legend">Event Editions</FormLabel>
            <FormGroup>
              {editions.map((edition) => (
                <FormControlLabel
                  key={edition.id}
                  control={(
                    <Checkbox
                      checked={values.editionIds.includes(edition.id)}
                      onChange={async (event) => {
                        const newValue = event.target.checked;
                        if (newValue && values.editionIds) {
                          await setFieldValue('editionIds', [...values.editionIds, edition.id]);
                        } else {
                          await setFieldValue('editionIds', values.editionIds.filter((id) => id !== edition.id));
                        }
                      }}
                      onBlur={() => setFieldTouched('editionIds')}
                      name={edition.name}
                    />
                  )}
                  label={getEditionSummary(eventConfig, edition)}
                />
              ))}
            </FormGroup>
            <FormHelperText>{(touched.editionIds && errors.editionIds) || ' '}</FormHelperText>
          </FormControl>
        </FormGrid>
      )}
      <FormGrid columns={2}>
        <FormControl
          error={touched.staffTeam && Boolean(errors.staffTeam)}
          disabled={isSubmitting}
        >
          <FormLabel id="staff-team-label">
            Status
          </FormLabel>
          <RadioGroup
            aria-labelledby="staff-team-label"
            value={values.staffTeam}
            onChange={async (event) => {
              if (isValidStaffTeam(event.target.value)) {
                await setFieldValue('staffTeam', event.target.value);
              }
              if (event.target.value !== 'Subcamp') {
                await setFieldValue('subcamp', undefined);
              }
            }}
            onBlur={() => setFieldTouched('staffTeam')}
          >
            {staffTeams.map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>
          <FormHelperText>
            {(touched.staffTeam && errors.staffTeam) || ' '}
          </FormHelperText>
        </FormControl>
      </FormGrid>
      <FormGrid columns={2}>
        <TextField
          label="Custom role"
          value={values.customRole}
          onChange={async (event) => {
            await setFieldValue('customRole', event.target.value);
          }}
          onBlur={() => setFieldTouched('customRole')}
          error={touched.customRole && Boolean(errors.customRole)}
          helperText={(touched.customRole && errors.customRole) || ' '}
          disabled={isSubmitting}
        />
        {values.staffTeam === 'Subcamp' && (
          <FormControl>
            <InputLabel>Subcamp</InputLabel>
            <Select
              label="Subcamp"
              value={values.subcamp ?? ''}
              onChange={async (event) => {
                if (event.target.value && isValidSubcamp(event.target.value)) {
                  await setFieldValue('subcamp', event.target.value);
                } else {
                  await setFieldValue('subcamp', undefined);
                }
              }}
              onBlur={() => setFieldTouched('subcamp')}
              error={touched.subcamp && Boolean(errors.subcamp)}
              disabled={isSubmitting}
            >
              <MenuItem value="">
                None
              </MenuItem>
              {subcamps.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {(touched.subcamp && errors.subcamp) || ' '}
            </FormHelperText>
          </FormControl>
        )}
      </FormGrid>

      <FormGrid columns={2}>
        <Autocomplete
          options={regions?.map((region) => region.id) ?? []}
          loading={regionsLoading}
          getOptionLabel={(option) => regions?.find((r) => r.id === option)?.name ?? ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Region"
              error={touched.regionId && Boolean(errors.regionId)}
              helperText={(touched.regionId && errors.regionId) || ' '}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {regionsLoading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          value={values.regionId}
          onChange={async (event, value) => {
            await setFieldValue('districtId', null);
            await setFieldValue('regionId', value);
          }}
          onBlur={() => setFieldTouched('regionId')}
        />
        <Autocomplete
          options={districts?.map((district) => district.id) ?? []}
          loading={districtsLoading}
          getOptionLabel={(option) => districts?.find((d) => d.id === option)?.name ?? ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label="District"
              helperText={(!values.regionId && 'Select a region first') || ' '}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {districtsLoading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          value={values.districtId}
          onChange={async (event, value) => {
            await setFieldValue('districtId', value);
          }}
          disabled={values.regionId === null}
        />
        <TextField
          label="Group or explorer unit name"
          value={values.groupName}
          onBlur={() => setFieldTouched('groupName')}
          onChange={async (event) => {
            await setFieldValue('groupName', event.target.value);
          }}
          error={touched.groupName && Boolean(errors.groupName)}
          helperText={(touched.groupName && errors.groupName) || ' '}
        />
      </FormGrid>

      <CardFooter>
        <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </CardFooter>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </form>
  );
};

export default StaffForm;

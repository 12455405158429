import {
  Autocomplete,
  Chip,
  CircularProgress,
  TextField,
  createFilterOptions,
} from '@mui/material';
import { ReviewTag } from '../../models/reviewTag';
import { ReviewType } from '../../models/reviewType';
import { Subcamp } from '../../models/subcamp';
import { useGetReviewTagsQuery } from '../../state/protectedApi/reviews';

type ReviewTagsSelectorProps = {
  tags: ReviewTag[]
  type: ReviewType
  subcamp?: Subcamp
  packId?: number
  disabled: boolean
  forceRefetchOfTags?: boolean
  onTagCreation?: () => void
  onChange: (update: ReviewTag[]) => void
};

type ReviewTagSelectOption = ReviewTag & { labelOverride?: string };

const ReviewTagsSelector = (props: ReviewTagsSelectorProps): JSX.Element => {
  const {
    tags,
    type,
    subcamp,
    packId,
    disabled,
    onTagCreation,
    onChange,
  } = props;
  const filter = createFilterOptions<ReviewTagSelectOption>();

  const {
    currentData: reviewTags,
    isLoading: reviewTagsIsLoading,
  } = useGetReviewTagsQuery({ type, subcamp, packId });

  return (
    <Autocomplete
      disabled={disabled}
      multiple
      disableClearable
      value={tags}
      options={reviewTags as ReviewTagSelectOption[] ?? []}
      onChange={(event, update, changeReason) => {
        if (changeReason === 'createOption' && onTagCreation) {
          onTagCreation();
        }
        onChange(update.map((value) => {
          if (typeof value === 'string') {
            return ({
              text: value.trim(),
            });
          }
          return ({
            id: value.id,
            text: value.text.trim(),
          });
        }));
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option.text);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            labelOverride: `Add "${inputValue}"`,
            text: inputValue,
          });
        }

        return filtered.filter((
          optionTag,
        ) => !tags.map((selectedTag) => selectedTag.text).includes(optionTag.text));
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.text)}
      renderOption={(renderProps, option) => (
        <li {...renderProps}>
          {(option as ReviewTagSelectOption).labelOverride ?? option.text}
        </li>
      )}
      freeSolo
      renderTags={
        (value: readonly ReviewTag[], getTagProps) => value.map(
          (option: ReviewTag, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant="outlined"
              label={option.text}
              {
                ...getTagProps({ index })
              }
            />
          ),
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Review tags"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {reviewTagsIsLoading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default ReviewTagsSelector;

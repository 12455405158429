import { BlocksRenderer as StrapiBlocksRenderer } from '@strapi/blocks-react-renderer';
import Image from 'mui-image';
import { FC, ReactNode } from 'react';
import RouterLink from '../RouterLink';

type ImageBlockNode = {
  image: {
    name: string
    alternativeText?: string | null
    url: string
    width: number
    height: number
    formats?: Record<string, unknown>
    hash: string
    ext: string
    mime: string
    size: number
    previewUrl?: string | null
    provider: string
    provider_metadata?: unknown | null
    createdAt: string
    updatedAt: string
  }
};

const ImageRenderer: FC<ImageBlockNode> = (
  { image },
) => (
  <Image
    alt={image.alternativeText ?? image.name}
    src={image.url}
    duration={1000}
    fit="contain"
  />
);

const LinkRenderer: FC<{ url: string, children?: ReactNode }> = (
  { url, children },
) => (
  <RouterLink to={url}>
    {children}
  </RouterLink>
);

const BlocksRenderer: typeof StrapiBlocksRenderer = (props) => (
  <StrapiBlocksRenderer
    {...props}
    blocks={{
      image: ImageRenderer,
      link: LinkRenderer,
    }}
  />
);

export default BlocksRenderer;

import { objectToCamel } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { Edition } from '../../models/edition';
import { publicApi } from './index';

const extendedApi = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    getEdition: builder.query<Edition, number>({
      query: (editionId: number) => `editions/${editionId}`,
      transformResponse: (response: ObjectToSnake<Edition>) => objectToCamel(response),
    }),
    getEditions: builder.query<Edition[], void>({
      query: () => 'editions/',
      transformResponse: (response: ObjectToSnake<Edition[]>) => objectToCamel(response),
    }),
    getEditionsForRegistrationCode: builder.query<Edition[], string>({
      query: (code: string) => `editions/registration/${code}`,
      transformResponse: (response: ObjectToSnake<Edition[]>) => objectToCamel(response),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetEditionQuery,
  useGetEditionsQuery,
  useGetEditionsForRegistrationCodeQuery,
} = extendedApi;

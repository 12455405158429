import { objectToCamel, objectToSnake } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { DataRequest, formatUrlParams } from '../../helpers/dataRequest';
import { PaginationResponse } from '../../helpers/paginationResponse';
import {
  CreatePerson, Person, PersonLocation, UpdatePerson,
} from '../../models/person';
import { protectedApi } from './index';

type PeopleQuery = {
  packId?: number
  editionId?: number
  attendeesOnly?: boolean
};

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getPeople: builder.query<
    PaginationResponse<Person>,
    DataRequest<PeopleQuery> | undefined
    >({
      query: (args) => {
        const route = 'people/list';
        if (args) {
          const urlParams = formatUrlParams(args);
          return `${route}?${urlParams}`;
        }
        return route;
      },
      transformResponse: (
        response: ObjectToSnake<PaginationResponse<Person>>,
      ) => objectToCamel(response),
      providesTags: (response) => (response?.results ? response.results.map((person) => ({
        type: 'people',
        id: person.id,
      })) : ['people']),
    }),
    getPerson: builder.query<Person, number>({
      query: (id: number) => `people/${id}`,
      transformResponse: (response: ObjectToSnake<Person>) => objectToCamel(response),
      providesTags: (person) => (person ? [{ type: 'people', id: person?.id }] : ['people']),
    }),
    createPerson: builder.mutation<Person, CreatePerson>({
      query: (person) => {
        const body = objectToSnake(person);
        return ({
          url: 'people/',
          method: 'POST',
          body,
        });
      },
      invalidatesTags: (result) => (result ? [{ type: 'people', id: result.id }] : []),
      transformResponse: (response: ObjectToSnake<Person>) => objectToCamel(response),
    }),
    updatePerson: builder.mutation<Person, Partial<UpdatePerson> & {
      id: number,
    }>({
      query: (args) => {
        const { id, ...update } = args;
        const body = objectToSnake(update);
        return ({
          url: `people/${id.toString()}`,
          method: 'PATCH',
          body,
        });
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'people', id: arg.id },
        ...arg.packId ? [{ type: 'packs' as const, id: arg.packId }] : [],
      ],
      transformResponse: (response: ObjectToSnake<Person>) => objectToCamel(response),
    }),
    deletePerson: builder.mutation({
      query: (id: number) => ({
        url: `people/${id}`,
        method: 'DELETE',
      }),
      // TODO: Invalidate the pack for a person when they are deleted
      invalidatesTags: (result, error, arg) => [{ type: 'people', id: arg }],
    }),
    getAttendeeLocations: builder.query<PersonLocation[], PeopleQuery>({
      query: ({ packId, editionId, attendeesOnly }) => {
        if (packId && editionId) {
          throw new Error('Cannot provide both packId and editionId');
        }

        const filterItems = [
          { field: 'latitude', operator: 'isNotEmpty' },
          { field: 'longitude', operator: 'isNotEmpty' },
        ];

        const params = [
          ...attendeesOnly ? ['attendees_only=true'] : [],
          ...packId ? [`pack_id=${packId}`] : [],
          ...editionId ? [`edition_id=${editionId}`] : [],
          `filter_items=${JSON.stringify(filterItems)}`,
        ].join('&');
        return ({
          url: `people/list?${params}`,
          headers: {
            'X-Fields': 'results{id,latitude,longitude},*',
          },
        });
      },
      transformResponse: (
        response: ObjectToSnake<PaginationResponse<PersonLocation>>,
      ) => objectToCamel(response.results),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPeopleQuery,
  useGetPersonQuery,
  useCreatePersonMutation,
  useUpdatePersonMutation,
  useDeletePersonMutation,
  useGetAttendeeLocationsQuery,
} = extendedApi;

import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import { SnackbarKey, closeSnackbar } from 'notistack';

const DismissAlertButton = (snackbarId: SnackbarKey): JSX.Element => {
  const onClick = (): void => closeSnackbar(snackbarId);
  return (
    <IconButton onClick={onClick} aria-label="clear">
      <ClearIcon />
    </IconButton>
  );
};

export default DismissAlertButton;

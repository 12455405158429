import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { FunctionComponent } from 'react';
import CardFooter from '../../../../../components/CardFooter';
import { FormGrid } from '../../../../../components/FormGrid';
import { FormProps } from '../../../../../components/forms/types';
import { isAdult, isLeaderOrAdult } from '../../../../../helpers/person';
import { getOnsiteStatusLabel, isValidOnsiteStatus, onsiteStatuses } from '../../../../../models/onsiteStatus';
import { Pack } from '../../../../../models/pack';
import { UpdatePerson } from '../../../../../models/person';
import { getPersonStatusLabel, isValidPersonStatus, personStatuses } from '../../../../../models/personStatus';
import { useGetPacksQuery } from '../../../../../state/protectedApi/packs';

const PersonForm: FunctionComponent<FormProps<UpdatePerson>> = (props) => {
  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = props;

  const {
    data: packs,
    isLoading: packsLoading,
  } = useGetPacksQuery();

  return (
    <form onSubmit={handleSubmit} noValidate>
      <FormGrid columns={2}>
        <FormControl
          error={touched.status && Boolean(errors.status)}
          disabled={isSubmitting}
        >
          <FormLabel id="person-status-label">
            Status
          </FormLabel>
          <RadioGroup
            aria-labelledby="person-status-label"
            value={values.status}
            onChange={async (event) => {
              if (isValidPersonStatus(event.target.value)) {
                await setFieldValue('status', event.target.value);
              }
            }}
            onBlur={() => setFieldTouched('status')}
          >
            {personStatuses.map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={getPersonStatusLabel(option)}
              />
            ))}
            <FormHelperText>
              {(touched.status && errors.status) || ' '}
            </FormHelperText>
          </RadioGroup>
        </FormControl>
        <FormControl error={touched.onsite && Boolean(errors.onsite)}>
          <FormLabel id="onsite-status-label">Onsite</FormLabel>
          <RadioGroup
            aria-labelledby="onsite-status-label"
            value={values.onsite}
            onChange={async (event) => {
              if (isValidOnsiteStatus(event.target.value)) {
                await setFieldValue('onsite', event.target.value);
              }
            }}
            onBlur={() => setFieldTouched('onsite')}
          >
            {onsiteStatuses.map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={getOnsiteStatusLabel(option)}
              />
            ))}
            <FormHelperText>
              {(touched.onsite && errors.onsite) || ' '}
            </FormHelperText>
          </RadioGroup>
        </FormControl>
      </FormGrid>
      <FormGrid columns={2}>
        <Autocomplete
          options={packs ?? []}
          loading={packsLoading}
          getOptionLabel={(option: Pack) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Pack"
              helperText=" "
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {packsLoading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          value={packs?.find((pack) => pack.id === values.packId) ?? null}
          onChange={async (event, value) => {
            await setFieldValue('packId', value?.id ?? null);
          }}
        />
        <FormControl
          error={touched.packBookingContact && Boolean(errors.packBookingContact)}
          disabled={isSubmitting}
        >
          <FormLabel id="pack-booking-contact-label">
            Are they a booking contact for their Pack?
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="pack-booking-contact-label"
            value={values.packBookingContact}
            onChange={async (event) => {
              await setFieldValue('packBookingContact', event.target.value === 'true');
            }}
            onBlur={() => setFieldTouched('packBookingContact')}
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
            <FormHelperText>
              {(touched.packBookingContact && errors.packBookingContact) || ' '}
            </FormHelperText>
          </RadioGroup>
        </FormControl>
      </FormGrid>
      <FormGrid columns={3}>
        <TextField
          label="First name"
          value={values.firstName}
          onChange={async (event) => {
            await setFieldValue('firstName', event.target.value);
          }}
          onBlur={() => setFieldTouched('firstName')}
          helperText={(touched.firstName && errors.firstName) || ' '}
          error={touched.firstName && Boolean(errors.firstName)}
          disabled={isSubmitting}
        />
        <TextField
          label="Known as (optional)"
          value={values.knownAs}
          onChange={async (event) => {
            await setFieldValue('knownAs', event.target.value);
          }}
          onBlur={() => setFieldTouched('knownAs')}
          helperText={(touched.knownAs && errors.knownAs) || ' '}
          error={touched.knownAs && Boolean(errors.knownAs)}
          disabled={isSubmitting}
        />
        <TextField
          label="Last name"
          value={values.lastName}
          onChange={async (event) => {
            await setFieldValue('lastName', event.target.value);
          }}
          onBlur={() => setFieldTouched('lastName')}
          helperText={(touched.lastName && errors.lastName) || ' '}
          error={touched.lastName && Boolean(errors.lastName)}
          disabled={isSubmitting}
        />
      </FormGrid>
      {isLeaderOrAdult(values) && (
        <FormGrid columns={2}>
          <TextField
            label="Mobile"
            value={values.mobile}
            onChange={async (event) => {
              await setFieldValue('mobile', event.target.value);
            }}
            onBlur={() => setFieldTouched('mobile')}
            helperText={(touched.mobile && errors.mobile) || ' '}
            error={touched.mobile && Boolean(errors.mobile)}
            disabled={isSubmitting}
            inputProps={{ inputMode: 'tel' }}
          />
          <TextField
            label="Email"
            value={values.email}
            onChange={async (event) => {
              await setFieldValue('email', event.target.value);
            }}
            onBlur={() => setFieldTouched('email')}
            helperText={(touched.email && errors.email) || ' '}
            error={touched.email && Boolean(errors.email)}
            disabled={isSubmitting}
            inputProps={{ inputMode: 'email' }}
          />
        </FormGrid>
      )}
      {isAdult(values) && (
        <FormGrid columns={2}>
          <TextField
            label="Scouting appointment"
            value={values.scoutingAppointment}
            onChange={async (event) => {
              await setFieldValue('scoutingAppointment', event.target.value);
            }}
            onBlur={() => setFieldTouched('scoutingAppointment')}
            helperText={(touched.scoutingAppointment && errors.scoutingAppointment) || ' '}
            error={touched.scoutingAppointment && Boolean(errors.scoutingAppointment)}
            disabled={isSubmitting}
          />
          <TextField
            label="Membership number"
            value={values.membershipNumber}
            onChange={async (event) => {
              await setFieldValue('membershipNumber', event.target.value);
            }}
            onBlur={() => setFieldTouched('membershipNumber')}
            helperText={(touched.membershipNumber && errors.membershipNumber) || ' '}
            error={touched.membershipNumber && Boolean(errors.membershipNumber)}
            disabled={isSubmitting}
          />
        </FormGrid>
      )}
      <h4>Postal Address</h4>
      <TextField
        label="Street"
        value={values.addressStreet}
        onBlur={() => setFieldTouched('addressStreet')}
        onChange={async (event) => {
          await setFieldValue('addressStreet', event.target.value);
        }}
        error={touched.addressStreet && Boolean(errors.addressStreet)}
        helperText={(touched.addressStreet && errors.addressStreet) || ' '}
        multiline
        minRows={3}
      />
      <FormGrid columns={2}>
        <TextField
          label="Town"
          value={values.addressTown}
          onBlur={() => setFieldTouched('addressTown')}
          onChange={async (event) => {
            await setFieldValue('addressTown', event.target.value);
          }}
          error={touched.addressTown && Boolean(errors.addressTown)}
          helperText={(touched.addressTown && errors.addressTown) || ' '}
        />
        <TextField
          label="Postcode"
          value={values.addressPostcode}
          onBlur={() => setFieldTouched('addressPostcode')}
          onChange={async (event) => {
            await setFieldValue('addressPostcode', event.target.value);
          }}
          error={touched.addressPostcode && Boolean(errors.addressPostcode)}
          helperText={(touched.addressPostcode && errors.addressPostcode) || ' '}
        />
      </FormGrid>

      <CardFooter>
        <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </CardFooter>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </form>
  );
};

export default PersonForm;

import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Bar,
  CartesianGrid,
  Legend,
  BarChart as LibBarChart,
  XAxis,
  YAxis,
} from 'recharts';
import { ContentType } from 'recharts/types/component/Tooltip';
import DashboardHorizontalBarChart from '../../../../../components/DashboardHorizontalBarChart';
import { FormGrid } from '../../../../../components/FormGrid';
import { eventPhaseOrEarlier, eventPhaseOrLater } from '../../../../../helpers/eventConfig';
import { ByPersonStatus, ByPersonStatusWithTotal } from '../../../../../models/pack';
import {
  PackAttendeeStatus,
  getPersonStatusLabel,
  getPluralPersonStatusLabel,
  isValidPackAttendeeStatus,
  packAttendeeStatuses,
} from '../../../../../models/personStatus';
import { Statistics } from '../../../../../models/statistics';
import routes from '../../../../../routes';
import { useGetEventConfigQuery } from '../../../../../state/publicApi';
import colours from '../../../../../theme/colours';
import * as Styled from './styles';

type TooltipProps = {
  active?: boolean
  label: string
  payload: {
    fill: string
    dataKey: string
    name: string
    color: string
    value: number
    payload: {
      id: number
      reference: string
      editionId?: number
      bookings: ByPersonStatusWithTotal
      registrations: ByPersonStatusWithTotal
      missing: ByPersonStatusWithTotal
      excessive: ByPersonStatusWithTotal
    }
  }[]
};

const getKeyForStatus = (status: PackAttendeeStatus): keyof ByPersonStatus => {
  switch (status) {
    case 'Cub':
      return 'cubs';
    case 'YoungLeader':
      return 'youngLeaders';
    case 'Leader':
      return 'leaders';
    case 'OtherChild':
      return 'otherChildren';
    default:
      throw new Error('Status not implemented');
  }
};

const CustomTooltip = (props: TooltipProps): ContentType<number, string> | null => {
  const { active, label, payload } = props;
  if (active && payload && payload.length) {
    const nestedPayload = payload[0].payload;

    const data = packAttendeeStatuses.map(
      (status) => {
        const dataKey = getKeyForStatus(status);
        const excessive = nestedPayload.excessive[dataKey];
        return ({
          name: getPluralPersonStatusLabel(status),
          registrations: nestedPayload.registrations[dataKey] - excessive,
          excessive,
          missing: nestedPayload.missing[dataKey],
        });
      },
    ).filter((item) => (item.registrations || item.excessive || item.missing));

    const graphHeight = (data.length + 2) * 40;
    return (
      <Styled.TooltipContainer>
        <Styled.TooltipTitle>{label}</Styled.TooltipTitle>
        <div>
          <LibBarChart
            width={300}
            height={graphHeight}
            data={data}
            layout="vertical"
            barGap={1}
            maxBarSize={20}
            margin={{
              top: 20,
            }}
          >
            <CartesianGrid
              horizontal={false}
              stroke={colours.blue}
              strokeWidth={0.5}
            />
            <XAxis
              type="number"
              orientation="top"
              tickMargin={10}
              stroke={colours.blue}
              strokeWidth={0.5}
            />
            <YAxis
              type="category"
              dataKey="name"
              width={75}
              stroke={colours.blue}
              interval={0}
            />
            <Legend
              verticalAlign="top"
              height={36}
            />
            <Bar
              name="Legitimate"
              dataKey="registrations"
              animationDuration={1000}
              fill={colours.green}
              stackId="a"
            />
            <Bar
              name="Excessive"
              dataKey="excessive"
              animationDuration={1000}
              fill={colours.orange}
              stackId="a"
            />
            <Bar
              name="Missing"
              dataKey="missing"
              animationDuration={1000}
              fill={colours.purple}
              stackId="a"
            />
          </LibBarChart>
        </div>
      </Styled.TooltipContainer>
    );
  }

  return null;
};

type MissingRegistrationsProps = {
  stats?: Statistics
  isLoading: boolean
};

type OrderBy = 'legitimate' | 'missing' | 'excessive';

const isValidOrderBy = (value: string): value is OrderBy => ['legitimate', 'missing', 'excessive'].includes(value);

const MissingRegistrations: FC<MissingRegistrationsProps> = (props) => {
  const { stats, isLoading } = props;
  const { data: eventConfig } = useGetEventConfigQuery();
  const [selectedStatus, setSelectedStatus] = useState<PackAttendeeStatus | ''>('');
  const [orderBy, setOrderBy] = useState<OrderBy | ''>('');
  const navigate = useNavigate();

  const keySelection = selectedStatus === '' ? 'total' : getKeyForStatus(selectedStatus);

  const controls = (
    <FormGrid columns={2} style={{ width: '95%' }}>
      <FormControl fullWidth sx={{ textAlign: 'left' }}>
        <InputLabel id="status-filter-label">Status</InputLabel>
        <Select
          labelId="status-filter-label"
          id="status-filter"
          value={selectedStatus}
          label="Status"
          onChange={(event) => {
            if (isValidPackAttendeeStatus(event.target.value)) {
              setSelectedStatus(event.target.value);
            } else {
              setSelectedStatus('');
            }
          }}
        >
          <MenuItem>
            <em>All</em>
          </MenuItem>
          {packAttendeeStatuses?.map((status) => (
            <MenuItem key={status} value={status}>{getPersonStatusLabel(status)}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ textAlign: 'left' }}>
        <InputLabel id="order-packs-label">Order by</InputLabel>
        <Select
          labelId="order-label"
          id="order-packs"
          value={orderBy}
          label="Order by"
          onChange={(event) => {
            if (event.target.value && isValidOrderBy(event.target.value)) {
              setOrderBy(event.target.value);
            } else {
              setOrderBy('');
            }
          }}
        >
          <MenuItem>
            <em>Reference</em>
          </MenuItem>
          <MenuItem value="legitimate">Legitimate</MenuItem>
          <MenuItem value="excessive">Excessive</MenuItem>
          <MenuItem value="missing">Missing</MenuItem>
        </Select>
      </FormControl>
    </FormGrid>
  );

  const filteredData = stats?.registered.packs?.filter(
    (item) => (eventConfig && (
      eventPhaseOrEarlier(eventConfig, 'Registration')
      || (item.missing.total > 0 && eventPhaseOrEarlier(eventConfig, 'Allocation'))
      || (item.excessive.total > 0 && eventPhaseOrEarlier(eventConfig, 'Allocation'))
      || item.registrationStatus === 'Open')),
  ).map(
    (item) => ({
      ...item,
      legitimate: {
        cubs: item.registrations.cubs - item.excessive.cubs,
        youngLeaders: item.registrations.youngLeaders - item.excessive.youngLeaders,
        leaders: item.registrations.leaders - item.excessive.leaders,
        otherChildren: item.registrations.otherChildren - item.excessive.otherChildren,
        total: item.registrations.total - item.excessive.total,
      },
    }),
  ) ?? [];

  if (!eventConfig || (filteredData.length === 0 && eventPhaseOrLater(eventConfig, 'Allocation'))) {
    return null;
  }

  const sortedData = filteredData.slice().sort(
    (a, b) => {
      if (orderBy === '') {
        return 1;
      }
      return b[orderBy][keySelection] - a[orderBy][keySelection];
    },
  );

  const navigateOnClick = (data: { id: number }): void => {
    navigate(routes.packs.detail(data.id));
  };

  const legitimateBar = (
    <Bar
      key="legitimate"
      name="Legitimate"
      dataKey={`legitimate.${keySelection}`}
      animationDuration={1000}
      fill={colours.green}
      stackId="a"
      onClick={navigateOnClick}
    />
  );

  const missingBar = (
    <Bar
      key="missing"
      name="Missing"
      dataKey={`missing.${keySelection}`}
      animationDuration={1000}
      fill={colours.purple}
      stackId="a"
      onClick={navigateOnClick}
    />
  );

  const excessiveBar = (
    <Bar
      key="excessive"
      name="Excessive"
      dataKey={`excessive.${keySelection}`}
      animationDuration={1000}
      fill={colours.orange}
      stackId="a"
      onClick={navigateOnClick}
    />
  );

  const bars = [
    ...orderBy === 'excessive' ? [excessiveBar] : [],
    ...orderBy === 'missing' ? [missingBar] : [],
    legitimateBar,
    ...orderBy === 'excessive' ? [] : [excessiveBar],
    ...orderBy === 'missing' ? [] : [missingBar],
  ];

  return (
    <DashboardHorizontalBarChart
      title="Registrations"
      data={sortedData}
      yAxisDataKey="reference"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tooltipContent={CustomTooltip}
      controls={controls}
      noDataMessage="No missing registrations!"
      isLoading={isLoading}
    >
      <Legend
        verticalAlign="top"
        height={36}
      />
      {bars}
    </DashboardHorizontalBarChart>
  );
};

export default MissingRegistrations;

import styled from 'styled-components';
import { responsiveSpacing } from '../../helpers/responsiveSpacing';
import { responsiveWidth } from '../../helpers/responsiveWidth';

export const Card = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: 20px;
  ${responsiveWidth({ desktop: 8, tablet: 6, mobile: 4 })}
  ${responsiveSpacing('margin', '0', 'auto', 'l')}
  ${responsiveSpacing('padding', 'l')}
`;

import { Edition } from '../models/edition';
import { EventConfig } from '../models/eventConfig';
import { Phase } from '../models/phase';
import { getPhaseOrder } from './phases';

export const getEditionName = (eventConfig: EventConfig, edition: Edition): string => `${eventConfig.name} ${edition.name}`;

export const getEditionDates = (edition: Edition): string => {
  const startDate = new Date(edition.start);
  const endDate = new Date(edition.end);

  const isSameMonth = startDate.getMonth() === endDate.getMonth();
  const startDateFormatted = startDate.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: isSameMonth ? undefined : 'long',
  });
  const endDateFormatted = endDate.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  return `${startDateFormatted} to ${endDateFormatted}`;
};

export const getEditionSummary = (eventConfig: EventConfig, edition: Edition): string => {
  const editionName = getEditionName(eventConfig, edition);
  const editionDates = getEditionDates(edition);
  return `${editionName}: ${editionDates}`;
};

export const getFirstEdition = (
  editions: Edition[],
): Edition | undefined => editions.slice().sort((a, b) => a.start.localeCompare(b.start))?.[0];

export const getLastEdition = (
  editions: Edition[],
): Edition | undefined => editions.slice().sort((a, b) => b.start.localeCompare(a.start))?.[0];

export const sortEditions = (
  editions: Edition[],
): Edition[] => editions.sort((a, b) => a.start.localeCompare(b.start));

export const editionPhaseOrLater = (edition: Edition | undefined, phase: Phase): boolean => {
  if (!edition) {
    return false;
  }
  const current = getPhaseOrder(edition.phase);
  const test = getPhaseOrder(phase);
  return test <= current;
};

import {
  Chip, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { FC } from 'react';
import { getFullName } from '../../helpers/person';
import { ReviewTagSummary } from '../../models/reviewTagSummary';
import routes from '../../routes';
import RouterLink from '../RouterLink';
import Table from '../Table';
import * as Styled from './styles';

type ReviewTagsTableProps = {
  data: ReviewTagSummary
};

const ReviewTagsTable: FC<ReviewTagsTableProps> = (props) => {
  const { data } = props;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <strong>Tags</strong>
          </TableCell>
          <TableCell>
            <strong>People</strong>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((summary) => (
          <TableRow key={`${summary.tags.map((t) => t.id).join('-')}-${summary.people.map((p) => p.id).join('-')}`}>
            <TableCell>
              {summary.tags.map((tag) => (
                <Chip key={tag.id} label={tag.text} sx={{ marginRight: '10px' }} />
              ))}
            </TableCell>
            <TableCell>
              {summary.people.length === 0 ? 'None' : (
                <Styled.OrderedList>
                  {summary.people.map((person) => (
                    <RouterLink key={person.id} to={routes.people.detail(person.id)}>
                      <li>
                        {person.packReference ? `${getFullName(person)}, ${person.packReference}` : getFullName(person)}
                      </li>
                    </RouterLink>
                  ))}
                </Styled.OrderedList>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ReviewTagsTable;

import styled from 'styled-components';
import { mediaQuery } from '../../helpers/responsive';

type FormGridProps = {
  columns?: number
  noGap?: boolean
  noBottomMargin?: boolean
};

export const FormGrid = styled.div<FormGridProps>`
  display: grid;

  ${({ noGap }) => !noGap && `
    gap: 15px;
  `}
  
  ${({ noBottomMargin }) => !noBottomMargin && `
    margin-bottom: 15px;
  `}
  
  ${({ columns }) => columns
    && mediaQuery.medium`
      grid-template-columns: repeat(${columns}, 1fr);
    `}
`;

export const FormGridWithTopPadding = styled(FormGrid)`
  padding-top: 5px;
`;

export const FormGridWithLargeTopPadding = styled(FormGrid)`
  padding-top: 20px;
`;

import { TableCell } from '@mui/material';
import styled from 'styled-components';
import { responsiveSpacing } from '../../helpers/responsiveSpacing';

export const CheckBoxTableCell = styled(TableCell)`
  padding: 0;
`;

export const StickyWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 10000;
`;

export const AlertWrapper = styled.div`
  ${responsiveSpacing('margin', 's', '0')}
`;

export const NameWrapper = styled.span`
  margin-bottom: 8px;
`;

import { v4 as uuid } from 'uuid';
import { NewRegistration } from '../../../state/registration/types';

const sanitiseOnSubmit = (rawValues: NewRegistration): NewRegistration => {
  const { meta: { registeringSelf } } = rawValues;

  const attendee = {
    ...rawValues.attendee,
  };
  if (rawValues.meta.registeringSelf) {
    attendee.status = 'Leader';
  } else if (attendee.status === 'Leader') {
    attendee.status = null;
  }

  const mainContact = {
    ...rawValues.mainContact,
    firstName: rawValues.mainContact.firstName.trim(),
    knownAs: rawValues.mainContact.knownAs.trim(),
    lastName: rawValues.mainContact.lastName.trim(),
    mobile: rawValues.mainContact.mobile.trim(),
    relationship: registeringSelf ? '' : rawValues.mainContact.relationship.trim(),
  };

  const contacts = [...rawValues.contacts];
  if (rawValues.meta.registeringSelf && rawValues.contacts.length === 0) {
    contacts.push({
      key: uuid(),
      firstName: '',
      knownAs: '',
      lastName: '',
      relationship: '',
      mobile: '',
      email: '',
      emergency: true,
      attending: null,
      updates: false,
    });
  }

  return {
    ...rawValues, attendee, mainContact, contacts,
  };
};

export default sanitiseOnSubmit;

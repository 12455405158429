import { TableRow } from '@mui/material';
import { Review } from '../../../models/registration';
import { ReviewStatus } from '../../../models/reviewStatus';
import { ReviewTag } from '../../../models/reviewTag';
import { ReviewType } from '../../../models/reviewType';
import ReviewTagsSelector from '../../ReviewTagsSelector';
import TableCell from '../../TableCell';
import ReviewStatusSelector from '../ReviewStatusSelector';

type ReviewTableRowProps = {
  name: string
  type: ReviewType
  review: Review
  disabled?: boolean
  onTagCreation?: () => void
  onChange: (update: Review) => void
  buttonOrientation: 'vertical' | 'horizontal'
};

const ReviewTableRow = (props: ReviewTableRowProps): JSX.Element => {
  const {
    name,
    review,
    type,
    disabled = false,
    buttonOrientation,
    onTagCreation,
    onChange,
  } = props;

  const onStatusChange = (
    newStatus: ReviewStatus,
  ): void => onChange({ ...review, status: newStatus });

  const onTagsChange = (
    newTags: ReviewTag[],
  ): void => onChange({ ...review, tags: newTags });

  return (
    <TableRow>
      <TableCell>
        <strong>{name}</strong>
      </TableCell>
      <ReviewStatusSelector
        status={review.status}
        disabled={disabled}
        orientation={buttonOrientation}
        onChange={onStatusChange}
      />
      <TableCell>
        <ReviewTagsSelector
          tags={review.tags ?? []}
          onChange={onTagsChange}
          onTagCreation={onTagCreation}
          type={type}
          disabled={disabled}
        />
      </TableCell>
    </TableRow>
  );
};

export default ReviewTableRow;

import { Button } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import RouterLink from '../../../components/RouterLink';
import { numberCellClassComparison, numberCellClassIfPositive } from '../../../helpers/dataGrid';
import { Activity } from '../../../models/activity';
import { activityCategories } from '../../../models/activityCategory';
import {
  ActivityDelivery,
  activityDeliveries,
  getActivityDeliveryLabel,
} from '../../../models/activityDelivery';
import { ActivitySession } from '../../../models/activitySession';
import routes from '../../../routes';
import { loadPdfDocument } from '../../../routes/documents';

// TODO: fix the aggregation of the number columns
// TODO: add options and single select type to delivery and category columns
const columns = (sessions: ActivitySession[], editionId: number): GridColDef[] => {
  const sessionsForEdition = sessions.slice().filter((session) => session.editionId === editionId);
  return [
    {
      field: 'id',
      headerName: 'ID',
      width: 50,
      type: 'number',
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 175,
      renderCell: (params: GridRenderCellParams<Activity>) => (
        <RouterLink to={routes.activities.adminDetail(params.row.id)}>{params.value}</RouterLink>
      ),
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 100,
      type: 'singleSelect',
      valueOptions: activityCategories.slice(),
    },
    {
      field: 'delivery',
      headerName: 'Delivery',
      width: 100,
      valueGetter: (value: ActivityDelivery) => value && getActivityDeliveryLabel(value),
      type: 'singleSelect',
      valueOptions: activityDeliveries.map(getActivityDeliveryLabel),
    },
    {
      field: 'targetParticipants',
      headerName: 'Target (P)',
      width: 150,
      type: 'number',
    },
    ...sessionsForEdition.map((session) => ({
      field: `participantsSession${session.id}`,
      headerName: `Session ${session.order} (P)`,
      width: 150,
      type: 'number' as const,
      valueGetter: (
        value: never,
        row: Activity,
      ) => (row.sessions ? row.sessions[session.id.toString()].participants : 0),
      cellClassName: numberCellClassComparison('targetParticipants', { greaterThan: 'warning-cell' }),
    })),
    ...sessionsForEdition.map((session) => ({
      field: `spareParticipantCapacitySession${session.id}`,
      headerName: `Session ${session.order} (C)`,
      width: 150,
      type: 'number' as const,
      valueGetter: (value: never, row: Activity) => (
        row.targetParticipants ?? 0
      ) - (row.sessions ? row.sessions[session.id.toString()].participants : 0),
      cellClassName: numberCellClassIfPositive('success-cell'),
    })),
    {
      field: 'spareParticipantCapacityTotal',
      headerName: 'Total (C)',
      type: 'number',
      valueGetter: (value: never, row: Activity) => {
        // TODO consider the total capacity if the activity is not active in certain sessions
        const target = (row.targetParticipants ?? 0) * sessionsForEdition.length;
        if (row.sessions === undefined) {
          return target;
        }
        const sumAllocated = Object.values(row.sessions).reduce(
          (current, next) => {
            if (next.editionId !== editionId) {
              return current;
            }
            return current + next.participants;
          },
          0,
        );
        return target - sumAllocated;
      },
    },
    {
      field: 'targetLeaders',
      headerName: 'Target (L)',
      width: 150,
      type: 'number',
    },
    ...sessionsForEdition.map((session) => ({
      field: `leadersSession${session.id}`,
      headerName: `Session ${session.order} (L)`,
      width: 150,
      type: 'number' as const,
      valueGetter: (value: never, row: Activity) => (
        row.sessions ? row.sessions[session.id.toString()].leaders : 0
      ),
      cellClassName: numberCellClassComparison('targetLeaders', {
        greaterThan: 'success-cell',
        lessThan: 'warning-cell',
      }),
    })),
    {
      field: 'targetAdultLeaders',
      headerName: 'Target (AL)',
      width: 150,
      type: 'number',
    },
    ...sessionsForEdition.map((session) => ({
      field: `adultLeadersSession${session.id}`,
      headerName: `Session ${session.order} (AL)`,
      width: 150,
      type: 'number' as const,
      valueGetter: (value: never, row: Activity) => (
        row.sessions ? row.sessions[session.id.toString()].adultLeaders : 0
      ),
      cellClassName: numberCellClassComparison('targetAdultLeaders', {
        greaterThan: 'success-cell',
        lessThan: 'warning-cell',
      }),
    })),
    {
      field: 'qrSign',
      headerName: 'QR Sign',
      filterable: false,
      sortable: false,
      width: 75,
      renderCell: (params: GridRenderCellParams<Activity>) => (
        <Button
          onClick={() => loadPdfDocument(routes.documents.activity.qrSign(params.row.id))}
          variant="text"
        >
          QR Sign
        </Button>
      ),
    },
    {
      field: 'public',
      headerName: 'Public',
      filterable: false,
      sortable: false,
      width: 75,
      renderCell: (params: GridRenderCellParams<Activity>) => (
        <RouterLink to={routes.activities.detail(params.row.id, true)}>Public</RouterLink>
      ),
    },
  ];
};

export default columns;

import { objectToCamel, objectToSnake } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import {
  BulkUpdatePackRequest,
  BulkUpdatePackResponse,
  Pack,
  UpdatePack,
} from '../../models/pack';
import { PackBookingsStatus } from '../../models/packBookingStatus';
import { protectedApi } from './index';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getPack: builder.query<Pack, number>({
      query: (packId) => `packs/${packId}`,
      transformResponse: (response: ObjectToSnake<Pack>) => objectToCamel(response),
      providesTags: (pack) => (pack ? [{ type: 'packs', id: pack.id }] : []),
    }),
    getPacks: builder.query<Pack[], { bookingStatus?: PackBookingsStatus } | void>({
      query: (args) => {
        const urlParams = args && args.bookingStatus ? `?booking_status=${args.bookingStatus}` : '';
        return `packs/${urlParams}`;
      },
      transformResponse: (response: ObjectToSnake<Pack[]>) => objectToCamel(response),
      providesTags: (packs) => (packs && packs.length
        ? packs.map((pack) => ({ type: 'packs', id: pack.id }))
        : ['packs']
      ),
    }),
    bulkUpdatePacks: builder.mutation<BulkUpdatePackResponse, BulkUpdatePackRequest>({
      query: (body) => ({
        url: 'packs/',
        method: 'PATCH',
        body: objectToSnake(body),
        timeout: 300000,
      }),
      invalidatesTags: ['packs', 'ballotStats'],
      transformResponse: (
        response: ObjectToSnake<BulkUpdatePackResponse>,
      ) => objectToCamel(response),
    }),
    updatePack: builder.mutation<Pack, {
      id: number, regionId: number | null
    } & Partial<UpdatePack>>({
      query: (args) => {
        const { id, regionId, ...update } = args;
        const body = objectToSnake(update);
        return ({
          url: `packs/${id}`,
          method: 'PATCH',
          body,
        });
      },
      invalidatesTags: (result, error, arg) => [{ type: 'packs', id: arg.id }],
      transformResponse: (response: ObjectToSnake<Pack>) => objectToCamel(response),
    }),
    deletePack: builder.mutation({
      query: (id: number) => ({
        url: `packs/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'packs', id: arg }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPackQuery,
  useGetPacksQuery,
  useBulkUpdatePacksMutation,
  useUpdatePackMutation,
  useDeletePackMutation,
} = extendedApi;

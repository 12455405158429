import styled from 'styled-components';

export const ParagraphWithinTable = styled.p`
  :first-of-type {
    margin-top: 0;
  }
  :last-of-type {
    margin-bottom: 0;
  }
`;

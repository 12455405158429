import { FC } from 'react';
import DashboardPieChart from '../../../../../components/DashboardPieChart';
import { toSentenceCase } from '../../../../../helpers/strings';
import { Statistics } from '../../../../../models/statistics';

type RegistrationsBySandwichProps = {
  stats?: Statistics
};

const RegistrationsBySandwich: FC<RegistrationsBySandwichProps> = (props) => {
  const { stats } = props;

  const counts = stats && Object.entries(stats.sandwiches ?? {})
    .map((option) => ({
      name: toSentenceCase(option[0]),
      count: option[1],
    }));

  const length = counts?.reduce((acc, item) => acc + item.name.length, 0) ?? 0;

  return (
    <DashboardPieChart
      title="Sandwiches"
      data={counts}
      tall={length > 70}
    />
  );
};

export default RegistrationsBySandwich;

import styled from 'styled-components';
import { responsiveWidth } from '../../helpers/responsiveWidth';

type ColumnProps = {
  mobile?: number
  tablet?: number
  desktop?: number
};

const Column = styled.div<ColumnProps>`
  ${(props) => responsiveWidth({
    desktop: props.desktop ?? 12,
    tablet: props.tablet ?? 8,
    mobile: props.mobile ?? 4,
  })}
`;

export default Column;

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import RouterLink from '../../../components/RouterLink';
import { eventPhaseOrLater } from '../../../helpers/eventConfig';
import { userHasRole } from '../../../helpers/user';
import { getOnsiteStatusLabel, onsiteStatuses } from '../../../models/onsiteStatus';
import { Person, PersonWithReviewStatus, ReviewSummary } from '../../../models/person';
import { personStatusOptions } from '../../../models/personStatus';
import { reviewStatusOptions } from '../../../models/reviewStatus';
import { ReviewType } from '../../../models/reviewType';
import { staffTeamOptions } from '../../../models/staffTeam';
import { subcamps } from '../../../models/subcamp';
import routes from '../../../routes';
import { useGetPacksQuery } from '../../../state/protectedApi/packs';
import { useGetCurrentUserQuery } from '../../../state/protectedApi/user';
import { useGetEventConfigQuery } from '../../../state/publicApi';
import { useGetDistrictsQuery, useGetRegionsQuery } from '../../../state/publicApi/reference';

const getReviewForType = (
  type: ReviewType,
  person: PersonWithReviewStatus,
): ReviewSummary | undefined => {
  if (type === 'Admin') {
    return person.adminReview;
  }
  if (type === 'Compass') {
    return person.compassReview;
  }
  if (type === 'Activities') {
    return person.activitiesReview;
  }
  if (type === 'Catering') {
    return person.cateringReview;
  }
  if (type === 'Subcamp') {
    return person.subcampReview;
  }
  if (type === 'PackLeader') {
    return person.packLeaderReview;
  }
  return undefined;
};

const getShortHandName = (type: ReviewType): string => {
  if (type === 'Admin') {
    return 'Core';
  }
  if (type === 'PackLeader') {
    return 'Pack';
  }
  return type;
};

const getReviewColumn = (
  type: ReviewType,
  manyMode: boolean,
): GridColDef<PersonWithReviewStatus>[] => {
  const camelCaseType = type.charAt(0).toLowerCase() + type.slice(1);
  const statusHeading = manyMode ? `Status (${getShortHandName(type)})` : 'Status';
  const tagsHeading = manyMode ? `Tags (${getShortHandName(type)})` : 'Tags';

  return [
    {
      field: `${camelCaseType}ReviewStatus`,
      headerName: statusHeading,
      width: 150,
      type: 'singleSelect',
      valueOptions: reviewStatusOptions,
      valueGetter: (
        value: never,
        row: PersonWithReviewStatus,
      ) => getReviewForType(type, row)?.status,
    },
    {
      field: `${camelCaseType}ReviewTags`,
      headerName: tagsHeading,
      width: 250,
      filterable: false,
      sortable: false,
      valueFormatter: (
        value: never,
        row: PersonWithReviewStatus,
      ) => getReviewForType(type, row)?.tags.map((tag) => tag.text).sort().join(' + '),
    },
  ];
};

const usePeopleGridColumns = (): GridColDef<PersonWithReviewStatus>[] => {
  const { data: user } = useGetCurrentUserQuery();
  const { data: eventConfig } = useGetEventConfigQuery();
  const { data: packs } = useGetPacksQuery();
  const { data: districts } = useGetDistrictsQuery();
  const { data: regions } = useGetRegionsQuery();

  const showAdminReview = userHasRole(user, 'Admin');
  const showCompassReview = userHasRole(user, 'Admin');
  const showActivitiesReview = userHasRole(user, ['Admin', 'Activities']);
  const showCateringReview = userHasRole(user, ['Admin', 'Catering']);
  const showSubcampReview = userHasRole(user, ['Admin', 'Subcamp']);
  const showPackLeaderReview = userHasRole(user, ['Admin', 'PackLeader']);

  const showingMultipleReviews = userHasRole(user, 'Admin');

  if (!eventConfig || !packs || !districts || !regions || !user) {
    return [];
  }

  return [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      type: 'number' as const,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      renderCell: (params: GridRenderCellParams<PersonWithReviewStatus>) => (
        <RouterLink to={routes.people.detail(params.row.id)}>
          {params.value}
        </RouterLink>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      type: 'singleSelect',
      valueOptions: personStatusOptions,
    },
    ...eventPhaseOrLater(eventConfig, 'Allocation') ? [{
      field: 'onsite',
      headerName: 'Onsite',
      type: 'singleSelect',
      width: 150,
      valueOptions: onsiteStatuses.map(getOnsiteStatusLabel),
      valueGetter: (value: never, row: Person) => getOnsiteStatusLabel(row.onsite),
    } as const] : [],
    ...userHasRole(user, 'PackLeader', false) ? [] : [
      {
        field: 'packId',
        headerName: 'Reference',
        width: 125,
        type: 'singleSelect' as const,
        valueOptions: [
          { value: 'null', label: 'Staff' },
          ...packs.slice().map((pack) => ({
            value: pack.id,
            label: pack.reference,
          })),
        ],
        valueGetter: (value: never, row: Person) => row.packId ?? 'null',
      },
      {
        field: 'packName',
        headerName: 'Group',
        width: 250,
        filterable: false,
        sortable: false,
      },
      {
        field: 'districtId',
        headerName: 'District',
        width: 150,
        type: 'singleSelect',
        valueOptions: districts?.map((district) => ({
          value: district.id,
          label: district.name,
        })),
      } as const,
      {
        field: 'regionId',
        headerName: 'Region',
        width: 150,
        type: 'singleSelect',
        valueOptions: regions?.map((region) => ({
          value: region.id,
          label: region.name,
        })),
      } as const,
      {
        field: 'subcamp',
        headerName: 'Subcamp',
        width: 150,
        type: 'singleSelect',
        valueOptions: subcamps.slice(),
      } as const,
      {
        field: 'staffTeam',
        headerName: 'Staff team',
        width: 150,
        type: 'singleSelect',
        valueOptions: staffTeamOptions,
      } as const,
    ],
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'mobile', headerName: 'Mobile', width: 150 },
    { field: 'scoutingAppointment', headerName: 'Scouting role', width: 150 },
    { field: 'membershipNumber', headerName: 'Membership number', width: 150 },
    { field: 'addressStreet', headerName: 'Street', width: 150 },
    { field: 'addressTown', headerName: 'Town', width: 150 },
    { field: 'addressPostcode', headerName: 'Postcode', width: 150 },
    {
      field: 'mediaConsent', headerName: 'Media Consent', width: 150, type: 'boolean',
    },
    ...showAdminReview ? getReviewColumn('Admin', showingMultipleReviews) : [],
    ...showCompassReview ? getReviewColumn('Compass', showingMultipleReviews) : [],
    ...showActivitiesReview ? getReviewColumn('Activities', showingMultipleReviews) : [],
    ...showCateringReview ? getReviewColumn('Catering', showingMultipleReviews) : [],
    ...showSubcampReview ? getReviewColumn('Subcamp', showingMultipleReviews) : [],
    ...showPackLeaderReview ? getReviewColumn('PackLeader', showingMultipleReviews) : [],
  ];
};

export default usePeopleGridColumns;

export const genders = [
  'Female',
  'Male',
  'SelfIdentify',
  'PreferNotToSay',
  'Unspecified',
  'Other',
] as const;

export type Gender = (typeof genders)[number];

export const isValidGender = (value: string): value is Gender => genders.includes(value as never);

export const getGenderLabel = (gender: Gender | null): string => {
  switch (gender) {
    case 'SelfIdentify':
      return 'Self-identify';
    case 'PreferNotToSay':
      return 'Prefer not to say';
    default:
      return gender ?? 'Unknown';
  }
};

export const genderOptions = genders.map((gender) => ({
  label: getGenderLabel(gender),
  value: gender,
}));

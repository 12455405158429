import { MenuItem, TextField, Tooltip } from '@mui/material';
import { useState } from 'react';
import {
  ComposableMap, Geographies, Geography, Marker,
} from 'react-simple-maps';
import { getHeightPx } from '../../../../../components/Dashboard/config';
import DashboardCard from '../../../../../components/DashboardCard';
import { HexColourCode } from '../../../../../helpers/cssTypes';
import { Pack } from '../../../../../models/pack';
import { useGetPacksQuery } from '../../../../../state/protectedApi/packs';
import { useGetEventConfigQuery } from '../../../../../state/publicApi';
import { useGetEditionsQuery } from '../../../../../state/publicApi/editions';
import colours from '../../../../../theme/colours';
import * as Styled from './styles';

const PackMarker = (props: { pack: Pack, colour: HexColourCode }): JSX.Element | null => {
  const { pack, colour } = props;
  const [isHovered, setIsHovered] = useState(false);

  if (!pack.longitude || !pack.latitude) {
    return null;
  }

  return (
    <Marker coordinates={[pack.longitude, pack.latitude]}>
      <Tooltip
        onOpen={() => setIsHovered(true)}
        onClose={() => setIsHovered(false)}
        title={pack.reference}
        arrow
        disableInteractive
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, isHovered ? -7 : -5],
                },
              },
            ],
          },
        }}
      >
        <circle r={isHovered ? 4 : 2} fill={colour} />
      </Tooltip>
    </Marker>
  );
};

const BallotMap = (): JSX.Element => {
  const rows = 3;
  const padding = 20;
  const mapHeight = getHeightPx(rows) - padding;
  const mapWidth = getHeightPx(2) - padding;

  const [edition, setEdition] = useState<number>();
  const [travelMethod, setTravelMethod] = useState<string>();

  const { data: eventConfig } = useGetEventConfigQuery();
  const { data: editions } = useGetEditionsQuery();
  const { data: packs } = useGetPacksQuery();

  // const includingShetland = {
  //   rotate: [4.85, -57.8, 0],
  //   scale: 3850,
  // };

  const filteredPacks = packs?.slice()
    .filter((pack) => edition === undefined || pack.editionId === edition)
    .filter((pack) => travelMethod === undefined || pack.travelIndication === travelMethod);

  const travelOptions = packs && [...new Set(packs.map((pack) => pack.travelIndication ?? ''))];

  const showEditionFilter = editions && editions.length > 1;
  return (
    <DashboardCard columns={2} rows={rows}>
      <Styled.FiltersWrapper>
        {showEditionFilter && (
          <TextField
            label="Edition"
            sx={{ m: 1, minWidth: 120 }}
            size="small"
            value={edition}
            onChange={(event) => {
              const newValue = event.target.value === '' ? undefined : parseInt(event.target.value, 10);
              setEdition(newValue);
            }}
            select
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {editions?.map((ed) => (
              <MenuItem key={ed.id} value={ed.id}>{ed.name}</MenuItem>
            ))}
          </TextField>
        )}
        {travelOptions && (
          <TextField
            label="Travel"
            sx={{ m: 1, minWidth: 120 }}
            size="small"
            value={travelMethod}
            onChange={(event) => {
              const newValue = event.target.value === '' ? undefined : event.target.value;
              setTravelMethod(newValue);
            }}
            select
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {travelOptions?.map((method) => (
              <MenuItem key={method} value={method}>{method}</MenuItem>
            ))}
          </TextField>
        )}
      </Styled.FiltersWrapper>
      <ComposableMap
        projection="geoAzimuthalEquidistant"
        projectionConfig={{
          rotate: [4.85, -57.1, 0],
          scale: 5000,
        }}
        height={mapHeight}
        width={mapWidth}
      >
        <Geographies geography="/scotland.json">
          {({ geographies }) => geographies.map((geo: { rsmKey: string }) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill={colours.lightGreen}
              stroke={colours.grey}
            />
          ))}
        </Geographies>
        {eventConfig && (
          <Marker coordinates={[eventConfig.longitude, eventConfig.latitude]}>
            <circle r={5} fill={colours.red} />
          </Marker>
        )}
        {filteredPacks && filteredPacks.map((pack) => (
          <PackMarker key={pack.id} pack={pack} colour={colours.blue} />
        ))}
      </ComposableMap>
    </DashboardCard>
  );
};

export default BallotMap;

import * as Yup from 'yup';
import personRules from '../../../../../helpers/validation/person';

export const validationSchema = Yup.object().shape({
  gender: Yup.mixed().required('Gender is required'),
  dateOfBirth: Yup.date().typeError('Date of birth is required')
    .required('Date of birth is required'),
  mediaConsent: Yup.boolean().required('Media consent response is required'),
  additionalNeeds: personRules.additionalNeeds.nullable(),
  allergies: personRules.allergies.nullable(),
  dietaryRequirements: personRules.dietaryRequirements.nullable(),
  medications: personRules.medications.nullable(),
  activitiesMessage: personRules.activitiesMessage.nullable(),
  cateringMessage: personRules.cateringMessage.nullable(),
  message: personRules.message.nullable(),
  internalNotes: personRules.internalNotes.nullable(),
});

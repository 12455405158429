import isPropValid from '@emotion/is-prop-valid';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { SnackbarProvider } from 'notistack';
import { ReactElement } from 'react';
import { Provider as StateProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { StyleSheetManager } from 'styled-components';
import DismissAlertButton from './components/DismissAlertButton';
import router from './router';
import { persistor, store } from './state/store';
import { useTheme } from './theme';
import GlobalStyle from './theme/global';
import 'moment/locale/en-gb';

const shouldForwardProp = (propName: string, target: unknown): boolean => {
  if (typeof target === 'string') {
    return isPropValid(propName);
  }
  return true;
};

const App = (): ReactElement => {
  const theme = useTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
      <StateProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                preventDuplicate
                maxSnack={4}
                action={DismissAlertButton}
              >
                <CssBaseline />
                <GlobalStyle />
                <RouterProvider router={router} />
              </SnackbarProvider>
            </ThemeProvider>
          </StyleSheetManager>
        </PersistGate>
      </StateProvider>
    </LocalizationProvider>
  );
};

export default App;

import { objectToCamel } from 'ts-case-convert';
import { objectToSnake } from 'ts-case-convert/lib/caseConvert';
import { NewBooking } from '../../state/booking';
import bookingsClient from './client';
import mapToRequest from './mapper';

export type BookingCreationRequest = Omit<NewBooking, 'meta' | '_persist'> & {
  pack: NewBooking['pack'] & {
    districtId: number | undefined
  }
};

export type BookingCreationResponse = {
  packId: number
  packReference: string
};

export type BookingsService = {
  create: (booking: NewBooking) => Promise<BookingCreationResponse>,
};

const bookingsService: BookingsService = {
  create: async (booking) => {
    const data = mapToRequest(booking);
    return bookingsClient.create(objectToSnake(data)).then((response) => objectToCamel(response));
  },
};

export default bookingsService;

import { Alert, AlertTitle, Skeleton } from '@mui/material';
import { AlertColor } from '@mui/material/Alert/Alert';
import { FC } from 'react';
import { ActivityLeadership } from '../../../../../models/activityLeadership';
import { useGetActivitiesQuery } from '../../../../../state/publicApi/activities';

type ActivityListFilter = 'canLead' | 'wontLead' | 'notSet';

type ActivityListProps = {
  activityLeadership: ActivityLeadership[]
  filter: ActivityListFilter
};

const getAlertColour = (filter: ActivityListFilter): AlertColor => {
  switch (filter) {
    case 'canLead':
      return 'success';
    case 'wontLead':
      return 'error';
    default:
      return 'warning';
  }
};

const getAlertTitle = (filter: ActivityListFilter): string => {
  switch (filter) {
    case 'canLead':
      return 'Can lead';
    case 'wontLead':
      return 'Will not lead';
    default:
      return 'Not set';
  }
};

const ActivityList: FC<ActivityListProps> = (props) => {
  const { activityLeadership, filter } = props;

  const {
    data: activities,
  } = useGetActivitiesQuery();

  const filtered = activityLeadership.filter((leadership) => {
    switch (filter) {
      case 'canLead':
        return leadership.canLead === true;
      case 'wontLead':
        return leadership.canLead === false;
      default:
        return leadership.canLead === null;
    }
  });

  if (filtered.length === 0) {
    return null;
  }

  if (activities === undefined) {
    return (
      <Skeleton
        variant="rectangular"
        height={30 + (filtered.length * 20)}
        sx={{ marginBottom: '12px' }}
      />
    );
  }

  const activityNames = filtered.map((l) => activities?.find((activity) => activity.id === l.activityId)?.name ?? 'Not found').sort();

  return (
    <Alert severity={getAlertColour(filter)} variant="outlined" sx={{ marginBottom: '12px' }}>
      <AlertTitle>{getAlertTitle(filter)}</AlertTitle>
      {activityNames.map((name) => (
        <div key={name}>{name}</div>
      ))}
    </Alert>
  );
};

export default ActivityList;

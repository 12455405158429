type TimedEvent = {
  start: string
  end: string
};

export const isHappeningNow = (event: TimedEvent): boolean => {
  const now = new Date();
  const start = new Date(event.start);
  const end = new Date(event.end);
  return now >= start && now <= end;
};

export const hasFinished = (event: TimedEvent): boolean => {
  const now = new Date();
  const end = new Date(event.end);
  return end < now;
};

import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  password: Yup.string().required('New password is required')
    .min(6, 'Password should be at least 6 characters'),
  confirm: Yup.string().required('You must confirm your new password'),
}).test((values, ctx) => {
  if (values.password !== values.confirm) {
    return ctx.createError({ path: 'confirm', message: 'Passwords must match' });
  }
  return true;
});

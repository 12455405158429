import { skipToken } from '@reduxjs/toolkit/query';
import DashboardCard from '../../../../../components/DashboardCard';
import DashboardCardTitle from '../../../../../components/DashboardCardTitle';
import RouterLink from '../../../../../components/RouterLink';
import { editionPhaseOrLater, getFirstEdition } from '../../../../../helpers/edition';
import { eventPhaseOrLater } from '../../../../../helpers/eventConfig';
import { userHasRole } from '../../../../../helpers/user';
import routes from '../../../../../routes';
import { useGetProtectedEventConfigQuery } from '../../../../../state/protectedApi';
import { useGetEditionsForCurrentUserQuery } from '../../../../../state/protectedApi/editions';
import { useGetPackQuery } from '../../../../../state/protectedApi/packs';
import { useGetCurrentUserQuery } from '../../../../../state/protectedApi/user';
import { useGetEventConfigQuery } from '../../../../../state/publicApi';

type LinkItem = {
  to: string;
  label: string;
  target?: string;
};

const QuickLinks = (): JSX.Element => {
  const { data: user } = useGetCurrentUserQuery();
  const { data: pack } = useGetPackQuery(user?.packId ?? skipToken);
  const { data: eventConfig } = useGetEventConfigQuery();
  const { data: protectedConfig } = useGetProtectedEventConfigQuery();
  const { data: editionsWithProtected } = useGetEditionsForCurrentUserQuery();

  const links: LinkItem[] = [];
  if (userHasRole(user, ['Admin', 'Activities']) && process.env.REACT_APP_CMS_BASE_URL) {
    links.push({ to: `${process.env.REACT_APP_CMS_BASE_URL}/admin`, label: 'Content Management' });
  }
  if (userHasRole(user, 'SuperUser') && process.env.REACT_APP_API_BASE_URL) {
    links.push({ to: `${process.env.REACT_APP_API_BASE_URL}/api`, label: 'API Swagger Docs' });
    links.push({ to: `${process.env.REACT_APP_API_BASE_URL}/reset-cache`, label: 'API Server Cache Reset' });
  }
  if (userHasRole(user, 'Admin') && eventConfig && eventConfig.phases.current === 'Booking') {
    links.push({ to: routes.bookings.start, label: 'Booking Form' });
  }
  if (userHasRole(user, ['Admin', 'Catering', 'Activities']) && protectedConfig && protectedConfig.staffRegistrationCode) {
    links.push({ to: routes.registrations.start(protectedConfig.staffRegistrationCode), label: 'Staff Registration (All Editions)' });
  }
  if (userHasRole(user, ['Admin', 'Catering', 'Activities']) && editionsWithProtected) {
    editionsWithProtected.forEach((edition) => {
      if (edition.staffRegistrationCode) {
        links.push({ to: routes.registrations.start(edition.staffRegistrationCode), label: `${edition.name} Staff Registration` });
      }
    });
  }
  if (pack) {
    links.push({ to: routes.packs.detail(pack.id), label: 'My Pack', target: '_self' });
  }
  if (pack && editionPhaseOrLater(getFirstEdition(editionsWithProtected ?? []), 'Delivery')) {
    links.push({ to: routes.activities.allocations, label: 'Activity Allocation', target: '_self' });
  }
  if (pack) {
    links.push({ to: routes.registrations.start(pack.publicId), label: `Registration Form (${pack.registrationStatus})` });
  }
  editionsWithProtected?.forEach((edition) => {
    if (edition.leadersWhatsappGroup) {
      links.push({
        to: edition.leadersWhatsappGroup,
        label: `${edition.name}: WhatsApp Community`,
      });
    }
  });
  links.push({ to: routes.documents.activity.preferences, label: 'Activity Preferences' });
  links.push({ to: routes.documents.menu, label: 'Menu' });
  links.push({ to: 'https://form.jotform.com/241271881729968', label: 'Merch Order Form' });
  // TODO: pull programme link from CMS
  links.push({
    to: 'https://the-howling-production-cms.s3.eu-west-2.amazonaws.com/Programme_The_Howling_2024_v3_8ec7fa790d.pdf',
    label: 'Programme',
  });
  links.push({
    to: 'https://www.youtube.com/watch?v=ZjVp_l0oPl0',
    label: 'Pack Contacts\' Call (8 May) Recording',
  });
  links.push({
    to: 'https://the-howling-production-cms.s3.eu-west-2.amazonaws.com/The_Howling_2024_Booking_Contacts_Briefing_min_d68b940b9e.pdf',
    label: 'Pack Contacts\' Call (8 May) Slides',
  });
  if (eventConfig && eventPhaseOrLater(eventConfig, 'Delivery')
    && userHasRole(user, ['Admin', 'Activities', 'Catering', 'Subcamp'])
  ) {
    links.push({ to: routes.arrivals, label: 'Arrival Check In' });
    links.push({ to: routes.meals.checkIn(), label: 'Meal Check In' });
  }

  const rows = Math.ceil((links.length + 2) / 6);

  return (
    <DashboardCard columns={2} rows={rows}>
      <DashboardCardTitle>Quick Links</DashboardCardTitle>
      {links.map((link) => (
        <RouterLink key={link.label} to={link.to} target={link.target ?? '_blank'}>{link.label}</RouterLink>
      ))}
    </DashboardCard>
  );
};

export default QuickLinks;

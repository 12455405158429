type RegistrationStatus =
| 'Closed'
| 'Open'
| 'Complete';

type CodeCheck = {
  code: string
};

type ValidCode = {
  valid: true
  allowYoungLeaders: boolean
  allowOtherChildren: boolean
} & CodeCheck;

type InvalidCode = {
  valid: false
} & CodeCheck;

type StaffCode = {
  staff: true
} & ValidCode;

export const isStaffCode = (
  value: RegistrationCodeCheckResult,
): value is StaffCode => value.valid && value.staff;

type PackCode = {
  staff: false
  packName: string
  status: RegistrationStatus
} & ValidCode;

export const isPackCode = (
  value: RegistrationCodeCheckResult,
): value is PackCode => value.valid && !value.staff;

export type RegistrationCodeCheckResult = InvalidCode | StaffCode | PackCode;

import {
  Backdrop,
  Button,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BlocksRenderer from '../../../components/BlocksRenderer';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import { useBookingsContent } from '../../../helpers/content';
import { getLongDateTime } from '../../../helpers/datesStrings';
import { getEditionDates } from '../../../helpers/edition';
import { getEventPriceSummary } from '../../../helpers/eventConfig';
import { getEmailLink } from '../../../helpers/links';
import routes from '../../../routes';
import { initialState, reset, update } from '../../../state/booking';
import { useGetEventConfigQuery } from '../../../state/publicApi';
import { useGetEditionsQuery } from '../../../state/publicApi/editions';

const BookingStartPage: () => JSX.Element = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { content } = useBookingsContent();
  const { data: eventConfig } = useGetEventConfigQuery();
  const { data: editions } = useGetEditionsQuery();
  if (!eventConfig || !editions || !content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  const startBooking = async (): Promise<void> => {
    if (editions.length === 1) {
      dispatch(update({
        ...initialState,
        meta: {
          ...initialState.meta,
          showEditionsChoice: false,
        },
        preferredEditionIds: [editions[0].id],
      }));
    } else {
      dispatch(reset());
    }
    navigate(routes.bookings.group);
  };

  return (
    <Card>
      <CardHeading>{content.start.title}</CardHeading>
      <hr />
      {content.start.show_summary_table && (
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>When</TableCell>
              <TableCell>
                {editions.map((edition, index) => (
                  <span key={edition.id}>
                    <strong>
                      {eventConfig.name}
                      {' '}
                      {edition.name}
                      {' - '}
                    </strong>
                    {getEditionDates(edition)}
                    {index < editions.length - 1 && <br />}
                  </span>
                ))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Where</TableCell>
              <TableCell>{eventConfig.location}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cost</TableCell>
              <TableCell>{getEventPriceSummary(eventConfig)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>
                <Link href={getEmailLink(eventConfig.email)}>{eventConfig.email}</Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Booking Deadline</TableCell>
              <TableCell>{getLongDateTime(eventConfig.phases.bookings.deadline)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      <BlocksRenderer content={content.start.body} />
      <hr />
      <BlocksRenderer content={content.start.privacy_statement} />
      <CardFooter>
        <Button variant="contained" size="large" onClick={startBooking}>
          {content.start.start_booking_button}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default BookingStartPage;

import { objectToCamel } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { PersonStatus } from '../../models/personStatus';
import { ReviewInstruction } from '../../models/registration';
import { ReviewStatus } from '../../models/reviewStatus';
import { ReviewTag } from '../../models/reviewTag';
import { ReviewTagSummary, ReviewTagSummaryRequest } from '../../models/reviewTagSummary';
import { ReviewType } from '../../models/reviewType';
import { StaffTeam } from '../../models/staffTeam';
import { Subcamp } from '../../models/subcamp';
import { protectedApi } from './index';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getReviews: builder.query<ReviewInstruction[], {
      type: ReviewType
      status: ReviewStatus
      personStatus?: PersonStatus | 'All'
      subcamp?: Subcamp | ''
      packId?: number
      districtId?: number
      editionId?: number
      staffTeams?: StaffTeam[]
    }>({
      query: (props) => {
        const {
          type,
          status,
          subcamp,
          packId,
          districtId,
          editionId,
          personStatus,
          staffTeams,
        } = props;
        const urlParams = [`review_type=${type}`, `status=${status}`];
        if (subcamp) {
          urlParams.push(`subcamp=${subcamp}`);
        }
        if (packId) {
          urlParams.push(`pack_id=${packId}`);
        }
        if (districtId) {
          urlParams.push(`district_id=${districtId}`);
        }
        if (editionId) {
          urlParams.push(`edition_id=${editionId}`);
        }
        if (personStatus && personStatus !== 'All') {
          urlParams.push(`person_status=${personStatus}`);
        }
        if (staffTeams && staffTeams.length > 0) {
          urlParams.push(`staff_teams=${staffTeams.join(',')}`);
        }
        return `reviews/?${urlParams.join('&')}`;
      },
      transformResponse: (
        response: ObjectToSnake<ReviewInstruction[]>,
      ) => objectToCamel(response),
    }),
    getReviewTags: builder.query<ReviewTag[], {
      type: ReviewType
      subcamp?: Subcamp
      packId?: number
    }>({
      query: (args) => {
        const { type, subcamp, packId } = args;
        const urlParams = [`review_type=${type}`];
        if (subcamp) {
          urlParams.push(`subcamp=${subcamp}`);
        }
        if (packId) {
          urlParams.push(`pack_id=${packId}`);
        }
        return `reviews/tags/list?${urlParams.join('&')}`;
      },
      transformResponse: (response: ObjectToSnake<ReviewTag[]>) => objectToCamel(response),
      providesTags: (result, error, arg) => [
        { type: 'reviewTags', id: arg.type },
      ],
    }),
    getReviewTagSummary: builder.query<ReviewTagSummary, ReviewTagSummaryRequest>({
      query: (args) => {
        const {
          reviewType, subcamp, packId, districtId, editionId, combinations, staffTeams,
        } = args;
        const urlParams = [`review_type=${reviewType}`];
        if (subcamp) {
          urlParams.push(`subcamp=${subcamp}`);
        }
        if (packId) {
          urlParams.push(`pack_id=${packId}`);
        }
        if (districtId) {
          urlParams.push(`district_id=${districtId}`);
        }
        if (editionId) {
          urlParams.push(`edition_id=${editionId}`);
        }
        if (combinations) {
          urlParams.push('combinations=true');
        }
        if (staffTeams && staffTeams.length > 0) {
          urlParams.push(`staff_teams=${staffTeams.join(',')}`);
        }
        return `reviews/tags/summary?${urlParams.join('&')}`;
      },
      transformResponse: (response: ObjectToSnake<ReviewTagSummary>) => objectToCamel(response),
      providesTags: (result, error, arg) => [
        { type: 'reviewTagSummaries', id: arg.reviewType },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetReviewsQuery,
  useGetReviewTagsQuery,
  useGetReviewTagSummaryQuery,
} = extendedApi;

import { OptionList } from '../helpers/options';

export const packAttendeeStatuses = [
  'Cub',
  'YoungLeader',
  'Leader',
  'OtherChild',
] as const;

export type PackAttendeeStatus = (typeof packAttendeeStatuses)[number];

export const isValidPackAttendeeStatus = (
  value: string,
): value is PackAttendeeStatus => packAttendeeStatuses.includes(value as never);

export const attendeeStatuses = [
  ...packAttendeeStatuses,
  'Visitor',
] as const;

export type AttendeeStatus = (typeof attendeeStatuses)[number];

export const isValidAttendeeStatus = (
  value: string,
): value is AttendeeStatus => attendeeStatuses.includes(value as never);

export const personStatuses = [
  ...attendeeStatuses,
  'Booker',
  'Contact',
] as const;

export type PersonStatus = (typeof personStatuses)[number];

export const isValidPersonStatus = (
  value: string,
): value is PersonStatus => personStatuses.includes(value as never);

export const getPersonStatusLabel = (status: PersonStatus): string => {
  switch (status) {
    case 'OtherChild':
      return 'Other child';
    case 'YoungLeader':
      return 'Young leader';
    default:
      return status;
  }
};

export const personStatusOptions: OptionList<PersonStatus> = personStatuses.map((status) => ({
  label: getPersonStatusLabel(status),
  value: status,
}));

export const nonAdultStatusOptions: OptionList<PersonStatus> = [
  {
    label: getPersonStatusLabel('Cub'),
    value: 'Cub',
  },
  {
    label: getPersonStatusLabel('YoungLeader'),
    value: 'YoungLeader',
  },
  {
    label: getPersonStatusLabel('OtherChild'),
    value: 'OtherChild',
  }];

export const getPluralPersonStatusLabel = (status: PersonStatus): string => {
  switch (status) {
    case 'OtherChild':
      return 'Other children';
    case 'YoungLeader':
      return 'Young leaders';
    case 'Cub':
      return 'Cubs';
    case 'Leader':
      return 'Leaders';
    case 'Visitor':
      return 'Visitors';
    default:
      return status;
  }
};

import { objectToCamel, objectToSnake } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { Registration } from '../../models/registration';
import { ReviewType } from '../../models/reviewType';
import { protectedApi } from './index';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getRegistration: builder.query<Registration, number>({
      query: (personId) => `/registration/${personId}`,
      transformResponse: (response: ObjectToSnake<Registration>) => objectToCamel(response),
      providesTags: (result, error, personId) => [{ type: 'registration', id: personId }],
    }),
    createRegistration: builder.mutation<Registration, Registration>({
      query: (args) => {
        const { personId, ...update } = args;
        const body = objectToSnake(update);
        return ({
          url: `registration/${personId}`,
          method: 'POST',
          body,
        });
      },
      invalidatesTags: (result, error, args) => [{ type: 'registration', id: args.personId }],
      transformResponse: (response: ObjectToSnake<Registration>) => objectToCamel(response),
    }),
    updateRegistration: builder.mutation<Registration, Partial<Registration> & {
      personId: number,
      reviewTypeWithNewTags?: ReviewType[],
    }>({
      query: (args) => {
        const { personId, reviewTypeWithNewTags, ...update } = args;
        const body = objectToSnake(update);
        return ({
          url: `registration/${personId}`,
          method: 'PATCH',
          body,
        });
      },
      invalidatesTags: (result, error, args) => [
        { type: 'registration', id: args.personId },
        ...args.reviewTypeWithNewTags
          ? args.reviewTypeWithNewTags.map((type) => ({ type: 'reviewTags' as const, id: String(type) }))
          : [],
        ...['Admin', 'Compass', 'Activities', 'Catering', 'Subcamp', 'PackLeader'].map((type) => ({
          type: 'reviewTagSummaries' as const,
          id: type,
        })),
      ],
      transformResponse: (response: ObjectToSnake<Registration>) => objectToCamel(response),
    }),
    deleteRegistration: builder.mutation<void, number>({
      query: (personId) => ({
        url: `registration/${personId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, personId) => [{ type: 'registration', id: personId }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetRegistrationQuery,
  useCreateRegistrationMutation,
  useUpdateRegistrationMutation,
  useDeleteRegistrationMutation,
} = extendedApi;

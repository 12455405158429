import { objectToCamel } from 'ts-case-convert';
import { ObjectToSnake, objectToSnake } from 'ts-case-convert/lib/caseConvert';
import { ActivityScheduleItem } from '../../models/activitySchedule';
import { protectedApi } from './index';

type ActivityScheduleFilter = {
  personId: number
  editionId: number
};

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getActivitySchedule: builder.query<ActivityScheduleItem[], ActivityScheduleFilter>({
      query: ({ personId, editionId }) => `activities/schedules/${personId}/${editionId}`,
      transformResponse: (
        response: ObjectToSnake<ActivityScheduleItem[]>,
      ) => objectToCamel(response),
    }),
    updateActivitySchedule: builder.mutation<ActivityScheduleItem[], ActivityScheduleFilter & {
      data: ActivityScheduleItem[]
    }>({
      query: (args) => {
        const { personId, editionId, data } = args;
        const body = objectToSnake(data);
        return ({
          url: `activities/schedules/${personId}/${editionId}`,
          method: 'PATCH',
          body,
        });
      },
    }),
    deleteActivitySchedule: builder.mutation<void, ActivityScheduleFilter>({
      query: ({ personId, editionId }) => ({
        url: `activities/schedules/${personId}/${editionId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetActivityScheduleQuery,
  useUpdateActivityScheduleMutation,
  useDeleteActivityScheduleMutation,
} = extendedApi;

import { FC } from 'react';
import DashboardPieChart from '../../../../../components/DashboardPieChart';
import { getColourRange } from '../../../../../helpers/colourRange';
import { Statistics } from '../../../../../models/statistics';
import colours from '../../../../../theme/colours';

type RegistrationsBySimdDecileProps = {
  stats?: Statistics
};

const lastYearsComparison: { decile: number, peopleCount: number }[] = [
  { decile: 1, peopleCount: 16 },
  { decile: 2, peopleCount: 26 },
  { decile: 3, peopleCount: 25 },
  { decile: 4, peopleCount: 30 },
  { decile: 5, peopleCount: 40 },
  { decile: 6, peopleCount: 72 },
  { decile: 7, peopleCount: 84 },
  { decile: 8, peopleCount: 169 },
  { decile: 9, peopleCount: 101 },
  { decile: 10, peopleCount: 148 },
];

const getDecileLabel = (decile: number | null, year: string): string => {
  if (decile === null) {
    return 'Unknown';
  }
  return `Decile ${decile} (${year})`;
};

const RegistrationsBySimdDecile: FC<RegistrationsBySimdDecileProps> = (props) => {
  const { stats } = props;

  const colourRange = getColourRange(colours.red, colours.green, 10);

  return (
    <DashboardPieChart
      title="SIMD Decile"
      halfCircle
      data={stats?.simdDeciles?.map((item) => ({
        name: getDecileLabel(item.decile, '2024'),
        count: item.peopleCount,
        colour: item.decile ? colourRange[item.decile] : colours.grey,
      }))}
      dataOuterCircle={lastYearsComparison.map((item) => ({
        name: getDecileLabel(item.decile, '2023'),
        count: item.peopleCount,
        colour: colourRange[item.decile],
      }))}
      showLegend={false}
    />
  );
};

export default RegistrationsBySimdDecile;

import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import { userHasRole } from '../../helpers/user';
import { useGetCurrentUserQuery } from '../../state/protectedApi/user';
import { FormGrid } from '../FormGrid';
import PersonSearchInput from './PersonSearchInput';
import { validationSchema } from './schema';

type ManualPersonIdFormProps = {
  disabled?: boolean
  submitButtonText?: string
  onSubmit: (payload: { personId: number }) => void
  edition?: number
};

const searchEnabled = process.env.REACT_APP_SEARCH_ENABLED === 'true';

const ManualPersonIdForm: FC<ManualPersonIdFormProps> = (props) => {
  const {
    disabled, submitButtonText, onSubmit, edition,
  } = props;

  const { data: currentUser } = useGetCurrentUserQuery();
  const allowSearch = searchEnabled && userHasRole(currentUser, ['Admin', 'Activities', 'Catering']);

  const {
    handleSubmit,
    values,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      personId: '',
    },
    validationSchema,
    onSubmit: async (formValues) => {
      const personId = parseInt(formValues.personId, 10);
      onSubmit({ personId });
    },
  });

  return (
    <form onSubmit={handleSubmit} noValidate>
      <FormGrid columns={3}>
        {allowSearch && (
          <PersonSearchInput
            onChange={(personId) => setFieldValue('personId', personId.toString())}
            disabled={disabled}
            onReset={() => setFieldValue('personId', '')}
            onOpen={() => setFieldValue('personId', '')}
            edition={edition}
          />
        )}
        <TextField
          label="Person ID"
          value={values.personId}
          onChange={(event) => setFieldValue('personId', event.target.value)}
          error={touched.personId && Boolean(errors.personId)}
          helperText={(touched.personId && errors.personId) || ' '}
          inputProps={{ pattern: '[0-9]*' }}
          disabled={disabled}
        />
        <Button
          color="secondary"
          variant="outlined"
          type="submit"
          disabled={disabled}
          sx={{ height: '56px' }}
        >
          {submitButtonText || 'Submit'}
        </Button>
      </FormGrid>
    </form>
  );
};

export default ManualPersonIdForm;

import {
  Button, Collapse, Stack, TextField,
} from '@mui/material';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useState } from 'react';
import { AlertWithBottomMargin } from '../../components/Alert';
import Card from '../../components/Card';
import CardHeading from '../../components/CardHeading';
import { FormGrid } from '../../components/FormGrid';
import FormLayout from '../../components/FormLayout';
import PageMetaTitle from '../../components/PageMetaTitle';
import RouterLink from '../../components/RouterLink';
import { auth } from '../../firebase';
import { isFirebaseError } from '../../helpers/errorTypes';
import { useTypeSafeFormik } from '../../helpers/form';
import routes from '../../routes';
import { validationSchema } from './schema';

const ForgottenPasswordPage = (): JSX.Element => {
  const [firebaseError, setFirebaseError] = useState<string>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const redirectUrl = window.location.origin + routes.dashboard;

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    setSubmitting,
  } = useTypeSafeFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async () => {
      setIsSuccess(false);
      setFirebaseError(undefined);
      sendPasswordResetEmail(auth, values.email, { url: redirectUrl }).then(() => {
        setIsSuccess(true);
      }).catch((error) => {
        const errorMessage = isFirebaseError(error) && error.message;
        setFirebaseError(errorMessage || 'Unexpected firebase error.');
      }).finally(() => {
        setSubmitting(false);
      });
    },
  });

  return (
    <>
      <PageMetaTitle title="Forgotten Password" />
      <FormLayout>
        <Card>
          <CardHeading>Password Recovery</CardHeading>
          <Collapse in={firebaseError !== undefined}>
            <AlertWithBottomMargin severity="error">
              We were unable to send a password reset email.
              <hr />
              {firebaseError}
            </AlertWithBottomMargin>
          </Collapse>
          <Collapse in={isSuccess}>
            <AlertWithBottomMargin severity="success">
              We have sent a password reset email.
            </AlertWithBottomMargin>
          </Collapse>
          <form onSubmit={handleSubmit} noValidate>
            <FormGrid>
              <TextField
                label="Email"
                autoComplete="email"
                value={values.email}
                onBlur={() => setFieldTouched('email')}
                onChange={async (event) => {
                  await setFieldValue('email', event.target.value);
                }}
                error={touched.email && Boolean(errors.email)}
                helperText={(touched.email && errors.email) || ' '}
              />
            </FormGrid>
            <Stack justifyContent="space-between" direction="row">
              <RouterLink to={routes.login}>
                Ready to sign in?
              </RouterLink>
              <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                Send email
              </Button>
            </Stack>
          </form>
        </Card>
      </FormLayout>
    </>
  );
};

export default ForgottenPasswordPage;

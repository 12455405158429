import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import apiService from '../apiService';
import type { BookingCreationRequest, BookingCreationResponse } from './index';

export type BookingsClient = {
  create: (
    data: ObjectToSnake<BookingCreationRequest>,
  ) => Promise<ObjectToSnake<BookingCreationResponse>>;
};

const bookingsClient: BookingsClient = {
  create: async (data) => apiService.post('bookings/', data),
};

export default bookingsClient;

import { Backdrop, CircularProgress } from '@mui/material';
import { useState } from 'react';
import DataGrid from '../../../components/DataGrid';
import ToolbarEditionFilter from '../../../components/DataGridToolbar/ToolbarEditionFilter';
import ExpandingSpace from '../../../components/ExpandingSpace';
import PageMetaTitle from '../../../components/PageMetaTitle';
import { sanitiseFilter } from '../../../helpers/dataRequest';
import { GridPaginationModel } from '../../../helpers/paginationResponse';
import useDebounce from '../../../helpers/useDebounce';
import useErrorHandler from '../../../helpers/useErrorHandler';
import { userHasRole } from '../../../helpers/user';
import useGridStateProps from '../../../state/grids/useGridStateProps';
import { useGetPeopleQuery } from '../../../state/protectedApi/people';
import { useGetCurrentUserQuery } from '../../../state/protectedApi/user';
import usePeopleGridColumns from './columns';

const PeopleGridPage = (): JSX.Element => {
  const [pagination, setPagination] = useState<GridPaginationModel>({
    pageSize: 25,
    page: 0,
  });
  const gridState = useGridStateProps(window.location.pathname);
  const debouncedFilter = useDebounce(gridState.filterModel, 500);

  const { data: user } = useGetCurrentUserQuery();
  const columns = usePeopleGridColumns();

  const {
    data: attendees,
    error: attendeesError,
    isFetching: isFetchingAttendees,
    refetch: refetchAttendees,
  } = useGetPeopleQuery({
    editionId: gridState.editionId,
    pagination,
    sort: gridState.sortModel,
    filter: sanitiseFilter(debouncedFilter),
  } ?? undefined);
  useErrorHandler(attendeesError, 'API Error: Failed to load people');

  if (!columns || columns.length === 0) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  const userIsPackLeader = userHasRole(user, 'PackLeader', false);

  return (
    <>
      <PageMetaTitle title="People" />
      <DataGrid
        loading={isFetchingAttendees}
        rows={attendees?.results ?? []}
        rowCount={attendees?.totalCount ?? 0}
        pagination
        paginationMode="server"
        autoPageSize={false}
        pageSizeOptions={[25, 100, 250, 500, 1000, 5000]}
        onPaginationModelChange={setPagination}
        filterMode="server"
        sortingMode="server"
        {...gridState}
        columns={columns}
        columnGroupingModel={[
          {
            groupId: 'Person',
            children: [
              { field: 'id' },
              { field: 'packReference' },
              { field: 'name' },
              { field: 'status' },
              { field: 'onsite' },
            ],
          },
          {
            groupId: 'Pack / Team',
            children: [
              { field: 'packId' },
              { field: 'packName' },
              { field: 'districtId' },
              { field: 'regionId' },
              { field: 'subcamp' },
              { field: 'staffTeam' },
            ],
          },
          {
            groupId: 'Leader Details',
            children: [
              { field: 'email' },
              { field: 'mobile' },
              { field: 'scoutingAppointment' },
              { field: 'membershipNumber' },
            ],
          },
          {
            groupId: 'Postal Address',
            children: [
              { field: 'addressStreet' },
              { field: 'addressTown' },
              { field: 'addressPostcode' },
            ],
          },
          {
            groupId: 'Media',
            children: [
              { field: 'mediaConsent' },
            ],
          },
          {
            groupId: 'Core Team Review',
            children: [
              { field: 'adminReviewStatus' },
              { field: 'adminReviewTags' },
            ],
          },
          {
            groupId: 'Compass Review',
            children: [
              { field: 'compassReviewStatus' },
              { field: 'compassReviewTags' },
            ],
          },
          {
            groupId: 'Activities Review',
            children: [
              { field: 'activitiesReviewStatus' },
              { field: 'activitiesReviewTags' },
            ],
          },
          {
            groupId: 'Catering Review',
            children: [
              { field: 'cateringReviewStatus' },
              { field: 'cateringReviewTags' },
            ],
          },
          {
            groupId: 'Subcamp Review',
            children: [
              { field: 'subcampReviewStatus' },
              { field: 'subcampReviewTags' },
            ],
          },
          {
            groupId: 'Pack Leader Review',
            children: [
              { field: 'packLeaderReviewStatus' },
              { field: 'packLeaderReviewTags' },
            ],
          },
        ]}
        slotProps={{
          toolbar: {
            refetch: refetchAttendees,
            children: (
              <>
                <ExpandingSpace />
                <ToolbarEditionFilter
                  allowAll
                  editionId={gridState.editionId}
                  onEditionChange={gridState.onEditionIdChange}
                />
              </>
            ),
          },
        }}
        initialState={{
          columns: {
            // TODO: Control which columns are shown based on the current event phase
            columnVisibilityModel: userIsPackLeader ? undefined : {
              dateOfBirth: false,
              region: false,
              district: false,
              subcamp: false,
              scoutingAppointment: false,
              membershipNumber: false,
              addressStreet: false,
              addressTown: false,
              addressPostcode: false,
            },
          },
          pagination: {
            paginationModel: pagination,
          },
        }}
      />
    </>
  );
};

export default PeopleGridPage;

import { objectToCamel } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { District } from '../../models/district';
import { Region } from '../../models/region';
import { protectedApi } from '../protectedApi';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getRegion: builder.query<Region, number>({
      query: (regionId: number) => `reference/regions/${regionId}`,
      transformResponse: (response: ObjectToSnake<Region>) => objectToCamel(response),
    }),
    getRegions: builder.query<Region[], void>({
      query: () => 'reference/regions/',
      transformResponse: (response: ObjectToSnake<Region[]>) => objectToCamel(response),
    }),
    getDistrict: builder.query<District, number>({
      query: (districtId: number) => `reference/districts/${districtId}`,
      transformResponse: (response: ObjectToSnake<District>) => objectToCamel(response),
    }),
    getDistricts: builder.query<District[], void>({
      query: () => 'reference/districts/',
      transformResponse: (response: ObjectToSnake<District[]>) => objectToCamel(response),
    }),
    getDistrictsByRegion: builder.query<District[], number>({
      query: (regionId: number) => `reference/districts/?region_id=${regionId.toString()}`,
      transformResponse: (response: ObjectToSnake<District[]>) => objectToCamel(response),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetRegionQuery,
  useGetRegionsQuery,
  useGetDistrictQuery,
  useGetDistrictsQuery,
  useGetDistrictsByRegionQuery,
} = extendedApi;

import { TableBody } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { FC } from 'react';
import DataPanel, { DataPanelDisplayProps } from '../../../../components/DataPanel';
import LoadingText from '../../../../components/LoadingText';
import { ParagraphWithinTable } from '../../../../components/ParagraphWithinTable';
import Table from '../../../../components/Table';
import TableKeyValueRow from '../../../../components/TableKeyValueRow';
import checkPersonPermissions from '../../../../helpers/dataAction';
import { getEditionSummary } from '../../../../helpers/edition';
import { getFullNamePossessive } from '../../../../helpers/person';
import { Person } from '../../../../models/person';
import { Staff } from '../../../../models/staff';
import { User } from '../../../../models/user';
import {
  useCreateStaffMutation,
  useDeleteStaffMutation,
  useGetStaffQuery,
  useUpdateStaffMutation,
} from '../../../../state/protectedApi/staff';
import { useGetEventConfigQuery } from '../../../../state/publicApi';
import { useGetEditionsQuery } from '../../../../state/publicApi/editions';
import { useGetDistrictQuery, useGetRegionQuery } from '../../../../state/publicApi/reference';
import StaffForm from './StaffForm';
import sanitiseOnSubmit from './StaffForm/sanitise';
import { validationSchema } from './StaffForm/schema';

type StaffPanelProps = {
  person: Person
  user: User | undefined
};

const DisplayStaff: FC<DataPanelDisplayProps<Staff>> = (props) => {
  const {
    data: {
      editionIds,
      subcamp,
      staffTeam,
      customRole,
      regionId,
      districtId,
      groupName,
    },
  } = props;

  const { data: eventConfig } = useGetEventConfigQuery();
  const { data: editions } = useGetEditionsQuery();
  const { data: region } = useGetRegionQuery(regionId || skipToken);
  const { data: district } = useGetDistrictQuery(districtId || skipToken);

  const getEditionNameForId = (editionId: number): string => {
    const edition = editions?.find((e) => e.id === editionId);
    if (!edition || !eventConfig) {
      return ' ';
    }
    return getEditionSummary(eventConfig, edition);
  };

  return (
    <Table size="small" sx={{ marginBottom: '30px' }}>
      <TableBody>
        {editions && editions.length > 0 && (
          <TableKeyValueRow name="Editions">
            {editionIds.map((editionId) => (
              <ParagraphWithinTable key={editionId}>
                {getEditionNameForId(editionId)}
              </ParagraphWithinTable>
            ))}
          </TableKeyValueRow>
        )}
        <TableKeyValueRow name="Team">
          {subcamp && staffTeam === 'Subcamp' ? `${subcamp} Subcamp` : staffTeam}
        </TableKeyValueRow>
        {customRole && (
          <TableKeyValueRow name="Custom role">
            {customRole}
          </TableKeyValueRow>
        )}
        <TableKeyValueRow name="Region">
          {region ? region.name : 'Not set'}
        </TableKeyValueRow>
        <TableKeyValueRow name="District">
          {district ? district.name : 'Not set'}
        </TableKeyValueRow>
        <TableKeyValueRow name="Scout group / ESU">
          {groupName ?? 'Not set'}
        </TableKeyValueRow>
      </TableBody>
    </Table>
  );
};

const StaffPanel: FC<StaffPanelProps> = (props) => {
  const { person, user } = props;

  const canCreate = checkPersonPermissions('Create', person, user);
  const canUpdate = checkPersonPermissions('Update', person, user);
  const canDelete = checkPersonPermissions('Delete', person, user);

  const fetchStaff = useGetStaffQuery(person.id);
  const [createStaff] = useCreateStaffMutation();
  const [updateStaff] = useUpdateStaffMutation();
  const [deleteStaff] = useDeleteStaffMutation();

  const personId = person.id;

  const initialValues: Staff = {
    personId,
    editionIds: [],
    staffTeam: null,
    customRole: '',
    regionId: null,
    districtId: null,
    groupName: '',
  };

  if (person.packId && fetchStaff.currentData === undefined) {
    return null;
  }

  return (
    <DataPanel
      title="Staff"
      modelName="staff"
      fetch={fetchStaff}
      formikConfig={{ validationSchema }}
      sanitiseOnSubmit={sanitiseOnSubmit}
      prepareInitialValues={(data) => ({
        ...data,
        customRole: data.customRole ?? '',
        groupName: data.groupName ?? '',
      })}
      canCreate={canCreate}
      create={{
        mutation: (create) => createStaff({ ...create, personId }),
        initialValues,
      }}
      canUpdate={canUpdate}
      update={{
        mutation: (update) => updateStaff({ ...update, personId }),
      }}
      canDelete={canDelete}
      deletion={{
        mutation: () => deleteStaff(personId),
        dialogTitle: `Delete ${getFullNamePossessive(person)} staff record`,
        dialogDescription: `Are you sure you want to delete the staff record for ${person.firstName}?`,
      }}
      LoadingComponent={<LoadingText lines={4} />}
      DisplayComponent={DisplayStaff}
      FormComponent={StaffForm}
    />
  );
};

export default StaffPanel;

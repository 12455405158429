import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FormikConfig } from 'formik';
import { FC, ReactElement } from 'react';
import { Mutation } from '../../../helpers/actionType';
import { useTypeSafeFormik } from '../../../helpers/form';
import { FormProps } from '../../forms/types';

type DataPanelFormWrapperProps<TForm extends object, TData extends TForm> = {
  mutation: Mutation<TForm, TData>,
  onSuccess: (data: TData) => void,
  onError: (error: SerializedError | FetchBaseQueryError) => void,
  sanitiseOnSubmit: ((values: TForm) => TForm) | undefined,
  formikConfig: Omit<FormikConfig<TForm>, 'onSubmit'>,
  FormComponent: FC<FormProps<TForm>>
};

const FormWrapper = <TForm extends object, TData extends TForm>(
  props: DataPanelFormWrapperProps<TForm, TData>,
): ReactElement => {
  const {
    mutation,
    onSuccess,
    onError,
    sanitiseOnSubmit,
    formikConfig,
    FormComponent,
  } = props;

  const onSubmit = async (
    values: TForm,
  ): Promise<void> => {
    const sanitisedValues = sanitiseOnSubmit ? sanitiseOnSubmit(values) : values;
    await mutation(sanitisedValues).then((result) => {
      const isError = 'error' in result;
      if (isError && onError) {
        onError(result.error);
      } else if (!isError && onSuccess) {
        onSuccess(result.data);
      }
    });
  };

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useTypeSafeFormik({ ...formikConfig, onSubmit });

  return (
    <FormComponent
      handleSubmit={handleSubmit}
      touched={touched}
      errors={errors}
      values={values}
      setFieldTouched={setFieldTouched}
      setFieldValue={setFieldValue}
      isSubmitting={isSubmitting}
    />
  );
};

export default FormWrapper;

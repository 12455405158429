import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Skeleton,
  Stack,
  TableBody,
  TableRow,
} from '@mui/material';
import { FC } from 'react';
import { getRelativeDayOrDate } from '../../helpers/datesStrings';
import { ReduxFetch } from '../../helpers/reduxFetch';
import { EmailEvent } from '../../models/emailEvent';
import DataPanelWrapper from '../DataPanel/DataPanelWrapper';
import ErrorAlert from '../DataPanel/ErrorAlert';
import Table from '../Table';
import TableCell from '../TableCell';

type EmailEventsPanelProps = {
  fetch: ReduxFetch<EmailEvent[]>
};

const EmailEventsPanel: FC<EmailEventsPanelProps> = (props) => {
  const { fetch } = props;

  const {
    data: emailEvents,
    isLoading,
    error,
  } = fetch;

  // TODO: test that the email events are in order (especially for multiple contacts)
  return (
    <DataPanelWrapper>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <h3>Email Tracking</h3>
        </AccordionSummary>
        <AccordionDetails>
          {isLoading && (
            <div>
              <Skeleton variant="rectangular" height={160} sx={{ marginBottom: '12px' }} />
              <Skeleton variant="rectangular" height={160} />
            </div>
          )}

          {error && <ErrorAlert error={error} type="load" modelName="email events" bottomMargin={false} />}

          {emailEvents && emailEvents.length > 0 && (
            <Table>
              <TableBody>
                {emailEvents.map((emailEvent) => (
                  <TableRow key={emailEvent.id}>
                    <TableCell>
                      <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <div>
                          <strong>{emailEvent.subject}</strong>
                          <div>
                            {`${emailEvent.recipient} (${emailEvent.emailAddress})`}
                          </div>
                        </div>
                        <div style={{ textAlign: 'right' }}>
                          <div>
                            {getRelativeDayOrDate(emailEvent.created)}
                          </div>
                          <div>
                            {'Status: '}
                            <strong>{emailEvent.status}</strong>
                          </div>
                        </div>
                      </Stack>

                      {emailEvent.error && (
                        <Alert severity="error" sx={{ marginTop: '12px' }}>
                          <AlertTitle>{emailEvent.error}</AlertTitle>
                        </Alert>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}

          {emailEvents && emailEvents.length === 0 && (
            <p>No email events</p>
          )}
        </AccordionDetails>
      </Accordion>
    </DataPanelWrapper>
  );
};

export default EmailEventsPanel;

import { TableBody } from '@mui/material';
import { FC } from 'react';
import DataPanel, { DataPanelDisplayProps } from '../../../../components/DataPanel';
import LoadingText from '../../../../components/LoadingText';
import RouterLink from '../../../../components/RouterLink';
import Table from '../../../../components/Table';
import TableKeyValueRow from '../../../../components/TableKeyValueRow';
import { getFullName } from '../../../../helpers/person';
import { userHasRole } from '../../../../helpers/user';
import { ActivitySupport, getActivitySupportStatusLabel } from '../../../../models/activitySupport';
import { Person } from '../../../../models/person';
import { getPersonStatusLabel } from '../../../../models/personStatus';
import { User } from '../../../../models/user';
import routes from '../../../../routes';
import {
  useGetActivitySupportQuery,
  useUpdateActivitySupportMutation,
} from '../../../../state/protectedApi/activitySupport';
import { useGetPersonQuery } from '../../../../state/protectedApi/people';
import ActivitySupportForm from './ActivitySupportForm';
import sanitiseOnSubmit from './ActivitySupportForm/sanitise';

type ActivitySupportPanelProps = {
  person: Person
  user: User | undefined
};

const DisplayLeader: FC<{ leaderId: number }> = (props) => {
  const { leaderId } = props;
  const { data: leader } = useGetPersonQuery(leaderId);
  if (!leader) {
    return (
      <span>Loading...</span>
    );
  }

  const nameWithStatus = `${getFullName(leader)}, ${getPersonStatusLabel(leader.status)}`;

  return (
    <div>
      <RouterLink to={routes.people.detail(leader.id)}>{nameWithStatus}</RouterLink>
    </div>
  );
};

const DisplaySupport: FC<DataPanelDisplayProps<ActivitySupport>> = (props) => {
  const {
    data: {
      activityAdditionalSupport, leaderIds,
    },
  } = props;

  return (
    <Table size="small" sx={{ marginBottom: '30px' }}>
      <TableBody>
        <TableKeyValueRow name="Status">{getActivitySupportStatusLabel(activityAdditionalSupport)}</TableKeyValueRow>
        {leaderIds.length > 0 && (
          <TableKeyValueRow name="Leaders">
            {leaderIds.map((leaderId) => (
              <DisplayLeader key={leaderId} leaderId={leaderId} />
            ))}
          </TableKeyValueRow>
        )}
      </TableBody>
    </Table>
  );
};

const ActivitySupportPanel: FC<ActivitySupportPanelProps> = (props) => {
  const { person, user } = props;
  const personId = person.id;

  const canUpdate = userHasRole(user, ['Admin', 'Activities']);

  const fetchSupport = useGetActivitySupportQuery(personId);
  const [updateSupport] = useUpdateActivitySupportMutation();

  return (
    <DataPanel
      title="Activity Support"
      modelName="activity support"
      fetch={fetchSupport}
      canUpdate={canUpdate}
      sanitiseOnSubmit={sanitiseOnSubmit}
      update={{
        mutation: (update) => updateSupport({ ...update, cubId: personId }),
      }}
      LoadingComponent={<LoadingText lines={4} />}
      DisplayComponent={DisplaySupport}
      FormComponent={ActivitySupportForm}
    />
  );
};

export default ActivitySupportPanel;

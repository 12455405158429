import {
  Chip, Stack, TableRow, useMediaQuery,
} from '@mui/material';
import TableKeyValueRow from '../../../../../components/TableKeyValueRow';
import { getStatusColourForReviewStatus } from '../../../../../helpers/statusColours';
import { Review } from '../../../../../models/registration';
import { getReviewStatusLabel } from '../../../../../models/reviewStatus';
import { useTheme } from '../../../../../theme';
import * as Styled from './styles';

type ReviewRowProps = {
  title: string
  review: Review
};

const ReviewRow = (props: ReviewRowProps): JSX.Element => {
  const { title, review: { status, tags } } = props;

  const label = getReviewStatusLabel(status);
  const isActiveStatus = ['Pending', 'SeekingInformation', 'Flagged'].includes(status);
  const hasTags = tags && tags.length > 0;

  const theme = useTheme();
  const screenWiderThanMobile = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <TableKeyValueRow name={title} borderBottom={!hasTags}>
        {isActiveStatus ? (
          <Chip
            label={label}
            color={getStatusColourForReviewStatus(status)}
          />
        ) : (
          label
        )}
      </TableKeyValueRow>
      {hasTags && (
        <TableRow>
          {screenWiderThanMobile && <Styled.TagsRowTableCell />}
          <Styled.TagsRowTableCell colSpan={screenWiderThanMobile ? 1 : 2}>
            <Stack direction="row" spacing={1}>
              {tags.map((tag) => (
                <Chip
                  key={tag.id}
                  label={tag.text}
                  color="default"
                  variant="outlined"
                />
              ))}
            </Stack>
          </Styled.TagsRowTableCell>
        </TableRow>
      )}
    </>
  );
};

export default ReviewRow;

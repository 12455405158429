import * as Yup from 'yup';
import personRules from '../../../helpers/validation/person';

const requiredMessage = 'Details are required';

export const validationSchema = Yup.object().shape({
  meta: Yup.object().shape({
    hasAdditionalNeeds: Yup.mixed().required('Response is required'),
    hasAllergies: Yup.mixed().required('Response is required'),
    hasDietaryRequirements: Yup.mixed().required('Response is required'),
    hasMedications: Yup.mixed().required('Response is required'),
  }),
  attendee: Yup.object()
    .when('meta.hasAdditionalNeeds', {
      is: true,
      then: Yup.object().shape({
        additionalNeeds: personRules.additionalNeeds.required(requiredMessage),
      }),
    })
    .when('meta.hasAllergies', {
      is: true,
      then: Yup.object().shape({
        allergies: personRules.allergies.required(requiredMessage),
      }),
    })
    .when('meta.hasDietaryRequirements', {
      is: true,
      then: Yup.object().shape({
        dietaryRequirements: personRules.dietaryRequirements.required(requiredMessage),
      }),
    })
    .when('meta.hasMedications', {
      is: true,
      then: Yup.object().shape({
        medications: personRules.medications.required(requiredMessage),
      }),
    }),
});

import { asString } from '../typeValidation';
import getSubstitution from './getSubstitution';

const formatStringWithContext = (
  template: string,
  data: unknown,
): string => template.replace(/{([^}]+)}/g, (match, reference) => {
  const substitution = getSubstitution(data, asString(reference));
  return substitution ?? match;
});

const applyContext = <T>(
  content: T,
  data: unknown,
): T => {
  if (!content) {
    return content;
  }

  if (typeof content === 'string') {
    return formatStringWithContext(content, data) as T;
  }
  if (Array.isArray(content)) {
    return content.map<unknown>((v) => applyContext(v, data)) as T;
  }
  if (typeof content === 'object') {
    return Object.fromEntries(
      Object.entries(content).map(([k, v]) => [
        k,
        applyContext(v, data),
      ]),
    ) as T;
  }
  return content;
};

export default applyContext;

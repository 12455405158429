import { AppBar as MuiAppBar, Toolbar as MuiToolbar } from '@mui/material';
import styled from 'styled-components';

export const AppBar = styled(MuiAppBar)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`;

export const Toolbar = styled(MuiToolbar)`
  justify-content: space-between;
`;

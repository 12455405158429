import { Button, ButtonGroup } from '@mui/material';
import { ButtonColour } from '../../../helpers/buttons';
import { ReviewStatus, getReviewStatusLabel } from '../../../models/reviewStatus';
import TableCell from '../../TableCell';

type ReviewStatusSelectorProps = {
  status: ReviewStatus
  disabled: boolean
  onChange: (update: ReviewStatus) => void
  orientation: 'vertical' | 'horizontal'
};

const getButtonColour = (status: ReviewStatus): ButtonColour => {
  switch (status) {
    case 'Pending':
      return 'primary';
    case 'SeekingInformation':
      return 'info';
    case 'Flagged':
      return 'error';
    case 'Complete':
      return 'success';
    default:
      return 'inherit';
  }
};

const ReviewStatusSelector = (props: ReviewStatusSelectorProps): JSX.Element => {
  const {
    status, orientation, disabled, onChange,
  } = props;
  const options: ReviewStatus[] = ['Pending', 'SeekingInformation', 'Flagged', 'Complete'];

  const handelOnChange = (newStatus: ReviewStatus): void => {
    if (status !== newStatus) {
      onChange(newStatus);
    }
  };

  return (
    <TableCell>
      <ButtonGroup
        variant="contained"
        disabled={disabled}
        disableElevation
        orientation={orientation}
        color={getButtonColour(status)}
      >
        {
          options.map((option) => (
            <Button
              key={option}
              variant={status === option ? 'contained' : 'outlined'}
              onClick={() => handelOnChange(option)}
            >
              {getReviewStatusLabel(option)}
            </Button>
          ))
        }
      </ButtonGroup>
    </TableCell>
  );
};

export default ReviewStatusSelector;

import {
  Backdrop, Button, CircularProgress, TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import IconLink from '../../../components/IconLink';
import { useFormikWithStateSync } from '../../../helpers/form';
import routes from '../../../routes';
import { selectBooking, update } from '../../../state/booking';
import sanitiseOnSubmit from './sanitise';
import { validationSchema } from './schema';

const BookingAddressFormPage = (): JSX.Element => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormikWithStateSync(
    selectBooking,
    update,
    {
      validationSchema,
      onSubmit: async () => {
        navigate(routes.bookings.estimates);
      },
    },
    sanitiseOnSubmit,
  );

  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <CardHeading>Your address</CardHeading>
        <TextField
          label="Street address"
          value={values.contact.addressStreet}
          onBlur={() => setFieldTouched('contact.addressStreet')}
          onChange={async (event) => {
            await setFieldValue('contact.addressStreet', event.target.value);
          }}
          error={touched.contact?.addressStreet && Boolean(errors.contact?.addressStreet)}
          helperText={(touched.contact?.addressStreet && errors.contact?.addressStreet) || ' '}
          multiline
          minRows={3}
        />
        <TextField
          label="Town"
          value={values.contact.addressTown}
          onBlur={() => setFieldTouched('contact.addressTown')}
          onChange={async (event) => {
            await setFieldValue('contact.addressTown', event.target.value);
          }}
          error={touched.contact?.addressTown && Boolean(errors.contact?.addressTown)}
          helperText={(touched.contact?.addressTown && errors.contact?.addressTown) || ' '}
        />
        <TextField
          label="Postcode"
          value={values.contact.addressPostcode}
          onBlur={() => setFieldTouched('contact.addressPostcode')}
          onChange={async (event) => {
            await setFieldValue('contact.addressPostcode', event.target.value);
          }}
          error={touched.contact?.addressPostcode && Boolean(errors.contact?.addressPostcode)}
          helperText={(touched.contact?.addressPostcode && errors.contact?.addressPostcode) || ' '}
        />
        <CardFooter>
          <IconLink icon="Back" to={routes.bookings.contact}>Back</IconLink>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Next
          </Button>
        </CardFooter>
      </form>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </Card>
  );
};

export default BookingAddressFormPage;

import { onAuthStateChanged } from '@firebase/auth';
import { ReactNode, useEffect, useState } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import { auth } from '../../firebase';
import { editionPhaseOrLater } from '../../helpers/edition';
import { eventPhaseOrEarlier, eventPhaseOrLater } from '../../helpers/eventConfig';
import useElementSize from '../../helpers/useElementSize';
import { userHasRole } from '../../helpers/user';
import routes from '../../routes';
import { useGetEditionsForCurrentUserQuery } from '../../state/protectedApi/editions';
import { useGetCurrentUserQuery } from '../../state/protectedApi/user';
import { useGetEventConfigQuery } from '../../state/publicApi';
import AppBar from './AppBar';
import Menu from './Menu';
import * as Styled from './styles';
import { MenuItem } from './types';

type PageLayoutProps = {
  children: ReactNode
  limitContentHeight?: boolean
  limitContentWidth?: boolean
};

const PageLayout = (props: PageLayoutProps): JSX.Element => {
  const {
    children,
    limitContentHeight = false,
    limitContentWidth = true,
  } = props;

  const [appBarRef, { height: appBarHeight }] = useElementSize();

  const isContentOnlyMode = process.env.REACT_APP_CMS_CONTENT_ONLY === 'true';
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = (): void => setMenuOpen(!menuOpen);

  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const {
    data: currentUser,
  } = useGetCurrentUserQuery(undefined, { skip: isContentOnlyMode });
  const {
    data: editions,
  } = useGetEditionsForCurrentUserQuery(undefined, { skip: isContentOnlyMode });
  const {
    data: eventConfig,
  } = useGetEventConfigQuery(undefined, { skip: isContentOnlyMode });

  useEffect(() => onAuthStateChanged(auth, (user) => {
    setUserAuthenticated(!!user);
  }));

  let showActivityAllocation = false;
  if (eventConfig && userHasRole(currentUser, ['Admin', 'Activities', 'Subcamp'])) {
    showActivityAllocation = eventPhaseOrLater(eventConfig, 'Allocation');
  }
  if (userHasRole(currentUser, 'PackLeader', false)) {
    const firstEdition = editions?.[0];
    showActivityAllocation = !!firstEdition && editionPhaseOrLater(firstEdition, 'Delivery');
  }

  const menuItems: MenuItem[] = [
    ...userAuthenticated ? [
      { label: 'Dashboard', route: routes.dashboard },
    ] : [],
    ...eventConfig?.phases.current === 'Delivery' ? [
      { label: 'Check In', route: routes.arrivals },
    ] : [],
    ...currentUser?.packId ? [
      { label: 'My Pack', route: routes.packs.detail(currentUser.packId) },
    ] : [],
    ...userHasRole(currentUser, ['Admin', 'Activities', 'Subcamp', 'Catering']) ? [
      { label: 'People', route: routes.people.grid },
      { label: 'Packs', route: routes.packs.grid },
    ] : [],
    ...userHasRole(currentUser, ['Admin', 'Activities', 'Subcamp']) ? [
      { label: 'Activities', route: routes.activities.grid },
    ] : [],
    ...showActivityAllocation ? [
      { label: 'Activity Allocation', route: routes.activities.allocations },
    ] : [],
    ...userHasRole(currentUser, ['Admin', 'Activities']) ? [
      { label: 'Activity Sessions', route: routes.activities.sessions.grid },
    ] : [],
    ...userHasRole(currentUser, ['Admin', 'Activities', 'Catering', 'Subcamp']) ? [
      { label: 'Duties', route: routes.duties.grid },
    ] : [],
    ...userHasRole(currentUser, ['Admin', 'Catering']) ? [
      { label: 'Meals', route: routes.meals.grid },
    ] : [],
    ...userAuthenticated && eventConfig && eventPhaseOrLater(eventConfig, 'Registration') ? [
      { label: 'Reviews', route: routes.reviews },
    ] : [],
    ...eventConfig && eventPhaseOrLater(eventConfig, 'Registration') && userHasRole(currentUser, [
      'Admin', 'Subcamp', 'Activities', 'Catering',
    ]) ? [
        { label: 'Review Tag Summary', route: routes.reviewTagSummary },
      ] : [],
    ...userHasRole(currentUser, 'Admin') ? [
      { label: 'Email Events', route: routes.emails.grid },
    ] : [],
    ...userAuthenticated ? [
      { id: 1, type: 'divider' as const },
    ] : [],
    { label: 'About', route: routes.home },
    { label: 'FAQs', route: routes.faq },
    ...(!isContentOnlyMode && !userAuthenticated
      && eventConfig && eventPhaseOrEarlier(eventConfig, 'Booking')) ? [
        { label: 'Bookings', route: routes.bookings.start },
      ] : [],
    ...(!isContentOnlyMode && eventConfig && eventPhaseOrLater(eventConfig, 'Registration')) ? [
      {
        label: userHasRole(currentUser, ['Admin', 'Activities', 'Subcamp']) ? 'Activities - Public' : 'Activities',
        route: routes.activities.public,
      },
    ] : [],
  ];

  return (
    <>
      <AppBar
        ref={appBarRef}
        open={menuOpen}
        toggle={toggleMenu}
        showSearch={userHasRole(currentUser, ['Admin', 'Activities', 'Subcamp', 'Catering'])}
        menu={menuItems}
      />
      <Menu
        appBarHeight={appBarHeight}
        menuItems={menuItems}
        open={menuOpen}
        onClose={toggleMenu}
      />
      <Styled.Main
        menuOpen={menuOpen}
        appBarHeight={appBarHeight}
        limitContentHeight={limitContentHeight}
        limitContentWidth={limitContentWidth}
      >
        <ScrollRestoration />
        {children}
      </Styled.Main>
    </>
  );
};

export default PageLayout;

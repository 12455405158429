const formatBoolean = (
  value: boolean,
  format: string | undefined,
): string => {
  if (!format) {
    return value.toString();
  }

  const match = format.match(/^(?<positive>[^|]+)\|(?<negative>[^|]+)$/);

  if (!match || !match.groups) {
    throw new Error(`Boolean format invalid: ${format}`);
  }

  const { positive, negative } = match.groups;

  return value ? positive : negative;
};

export default formatBoolean;

import { AlertTitle } from '@mui/material';
import { FunctionComponent } from 'react';
import { AlertWithSmallBottomMargin } from '../../../../components/Alert';
import { ByPersonStatus } from '../../../../models/pack';

type ExcessiveRegistrationsAlertProps = {
  excessive: ByPersonStatus;
};

const ExcessiveRegistrationsAlert: FunctionComponent<ExcessiveRegistrationsAlertProps> = (
  props,
) => {
  const { excessive } = props;

  return (
    <AlertWithSmallBottomMargin severity="error">
      <AlertTitle>Excessive Registrations:</AlertTitle>
      <ul style={{ margin: 0 }}>
        {excessive.cubs > 0 && (
          <li>
            {`${excessive.cubs} excessive Cubs`}
          </li>
        )}
        {excessive.youngLeaders > 0 && (
          <li>
            {`${excessive.youngLeaders} excessive Young Leaders`}
          </li>
        )}
        {excessive.leaders > 0 && (
          <li>
            {`${excessive.leaders} excessive Leaders`}
          </li>
        )}
        {excessive.otherChildren > 0 && (
          <li>
            {`${excessive.otherChildren} excessive Other Children`}
          </li>
        )}
      </ul>
    </AlertWithSmallBottomMargin>
  );
};

export default ExcessiveRegistrationsAlert;

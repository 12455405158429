import * as Yup from 'yup';
import { maxLength } from '../../../helpers/validation/maxLength';

export const validationSchema = Yup.object().shape({
  surgeryName: Yup.string().required('Surgery name is required').max(...maxLength.short),
  name: Yup.string().max(...maxLength.short).nullable(),
  phone: Yup.string().required('Phone number is required').max(...maxLength.short),
  addressStreet: Yup.string().required('Street is required').max(...maxLength.default),
  addressTown: Yup.string().required('Town is required').max(...maxLength.default),
});

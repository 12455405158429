import { Button, Menu as MuiMenu } from '@mui/material';
import styled from 'styled-components';
import colours from '../../../../theme/colours';

export const Menu = styled(MuiMenu)`
  margin-top: 40px;
`;

export const UsernameButton = styled(Button)`
  color: ${colours.white};

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const Wrapper = styled.div`
  min-width: 38px;
  margin-left: 18px;
`;

import {
  GridColDef,
  GridColumnVisibilityModel,
  DataGridPro as MuiDataGrid,
} from '@mui/x-data-grid-pro';
import useGridStateProps from '../../state/grids/useGridStateProps';
import DataGridToolbar from '../DataGridToolbar';
import * as Styled from './styles';
import { DataGridComponent } from './types';

const getGridWidth = (
  columns: GridColDef[],
  columnVisibility?: GridColumnVisibilityModel,
): number => columns.reduce((total, current) => total + (
  columnVisibility?.[current.field] === false ? 0 : current.width ?? 100
), 0);

const DataGrid: DataGridComponent = (props) => {
  const {
    disableExternalMargin = false,
    columns,
    initialState,
    slots,
  } = props;

  const initialColumnVisibilityModel = Object.fromEntries(
    columns.map((column) => [
      column.field,
      initialState?.columns?.columnVisibilityModel?.[column.field] ?? true,
    ]),
  );

  const gridState = useGridStateProps(window.location.pathname);

  const initialGridWidth = getGridWidth(columns, initialColumnVisibilityModel) + 10;
  const currentColumnsWidth = getGridWidth(
    columns,
    gridState.columnVisibilityModel,
  ) + 20;

  const gridWidth = initialGridWidth > currentColumnsWidth ? initialGridWidth : currentColumnsWidth;

  const gridProps = {
    slots: {
      toolbar: DataGridToolbar,
      ...slots,
    },
    autoPageSize: true,
    ...gridState,
    ...props,
  };

  return (
    <Styled.DataGridWrapper width={`${gridWidth}px`} disableExternalMargin={disableExternalMargin}>
      <MuiDataGrid {...gridProps} />
    </Styled.DataGridWrapper>
  );
};

export default DataGrid;

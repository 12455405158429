import { GridColDef } from '@mui/x-data-grid-pro';
import RouterLink from '../../../components/RouterLink';
import { getShortTime } from '../../../helpers/datesStrings';
import { getRegistrationLink } from '../../../helpers/pack';
import { getIdFromReference } from '../../../helpers/packReference';
import { getFullName } from '../../../helpers/person';
import { Pack } from '../../../models/pack';
import { packBookingStatusOptions } from '../../../models/packBookingStatus';
import { registrationStatusOptions } from '../../../models/registrationStatus';
import { subcampOptions } from '../../../models/subcamp';
import { wideningAccessStatusOptions } from '../../../models/wideningAccessStatus';
import routes from '../../../routes';
import { useGetEventConfigQuery } from '../../../state/publicApi';
import { useGetEditionsQuery } from '../../../state/publicApi/editions';
import { useGetDistrictsQuery, useGetRegionsQuery } from '../../../state/publicApi/reference';
import { getColumnForStatus, hideIfBookingCancelled, showIfBookingActive } from './helpers';
import { renderRegistrationStatusLink } from './registrationLink';

export const usePackColumns = (): GridColDef<Pack>[] => {
  const { data: config } = useGetEventConfigQuery();
  const { data: regions } = useGetRegionsQuery();
  const { data: districts } = useGetDistrictsQuery();
  const { data: editions } = useGetEditionsQuery();
  return [
    {
      field: 'reference',
      headerName: 'Reference',
      width: 125,
      renderCell: (params) => (
        <RouterLink to={routes.packs.detail(params.row.id)}>{params.value}</RouterLink>
      ),
      sortComparator: (v1: string, v2: string) => getIdFromReference(v1) - getIdFromReference(v2),
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'districtId',
      headerName: 'District',
      type: 'singleSelect',
      valueOptions: districts?.map((d) => ({ value: d.id, label: d.name })),
      sortComparator: (v1: number, v2: number) => {
        const district1 = districts?.find((d) => d.id === v1);
        const district2 = districts?.find((d) => d.id === v2);
        return district1?.name.localeCompare(district2?.name ?? '') ?? 0;
      },
      width: 150,
    },
    {
      field: 'regionId',
      headerName: 'Region',
      type: 'singleSelect',
      valueOptions: regions?.map((r) => ({ value: r.id, label: r.name })),
      width: 150,
      sortComparator: (v1: number, v2: number) => {
        const region1 = regions?.find((r) => r.id === v1);
        const region2 = regions?.find((r) => r.id === v2);
        return region1?.name.localeCompare(region2?.name ?? '') ?? 0;
      },
    },
    {
      field: 'bookingStatus',
      headerName: 'Booking',
      width: 150,
      type: 'singleSelect',
      valueOptions: packBookingStatusOptions,
    },
    {
      field: 'wideningAccess',
      headerName: 'Widening Access',
      width: 150,
      type: 'singleSelect',
      valueOptions: wideningAccessStatusOptions,
    },
    {
      field: 'attendedTwoYearsAgo',
      headerName: '2022',
      width: 100,
      type: 'singleSelect',
      valueOptions: [{ label: 'Yes', value: true }, { label: 'No', value: false }, { label: 'Not set', value: 'unknown' }],
      valueGetter: (value: boolean | null) => (value === null ? 'unknown' : value),
    },
    {
      field: 'attendedLastYear',
      headerName: '2023',
      width: 100,
      type: 'singleSelect',
      valueOptions: [{ label: 'Yes', value: true }, { label: 'No', value: false }, { label: 'Not set', value: 'unknown' }],
      valueGetter: (value: boolean | null) => (value === null ? 'unknown' : value),
    },
    {
      field: 'registrationStatus',
      headerName: 'Registration',
      width: 150,
      type: 'singleSelect',
      valueOptions: registrationStatusOptions,
      valueFormatter: (params, pack) => getRegistrationLink(pack),
      renderCell: renderRegistrationStatusLink,
    } as const,
    {
      field: 'subcamp',
      headerName: 'Subcamp',
      width: 150,
      type: 'singleSelect',
      valueOptions: [{ label: 'None', value: '' }, ...subcampOptions],
      valueGetter: showIfBookingActive,
    },
    {
      field: 'travelIndication',
      headerName: 'Travel',
      width: 100,
      valueGetter: hideIfBookingCancelled,
    },
    {
      field: 'arrival',
      headerName: 'Arrival',
      width: 175,
      valueGetter: (value: never, row: Pack) => {
        if (!row.arrivalStart || !row.arrivalEnd) {
          return '';
        }
        return `${getShortTime(row.arrivalStart)} - ${getShortTime(row.arrivalEnd)}`;
      },
    },
    {
      field: 'departure',
      headerName: 'Departure',
      width: 175,
      valueGetter: (value: never, row: Pack) => {
        if (!row.departureStart || !row.departureEnd) {
          return '';
        }
        return `${getShortTime(row.departureStart)} - ${getShortTime(row.departureEnd)}`;
      },
    },
    {
      field: 'preferredEditionIds',
      headerName: 'Preferred Editions',
      width: 200,
      valueGetter: (value: number[]): string => {
        if (!editions || value === undefined) {
          return '';
        }
        const preferredEditions = editions.filter((e) => value.includes(e.id));
        return preferredEditions.map((e) => e.name).join(', ');
      },
    },
    getColumnForStatus('cubs', config),
    getColumnForStatus('youngLeaders', config),
    getColumnForStatus('leaders', config),
    getColumnForStatus('otherChildren', config),
    {
      field: 'bookingContacts',
      headerName: 'Booking Contacts',
      width: 250,
      valueGetter: (value: Pack['bookingContacts']) => value.map(getFullName).join(', '),
      renderCell: (params) => params.row.bookingContacts.map((person, index, bookingContacts) => (
        <span key={person.id}>
          <RouterLink to={routes.people.detail(person.id)}>
            {getFullName(person)}
          </RouterLink>
          {index + 1 !== bookingContacts.length && ', '}
        </span>
      )),
    },
    {
      field: 'bookingContactsEmails',
      headerName: 'Booking Emails',
      width: 400,
      valueGetter: (value, row: Pack) => row.bookingContacts.map((contact) => contact.email).join(';'),
    },
  ];
};

import DataGrid from '../../../components/DataGrid';
import ToolbarEditionFilter from '../../../components/DataGridToolbar/ToolbarEditionFilter';
import ExpandingSpace from '../../../components/ExpandingSpace';
import PageMetaTitle from '../../../components/PageMetaTitle';
import { useDefaultEdition } from '../../../helpers/useDefaultEdition';
import useErrorHandler from '../../../helpers/useErrorHandler';
import { useGetSessionsQuery } from '../../../state/publicApi/activities';
import { useGetEditionsQuery } from '../../../state/publicApi/editions';
import getColumns from './columns';

const SessionsGridPage = (): JSX.Element => {
  const {
    data: sessions,
    isFetching: isFetchingSessions,
    error: sessionsError,
  } = useGetSessionsQuery();
  useErrorHandler(sessionsError, 'API Error loading sessions');

  const {
    data: editions,
    error: editionsError,
  } = useGetEditionsQuery();
  useErrorHandler(editionsError, 'API Error loading editions');

  const [edition, setEditionById] = useDefaultEdition();
  const columns = getColumns(editions ?? [], !edition);

  const filteredSessions = sessions?.slice()
    .filter((session) => !edition || session.editionId === edition.id) ?? [];

  return (
    <>
      <PageMetaTitle title="Sessions" />
      <DataGrid
        columns={columns}
        rows={filteredSessions}
        loading={isFetchingSessions}
        slotProps={{
          toolbar: {
            children: (
              <>
                <ExpandingSpace />
                <ToolbarEditionFilter
                  allowAll
                  editionId={edition?.id}
                  onEditionChange={setEditionById}
                />
              </>
            ),
          },
        }}
      />
    </>
  );
};

export default SessionsGridPage;

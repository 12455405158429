import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { objectToCamel } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { auth } from '../../firebase';
import { ProtectedEventConfig } from '../../models/eventConfig';

const baseUrl = process.env.REACT_APP_API_BASE_URL ?? '';

export const protectedApi = createApi({
  reducerPath: 'protectedApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/api/`,
    prepareHeaders: async (headers) => {
      const token = await auth.currentUser?.getIdToken();
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['activityAllocation', 'activityRegister', 'contacts', 'duties', 'dutyRegister', 'packs', 'people', 'registration', 'doctor', 'staff', 'reviewTags', 'reviewTagSummaries', 'users', 'ballotStats', 'sessions'],
  endpoints: (builder) => ({
    getProtectedEventConfig: builder.query<ProtectedEventConfig, void>({
      query: () => 'event-config/protected/',
      transformResponse: (response: ObjectToSnake<ProtectedEventConfig>) => objectToCamel(response),
    }),
  }),
});

export const {
  useGetProtectedEventConfigQuery,
} = protectedApi;

import * as Yup from 'yup';

// TODO add length validation
// TODO validate the meal times are within the edition event times
// TODO validate description length
export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  editionId: Yup.number().required('Edition is required'),
  start: Yup.string().required('Start time is required'),
  end: Yup.string().required('End time is required'),
  description: Yup.string().required('Description is required'),
});

import { BooleanStats } from '../../models/statistics';
import colours from '../../theme/colours';
import DashboardPieChart from '../DashboardPieChart';

type DashboardBooleanPieChartProps = {
  title: string
  data?: BooleanStats
  includeNotSet?: boolean
};

const DashboardBooleanPieChart = (props: DashboardBooleanPieChartProps): JSX.Element => {
  const { title, data, includeNotSet = false } = props;

  const chartData = data && [
    { name: 'Yes', count: data.yes, colour: colours.forestGreen },
    { name: 'No', count: data.no, colour: colours.orange },
    ...includeNotSet && data.notSet > 0 ? [
      { name: 'Not Set', count: data.notSet, colour: colours.grey },
    ] : [],
  ];

  return (
    <DashboardPieChart
      title={title}
      data={chartData}
      halfCircle
    />

  );
};

export default DashboardBooleanPieChart;

import { NewRegistration } from '../../../state/registration/types';

const sanitiseOnSubmit = (rawValues: NewRegistration): NewRegistration => {
  const attendee = {
    ...rawValues.attendee,
    additionalNeeds: rawValues.meta.hasAdditionalNeeds
      ? rawValues.attendee.additionalNeeds.trim() : '',
    medications: rawValues.meta.hasMedications
      ? rawValues.attendee.medications.trim() : '',
    allergies: rawValues.meta.hasAllergies
      ? rawValues.attendee.allergies.trim() : '',
    dietaryRequirements: rawValues.meta.hasDietaryRequirements
      ? rawValues.attendee.dietaryRequirements.trim() : '',
  };

  return { ...rawValues, attendee };
};

export default sanitiseOnSubmit;

import { coerceStringToNull } from '../../../../../helpers/strings';
import { Registration } from '../../../../../models/registration';

const sanitiseOnSubmit = (rawValues: Registration): Registration => ({
  ...rawValues,
  additionalNeeds: coerceStringToNull(rawValues.additionalNeeds?.trim()),
  allergies: coerceStringToNull(rawValues.allergies?.trim()),
  dietaryRequirements: coerceStringToNull(rawValues.dietaryRequirements?.trim()),
  medications: coerceStringToNull(rawValues.medications?.trim()),
  sandwichPreference: coerceStringToNull(rawValues.sandwichPreference?.trim()),
  activitiesMessage: coerceStringToNull(rawValues.activitiesMessage?.trim()),
  cateringMessage: coerceStringToNull(rawValues.cateringMessage?.trim()),
  message: coerceStringToNull(rawValues.message?.trim()),
  internalNotes: coerceStringToNull(rawValues.internalNotes?.trim()),
});

export default sanitiseOnSubmit;

import * as Yup from 'yup';
import personRules from '../../../helpers/validation/person';

export const validationSchema = Yup.object().shape({
  meta: Yup.object().shape({
    registeringSelf: Yup.mixed().required('Response required'),
  }),
  mainContact: Yup.object().shape({
    firstName: personRules.firstName.required('Your first name is required'),
    knownAs: personRules.knownAs,
    lastName: personRules.lastName.required('Your last name is required'),
    email: personRules.email.required('Your email is required'),
    mobile: personRules.mobile.required('Your mobile number is required'),
  }).when('meta.registeringSelf', {
    is: (registeringSelf: boolean | undefined) => registeringSelf === false,
    then: Yup.object().shape({
      relationship: personRules.relationship.required('Relationship to attendee is required'),
    }),
  }),
});

import DashboardPieChart from '../../../../../components/DashboardPieChart';
import { useGetBallotStatisticsQuery } from '../../../../../state/protectedApi/statistics';

const BallotTravelMethods = (): JSX.Element => {
  const { data } = useGetBallotStatisticsQuery(undefined);

  return (
    <DashboardPieChart
      title="Travel"
      data={data?.travelIndications
        .map((method) => ({ name: method.method, count: method.total })) ?? []}
    />
  );
};

export default BallotTravelMethods;

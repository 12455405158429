/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { ObjectSchema } from 'yup';
import * as Yup from 'yup';
import { AnyObject } from 'yup/es/types';
import { EventConfig } from '../../../models/eventConfig';

export const getValidationSchema = (
  eventConfig?: EventConfig,
): ObjectSchema<AnyObject> => {
  if (eventConfig === undefined) {
    return Yup.object();
  }
  const { phases: { bookings } } = eventConfig;

  return Yup.object().shape({
    pack: Yup.object().shape({
      cubs: Yup.number()
        .typeError('You must specify a number')
        .required('Cub numbers is required')
        .positive('Number must be positive')
        .lessThan(
          bookings.maxCubs + 1,
          `Cubs cannot exceed ${bookings.maxCubs}`,
        ),
      leaders: Yup.number()
        .typeError('You must specify a number')
        .required('Adult numbers is required')
        .positive('Number must be positive')
        .moreThan(
          bookings.minLeaders - 1,
          `At least ${bookings.minLeaders} adults required`,
        )
        .when('cubs', {
          is: (cubs: number) => cubs > 0 && cubs < bookings.maxCubs + 1,
          then: Yup.number().test({
            name: 'ratio',
            message: `You need at least 1 leader for every ${bookings.maxCubsToLeadersRatio} cubs`,
            test: (leaders, context) => !!leaders
              && (leaders * bookings.maxCubsToLeadersRatio) >= context.parent.cubs,
          }),
        })
        .when('cubs', {
          is: (cubs: number) => cubs > 0 && cubs < bookings.maxCubs + 1,
          then: Yup.number().test({
            name: 'excessive',
            message: `You can only have 
              ${bookings.maxAdditionalLeadersAboveMin} leaders in addition
              to the minimum cubs ratio (1:${bookings.maxCubsToLeadersRatio})`,
            test: (leaders, context) => {
              const leaderRatio = bookings.maxCubsToLeadersRatio;
              const minLeaders = Math.ceil(context.parent.cubs / leaderRatio);
              const maxLeaders = minLeaders + bookings.maxAdditionalLeadersAboveMin;
              return !!leaders && leaders <= maxLeaders;
            },
          }),
        }),
      youngLeaders: Yup.number()
        .typeError('You must specify a number')
        .min(0, 'Number cannot be negative')
        .max(
          bookings.maxYoungLeaders,
          `Young leaders cannot exceed ${bookings.maxYoungLeaders}`,
        ),
      otherChildren: Yup.number()
        .typeError('You must specify a number')
        .min(0, 'Number cannot be negative')
        .max(
          bookings.maxOtherChildren,
          `Other children cannot exceed ${bookings.maxOtherChildren}`,
        ),
    }),
  });
};

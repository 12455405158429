import { PackEmailTemplate } from '../../../../models/emailTemplate';
import { Pack, UpdatePack } from '../../../../models/pack';
import { PackBookingsStatus } from '../../../../models/packBookingStatus';
import { WideningAccessStatus } from '../../../../models/wideningAccessStatus';
import { BulkPackUpdateFormValues } from './types';

const validateEmailForBookingStatus = (
  emailTemplate: PackEmailTemplate,
  bookingStatus: PackBookingsStatus | 'No change',
  packs: Pack[],
): void => {
  switch (emailTemplate) {
    case 'BallotConfirmation':
      if (
        bookingStatus !== 'Pending'
        && packs.some((p) => p.bookingStatus !== 'Pending')
      ) {
        throw new Error('Packs must have a \'Pending\' booking status to send this email');
      }
      break;
    case 'BallotOutcomeOffered':
    case 'BallotOutcomeOfferedWithWideningAccess':
    case 'BallotOutcomeOfferedWithoutWideningAccess':
      if (
        bookingStatus !== 'Offered'
        && packs.some((p) => p.bookingStatus !== 'Offered')
      ) {
        throw new Error('Packs must have a \'Offered\' booking status to send this email');
      }
      break;
    case 'BallotOutcomeWaitListed':
      if (
        bookingStatus !== 'WaitListed'
        && packs.some((p) => p.bookingStatus !== 'WaitListed')
      ) {
        throw new Error('Packs must have a \'Wait Listed\' booking status to send this email');
      }
      break;
    case 'BallotOutcomeUnsuccessful':
      if (
        bookingStatus !== 'Unsuccessful'
        && packs.some((p) => p.bookingStatus !== 'Unsuccessful')
      ) {
        throw new Error('Packs must have a \'Unsuccessful\' booking status to send this email');
      }
      break;
    default:
      break;
  }
};

const validateEmailForEditionId = (
  emailTemplate: PackEmailTemplate,
  editionId: number | null | 'No change',
  packs: Pack[],
): void => {
  const templatesRequiringEdition = [
    'BallotOutcomeOffered',
    'BallotOutcomeOfferedWithWideningAccess',
    'BallotOutcomeOfferedWithoutWideningAccess',
  ];
  if (templatesRequiringEdition.includes(emailTemplate)
    && typeof editionId !== 'number'
    && packs.some((p) => p.editionId === null)) {
    throw new Error('An edition must be selected to send this email');
  }
};

const validateEmailForWideningAccess = (
  emailTemplate: PackEmailTemplate,
  wideningAccess: WideningAccessStatus | 'No change',
  packs: Pack[],
): void => {
  switch (emailTemplate) {
    case 'BallotOutcomeOffered':
      if (
        wideningAccess !== 'DidNotApply'
        && packs.some((p) => p.wideningAccess !== 'DidNotApply')
      ) {
        throw new Error('Widening access status must be \'Did not apply\' to send this email');
      }
      break;
    case 'BallotOutcomeOfferedWithWideningAccess':
      if (
        wideningAccess !== 'Offered'
          && packs.some((p) => p.wideningAccess !== 'Offered')
      ) {
        throw new Error('Widening access status must be \'Offered\' to send this email');
      }
      break;
    case 'BallotOutcomeOfferedWithoutWideningAccess':
      if (
        wideningAccess !== 'Unsuccessful'
        && packs.some((p) => p.wideningAccess !== 'Unsuccessful')
      ) {
        throw new Error('Widening access status must be \'Unsuccessful\' to send this email');
      }
      break;
    default:
      break;
  }
};

export const processFormValuesToRequest = (
  formValues: BulkPackUpdateFormValues,
  packs: Pack[],
): UpdatePack[] => {
  const {
    editionId: editionIdValue,
    bookingStatus,
    attendedLastYear,
    attendedTwoYearsAgo,
    wideningAccess,
    registrationStatus,
    subcamp: subcampValue,
    emailTemplate,
  } = formValues;

  const editionId = editionIdValue === 'Unset' ? null : editionIdValue;
  const subcamp = subcampValue === 'Unset' ? null : subcampValue;

  if (
    [editionId, bookingStatus, attendedLastYear, attendedTwoYearsAgo, wideningAccess, registrationStatus, subcamp].every((v) => v === 'No change')
    && emailTemplate === 'No email'
  ) {
    throw new Error('No action have been specified');
  }

  const missingPreference = packs.slice().filter(
    (p) => p.editionId !== editionId
      && typeof editionId === 'number'
      && !p.preferredEditionIds.includes(editionId),
  );
  if (missingPreference.length > 0) {
    const packReferences = missingPreference.map((p) => p.reference).join(', ');
    throw new Error(`Some packs do not have the selected edition as a preference: ${packReferences}`);
  }

  if (emailTemplate !== 'No email') {
    validateEmailForBookingStatus(emailTemplate, bookingStatus, packs);
    validateEmailForEditionId(emailTemplate, editionId, packs);
    validateEmailForWideningAccess(emailTemplate, wideningAccess, packs);
  }

  const updateAttributes = [
    ['editionId', editionId],
    ['bookingStatus', bookingStatus],
    ['attendedLastYear', attendedLastYear],
    ['attendedTwoYearsAgo', attendedTwoYearsAgo],
    ['wideningAccess', wideningAccess],
    ['registrationStatus', registrationStatus],
    ['subcamp', subcamp],
  ].filter(([, value]) => value !== 'No change');
  const update = Object.fromEntries(updateAttributes) as Omit<UpdatePack, 'id'>;

  return packs.map((pack) => ({ id: pack.id, ...update }));
};

import { TableCell, TableRow } from '@mui/material';
import { ReactNode } from 'react';
import * as Styled from './styles';

type TableKeyValueRowProps = {
  title: string
  children?: ReactNode
};

const TableKeyValueSectionHeader = (props: TableKeyValueRowProps): JSX.Element => {
  const {
    title,
    children,
  } = props;

  return (
    <TableRow>
      <TableCell colSpan={2} component="th" scope="rowgroup">
        <Styled.Heading>
          {title}
        </Styled.Heading>
        {children}
      </TableCell>
    </TableRow>
  );
};

export default TableKeyValueSectionHeader;

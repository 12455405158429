import { useMediaQuery } from '@mui/material';
import {
  CartesianGrid, Legend, Line, LineChart, ReferenceLine, Tooltip, XAxis, YAxis,
} from 'recharts';
import { getHeightPx, getWidthPx } from '../../../../../components/Dashboard/config';
import DashboardCard from '../../../../../components/DashboardCard';
import DashboardCardTitle from '../../../../../components/DashboardCardTitle';
import { getMediumDate } from '../../../../../helpers/datesStrings';
import { getColourForAttendeeStatus } from '../../../../../helpers/statusColours';
import { isNumber } from '../../../../../helpers/typeValidation';
import { useGetBallotStatisticsQuery } from '../../../../../state/protectedApi/statistics';
import { useGetEditionsQuery } from '../../../../../state/publicApi/editions';
import { useTheme } from '../../../../../theme';
import colours from '../../../../../theme/colours';

const BallotSubmissionsByDay = (): JSX.Element => {
  const { data } = useGetBallotStatisticsQuery(undefined);
  const { data: editions } = useGetEditionsQuery();

  const theme = useTheme();
  const screenWiderThanMobile = useMediaQuery(theme.breakpoints.up('sm'));

  const rows = 3;
  const columns = screenWiderThanMobile ? 4 : 2;
  const nonGraphHeight = 100;
  const graphHeight = getHeightPx(rows) - nonGraphHeight;
  const graphWidth = getWidthPx(columns);

  const title = data ? `${data.summary.packCount} Ballot Entries` : 'Ballot Entries';

  const totalCapacity = editions?.reduce((accumulator, edition) => {
    const { maximumCubs } = edition;
    if (isNumber(maximumCubs)) {
      return accumulator + maximumCubs;
    }
    return accumulator;
  }, 0 as number) || 0;

  return (
    <DashboardCard rows={rows} columns={columns}>
      <DashboardCardTitle>
        {title}
      </DashboardCardTitle>
      <LineChart
        width={graphWidth}
        height={graphHeight}
        data={data && data.entriesByDay}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="7 7" />
        <XAxis dataKey="date" name="Date" tickFormatter={getMediumDate} minTickGap={6} tickMargin={5} interval={screenWiderThanMobile ? 2 : 6} />
        <YAxis orientation="left" type="number" width={screenWiderThanMobile ? 60 : 30} />
        <Tooltip
          labelFormatter={getMediumDate}
          labelStyle={{ color: colours.darkBlue }}
          separator=": "
        />
        <Legend />
        <Line
          type="monotone"
          dataKey="cubs"
          name="Cubs"
          stroke={getColourForAttendeeStatus('Cub')}
        />
        <Line
          type="monotone"
          dataKey="leaders"
          name="Leaders"
          stroke={getColourForAttendeeStatus('Leader')}
        />
        <Line
          type="monotone"
          dataKey="youngLeaders"
          name="YLs"
          stroke={getColourForAttendeeStatus('YoungLeader')}
        />
        <Line
          type="monotone"
          dataKey="otherChildren"
          name="Others"
          stroke={getColourForAttendeeStatus('OtherChild')}
        />
        {totalCapacity > 0 && (
          <ReferenceLine
            label="Maximum Cubs"
            y={totalCapacity}
            stroke={colours.red}
            strokeDasharray="3 3"
          />
        )}
      </LineChart>
    </DashboardCard>
  );
};

export default BallotSubmissionsByDay;

import {
  FormikConfig, FormikErrors, FormikTouched, FormikValues, useFormik,
} from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AnyAction } from 'redux';
import type { RootState } from '../state/store';
import { DeepRequired, NestedKeyOf, ValueAtNestedKey } from './nestedKeys';

export type SetFieldValueType<TFormValues extends FormikValues> = <
  TFieldPath extends NestedKeyOf<DeepRequired<TFormValues>>,
>(
  field: TFieldPath,
  value: ValueAtNestedKey<TFormValues, TFieldPath>,
  shouldValidate?: boolean | undefined,
) => Promise<FormikErrors<TFormValues> | void>;

export type SetFieldTouchedType<TFormValues extends FormikValues> = (
  field: NestedKeyOf<DeepRequired<TFormValues>>,
  touched?: boolean,
  shouldValidate?: boolean | undefined,
) => Promise<FormikErrors<TFormValues> | void>;

export const useTypeSafeFormik = <TFormValues extends FormikValues>(
  formikConfig: FormikConfig<TFormValues>,
): Omit<
  ReturnType<typeof useFormik<TFormValues>>, 'setFieldTouched' | 'setFieldValue' | 'errors' | 'touched'> & {
    setFieldTouched: SetFieldTouchedType<TFormValues>;
    setFieldValue: SetFieldValueType<TFormValues>;
    errors: FormikErrors<DeepRequired<TFormValues>>;
    touched: FormikTouched<DeepRequired<TFormValues>>;
  } => {
  const formik = useFormik<TFormValues>(formikConfig);

  const setFieldTouched: SetFieldTouchedType<TFormValues> = async (
    field,
    touched,
    shouldValidate,
  ) => formik.setFieldTouched(field as string, touched, shouldValidate);

  const setFieldValue: SetFieldValueType<TFormValues> = async (
    field,
    value,
    shouldValidate,
  ) => formik.setFieldValue(
    field as string,
    value,
    shouldValidate,
  );

  return {
    ...formik,
    setFieldTouched,
    setFieldValue,
  };
};

export const useFormikWithStateSync = <T extends FormikValues>(
  selector: (state: RootState) => T,
  reducer: (update: Partial<T>) => AnyAction,
  formikConfig: Omit<FormikConfig<T>, 'initialValues'>,
  sanitiseOnSubmit?: (values: T) => T,
): ReturnType<typeof useTypeSafeFormik<T>> => {
  const initialValues = useSelector(selector);
  const dispatch = useDispatch();

  const onSubmit: FormikConfig<T>['onSubmit'] = async (
    values,
    formikHelpers,
  ) => {
    const sanitisedValues = sanitiseOnSubmit?.(values) ?? values;
    dispatch(reducer(sanitisedValues));
    return formikConfig.onSubmit(sanitisedValues, formikHelpers);
  };

  const formik = useTypeSafeFormik<T>({
    ...formikConfig,
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(reducer(formik.values));
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [dispatch, reducer, formik.values]);

  return formik;
};

import { objectToCamel, objectToSnake } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { CreateDuty, Duty, UpdateDuty } from '../../models/duty';
import { RegisterItem, UpdateDutyRegisterPayload } from '../../models/register';
import { protectedApi } from './index';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getDuty: builder.query<Duty, number>({
      query: (id) => `duties/${id}`,
      transformResponse: (response: ObjectToSnake<Duty>) => objectToCamel(response),
      providesTags: (duty) => (duty ? [{ type: 'duties', id: duty.id }] : ['duties']),
    }),
    getDuties: builder.query<Duty[], void>({
      query: () => 'duties/',
      transformResponse: (response: ObjectToSnake<Duty[]>) => objectToCamel(response),
      providesTags: (duties) => (duties && duties.length
        ? duties.map((duty) => ({ type: 'duties', id: duty.id }))
        : ['duties']),
    }),
    createDuty: builder.mutation<Duty, CreateDuty>({
      query: (args) => {
        const body = objectToSnake(args);
        return ({
          url: 'duties/',
          method: 'POST',
          body,
        });
      },
      transformResponse: (response: ObjectToSnake<Duty>) => objectToCamel(response),
      invalidatesTags: ['duties'],
    }),
    updateDuty: builder.mutation<Duty, UpdateDuty>({
      query: (args) => {
        const { id, ...update } = args;
        const body = objectToSnake(update);
        return ({
          url: `duties/${id}`,
          method: 'PATCH',
          body,
        });
      },
      transformResponse: (response: ObjectToSnake<Duty>) => objectToCamel(response),
      invalidatesTags: (result, error, args) => ['duties', { type: 'duties', id: args.id }],
    }),
    deleteDuty: builder.mutation<void, number>({
      query: (id) => ({
        url: `duties/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => ['duties', { type: 'duties', id }],
    }),
    getDutyRegister: builder.query<RegisterItem[], number>({
      query: (dutyId) => `duties/register/${dutyId}`,
      transformResponse: (response: ObjectToSnake<RegisterItem[]>) => objectToCamel(response),
      providesTags: (result, error, id) => (result
        ? [{ type: 'dutyRegister', id }]
        : ['dutyRegister']),
    }),
    updateDutyRegister: builder.mutation<void, UpdateDutyRegisterPayload>({
      query: ({ dutyId, ...body }) => ({
        url: `duties/register/${dutyId}`,
        method: 'PUT',
        body: objectToSnake(body),
      }),
      invalidatesTags: (result, error, { dutyId }) => [
        { type: 'dutyRegister', id: dutyId },
      ],
    }),
    addPersonToDuty: builder.mutation<void, { dutyId: number; personId: number }>({
      query: ({ dutyId, personId }) => ({
        url: `duties/assignment/${dutyId}/${personId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { dutyId }) => [
        { type: 'dutyRegister', id: dutyId },
      ],
    }),
    deletePersonFromDuty: builder.mutation<void, { dutyId: number; personId: number }>({
      query: ({ dutyId, personId }) => ({
        url: `duties/assignment/${dutyId}/${personId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { dutyId }) => [
        { type: 'dutyRegister', id: dutyId },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDutyQuery,
  useGetDutiesQuery,
  useCreateDutyMutation,
  useUpdateDutyMutation,
  useDeleteDutyMutation,
  useGetDutyRegisterQuery,
  useUpdateDutyRegisterMutation,
  useAddPersonToDutyMutation,
  useDeletePersonFromDutyMutation,
} = extendedApi;

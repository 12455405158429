import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import styled from 'styled-components';
import { mediaQuery } from '../../helpers/responsive';
import { responsiveSpacing } from '../../helpers/responsiveSpacing';

export const Wrapper = styled.div`
  display: flex;
`;

export const RankItemList = styled.div`
  flex-grow: 1;
`;

export const ScrollSpaceWrapper = styled.div`
  margin-left: 20px;
  ${mediaQuery.medium`
    display: none;
  `}
`;

export const ScrollSpace = styled.div`
  position: sticky;
  ${responsiveSpacing('top', 'xl')}
  writing-mode: vertical-rl;
  text-orientation: mixed;
`;

export const ScrollUpArrow = styled(NorthIcon)`
  ${responsiveSpacing('margin-bottom', 'xxl')}
`;

export const ScrollDownArrow = styled(SouthIcon)`
  ${responsiveSpacing('margin-top', 'xxl')}
`;

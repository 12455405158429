import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { Badge, Button } from '@mui/material';
import { ToolbarPropsOverrides } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import DataGridToolbar from '../../../components/DataGridToolbar';
import { userHasRole } from '../../../helpers/user';
import { useGetCurrentUserQuery } from '../../../state/protectedApi/user';
import ActionsDialog from './ActionsDialog';

const BulkActionIcon = (props: { count: number }): JSX.Element => {
  const { count } = props;
  return (
    <Badge badgeContent={count} color="primary">
      <AutoAwesomeMotionIcon fontSize="small" />
    </Badge>
  );
};

const Toolbar = (props: ToolbarPropsOverrides): JSX.Element => {
  const {
    selectedPacks,
    onSuccess,
    unselectPacks,
  } = props;

  const { data: currentUser } = useGetCurrentUserQuery();
  const [open, setOpen] = useState(false);

  const count = selectedPacks.length;
  const disabled = count === 0;

  const wrappedUnselectPack = (ids: number[]): void => {
    if (selectedPacks.length === ids.length) {
      setOpen(false);
    }
    unselectPacks(ids);
  };

  const wrappedOnSuccess = async (): Promise<void> => {
    setOpen(false);
    await onSuccess();
  };

  if (!userHasRole(currentUser, 'Admin')) {
    return <DataGridToolbar />;
  }

  return (
    <>
      <DataGridToolbar>
        <Button
          startIcon={<BulkActionIcon count={count} />}
          disabled={disabled}
          onClick={() => setOpen(true)}
          size="small"
        >
          Actions
        </Button>
      </DataGridToolbar>
      <ActionsDialog
        open={open}
        unselectPacks={wrappedUnselectPack}
        packs={selectedPacks}
        onCancel={() => setOpen(false)}
        onSuccess={wrappedOnSuccess}
      />
    </>
  );
};

export default Toolbar;

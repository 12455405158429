import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type Error, selectError, update } from './index';

const useError = (): [
  Error,
  (newError: Error) => void,
] => {
  const error = useSelector(selectError);
  const dispatch = useDispatch();

  const throwError = useCallback(
    (newError: Error): void => {
      dispatch(update(newError));
      throw Error(newError.message);
    },
    [dispatch],
  );

  return [error, throwError];
};

export default useError;

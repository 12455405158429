import { objectToCamel, objectToSnake } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { Staff } from '../../models/staff';
import { protectedApi } from './index';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getStaff: builder.query<Staff, number>({
      query: (personId: number) => `staff/${personId}`,
      transformResponse: (response: ObjectToSnake<Staff>) => objectToCamel(response),
      providesTags: (result, error, personId) => [{ type: 'staff', id: personId }],
    }),
    createStaff: builder.mutation<Staff, Staff>({
      query: (args) => {
        const { personId, ...update } = args;
        const body = objectToSnake(update);
        return ({
          url: `staff/${personId}`,
          method: 'POST',
          body,
        });
      },
      invalidatesTags: (result, error, args) => [{ type: 'staff', id: args.personId }],
      transformResponse: (response: ObjectToSnake<Staff>) => objectToCamel(response),
    }),
    updateStaff: builder.mutation<Staff, Partial<Staff> & { personId: number }>({
      query: (args) => {
        const { personId, ...update } = args;
        const body = objectToSnake(update);
        return ({
          url: `staff/${personId}`,
          method: 'PATCH',
          body,
        });
      },
      invalidatesTags: (result, error, args) => [{ type: 'staff', id: args.personId }],
      transformResponse: (response: ObjectToSnake<Staff>) => objectToCamel(response),
    }),
    deleteStaff: builder.mutation<void, number>({
      query: (personId) => ({
        url: `staff/${personId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, personId) => [{ type: 'staff', id: personId }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetStaffQuery,
  useCreateStaffMutation,
  useUpdateStaffMutation,
  useDeleteStaffMutation,
} = extendedApi;

import { Dispatch, useEffect, useState } from 'react';
import { Edition } from '../models/edition';
import { useGetEditionsForCurrentUserQuery } from '../state/protectedApi/editions';
import { useGetEditionsQuery } from '../state/publicApi/editions';
import { useUrlOptionalNumberParam } from './useUrlParam';

const compareEditions = (a: Edition, b: Edition): number => {
  const now = new Date();
  const aStart = new Date(a.start);
  const bStart = new Date(b.start);
  const aEnd = new Date(a.end);
  const bEnd = new Date(b.end);

  if (aEnd < now && bEnd > now) {
    return 1;
  }
  if (aStart < bStart) {
    return 1;
  }
  if (aStart > bStart) {
    return -1;
  }
  return 0;
};

const selectDefaultEdition = (editions?: Edition[]): Edition | undefined => {
  if (!editions) {
    return undefined;
  }
  if (editions.length === 0) {
    throw new Error('No editions found to select as a default');
  }
  const sortedEditions = editions.slice().sort(compareEditions);
  return sortedEditions[0];
};

const useDefaultEditionSelection = (
  editions?: Edition[],
): [Edition | undefined, Dispatch<number | undefined>] => {
  const [editionId, setEditionId] = useUrlOptionalNumberParam('edition');
  const [hasBeenSet, setHasBeenSet] = useState(false);

  const defaultEdition = selectDefaultEdition(editions);

  useEffect(() => {
    if (!hasBeenSet && editionId === undefined && defaultEdition !== undefined) {
      setEditionId(defaultEdition.id);
      setHasBeenSet(true);
    }
  }, [defaultEdition, editionId, hasBeenSet, setEditionId]);

  const selectedEdition = editions?.find((edition) => edition.id === editionId);
  return [selectedEdition, setEditionId];
};

type UseDefaultEditionReturn = [Edition | undefined, Dispatch<number | undefined>];

export const useDefaultEdition = (): UseDefaultEditionReturn => {
  const { data } = useGetEditionsQuery();
  return useDefaultEditionSelection(data);
};

export const useDefaultEditionForUser = (): UseDefaultEditionReturn => {
  const { data } = useGetEditionsForCurrentUserQuery();
  return useDefaultEditionSelection(data);
};

import { GridColDef } from '@mui/x-data-grid-pro';
import { eventPhaseOrLater } from '../../../helpers/eventConfig';
import { isBookingActive, isBookingCancelled } from '../../../helpers/pack';
import { EventConfig } from '../../../models/eventConfig';
import { ByPersonStatus, Pack } from '../../../models/pack';

type ValueGetter<TValue = unknown> = (
  value: TValue,
  row: Pack,
) => TValue | undefined;

export const showIfBookingActive: ValueGetter = (value, row) => {
  if (isBookingActive(row)) {
    return value;
  }
  return undefined;
};

export const hideIfBookingCancelled: ValueGetter = (value, row) => {
  if (isBookingCancelled(row)) {
    return undefined;
  }
  return value;
};

const getStatusHeaderByKey = (status: keyof ByPersonStatus): string => {
  switch (status) {
    case 'cubs':
      return 'Cubs';
    case 'youngLeaders':
      return 'YLs';
    case 'leaders':
      return 'Leaders';
    case 'otherChildren':
      return 'Other';
    default:
      return '';
  }
};

export const getColumnForStatus = (
  status: keyof ByPersonStatus,
  config?: EventConfig,
): GridColDef<Pack, number | undefined> => ({
  field: status,
  headerName: getStatusHeaderByKey(status),
  width: 100,
  align: 'center',
  headerAlign: 'center',
  type: 'number',
  valueGetter: (value: never, row: Pack) => {
    if (isBookingCancelled(row) || !config) {
      return undefined;
    }
    if (row.bookingStatus === 'Accepted' && eventPhaseOrLater(config, 'Allocation')) {
      return row.registrations[status];
    }
    return row.bookings[status];
  },
});

import { TableRow } from '@mui/material';
import { ReactNode } from 'react';
import MultiLineText from '../MultiLineText';
import { ParagraphWithoutTopMargin } from '../Paragraph';
import TableCell from '../TableCell';

type TableKeyValueRowProps = {
  name: string
  borderBottom?: boolean
  valueCellAlign?: 'left' | 'right'
  forceSingleColumn?: boolean
  children: ReactNode
};

// TODO: Vary the limit based on the screen size
const MULTI_COLUMN_CHARACTER_LIMIT = 50;

const TableKeyValueRow = (props: TableKeyValueRowProps): JSX.Element => {
  const {
    name,
    borderBottom = true,
    valueCellAlign = 'left',
    forceSingleColumn = false,
    children,
  } = props;

  const isString = typeof children === 'string';
  const isMultiColumn = (!isString || children.length <= MULTI_COLUMN_CHARACTER_LIMIT)
    && !forceSingleColumn;

  const tableCellStyles = borderBottom ? undefined : {
    borderBottom: 'none',
  };

  return (
    <TableRow>
      {isMultiColumn && (
        <TableCell component="th" scope="row" sx={tableCellStyles}>
          <strong>{name}</strong>
        </TableCell>
      )}
      <TableCell colSpan={isMultiColumn ? 1 : 2} sx={tableCellStyles} align={valueCellAlign}>
        {!isMultiColumn && (
          <ParagraphWithoutTopMargin>
            <strong>{name}</strong>
          </ParagraphWithoutTopMargin>
        )}
        {!isString ? children : (
          <MultiLineText>
            {children}
          </MultiLineText>
        )}
      </TableCell>
    </TableRow>
  );
};

export default TableKeyValueRow;

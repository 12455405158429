import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface DataGridToolbarProps {
  children?: ReactNode
  createRoute?: string
  refetch?: () => void
}

const DataGridToolbar = (props: DataGridToolbarProps): JSX.Element => {
  const { children, createRoute, refetch } = props;

  const navigate = useNavigate();

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      {createRoute && (
        <Button
          startIcon={<AddIcon />}
          onClick={() => navigate(createRoute)}
          size="small"
        >
          Create
        </Button>
      )}
      {refetch && (
        <Button
          startIcon={<RefreshIcon />}
          onClick={refetch}
          size="small"
        >
          Refresh
        </Button>
      )}
      {children}
    </GridToolbarContainer>
  );
};

export default DataGridToolbar;

import {
  Backdrop, Checkbox, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Select,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Dashboard from '../../../components/Dashboard';
import { SubGrid } from '../../../components/Dashboard/styles';
import DashboardBooleanPieChart from '../../../components/DashboardBooleanPieChart';
import DashboardPieChart from '../../../components/DashboardPieChart';
import { FormGrid } from '../../../components/FormGrid';
import PageMetaTitle from '../../../components/PageMetaTitle';
import { eventPhaseOrLater } from '../../../helpers/eventConfig';
import { mapReviewStatusBreakdownToCountData } from '../../../helpers/statistics';
import useErrorHandler from '../../../helpers/useErrorHandler';
import { useUrlOptionalNumberParam } from '../../../helpers/useUrlParam';
import { userHasRole } from '../../../helpers/user';
import { useGetEditionsForCurrentUserQuery } from '../../../state/protectedApi/editions';
import { useGetStatisticsQuery } from '../../../state/protectedApi/statistics';
import { useGetCurrentUserQuery } from '../../../state/protectedApi/user';
import { useGetEventConfigQuery } from '../../../state/publicApi';
import ActivitiesByRankedPreference from './Dashlets/ActivitiesByRankedPreference';
import BallotByEdition from './Dashlets/BallotByEdition';
import BallotByLocation from './Dashlets/BallotByLocation';
import BallotByPreviousAttendance from './Dashlets/BallotByPreviousAttendance';
import BallotBySimdDecile from './Dashlets/BallotBySimdDecile';
import BallotByWideningAccess from './Dashlets/BallotByWideningAccess';
import BallotMap from './Dashlets/BallotMap';
import BallotSubmissionsByDay from './Dashlets/BallotSubmissionsByDay';
import BallotTravelMethods from './Dashlets/BallotTravelMethods';
import LastGeneratedTimestamp from './Dashlets/LastGeneratedTimestamp';
import MissingRegistrations from './Dashlets/MissingRegistrations';
import PackRegistrationProgress from './Dashlets/PackRegistrationProgress';
import DashboardQuickLinks from './Dashlets/QuickLinks';
import RegistrationsBySandwich from './Dashlets/RegistrationsBySandwich';
import RegistrationsBySimdDecile from './Dashlets/RegistrationsBySimdDecile';
import RegistrationsMap from './Dashlets/RegistrationsMap';
import StaffRegistered from './Dashlets/StaffRegistered';
import DashboardTimeline from './Dashlets/Timeline';

const DashboardPage = (): JSX.Element => {
  const [displayNotRequired, setDisplayNotRequired] = useState(false);

  const { data: user } = useGetCurrentUserQuery();
  const { data: editions } = useGetEditionsForCurrentUserQuery();
  const [editionId, setEditionId] = useUrlOptionalNumberParam('edition');

  useEffect(() => {
    if (editions?.length === 1) {
      setEditionId(editions[0].id);
    }
  }, [editions, setEditionId]);

  const {
    data: eventConfig,
  } = useGetEventConfigQuery();

  const {
    currentData: stats,
    isFetching: statsLoading,
    error: statsError,
  } = useGetStatisticsQuery(editionId);
  useErrorHandler(statsError, 'API Error: Failed to load statistics');

  if (!eventConfig) {
    return (
      <Backdrop open>
        <PageMetaTitle title="Dashboard" />
        <CircularProgress />
      </Backdrop>
    );
  }

  const showReviewCharts = eventPhaseOrLater(eventConfig, 'Registration');
  const showPackRegistrationsStats = eventPhaseOrLater(eventConfig, 'Registration');
  const showActivityPreferences = eventPhaseOrLater(eventConfig, 'Allocation')
    || (eventPhaseOrLater(eventConfig, 'Registration') && userHasRole(user, ['Admin', 'Activities']));

  const selectedEdition = editions?.find((edition) => edition.id === editionId);
  const pageTitle = selectedEdition ? `${selectedEdition.name} Dashboard` : 'Dashboard';

  return (
    <Dashboard filterBar={showReviewCharts && !userHasRole(user, 'PackLeader', false) && (
      <FormGrid noBottomMargin columns={2} style={{ width: '100%' }}>
        <FormControl fullWidth sx={{ textAlign: 'left' }}>
          <InputLabel id="edition-filter-label">Edition</InputLabel>
          <Select
            labelId="edition-filter-label"
            id="edition-filter"
            value={editionId}
            disabled={!editions || editions.length === 1}
            label="Edition"
            onChange={(event) => {
              if (typeof event.target.value === 'number') {
                setEditionId(event.target.value);
              } else {
                setEditionId(undefined);
              }
            }}
          >
            <MenuItem>
              <em>All</em>
            </MenuItem>
            {editions?.map((edition) => (
              <MenuItem key={edition.id} value={edition.id}>{edition.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          label="Display not required review status"
          control={(
            <Checkbox
              checked={displayNotRequired}
              onChange={(event) => setDisplayNotRequired(event.target.checked)}
            />
          )}
        />
      </FormGrid>
    )}
    >
      <PageMetaTitle title={pageTitle} />
      <DashboardTimeline />
      <DashboardQuickLinks />
      {userHasRole(user, ['Admin', 'Activities', 'Subcamp', 'Catering']) && (
        <SubGrid columns={2} rows={1}>
          <LastGeneratedTimestamp stats={stats} />
          <StaffRegistered stats={stats} />
        </SubGrid>
      )}
      {eventConfig.phases.current === 'Booking' && userHasRole(user, 'Admin') && (
        <>
          <BallotByEdition />
          <BallotMap />
          <BallotTravelMethods />
          <BallotByWideningAccess />
          <BallotByPreviousAttendance />
          <BallotBySimdDecile />
          <BallotSubmissionsByDay />
          <BallotByLocation />
        </>
      )}
      {eventPhaseOrLater(eventConfig, 'Registration')
        && userHasRole(user, ['Catering', 'Admin', 'Activities', 'Subcamp'])
        && (
          <>
            <RegistrationsBySimdDecile stats={stats} />
            <MissingRegistrations stats={stats} isLoading={statsLoading} />
            <RegistrationsMap editionId={editionId} />
          </>
        )}
      {showPackRegistrationsStats && <PackRegistrationProgress stats={stats} />}
      <RegistrationsBySandwich stats={stats} />
      {eventPhaseOrLater(eventConfig, 'Registration') && (
        <>
          <DashboardBooleanPieChart title="Media" data={stats?.mediaConsent} includeNotSet />
          <DashboardBooleanPieChart title="Paracetamol" data={stats?.painkillerConsent} />
          <DashboardBooleanPieChart title="Antihistamine" data={stats?.antihistamineConsent} />
          <DashboardBooleanPieChart title="Suncream" data={stats?.suncreamConsent} />
          <DashboardBooleanPieChart title="After sun" data={stats?.afterSunConsent} />
        </>
      )}
      {showActivityPreferences && stats && stats.activities.length > 0 && (
        <ActivitiesByRankedPreference stats={stats} />
      )}
      {showReviewCharts && userHasRole(user, 'Admin') && (
        <DashboardPieChart
          title="Core team reviews"
          data={mapReviewStatusBreakdownToCountData(stats?.reviews.admin, displayNotRequired)}
        />
      )}
      {showReviewCharts && userHasRole(user, 'Admin') && (
        <DashboardPieChart
          title="Compass reviews"
          data={mapReviewStatusBreakdownToCountData(stats?.reviews.compass, displayNotRequired)}
        />
      )}
      {showReviewCharts && userHasRole(user, ['Activities', 'Admin']) && (
        <DashboardPieChart
          title="Activity reviews"
          data={mapReviewStatusBreakdownToCountData(stats?.reviews.activities, displayNotRequired)}
        />
      )}
      {showReviewCharts && userHasRole(user, ['Catering', 'Admin']) && (
        <DashboardPieChart
          title="Catering reviews"
          data={mapReviewStatusBreakdownToCountData(stats?.reviews.catering, displayNotRequired)}
        />
      )}
      {showReviewCharts && userHasRole(user, ['Subcamp', 'Admin']) && (
        <DashboardPieChart
          title="Subcamp reviews"
          data={mapReviewStatusBreakdownToCountData(stats?.reviews.subcamp, displayNotRequired)}
        />
      )}
      {/* TODO: add a pack leader reviews chart for subcamp users for specifically their subcamp */}
      {showReviewCharts && userHasRole(user, ['PackLeader', 'Admin']) && (
        <DashboardPieChart
          title="Pack leader reviews"
          data={mapReviewStatusBreakdownToCountData(stats?.reviews.packLeader, displayNotRequired)}
        />
      )}
    </Dashboard>
  );
};

export default DashboardPage;

import { Backdrop, CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { auth } from '../firebase';
import routes from '../routes';
import { reset as resetBooking } from '../state/booking';
import { strapiContent } from '../state/content';
import { reset as resetError } from '../state/error';
import { reset as resetGrids } from '../state/grids';
import { protectedApi } from '../state/protectedApi';
import { publicApi } from '../state/publicApi';
import { reset as resetRegistration } from '../state/registration';

const Reset = (): JSX.Element => {
  auth.signOut().catch(console.error);

  const dispatch = useDispatch();
  dispatch(resetBooking());
  dispatch(resetGrids());
  dispatch(resetRegistration([]));
  dispatch(resetError());
  dispatch(protectedApi.util.resetApiState());
  dispatch(publicApi.util.resetApiState());
  dispatch(strapiContent.util.resetApiState());

  window.localStorage.clear();
  window.sessionStorage.clear();

  window.location.replace(routes.home);

  return (
    <Backdrop open>
      <CircularProgress />
    </Backdrop>
  );
};

export default Reset;

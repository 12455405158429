import axios from 'axios';
import queryString from 'query-string';
import { auth } from '../firebase';

const baseUrl = process.env.REACT_APP_API_BASE_URL ?? '';
const apiUrl = `${baseUrl}/api`;

const apiService = {
  get: async <T>(
    path: string,
    queryParams?: Record<string, string | undefined>,
    addToken?: boolean,
  ): Promise<T> => {
    // Undefined query params are automatically filtered out by 'stringify'
    const serialisedQueryParams = queryParams ? `?${queryString.stringify(queryParams)}` : '';
    const token = await auth.currentUser?.getIdToken();
    const result = await axios.get<T>(`${apiUrl}/${path}${serialisedQueryParams}`, {
      headers: addToken && token ? {
        Authorization: `Bearer ${token}`,
      } : {},
    });
    return result.data;
  },
  post: async <T>(
    path: string,
    body: unknown,
    queryParams?: Record<string, string | undefined>,
  ): Promise<T> => {
    // Undefined query params are automatically filtered out by 'stringify'
    const serialisedQueryParams = queryParams ? `?${queryString.stringify(queryParams)}` : '';
    const result = await axios.post<T>(
      `${apiUrl}/${path}${serialisedQueryParams}`,
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      },
    );
    return result.data;
  },
};

export default apiService;

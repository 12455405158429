import {
  AlertTitle,
  Chip,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Tooltip,
} from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid-pro';
import {
  ReactNode, useEffect, useRef, useState,
} from 'react';
import { AlertWithMargin } from '../Alert';

export type BulkActionError = {
  title: string,
  details: string[]
} | null;

type DataGridBulkActionDialogProps<TRow extends GridValidRowModel> = {
  items: TRow[]
  open: boolean
  onCancel: () => void
  getId: (item: TRow) => number
  getReference: (item: TRow) => string
  getName: (item: TRow) => string
  unselectItem: (id: number) => void
  error: BulkActionError
  title: string
  description: string
  children: ReactNode
};

const DataGridBulkActionDialog = <
  TRow extends GridValidRowModel,
>(props: DataGridBulkActionDialogProps<TRow>): JSX.Element => {
  const {
    items,
    open,
    onCancel,
    getReference,
    getName,
    getId,
    unselectItem,
    error,
    title,
    description,
    children,
  } = props;

  const maxChips = 5;
  const [showAll, setShowAll] = useState(false);

  const errorAlertRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (errorAlertRef.current !== null) {
      errorAlertRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, [error]);

  const getElementId = (suffix: string): string => `bulk-action-dialog-${suffix}`;

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby={getElementId('title')}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id={getElementId('title')}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description}
        </DialogContentText>
        <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
          {items.filter((item, index) => showAll || index < maxChips).map((item) => (
            <Tooltip
              key={getId(item)}
              title={getName(item)}
              arrow
            >
              <Chip
                label={getReference(item)}
                size="small"
                onDelete={() => unselectItem(getId(item))}
              />
            </Tooltip>
          ))}
          {items.length > maxChips && (
            <Chip
              label={showAll ? 'Hide' : `+${items.length - maxChips}`}
              size="small"
              variant="outlined"
              onClick={() => setShowAll(!showAll)}
            />
          )}
        </Stack>
        <Collapse in={error !== null}>
          {error && (
          <AlertWithMargin severity="error" ref={errorAlertRef}>
            <AlertTitle>{error.title}</AlertTitle>
            <ul>
              {error.details.map((detail, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>{detail}</li>
              ))}
            </ul>
          </AlertWithMargin>
          )}
        </Collapse>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default DataGridBulkActionDialog;

import { AlertTitle, Button, Alert as MuiAlert } from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ActionType } from '../../../helpers/actionType';
import { isApiHttpError } from '../../../helpers/errorTypes';
import { AlertTitleWithoutMargin, AlertWithBottomMargin } from '../../Alert';

const getErrorAlertTitle = (actionType: ActionType, modelName: string): string => {
  switch (actionType) {
    case 'load':
      return `Error loading ${modelName}`;
    case 'create':
      return `Error creating ${modelName}`;
    case 'update':
      return `Error updating ${modelName}`;
    case 'delete':
      return `Error deleting ${modelName}`;
    default:
      return 'An unexpected error has occurred';
  }
};

export type ErrorAlertProps = {
  modelName: string
  type: ActionType
  error: SerializedError | FetchBaseQueryError
  onCreateClick?: () => void
  bottomMargin?: boolean
};

// TODO: Consider switching to FC type declarations everywhere
const ErrorAlert = (props: ErrorAlertProps): JSX.Element => {
  const {
    modelName,
    type,
    error,
    onCreateClick,
    bottomMargin = true,
  } = props;
  const lowercaseModelName = modelName.toLowerCase();

  const Alert = bottomMargin ? AlertWithBottomMargin : MuiAlert;

  const noDataFound = error && 'status' in error && error.status === 404;
  if (noDataFound) {
    return (
      <Alert
        severity="warning"
        action={onCreateClick && (
          <Button color="inherit" onClick={onCreateClick} size="small">
            Create
          </Button>
        )}
      >
        <AlertTitleWithoutMargin>{`No ${lowercaseModelName} found`}</AlertTitleWithoutMargin>
      </Alert>
    );
  }

  return (
    <Alert severity="error">
      <AlertTitle>{getErrorAlertTitle(type, lowercaseModelName)}</AlertTitle>
      {isApiHttpError(error) && error.data.message}
    </Alert>
  );
};

export default ErrorAlert;

import {
  Backdrop, Button, CircularProgress, MenuItem, TextField,
} from '@mui/material';
import { FC } from 'react';
import CardFooter from '../../../components/CardFooter';
import { FormGrid } from '../../../components/FormGrid';
import { FormProps } from '../../../components/forms/types';
import { CreateActivity } from '../../../models/activity';
import { activityCategoryOptions, isValidActivityCategory } from '../../../models/activityCategory';
import { activityDeliveryOptions, isValidActivityDelivery } from '../../../models/activityDelivery';

const ActivityForm: FC<FormProps<CreateActivity>> = (props) => {
  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = props;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <FormGrid columns={2}>
        <TextField
          label="Name"
          value={values.name}
          onBlur={() => setFieldTouched('name')}
          onChange={async (event) => {
            await setFieldValue('name', event.target.value);
          }}
          error={touched.name && Boolean(errors.name)}
          helperText={(touched.name && errors.name) || ' '}
        />
      </FormGrid>
      <FormGrid columns={2}>
        <TextField
          select
          label="Category"
          value={values.category}
          onBlur={() => setFieldTouched('category')}
          onChange={async (event) => {
            if (isValidActivityCategory(event.target.value)) {
              await setFieldValue('category', event.target.value);
            }
          }}
          error={touched.category && Boolean(errors.category)}
          helperText={(touched.category && errors.category) || ' '}
        >
          {activityCategoryOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Delivery"
          value={values.delivery}
          onBlur={() => setFieldTouched('delivery')}
          onChange={async (event) => {
            if (isValidActivityDelivery(event.target.value)) {
              await setFieldValue('delivery', event.target.value);
            }
          }}
          error={touched.delivery && Boolean(errors.delivery)}
          helperText={(touched.delivery && errors.delivery) || ' '}
        >
          {activityDeliveryOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </FormGrid>
      <FormGrid columns={3}>
        <TextField
          label="Target Participants"
          value={values.targetParticipants}
          onBlur={() => setFieldTouched('targetParticipants')}
          onChange={async (event) => {
            const newValue = Number(event.target.value);
            if (!Number.isNaN(newValue)) {
              await setFieldValue('targetParticipants', newValue);
            }
          }}
          error={touched.targetParticipants && Boolean(errors.targetParticipants)}
          helperText={(touched.targetParticipants && errors.targetParticipants) || ' '}
        />
        <TextField
          label="Target Leaders"
          value={values.targetLeaders}
          onBlur={() => setFieldTouched('targetLeaders')}
          onChange={async (event) => {
            const newValue = Number(event.target.value);
            if (!Number.isNaN(newValue)) {
              await setFieldValue('targetLeaders', newValue);
            }
          }}
          error={touched.targetLeaders && Boolean(errors.targetLeaders)}
          helperText={(touched.targetLeaders && errors.targetLeaders) || ' '}
        />
        <TextField
          label="Target Adult Leaders"
          value={values.targetAdultLeaders}
          onBlur={() => setFieldTouched('targetAdultLeaders')}
          onChange={async (event) => {
            const newValue = Number(event.target.value);
            if (!Number.isNaN(newValue)) {
              await setFieldValue('targetAdultLeaders', newValue);
            }
          }}
          error={touched.targetAdultLeaders && Boolean(errors.targetAdultLeaders)}
          helperText={(touched.targetAdultLeaders && errors.targetAdultLeaders) || ' '}
        />
      </FormGrid>

      <CardFooter>
        <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </CardFooter>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </form>
  );
};

export default ActivityForm;

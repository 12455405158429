import { OptionList } from '../helpers/options';

export const reviewStatuses = [
  'NotRequired',
  'Pending',
  'SeekingInformation',
  'Flagged',
  'Complete',
] as const;

export type ReviewStatus = (typeof reviewStatuses)[number];

export const isValidReviewStatus = (
  status: string,
): status is ReviewStatus => reviewStatuses.includes(status as never);

export const getReviewStatusLabel = (status: ReviewStatus): string => {
  switch (status) {
    case 'NotRequired':
      return 'Not required';
    case 'SeekingInformation':
      return 'Seeking information';
    default:
      return status;
  }
};

export const reviewStatusOptions: OptionList<ReviewStatus> = reviewStatuses.map((status) => ({
  label: getReviewStatusLabel(status),
  value: status,
}));

import styled from 'styled-components';
import { responsiveWidth } from '../../helpers/responsiveWidth';

type ContentLayoutProps = {
  desktop?: number;
  tablet?: number;
  mobile?: number;
};

const ContentLayout = styled.div<ContentLayoutProps>`
  ${({
    desktop = 10,
    tablet = 7,
    mobile = 4,
  }) => responsiveWidth({
    desktop,
    tablet,
    mobile,
  })}
`;

export default ContentLayout;

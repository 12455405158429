import { objectToCamel, objectToSnake } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { ActivitySupport } from '../../models/activitySupport';
import { protectedApi } from './index';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getActivitySupport: builder.query<ActivitySupport, number>({
      query: (cubId) => `activities/support/${cubId}`,
      transformResponse: (response: ObjectToSnake<ActivitySupport>) => objectToCamel(response),
    }),
    updateActivitySupport: builder.mutation<ActivitySupport, ActivitySupport>({
      query: ({ cubId, ...data }) => {
        const body = objectToSnake(data);
        return ({
          url: `activities/support/${cubId}`,
          method: 'PATCH',
          body,
        });
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetActivitySupportQuery,
  useUpdateActivitySupportMutation,
} = extendedApi;

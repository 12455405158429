import styled, { css } from 'styled-components';

type MainStyleProps = {
  menuOpen: boolean
  appBarHeight: number
  limitContentHeight: boolean
  limitContentWidth: boolean
};

export const Main = styled.div<MainStyleProps>`
  flex-grow: 1;
  
  ${({ limitContentWidth }) => (limitContentWidth && css`
    padding: 10px;
    display: flex;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      padding: 20px;
      transition: ${({ theme }) => theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};
  `)}
  justify-content: space-around;

  ${({ appBarHeight, limitContentHeight }) => (limitContentHeight ? css`
    height: calc(100vh - ${appBarHeight}px);
  ` : css`
    min-height: calc(100vh - ${appBarHeight}px);
  `)}

    ${(props) => props.menuOpen && css`
      transition: ${({ theme }) => theme.transitions.create('margin', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  })}
  `}
  }
  
  ${({ appBarHeight }) => css`
    & * {
      scroll-margin-top: ${appBarHeight + 15}px;
    }
  `}
`;

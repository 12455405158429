export const userRoles = [
  'SuperUser',
  'Admin',
  'Catering',
  'Activities',
  'Subcamp',
  'PackLeader',
] as const;

export type UserRole = (typeof userRoles)[number];

export const isValidUserRole = (
  value: string,
): value is UserRole => userRoles.includes(value as never);

export const getUserRoleLabel = (status: UserRole): string => {
  switch (status) {
    case 'SuperUser':
      return 'Super user';
    case 'PackLeader':
      return 'Pack leader';
    default:
      return status;
  }
};

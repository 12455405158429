import { Backdrop, CircularProgress } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import BlocksRenderer from '../../components/BlocksRenderer';
import Card from '../../components/Card';
import CardHeading from '../../components/CardHeading';
import { useRegistrationsContent } from '../../helpers/content';
import useErrorHandler from '../../helpers/useErrorHandler';
import { isPackCode, isStaffCode } from '../../models/registrationCode';
import { useRegistrationCodeOrError } from '../../routes/registrationsRoutes';
import useError from '../../state/error/useError';
import { useGetEventConfigQuery } from '../../state/publicApi';
import { useGetActivitiesQuery } from '../../state/publicApi/activities';
import { useCheckRegistrationCodeQuery } from '../../state/publicApi/registration';
import { updateMeta } from '../../state/registration';
import { NewRegistration } from '../../state/registration/types';

const Registrations = (): JSX.Element => {
  const { content } = useRegistrationsContent();
  const [, throwError] = useError();
  const registrationCode = useRegistrationCodeOrError();
  const dispatch = useDispatch();

  const updateRegistrationMeta = useCallback(
    (newRegistrationMeta: Partial<NewRegistration['meta']>): void => {
      dispatch(updateMeta(newRegistrationMeta));
    },
    [dispatch],
  );

  const {
    data: eventConfig,
    error: eventConfigError,
  } = useGetEventConfigQuery();
  useErrorHandler(eventConfigError, 'API Error: Failed to load event configuration');

  const {
    data: activities,
    error: activitiesError,
  } = useGetActivitiesQuery();
  useErrorHandler(activitiesError, 'API Error: Failed to load activities');

  const {
    data: codeCheck,
    error: registrationCodeCheckError,
  } = useCheckRegistrationCodeQuery(registrationCode ?? skipToken);
  useErrorHandler(registrationCodeCheckError, 'API Error: Failed to check registration code');

  useEffect(() => {
    if (codeCheck) {
      if (!codeCheck.valid) {
        throwError({ message: 'The registration link is invalid.' });
      } else if (isStaffCode(codeCheck)) {
        updateRegistrationMeta({
          type: 'staff',
          pack: undefined,
        });
      } else if (isPackCode(codeCheck)) {
        updateRegistrationMeta({
          type: 'pack',
          pack: codeCheck.packName,
        });
      }
    }
  }, [
    codeCheck,
    throwError,
    updateRegistrationMeta,
  ]);

  if (!eventConfig || !activities || !codeCheck || !codeCheck.valid || !content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  if (isStaffCode(codeCheck)) {
    return <Outlet />;
  }

  switch (codeCheck.status) {
    case 'Open':
      return <Outlet />;
    case 'Complete':
      return (
        <Card>
          <CardHeading>{content.complete.title}</CardHeading>
          <BlocksRenderer content={content.complete.body} />
        </Card>
      );
    case 'Closed':
    default:
      return (
        <Card>
          <CardHeading>{content.closed.title}</CardHeading>
          <BlocksRenderer content={content.closed.body} />
        </Card>
      );
  }
};

export default Registrations;

import { ActivityScheduleItem } from '../../../../../models/activitySchedule';

const sanitiseOnSubmit = (
  rawValues: ActivityScheduleItem[],
): ActivityScheduleItem[] => rawValues.map((rawValue) => ({
  ...rawValue,
  leaderRole: rawValue.activityId ? rawValue.leaderRole : undefined,
}));

export default sanitiseOnSubmit;

import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import registrationsService from '../../../api/registrations';
import BlocksRenderer from '../../../components/BlocksRenderer';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import IconLink from '../../../components/IconLink';
import { ParagraphWithinTable } from '../../../components/ParagraphWithinTable';
import RankingInput from '../../../components/RankingInput';
import ReviewTable from '../../../components/ReviewTable';
import ReviewHeaderRow from '../../../components/ReviewTable/ReviewHeaderRow';
import ReviewRow from '../../../components/ReviewTable/ReviewRow';
import { useRegistrationsContent } from '../../../helpers/content';
import { getLongDate } from '../../../helpers/datesStrings';
import { getEditionSummary } from '../../../helpers/edition';
import { useFormikWithStateSync } from '../../../helpers/form';
import { getFullName } from '../../../helpers/person';
import { showRegistrationActivitiesFormPage } from '../../../helpers/registrations';
import { getGenderLabel } from '../../../models/gender';
import { getPersonStatusLabel } from '../../../models/personStatus';
import { getStaffTeamLabel } from '../../../models/staffTeam';
import routes from '../../../routes';
import { useRegistrationCodeOrError } from '../../../routes/registrationsRoutes';
import useError from '../../../state/error/useError';
import { useGetEventConfigQuery } from '../../../state/publicApi';
import { useGetEditionsQuery } from '../../../state/publicApi/editions';
import { selectRegistration, update } from '../../../state/registration';
import { isPackRegistration, isStaffRegistration } from '../../../state/registration/types';
import useRegistration from '../../../state/registration/useRegistration';
import sanitiseOnSubmit from './sanitise';
import { validationSchema } from './schema';
import * as Styled from './styles';

const RegistrationReviewPage = (): JSX.Element => {
  const { content } = useRegistrationsContent();
  const [registration] = useRegistration();
  const registrationCode = useRegistrationCodeOrError();
  const navigate = useNavigate();
  const [, throwError] = useError();
  const [registrationErrored, setRegistrationErrored] = useState(false);

  const { data: eventConfig } = useGetEventConfigQuery();
  const { data: editions } = useGetEditionsQuery(undefined, {
    skip: !registration.attendee.editions.length,
  });

  const getEditionSummaryById = (
    editionId: number,
  ): string => {
    const edition = editions?.find((e) => e.id === editionId);
    if (eventConfig && edition) {
      return getEditionSummary(eventConfig, edition);
    }
    return '';
  };

  useEffect(() => {
    if (registrationErrored) {
      throwError({ message: 'API Error: Failed to create registration' });
    }
  }, [registrationErrored, throwError]);

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormikWithStateSync(
    selectRegistration,
    update,
    {
      validationSchema,
      onSubmit: async (data) => {
        await registrationsService.create(registrationCode, data).then(() => {
          navigate(routes.registrations.confirmation(registrationCode));
        }).catch(() => {
          setRegistrationErrored(true);
        });
      },
    },
    sanitiseOnSubmit,
  );

  const previousPageRoute = showRegistrationActivitiesFormPage(registration)
    ? routes.registrations.activities(registrationCode)
    : routes.registrations.doctor(registrationCode);

  if (!eventConfig || !registration || !content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  const termsContent = isPackRegistration(registration)
    ? content.review.pack_terms : content.review.staff_terms;

  return (
    <Card>
      <CardHeading>{content.review.page_title}</CardHeading>
      <hr />
      <ReviewTable>
        <ReviewHeaderRow>About you</ReviewHeaderRow>
        <ReviewRow name="Name" route={routes.registrations.contact(registrationCode)}>
          {getFullName(registration.mainContact)}
        </ReviewRow>
        <ReviewRow name="Mobile" route={routes.registrations.contact(registrationCode)}>
          {registration.mainContact.mobile}
        </ReviewRow>
        <ReviewRow name="Email" route={routes.registrations.contact(registrationCode)}>
          {registration.mainContact.email}
        </ReviewRow>
        {!registration.meta.registeringSelf && (
          <>
            <ReviewRow
              name={`Relationship to ${registration.attendee.firstName}`}
              route={routes.registrations.contact(registrationCode)}
            >
              {registration.mainContact.relationship}
            </ReviewRow>
            <ReviewRow
              name="Contact in an emergency"
              route={routes.registrations.emergencyContacts(registrationCode)}
            >
              {registration.mainContact.emergency ? 'Yes' : 'No'}
            </ReviewRow>
            <ReviewRow name="Also attending" route={routes.registrations.emergencyContacts(registrationCode)}>
              {registration.mainContact.attending ? 'Yes' : 'No'}
            </ReviewRow>
            <ReviewHeaderRow>
              About your young person
            </ReviewHeaderRow>
            <ReviewRow name="Status" route={routes.registrations.attendee(registrationCode)}>
              {registration.attendee.status && getPersonStatusLabel(registration.attendee.status)}
            </ReviewRow>
            <ReviewRow name="Name" route={routes.registrations.attendee(registrationCode)}>
              {getFullName(registration.attendee)}
            </ReviewRow>
          </>
        )}
        <ReviewRow name="Gender" route={routes.registrations.attendee(registrationCode)}>
          {registration.attendee.gender && getGenderLabel(registration.attendee.gender)}
        </ReviewRow>
        <ReviewRow name="Date of birth" route={routes.registrations.attendee(registrationCode)}>
          {getLongDate(registration.attendee.dateOfBirth ?? '')}
        </ReviewRow>
        {registration.attendee.status === 'YoungLeader' && (
          <>
            <ReviewRow name="Mobile" route={routes.registrations.attendee(registrationCode)}>
              {registration.attendee.mobile}
            </ReviewRow>
            <ReviewRow name="Email" route={routes.registrations.attendee(registrationCode)}>
              {registration.attendee.email}
            </ReviewRow>
          </>
        )}
        <ReviewRow name="Address" route={routes.registrations.attendee(registrationCode)}>
          {registration.attendee.addressStreet}
          <br />
          {registration.attendee.addressTown}
          <br />
          {registration.attendee.addressPostcode}
        </ReviewRow>
        {isStaffRegistration(registration) && (
          <>
            <ReviewHeaderRow>{eventConfig.name}</ReviewHeaderRow>
            <ReviewRow name="Staff team" route={routes.registrations.attendee(registrationCode)}>
              {registration.attendee.staffTeam
                && getStaffTeamLabel(registration.attendee.staffTeam)}
            </ReviewRow>
            {registration.attendee.editions.length > 0 && (
              <ReviewRow name="Editions" route={routes.registrations.attendee(registrationCode)}>
                {registration.attendee.editions.map((editionId) => (
                  <ParagraphWithinTable key={editionId}>
                    {getEditionSummaryById(editionId)}
                  </ParagraphWithinTable>
                ))}
              </ReviewRow>
            )}
            <ReviewHeaderRow>Scouting</ReviewHeaderRow>
            {registration.meta.registeringSelf && (
              <>
                <ReviewRow name="Role" route={routes.registrations.attendee(registrationCode)}>
                  {registration.attendee.scoutingAppointment}
                </ReviewRow>
                <ReviewRow name="Membership number" route={routes.registrations.attendee(registrationCode)}>
                  {registration.attendee.membershipNumber}
                </ReviewRow>
              </>
            )}
            {registration.meta.region && (
              <ReviewRow name="Region" route={routes.registrations.attendee(registrationCode)}>
                {registration.meta.region.name}
              </ReviewRow>
            )}
            {registration.meta.district && (
              <ReviewRow name="District" route={routes.registrations.attendee(registrationCode)}>
                {registration.meta.district.name}
              </ReviewRow>
            )}
            {registration.attendee.groupName && (
              <ReviewRow name="Group or ESU" route={routes.registrations.attendee(registrationCode)}>
                {registration.attendee.groupName}
              </ReviewRow>
            )}

          </>
        )}
        <ReviewHeaderRow>Media consent</ReviewHeaderRow>
        <ReviewRow
          name={registration.meta.registeringSelf
            ? 'Do you give media consent?'
            : `Do you give media consent on behalf of ${registration.attendee.firstName}?`}
          route={routes.registrations.media(registrationCode)}
        >
          {registration.attendee.mediaConsent ? 'Yes' : 'No'}
        </ReviewRow>

        {!registration.meta.registeringSelf && (
          <>
            <ReviewHeaderRow>First aid</ReviewHeaderRow>
            <ReviewRow
              name="Anti-histamine consent"
              route={routes.registrations.firstAid(registrationCode)}
            >
              {registration.attendee.antihistamineConsent ? 'Yes' : 'No'}
            </ReviewRow>
            <ReviewRow
              name="Paracetamol consent"
              route={routes.registrations.firstAid(registrationCode)}
            >
              {registration.attendee.painkillerConsent ? 'Yes' : 'No'}
            </ReviewRow>
            <ReviewRow
              name="Sun cream consent"
              route={routes.registrations.firstAid(registrationCode)}
            >
              {registration.attendee.suncreamConsent ? 'Yes' : 'No'}
            </ReviewRow>
            <ReviewRow
              name="After sun consent"
              route={routes.registrations.firstAid(registrationCode)}
            >
              {registration.attendee.afterSunConsent ? 'Yes' : 'No'}
            </ReviewRow>
          </>
        )}

        <ReviewHeaderRow>
          Catering
        </ReviewHeaderRow>
        <ReviewRow name="Your message to the catering team" route={routes.registrations.catering(registrationCode)}>
          {registration.attendee.cateringMessage === ''
            ? 'None' : registration.attendee.cateringMessage}
        </ReviewRow>
        <ReviewRow name="Sandwich preference" route={routes.registrations.catering(registrationCode)}>
          {registration.attendee.sandwichPreference}
        </ReviewRow>

        <ReviewHeaderRow>
          Additional information
        </ReviewHeaderRow>
        <ReviewRow name="Additional needs" route={routes.registrations.additionalInformation(registrationCode)}>
          {registration.meta.hasAdditionalNeeds ? registration.attendee.additionalNeeds : 'None'}
        </ReviewRow>
        <ReviewRow name="Allergies" route={routes.registrations.additionalInformation(registrationCode)}>
          {registration.meta.hasAllergies ? registration.attendee.allergies : 'None'}
        </ReviewRow>
        <ReviewRow name="Medications" route={routes.registrations.additionalInformation(registrationCode)}>
          {registration.meta.hasMedications ? registration.attendee.medications : 'None'}
        </ReviewRow>
        <ReviewRow name="Dietary" route={routes.registrations.additionalInformation(registrationCode)}>
          {registration.meta.hasDietaryRequirements ? registration.attendee.dietaryRequirements : 'None'}
        </ReviewRow>
        {registration.contacts.length > 0 && (
          <ReviewHeaderRow>
            {!registration.meta.registeringSelf && registration.mainContact.emergency
              ? 'Additional emergency contacts' : 'Emergency contacts'}
          </ReviewHeaderRow>
        )}
        {registration.contacts.map((contact) => (
          <ReviewRow
            key={contact.key}
            name={`${getFullName(contact)} (${contact.relationship})`}
            route={routes.registrations.emergencyContacts(registrationCode)}
          >
            {contact.mobile}
            <br />
            {contact.email}
          </ReviewRow>
        ))}
        <ReviewHeaderRow>
          Doctor
        </ReviewHeaderRow>
        <ReviewRow name="Name" route={routes.registrations.doctor(registrationCode)}>
          {registration.doctor.name
            ? `${registration.doctor.surgeryName} (${registration.doctor.name})`
            : registration.doctor.surgeryName}
        </ReviewRow>
        <ReviewRow name="Phone" route={routes.registrations.doctor(registrationCode)}>
          {registration.doctor.phone}
        </ReviewRow>
        <ReviewRow name="Address" route={routes.registrations.doctor(registrationCode)}>
          {registration.doctor.addressStreet}
          <br />
          {registration.doctor.addressTown}
        </ReviewRow>
        {showRegistrationActivitiesFormPage(registration) && (
          <>
            <ReviewHeaderRow>Activities</ReviewHeaderRow>
            {registration.attendee.status === 'Cub' ? (
              <ReviewRow
                name={undefined}
                route={routes.registrations.activities(registrationCode)}
              >
                <RankingInput options={registration.activityPreferences} disabled />
              </ReviewRow>
            ) : (
              <>
                <ReviewRow
                  name="Activities you are comfortable leading"
                  route={routes.registrations.activities(registrationCode)}
                >
                  {Object.values(registration.activityLeadership)
                    .filter((activity) => activity.canLead)
                    .map((activity) => (
                      <>
                        <span key={activity.name}>{activity.name}</span>
                        <br />
                      </>
                    ))}
                </ReviewRow>
                <ReviewRow
                  name="Your message to the activities team"
                  route={routes.registrations.activities(registrationCode)}
                >
                  {registration.attendee.activitiesMessage === ''
                    ? 'None' : registration.attendee.activitiesMessage}
                </ReviewRow>
              </>
            )}
          </>
        )}
      </ReviewTable>
      <Styled.Form onSubmit={handleSubmit} noValidate>
        <h2>{content.review.message.title}</h2>
        <BlocksRenderer content={content.review.message.body} />
        <TextField
          label={content.review.message.label}
          value={values.attendee.message}
          onBlur={() => setFieldTouched('attendee.message')}
          onChange={async (event) => {
            await setFieldValue('attendee.message', event.target.value);
          }}
          error={touched.attendee?.message && Boolean(errors.attendee?.message)}
          multiline
          minRows={3}
        />
        <FormHelperText>
          {(touched.attendee?.message && errors.attendee?.message) || ' '}
        </FormHelperText>
        <h3>{termsContent.title}</h3>
        <BlocksRenderer content={termsContent.body} />
        <FormControl error={touched.meta?.termsAccepted && Boolean(errors.meta?.termsAccepted)}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={values.meta.termsAccepted}
                onChange={async (event) => {
                  await setFieldValue('meta.termsAccepted', event.target.checked);
                }}
                onBlur={async () => {
                  await setFieldTouched('meta.termsAccepted');
                }}
              />
            )}
            label={termsContent.label}
          />
          <FormHelperText>
            {(touched.meta?.termsAccepted && errors.meta?.termsAccepted) || ' '}
          </FormHelperText>
        </FormControl>
        <CardFooter>
          <IconLink icon="Back" to={previousPageRoute}>Back</IconLink>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Confirm Registration
          </Button>
        </CardFooter>
      </Styled.Form>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </Card>
  );
};

export default RegistrationReviewPage;

import { Link } from '@mui/material';
import { getEmailLink } from '../../helpers/links';
import routes from '../../routes';
import useError from '../../state/error/useError';
import { useGetEventConfigQuery } from '../../state/publicApi';
import Card from '../Card';
import CardHeading from '../CardHeading';
import CardIntroduction from '../CardIntroduction';
import RouterLink from '../RouterLink';
import * as Styled from './styles';

const ErrorCard: () => JSX.Element = () => {
  const [error] = useError();
  const {
    data: eventConfig,
  } = useGetEventConfigQuery();

  const currentlyOffline = !navigator.onLine;
  if (currentlyOffline) {
    return (
      <Card>
        <CardHeading>Oops, something went wrong...</CardHeading>
        <CardIntroduction>
          It looks like you are currently offline. Please check your internet
          connection and try again.
        </CardIntroduction>
      </Card>
    );
  }

  const currentlyOnHomePage = window.location.pathname === routes.home;

  return (
    <Card>
      <CardHeading>Oops, something went wrong...</CardHeading>
      {error.message && (
        <CardIntroduction>
          {error.message}
        </CardIntroduction>
      )}
      <Styled.OrderedList>
        <li>
          Firstly, please try refreshing the page in case your connection to
          internet was interrupted.
        </li>
        <li>
          If you continue to have issues, click here to
          {' '}
          <RouterLink to={routes.reset}>
            {currentlyOnHomePage ? 'click here to reset your local cache and clear your user session.'
              : 'click here to reset your local cache, clear your user session and return to the homepage.'}
          </RouterLink>
        </li>
        {eventConfig && (
          <li>
            If the issue persists please email the event team:
            {' '}
            <Link href={getEmailLink(eventConfig.email)}>{eventConfig.email}</Link>
          </li>
        )}
      </Styled.OrderedList>
    </Card>
  );
};

export default ErrorCard;

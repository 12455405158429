import styled from 'styled-components';
import { responsiveSpacing } from '../../helpers/responsiveSpacing';
import { responsiveWidth } from '../../helpers/responsiveWidth';
import colours from '../../theme/colours';

export const Layout = styled.div`
  background-color: ${({ theme }) => (theme.palette.mode === 'light' ? theme.palette.primary.light : colours.darkBlue)};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.div`
  ${responsiveWidth({ desktop: 2, tablet: 2, mobile: 2 })}
  ${responsiveSpacing('margin-top', 'xs')}
  ${responsiveSpacing('padding', 'xs')}
  fill: ${({ theme }) => (theme.palette.mode === 'light' ? 'black' : 'white')};
`;

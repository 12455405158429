const formatNumber = (
  value: number,
  format: string | undefined,
): string => {
  if (!format) {
    return value.toString();
  }

  if (format === 'currency') {
    const formatter = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(value);
  }
  if (format === 'percentage') {
    return `${value}%`;
  }

  throw new Error(`Unknown number format type: ${format}`);
};

export default formatNumber;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  FormLabel, Radio,
  RadioGroup,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FormGrid } from '../../../../components/FormGrid';
import { useTypeSafeFormik } from '../../../../helpers/form';
import { Activity } from '../../../../models/activity';
import { ActivityAllocationForPerson } from '../../../../models/activityAllocationForPerson';
import { activityLeaderRoleOptions, isValidActivityLeaderRole } from '../../../../models/activityLeaderRole';
import { ActivitySession } from '../../../../models/activitySession';
import { useUpdateActivityAllocationsMutation } from '../../../../state/protectedApi/activityAllocations';
import * as Styled from './styles';

type EditLeaderRoleDialogProps = {
  editionId: number
  assignment: ActivityAllocationForPerson
  activities: Activity[]
  sessions: ActivitySession[]
  onClose: () => void
};

const EditLeaderRoleDialog = (props: EditLeaderRoleDialogProps): JSX.Element => {
  const {
    editionId,
    assignment,
    activities,
    sessions,
    onClose,
  } = props;

  const [updateAllocations] = useUpdateActivityAllocationsMutation();

  // TODO: Add a validation schema
  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    setSubmitting,
  } = useTypeSafeFormik({
    initialValues: assignment,
    onSubmit: async (newValues) => {
      await updateAllocations({ editionId, data: [newValues] })
        .then(() => {
          enqueueSnackbar(
            `Leadership statuses for ${assignment.personName} successfully updated`,
            {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              variant: 'success',
              style: { whiteSpace: 'pre-line' },
            },
          );
          onClose();
        }).catch(() => {
          enqueueSnackbar(
            `Leadership statuses for ${assignment.personName} failed to update`,
            {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              variant: 'error',
              style: { whiteSpace: 'pre-line' },
              persist: true,
            },
          );
        }).finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    // eslint-disable-next-line react/jsx-boolean-value
    <Dialog open={true} onClose={onClose} maxWidth="xl">
      <form onSubmit={handleSubmit} noValidate>
        <DialogTitle>{`Update leader roles for ${assignment?.personName ?? ''}`}</DialogTitle>
        <DialogContent>
          <FormGrid columns={1}>
            {sessions.map((session) => {
              const activity = activities.find(
                (a) => values.sessions[session.id]
                  && values.sessions[session.id].activityId === a.id,
              );
              const sessionName = `Session ${session.order}`;
              return (
                <Styled.FormControl
                  key={`leadership-role-input-${assignment.personId}-${session.id}`}
                  error={touched.sessions && touched.sessions[session.id]?.leaderRole
                  && errors.sessions && Boolean(errors.sessions[session.id]?.leaderRole)}
                  disabled={activity === undefined}
                >
                  <FormLabel id={`session-${session.id}-label`}>
                    {activity ? `${session.order}. ${activity.name}` : sessionName}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby={`session-${session.id}-label`}
                    value={(values.sessions[session.id]
                        && values.sessions[session.id].leaderRole)
                      || ''}
                    onChange={async (event) => {
                      if (isValidActivityLeaderRole(event.target.value)) {
                        await setFieldValue(`sessions.${session.id}.leaderRole`, event.target.value);
                      }
                    }}
                    onBlur={() => setFieldTouched(`sessions.${session.id}.leaderRole`)}
                  >
                    {activity && activityLeaderRoleOptions.map((option) => (
                      <FormControlLabel
                        key={`leadership-role-input-${assignment.personId}-${session.id}-option-${option.label}`}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                        disabled={option.value === 'Lead' && activity.delivery !== 'PackLeader'}
                      />
                    ))}
                  </RadioGroup>
                  <FormHelperText>
                    {activity
                      ? (touched.sessions && touched.sessions[session.id]?.leaderRole
                        && errors.sessions && errors.sessions[session.id]?.leaderRole)
                      || ' '
                      : `Disabled until an activity has been selected for session ${session.order}`}
                  </FormHelperText>
                </Styled.FormControl>
              );
            })}
          </FormGrid>
          <DialogActions>
            <Button onClick={onClose} disabled={isSubmitting}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>Save</Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default EditLeaderRoleDialog;

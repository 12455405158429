import { documentsRoute } from './documents';
import { personRoute } from './personRoutes';
import { registrationsRoute } from './registrationsRoutes';

const bookingsRoute = (subPath?: string): string => (subPath ? `/bookings/${subPath}` : '/bookings');

const routes = {
  home: '/',
  faq: '/faq',
  login: '/login',
  forgotPassword: '/forgot-password',
  bookings: {
    start: bookingsRoute(),
    group: bookingsRoute('group'),
    contact: bookingsRoute('contact'),
    address: bookingsRoute('address'),
    estimates: bookingsRoute('estimates'),
    travel: bookingsRoute('travel'),
    editions: bookingsRoute('editions'),
    review: bookingsRoute('review'),
    confirmation: bookingsRoute('confirmation'),
  },
  registrations: {
    start: registrationsRoute(),
    contact: registrationsRoute('contact'),
    attendee: registrationsRoute('attendee'),
    media: registrationsRoute('media'),
    additionalInformation: registrationsRoute('additional-information'),
    firstAid: registrationsRoute('first-aid'),
    catering: registrationsRoute('catering'),
    emergencyContacts: registrationsRoute('emergency-contact'),
    doctor: registrationsRoute('doctor'),
    activities: registrationsRoute('activities'),
    review: registrationsRoute('review'),
    confirmation: registrationsRoute('confirmation'),
  },
  arrivals: '/arrivals',
  dashboard: '/dashboard',
  packs: {
    grid: '/packs',
    detail: (packId: number | string) => `/packs/${packId}`,
  },
  duties: {
    grid: '/duties',
    detail: (dutyId: number | string) => `/duties/${dutyId}`,
  },
  meals: {
    grid: '/meals',
    adminDetail: (mealId: number | string) => `/meals/${mealId}/admin`,
    checkIn: (mealId?: number) => (mealId ? `/meal-check-in?meal=${mealId}` : '/meal-check-in'),
  },
  people: {
    grid: '/people',
    detail: personRoute(),
    compare: '/people/compare',
  },
  reviews: '/reviews',
  reviewTagSummary: '/reviews/summary',
  activities: {
    public: '/activities',
    grid: '/activities/admin',
    allocations: '/activities/allocation',
    detail: (activityId: number | string, leaders = false) => (leaders ? `/activities/${activityId}?leader=true` : `/activities/${activityId}`),
    adminDetail: (activityId: number | string, session?: number) => (session
      ? `/activities/${activityId}/admin?session=${session}`
      : `/activities/${activityId}/admin`),
    sessions: {
      grid: '/activities/sessions',
      detail: (sessionId: number | string) => `/activities/sessions/${sessionId}`,
    },
  },
  emails: {
    grid: '/emails',
  },
  changePassword: '/change-password',
  signUp: '/sign-up/:userCode',
  documents: {
    activity: {
      preferences: documentsRoute('activities/preferences'),
      qrSign: (activityId?: number) => documentsRoute(activityId ? `activities/signs/${activityId}` : 'activities/signs'),
    },
    lanyard: (personPublicId: string) => documentsRoute(`lanyards/public/${personPublicId}`),
    menu: documentsRoute('meals/menu'),
  },
  reset: '/reset',
};

export default routes;

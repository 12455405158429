import styled from 'styled-components';
import { responsiveSpacing } from '../../helpers/responsiveSpacing';

const CardFooter = styled.div`
  display: flex;
  ${responsiveSpacing('margin-top', 's')}
  justify-content: end;
`;

export default CardFooter;

import { NewRegistration } from '../../../state/registration/types';

const sanitiseOnSubmit = (rawValues: NewRegistration): NewRegistration => {
  const attendee = {
    ...rawValues.attendee,
    message: rawValues.attendee.message.trim(),
  };

  return { ...rawValues, attendee };
};

export default sanitiseOnSubmit;

import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import {
  Label, Scatter, ScatterChart, XAxis, YAxis, ZAxis,
} from 'recharts';
import { getHeightPx } from '../../../../../components/Dashboard/config';
import DashboardCard from '../../../../../components/DashboardCard';
import DashboardCardLegend from '../../../../../components/DashboardCardLegend';
import DashboardCardTitle from '../../../../../components/DashboardCardTitle';
import { SortComparison } from '../../../../../helpers/sort';
import { ActivityStats, Statistics } from '../../../../../models/statistics';
import { useTheme } from '../../../../../theme';
import colours from '../../../../../theme/colours';

type ActivitiesByRankedPreferenceProps = {
  stats: Statistics
};

const ActivitiesByRankedPreference: FC<ActivitiesByRankedPreferenceProps> = (props) => {
  const { stats } = props;

  const theme = useTheme();
  const screenWiderThanMobile = useMediaQuery(theme.breakpoints.up('sm'));

  const byAverageRank: SortComparison<ActivityStats> = (a, b) => a.averageRank - b.averageRank;
  const activities = stats.activities.slice().sort(byAverageRank) ?? [];

  const rows = Math.ceil(activities.length / 5) + (screenWiderThanMobile ? 1 : 3);
  const columns = screenWiderThanMobile ? 4 : 2;

  const graphHeight = getHeightPx(rows) - 115;
  const graphWidth = getHeightPx(columns) - 20;

  const xMargin = screenWiderThanMobile ? 40 : 15;

  return (
    <DashboardCard columns={columns} rows={rows}>
      <DashboardCardTitle>Preferences</DashboardCardTitle>
      <ScatterChart
        width={graphWidth}
        height={graphHeight}
        margin={{
          top: 30, right: xMargin, bottom: 10, left: xMargin,
        }}
      >
        <XAxis
          dataKey="rank"
          type="category"
          tick={false}
          reversed
          orientation="top"
          allowDuplicatedCategory={false}
        >
          <Label value="Most" position="insideTopRight" />
          <Label value="Ranked preference" position="insideTop" offset={-15} />
          <Label value="Least" position="insideTopLeft" />
        </XAxis>
        <YAxis
          type="category"
          dataKey="name"
          orientation="right"
          reversed
          width={screenWiderThanMobile ? 170 : 100}
          allowDuplicatedCategory={false}
          interval={0}
          tickMargin={screenWiderThanMobile ? 16 : 8}
        />
        <ZAxis type="number" dataKey="count" range={[0, 1000]} />
        {activities.map((activity, index) => (
          <Scatter
            key={activity.id}
            data={activity.countByRank.map(
              (item) => ({ ...item, name: activity.name }),
            )}
            fill={index % 2 ? colours.purple : colours.teal}
          />
        ))}
      </ScatterChart>
      <DashboardCardLegend>
        {`Activities are ordered by the average rank given. ${activities[0].name} is the most preferred activity.`}
      </DashboardCardLegend>
    </DashboardCard>
  );
};

export default ActivitiesByRankedPreference;

import SendIcon from '@mui/icons-material/Send';
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  DialogActions,
  MenuItem,
  TextField,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import DataGridBulkActionDialog, { BulkActionError } from '../../../../components/DataGridBulkActionDialog';
import { FormGridWithLargeTopPadding } from '../../../../components/FormGrid';
import { useTypeSafeFormik } from '../../../../helpers/form';
import { isPackEmailTemplate, packEmailTemplateOptions } from '../../../../models/emailTemplate';
import { Pack } from '../../../../models/pack';
import { isValidPackBookingsStatus, packBookingStatusOptions } from '../../../../models/packBookingStatus';
import { isValidRegistrationStatus, registrationStatusOptions } from '../../../../models/registrationStatus';
import { isValidSubcamp } from '../../../../models/subcamp';
import { isValidWideningAccessStatus } from '../../../../models/wideningAccessStatus';
import { useBulkUpdatePacksMutation } from '../../../../state/protectedApi/packs';
import { useGetEditionsQuery } from '../../../../state/publicApi/editions';
import { processFormValuesToRequest } from './helpers';
import { subcampBulkOptions, wideningAccessBulkOptions } from './options';
import { BulkPackUpdateFormValues } from './types';

type ActionsDialogProps = {
  packs: Pack[]
  open: boolean
  unselectPacks: (ids: number[]) => void
  onSuccess?: () => void
  onCancel?: () => void
};

const ActionsDialog = (props: ActionsDialogProps): JSX.Element => {
  const {
    packs,
    open,
    unselectPacks,
    onSuccess,
    onCancel,
  } = props;

  const { data: editions, isLoading: editionsLoading } = useGetEditionsQuery();
  const [updatePacks] = useBulkUpdatePacksMutation();
  const [updateError, setUpdateError] = useState<BulkActionError>(null);

  const initialValues: BulkPackUpdateFormValues = {
    editionId: 'No change',
    bookingStatus: 'No change',
    attendedLastYear: 'No change',
    attendedTwoYearsAgo: 'No change',
    wideningAccess: 'No change',
    registrationStatus: 'No change',
    subcamp: 'No change',
    emailTemplate: 'No email',
  };

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    resetForm,
  } = useTypeSafeFormik(
    {
      initialValues,
      onSubmit: async () => {
        setUpdateError(null);
        try {
          const packsToUpdate = processFormValuesToRequest(values, packs);
          const emailTemplate = values.emailTemplate === 'No email' ? undefined : values.emailTemplate;
          await updatePacks({
            packs: packsToUpdate,
            emailTemplate,
          }).then((response) => {
            if ('error' in response) {
              setUpdateError({
                title: 'An error occurred during the update: ',
                details: [JSON.stringify(response.error)],
              });
              return;
            }

            const { success, errors: updateErrors } = response.data;

            if (updateErrors.length === 0 && success.length > 0) {
              if (onSuccess) {
                onSuccess();
              }
              resetForm();
              enqueueSnackbar(
                'Packs updated successfully!',
                {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                  variant: 'success',
                  persist: false,
                },
              );
              return;
            }

            const packErrors = updateErrors.map((e) => `${packs.find((p) => p.id === e.id)?.reference ?? e.id}: ${e.error}`);
            const errorTitle = success.length > 0 ? 'Some packs failed to update:' : 'All packs failed to update:';
            setUpdateError({
              title: errorTitle,
              details: packErrors,
            });
            unselectPacks(success.map((s) => s.id));
          });
        } catch (e) {
          setUpdateError({
            title: 'An error occurred during the update: ',
            details: [String(e)],
          });
        }
      },
    },
  );

  const wrappedUnselectPack = (id: number): void => {
    setUpdateError(null);
    unselectPacks([id]);
  };

  const wrappedOnCancel = (): void => {
    resetForm();
    setUpdateError(null);
    if (onCancel) {
      onCancel();
    }
  };

  const editionIds = [
    'No change' as const,
    'Unset' as const,
    ...(editions ?? []).map((edition) => edition.id),
  ];

  return (
    <DataGridBulkActionDialog
      items={packs}
      open={open}
      onCancel={wrappedOnCancel}
      getReference={(pack: Pack) => pack.reference}
      getName={(pack: Pack) => pack.name}
      getId={(pack: Pack) => pack.id}
      unselectItem={wrappedUnselectPack}
      error={updateError}
      title="Perform bulk action on packs"
      description={`You are about to perform an action on ${packs.length} pack${packs.length > 1 ? 's' : ''}:`}
    >
      <form onSubmit={handleSubmit} noValidate>
        <FormGridWithLargeTopPadding columns={2}>
          <TextField
            select
            label="Email Template"
            value={values.emailTemplate}
            onChange={async (event) => {
              const newValue = event.target.value;
              await setFieldValue('emailTemplate', isPackEmailTemplate(newValue) ? newValue : 'No email');
            }}
            onBlur={() => setFieldTouched('emailTemplate')}
            error={touched.emailTemplate && Boolean(errors.emailTemplate)}
            helperText={(touched.emailTemplate && errors.emailTemplate) || ' '}
            disabled={isSubmitting}
          >
            <MenuItem value="No email">
              No email
            </MenuItem>
            {packEmailTemplateOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Autocomplete
            options={editionIds}
            loading={editionsLoading}
            getOptionLabel={(id) => editions?.find((e) => e.id === id)?.name ?? String(id)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Edition"
                error={touched.editionId && Boolean(errors.editionId)}
                helperText={(touched.editionId && errors.editionId) || ' '}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {editionsLoading ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            value={values.editionId}
            onChange={async (event, value) => {
              if (editionIds.includes(value)) {
                await setFieldValue('editionId', value);
              }
            }}
            onBlur={() => setFieldTouched('editionId')}
            disableClearable
            disabled={isSubmitting}
          />
          <TextField
            select
            label="Booking status"
            value={values.bookingStatus}
            onChange={async (event) => {
              const newValue = event.target.value;
              await setFieldValue('bookingStatus', isValidPackBookingsStatus(newValue) ? newValue : 'No change');
            }}
            onBlur={() => setFieldTouched('bookingStatus')}
            error={touched.bookingStatus && Boolean(errors.bookingStatus)}
            helperText={(touched.bookingStatus && errors.bookingStatus) || ' '}
            disabled={isSubmitting}
          >
            <MenuItem value="No change">
              No change
            </MenuItem>
            {packBookingStatusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Widening access"
            value={values.wideningAccess}
            onChange={async (event) => {
              const newValue = event.target.value;
              if (isValidWideningAccessStatus(newValue) || newValue === 'No change') {
                await setFieldValue('wideningAccess', newValue);
              }
            }}
            onBlur={() => setFieldTouched('wideningAccess')}
            error={touched.wideningAccess && Boolean(errors.wideningAccess)}
            helperText={(touched.wideningAccess && errors.wideningAccess) || ' '}
            disabled={isSubmitting}
          >
            {wideningAccessBulkOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Attended 2022"
            value={values.attendedTwoYearsAgo}
            onChange={async (event) => {
              const newValue = event.target.value;
              if (newValue === 'true' || newValue === 'false') {
                await setFieldValue('attendedTwoYearsAgo', newValue === 'true');
              } else {
                await setFieldValue('attendedTwoYearsAgo', 'No change');
              }
            }}
            onBlur={() => setFieldTouched('attendedTwoYearsAgo')}
            error={touched.attendedTwoYearsAgo && Boolean(errors.attendedTwoYearsAgo)}
            helperText={(touched.attendedTwoYearsAgo && errors.attendedTwoYearsAgo) || ' '}
            disabled={isSubmitting}
          >
            <MenuItem value="No change">
              No change
            </MenuItem>
            <MenuItem value="true">
              Yes
            </MenuItem>
            <MenuItem value="false">
              No
            </MenuItem>
          </TextField>
          <TextField
            select
            label="Attended 2023"
            value={values.attendedLastYear}
            onChange={async (event) => {
              const newValue = event.target.value;
              if (newValue === 'true' || newValue === 'false') {
                await setFieldValue('attendedLastYear', newValue === 'true');
              } else {
                await setFieldValue('attendedLastYear', 'No change');
              }
            }}
            onBlur={() => setFieldTouched('attendedLastYear')}
            error={touched.attendedLastYear && Boolean(errors.attendedLastYear)}
            helperText={(touched.attendedLastYear && errors.attendedLastYear) || ' '}
            disabled={isSubmitting}
          >
            <MenuItem value="No change">
              No change
            </MenuItem>
            <MenuItem value="true">
              Yes
            </MenuItem>
            <MenuItem value="false">
              No
            </MenuItem>
          </TextField>
          <TextField
            select
            label="Registration status"
            value={values.registrationStatus}
            onChange={async (event) => {
              const newValue = event.target.value;
              await setFieldValue('registrationStatus', isValidRegistrationStatus(newValue) ? newValue : 'No change');
            }}
            onBlur={() => setFieldTouched('registrationStatus')}
            error={touched.registrationStatus && Boolean(errors.registrationStatus)}
            helperText={(touched.registrationStatus && errors.registrationStatus) || ' '}
            disabled={isSubmitting}
          >
            <MenuItem value="No change">
              No change
            </MenuItem>
            {registrationStatusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Subcamp"
            value={values.subcamp}
            onChange={async (event) => {
              const newValue = event.target.value;
              if (isValidSubcamp(newValue) || newValue === 'Unset' || newValue === 'No change') {
                await setFieldValue('subcamp', newValue);
              }
            }}
            onBlur={() => setFieldTouched('subcamp')}
            error={touched.subcamp && Boolean(errors.subcamp)}
            helperText={(touched.subcamp && errors.subcamp) || ' '}
            disabled={isSubmitting}
          >
            {subcampBulkOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </FormGridWithLargeTopPadding>
        <DialogActions>
          <Button
            size="large"
            variant="outlined"
            onClick={wrappedOnCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            size="large"
            variant="contained"
            color="primary"
            endIcon={<SendIcon />}
            type="submit"
            disabled={isSubmitting}
          >
            Confirm
          </Button>
        </DialogActions>
      </form>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </DataGridBulkActionDialog>
  );
};

export default ActionsDialog;

// Scouts Brand Pallet

import { HexColourCode } from '../helpers/cssTypes';

type Colours = {
  [name: string]: HexColourCode
};

const colours: Colours = {
  blue: '#006ddf',
  darkBlue: '#001b37',
  indigo: '#003982',
  purple: '#7413dc',
  pink: '#ffb4e5',
  red: '#e22e12',
  orange: '#ff912a',
  yellow: '#ffe627',
  lightGreen: '#b3efc7',
  green: '#23a950',
  forestGreen: '#205b41',
  teal: '#00a794',
  grey: '#bdbdbd',
  white: '#ffffff',
};

export default colours;

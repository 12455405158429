import { objectToCamel, objectToSnake } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import {
  ActivityAllocationForPerson,
  ActivityAllocationsForPeopleQuery,
  ActivityAllocationsForPeopleUpdateRequest,
} from '../../models/activityAllocationForPerson';
import { protectedApi } from './index';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getActivityAllocations: builder.query<
    ActivityAllocationForPerson[],
    ActivityAllocationsForPeopleQuery
    >({
      query: ({ includeScores, editionId }) => {
        const fields = includeScores ? '*' : 'person_id,person_name,pack_id,pack_reference,status,sessions';
        return ({
          url: `activities/allocations/edition/${editionId}`,
          headers: {
            'X-Fields': fields,
          },
        });
      },
      transformResponse: (
        response: ObjectToSnake<ActivityAllocationForPerson[]>,
      ) => objectToCamel(response),
      providesTags: (people) => (people && people.length
        ? people.map((person) => ({
          type: 'activityAllocation', id: person.personId,
        }))
        : ['activityAllocation']
      ),
    }),
    updateActivityAllocations: builder.mutation<void, ActivityAllocationsForPeopleUpdateRequest>({
      query: ({ data, editionId }) => {
        const body = objectToSnake(data);
        return ({
          url: `activities/allocations/edition/${editionId}`,
          method: 'PATCH',
          body,
        });
      },
      invalidatesTags: (result, error, arg) => arg.data.flatMap(
        (person) => ([
          { type: 'activityAllocation', id: person.personId },
          //   TODO add activity tag invalidation too as the session numbers will update
        ]),
      ),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetActivityAllocationsQuery,
  useUpdateActivityAllocationsMutation,
} = extendedApi;

import { auth } from '../firebase';

const localDocumentsOrigin = 'http://127.0.0.1:5000';

export const documentsRoute = (subPath: string): string => {
  const isLocal = window.location.host.startsWith('localhost');
  const currentOrigin = window.location.origin;
  const documentsOrigin = isLocal ? localDocumentsOrigin : currentOrigin;
  return `${documentsOrigin}/documents/${subPath}`;
};

export const loadPdfDocument = async (documentUrl: string): Promise<void> => {
  const token = await auth.currentUser?.getIdToken();
  if (token) {
    await fetch(documentUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        const objectUrl = window.URL.createObjectURL(blob);
        window.open(objectUrl, '_blank')?.focus();
      });
  }
};

import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BlocksRenderer from '../../../components/BlocksRenderer';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import CardIntroduction from '../../../components/CardIntroduction';
import IconLink from '../../../components/IconLink';
import { useRegistrationsContent } from '../../../helpers/content';
import { useFormikWithStateSync } from '../../../helpers/form';
import routes from '../../../routes';
import { useRegistrationCodeOrError } from '../../../routes/registrationsRoutes';
import { useGetEventConfigQuery } from '../../../state/publicApi';
import { selectRegistration, update } from '../../../state/registration';
import { validationSchema } from './schema';

const RegistrationMediaFormPage = (): JSX.Element => {
  const { content } = useRegistrationsContent();
  const registrationCode = useRegistrationCodeOrError();
  const navigate = useNavigate();

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormikWithStateSync(
    selectRegistration,
    update,
    {
      validationSchema,
      onSubmit: async () => {
        navigate(routes.registrations.additionalInformation(registrationCode));
      },
    },
  );

  const { data: eventConfig } = useGetEventConfigQuery();

  if (!eventConfig || !content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  const contentVersion = values.meta.registeringSelf
    ? content.media.self : content.media.other;

  return (
    <Card>
      <CardHeading>{contentVersion.title}</CardHeading>
      <CardIntroduction>
        <BlocksRenderer content={contentVersion.body} />
      </CardIntroduction>
      <form onSubmit={handleSubmit} noValidate>
        <FormControl
          error={touched.attendee?.mediaConsent && Boolean(errors.attendee?.mediaConsent)}
        >
          <FormLabel id="media-label">
            {values.meta.registeringSelf
              ? 'Do you give media consent?'
              : `Do you give media consent on behalf of ${values.attendee.firstName}?`}
          </FormLabel>
          <RadioGroup
            aria-labelledby="media-label"
            value={values.attendee.mediaConsent}
            onChange={async (event) => {
              await setFieldValue('attendee.mediaConsent', event.target.value === 'true');
            }}
            onBlur={() => setFieldTouched('attendee.mediaConsent')}
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
          <FormHelperText>
            {(touched.attendee?.mediaConsent && errors.attendee?.mediaConsent) || ' '}
          </FormHelperText>
        </FormControl>
        <CardFooter>
          <IconLink icon="Back" to={routes.registrations.attendee(registrationCode)}>Back</IconLink>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Next
          </Button>
        </CardFooter>
      </form>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </Card>
  );
};

export default RegistrationMediaFormPage;

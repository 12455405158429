import { enqueueSnackbar } from 'notistack';
import { ReactNode, createElement } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';

type AnchorHeaderProps = {
  id: string
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children: ReactNode
};

const AnchorHeader = (props: AnchorHeaderProps): JSX.Element => {
  const { id, tag, children } = props;

  const navigate = useNavigate();

  const content = createElement(tag, { id }, children);
  const relativeLink = `#${id}`;
  const absoluteLink = `${window.location.origin}${window.location.pathname}${relativeLink}`;

  const onCopy = (): void => {
    navigate(relativeLink);
    enqueueSnackbar(
      'Anchor link copied to clipboard',
      {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        variant: 'success',
        persist: false,
      },
    );
  };

  return (
    <CopyToClipboard text={absoluteLink} onCopy={onCopy}>
      {content}
    </CopyToClipboard>
  );
};

export default AnchorHeader;

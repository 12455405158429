import { Backdrop, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BlocksRenderer from '../../../components/BlocksRenderer';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import CardIntroduction from '../../../components/CardIntroduction';
import IconLink from '../../../components/IconLink';
import DoctorForm from '../../../components/forms/DoctorForm';
import sanitiseOnSubmit from '../../../components/forms/DoctorForm/sanitise';
import { validationSchema } from '../../../components/forms/DoctorForm/schema';
import { useRegistrationsContent } from '../../../helpers/content';
import { useFormikWithStateSync } from '../../../helpers/form';
import { showRegistrationActivitiesFormPage } from '../../../helpers/registrations';
import routes from '../../../routes';
import { useRegistrationCodeOrError } from '../../../routes/registrationsRoutes';
import { selectDoctorFromRegistration, updateDoctor } from '../../../state/registration';
import useRegistration from '../../../state/registration/useRegistration';

const RegistrationDoctorFormPage = (): JSX.Element => {
  const { content } = useRegistrationsContent();
  const registrationCode = useRegistrationCodeOrError();
  const navigate = useNavigate();
  const [registration] = useRegistration();

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormikWithStateSync(
    selectDoctorFromRegistration,
    updateDoctor,
    {
      validationSchema,
      onSubmit: async () => {
        const nextPageRoute = showRegistrationActivitiesFormPage(registration)
          ? routes.registrations.activities(registrationCode)
          : routes.registrations.review(registrationCode);
        navigate(nextPageRoute);
      },
    },
    sanitiseOnSubmit,
  );

  if (!content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <Card>
      <CardHeading>{content.doctor.title}</CardHeading>
      <CardIntroduction>
        <BlocksRenderer content={content.doctor.body} />
      </CardIntroduction>
      <DoctorForm
        handleSubmit={handleSubmit}
        touched={touched}
        errors={errors}
        values={values}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        isSubmitting={isSubmitting}
      >
        <CardFooter>
          <IconLink icon="Back" to={routes.registrations.emergencyContacts(registrationCode)}>Back</IconLink>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Next
          </Button>
        </CardFooter>
      </DoctorForm>
    </Card>
  );
};

export default RegistrationDoctorFormPage;

type MultiLineTextProps = {
  children: string
};

const MultiLineText = (props: MultiLineTextProps): JSX.Element => {
  const { children } = props;

  return (
    <>
      {
        children.split('\n').map((line, index) => (
          <span key={line}>
            {index > 0 && (<br />)}
            {line}
          </span>
        ))
      }
    </>
  );
};

export default MultiLineText;

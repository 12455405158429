import { createGlobalStyle } from 'styled-components';
import '@fontsource/nunito-sans';
import { getWidthPx, gutterPx, minGridColumnsWidth } from '../components/Dashboard/config';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  body {
    font-family: "Nunito Sans", serif;
    min-width: ${getWidthPx(minGridColumnsWidth) + 2 * gutterPx}px;
  }

  path {
    outline: none;
  }
`;

export default GlobalStyle;

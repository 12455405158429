import { Pack } from '../models/pack';
import routes from '../routes';
import { isActiveBookingStatus, isCancelledBookingStatus } from './packBookingStatus';

export const isBookingActive = (pack: Pack): boolean => isActiveBookingStatus(pack.bookingStatus);

export const isBookingCancelled = (
  pack: Pack,
): boolean => isCancelledBookingStatus(pack.bookingStatus);

export const getRegistrationLink = (
  pack: Pack,
): string => window.location.origin + routes.registrations.start(pack.publicId);

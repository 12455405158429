import {
  DndContext,
  DragOverlay,
  DragStartEvent,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useState } from 'react';
import RankOption, { RankOptionProps } from './RankOption';
import { KeyboardSensor, MouseSensor, TouchSensor } from './sensors';
import * as Styled from './styles';

export type SourceRankOption = Omit<RankOptionProps, 'status'>;

type RankingInputProps = {
  options: SourceRankOption[]
  onChange?: (rankedOptions: SourceRankOption[]) => void
  disabled?: boolean
};

const RankingInput = (props: RankingInputProps): JSX.Element => {
  const { options: initialOptions, onChange, disabled } = props;

  const [rankedOptions, setRankedOptions] = useState(initialOptions);
  const [activeOption, setActiveOption] = useState<SourceRankOption | null>(null);

  const sensors = useSensors(
    useSensor(KeyboardSensor),
    useSensor(MouseSensor),
    useSensor(TouchSensor),
  );

  const onDragStart = (event: DragStartEvent): void => {
    const { active } = event;
    setActiveOption(initialOptions.find((opt) => opt.id === active.id) ?? null);
  };

  const onDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setRankedOptions((options) => {
        const optionIds = options.map((opt) => opt.id);
        const oldIndex = optionIds.indexOf(active.id);
        const newIndex = optionIds.indexOf(over.id);

        const newlySortedOptions = arrayMove(options, oldIndex, newIndex);

        if (onChange !== undefined) {
          onChange(newlySortedOptions);
        }

        return newlySortedOptions;
      });
    }
    setActiveOption(null);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <SortableContext
        items={rankedOptions}
        strategy={verticalListSortingStrategy}
        disabled={disabled}
      >
        <Styled.Wrapper>
          <Styled.RankItemList>
            {rankedOptions.map((option, index) => (
              <RankOption
                key={option.id}
                id={option.id}
                name={option.name}
                status={activeOption !== null && option.id === activeOption.id ? 'placeholder' : 'inactive'}
                rank={index + 1}
                disabled={disabled}
              />
            ))}
          </Styled.RankItemList>
          {disabled !== true && rankedOptions.length !== 0 && (
            <Styled.ScrollSpaceWrapper>
              <Styled.ScrollSpace>
                <Styled.ScrollUpArrow />
                Touch here to scroll
                <Styled.ScrollDownArrow />
              </Styled.ScrollSpace>
            </Styled.ScrollSpaceWrapper>
          )}
        </Styled.Wrapper>
      </SortableContext>
      <DragOverlay modifiers={[restrictToWindowEdges]}>
        {activeOption && (
          <RankOption
            id={activeOption.id}
            name={activeOption.name}
            status="active"
          />
        )}
      </DragOverlay>
    </DndContext>
  );
};

export default RankingInput;

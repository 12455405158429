import { useSearchParams } from 'react-router-dom';

type UseUrlParamReturn<TParam> = [TParam, (update: TParam) => void];

const useUrlParam = <TParam>(
  key: string,
  initial: TParam,
  decodeString: (value: string) => TParam,
  encodeString: (value: TParam) => string | undefined,
): UseUrlParamReturn<TParam> => {
  const [searchParams, setSearchParams] = useSearchParams();

  const rawValue = searchParams.get(key) ?? undefined;
  const value = rawValue ? decodeString(rawValue) : initial;

  // WARNING: If you call this function for two keys in the same render, the second call
  //  will overwrite the first.
  const updateValue = (update: TParam): void => {
    const encodedUpdate = encodeString(update);
    setSearchParams((prev) => {
      const newSearchParams = new URLSearchParams(prev);
      if (encodedUpdate !== undefined) {
        newSearchParams.set(key, encodedUpdate);
      } else {
        newSearchParams.delete(key);
      }
      return newSearchParams;
    }, {
      replace: true,
    });
  };

  return [value, updateValue];
};

export const useUrlStringParam = <TParam extends string>(
  key: string,
  initial: TParam,
): UseUrlParamReturn<TParam> => useUrlParam<TParam>(
    key,
    initial,
    (value) => value as TParam,
    (value) => value,
  );

export const useUrlOptionalStringParam = <TParam extends string>(
  key: string,
  initial?: TParam,
): UseUrlParamReturn<TParam | undefined> => useUrlParam<TParam | undefined>(
    key,
    initial,
    (value) => value as TParam,
    (value) => value,
  );

export const useUrlStringListParam = <TParam extends string>(
  key: string,
  initial: TParam[],
): UseUrlParamReturn<TParam[]> => useUrlParam<TParam[]>(
    key,
    initial,
    (value) => value.split(',') as TParam[],
    (value) => (value.length > 0 ? value.join(',') : undefined),
  );

export const useUrlNumberParam = <TParam extends number>(
  key: string,
  initial: TParam,
): UseUrlParamReturn<TParam> => useUrlParam<TParam>(
    key,
    initial,
    (value) => parseInt(value, 10) as TParam,
    (value) => value.toString(),
  );

export const useUrlOptionalNumberParam = <TParam extends number>(
  key: string,
  initial?: TParam,
): UseUrlParamReturn<TParam | undefined> => useUrlParam<TParam | undefined>(
    key,
    initial,
    (value) => parseInt(value, 10) as TParam,
    (value) => value?.toString(),
  );

export const useUrlBooleanParam = (
  key: string,
  initial: boolean,
): UseUrlParamReturn<boolean> => useUrlParam<boolean>(
  key,
  initial,
  (value) => value === 'true',
  (value) => (value ? 'true' : undefined),
);

import {
  GridColumnVisibilityModel, GridDensity, GridFilterModel, GridPinnedColumnFields, GridSortModel,
} from '@mui/x-data-grid-pro';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

export interface GridState {
  editionId?: number | undefined
  columnVisibilityModel?: GridColumnVisibilityModel
  density?: GridDensity
  filterModel?: GridFilterModel
  sortModel?: GridSortModel
  pinnedColumns?: GridPinnedColumnFields
}

export interface GridStateWithKey extends GridState {
  key: string
}

export type GridsStoreState = {
  items: GridStateWithKey[];
};

const initialState: GridsStoreState = {
  items: [],
};

export const gridsSlice = createSlice({
  name: 'grids',
  initialState,
  reducers: {
    reset: () => initialState,
    update: (
      state,
      action: PayloadAction<GridsStoreState['items']>,
    ) => ({ ...state, items: action.payload }),
  },
});

export const { reset, update } = gridsSlice.actions;

export const selectGrids = (state: RootState): GridsStoreState => state.grids;

export default gridsSlice.reducer;

import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { DateTimeRangePicker } from '@mui/x-date-pickers-pro';
import { skipToken } from '@reduxjs/toolkit/query';
import moment from 'moment';
import { FunctionComponent } from 'react';
import CardFooter from '../../../../components/CardFooter';
import { FormGrid } from '../../../../components/FormGrid';
import { FormProps } from '../../../../components/forms/types';
import { getFullName, isAdultInScouting } from '../../../../helpers/person';
import useErrorHandler from '../../../../helpers/useErrorHandler';
import { UpdatePackForm } from '../../../../models/pack';
import { packBookingStatusOptions } from '../../../../models/packBookingStatus';
import { registrationStatusOptions } from '../../../../models/registrationStatus';
import { subcamps } from '../../../../models/subcamp';
import { wideningAccessStatusOptions } from '../../../../models/wideningAccessStatus';
import { useGetPeopleQuery } from '../../../../state/protectedApi/people';
import { useGetEditionsQuery } from '../../../../state/publicApi/editions';
import { useGetDistrictsByRegionQuery, useGetRegionsQuery } from '../../../../state/publicApi/reference';

const PackForm: FunctionComponent<FormProps<UpdatePackForm>> = (props) => {
  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = props;

  const {
    data: regions,
    isLoading: regionsLoading,
    error: regionsError,
  } = useGetRegionsQuery();
  useErrorHandler(regionsError, 'API Error: Failed to fetch regions');

  const {
    data: districts,
    isLoading: districtsLoading,
    error: districtsError,
  } = useGetDistrictsByRegionQuery(values.regionId ?? skipToken);
  useErrorHandler(districtsError, 'API Error: Failed to fetch districts');

  const {
    data: editions,
    isLoading: editionsLoading,
    error: editionsError,
  } = useGetEditionsQuery();
  useErrorHandler(editionsError, 'API Error: Failed to fetch event editions');

  const {
    data: people,
    isLoading: peopleLoading,
    error: peopleError,
  } = useGetPeopleQuery({ packId: values.id });
  useErrorHandler(peopleError, 'API Error: Failed to fetch people for pack');
  const leaders = people?.results.slice().filter(isAdultInScouting);

  const arrivalStartMoment = values.arrivalStart ? moment(values.arrivalStart) : null;
  const arrivalEndMoment = values.arrivalEnd ? moment(values.arrivalEnd) : null;
  const departureStartMoment = values.departureStart ? moment(values.departureStart) : null;
  const departureEndMoment = values.departureEnd ? moment(values.departureEnd) : null;

  // TODO: validate that the arrival and departure times are within the edition start and end day
  return (
    <form onSubmit={handleSubmit} noValidate>
      <FormGrid columns={2}>
        <Autocomplete
          options={editions?.map((edition) => edition.id) ?? []}
          loading={editionsLoading}
          getOptionLabel={(id) => editions?.find((edition) => edition.id === id)?.name ?? ''}
          multiple
          renderInput={(params) => (
            <TextField
              {...params}
              label="Preferred Editions"
              error={touched.preferredEditionIds && Boolean(errors.preferredEditionIds)}
              helperText={(touched.preferredEditionIds && errors.preferredEditionIds) || ' '}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {editionsLoading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          value={values.preferredEditionIds}
          onChange={async (event, value) => {
            await setFieldValue('preferredEditionIds', value);
          }}
          onBlur={() => setFieldTouched('preferredEditionIds')}
          disabled={isSubmitting}
        />
      </FormGrid>
      <FormGrid columns={2}>
        <Autocomplete
          options={editions?.map((edition) => edition.id) ?? []}
          loading={editionsLoading}
          getOptionLabel={(id) => editions?.find((edition) => edition.id === id)?.name ?? ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Edition"
              error={touched.editionId && Boolean(errors.editionId)}
              helperText={(touched.editionId && errors.editionId) || ' '}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {editionsLoading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          value={values.editionId}
          onChange={async (event, value) => {
            const setEdition = setFieldValue('editionId', value);
            const setArrivalStart = setFieldValue('arrivalStart', null);
            const setArrivalEnd = setFieldValue('arrivalEnd', null);
            const setDepartureStart = setFieldValue('departureStart', null);
            const setDepartureEnd = setFieldValue('departureEnd', null);
            await Promise.all([
              setEdition,
              setArrivalStart,
              setArrivalEnd,
              setDepartureStart,
              setDepartureEnd,
            ]);
          }}
          onBlur={() => setFieldTouched('editionId')}
        />
        <Autocomplete
          options={subcamps}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Subcamp"
              error={touched.subcamp && Boolean(errors.subcamp)}
              helperText={(touched.subcamp && errors.subcamp) || ' '}
            />
          )}
          value={values.subcamp}
          onChange={async (event, value) => {
            await setFieldValue('subcamp', value);
          }}
          onBlur={() => setFieldTouched('subcamp')}
        />
        <Autocomplete
          options={packBookingStatusOptions}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              label="Booking Status"
              error={touched.bookingStatus && Boolean(errors.bookingStatus)}
              helperText={(touched.bookingStatus && errors.bookingStatus) || ' '}
            />
          )}
          value={packBookingStatusOptions.find((option) => option.value === values.bookingStatus)}
          onChange={async (event, target) => {
            if (target.value) {
              await setFieldValue('bookingStatus', target.value);
            }
          }}
          onBlur={() => setFieldTouched('bookingStatus')}
        />
        <Autocomplete
          options={wideningAccessStatusOptions}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              label="Widening Access"
              error={touched.wideningAccess && Boolean(errors.wideningAccess)}
              helperText={(touched.wideningAccess && errors.wideningAccess) || ' '}
            />
          )}
          value={wideningAccessStatusOptions.find(
            (option) => option.value === values.wideningAccess,
          )}
          onChange={async (event, target) => {
            if (target.value) {
              await setFieldValue('wideningAccess', target.value);
            }
          }}
          onBlur={() => setFieldTouched('wideningAccess')}
        />
        <FormControl
          error={touched.attendedTwoYearsAgo && Boolean(errors.attendedTwoYearsAgo)}
          disabled={isSubmitting}
        >
          <FormLabel id="attended-two-years-ago-label">
            Attended 2022
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="attended-two-years-ago-label"
            value={values.attendedTwoYearsAgo ?? ''}
            onChange={async (event) => {
              if (event.target.value) {
                await setFieldValue('attendedTwoYearsAgo', event.target.value === 'true');
              } else {
                await setFieldValue('attendedTwoYearsAgo', null);
              }
            }}
            onBlur={() => setFieldTouched('attendedTwoYearsAgo')}
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
            <FormControlLabel value="" control={<Radio />} label="Not set" />
            <FormHelperText>
              {(touched.attendedTwoYearsAgo && errors.attendedTwoYearsAgo) || ' '}
            </FormHelperText>
          </RadioGroup>
        </FormControl>
        <FormControl
          error={touched.attendedLastYear && Boolean(errors.attendedLastYear)}
          disabled={isSubmitting}
        >
          <FormLabel id="attended-last-year-label">
            Attended 2023
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="attended-last-year-label"
            value={values.attendedLastYear ?? ''}
            onChange={async (event) => {
              if (event.target.value) {
                await setFieldValue('attendedLastYear', event.target.value === 'true');
              } else {
                await setFieldValue('attendedLastYear', null);
              }
            }}
            onBlur={() => setFieldTouched('attendedLastYear')}
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
            <FormControlLabel value="" control={<Radio />} label="Not set" />
            <FormHelperText>
              {(touched.attendedLastYear && errors.attendedLastYear) || ' '}
            </FormHelperText>
          </RadioGroup>
        </FormControl>
        <Autocomplete
          options={registrationStatusOptions}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              label="Registration Status"
              error={touched.registrationStatus && Boolean(errors.registrationStatus)}
              helperText={(touched.registrationStatus && errors.registrationStatus) || ' '}
            />
          )}
          value={registrationStatusOptions.find(
            (option) => option.value === values.registrationStatus,
          )}
          onChange={async (event, target) => {
            if (target.value) {
              await setFieldValue('registrationStatus', target.value);
            }
          }}
          onBlur={() => setFieldTouched('registrationStatus')}
        />
      </FormGrid>
      <FormGrid columns={1}>
        <TextField
          label="Travel indication (collected from booking form)"
          value={values.travelIndication}
          onBlur={() => setFieldTouched('travelIndication')}
          onChange={async (event) => {
            await setFieldValue('travelIndication', event.target.value);
          }}
          error={touched.travelIndication && Boolean(errors.travelIndication)}
          helperText={(touched.travelIndication && errors.travelIndication) || ' '}
        />
      </FormGrid>
      <FormGrid columns={2}>
        <FormControl
          error={touched.travellingByCar && Boolean(errors.travellingByCar)}
          disabled={isSubmitting}
        >
          <FormLabel id="travel-by-car-label">
            Travelling by car
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="travel-by-car-label"
            value={values.travellingByCar ?? ''}
            onChange={async (event) => {
              if (event.target.value) {
                await setFieldValue('travellingByCar', event.target.value === 'true');
              } else {
                await setFieldValue('travellingByCar', null);
              }
            }}
            onBlur={() => setFieldTouched('travellingByCar')}
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
            <FormControlLabel value="" control={<Radio />} label="Not set" />
            <FormHelperText>
              {(touched.travellingByCar && errors.travellingByCar) || ' '}
            </FormHelperText>
          </RadioGroup>
        </FormControl>
      </FormGrid>
      <FormGrid columns={2}>
        <FormControl
          error={touched.arrivalStart
            && touched.arrivalEnd
            && (Boolean(errors.arrivalStart) || Boolean(errors.arrivalEnd))}
        >
          <FormLabel>
            Arrivals
          </FormLabel>
          <DateTimeRangePicker
            value={[arrivalStartMoment, arrivalEndMoment]}
            onChange={async (dateTimeRange) => {
              const [start, end] = dateTimeRange;
              const setStart = setFieldValue('arrivalStart', start?.toISOString(true) ?? null);
              const setEnd = setFieldValue('arrivalEnd', end?.toISOString(true) ?? null);
              await Promise.all([setStart, setEnd]);
            }}
            disabled={isSubmitting || !values.editionId}
          />
          <FormHelperText>
            {!values.editionId ? 'Select an edition to set arrival times' : (
              (touched.arrivalStart && touched.arrivalEnd && (errors.arrivalStart || errors.arrivalEnd)) || ' '
            )}
          </FormHelperText>
        </FormControl>
      </FormGrid>
      <FormGrid columns={2}>
        <FormControl
          error={touched.departureStart
            && touched.departureEnd
            && (Boolean(errors.departureStart) || Boolean(errors.departureEnd))}
        >
          <FormLabel>
            Departures
          </FormLabel>
          <DateTimeRangePicker
            value={[departureStartMoment, departureEndMoment]}
            onChange={async (dateTimeRange) => {
              const [start, end] = dateTimeRange;
              const setStart = setFieldValue('departureStart', start?.toISOString(true) ?? null);
              const setEnd = setFieldValue('departureEnd', end?.toISOString(true) ?? null);
              await Promise.all([setStart, setEnd]);
            }}
            disabled={isSubmitting || !values.editionId}
          />
          <FormHelperText>
            {!values.editionId ? 'Select an edition to set departure times' : (
              (touched.departureStart && touched.departureEnd && (errors.departureStart || errors.departureEnd)) || ' '
            )}
          </FormHelperText>
        </FormControl>
      </FormGrid>

      <h2>Basic Details</h2>
      <FormGrid columns={2}>
        <Autocomplete
          options={leaders?.map((leader) => leader.id) ?? []}
          loading={peopleLoading}
          multiple
          getOptionLabel={(id) => {
            const person = leaders?.find((leader) => leader.id === id);
            if (!person) {
              return '';
            }
            return getFullName(person);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Booking Contact(s)"
              error={touched.bookingContactIds && Boolean(errors.bookingContactIds)}
              helperText={(touched.bookingContactIds && errors.bookingContactIds) || ' '}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {peopleLoading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          value={values.bookingContactIds}
          onChange={(event, value) => setFieldValue('bookingContactIds', value)}
          onBlur={() => setFieldTouched('bookingContactIds')}
        />
      </FormGrid>
      <FormGrid columns={2}>
        <Autocomplete
          options={regions?.map((region) => region.id) ?? []}
          loading={regionsLoading}
          getOptionLabel={(id) => regions?.find((region) => region.id === id)?.name ?? ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Region"
              error={touched.regionId && Boolean(errors.regionId)}
              helperText={(touched.regionId && errors.regionId) || ' '}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {regionsLoading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          value={values.regionId}
          onChange={async (event, value) => {
            await setFieldValue('districtId', null);
            await setFieldValue('regionId', value);
          }}
          onBlur={() => setFieldTouched('regionId')}
        />
        <Autocomplete
          options={districts?.map((district) => district.id) ?? []}
          loading={districtsLoading}
          getOptionLabel={(id) => districts?.find((district) => district.id === id)?.name ?? ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label="District"
              error={touched.districtId && Boolean(errors.districtId)}
              helperText={
                (touched.districtId && errors.districtId)
                || (!values.regionId && 'Select a region first')
                || ' '
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {districtsLoading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          value={values.districtId}
          onChange={(event, value) => setFieldValue('districtId', value)}
          onBlur={() => setFieldTouched('districtId')}
          disabled={values.regionId === null}
        />
        <TextField
          label="Group name"
          value={values.groupName}
          onBlur={() => setFieldTouched('groupName')}
          onChange={async (event) => {
            await setFieldValue('groupName', event.target.value);
          }}
          error={touched.groupName && Boolean(errors.groupName)}
          helperText={(touched.groupName && errors.groupName) || ' '}
        />
        <TextField
          label="Pack name"
          value={values.packName}
          onBlur={() => setFieldTouched('packName')}
          onChange={async (event) => {
            await setFieldValue('packName', event.target.value);
          }}
          error={touched.packName && Boolean(errors.packName)}
          helperText={(touched.packName && errors.packName)
            || 'Only required if the group has more than one pack'}
        />
      </FormGrid>
      <h4>Meeting Place</h4>
      <TextField
        label="Street"
        value={values.addressStreet}
        onBlur={() => setFieldTouched('addressStreet')}
        onChange={async (event) => {
          await setFieldValue('addressStreet', event.target.value);
        }}
        error={touched.addressStreet && Boolean(errors.addressStreet)}
        helperText={(touched.addressStreet && errors.addressStreet) || ' '}
        multiline
        minRows={3}
      />
      <FormGrid columns={2}>
        <TextField
          label="Town"
          value={values.addressTown}
          onBlur={() => setFieldTouched('addressTown')}
          onChange={async (event) => {
            await setFieldValue('addressTown', event.target.value);
          }}
          error={touched.addressTown && Boolean(errors.addressTown)}
          helperText={(touched.addressTown && errors.addressTown) || ' '}
        />
        <TextField
          label="Postcode"
          value={values.addressPostcode}
          onBlur={() => setFieldTouched('addressPostcode')}
          onChange={async (event) => {
            await setFieldValue('addressPostcode', event.target.value);
          }}
          error={touched.addressPostcode && Boolean(errors.addressPostcode)}
          helperText={(touched.addressPostcode && errors.addressPostcode) || ' '}
        />
      </FormGrid>

      <h2>Bookings</h2>
      <FormGrid columns={2}>
        <TextField
          label="Cubs"
          value={values.bookings.cubs}
          onBlur={() => setFieldTouched('bookings.cubs')}
          onChange={async (event) => {
            const newValue = Number(event.target.value);
            if (!Number.isNaN(newValue)) {
              await setFieldValue('bookings.cubs', newValue);
            }
          }}
          error={touched.bookings?.cubs && Boolean(errors.bookings?.cubs)}
          helperText={(touched.bookings?.cubs && errors.bookings?.cubs) || ' '}
          inputProps={{ pattern: '[0-9]*' }}
        />
        <TextField
          label="Young leaders"
          value={values.bookings.youngLeaders}
          onBlur={() => setFieldTouched('bookings.youngLeaders')}
          onChange={async (event) => {
            const newValue = Number(event.target.value);
            if (!Number.isNaN(newValue)) {
              await setFieldValue('bookings.youngLeaders', newValue);
            }
          }}
          error={touched.bookings?.youngLeaders && Boolean(errors.bookings?.youngLeaders)}
          helperText={(touched.bookings?.youngLeaders && errors.bookings?.youngLeaders) || ' '}
          inputProps={{ pattern: '[0-9]*' }}
        />
        <TextField
          label="Adult leaders"
          value={values.bookings.leaders}
          onBlur={() => setFieldTouched('bookings.leaders')}
          onChange={async (event) => {
            const newValue = Number(event.target.value);
            if (!Number.isNaN(newValue)) {
              await setFieldValue('bookings.leaders', newValue);
            }
          }}
          error={touched.bookings?.leaders && Boolean(errors.bookings?.leaders)}
          helperText={(touched.bookings?.leaders && errors.bookings?.leaders) || ' '}
          inputProps={{ pattern: '[0-9]*' }}
        />
        <TextField
          label="Other children"
          value={values.bookings.otherChildren}
          onBlur={() => setFieldTouched('bookings.otherChildren')}
          onChange={async (event) => {
            const newValue = Number(event.target.value);
            if (!Number.isNaN(newValue)) {
              await setFieldValue('bookings.otherChildren', newValue);
            }
          }}
          error={touched.bookings?.otherChildren && Boolean(errors.bookings?.otherChildren)}
          helperText={(touched.bookings?.otherChildren && errors.bookings?.otherChildren) || ' '}
          inputProps={{ pattern: '[0-9]*' }}
        />
      </FormGrid>

      <CardFooter>
        <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </CardFooter>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </form>
  );
};

export default PackForm;

import { AccordionDetails } from '@mui/material';
import styled from 'styled-components';
import { responsiveSpacing } from '../../helpers/responsiveSpacing';

export const CategoryWrapper = styled.div`
  ${responsiveSpacing('margin-bottom', 'l')}
`;

export const Answer = styled(AccordionDetails)`
  padding-top: 0;

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import CardIntroduction from '../../../components/CardIntroduction';
import { FormGrid } from '../../../components/FormGrid';
import IconLink from '../../../components/IconLink';
import { useBookingsContent } from '../../../helpers/content';
import { getEditionSummary } from '../../../helpers/edition';
import { useFormikWithStateSync } from '../../../helpers/form';
import routes from '../../../routes';
import { selectBooking, update } from '../../../state/booking';
import { useGetEventConfigQuery } from '../../../state/publicApi';
import { useGetEditionsQuery } from '../../../state/publicApi/editions';
import { validationSchema } from './schema';

const BookingEditionsFormPage = (): JSX.Element => {
  const navigate = useNavigate();

  const { content } = useBookingsContent();
  const { data: eventConfig } = useGetEventConfigQuery();
  const { data: editions } = useGetEditionsQuery();

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormikWithStateSync(
    selectBooking,
    update,
    {
      validationSchema,
      onSubmit: async () => {
        navigate(routes.bookings.review);
      },
    },
  );

  if (!eventConfig || !editions || !content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <Card>
      <form onSubmit={handleSubmit} noValidate>
        <CardHeading>{content.editions.title}</CardHeading>
        <CardIntroduction>
          <BlocksRenderer content={content.editions.body} />
        </CardIntroduction>
        <FormGrid>
          <FormControl
            error={touched.preferredEditionIds && !!errors.preferredEditionIds}
            component="fieldset"
          >
            <FormLabel component="legend">Pick one or more editions to enter the ballot for:</FormLabel>
            <FormGroup>
              {editions?.map((edition) => (
                <FormControlLabel
                  key={edition.id}
                  control={(
                    <Checkbox
                      checked={values.preferredEditionIds.includes(edition.id)}
                      onChange={async (event) => {
                        const newValue = event.target.checked;
                        if (newValue) {
                          await setFieldValue('preferredEditionIds', [...values.preferredEditionIds, edition.id]);
                        } else {
                          await setFieldValue('preferredEditionIds', values.preferredEditionIds.filter((id) => id !== edition.id));
                        }
                      }}
                      onBlur={() => setFieldTouched('preferredEditionIds')}
                      name={edition.name}
                    />
                  )}
                  label={eventConfig && getEditionSummary(eventConfig, edition)}
                />
              ))}
            </FormGroup>
            <FormHelperText>{(touched.preferredEditionIds && errors.preferredEditionIds) || ' '}</FormHelperText>
          </FormControl>
        </FormGrid>
        <CardFooter>
          <IconLink icon="Back" to={routes.bookings.travel}>Back</IconLink>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Next
          </Button>
        </CardFooter>
      </form>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </Card>
  );
};

export default BookingEditionsFormPage;

import { ObjectSchema } from 'yup';
import * as Yup from 'yup';
import { AnyObject } from 'yup/es/types';
import ValidationError from 'yup/lib/ValidationError';
import { subtractDays, subtractYears } from '../../../helpers/dates';
import { getFirstEdition, getLastEdition } from '../../../helpers/edition';
import personRules from '../../../helpers/validation/person';
import { Edition } from '../../../models/edition';
import { NewRegistration } from '../../../state/registration/types';

const validateDateOfBirth = (
  allEditions?: Edition[],
) => (
  value: Date | undefined,
  context: Yup.TestContext<AnyObject>,
): boolean | ValidationError => {
  const attendee = context.parent as Partial<NewRegistration['attendee']>;
  const { status, editions: selectedEditionIds } = attendee;
  const editions = selectedEditionIds && selectedEditionIds.length ? allEditions?.slice().filter(
    (edition) => selectedEditionIds?.includes(edition.id),
  ) : allEditions;

  const firstEdition = getFirstEdition(editions ?? []);
  const lastEdition = getLastEdition(editions ?? []);

  if (!value || !firstEdition || !lastEdition) {
    return true;
  }

  const firstStart = new Date(firstEdition.start);
  const lastStart = new Date(lastEdition.start);

  if (status === 'Cub' && value > subtractYears(lastStart, 7)) {
    return context.createError({
      message: 'Cubs must be aged at least 7 years',
    });
  }
  if (status === 'Cub' && value > subtractYears(firstStart, 7)) {
    return context.createError({
      message: 'Cubs must be aged at least 7 years at the start of the event. They will only be '
        + '7 years old for some of the event editions. Only select event editions that start on or '
        + 'after their 7th birthday',
    });
  }
  if (status === 'Cub' && value < subtractYears(firstStart, 12)) {
    return context.createError({
      message: 'Cubs cannot be older than 11 years',
    });
  }
  if (status === 'Cub' && value < subtractYears(lastStart, 12)) {
    return context.createError({
      message: 'Cubs cannot be older than 11 years at the start of the event, but they will '
        + 'have their 12th birthday between the starts of the selected event editions. Only '
        + 'select event editions that start before their 12th birthday',
    });
  }

  if (status === 'OtherChild' && value < subtractYears(firstStart, 14)) {
    return context.createError({
      message: 'Other children cannot be older than 14 years',
    });
  }

  if (status === 'YoungLeader' && value > subtractYears(lastStart, 12)) {
    return context.createError({
      message: 'Young leaders must be aged at least 12 years',
    });
  }
  if (status === 'YoungLeader' && value > subtractYears(firstStart, 12)) {
    return context.createError({
      message: 'Young leaders must be aged at least 12 years at the start of the event, but they will '
        + 'have their 12th birthday between the starts of the selected event editions. Only '
        + 'select event editions that start on or after their 12th birthday',
    });
  }
  if (status === 'YoungLeader' && value < subtractYears(firstStart, 18)) {
    return context.createError({
      message: 'Young leaders cannot be older than 17 years',
    });
  }
  if (status === 'YoungLeader' && value < subtractYears(lastStart, 18)) {
    return context.createError({
      message: 'Young leaders cannot be older than 17 years at the start of the event, but they will '
        + 'have their 18th birthday between the starts of the selected event editions. If they are attending '
        + 'some editions as a young leader and others as an adult leader, we need separate '
        + 'registrations for editions starting before vs. after their 18th birthday.',
    });
  }

  if (status === 'Leader' && value > subtractYears(lastStart, 18)) {
    return context.createError({
      message: 'To register yourself you must be at least 18 year old at the start of the event',
    });
  }
  if (status === 'Leader' && value > subtractYears(firstStart, 18)) {
    return context.createError({
      message: 'Your 18th birthday is between the event editions selected, but you cannot register as both an adult leader and '
        + 'young leader at the same time. You need a guardian to register you as a young leader for editions which '
        + ' start before your birthday. For editions that start on or after your birthday you can register yourself.',
    });
  }

  return true;
};

export const getValidationSchema = (
  editions?: Edition[],
): ObjectSchema<AnyObject> => {
  const yesterday = subtractDays(new Date(), 1);

  return Yup.object().shape({
    attendee: Yup.object().shape({
      dateOfBirth: Yup.date()
        .typeError('Date of birth is required')
        .required('Date of birth is required')
        .max(yesterday, 'Date must be in the past')
        .test(validateDateOfBirth(editions)),
      editions: Yup.array().when([], {
        is: () => editions && editions.length > 1,
        then: Yup.array().of(Yup.number().required('At least one edition must be selected')),
      }),
      gender: Yup.mixed().required('Gender is required'),
      addressStreet: personRules.addressStreet.required('Street address is required'),
      addressTown: personRules.addressTown.required('Town is required'),
      addressPostcode: personRules.addressPostcode.required('Postcode is required'),
    }).when('meta.type', {
      is: 'staff',
      then: Yup.object().shape({
        staffTeam: Yup.mixed().required('Team is required'),
        groupName: personRules.groupName,
      }),
    })
      .when('meta.registeringSelf', {
        is: true,
        then: Yup.object().shape({
          scoutingAppointment: personRules.scoutingAppointment.required('Scouting role is required'),
          membershipNumber: personRules.membershipNumber.required('Membership number is required'),
        }),
        otherwise: Yup.object().shape({
          status: Yup.mixed().required('Status is required'),
          firstName: personRules.firstName.required('First name is required'),
          knownAs: personRules.knownAs,
          lastName: personRules.lastName.required('Last name is required'),
          mobile: Yup.string().when('status', {
            is: 'YoungLeader',
            then: personRules.mobile.required('A mobile number is required for Young Leaders'),
          }),
          email: Yup.string().when('status', {
            is: 'YoungLeader',
            then: personRules.email.required('An email address is required for Young Leaders'),
          }),
        }),
      }),
  });
};

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import { Address } from '../../models/address';
import { District } from '../../models/district';
import { Region } from '../../models/region';
import type { RootState } from '../store';

export interface NewBooking extends PersistPartial {
  meta: {
    reference?: string
    region: Region | null
    district: District | null
    showEditionsChoice: boolean
  }
  pack: Address & {
    groupName: string
    packName: string
    cubs: number
    leaders: number
    youngLeaders: number
    otherChildren: number
    travelIndication: string
  }
  contact: Address & {
    firstName: string
    knownAs: string
    lastName: string
    scoutingAppointment: string
    membershipNumber: string
    mobile: string
    email: string
  }
  preferredEditionIds: number[]
}

export const initialState: NewBooking = {
  _persist: {
    version: -1,
    rehydrated: false,
  },
  meta: {
    reference: undefined,
    region: null,
    district: null,
    showEditionsChoice: true,
  },
  pack: {
    groupName: '',
    packName: '',
    cubs: 0,
    leaders: 0,
    youngLeaders: 0,
    otherChildren: 0,
    travelIndication: '',
    addressStreet: '',
    addressTown: '',
    addressPostcode: '',
  },
  contact: {
    firstName: '',
    knownAs: '',
    lastName: '',
    scoutingAppointment: '',
    membershipNumber: '',
    mobile: '',
    email: '',
    addressStreet: '',
    addressTown: '',
    addressPostcode: '',
  },
  preferredEditionIds: [],
};

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    reset: () => initialState,
    update: (
      state,
      action: PayloadAction<Partial<NewBooking>>,
    ) => ({ ...state, ...action.payload }),
  },
});

export const { reset, update } = bookingSlice.actions;

export const selectBooking = (state: RootState): NewBooking => state.booking;

export default bookingSlice.reducer;

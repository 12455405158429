import { isAdult, isLeaderOrAdult } from '../../../../../helpers/person';
import { UpdatePerson } from '../../../../../models/person';

const sanitiseOnSubmit = (rawValues: UpdatePerson): UpdatePerson => ({
  ...rawValues,
  firstName: rawValues.firstName?.trim(),
  knownAs: rawValues.knownAs?.trim() ? rawValues.knownAs?.trim() : null,
  lastName: rawValues.lastName?.trim(),
  scoutingAppointment: isAdult(rawValues) ? rawValues.scoutingAppointment?.trim() : undefined,
  membershipNumber: isAdult(rawValues) ? rawValues.membershipNumber?.trim() : undefined,
  mobile: isLeaderOrAdult(rawValues) ? rawValues.mobile?.trim() : undefined,
  email: isLeaderOrAdult(rawValues) ? rawValues.email?.trim() : undefined,
  addressStreet: rawValues.addressStreet?.trim(),
  addressTown: rawValues.addressTown?.trim(),
  addressPostcode: rawValues.addressPostcode?.trim(),
});

export default sanitiseOnSubmit;

import { Chip } from '@mui/material';
import { getStatusColourForPersonStatus } from '../../helpers/statusColours';
import { PersonStatus, getPersonStatusLabel } from '../../models/personStatus';

type PersonStatusChipProps = {
  status: PersonStatus
  size?: 'small' | 'medium'
};

const PersonStatusChip = (props: PersonStatusChipProps): JSX.Element => {
  const { status, size = 'medium' } = props;

  return (
    <Chip
      size={size}
      label={getPersonStatusLabel(status)}
      color={getStatusColourForPersonStatus(status)}
      variant="outlined"
    />
  );
};

export default PersonStatusChip;

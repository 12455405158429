import { NewBooking } from '../../state/booking';
import type { BookingCreationRequest } from './index';

const mapToRequest = (data: NewBooking): BookingCreationRequest => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { _persist, meta, ...rawValues } = data;

  const pack = {
    ...data.pack,
    districtId: meta.district?.id,
  };

  return { ...rawValues, pack };
};

export default mapToRequest;

import { GridColDef } from '@mui/x-data-grid-pro';
import RouterLink from '../../../../components/RouterLink';
import { eventPhaseOrLater } from '../../../../helpers/eventConfig';
import { getFullName } from '../../../../helpers/person';
import { EventConfig } from '../../../../models/eventConfig';
import { getOnsiteStatusLabel, onsiteStatuses } from '../../../../models/onsiteStatus';
import { Person } from '../../../../models/person';
import { getPersonStatusLabel, personStatuses } from '../../../../models/personStatus';
import { getReviewStatusLabel, reviewStatuses } from '../../../../models/reviewStatus';
import routes from '../../../../routes';

const getColumns = (eventConfig: EventConfig): GridColDef<Person>[] => [
  {
    field: 'id',
    headerName: 'Person ID',
    width: 125,
    type: 'number' as const,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 125,
    type: 'singleSelect',
    valueOptions: personStatuses.map(getPersonStatusLabel),
    valueGetter: (value, row: Person) => getPersonStatusLabel(row.status),
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 175,
    valueGetter: (value: never, row: Person) => getFullName(row),
    renderCell: (params) => (
      <RouterLink to={routes.people.detail(params.row.id)}>
        {params.value}
      </RouterLink>
    ),
  },
  ...eventPhaseOrLater(eventConfig, 'Allocation') ? [{
    field: 'onsite',
    headerName: 'Onsite',
    type: 'singleSelect',
    width: 125,
    valueOptions: onsiteStatuses.map(getOnsiteStatusLabel),
    valueGetter: getOnsiteStatusLabel,
  } as const] : [],
  { field: 'scoutingAppointment', headerName: 'Scouting role', width: 175 },
  {
    field: 'mediaConsent',
    headerName: 'Media Consent',
    width: 125,
    type: 'boolean',
    valueGetter: (value, row: Person) => row.mediaConsent ?? '',
  } as const,
  {
    field: 'packLeaderReviewStatus',
    headerName: 'Review',
    width: 175,
    type: 'singleSelect',
    valueOptions: reviewStatuses.map(getReviewStatusLabel),
    valueGetter: getReviewStatusLabel,
  } as const,
];

export default getColumns;

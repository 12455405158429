import { objectToSnake } from 'ts-case-convert/lib/caseConvert';
import apiService from '../apiService';

export type MealCheckInRequest = {
  mealId: number
  personId?: number
  personPublicId?: string
};

export type MealCheckInResponse = {
  id: number
  message: string
  status: 'success' | 'info' | 'warning'
};

type MealCheckInService = {
  notify: (body: MealCheckInRequest) => Promise<MealCheckInResponse>
};

const mealCheckInService: MealCheckInService = {
  notify: async (data) => apiService.post('meals/check-in', objectToSnake(data)),
};

export default mealCheckInService;

import { OptionList } from '../helpers/options';
import { toTitleCase } from '../helpers/strings';

const personEmailTemplates = [
  'UserInvitation',
  'RegistrationConfirmationStaffAdult',
  'RegistrationConfirmationStaffYouth',
  'RegistrationConfirmationPackAdult',
  'RegistrationConfirmationPackYouth',
  'ParkingPermitInstructions',
  'ParkingPermitReminder',
  'ParkingPermitClosed',
  'ParkingPermitConfirmation',
  'AdultSuitabilityChecks',
  'FinalInformationCubs',
  'FinalInformationOtherChildren',
  'FinalInformationYoungLeaders',
  'FinalInformationLeaders',
  'FinalInformationStaff',
  'FeedbackRequest',
  'FeedbackReminder',
  'MerchandiseOrderInstructions',
  'MerchandiseOrderReminder',
  'MerchandiseOrderConfirmation',
] as const;

const packEmailTemplates = [
  'BallotConfirmation',
  'BallotOutcomeOffered',
  'BallotOutcomeOfferedWithWideningAccess',
  'BallotOutcomeOfferedWithoutWideningAccess',
  'BallotOutcomeWaitListed',
  'BallotOutcomeUnsuccessful',
  'WaitingListOffered',
  'WaitingListOfferedWithWideningAccess',
  'WaitingListOfferedWithoutWideningAccess',
  'WaitingListClosed',
  'BookingAccepted',
  'BookingAdjustmentDeadlineReminder',
  'DepositPaymentInstruction',
  'DepositPaymentReminder',
  'DepositPaymentOverdue',
  'DepositPaymentReceipt',
  'FinalPaymentInstruction',
  'FinalPaymentReminder',
  'FinalPaymentOverdue',
  'FinalPaymentReceipt',
  'RegistrationsOpen',
  'RegistrationsReminder',
  'RegistrationsClosed',
  'SwapDeadlineReminder',
  'SwapDeadlinePassed',
  'PackLeaderReviewInstructions',
  'PackLeaderReviewReminder',
  'PackLeaderReviewOverdue',
  'ActivitySupportEnquiry',
  'NanInstructions',
  'PlanningUpdateCars',
  'PlanningUpdateCoach',
  'PlanningUpdateMinibus',
  'PlanningUpdateTrain',
] as const;

export type PersonEmailTemplate = typeof personEmailTemplates[number];
export type PackEmailTemplate = typeof packEmailTemplates[number];
export type EmailTemplate = PersonEmailTemplate | PackEmailTemplate;

export const isPersonEmailTemplate = (
  template: string,
): template is PersonEmailTemplate => personEmailTemplates.includes(template as never);

export const isPackEmailTemplate = (
  template: string,
): template is PackEmailTemplate => packEmailTemplates.includes(template as never);

export const personEmailTemplateOptions: OptionList<PersonEmailTemplate> = personEmailTemplates.map(
  (template) => ({
    label: toTitleCase(template),
    value: template,
  }),
);

export const packEmailTemplateOptions: OptionList<PackEmailTemplate> = packEmailTemplates.map(
  (template) => ({
    label: toTitleCase(template),
    value: template,
  }),
);

export const emailTemplateOptions: OptionList<EmailTemplate> = [
  ...personEmailTemplateOptions,
  ...packEmailTemplateOptions,
];

import { Skeleton } from '@mui/material';
import { getRandomNumber } from '../../helpers/random';

const widths = [180, 190, 200, 200, 210, 210, 210, 220, 220, 230, 240, 250];

const getWidth = (): number => widths[Math.floor(Math.random() * widths.length)];
const getKey = (): string => `text-filler-line-${getRandomNumber().toString()}`;
const getPlaceholders = (count: number): JSX.Element[] => {
  const placeholders = [];
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < count; index++) {
    placeholders.push(<Skeleton key={getKey()} variant="text" width={getWidth()} />);
  }
  return placeholders;
};

const LoadingText = (props: { lines?: number }): JSX.Element => {
  const { lines = 1 } = props;

  return (
    <div>
      {getPlaceholders(lines)}
    </div>
  );
};

export default LoadingText;

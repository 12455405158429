import { Differ, Viewer } from 'json-diff-kit';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import ContentLayout from '../../../components/ContentLayout';
import 'json-diff-kit/dist/viewer.css';
import PageMetaTitle from '../../../components/PageMetaTitle';
import RouterLink from '../../../components/RouterLink';
import { getFullName } from '../../../helpers/person';
import routes from '../../../routes';
import { useGetPersonQuery } from '../../../state/protectedApi/people';
import { usePersonSummary } from './usePersonSummary';

const PeopleComparePage = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const personOneId = Number(searchParams.get('personOne'));
  const personTwoId = Number(searchParams.get('personTwo'));

  const { data: personOne } = useGetPersonQuery(personOneId);
  const { data: personTwo } = useGetPersonQuery(personTwoId);

  const summaryOne = usePersonSummary(personOneId);
  const summaryTwo = usePersonSummary(personTwoId);

  const diff = useMemo(() => {
    const differ = new Differ({
      detectCircular: true,
      showModifications: true,
      ignoreCase: true,
    });

    return differ.diff(summaryOne, summaryTwo);
  }, [summaryOne, summaryTwo]);

  return (
    <ContentLayout>
      <PageMetaTitle title="Compare People" />
      <h1>Compare People</h1>
      {personOne && personTwo && (
        <p>
          <RouterLink to={routes.people.detail(personOneId)}>
            {`${getFullName(personOne)} (ID: ${personOneId})`}
          </RouterLink>
          {' vs. '}
          <RouterLink to={routes.people.detail(personTwoId)}>
            {`${getFullName(personTwo)} (ID: ${personTwoId})`}
          </RouterLink>
        </p>
      )}
      <Viewer
        diff={diff}
        indent={4}
        highlightInlineDiff
        inlineDiffOptions={{
          mode: 'word',
          wordSeparator: ' ',
        }}
      />
    </ContentLayout>
  );
};

export default PeopleComparePage;

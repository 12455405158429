import { Backdrop, CircularProgress } from '@mui/material';
import Image from 'mui-image';
import { useParams } from 'react-router-dom';
import ActivityRegister from '../../components/ActivityRegister';
import BlocksRenderer from '../../components/BlocksRenderer';
import Card from '../../components/Card';
import CardHeading from '../../components/CardHeading';
import CardIntroduction from '../../components/CardIntroduction';
import IconLink from '../../components/IconLink';
import PageMetaTitle from '../../components/PageMetaTitle';
import { addCmsBaseUrl } from '../../helpers/cmsImage';
import { useActivitiesContent } from '../../helpers/content';
import useErrorHandler from '../../helpers/useErrorHandler';
import { useGetActivityQuery } from '../../state/publicApi/activities';

const ActivityPage = (): JSX.Element => {
  const { activityId } = useParams();

  const queryParameters = new URLSearchParams(window.location.search);
  const leaderView = queryParameters.get('leader') === 'true';

  const { data: activity, error: activityError } = useGetActivityQuery(Number(activityId));
  useErrorHandler(activityError, 'API Error: Failed to load activity');

  const { content, error: contentError } = useActivitiesContent();
  useErrorHandler(contentError, 'CMS Error: Failed to load activities content');
  const activityContent = content?.find((c) => c.id === Number(activityId));

  if (!activity) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <Card>
      <PageMetaTitle title={activity.name} />
      <CardHeading>{activity.name}</CardHeading>
      {leaderView && activity.documents?.map((document) => (
        <CardIntroduction key={document.id}>
          <IconLink icon="Document" to={document.url}>{document.name}</IconLink>
        </CardIntroduction>
      ))}
      {leaderView && activity && (
        <ActivityRegister activityId={activity.id} type="public" />
      )}
      {activityContent?.square_image.data && !leaderView && (
        <>
          <CardIntroduction>
            {activityContent.description && (
              <BlocksRenderer content={activityContent.description} />
            )}
          </CardIntroduction>
          <Image
            alt={activity.name}
            src={addCmsBaseUrl(activityContent.square_image.data.attributes.url)}
            duration={1000}
          />
        </>
      )}
    </Card>
  );
};

export default ActivityPage;

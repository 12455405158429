import { OptionList } from '../helpers/options';

export const mealStatuses = [
  'Closed',
  'Open',
  'Complete',
] as const;

export type MealStatus = (typeof mealStatuses)[number];

export const isValidMealStatus = (
  value: string,
): value is MealStatus => mealStatuses.includes(value as never);

export const mealStatusOptions: OptionList<MealStatus> = mealStatuses.map(
  (status) => ({
    label: status,
    value: status,
  }),
);

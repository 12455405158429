import { GridFilterModel, GridLogicOperator, GridSortModel } from '@mui/x-data-grid-pro';
import { objectToSnake } from 'ts-case-convert/lib/caseConvert';
import { GridPaginationModel } from './paginationResponse';

export type DataRequest<TParams extends object = object> = TParams & {
  pagination?: GridPaginationModel
  sort?: GridSortModel
  filter?: GridFilterModel
};

const operatorsWithoutValue = ['empty', 'isEmpty', 'isNotEmpty'];
const operatorsWithListValue = ['isAnyOf'];

export const sanitiseFilter = (
  filter: GridFilterModel | undefined,
): Pick<Required<GridFilterModel>, 'items' | 'logicOperator'> => ({
  logicOperator: filter?.logicOperator ?? GridLogicOperator.And,
  items: filter?.items.slice()
    .filter((item) => {
      if (operatorsWithoutValue.includes(item.operator)) {
        return true;
      }
      if (operatorsWithListValue.includes(item.operator)) {
        return Array.isArray(item.value) && item.value.length > 0;
      }
      return Boolean(item.value);
    })
    .map((item) => ({
      field: item.field,
      operator: item.operator,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value: item.value,
    })) ?? [],
});

export const formatUrlParams = (request: DataRequest): string => {
  const {
    pagination,
    sort,
    filter,
    ...custom
  } = request;

  const urlParams = new URLSearchParams();

  Object.entries(objectToSnake(custom)).forEach(([key, value]) => {
    if (value) {
      urlParams.append(key, JSON.stringify(objectToSnake(value)));
    }
  });

  if (pagination?.pageSize) {
    urlParams.append('page_size', pagination.pageSize.toString());
  }
  if (pagination?.page) {
    urlParams.append('page', pagination.page.toString());
  }
  if (sort?.length) {
    urlParams.append('sort', JSON.stringify(objectToSnake(sort)));
  }
  if (filter?.items.length) {
    urlParams.append('filter_items', JSON.stringify(objectToSnake(filter.items.slice())));
    urlParams.append('filter_operator', filter.logicOperator ?? GridLogicOperator.And);
  }

  return urlParams.toString();
};

import styled from 'styled-components';
import { responsiveSpacing } from '../../../../helpers/responsiveSpacing';

export const Wrapper = styled.div`
  ${responsiveSpacing('margin', 'm', '0')}
`;

export const Title = styled.h2`
  margin: 0;
`;

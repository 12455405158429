import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinkIcon from '@mui/icons-material/Link';
import {
  Accordion, AccordionSummary, Backdrop, Button, CircularProgress,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AnchorHeader from '../../components/AnchorHeader';
import BlocksRenderer from '../../components/BlocksRenderer';
import ContentLayout from '../../components/ContentLayout';
import PageMetaTitle from '../../components/PageMetaTitle';
import RouterLink from '../../components/RouterLink';
import { useFaqContent } from '../../helpers/content';
import useErrorHandler from '../../helpers/useErrorHandler';
import * as Styled from './styles';

const getAnchor = (category: { heading: string }, item?: { id: number }): string => {
  let anchor = category.heading.replace(' ', '_');
  if (item) {
    anchor += `_${item.id}`;
  }
  return anchor;
};

const getCategoryHeading = (
  category: { heading: string, archived: boolean },
): string => (category.archived ? `${category.heading} (archived)` : category.heading);

const sortArchivedToEnd = (a: { archived: boolean }, b: { archived: boolean }): number => {
  if (a.archived && !b.archived) {
    return 1;
  }
  if (!a.archived && b.archived) {
    return -1;
  }
  return 0;
};

const FaqPage = (): JSX.Element => {
  const { content: rawContent, error } = useFaqContent();
  useErrorHandler(error, 'CMS Error: Failed to load content for FAQs page');

  const content = {
    ...rawContent,
    categories: rawContent?.categories?.slice().filter(
      (c) => !c.archived || rawContent.show_archived,
    ).sort(sortArchivedToEnd),
  };

  const location = useLocation();
  const navigate = useNavigate();

  const currentAnchor = location.hash.replace('#', '');
  const anchors = content.categories?.flatMap(
    (category) => category.items.map((item) => getAnchor(category, item)),
  );
  const [open, setOpen] = useState<{ [anchor: string]: boolean }>(Object.fromEntries(
    anchors?.map((anchor) => [anchor, anchor === currentAnchor]) ?? [],
  ));

  useEffect(() => {
    if (currentAnchor) {
      setOpen((prev) => ({
        ...prev,
        [currentAnchor]: true,
      }));
    }
  }, [currentAnchor]);

  if (!content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  const copyLinkToAnswer = async (anchor: string): Promise<void> => {
    const relativeLink = `#${anchor}`;
    const absoluteLink = `${window.location.origin}${window.location.pathname}${relativeLink}`;
    await navigator.clipboard.writeText(absoluteLink);
    navigate(relativeLink, { replace: true });
    enqueueSnackbar(
      'Anchor link copied to clipboard',
      {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        variant: 'success',
        persist: false,
      },
    );
  };

  return (
    <ContentLayout>
      <PageMetaTitle title="FAQs" />
      <ContentLayout desktop={8} tablet={6}>
        <h1>{content && content.title}</h1>
        {content?.introduction && (
          <BlocksRenderer content={content.introduction} />
        )}
        {content?.menu_minimum
          && content?.categories
          && content.categories.length >= content.menu_minimum
          && (
          <Styled.CategoryWrapper>
            <h2>Categories</h2>
            <ol>
              {content.categories.map((category) => (
                <li key={category.id}>
                  <RouterLink to={`#${getAnchor(category)}`}>
                    {getCategoryHeading(category)}
                  </RouterLink>
                </li>
              ))}
            </ol>
          </Styled.CategoryWrapper>
          )}
        {content && content.categories?.map((category) => (
          <Styled.CategoryWrapper key={category.id}>
            <AnchorHeader id={getAnchor(category)} tag="h2">
              {getCategoryHeading(category)}
            </AnchorHeader>
            {category.summary && (
              <BlocksRenderer content={category.summary} />
            )}
            {category.items.map((item) => (
              <Accordion
                key={item.id}
                id={getAnchor(category, item)}
                expanded={open[getAnchor(category, item)] ?? false}
                onChange={(_, expanded) => setOpen((prev) => ({
                  ...prev,
                  [getAnchor(category, item)]: expanded,
                }))}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {open[getAnchor(category, item)] ? (
                    <strong>
                      {item.question}
                    </strong>
                  ) : item.question}
                </AccordionSummary>
                <Styled.Answer>
                  <BlocksRenderer content={item.answer} />
                  <Button
                    variant="text"
                    size="small"
                    startIcon={<LinkIcon />}
                    onClick={() => copyLinkToAnswer(getAnchor(category, item))}
                  >
                    Copy link to this answer
                  </Button>
                </Styled.Answer>
              </Accordion>
            ))}
          </Styled.CategoryWrapper>
        ))}
      </ContentLayout>
    </ContentLayout>
  );
};

export default FaqPage;

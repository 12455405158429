import * as Yup from 'yup';
import personRules from '../../../helpers/validation/person';

export const validationSchema = Yup.object().shape({
  contact: Yup.object().shape({
    addressStreet: personRules.addressStreet.required('Street is required'),
    addressTown: personRules.addressTown.required('Town is required'),
    addressPostcode: personRules.addressPostcode.required('Postcode is required'),
  }),
});

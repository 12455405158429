export const addCmsBaseUrl = (url: string): string => {
  if (url.startsWith('http')) {
    return url;
  }

  const cmsBaseUrl = process.env.REACT_APP_CMS_BASE_URL;
  if (!cmsBaseUrl) {
    throw new Error('REACT_APP_CMS_BASE_URL is not set');
  }
  return cmsBaseUrl + url;
};

import { Backdrop, CircularProgress } from '@mui/material';
import BlocksRenderer from '../../../components/BlocksRenderer';
import Card from '../../../components/Card';
import CardHeading from '../../../components/CardHeading';
import { useBookingsContent } from '../../../helpers/content';
import { useGetEventConfigQuery } from '../../../state/publicApi';

const BookingConfirmationPage: () => JSX.Element = () => {
  const { content } = useBookingsContent();

  const {
    data: eventConfig,
  } = useGetEventConfigQuery();

  if (!eventConfig || !content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <Card>
      <CardHeading>{content.confirmation.title}</CardHeading>
      <BlocksRenderer content={content.confirmation.body} />
    </Card>
  );
};

export default BookingConfirmationPage;

import keys from '../../helpers/typedKeys';
import { NewRegistration } from '../../state/registration/types';
import type { RegistrationRequest } from './index';

const mapToRequest = (data: NewRegistration): RegistrationRequest => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _persist, meta, mainContact, ...rawValues
  } = data;

  const isStaff = meta.type === 'staff';
  const attendee = {
    ...data.attendee,
    regionId: isStaff && meta.region ? meta.region.id : undefined,
    districtId: isStaff && meta.district ? meta.district.id : undefined,
    groupName: (isStaff && data.attendee.groupName) || '',
    staffTeam: (isStaff && data.attendee.staffTeam) || undefined,
  };

  const contacts = [...data.contacts];
  if (meta?.registeringSelf === false) {
    contacts.push(mainContact);
  }

  const activityPreferences = attendee.status === 'Cub'
    ? rawValues.activityPreferences : [];

  const activityLeadership = (attendee.status === 'Leader' || attendee.status === 'YoungLeader')
    && meta.type === 'pack'
    ? keys(rawValues.activityLeadership)
      .map((activityId) => ({
        id: Number(activityId),
        canLead: rawValues.activityLeadership[activityId].canLead,
      }))
    : [];

  return {
    ...rawValues,
    attendee,
    contacts,
    activityPreferences,
    activityLeadership,
  };
};

export default mapToRequest;

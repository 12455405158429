import { PersonStatus } from '../models/personStatus';
import { ReviewStatus } from '../models/reviewStatus';
import colours from '../theme/colours';

type StatusColour = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

export const getColourForAttendeeStatus = (status: PersonStatus): string => {
  switch (status) {
    case 'Cub':
      return colours.green;
    case 'YoungLeader':
      return colours.teal;
    case 'Leader':
      return colours.blue;
    default:
      return colours.darkBlue;
  }
};

export const getStatusColourForPersonStatus = (status: PersonStatus): StatusColour => {
  switch (status) {
    case 'Cub':
      return 'success';
    case 'OtherChild':
      return 'default';
    case 'YoungLeader':
      return 'secondary';
    case 'Leader':
      return 'primary';
    case 'Booker':
      return 'info';
    case 'Contact':
      return 'error';
    default:
      return 'default';
  }
};

export const getStatusColourForReviewStatus = (status: ReviewStatus): StatusColour => {
  switch (status) {
    case 'NotRequired':
      return 'default';
    case 'Pending':
      return 'primary';
    case 'SeekingInformation':
      return 'info';
    case 'Flagged':
      return 'error';
    case 'Complete':
      return 'default';
    default:
      return 'default';
  }
};

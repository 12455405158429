import { objectToCamel } from 'ts-case-convert';
import { ObjectToSnake, objectToSnake } from 'ts-case-convert/lib/caseConvert';
import {
  ActivityLeadership,
  ActivityLeadershipWithPersonSummary,
  ActivityLeadershipWithPersonSummaryRequest,
} from '../../models/activityLeadership';
import { protectedApi } from './index';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getPossibleLeadersForActivity: builder.query<
    ActivityLeadershipWithPersonSummary[],
    ActivityLeadershipWithPersonSummaryRequest
    >({
      query: (args) => {
        const { activityId, editionId } = args;
        const urlParams = editionId ? `edition_id=${editionId}` : '';
        return `activities/leadership/activity/${activityId}?${urlParams}`;
      },
      transformResponse: (
        response: ObjectToSnake<ActivityLeadershipWithPersonSummary[]>,
      ) => objectToCamel(response),
    }),
    getActivityLeadershipsForPerson: builder.query<ActivityLeadership[], number>({
      query: (personId) => `activities/leadership/person/${personId}`,
      transformResponse: (response: ObjectToSnake<ActivityLeadership[]>) => objectToCamel(response),
    }),
    updateActivityLeadershipsForPerson: builder.mutation<ActivityLeadership[], {
      personId: number, data: ActivityLeadership[]
    }>({
      query: (args) => {
        const { personId, data } = args;
        const body = objectToSnake(data);
        return ({
          url: `activities/leadership/person/${personId}`,
          method: 'PATCH',
          body,
        });
      },
    }),
    deleteActivityLeadershipsForPerson: builder.mutation<void, number>({
      query: (personId) => ({
        url: `activities/leadership/person/${personId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPossibleLeadersForActivityQuery,
  useGetActivityLeadershipsForPersonQuery,
  useUpdateActivityLeadershipsForPersonMutation,
  useDeleteActivityLeadershipsForPersonMutation,
} = extendedApi;

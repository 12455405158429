import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

export interface Error {
  message?: string
}

const initialState: Error = {
  message: undefined,
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    reset: () => initialState,
    update: (state, action: PayloadAction<Partial<Error>>) => ({ ...state, ...action.payload }),
  },
});

export const { reset, update } = errorSlice.actions;

export const selectError = (state: RootState): Error => state.error;

export default errorSlice.reducer;

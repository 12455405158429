import { CreateDoctor } from '../../../models/doctor';

const sanitiseOnSubmit = <TDoctor extends CreateDoctor>(rawValues: TDoctor): TDoctor => ({
  ...rawValues,
  surgeryName: rawValues.surgeryName.trim(),
  name: rawValues.name?.trim(),
  phone: rawValues.phone.trim(),
  addressStreet: rawValues.addressStreet.trim(),
  addressTown: rawValues.addressTown.trim(),
});

export default sanitiseOnSubmit;

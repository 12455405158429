import DeleteIcon from '@mui/icons-material/Delete';
import {
  Backdrop,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useState } from 'react';
import { DeleteMutation } from '../../helpers/actionType';
import { getRandomNumber } from '../../helpers/random';
import ErrorAlert from '../DataPanel/ErrorAlert';

export type DeleteDialogProps<TData extends object> = {
  title: string
  description?: string
  modelName: string
  data: TData
  onOpen?: () => void
  onSuccess?: () => void
  onDelete: DeleteMutation<TData>
};

const DeleteDialog = <TData extends object>(props: DeleteDialogProps<TData>): JSX.Element => {
  const {
    title,
    description,
    modelName,
    data,
    onOpen,
    onSuccess,
    onDelete,
  } = props;

  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteError, setDeleteError] = useState<SerializedError | FetchBaseQueryError>();

  const handleCancel = (): void => setOpen(false);

  const handleOpen = (): void => {
    setOpen(true);
    if (onOpen) {
      onOpen();
    }
  };

  const handleDeletion = async (): Promise<void> => {
    setIsSubmitting(true);
    setDeleteError(undefined);
    await onDelete(data).then((result) => {
      const isSuccessful = 'data' in result;
      if (isSuccessful) {
        setOpen(false);
        if (onSuccess) {
          onSuccess();
        }
      } else {
        setDeleteError(result.error);
      }
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  const dialogTitleAria = `${modelName}-deletion-alert-title-${getRandomNumber()}`;
  const dialogDescriptionAria = `${modelName}-deletion-alert-description-${getRandomNumber()}`;

  return (
    <>
      <IconButton aria-label="delete" onClick={handleOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby={dialogTitleAria}
        aria-describedby={description && dialogDescriptionAria}
      >
        <DialogTitle id={dialogTitleAria}>
          {title}
        </DialogTitle>
        <Collapse in={deleteError !== undefined}>
          {deleteError && (
            <ErrorAlert modelName={modelName} type="delete" error={deleteError} />
          )}
        </Collapse>
        {description && (
          <DialogContent>
            <DialogContentText id={dialogDescriptionAria}>
              {description}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            size="large"
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleDeletion}
          >
            Delete
          </Button>
          <Button
            size="large"
            variant="outlined"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default DeleteDialog;

import { Link as MuiLink } from '@mui/material';
import { HTMLAttributeAnchorTarget, ReactNode } from 'react';
import { Link as RouterDomLink } from 'react-router-dom';

type RouterLinkProps = {
  to: string
  children: ReactNode
  target?: HTMLAttributeAnchorTarget
};

const RouterLink = (props: RouterLinkProps): JSX.Element => {
  const { to, children, target } = props;

  if (to.startsWith('http')) {
    return (
      <MuiLink href={to} target={target ?? '_blank'}>
        {children}
      </MuiLink>
    );
  }

  return (
    <MuiLink to={to} component={RouterDomLink} target={target}>
      {children}
    </MuiLink>
  );
};

export default RouterLink;

import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import ContentLayout from '../../../components/ContentLayout';
import { FormGrid } from '../../../components/FormGrid';
import PageMetaTitle from '../../../components/PageMetaTitle';
import ReviewTypeSelection from '../../../components/ReviewTypeSelection';
import useErrorHandler from '../../../helpers/useErrorHandler';
import {
  useUrlOptionalNumberParam,
  useUrlOptionalStringParam,
  useUrlStringListParam,
  useUrlStringParam,
} from '../../../helpers/useUrlParam';
import { userHasRole } from '../../../helpers/user';
import {
  PackAttendeeStatus,
  getPersonStatusLabel,
  getPluralPersonStatusLabel,
  isValidPackAttendeeStatus,
  packAttendeeStatuses,
} from '../../../models/personStatus';
import {
  ReviewStatus, getReviewStatusLabel, isValidReviewStatus, reviewStatuses,
} from '../../../models/reviewStatus';
import { ReviewType } from '../../../models/reviewType';
import { StaffTeam } from '../../../models/staffTeam';
import { Subcamp } from '../../../models/subcamp';
import { useGetReviewsQuery } from '../../../state/protectedApi/reviews';
import { useGetCurrentUserQuery } from '../../../state/protectedApi/user';
import { useGetEditionsQuery } from '../../../state/publicApi/editions';
import PersonReview from './PersonReview';

// TODO: add the person in view to the URL so a specific person can be linked
const PersonReviewPage = (): JSX.Element => {
  const { data: user } = useGetCurrentUserQuery();
  const [index, setIndex] = useState(0);
  const [subcamp, setSubcamp] = useUrlOptionalStringParam<Subcamp | ''>('subcamp', user?.subcamp ?? undefined);
  const [selectedReviewType, setSelectedReviewType] = useUrlStringParam<ReviewType>('type', 'Admin');
  const [reviewStatus, setReviewStatus] = useUrlStringParam<ReviewStatus>('reviewStatus', 'Pending');
  const [personStatus, setPersonStatus] = useUrlStringParam<PackAttendeeStatus | 'All'>('personStatus', 'All');
  const [packId, setPackId] = useUrlOptionalNumberParam('pack', user?.packId ?? undefined);
  const [districtId, setDistrictId] = useUrlOptionalNumberParam('district');
  const [editionId, setEditionId] = useUrlOptionalNumberParam('edition');
  const [staffTeams, setStaffTeams] = useUrlStringListParam<StaffTeam>('teams', []);

  const reviewType = user?.role === 'SuperUser' || user?.role === 'Admin' ? selectedReviewType : user?.role ?? 'PackLeader';
  const {
    currentData: reviews,
    isFetching: reviewsIsFetching,
    refetch: refetchReviews,
    error: reviewsError,
  } = useGetReviewsQuery({
    type: reviewType,
    status: reviewStatus,
    subcamp: reviewType === 'Subcamp' ? subcamp : undefined,
    packId: reviewType === 'PackLeader' ? packId : undefined,
    districtId: reviewType === 'Compass' ? districtId : undefined,
    editionId: editionId ?? undefined,
    personStatus: personStatus === 'All' ? undefined : personStatus,
    staffTeams,
  }, { skip: user?.role === undefined });
  useErrorHandler(reviewsError, 'API Error: Failed to load review instructions');

  const { data: editions, isLoading: editionsLoading } = useGetEditionsQuery();

  useEffect(() => {
    setIndex(0);
  }, [reviews]);

  const isLast = reviews !== undefined && index + 1 === reviews.length;
  const isFirst = index === 0;

  const handelShowPrevReview = (): void => {
    if (index !== 0) {
      setIndex(index - 1);
    }
  };

  const handelShowNextReview = async (): Promise<void> => {
    if (isLast) {
      await refetchReviews();
    } else {
      setIndex(index + 1);
    }
  };

  return (
    <ContentLayout>
      <PageMetaTitle title="Reviews" />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <h1>Reviews</h1>
        <div>
          <Button
            onClick={refetchReviews}
            disabled={reviewsIsFetching}
            variant="outlined"
            startIcon={<RefreshIcon />}
          >
            Refresh Reviews
          </Button>
        </div>
      </Stack>
      <FormGrid>
        <FormControl>
          <FormLabel id="review-status-label">Review Status</FormLabel>
          <RadioGroup
            row
            aria-labelledby="review-status-label"
            value={reviewStatus}
            onChange={(event) => {
              if (isValidReviewStatus(event.target.value)) {
                setReviewStatus(event.target.value);
              }
            }}
          >
            {reviewStatuses.filter((option) => option !== 'NotRequired').map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={getReviewStatusLabel(option)}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel id="person-status-label">Person Status</FormLabel>
          <RadioGroup
            row
            aria-labelledby="person-status-label"
            value={personStatus}
            onChange={(event) => {
              if (isValidPackAttendeeStatus(event.target.value)) {
                setPersonStatus(event.target.value);
              } else {
                setPersonStatus('All');
              }
            }}
          >
            <FormControlLabel
              value="All"
              control={<Radio />}
              label="Any"
            />
            {packAttendeeStatuses.map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={getPersonStatusLabel(option)}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {userHasRole(user, ['Admin', 'Activities', 'Subcamp', 'Catering']) && (
          <Autocomplete
            sx={{ width: 300 }}
            options={editions?.map((edition) => edition.id) ?? []}
            loading={editionsLoading}
            getOptionLabel={(id) => editions?.find((edition) => edition.id === id)?.name ?? ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Edition"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {editionsLoading ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            value={editionId ?? null}
            onChange={(event, value) => {
              setEditionId(value ?? undefined);
            }}
          />
        )}
      </FormGrid>
      {userHasRole(user, 'Admin') && (
        <ReviewTypeSelection
          reviewType={selectedReviewType}
          reviewTypeOnChange={setSelectedReviewType}
          districtId={districtId}
          districtIdOnChange={setDistrictId}
          staffTeams={staffTeams}
          staffTeamsOnChange={setStaffTeams}
          subcamp={subcamp}
          subcampOnChange={setSubcamp}
          packId={packId}
          packIdOnChange={setPackId}
        />
      )}
      <hr />
      {reviewsIsFetching && (
        <p>Reviews are being fetched</p>
      )}
      {!reviewsIsFetching && reviews && reviews.length === 0 && (
        <p>
          {personStatus && (personStatus === 'All'
            ? `No reviews with '${getReviewStatusLabel(reviewStatus)}' status`
            : `No reviews for ${getPluralPersonStatusLabel(personStatus)} with '${getReviewStatusLabel(reviewStatus)}' status`)}
        </p>
      )}
      {!reviewsIsFetching && reviews && reviews.length > 0 && index < reviews.length && (
        <>
          <PersonReview
            reviewType={reviewType}
            personId={reviews[index].personId}
            onSave={handelShowNextReview}
            onSkip={isLast ? undefined : handelShowNextReview}
            onPrev={isFirst ? undefined : handelShowPrevReview}
          />
          <p>{`Review ${index + 1} of ${reviews.length}`}</p>
        </>
      )}
    </ContentLayout>
  );
};

export default PersonReviewPage;

import DeleteIcon from '@mui/icons-material/Delete';
import {
  Backdrop,
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { getFullName } from '../../../../helpers/person';
import { Person } from '../../../../models/person';
import { useDeletePersonMutation } from '../../../../state/protectedApi/people';

type DeletePersonDialogProps = {
  person: Person
  open: boolean
  onSuccessfulDeletion: () => void
  onCancel: () => void
};

const DeletePersonDialog = (props: DeletePersonDialogProps): JSX.Element => {
  const {
    person, open, onSuccessfulDeletion, onCancel,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [deletePerson] = useDeletePersonMutation();

  const handleDeletion = async (): Promise<void> => {
    setIsSubmitting(true);
    await deletePerson(person.id).then((response) => {
      const deletionErrored = 'error' in response;

      enqueueSnackbar(
        `Deletion of ${getFullName(person)} ${deletionErrored ? 'failed' : 'succeeded'}`,
        {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          variant: deletionErrored ? 'error' : 'success',
        },
      );

      if (!deletionErrored) {
        onSuccessfulDeletion();
      }
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  const getId = (suffix: string): string => `person-deletion-alert-${suffix}`;

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby={getId('title')}
    >
      <DialogTitle id={getId('title')}>
        {`Delete ${getFullName(person)}`}
      </DialogTitle>
      <DialogContent>
        <p>
          {`Are you sure you want to delete ${person.firstName}?`}
        </p>
        <p>
          Taking this action will delete all their personal details, associated emergency
          contacts, activity preferences and email tracking history.
        </p>
        <p>
          {/* TODO allow admins to revert the soft deletion */}
          If you delete someone mistakenly please tell Charlie as soon as possible.
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={handleDeletion}
          disabled={isSubmitting}
        >
          Delete
        </Button>
        <Button
          size="large"
          variant="outlined"
          onClick={onCancel}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
      </DialogActions>
      {isSubmitting && (
        <Backdrop open>
          <CircularProgress />
        </Backdrop>
      )}
    </Dialog>
  );
};

export default DeletePersonDialog;

import * as Yup from 'yup';
import personRules from '../../../helpers/validation/person';

export const validationSchema = Yup.object().shape({
  meta: Yup.object().shape({
    message: personRules.message,
    termsAccepted: Yup.boolean().test(
      'mustAcceptTerms',
      'You must accept the terms to register',
      (value) => value === true,
    ),
  }),
});

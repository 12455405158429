import { objectToCamel } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { RegistrationCodeCheckResult } from '../../models/registrationCode';
import { publicApi } from './index';

const extendedApi = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    checkRegistrationCode: builder.query<RegistrationCodeCheckResult, string>({
      query: (code: string) => `new-registrations/${code}/code-check`,
      transformResponse: (
        response: ObjectToSnake<RegistrationCodeCheckResult>,
      ) => objectToCamel(response),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCheckRegistrationCodeQuery,
} = extendedApi;

import { TableBody } from '@mui/material';
import { eventPhaseOrLater } from '../../helpers/eventConfig';
import { getOnsiteStatusLabel } from '../../models/onsiteStatus';
import { Person } from '../../models/person';
import { useGetEventConfigQuery } from '../../state/publicApi';
import EmailLink from '../EmailLink';
import PhoneLink from '../PhoneLink';
import Table from '../Table';
import TableKeyValueRow from '../TableKeyValueRow';

type PersonDetailsTableProps = {
  person: Person
  reviewMode?: boolean
};

const PersonDetailsTable = (props: PersonDetailsTableProps): JSX.Element => {
  const { person, reviewMode = false } = props;

  const { data: eventConfig } = useGetEventConfigQuery();

  return (
    <Table size="small">
      <TableBody>
        {!reviewMode && (
          <TableKeyValueRow name="ID">{person.id}</TableKeyValueRow>
        )}
        {eventConfig && eventPhaseOrLater(eventConfig, 'Allocation') && (
          <TableKeyValueRow name="Onsite">
            {getOnsiteStatusLabel(person.onsite)}
          </TableKeyValueRow>
        )}
        {person.scoutingAppointment && (
          <TableKeyValueRow name="Scouting appointment">{person.scoutingAppointment}</TableKeyValueRow>
        )}
        {person.membershipNumber && (
          <TableKeyValueRow name="Membership number">{person.membershipNumber}</TableKeyValueRow>
        )}
        {person.mobile && (
          <TableKeyValueRow name="Mobile">
            <PhoneLink phoneNumber={person.mobile} />
          </TableKeyValueRow>
        )}
        {person.email && (
          <TableKeyValueRow name="Email">
            <EmailLink email={person.email} />
          </TableKeyValueRow>
        )}
        {!reviewMode && [
          person.addressStreet,
          person.addressTown,
          person.addressPostcode,
        ].some((value) => Boolean(value))
            && (
              <TableKeyValueRow name="Address">
                {person.addressStreet}
                <br />
                {person.addressTown}
                <br />
                {person.addressPostcode}
              </TableKeyValueRow>
            )}
      </TableBody>
    </Table>
  );
};

export default PersonDetailsTable;

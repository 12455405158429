import { coerceToDate } from './dates';

const local = 'en-GB';

const isSameDay = (value1: Date, value2: Date): boolean => value1.getDate() === value2.getDate()
  && value1.getMonth() === value2.getMonth()
  && value1.getFullYear() === value2.getFullYear();

const isToday = (value: Date): boolean => {
  const today = new Date();
  return isSameDay(value, today);
};

const isYesterday = (value: Date): boolean => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return isSameDay(value, yesterday);
};

const isTomorrow = (value: Date): boolean => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return isSameDay(value, tomorrow);
};

export const getShortTime = (value: string | Date): string => {
  const dateValue = coerceToDate(value);
  return dateValue.toLocaleString(local, { timeStyle: 'short' });
};

export const getShortDate = (value: string | Date): string => {
  const dateValue = coerceToDate(value);
  return dateValue.toLocaleString(local, { dateStyle: 'short' });
};

export const getMediumDate = (value: string | Date): string => {
  const dateValue = coerceToDate(value);
  return dateValue.toLocaleString(local, { day: 'numeric', month: 'short' });
};

export const getRelativeDayOrDate = (value: string | Date): string => {
  const dateValue = coerceToDate(value);
  if (isTomorrow(dateValue)) {
    return 'Tomorrow';
  }
  if (isToday(dateValue)) {
    return 'Today';
  }
  if (isYesterday(dateValue)) {
    return 'Yesterday';
  }
  return getMediumDate(dateValue);
};

export const getRelativeDayOrDayName = (value: string | Date): string => {
  const dateValue = coerceToDate(value);
  if (isTomorrow(dateValue)) {
    return 'Tomorrow';
  }
  if (isToday(dateValue)) {
    return 'Today';
  }
  if (isYesterday(dateValue)) {
    return 'Yesterday';
  }
  return dateValue.toLocaleString(local, { weekday: 'long' });
};

export const getRelativeDayOrDateWithTime = (value: string | Date): string => {
  const time = getShortTime(value);
  const date = getRelativeDayOrDate(value);

  if (time === 'Invalid Date') {
    return 'Invalid Date';
  }

  return `${date}, ${time}`;
};

export const getLongDate = (value: string | Date): string => {
  const dateValue = coerceToDate(value);
  return dateValue.toLocaleDateString(local, { dateStyle: 'long' });
};

export const getLongDateWithoutYear = (value: string | Date): string => {
  const dateValue = coerceToDate(value);
  return dateValue.toLocaleDateString(local, {
    month: 'long',
    day: 'numeric',
  });
};

export const getYear = (value: string | Date): string => {
  const dateValue = coerceToDate(value);
  return dateValue.toLocaleDateString(local, { year: 'numeric' });
};

export const getShortDateTime = (value: string | Date): string => {
  const dateValue = coerceToDate(value);
  const shortDate = dateValue.toLocaleString(local, {
    day: 'numeric',
    month: 'short',
  });
  const time = getShortTime(dateValue);
  return `${shortDate}, ${time}`;
};

export const getLongDateTime = (value: string | Date): string => {
  const dateValue = coerceToDate(value);
  return dateValue.toLocaleString(local, {
    dateStyle: 'long',
    timeStyle: 'short',
  });
};

export const getLongDateTimeWithoutYear = (value: string | Date): string => {
  const dateValue = coerceToDate(value);
  return dateValue.toLocaleString(local, {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const formatTimeRange = (start: string | Date, end: string | Date): string => {
  const startValue = coerceToDate(start);
  const endValue = coerceToDate(end);

  const startFormat = new Intl.DateTimeFormat(local, { hour: 'numeric', minute: 'numeric' });
  const endFormat = new Intl.DateTimeFormat(local, { hour: 'numeric', minute: 'numeric' });

  const startTime = startFormat.format(startValue);
  const endTime = endFormat.format(endValue);

  const startDay = startValue.toLocaleDateString(local, { weekday: 'long', day: 'numeric' });
  const endDay = endValue.toLocaleDateString(local, { weekday: 'long' });

  if (isSameDay(startValue, endValue)) {
    return `${startDay}, ${startTime} - ${endTime}`;
  }

  return `${startDay}, ${startTime} - ${endDay}, ${endTime}`;
};

import {
  Backdrop,
  CircularProgress,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
} from '@mui/material';
import Image from 'mui-image';
import ActivityDialog from '../../components/ActivityDialog';
import BlocksRenderer from '../../components/BlocksRenderer';
import ContentLayout from '../../components/ContentLayout';
import PageMetaTitle from '../../components/PageMetaTitle';
import { addCmsBaseUrl } from '../../helpers/cmsImage';
import { useActivitiesContent, useActivitiesPageContent } from '../../helpers/content';
import useErrorHandler from '../../helpers/useErrorHandler';
import { Activity } from '../../models/activity';
import { ActivityContent } from '../../models/content/activityContent';
import { useGetActivitiesQuery } from '../../state/publicApi/activities';
import { useTheme } from '../../theme';

type ActivityWithContent = Activity & ActivityContent;

const ActivitiesPage = (): JSX.Element => {
  const { content: pageContent, error: pageContentError } = useActivitiesPageContent();
  useErrorHandler(pageContentError, 'CMS Error: Failed to load page content');

  const { data: activitiesData, error: activitiesDataError } = useGetActivitiesQuery();
  useErrorHandler(activitiesDataError, 'API Error: Failed to load activities');

  const { content: activitiesContent, error: activitiesContentError } = useActivitiesContent();
  useErrorHandler(activitiesContentError, 'CMS Error: Failed to load activities content');

  const theme = useTheme();
  const screenWiderThanMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const screenWiderThanTablet = useMediaQuery(theme.breakpoints.up('md'));

  const getColumns = (): number => {
    if (screenWiderThanTablet) {
      return 3;
    }
    if (screenWiderThanMobile) {
      return 2;
    }
    return 1;
  };

  if (!pageContent || !activitiesData || !activitiesContent) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  const activities: ActivityWithContent[] = activitiesData.reduce((acc, activity) => {
    const content = activitiesContent?.find((c) => c.id === activity.id);
    if (content && content.square_image.data) {
      acc.push({ ...activity, ...content });
    }
    return acc;
  }, [] as ActivityWithContent[]).sort(
    (a, b) => a.name.localeCompare(b.name),
  );

  return (
    <ContentLayout>
      <PageMetaTitle title="Activities" />
      <h1>{pageContent.title}</h1>
      <ContentLayout desktop={8} tablet={6}>
        <BlocksRenderer content={pageContent.body} />
      </ContentLayout>
      <ImageList cols={getColumns()}>
        {activities?.map((item) => (
          <ImageListItem key={item.name}>
            {item.square_image.data && (
              <Image
                alt={item.name}
                src={addCmsBaseUrl(item.square_image.data?.attributes.url)}
                duration={1000}
              />
            )}
            <ImageListItemBar
              title={item.name}
              subtitle={item.category}
              actionIcon={(
                <ActivityDialog
                  id={item.id}
                  name={item.name}
                  hideImageOnMobile
                />
              )}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </ContentLayout>
  );
};

export default ActivitiesPage;

import {
  Skeleton, Table, TableBody, TableCell, TableRow,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { FC } from 'react';
import DataPanel, { DataPanelDisplayProps } from '../../../../components/DataPanel';
import { userHasRole } from '../../../../helpers/user';
import { Person } from '../../../../models/person';
import { Staff } from '../../../../models/staff';
import { User, UserFormValues } from '../../../../models/user';
import { UserRole, getUserRoleLabel } from '../../../../models/userRole';
import { useGetPackQuery } from '../../../../state/protectedApi/packs';
import { useGetStaffQuery } from '../../../../state/protectedApi/staff';
import {
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
} from '../../../../state/protectedApi/user';
import UserForm from './UserForm';
import sanitiseOnSubmit from './UserForm/sanitise';
import { validationSchema } from './UserForm/schema';

type UserPanelProps = {
  person: Person
  currentUser: User | undefined
};

const DisplayUser: FC<DataPanelDisplayProps<User>> = (props) => {
  const {
    data: user,
  } = props;

  const { data: pack } = useGetPackQuery(user.packId ?? skipToken);

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>Firebase ID</TableCell>
          <TableCell>{user.firebaseId ?? 'Sign-up not yet completed'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>User role</TableCell>
          <TableCell>{user.role && getUserRoleLabel(user.role)}</TableCell>
        </TableRow>
        {user.subcamp && (
          <TableRow>
            <TableCell>Subcamp</TableCell>
            <TableCell>{user.subcamp}</TableCell>
          </TableRow>
        )}
        {user.packId && pack && (
          <TableRow>
            <TableCell>Pack</TableCell>
            <TableCell>{pack.name}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const getInitialRole = (staff?: Staff): UserRole => {
  switch (staff?.staffTeam) {
    case 'Core':
      return 'Admin';
    case 'Activities':
      return 'Activities';
    case 'Catering':
      return 'Catering';
    case 'Subcamp':
      return 'Subcamp';
    default:
      return 'PackLeader';
  }
};

// TODO: prevent user creation for non-adults
const UserPanel: FC<UserPanelProps> = (props) => {
  const { person, currentUser } = props;

  const canMutate = currentUser && person.id !== currentUser.personId && userHasRole(currentUser, 'Admin');

  const { data: staff } = useGetStaffQuery(person.id);
  const { data: pack } = useGetPackQuery(person.packId ?? skipToken);

  const fetch = useGetUserQuery(person.id);
  const [createUser] = useCreateUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();

  const initialUser: UserFormValues = {
    personId: person.id,
    role: getInitialRole(staff),
    subcamp: pack?.subcamp ?? '',
  };

  return (
    <DataPanel
      title="User"
      modelName="user"
      fetch={fetch}
      formikConfig={{ validationSchema }}
      sanitiseOnSubmit={sanitiseOnSubmit}
      canCreate={canMutate}
      create={{
        mutation: (create) => createUser({ ...create, personId: person.id }),
        initialValues: initialUser,
      }}
      update={{
        mutation: (update) => updateUser({ ...update, personId: person.id }),
      }}
      canUpdate={canMutate}
      deletion={{
        mutation: () => deleteUser(person.id),
        dialogTitle: 'Delete user',
        dialogDescription: 'Are you sure you want to delete this user?',
      }}
      canDelete={canMutate}
      LoadingComponent={<Skeleton variant="rectangular" height="100px" />}
      DisplayComponent={DisplayUser}
      FormComponent={UserForm}
    />
  );
};

export default UserPanel;

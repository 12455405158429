import { FC } from 'react';
import DataGrid from '../../../../components/DataGrid';
import DataPanelWrapper from '../../../../components/DataPanel/DataPanelWrapper';
import { Activity } from '../../../../models/activity';
import { ActivityLeadershipWithPersonSummary } from '../../../../models/activityLeadership';
import { useGetPossibleLeadersForActivityQuery } from '../../../../state/protectedApi/activityLeadership';
import columns from './columns';

type PossibleLeadersListProps = {
  activity: Activity;
};

const PossibleLeadersList: FC<PossibleLeadersListProps> = (props) => {
  const { activity } = props;
  const {
    data: possibleLeaders,
    isFetching,
    refetch,
  } = useGetPossibleLeadersForActivityQuery({ activityId: activity.id });

  if (!possibleLeaders || possibleLeaders.length === 0) {
    return null;
  }
  return (
    <DataPanelWrapper>
      <h2>Possible Leads</h2>
      <DataGrid
        columns={columns}
        rows={possibleLeaders}
        loading={isFetching}
        getRowId={(leadership: ActivityLeadershipWithPersonSummary) => leadership.id}
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        slotProps={{
          toolbar: {
            refetch,
          },
        }}
        pageSizeOptions={[5, 10, 25, 50]}
        autoPageSize={false}
      />
    </DataPanelWrapper>
  );
};

export default PossibleLeadersList;

import {
  useEffect, useLayoutEffect, useRef,
} from 'react';

const useWindowEventListener = <Key extends keyof WindowEventMap>(
  eventName: Key,
  handler: (event: WindowEventMap[Key]) => void,
  options?: boolean | AddEventListenerOptions,
): void => {
  const savedHandler = useRef(handler);

  useLayoutEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const listener: typeof handler = (event) => savedHandler.current(event);
    window.addEventListener(eventName, listener, options);

    return () => {
      window.removeEventListener(eventName, listener, options);
    };
  }, [eventName, options]);
};

export default useWindowEventListener;

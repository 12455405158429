import { GridColDef } from '@mui/x-data-grid-pro';
import RouterLink from '../../../../components/RouterLink';
import { getIdFromReference } from '../../../../helpers/packReference';
import { getFullName } from '../../../../helpers/person';
import { ActivityLeadershipWithPersonSummary } from '../../../../models/activityLeadership';
import { getPersonStatusLabel } from '../../../../models/personStatus';
import routes from '../../../../routes';

const columns : GridColDef<ActivityLeadershipWithPersonSummary>[] = [
  {
    field: 'packReference',
    headerName: 'Reference',
    width: 125,
    sortComparator: (v1: string, v2: string) => getIdFromReference(v1) - getIdFromReference(v2),
  },
  {
    field: 'personId',
    headerName: 'Person ID',
    width: 100,
    type: 'number' as const,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    valueGetter: (value: never, row: ActivityLeadershipWithPersonSummary) => getFullName(row),
    renderCell: (params) => (
      <RouterLink to={routes.people.detail(params.row.personId)}>
        {params.value}
      </RouterLink>
    ),
  },
  { field: 'scoutingAppointment', headerName: 'Scouting role', width: 150 },
  {
    field: 'personStatus',
    headerName: 'Status',
    width: 150,
    type: 'singleSelect',
    valueOptions: [getPersonStatusLabel('Leader'), getPersonStatusLabel('YoungLeader')],
    valueGetter: (
      value,
      row: ActivityLeadershipWithPersonSummary,
    ) => getPersonStatusLabel(row.personStatus),
  },
  { field: 'email', headerName: 'Email', width: 250 },
  { field: 'mobile', headerName: 'Mobile', width: 150 },
];

export default columns;

import { OptionList } from '../helpers/options';
import { toTitleCase } from '../helpers/strings';

const emailSendStatuses = [
  'Failed',
  'Sent',
  'Rejected',
  'HardBounce',
  'Delayed',
  'Delivered',
  'Opened',
  'Complaint',
  'Unknown',
] as const;

export type EmailSendStatus = typeof emailSendStatuses[number];

export const emailSendStatusOptions: OptionList<EmailSendStatus> = emailSendStatuses.map(
  (status) => ({
    label: toTitleCase(status),
    value: status,
  }),
);

import DataGrid from '../../../components/DataGrid';
import ToolbarEditionFilter from '../../../components/DataGridToolbar/ToolbarEditionFilter';
import ExpandingSpace from '../../../components/ExpandingSpace';
import PageMetaTitle from '../../../components/PageMetaTitle';
import { useDefaultEdition } from '../../../helpers/useDefaultEdition';
import useErrorHandler from '../../../helpers/useErrorHandler';
import routes from '../../../routes';
import { useGetMealsQuery } from '../../../state/publicApi/meals';
import { columns } from './columns';

const MealsGridPage = (): JSX.Element => {
  const [edition, setEditionId] = useDefaultEdition();

  const {
    data: meals,
    error: mealsError,
    isFetching,
    refetch,
  } = useGetMealsQuery();
  useErrorHandler(mealsError, 'API Error: Failed to load meals');

  const filteredMeals = meals?.filter((meal) => meal.editionId === edition?.id) ?? [];

  return (
    <>
      <PageMetaTitle title="Meals" />
      <DataGrid
        columns={columns}
        rows={filteredMeals}
        loading={isFetching}
        slotProps={{
          toolbar: {
            refetch,
            createRoute: routes.meals.adminDetail('new'),
            children: (
              <>
                <ExpandingSpace />
                <ToolbarEditionFilter
                  allowAll
                  editionId={edition?.id}
                  onEditionChange={setEditionId}
                />
              </>
            ),
          },
        }}
      />
    </>
  );
};

export default MealsGridPage;

import { TableBody } from '@mui/material';
import { FC } from 'react';
import DataPanel, { DataPanelDisplayProps } from '../../../../components/DataPanel';
import LoadingText from '../../../../components/LoadingText';
import Table from '../../../../components/Table';
import TableKeyValueRow from '../../../../components/TableKeyValueRow';
import { formatBooleanResponse } from '../../../../helpers/boolean';
import checkPersonPermissions from '../../../../helpers/dataAction';
import { getLongDate } from '../../../../helpers/datesStrings';
import { getFullNamePossessive } from '../../../../helpers/person';
import { getGenderLabel } from '../../../../models/gender';
import { Person } from '../../../../models/person';
import { Registration } from '../../../../models/registration';
import { User } from '../../../../models/user';
import {
  useCreateRegistrationMutation,
  useDeleteRegistrationMutation,
  useGetRegistrationQuery,
  useUpdateRegistrationMutation,
} from '../../../../state/protectedApi/registration';
import { useGetCurrentUserQuery } from '../../../../state/protectedApi/user';
import RegistrationForm from './RegistrationForm';
import sanitiseOnSubmit from './RegistrationForm/sanitise';
import { validationSchema } from './RegistrationForm/schema';
import ReviewRow from './ReviewRow';

type RegistrationPanelProps = {
  person: Person
  user: User | undefined
};

const DisplayRegistration: FC<DataPanelDisplayProps<Registration>> = (props) => {
  const {
    data: {
      gender,
      dateOfBirth,
      mediaConsent,
      antihistamineConsent,
      painkillerConsent,
      suncreamConsent,
      afterSunConsent,
      additionalNeeds,
      allergies,
      dietaryRequirements,
      medications,
      sandwichPreference,
      cateringMessage,
      activitiesMessage,
      message,
      internalNotes,
      adminReview,
      compassReview,
      activitiesReview,
      cateringReview,
      subcampReview,
      packLeaderReview,
    },
  } = props;

  const { data: user } = useGetCurrentUserQuery();
  const hasPackLeaderRole = user?.role === 'PackLeader';

  return (
    <Table size="small" sx={{ marginBottom: '30px' }}>
      <TableBody>
        <TableKeyValueRow name="Gender">{getGenderLabel(gender)}</TableKeyValueRow>
        <TableKeyValueRow name="Date of birth">
          {getLongDate(dateOfBirth ?? 'Not Set')}
        </TableKeyValueRow>
        <TableKeyValueRow name="Media consent">
          {formatBooleanResponse(mediaConsent)}
        </TableKeyValueRow>
        <TableKeyValueRow name="Antihistamine consent">
          {formatBooleanResponse(antihistamineConsent)}
        </TableKeyValueRow>
        <TableKeyValueRow name="Paracetamol consent">
          {formatBooleanResponse(painkillerConsent)}
        </TableKeyValueRow>
        <TableKeyValueRow name="Suncream consent">
          {formatBooleanResponse(suncreamConsent)}
        </TableKeyValueRow>
        <TableKeyValueRow name="After sun consent">
          {formatBooleanResponse(afterSunConsent)}
        </TableKeyValueRow>
        <TableKeyValueRow name="Additional needs">
          {additionalNeeds ?? 'None'}
        </TableKeyValueRow>
        <TableKeyValueRow name="Medications">
          {medications ?? 'None'}
        </TableKeyValueRow>
        <TableKeyValueRow name="Allergies">
          {allergies ?? 'None'}
        </TableKeyValueRow>
        <TableKeyValueRow name="Dietary requirements">
          {dietaryRequirements ?? 'None'}
        </TableKeyValueRow>
        <TableKeyValueRow name="Sandwich preference">
          {sandwichPreference ?? 'Not set'}
        </TableKeyValueRow>
        <TableKeyValueRow name="Catering message">
          {cateringMessage ?? 'None'}
        </TableKeyValueRow>
        {activitiesMessage && (
          <TableKeyValueRow name="Leader activities message">
            {activitiesMessage}
          </TableKeyValueRow>
        )}
        {message && (
          <TableKeyValueRow name="Message">
            {message}
          </TableKeyValueRow>
        )}
        {internalNotes && (
          <TableKeyValueRow name="Internal Notes">
            {internalNotes}
          </TableKeyValueRow>
        )}
        {adminReview && user && !hasPackLeaderRole && (
          <ReviewRow title="Core team review" review={adminReview} />
        )}
        {compassReview && user && !hasPackLeaderRole && (
          <ReviewRow title="Compass review" review={compassReview} />
        )}
        {cateringReview && user && !hasPackLeaderRole && (
          <ReviewRow title="Catering review" review={cateringReview} />
        )}
        {activitiesReview && user && !hasPackLeaderRole && (
          <ReviewRow title="Activities review" review={activitiesReview} />
        )}
        {subcampReview && user && !hasPackLeaderRole && (
          <ReviewRow title="Subcamp review" review={subcampReview} />
        )}
        {packLeaderReview && (
          <ReviewRow title="Pack leader review" review={packLeaderReview} />
        )}
      </TableBody>
    </Table>
  );
};

const RegistrationPanel = (props: RegistrationPanelProps): JSX.Element => {
  const { person, user } = props;

  const canCreate = checkPersonPermissions('Create', person, user);
  const canUpdate = checkPersonPermissions('Update', person, user);
  const canDelete = checkPersonPermissions('Delete', person, user);

  const fetchRegistration = useGetRegistrationQuery(person.id);
  const [createRegistration] = useCreateRegistrationMutation();
  const [updateRegistration] = useUpdateRegistrationMutation();
  const [deleteRegistration] = useDeleteRegistrationMutation();

  return (
    <DataPanel
      title="Registration"
      modelName="registration"
      fetch={fetchRegistration}
      prepareInitialValues={(data: Registration) => ({
        ...data,
        additionalNeeds: data.additionalNeeds ?? '',
        allergies: data.allergies ?? '',
        dietaryRequirements: data.dietaryRequirements ?? '',
        medications: data.medications ?? '',
        sandwichPreference: data.sandwichPreference ?? '',
        activitiesMessage: data.activitiesMessage ?? '',
        cateringMessage: data.cateringMessage ?? '',
        message: data.message ?? '',
      })}
      formikConfig={{ validationSchema }}
      sanitiseOnSubmit={sanitiseOnSubmit}
      canCreate={canCreate}
      create={{
        mutation: createRegistration,
        initialValues: {
          personId: person.id,
          gender: null,
          dateOfBirth: '',
          mediaConsent: null,
          additionalNeeds: '',
          allergies: '',
          dietaryRequirements: '',
          medications: '',
          antihistamineConsent: null,
          painkillerConsent: null,
          suncreamConsent: null,
          afterSunConsent: null,
          sandwichPreference: '',
          activitiesMessage: '',
          cateringMessage: '',
          message: '',
          internalNotes: '',
          adminReview: {
            status: 'NotRequired',
            tags: [],
          },
          compassReview: {
            status: 'NotRequired',
            tags: [],
          },
          activitiesReview: {
            status: 'NotRequired',
            tags: [],
          },
          cateringReview: {
            status: 'NotRequired',
            tags: [],
          },
          subcampReview: {
            status: 'NotRequired',
            tags: [],
          },
          packLeaderReview: {
            status: 'NotRequired',
            tags: [],
          },
        },
      }}
      canUpdate={canUpdate}
      update={{
        mutation: (update) => updateRegistration({ ...update, personId: person.id }),
      }}
      canDelete={canDelete}
      deletion={{
        mutation: () => deleteRegistration(person.id),
        dialogTitle: `Delete ${getFullNamePossessive(person)} registration`,
        dialogDescription: `Are you sure you want to delete the registration for ${person.firstName}?`,
      }}
      LoadingComponent={<LoadingText lines={8} />}
      DisplayComponent={DisplayRegistration}
      FormComponent={RegistrationForm}
    />
  );
};

export default RegistrationPanel;

import {
  Alert,
  AlertTitle,
  Badge,
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@mui/material';
import { QrcodeSuccessCallback } from 'html5-qrcode';
import { useState } from 'react';
import arrivalsService, { Arrival } from '../../api/arrivals';
import Card from '../../components/Card';
import CardHeading from '../../components/CardHeading';
import CardIntroduction from '../../components/CardIntroduction';
import { FormGrid } from '../../components/FormGrid';
import ManualPersonIdForm from '../../components/ManualPersonIdForm';
import PageMetaTitle from '../../components/PageMetaTitle';
import QrScanner from '../../components/QrScanner';
import RouterLink from '../../components/RouterLink';
import { addNotification } from '../../helpers/notification';
import { asString } from '../../helpers/typeValidation';
import { useDefaultEdition } from '../../helpers/useDefaultEdition';
import { useLocalStorage } from '../../helpers/useLocalStorage';
import { useUrlOptionalStringParam } from '../../helpers/useUrlParam';
import { userHasRole } from '../../helpers/user';
import routes from '../../routes';
import { useGetCurrentUserQuery } from '../../state/protectedApi/user';
import { useGetEditionsQuery } from '../../state/publicApi/editions';

const Arrivals = (): JSX.Element => {
  const [mode, setMode] = useUrlOptionalStringParam<'scanner' | 'manual' | 'history'>('mode', 'scanner');
  const [bypass, setBypass] = useState(false);

  const [selectedEdition, setEditionById] = useDefaultEdition();

  const [isFetching, setIsFetching] = useState(false);
  const [history, setHistory] = useLocalStorage<Arrival[]>('arrivals', []);
  const { data: editions } = useGetEditionsQuery();
  const { data: currentUser } = useGetCurrentUserQuery();

  const onApiSuccess = (response: Arrival | undefined): void => {
    if (response === undefined) {
      addNotification('An unexpected error occurred', 'error');
      return;
    }
    addNotification(response.message, response.status);
    setHistory((prevHistory) => [
      response,
      ...prevHistory,
    ]);
    setBypass(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onApiError = (error: any): void => {
    // TODO: deal with general Axios errors better with a default handler
    const errorMessage = asString(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      error?.response?.data?.message ?? 'An unexpected error occurred',
    );
    addNotification(errorMessage, 'error');
  };

  const onFetchComplete = (): void => {
    setIsFetching(false);
  };

  const onScanSuccess: QrcodeSuccessCallback = (decodedText) => {
    const url = new URL(decodedText);
    const publicId = url.searchParams.get('person_public_id');

    if (isFetching) {
      return;
    }
    if (publicId === null) {
      addNotification('Invalid QR code - are you scanning an attendees lanyard?', 'error');
    } else if (selectedEdition === undefined) {
      addNotification('Scan failure as edition is not set', 'error');
    } else {
      setIsFetching(true);
      arrivalsService.notifyByPublicId(publicId, selectedEdition.id, bypass)
        .then(onApiSuccess)
        .catch(onApiError)
        .finally(onFetchComplete);
    }
  };

  const onFormSubmit = (payload: { personId: number }): void => {
    const { personId } = payload;
    if (selectedEdition === undefined) {
      addNotification('Check-in failure as edition is not set', 'error');
    } else {
      setIsFetching(true);
      arrivalsService.notifyByPersonId(personId, selectedEdition.id, bypass)
        .then(onApiSuccess)
        .catch(onApiError)
        .finally(onFetchComplete);
    }
  };

  // TODO track history of check-ins for the device (it can reset on page refresh) or maybe
  //  persisted in the state would be good.
  // TODO allow the user to undo a notification if they do so within 1 minute (scanning the
  //  wrong lanyard).
  return (
    <Card>
      <PageMetaTitle title="Arrivals" />
      <CardHeading>
        Arrivals
      </CardHeading>
      <CardIntroduction>
        Scan the QR code on the attendees lanyard to register their arrival on site.
      </CardIntroduction>
      <FormGrid columns={2}>
        {editions && editions.length > 1 && (
          <FormControl fullWidth sx={{ textAlign: 'left' }}>
            <InputLabel id="edition-filter-label">Edition</InputLabel>
            <Select
              labelId="edition-filter-label"
              id="edition-filter"
              value={selectedEdition}
              disabled={!editions || editions.length === 1}
              label="Edition"
              onChange={(event) => {
                if (typeof event.target.value === 'number') {
                  setEditionById(event.target.value);
                }
              }}
            >
              {editions.map((edition) => (
                <MenuItem key={edition.id} value={edition.id}>{edition.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <ButtonGroup
          variant="outlined"
          aria-label="Switch between scan and history views"
          fullWidth
        >
          <Button
            onClick={() => setMode('scanner')}
            variant={mode === 'scanner' ? 'contained' : 'outlined'}
          >
            Scanner
          </Button>
          <Button
            onClick={() => setMode('manual')}
            variant={mode === 'manual' ? 'contained' : 'outlined'}
          >
            Manual
          </Button>
          <Badge badgeContent={history.length} color="primary">
            <Button
              onClick={() => setMode('history')}
              disabled={history.length === 0}
              variant={mode === 'history' ? 'contained' : 'outlined'}
            >
              History
            </Button>
          </Badge>
        </ButtonGroup>
        {userHasRole(currentUser, 'Admin') && (
          <FormControlLabel
            control={<Switch />}
            checked={bypass}
            onChange={(event, checked) => setBypass(checked)}
            label="Force bypass review warning"
          />
        )}
      </FormGrid>
      {mode === 'scanner' && <QrScanner onSuccess={onScanSuccess} />}
      {mode === 'manual' && (
        <ManualPersonIdForm
          submitButtonText="Check In"
          onSubmit={onFormSubmit}
          disabled={isFetching}
          edition={selectedEdition?.id}
        />
      )}
      {mode === 'history' && (
        <div>
          <div>
            <Button
              onClick={() => {
                setMode('scanner');
                setHistory([]);
              }}
              disabled={history.length === 0}
            >
              Clear history
            </Button>
          </div>
            {history.map((arrival) => (
              <Alert
                severity={arrival.status}
                key={arrival.publicId}
                sx={{ marginTop: '1rem' }}
              >
                <AlertTitle>
                  {userHasRole(currentUser, 'Admin') ? (
                    <RouterLink to={routes.people.detail(arrival.personId)}>
                      {arrival.name}
                    </RouterLink>
                  ) : arrival.name}
                </AlertTitle>
                {arrival.message}
              </Alert>
            ))}
        </div>
      )}
    </Card>
  );
};

export default Arrivals;

import { Backdrop, CircularProgress } from '@mui/material';
import BlocksRenderer from '../../components/BlocksRenderer';
import ContentLayout from '../../components/ContentLayout';
import { useHomeContent } from '../../helpers/content';
import useErrorHandler from '../../helpers/useErrorHandler';

const HomePage = (): JSX.Element => {
  const { content, error } = useHomeContent();
  useErrorHandler(error, 'CMS Error: Failed to load content for home page');

  if (!content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <ContentLayout>
      <h1>{content.title}</h1>
      <ContentLayout desktop={8} tablet={6}>
        <BlocksRenderer content={content.body} />
      </ContentLayout>
    </ContentLayout>
  );
};

export default HomePage;

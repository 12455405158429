import { Button } from '@mui/material';
import { useUrlStringParam } from '../../helpers/useUrlParam';
import ManualPersonIdForm from '../ManualPersonIdForm';
import ModeSwitchBar from './ModeSwitchBar';
import PersonPublicIdScanner from './PersonPublicIdScanner';
import { CheckInProps } from './types';

const CheckIn = <TResponse extends object>(props: CheckInProps<TResponse>): JSX.Element => {
  const {
    trackingId,
    editionId,
    checkInPerson,
    isFetching,
    history,
    RenderHistoryItem,
    resetHistory,
    getResponseId,
  } = props;

  const [mode, setMode] = useUrlStringParam<'scanner' | 'manual' | 'history'>(`${trackingId}-mode`, 'scanner');

  return (
    <div>
      <ModeSwitchBar
        mode={mode}
        setMode={setMode}
        historyLength={history.length}
      />
      {mode === 'scanner' && <PersonPublicIdScanner onScan={checkInPerson} />}
      {mode === 'manual' && (
        <ManualPersonIdForm
          submitButtonText="Check In"
          onSubmit={checkInPerson}
          disabled={isFetching}
          edition={editionId}
        />
      )}
      {mode === 'history' && (
        <div>
          <div>
            <Button
              onClick={() => {
                setMode('scanner');
                resetHistory();
              }}
              disabled={history.length === 0}
            >
              Clear history
            </Button>
          </div>
          {history.map((item) => (
            <RenderHistoryItem key={getResponseId(item)} {...item} />
          ))}
        </div>
      )}
    </div>
  );
};

export default CheckIn;

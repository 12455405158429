import styled from 'styled-components';
import { responsiveSpacing } from '../../helpers/responsiveSpacing';

const CardIntroduction = styled.div`
  ${responsiveSpacing('margin-bottom', 's')}
  
  :last-of-type {
    ${responsiveSpacing('margin-bottom', 'm')}
  }
`;

export default CardIntroduction;

import { EventConfig } from '../models/eventConfig';
import { Phase } from '../models/phase';
import { getPhaseOrder } from './phases';

export const getEventPriceSummary = (eventConfig: EventConfig): string => `£${eventConfig.prices.cub} per Cub, £${eventConfig.prices.leader} per leader`;

export const eventPhaseOrEarlier = (eventConfig: EventConfig, phase: Phase): boolean => {
  const current = getPhaseOrder(eventConfig.phases.current);
  const test = getPhaseOrder(phase);
  return current <= test;
};

export const eventPhaseOrLater = (eventConfig: EventConfig, phase: Phase): boolean => {
  const current = getPhaseOrder(eventConfig.phases.current);
  const test = getPhaseOrder(phase);
  return test <= current;
};

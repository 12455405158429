import {
  getLongDate, getLongDateWithoutYear, getShortTime, getYear,
} from '../datesStrings';

const formatString = (
  value: string,
  format: string | undefined,
): string => {
  if (!format) {
    return value;
  }
  if (format === 'time') {
    return getShortTime(value);
  }
  if (format === 'date_without_year') {
    return getLongDateWithoutYear(value);
  }
  if (format === 'date_with_year') {
    return getLongDate(value);
  }
  if (format === 'year') {
    return getYear(value);
  }

  throw new Error(`Unexpected format type '${format}'`);
};

export default formatString;

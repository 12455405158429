import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import RouterLink from '../../../components/RouterLink';
import { numberCellClassComparison } from '../../../helpers/dataGrid';
import { getRelativeDayOrDayName, getShortTime } from '../../../helpers/datesStrings';
import { Duty } from '../../../models/duty';
import { hasFinished, isHappeningNow } from '../../../models/timedEvent';
import routes from '../../../routes';

const alignCenter = {
  align: 'center',
  headerAlign: 'center',
} as const;

const getAssignedAdultLeaders = (duty: Duty): number => duty.allocationSummary.adultLeaders.total;
const getAssignedLeaders = (duty: Duty): number => duty.allocationSummary.leaders.total;

export const columns: GridColDef<Duty>[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 100,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    renderCell: (params: GridRenderCellParams<Duty, string>) => (
      <RouterLink to={routes.duties.detail(params.row.id)}>{params.value}</RouterLink>
    ),
  },
  {
    field: 'targetAdultLeaders',
    headerName: 'Target Adults',
    width: 100,
    type: 'number',
    ...alignCenter,
  },
  {
    field: 'assignedAdultLeaders',
    headerName: 'Assigned Adults',
    width: 100,
    type: 'number',
    valueGetter: (value, row: Duty) => getAssignedAdultLeaders(row),
    cellClassName: numberCellClassComparison('targetAdultLeaders', {
      lessThan: 'warning-cell',
      greaterThan: 'success-cell',
    }, ({ row }) => !hasFinished(row)),
    ...alignCenter,
  },
  {
    field: 'checkedInAdultLeaders',
    headerName: 'Checked In Adults',
    width: 100,
    type: 'number',
    valueGetter: (value, row: Duty) => row.allocationSummary.adultLeaders.checkedIn,
    cellClassName: numberCellClassComparison(
      ({ row }) => getAssignedAdultLeaders(row),
      {
        lessThan: 'warning-cell',
        equal: 'success-cell',
      },
      ({ row }) => isHappeningNow(row),
    ),
    ...alignCenter,
  },
  {
    field: 'targetLeaders',
    headerName: 'Target Leaders',
    width: 100,
    type: 'number',
    ...alignCenter,
  },
  {
    field: 'assignedLeaders',
    headerName: 'Assigned Leaders',
    width: 100,
    type: 'number',
    valueGetter: (value, row: Duty) => getAssignedLeaders(row),
    cellClassName: numberCellClassComparison('targetLeaders', {
      lessThan: 'warning-cell',
      greaterThan: 'success-cell',
    }, ({ row }) => !hasFinished(row)),
    ...alignCenter,
  },
  {
    field: 'checkedInLeaders',
    headerName: 'Checked In Leaders',
    width: 100,
    type: 'number',
    valueGetter: (value, row: Duty) => row.allocationSummary.leaders.checkedIn,
    cellClassName: numberCellClassComparison(
      ({ row }) => getAssignedLeaders(row),
      {
        lessThan: 'warning-cell',
        equal: 'success-cell',
      },
      ({ row }) => isHappeningNow(row),
    ),
    ...alignCenter,
  },
  {
    field: 'day',
    headerName: 'Day',
    type: 'dateTime',
    width: 120,
    valueGetter: (value, row: Duty) => new Date(row.start),
    valueFormatter: (value: Date) => getRelativeDayOrDayName(value),
    ...alignCenter,
  },
  {
    field: 'start',
    headerName: 'Start',
    type: 'dateTime',
    width: 110,
    valueGetter: (value, row: Duty) => new Date(row.start),
    valueFormatter: (value: Date) => getShortTime(value),
    ...alignCenter,
  },
  {
    field: 'end',
    headerName: 'End',
    type: 'dateTime',
    width: 110,
    valueGetter: (value, row: Duty) => new Date(row.end),
    valueFormatter: (value: Date) => getShortTime(value),
    ...alignCenter,
  },
  {
    field: 'isTargetOptional',
    headerName: 'Optional',
    width: 100,
    type: 'boolean',
  },
  {
    field: 'youngLeaderOnly',
    headerName: 'YL Only',
    width: 100,
    type: 'boolean',
  },
  {
    field: 'adultsOnly',
    headerName: 'Adult Only',
    width: 100,
    type: 'boolean',
  },
  {
    field: 'onlyOnce',
    headerName: 'Once',
    width: 100,
    type: 'boolean',
  },
  {
    field: 'isCatering',
    headerName: 'Catering',
    width: 100,
    type: 'boolean',
  },
];

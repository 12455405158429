import * as Yup from 'yup';
import keys from '../../../helpers/typedKeys';
import personRules from '../../../helpers/validation/person';
import { NewRegistration } from '../../../state/registration/types';

export const validationSchema = Yup.object().shape({
  attendee: Yup.object().shape({
    activitiesMessage: personRules.activitiesMessage,
  }),
  activityLeadership: Yup.object().when('attendee', {
    is: (attendee: NewRegistration['attendee']) => attendee.status === 'Leader' || attendee.status === 'YoungLeader',
    then: Yup.lazy((value) => {
      if (value) {
        const validationObject = { canLead: Yup.mixed().required('A response is required') };
        const newEntries = keys(value).reduce(
          (previous, current) => ({
            ...previous,
            [current]: Yup.object(validationObject),
          }),
          {},
        );
        return Yup.object().shape(newEntries);
      }
      return Yup.mixed().notRequired();
    }),
  }),
});

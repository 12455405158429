import colours from '../theme/colours';
import { HexColourCode } from './cssTypes';

export const getChartColour = (index: number): HexColourCode => {
  const colourList = [
    colours.blue,
    colours.teal,
    colours.pink,
    colours.green,
    colours.purple,
    colours.orange,
    colours.forestGreen,
    colours.indigo,
  ];

  if (index < colourList.length) {
    return colourList[index];
  }
  return colourList[index % colourList.length];
};

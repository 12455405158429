import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { DateTimeRangePicker } from '@mui/x-date-pickers-pro';
import moment from 'moment/moment';
import { FC } from 'react';
import CardFooter from '../../../components/CardFooter';
import { FormGrid } from '../../../components/FormGrid';
import { FormProps } from '../../../components/forms/types';
import { ActivitySession } from '../../../models/activitySession';
import { activitySessionStatusOptions, isValidActivitySessionStatus } from '../../../models/activitySessionStatus';
import { useGetEditionsQuery } from '../../../state/publicApi/editions';

const SessionForm: FC<FormProps<ActivitySession>> = (props) => {
  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = props;

  const {
    data: editions,
    isLoading: editionsLoading,
  } = useGetEditionsQuery();

  // TODO: add validation to this form (including start is before end)
  return (
    <form onSubmit={handleSubmit} noValidate>
      <FormGrid columns={3}>
        <Autocomplete
          options={editions?.map((edition) => edition.id) ?? []}
          loading={editionsLoading}
          getOptionLabel={(id) => editions?.find((edition) => edition.id === id)?.name ?? ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Edition"
              error={touched.editionId && Boolean(errors.editionId)}
              helperText={(touched.editionId && errors.editionId) || ' '}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {editionsLoading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          value={values.editionId}
          onChange={async (event, value) => {
            if (value) {
              await setFieldValue('editionId', value);
            }
          }}
          onBlur={() => setFieldTouched('editionId')}
          disabled={isSubmitting}
        />
        <TextField
          label="Order"
          value={values.order}
          onBlur={() => setFieldTouched('order')}
          onChange={async (event) => {
            await setFieldValue('order', Number(event.target.value.trim()));
          }}
          error={touched.order && Boolean(errors.order)}
          helperText={(touched.order && errors.order) || ' '}
          type="number"
        />
      </FormGrid>
      <FormGrid>
        <FormControl error={touched.start && touched.end
          && (Boolean(errors.start) || Boolean(errors.end))}
        >
          <DateTimeRangePicker
            value={[moment(values.start), moment(values.end)]}
            onChange={async (dateTimeRange) => {
              const [start, end] = dateTimeRange;
              if (!start || !end) {
                return;
              }
              const setStart = setFieldValue('start', start.toISOString(true));
              const setEnd = setFieldValue('end', end.toISOString(true));
              await Promise.all([setStart, setEnd]);
            }}
            disabled={isSubmitting}
          />
          <FormHelperText>
            {(touched.start && touched.end && (errors.start || errors.end)) || ' '}
          </FormHelperText>
        </FormControl>
      </FormGrid>
      <FormGrid columns={2}>
        <FormControl error={touched.status && Boolean(errors.status)}>
          <FormLabel id="check-in-status-label">Check in status</FormLabel>
          <RadioGroup
            aria-labelledby="check-in-status-label"
            value={values.status}
            onChange={async (event) => {
              if (isValidActivitySessionStatus(event.target.value)) {
                await setFieldValue('status', event.target.value);
              }
            }}
            onBlur={() => setFieldTouched('status')}
          >
            {activitySessionStatusOptions.map((status) => (
              <FormControlLabel
                key={status.value}
                value={status.value}
                control={<Radio />}
                label={status.label}
              />
            ))}
          </RadioGroup>
          <FormHelperText>
            {(touched.status && errors.status) || ' '}
          </FormHelperText>
        </FormControl>
      </FormGrid>
      <CardFooter>
        <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </CardFooter>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </form>
  );
};

export default SessionForm;

import { Skeleton } from '@mui/material';
import DataGrid from '../../../../components/DataGrid';
import DataPanelWrapper from '../../../../components/DataPanel/DataPanelWrapper';
import { sortByFullName, sortByStatus } from '../../../../helpers/person';
import useErrorHandler from '../../../../helpers/useErrorHandler';
import { isValidAttendeeStatus } from '../../../../models/personStatus';
import { useGetPeopleQuery } from '../../../../state/protectedApi/people';
import { useGetEventConfigQuery } from '../../../../state/publicApi';
import getColumns from './columns';

type PackPeopleGridProps = {
  packId: number;
};

const PackPeopleGrid = (props: PackPeopleGridProps): JSX.Element => {
  const { packId } = props;
  const {
    data: people,
    error: peopleError,
    isFetching: isFetchingPeople,
  } = useGetPeopleQuery({ packId });
  useErrorHandler(peopleError, 'API Error: Failed to load people for pack');
  const {
    data: eventConfig,
    error: eventConfigError,
  } = useGetEventConfigQuery();
  useErrorHandler(eventConfigError, 'API Error: Failed to load event configuration');

  if (!eventConfig) {
    return (
      <Skeleton variant="rectangular" height={500} />
    );
  }

  const attendees = people?.results.slice()
    .filter((person) => isValidAttendeeStatus(person.status))
    .sort(sortByFullName)
    .sort(sortByStatus);

  const columns = getColumns(eventConfig);
  return (
    <DataPanelWrapper>
      <DataGrid
        autoHeight
        autoPageSize={false}
        disableExternalMargin
        columns={columns}
        rows={attendees ?? []}
        loading={isFetchingPeople}
      />
    </DataPanelWrapper>
  );
};

export default PackPeopleGrid;

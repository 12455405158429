import * as Yup from 'yup';

// TODO add length validation
// TODO validate the duty times are within the edition event times
export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  editionId: Yup.number().typeError('Edition is required').required('Edition is required'),
  targetLeaders: Yup.number().required('Target Leaders is required')
    .positive('Value must be positive'),
  targetAdultLeaders: Yup.number().optional()
    .min(0, 'Value cannot be negative')
    .max(Yup.ref('targetLeaders'), 'Adults cannot exceed leaders'),
  start: Yup.string().required('Start time is required'),
  end: Yup.string().required('End time is required'),
});

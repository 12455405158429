import { TableBody } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { getLongDate } from '../../helpers/datesStrings';
import { Contact } from '../../models/contact';
import { getGenderLabel } from '../../models/gender';
import { Person } from '../../models/person';
import { Registration } from '../../models/registration';
import { ReviewType } from '../../models/reviewType';
import { useGetDistrictQuery, useGetRegionQuery } from '../../state/publicApi/reference';
import Table from '../Table';
import TableKeyValueRow from '../TableKeyValueRow';
import ContactRow from './ContactRow';

type PersonRegistrationDetailsTableProps = {
  person: Person
  registration: Registration
  contacts: Contact[]
  reviewMode?: boolean
  reviewType?: ReviewType
};

const PersonRegistrationDetailsTable = (
  props: PersonRegistrationDetailsTableProps,
): JSX.Element => {
  const {
    person,
    registration,
    contacts,
    reviewMode = false,
    reviewType,
  } = props;

  const {
    currentData: region,
    isFetching: isFetchingRegion,
  } = useGetRegionQuery(person.regionId ?? skipToken);
  const {
    currentData: district,
    isFetching: isFetchingDistrict,
  } = useGetDistrictQuery(person.districtId ?? skipToken);

  return (
    <Table size="small" sx={{ marginBottom: '30px' }}>
      <TableBody>
        {reviewMode && reviewType === 'Compass' && (
          <>
            <TableKeyValueRow name="Region">
              {isFetchingRegion ? 'Loading...' : region?.name ?? 'Not Set'}
            </TableKeyValueRow>
            <TableKeyValueRow name="District">
              {isFetchingDistrict ? 'Loading...' : district?.name ?? 'Not Set'}
            </TableKeyValueRow>
          </>
        )}
        <TableKeyValueRow name="Gender">{getGenderLabel(registration.gender)}</TableKeyValueRow>
        <TableKeyValueRow name="Date of birth">
          {getLongDate(registration.dateOfBirth)}
        </TableKeyValueRow>
        {(!reviewMode || person.status !== 'Leader') && contacts.map((contact) => (
          <ContactRow key={contact.id} contact={contact} />
        ))}
        <TableKeyValueRow name="Media consent">
          {registration.mediaConsent ? 'Yes' : 'No'}
        </TableKeyValueRow>
        <TableKeyValueRow name="Additional needs">
          {registration.additionalNeeds ?? 'None'}
        </TableKeyValueRow>
        <TableKeyValueRow name="Medications">
          {registration.medications ?? 'None'}
        </TableKeyValueRow>
        <TableKeyValueRow name="Allergies">
          {registration.allergies ?? 'None'}
        </TableKeyValueRow>
        <TableKeyValueRow name="Dietary requirements">
          {registration.dietaryRequirements ?? 'None'}
        </TableKeyValueRow>
        {registration.cateringMessage && (
          <TableKeyValueRow name="Catering message">
            {registration.cateringMessage}
          </TableKeyValueRow>
        )}
        {registration.activitiesMessage && (
          <TableKeyValueRow name="Leader activities message">
            {registration.activitiesMessage}
          </TableKeyValueRow>
        )}
        {registration.message && (
          <TableKeyValueRow name="Message">
            {registration.message}
          </TableKeyValueRow>
        )}
        {registration.internalNotes && (
          <TableKeyValueRow name="Internal Notes">
            {registration.internalNotes}
          </TableKeyValueRow>
        )}
      </TableBody>
    </Table>
  );
};

export default PersonRegistrationDetailsTable;

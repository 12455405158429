import {
  ExecutionProps,
  Interpolation,
  css,
} from 'styled-components';

export const breakpoints = {
  small: 375,
  medium: 768,
  large: 1024,
  xLarge: 1440,
  xxLarge: 1920,
};

export type Breakpoint = keyof typeof breakpoints;

export type MinWidthQuery = (
  strings: TemplateStringsArray,
  ...interpolations: Interpolation<ExecutionProps>[]
) => Interpolation<object>;

export const minWidthQuery: (width: number) => MinWidthQuery = (width) => (strings, ...interpolations) => css`
  @media (min-width: ${width}px) {
    ${css(strings, ...interpolations)}
  }
`;

export const mediaQuery = Object.fromEntries(
  Object.entries(breakpoints).map(([breakpoint, value]) => [
    breakpoint as Breakpoint,
    minWidthQuery(value),
  ]),
);

import {
  Backdrop, Button, CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BlocksRenderer from '../../../components/BlocksRenderer';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import CardIntroduction from '../../../components/CardIntroduction';
import { useRegistrationsContent } from '../../../helpers/content';
import routes from '../../../routes';
import { useRegistrationCodeOrError } from '../../../routes/registrationsRoutes';
import { useGetEventConfigQuery } from '../../../state/publicApi';
import useRegistration from '../../../state/registration/useRegistration';

const RegistrationConfirmationPage = (): JSX.Element => {
  const { content } = useRegistrationsContent();
  const [registration] = useRegistration();
  const registrationCode = useRegistrationCodeOrError();
  const navigate = useNavigate();

  const startAgain = (): void => {
    navigate(routes.registrations.start(registrationCode));
  };

  const {
    data: eventConfig,
  } = useGetEventConfigQuery();
  if (!eventConfig || !content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  const mainContent = registration.meta.registeringSelf
    ? content.confirmation.self : content.confirmation.other;

  return (
    <Card>
      <CardHeading>{mainContent.title}</CardHeading>
      <CardIntroduction>
        <BlocksRenderer content={mainContent.body} />
      </CardIntroduction>
      <CardFooter>
        <Button color="primary" variant="contained" onClick={startAgain}>Start Again</Button>
      </CardFooter>
    </Card>
  );
};

export default RegistrationConfirmationPage;

import { ObjectToSnake, objectToSnake } from 'ts-case-convert/lib/caseConvert';
import { ActivityCategory } from '../../models/activityCategory';
import { ActivityDelivery } from '../../models/activityDelivery';
import { PackBookingsStatus } from '../../models/packBookingStatus';
import { PersonStatus } from '../../models/personStatus';
import { Subcamp } from '../../models/subcamp';
import apiService from '../apiService';

type SearchResponse<TResult extends object> = {
  query: string
  currentPage: number
  itemsPerPage: number
  totalResults: number
  totalPages: number
  results: TResult[]
};

type SearchAction<TResult extends object> = (
  query: string,
  currentPage: number,
  itemsPerPage: number,
  edition?: number,
) => Promise<SearchResponse<ObjectToSnake<TResult>>>;

type SearchClient = {
  activities: SearchAction<{
    id: number
    name: string
    category: ActivityCategory
    delivery: ActivityDelivery
  }>
  packs: SearchAction<{
    id: number
    name: string
    reference: string
    bookingStatus: PackBookingsStatus
    subcamp: Subcamp | null
    editionId: number | null
  }>
  people: SearchAction<{
    id: number
    name: string
    role: string
    status: PersonStatus
    subcamp: Subcamp | null
  }>
};

const searchClientForUrl = <TResult extends object>(url: string): SearchAction<TResult> => async (
  query,
  currentPage,
  itemsPerPage,
  edition,
) => apiService.get(url, objectToSnake({
  query,
  currentPage: currentPage.toString(),
  itemsPerPage: itemsPerPage.toString(),
  edition: edition?.toString(),
}), true);

const searchClient: SearchClient = {
  activities: searchClientForUrl('activities/search'),
  packs: searchClientForUrl('packs/search'),
  people: searchClientForUrl('people/search'),
};

export default searchClient;

export const activitySupportStatuses = [
  'NotRequired',
  'FamiliarLeader',
  'FocusedSupport',
] as const;

export type ActivitySupportStatus = (typeof activitySupportStatuses)[number];

export const isValidActivitySupportStatus = (
  value: string,
): value is ActivitySupportStatus => activitySupportStatuses.includes(value as never);

export const getActivitySupportStatusLabel = (status: ActivitySupportStatus): string => {
  switch (status) {
    case 'NotRequired':
      return 'Not required';
    case 'FamiliarLeader':
      return 'Familiar leader';
    case 'FocusedSupport':
      return 'Focused support';
    default:
      return status;
  }
};

export type ActivitySupport = {
  readonly cubId: number
  readonly packId: number
  activityAdditionalSupport: ActivitySupportStatus
  leaderIds: number[]
};

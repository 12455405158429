import {
  Timeline as MuiTimeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  timelineItemClasses,
} from '@mui/lab';
import { CircularProgress } from '@mui/material';
import { gutterPx, rowHeightPx } from '../../../../../components/Dashboard/config';
import DashboardCard from '../../../../../components/DashboardCard';
import DashboardCardTitle from '../../../../../components/DashboardCardTitle';
import { getMediumDate, getShortTime } from '../../../../../helpers/datesStrings';
import { Edition, EditionDates } from '../../../../../models/edition';
import { useGetEditionsForCurrentUserQuery } from '../../../../../state/protectedApi/editions';
import { useGetEventConfigQuery } from '../../../../../state/publicApi';

type Milestone = {
  id: string;
  date: string;
  title: string;
};

const getRows = (mileStoneCount: number): { rows: number, showTitle: boolean } => {
  const titleHeightPx = 70;
  const cardPaddingPx = 20;
  const rowHeight = rowHeightPx + gutterPx;
  const milestonesHeightPx = mileStoneCount * 70;

  const rows = Math.ceil(milestonesHeightPx / rowHeight);
  const actualHeight = (rows * rowHeightPx) + (gutterPx * (rows - 1)) - cardPaddingPx;

  return {
    rows,
    showTitle: actualHeight > titleHeightPx + milestonesHeightPx,
  };
};

const getEditionMilestones = (
  editions: Edition[],
  dateKey: keyof EditionDates,
  title: string,
): Milestone[] => {
  const uniqueValues = editions.reduce(
    (accumulator, edition) => {
      const dateValue = edition[dateKey];
      if (edition[dateKey] && typeof dateValue === 'string' && !accumulator.includes(dateValue)) {
        accumulator.push(dateValue);
      }
      return accumulator;
    },
    [] as string[],
  );

  if (uniqueValues.length === 1) {
    return [{
      id: dateKey,
      date: uniqueValues[0],
      title,
    }];
  }

  return editions.reduce((accumulator, edition) => {
    const dateValue = edition[dateKey];
    if (dateValue && typeof dateValue === 'string') {
      accumulator.push({
        id: `${dateKey}-${edition.id}`,
        date: dateValue,
        title: `${edition.name}: ${title}`,
      });
    }
    return accumulator;
  }, [] as Milestone[]);
};

const Timeline = (): JSX.Element => {
  const { data: eventConfig } = useGetEventConfigQuery();
  const { data: editions } = useGetEditionsForCurrentUserQuery();

  const columns = 2;

  if (!eventConfig || !editions) {
    return (
      <DashboardCard columns={columns} rows={3}>
        <CircularProgress />
      </DashboardCard>
    );
  }

  const fourDaysAgo = new Date(new Date().getTime() - 4 * 24 * 60 * 60 * 1000);

  const { phases: { bookings } } = eventConfig;
  const milestones: Milestone[] = [{
    id: 'ballotLaunch',
    date: bookings.launch,
    title: 'Ballot opens',
  }, {
    id: 'ballotDeadline',
    date: bookings.deadline,
    title: 'Ballot closes',
  }, {
    id: 'ballotResult',
    date: bookings.confirmationDeadline,
    title: 'Ballot result',
  }, {
    id: 'reduceSpacesDeadline',
    date: bookings.deadlineToReturnSpaces,
    title: 'Deadline to return spaces without penalty',
  }, {
    id: 'depositPaymentDeadline',
    date: bookings.depositPaymentDeadline,
    title: 'Deposit payment deadline',
  },
  ...getEditionMilestones(editions, 'registrationsLaunch', 'Registration starts'),
  ...getEditionMilestones(editions, 'registrationsDeadline', 'Registration deadline'),
  ...getEditionMilestones(editions, 'finalPaymentsDeadline', 'Final payment deadline'),
  ...getEditionMilestones(editions, 'attendeeSwapDeadline', 'Attendee swap deadline'),
  ...getEditionMilestones(editions, 'start', 'Event starts'),
  ...getEditionMilestones(editions, 'end', 'Event ends'),
  ].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .filter((milestone) => fourDaysAgo < new Date(milestone.date));

  const { rows, showTitle } = getRows(milestones.length);

  const mainFontSize = '0.9rem';

  return (
    <DashboardCard columns={columns} rows={rows}>
      <MuiTimeline sx={{
        margin: 0,
        justifyContent: 'flex-end',
        [`& .${timelineItemClasses.root}:first-child:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
      >
        {showTitle && (
          <TimelineItem sx={{ alignSelf: 'center' }}>
            <DashboardCardTitle>Timeline</DashboardCardTitle>
          </TimelineItem>
        )}
        {milestones.map((milestone, index) => (
          <TimelineItem key={milestone.id}>
            <TimelineOppositeContent style={{ fontSize: mainFontSize }}>
              {getMediumDate(milestone.date)}
              {!milestone.date.includes('T00:00:00') && (
                <div style={{ fontSize: '0.6rem' }}>
                  {getShortTime(milestone.date)}
                </div>
              )}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              {index < milestones.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent
              style={{ flexGrow: 2.5, fontSize: mainFontSize }}
            >
              {milestone.title}
            </TimelineContent>
          </TimelineItem>
        ))}
      </MuiTimeline>
    </DashboardCard>
  );
};

export default Timeline;

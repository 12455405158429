import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import RouterLink from '../../../components/RouterLink';
import { getRelativeDayOrDayName, getShortTime } from '../../../helpers/datesStrings';
import { ActivitySession } from '../../../models/activitySession';
import { Edition } from '../../../models/edition';
import routes from '../../../routes';

const getColumns = (editions: Edition[], showEdition: boolean): GridColDef<ActivitySession>[] => [
  {
    field: 'id',
    headerName: 'ID',
    width: 100,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    valueGetter: (value, row: ActivitySession) => {
      const sessionName = `Session ${row.order}`;
      const edition = editions.find((e) => e.id === row.editionId);
      if (!showEdition || !edition) {
        return sessionName;
      }
      return `${edition.name}: ${sessionName}`;
    },
    renderCell: (params: GridRenderCellParams<ActivitySession>) => (
      <RouterLink to={routes.activities.sessions.detail(params.row.id)}>
        {params.value}
      </RouterLink>
    ),
  },
  {
    field: 'day',
    headerName: 'Day',
    type: 'dateTime',
    width: 120,
    valueGetter: (value, row: ActivitySession) => new Date(row.start),
    valueFormatter: (value: Date) => getRelativeDayOrDayName(value),
  },
  {
    field: 'start',
    headerName: 'Start',
    type: 'dateTime',
    width: 110,
    valueGetter: (value, row: ActivitySession) => new Date(row.start),
    valueFormatter: (value: Date) => getShortTime(value),
  },
  {
    field: 'end',
    headerName: 'End',
    type: 'dateTime',
    width: 110,
    valueGetter: (value, row: ActivitySession) => new Date(row.end),
    valueFormatter: (value: Date) => getShortTime(value),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
  },
];

export default getColumns;

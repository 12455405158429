export const formatBooleanResponse = (
  value: boolean | null | undefined,
  positive = 'Yes',
  negative = 'No',
  notSet = 'Not set',
): string => {
  if (value === null || value === undefined) {
    return notSet;
  }
  return value ? positive : negative;
};

export const staffTeams = [
  'Core',
  'Activities',
  'Catering',
  'Subcamp',
  'Medical',
] as const;

export type StaffTeam = (typeof staffTeams)[number];

export const isValidStaffTeam = (
  value: string,
): value is StaffTeam => staffTeams.includes(value as never);

export const getStaffTeamLabel = (team: StaffTeam | null): string => {
  switch (team) {
    case 'Core':
      return 'Core team';
    default:
      return team ?? 'Not set';
  }
};

export const staffTeamOptions = staffTeams.map((team) => ({
  label: getStaffTeamLabel(team),
  value: team,
}));

import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ActivityDialog from '../../../components/ActivityDialog';
import BlocksRenderer from '../../../components/BlocksRenderer';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import CardIntroduction from '../../../components/CardIntroduction';
import IconLink from '../../../components/IconLink';
import RankingInput, { SourceRankOption } from '../../../components/RankingInput';
import { useRegistrationsContent } from '../../../helpers/content';
import { useFormikWithStateSync } from '../../../helpers/form';
import keys from '../../../helpers/typedKeys';
import { PageSection } from '../../../models/content/components/pageSection';
import routes from '../../../routes';
import { useRegistrationCodeOrError } from '../../../routes/registrationsRoutes';
import { selectRegistration, update } from '../../../state/registration';
import sanitiseOnSubmit from './sanitise';
import { validationSchema } from './schema';
import * as Styled from './styles';

const RegistrationActivitiesFormPage = (): JSX.Element => {
  const { content } = useRegistrationsContent();
  const registrationCode = useRegistrationCodeOrError();
  const navigate = useNavigate();

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormikWithStateSync(
    selectRegistration,
    update,
    {
      validationSchema,
      onSubmit: async () => {
        navigate(routes.registrations.review(registrationCode));
      },
    },
    sanitiseOnSubmit,
  );

  const onRankChanged = async (newRankedOptions: SourceRankOption[]): Promise<void> => {
    await setFieldValue('activityPreferences', newRankedOptions.map((activity, index) => ({
      id: activity.id,
      name: activity.name,
      rank: index + 1,
    })));
  };

  if (!content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  const getMainContent = (): PageSection => {
    if (values.attendee.status === 'Leader') {
      return content.pack_activities.adult_leader;
    }
    if (values.attendee.status === 'YoungLeader') {
      return content.pack_activities.young_leader;
    }
    return content.pack_activities.cub;
  };
  const mainContent = getMainContent();

  return (
    <Card>
      <CardHeading>{mainContent.title}</CardHeading>
      <CardIntroduction>
        <BlocksRenderer content={mainContent.body} />
      </CardIntroduction>
      <form onSubmit={handleSubmit} noValidate>
        {values.attendee.status === 'Cub' && (
          <RankingInput options={values.activityPreferences} onChange={onRankChanged} />
        )}
        {(values.attendee.status === 'Leader' || values.attendee.status === 'YoungLeader') && (
          <>
            {keys(values.activityLeadership).map((activityId) => (
              <Styled.RadioFieldWrapper key={activityId}>
                <FormControl
                  error={touched.activityLeadership?.[activityId]
                    && Boolean(errors.activityLeadership?.[activityId])}
                >
                  <FormLabel id={`${activityId}-form-label`}>
                    {values.activityLeadership?.[activityId].name}
                    <Styled.DialogButton>
                      <ActivityDialog
                        id={activityId}
                        name={values.activityLeadership?.[activityId].name}
                      />
                    </Styled.DialogButton>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby={`${activityId}-form-label`}
                    value={values.activityLeadership[activityId].canLead}
                    onChange={async (event) => {
                      await setFieldValue(`activityLeadership.${activityId}.canLead`, event.target.value === 'true');
                    }}
                    onBlur={() => setFieldTouched(`activityLeadership.${activityId}.canLead`)}
                  >
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                  <FormHelperText>
                    {(touched.activityLeadership?.[activityId]?.canLead && errors.activityLeadership?.[activityId]?.canLead) ?? ' '}
                  </FormHelperText>
                </FormControl>
              </Styled.RadioFieldWrapper>
            ))}
            <CardIntroduction>
              <BlocksRenderer content={content.pack_activities.leaders_activity_message} />
            </CardIntroduction>
            <TextField
              label="Your message to the activities team..."
              value={values.attendee.activitiesMessage}
              onBlur={() => setFieldTouched('attendee.activitiesMessage')}
              onChange={async (event) => {
                await setFieldValue('attendee.activitiesMessage', event.target.value);
              }}
              error={touched.attendee?.activitiesMessage
                && Boolean(errors.attendee?.activitiesMessage)}
              helperText={(touched.attendee?.activitiesMessage
                && errors.attendee?.activitiesMessage) || ' '}
              multiline
              minRows={3}
            />
          </>
        )}
        <CardFooter>
          <IconLink icon="Back" to={routes.registrations.doctor(registrationCode)}>Back</IconLink>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Next
          </Button>
        </CardFooter>
        <Backdrop open={isSubmitting}>
          <CircularProgress />
        </Backdrop>
      </form>
    </Card>
  );
};

export default RegistrationActivitiesFormPage;

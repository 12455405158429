import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Button,
  IconButton,
  Stack,
  useMediaQuery,
  useScrollTrigger,
} from '@mui/material';
import { forwardRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetEventConfigQuery } from '../../../state/publicApi';
import { useTheme } from '../../../theme';
import { MenuItem, isDivider, isPageLink } from '../types';
import SearchBar from './SearchBar';
import UserMenu from './UserMenu';
import * as Styled from './styles';

type AppBarProps = {
  open: boolean
  toggle: () => void
  showSearch: boolean
  menu: MenuItem[]
};

const AppBar = forwardRef<HTMLDivElement, AppBarProps>((props, ref) => {
  const {
    open,
    toggle,
    showSearch,
    menu,
  } = props;

  const theme = useTheme();
  const screenWiderThanMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const screenWiderThanTablet = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const location = useLocation();
  const isContentOnlyMode = process.env.REACT_APP_CMS_CONTENT_ONLY === 'true';

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
  });

  const closeMenuOnUserMenuNavigation = (): void => {
    if (open) {
      toggle();
    }
  };

  const { data: eventConfig } = useGetEventConfigQuery(undefined, { skip: isContentOnlyMode });

  const pageLinkMenuItems = menu.filter((menuItem: MenuItem) => isPageLink(menuItem));
  const hideMenu = pageLinkMenuItems.length === 0
    || pageLinkMenuItems.every((m) => isDivider(m) || m.route === location.pathname)
    || (pageLinkMenuItems.length < 4 && screenWiderThanMobile);

  return (
    <Styled.AppBar ref={ref} position="sticky" elevation={trigger ? 4 : 0} enableColorOnDark>
      <Styled.Toolbar>
        {!hideMenu && (
          <IconButton
            color="inherit"
            aria-label={open ? 'close menu' : 'open menu'}
            onClick={toggle}
            edge="start"
          >
            {open ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        )}

        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
          justifyContent="center"
        >
          {(!showSearch || screenWiderThanTablet) && (
            <div>{eventConfig?.nameWithYear ?? process.env.REACT_APP_EVENT_NAME_WITH_YEAR}</div>
          )}
          {hideMenu && menu.map((menuItem: MenuItem) => isPageLink(menuItem) && (
            <Button
              key={menuItem.label}
              onClick={() => navigate(menuItem.route)}
              variant="text"
              color="inherit"
              disabled={location.pathname === menuItem.route}
            >
              {menuItem.label}
            </Button>
          ))}
        </Stack>

        {showSearch && <SearchBar />}
        {!isContentOnlyMode && (
          <UserMenu onNavigate={closeMenuOnUserMenuNavigation} />
        )}
      </Styled.Toolbar>
    </Styled.AppBar>
  );
});

export default AppBar;

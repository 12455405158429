import { CountData, ReviewStatusBreakdown } from '../models/statistics';
import colours from '../theme/colours';

export const mapReviewStatusBreakdownToCountData = (
  reviews?: ReviewStatusBreakdown,
  includeNotRequired?: boolean,
): CountData[] | undefined => reviews && [{
  name: 'Pending',
  count: reviews.pending ?? 0,
  colour: colours.blue,
},
{
  name: 'Seeking information',
  count: reviews.seekingInformation ?? 0,
  colour: colours.teal,
},
{
  name: 'Flagged',
  count: reviews.flagged ?? 0,
  colour: colours.red,
}, {
  name: 'Complete',
  count: reviews.complete ?? 0,
  colour: colours.green,
},
...includeNotRequired ? [
  {
    name: 'Not required',
    count: reviews.notRequired !== undefined ? reviews.notRequired : 0,
    colour: colours.grey,
  },
] : []];

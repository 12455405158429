import DataGrid from '../../../components/DataGrid';
import ToolbarEditionFilter from '../../../components/DataGridToolbar/ToolbarEditionFilter';
import ExpandingSpace from '../../../components/ExpandingSpace';
import PageMetaTitle from '../../../components/PageMetaTitle';
import { useDefaultEdition } from '../../../helpers/useDefaultEdition';
import useErrorHandler from '../../../helpers/useErrorHandler';
import routes from '../../../routes';
import { useGetDutiesQuery } from '../../../state/protectedApi/duties';
import { useGetEditionsQuery } from '../../../state/publicApi/editions';
import { columns } from './columns';

const DutiesGridPage = (): JSX.Element => {
  const {
    data: duties,
    error: dutiesError,
    isFetching,
    refetch,
  } = useGetDutiesQuery();
  useErrorHandler(dutiesError, 'API Error: Failed to load duties');

  const { data: editions, error: editionsError } = useGetEditionsQuery();
  useErrorHandler(editionsError, 'API Error: Failed to load editions');

  const [edition, setEditionId] = useDefaultEdition();

  const filteredDuties = duties?.slice()
    .filter((duty) => !edition || duty.editionId === edition.id)
    .map((duty) => ({
      ...duty,
      name: edition ? duty.name
        : `${editions?.find((e) => e.id === duty.editionId)?.name ?? ''}: ${duty.name}`,
    })) ?? [];

  return (
    <>
      <PageMetaTitle title="Duties" />
      <DataGrid
        columns={columns}
        rows={filteredDuties}
        loading={isFetching}
        slotProps={{
          toolbar: {
            refetch,
            createRoute: routes.duties.detail('new'),
            children: (
              <>
                <ExpandingSpace />
                <ToolbarEditionFilter
                  allowAll
                  editionId={edition?.id}
                  onEditionChange={setEditionId}
                />
              </>
            ),
          },
        }}
      />
    </>
  );
};

export default DutiesGridPage;

import {
  Backdrop,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import emailsClient, { EmailsValidationResponse } from '../../../api/emails';
import { AlertWithBottomMargin } from '../../../components/Alert';
import BlocksRenderer from '../../../components/BlocksRenderer';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import CardIntroduction from '../../../components/CardIntroduction';
import { FormGrid } from '../../../components/FormGrid';
import IconLink from '../../../components/IconLink';
import { useRegistrationsContent } from '../../../helpers/content';
import { useFormikWithStateSync } from '../../../helpers/form';
import routes from '../../../routes';
import { useRegistrationCodeOrError } from '../../../routes/registrationsRoutes';
import { selectRegistration, update } from '../../../state/registration';
import { NewRegistration } from '../../../state/registration/types';
import sanitiseOnSubmit from './sanitise';
import { summaryValidate, validationSchema } from './schema';
import * as Styled from './styles';

const RegistrationEmergencyContactsFormPage = (): JSX.Element => {
  const { content } = useRegistrationsContent();
  const registrationCode = useRegistrationCodeOrError();
  const navigate = useNavigate();
  const [summaryError, setSummaryError] = useState('');
  const [externalValidation, setExternalValidation] = useState<EmailsValidationResponse>();

  const getExternalValidationError = (
    email: string,
  ): string | undefined => externalValidation?.find(
    (validation) => !validation.valid && validation.input_email === email,
  )?.error;

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormikWithStateSync(
    selectRegistration,
    update,
    {
      validationSchema,
      onSubmit: async (submittedValues) => {
        const { valid, error } = summaryValidate(submittedValues);
        if (valid && submittedValues.contacts) {
          const emailsToValidate = submittedValues.contacts.slice()
            .map((contact) => contact.email).filter((email) => email);
          if (emailsToValidate.length === 0) {
            navigate(routes.registrations.doctor(registrationCode));
            return;
          }
          await emailsClient.validate(emailsToValidate).then(async (response) => {
            setExternalValidation(response);
            const newEmergencyContacts: NewRegistration['contacts'] = [];
            submittedValues.contacts.forEach((contact) => {
              const validation = response.find((v) => v.input_email === contact.email);
              if (validation?.valid) {
                newEmergencyContacts.push(
                  { ...contact, email: validation.normalised_email ?? contact.email },
                );
              } else {
                newEmergencyContacts.push(contact);
              }
            });
            await setFieldValue('contacts', newEmergencyContacts);

            if (response.every((validation) => validation.valid)) {
              navigate(routes.registrations.doctor(registrationCode));
            }
          }).catch(() => {
            console.error('API Error: Could not check if emails were valid');
            navigate(routes.registrations.doctor(registrationCode));
          });
        } else if (!valid) {
          setSummaryError(error);
          window.scrollTo(0, 0);
        }
      },
    },
    sanitiseOnSubmit,
  );

  const [displayedKeys, setDisplayedKeys] = useState(
    values.contacts.map((contact) => contact.key),
  );

  const addEmergencyContact = useCallback(async (): Promise<void> => {
    const newKey = uuid();
    const newList = [...values.contacts, {
      key: newKey,
      firstName: '',
      knownAs: '',
      lastName: '',
      relationship: '',
      mobile: '',
      email: '',
      emergency: true,
      attending: null,
      updates: values.meta.registeringSelf ? false : null,
    }];
    await setFieldValue('contacts', newList);
    setDisplayedKeys([...displayedKeys, newKey]);
  }, [displayedKeys, setFieldValue, values.contacts, values.meta.registeringSelf]);

  const addFirstEmergencyContact = useCallback(async (): Promise<void> => {
    if (values.contacts.length === 0) {
      await addEmergencyContact();
    }
  }, [addEmergencyContact, values.contacts.length]);

  const deleteEmergencyContact = async (contactKey: string): Promise<void> => {
    const newList = values.contacts.map((x) => x);
    await setFieldValue('contacts', newList.filter((contact) => contact.key !== contactKey));
  };

  const getContactSectionHeading = (index: number): string => {
    const contact = values.contacts[index];
    const fullNameSet = Boolean(contact.firstName) && Boolean(contact.lastName);
    return fullNameSet ? `${contact.firstName} ${contact.lastName}` : 'New Contact';
  };

  const isContactFieldTouched = (
    fieldName: keyof NewRegistration['contacts'][0],
    index: number,
  ): boolean => Boolean(
    touched.contacts
    && touched.contacts[index]
    && touched.contacts[index][fieldName],
  );

  const getContactFieldError = (
    fieldName: keyof NewRegistration['contacts'][0],
    index: number,
  ): string | undefined => {
    const contactErrors = errors.contacts?.[index];
    if (contactErrors === undefined || typeof contactErrors === 'string') {
      return undefined;
    }
    return contactErrors[fieldName];
  };

  const showRemoveButton = values.contacts.length > 1
    || (values.mainContact.emergency === true && values.mainContact.attending !== true);

  if (!content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  // TODO pull the event start time/dates from the event config object
  return (
    <Card>
      <CardHeading>{content.emergency_contacts.title}</CardHeading>
      <CardIntroduction>
        <BlocksRenderer content={content.emergency_contacts.body} />
      </CardIntroduction>
      <Collapse in={Boolean(summaryError)}>
        <AlertWithBottomMargin severity="error">{summaryError}</AlertWithBottomMargin>
      </Collapse>
      <form onSubmit={handleSubmit} noValidate>
        {!values.meta.registeringSelf && (
          <>
            <div>
              <FormControl
                error={touched.mainContact?.emergency
                  && Boolean(errors.mainContact?.emergency)}
              >
                <FormLabel id="are-you-a-contact-label">
                  Should you be contacted in the event of an emergency?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="are-you-a-contact-label"
                  value={values.mainContact.emergency}
                  onChange={async (event) => {
                    await setFieldValue('mainContact.emergency', event.target.value === 'true');
                    if (event.target.value === 'false') {
                      await addFirstEmergencyContact();
                    }
                  }}
                  onBlur={() => setFieldTouched('mainContact.emergency')}
                >
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
                <FormHelperText>
                  {(touched.mainContact?.emergency && errors.mainContact?.emergency) || ' '}
                </FormHelperText>
              </FormControl>
            </div>
            <div>
              <FormControl
                error={touched.mainContact?.attending && Boolean(errors.mainContact?.attending)}
              >
                <FormLabel id="are-you-attending-label">
                  Are you also attending?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="are-you-attending-label"
                  value={values.mainContact.attending}
                  onChange={async (event) => {
                    await setFieldValue('mainContact.attending', event.target.value === 'true');
                    if (event.target.value === 'true') {
                      await addFirstEmergencyContact();
                    }
                  }}
                  onBlur={() => setFieldTouched('mainContact.attending')}
                >
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
                <FormHelperText>
                  {(touched.mainContact?.attending && errors.mainContact?.attending) || ' '}
                </FormHelperText>
              </FormControl>
            </div>
          </>
        )}
        {values.contacts.map((contact, index) => (
          <Collapse
            key={contact.key}
            in={displayedKeys.some((displayedKey) => displayedKey === contact.key)}
          >
            <Styled.NewContactFormWrapper>
              <FormGrid columns={2} noGap noBottomMargin>
                <h2>{getContactSectionHeading(index)}</h2>
                {showRemoveButton && (
                  <Styled.RemoveButtonWrapper>
                    <Button variant="contained" color="error" onClick={() => deleteEmergencyContact(contact.key)}>
                      Remove Contact
                    </Button>
                  </Styled.RemoveButtonWrapper>
                )}
              </FormGrid>
              <FormGrid columns={2}>
                <TextField
                  label="First name"
                  value={contact.firstName}
                  onBlur={() => setFieldTouched(`contacts[${index}].firstName`)}
                  onChange={async (event) => {
                    await setFieldValue(`contacts[${index}].firstName`, event.target.value);
                  }}
                  error={isContactFieldTouched('firstName', index)
                    && Boolean(getContactFieldError('firstName', index))}
                  helperText={(isContactFieldTouched('firstName', index)
                    && getContactFieldError('firstName', index)) || ' '}
                />
                <TextField
                  label="Last name"
                  value={contact.lastName}
                  onBlur={() => setFieldTouched(`contacts[${index}].lastName`)}
                  onChange={async (event) => {
                    await setFieldValue(`contacts[${index}].lastName`, event.target.value);
                  }}
                  error={isContactFieldTouched('lastName', index)
                    && Boolean(getContactFieldError('lastName', index))}
                  helperText={(isContactFieldTouched('lastName', index)
                    && getContactFieldError('lastName', index)) || ' '}
                />
              </FormGrid>
              <FormGrid columns={2}>
                <TextField
                  label="Relationship"
                  value={contact.relationship}
                  onBlur={() => setFieldTouched(`contacts[${index}].relationship`)}
                  onChange={async (event) => {
                    await setFieldValue(`contacts[${index}].relationship`, event.target.value);
                  }}
                  error={isContactFieldTouched('relationship', index)
                    && Boolean(getContactFieldError('relationship', index))}
                  helperText={(isContactFieldTouched('relationship', index)
                    && getContactFieldError('relationship', index)) || ' '}
                />
                <TextField
                  label="Mobile"
                  value={contact.mobile}
                  onBlur={() => setFieldTouched(`contacts[${index}].mobile`)}
                  onChange={async (event) => {
                    await setFieldValue(`contacts[${index}].mobile`, event.target.value);
                  }}
                  error={isContactFieldTouched('mobile', index)
                    && Boolean(getContactFieldError('mobile', index))}
                  helperText={(isContactFieldTouched('mobile', index)
                    && getContactFieldError('mobile', index)) || ' '}
                  inputProps={{ inputMode: 'tel' }}
                />
                {!values.meta.registeringSelf && (
                  <TextField
                    label="Email"
                    value={contact.email}
                    onBlur={() => setFieldTouched(`contacts[${index}].email`)}
                    onChange={async (event) => {
                      await setFieldValue(`contacts[${index}].email`, event.target.value.trim());
                    }}
                    error={isContactFieldTouched('email', index)
                      && Boolean(getContactFieldError('email', index)
                        || getExternalValidationError(contact.email))}
                    helperText={(isContactFieldTouched('email', index)
                        && getContactFieldError('email', index))
                      || getExternalValidationError(contact.email) || ' '}
                    inputProps={{ inputMode: 'email' }}
                  />
                )}
                <FormControl
                  error={isContactFieldTouched('attending', index)
                    && Boolean(getContactFieldError('attending', index))}
                >
                  <FormLabel id={`are-they-attending-label-${index}`}>
                    Are they also attending?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby={`are-they-attending-label-${index}`}
                    value={contact.attending}
                    onChange={async (event) => {
                      await setFieldValue(`contacts[${index}].attending`, event.target.value === 'true');
                    }}
                    onBlur={() => setFieldTouched(`contacts[${index}].attending`)}
                  >
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                  <FormHelperText>
                    {(isContactFieldTouched('attending', index)
                      && getContactFieldError('attending', index)) || ' '}
                  </FormHelperText>
                </FormControl>
              </FormGrid>
              {!values.meta.registeringSelf && (
                <Collapse in={contact.email !== ''}>
                  <FormGrid>
                    <FormControl
                      error={isContactFieldTouched('updates', index)
                        && Boolean(getContactFieldError('updates', index))}
                    >
                      <FormLabel id={`send-email-updates-label-${index}`}>
                        Should we include them in non-emergency communications?
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby={`send-email-updates-label-${index}`}
                        value={contact.updates}
                        onChange={async (event) => {
                          await setFieldValue(`contacts[${index}].updates`, event.target.value === 'true');
                        }}
                        onBlur={() => setFieldTouched(`contacts[${index}].updates`)}
                      >
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                      </RadioGroup>
                      <FormHelperText>
                        {(isContactFieldTouched('updates', index)
                            && getContactFieldError('updates', index))
                          || 'If yes, we include them in the confirmation email you receive at the end of registration'}
                      </FormHelperText>
                    </FormControl>
                  </FormGrid>
                </Collapse>
              )}
            </Styled.NewContactFormWrapper>
          </Collapse>
        ))}
        <Styled.AddButtonWrapper>
          <Button variant="contained" color="success" onClick={addEmergencyContact}>
            Add Contact
          </Button>
        </Styled.AddButtonWrapper>
        <CardFooter>
          <IconLink icon="Back" to={routes.registrations.catering(registrationCode)}>
            Back
          </IconLink>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Next
          </Button>
        </CardFooter>
      </form>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </Card>
  );
};

export default RegistrationEmergencyContactsFormPage;

import { GridColDef } from '@mui/x-data-grid-pro';
import RouterLink from '../../../../components/RouterLink';
import { getRelativeDayOrDateWithTime } from '../../../../helpers/datesStrings';
import { getFullName } from '../../../../helpers/person';
import { ActivityAllocation } from '../../../../models/activityAllocation';
import { getActivityLeaderRoleLabel } from '../../../../models/activityLeaderRole';
import { getPersonStatusLabel } from '../../../../models/personStatus';
import routes from '../../../../routes';

export const columns: GridColDef<ActivityAllocation>[] = [
  {
    field: 'personId',
    headerName: 'ID',
    width: 100,
  },
  {
    field: 'person',
    headerName: 'Person',
    width: 200,
    valueGetter: (value, row) => getFullName(row),
    renderCell: (params) => (
      <RouterLink to={routes.people.detail(params.row.id)}>{params.value}</RouterLink>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    valueFormatter: getPersonStatusLabel,
  },
  {
    field: 'leaderRole',
    headerName: 'Leader Role',
    valueGetter: (value, row) => (row.activityName ? value : null),
    valueFormatter: getActivityLeaderRoleLabel,
    width: 200,
  },
  {
    field: 'activityName',
    headerName: 'Activity',
    valueGetter: (value, row) => {
      if (row.activityName) {
        return row.activityName;
      }
      if (['YoungLeader', 'Leader'].includes(row.status)) {
        return 'Stand by';
      }
      return null;
    },
    renderCell: (params) => {
      const { activityId } = params.row;
      if (activityId === null) {
        return params.row.activityName;
      }
      return (
        <RouterLink to={routes.activities.adminDetail(activityId)}>
          {params.value}
        </RouterLink>
      );
    },
    width: 250,
  },
  {
    field: 'checkedInAt',
    headerName: 'Check In',
    width: 200,
    type: 'dateTime',
    valueFormatter: (value, row) => {
      if (value) {
        return getRelativeDayOrDateWithTime(value);
      }
      if (row.activityId) {
        return 'Missing';
      }
      return null;
    },
  },
];

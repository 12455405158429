import { Outlet } from 'react-router-dom';
import FormLayout from '../../components/FormLayout';
import { useScrollToTopOnNavigation } from '../../helpers/windowScroll';

// TODO move this to the components directory
const FormLayoutWrapper = (): JSX.Element => {
  useScrollToTopOnNavigation();

  return (
    <FormLayout>
      <Outlet />
    </FormLayout>
  );
};

export default FormLayoutWrapper;

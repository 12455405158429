import { CreatePerson } from '../../../../../models/person';

const sanitiseOnSubmit = <TForm extends CreatePerson>(rawValues: TForm): TForm => ({
  ...rawValues,
  firstName: rawValues.firstName.trim(),
  lastName: rawValues.lastName.trim(),
  mobile: rawValues.mobile?.trim(),
  email: rawValues.email?.trim(),
});

export default sanitiseOnSubmit;

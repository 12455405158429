import { GridColumnGroupingModel } from '@mui/x-data-grid-pro';
import { ActivitySession } from '../../../models/activitySession';

const columnGroupings = (sessions: ActivitySession[]): GridColumnGroupingModel => [
  {
    groupId: 'Activity',
    children: [
      { field: 'id' },
      { field: 'name' },
    ],
  },
  {
    groupId: 'Type',
    children: [
      { field: 'category' },
      { field: 'delivery' },
    ],
  },
  {
    groupId: 'Participants',
    children: [
      { field: 'targetParticipants' },
      ...sessions.map((session) => ({ field: `participantsSession${session.id}` })),
    ],
  },
  {
    groupId: 'Capacity for more participant ',
    children: [
      ...sessions.map((session) => ({ field: `spareParticipantCapacitySession${session.id}` })),
      { field: 'spareParticipantCapacityTotal' },
    ],
  },
  {
    groupId: 'Leaders',
    children: [
      { field: 'targetLeaders' },
      ...sessions.map((session) => ({ field: `leadersSession${session.id}` })),
    ],
  },
  {
    groupId: 'Adult Leaders',
    children: [
      { field: 'targetAdultLeaders' },
      ...sessions.map((session) => ({ field: `adultLeadersSession${session.id}` })),
    ],
  },
  {
    groupId: 'Links',
    children: [
      { field: 'qrSign' },
      { field: 'public' },
      { field: 'admin' },
    ],
  },
];

export default columnGroupings;

import { CircularProgress } from '@mui/material';
import { FC } from 'react';
import {
  Cell,
  Legend as LibLegend,
  Pie,
  PieChart,
  Tooltip,
} from 'recharts';
import { getChartColour } from '../../helpers/chartColour';
import { CountData } from '../../models/statistics';
import DashboardCard from '../DashboardCard';
import DashboardCardTitle from '../DashboardCardTitle';

type DashboardPieChartProps = {
  title: string
  showLegend?: boolean
  data?: CountData[]
  dataOuterCircle?: CountData[]
  halfCircle?: boolean
  tall?: boolean
};

const getMainPieRadius = (halfCircle: boolean, outerPie: boolean): number => {
  const outerPieSpace = outerPie ? 25 : 0;
  if (halfCircle) {
    return 125 - outerPieSpace;
  }
  return 75 - outerPieSpace;
};

const DashboardPieChart: FC<DashboardPieChartProps> = (props) => {
  const {
    title,
    showLegend = true,
    data,
    dataOuterCircle,
    halfCircle = false,
    tall = false,
  } = props;

  if (!data) {
    return (
      <DashboardCard columns={2} rows={tall ? 3 : 2}>
        <CircularProgress />
      </DashboardCard>
    );
  }

  return (
    <DashboardCard columns={2} rows={tall ? 3 : 2}>
      <DashboardCardTitle>{title}</DashboardCardTitle>
      <PieChart width={280} height={tall ? 390 : 250}>
        {showLegend && (
          <LibLegend verticalAlign="bottom" />
        )}
        <Tooltip
          separator=": "
        />
        <Pie
          nameKey="name"
          dataKey="count"
          data={data}
          cx="50%"
          cy={halfCircle ? '75%' : '50%'}
          outerRadius={getMainPieRadius(halfCircle, !!dataOuterCircle) + (tall ? 25 : 0)}
          startAngle={halfCircle ? 180 : undefined}
          endAngle={halfCircle ? 0 : undefined}
        >
          {data.map((value, index) => (
            <Cell key={value.name} fill={value.colour ?? getChartColour(index)} />
          ))}
        </Pie>
        {dataOuterCircle && (
          <Pie
            nameKey="name"
            dataKey="count"
            data={dataOuterCircle}
            cx="50%"
            cy={halfCircle ? '75%' : '50%'}
            innerRadius={getMainPieRadius(halfCircle, true) + 10}
            outerRadius={getMainPieRadius(halfCircle, true) + 25}
            startAngle={halfCircle ? 180 : undefined}
            endAngle={halfCircle ? 0 : undefined}
          >
            {dataOuterCircle.map((value, index) => (
              <Cell key={value.name} fill={value.colour ?? getChartColour(index)} />
            ))}
          </Pie>
        )}
      </PieChart>
    </DashboardCard>
  );
};

export default DashboardPieChart;

import { TableBody } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { FC } from 'react';
import { DataPanelDisplayProps } from '../../../components/DataPanel';
import Table from '../../../components/Table';
import TableKeyValueRow from '../../../components/TableKeyValueRow';
import { getRelativeDayOrDayName, getShortTime } from '../../../helpers/datesStrings';
import { ActivitySession } from '../../../models/activitySession';
import { useGetEditionQuery } from '../../../state/publicApi/editions';

const DisplaySession: FC<DataPanelDisplayProps<ActivitySession>> = (props) => {
  const {
    data: {
      id,
      editionId,
      order,
      start,
      end,
      status,
    },
  } = props;

  const { data: edition } = useGetEditionQuery(editionId ?? skipToken);

  return (
    <Table size="small" sx={{ marginBottom: '30px' }}>
      <TableBody>
        <TableKeyValueRow name="ID">{id}</TableKeyValueRow>
        <TableKeyValueRow name="Edition">{edition?.name}</TableKeyValueRow>
        <TableKeyValueRow name="Order">{order}</TableKeyValueRow>
        <TableKeyValueRow name="Day">{getRelativeDayOrDayName(start)}</TableKeyValueRow>
        <TableKeyValueRow name="Start">{getShortTime(start)}</TableKeyValueRow>
        <TableKeyValueRow name="End">{getShortTime(end)}</TableKeyValueRow>
        <TableKeyValueRow name="Status">{status}</TableKeyValueRow>
      </TableBody>
    </Table>
  );
};

export default DisplaySession;

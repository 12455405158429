import { ActivitySession } from '../models/activitySession';
import { useGetSessionsQuery } from '../state/publicApi/activities';

const useDefaultSession = (): ActivitySession | undefined => {
  const { data, isLoading } = useGetSessionsQuery();
  if (isLoading || !data) {
    return undefined;
  }
  if (data.length === 0) {
    throw new Error('No sessions found to select as a default');
  }

  const futureSessions = data.slice().filter((session) => new Date(session.end) > new Date());
  const futureSessionsOrdered = futureSessions.slice()
    .sort((a, b) => {
      if (a.editionId === b.editionId) {
        return a.order - b.order;
      }
      return a.editionId - b.editionId;
    });

  if (futureSessionsOrdered.length > 0) {
    return futureSessionsOrdered[0];
  }
  return data[0];
};

export default useDefaultSession;

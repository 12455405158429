import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {
  Collapse,
  IconButton,
  Skeleton,
  Stack,
} from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useState } from 'react';
import DataPanelWrapper from '../../../../components/DataPanel/DataPanelWrapper';
import ErrorAlert from '../../../../components/DataPanel/ErrorAlert';
import SuccessAlert from '../../../../components/DataPanel/SuccessAlert';
import DeleteDialog from '../../../../components/DeleteDialog';
import { MutateActionType } from '../../../../helpers/actionType';
import checkPersonPermissions from '../../../../helpers/dataAction';
import { Person } from '../../../../models/person';
import { User } from '../../../../models/user';
import {
  useDeleteActivityScheduleMutation,
  useGetActivityScheduleQuery,
} from '../../../../state/protectedApi/activitySchedules';
import { useGetActivitiesQuery, useGetSessionsQuery } from '../../../../state/publicApi/activities';
import { useGetEditionQuery } from '../../../../state/publicApi/editions';
import ScheduleForm from './ScheduleForm';
import ScheduleTable from './ScheduleTable';

type SchedulePanelProps = {
  editionId: number
  multipleEditions: boolean
  person: Person
  user: User | undefined
};

const SchedulePanel = (props: SchedulePanelProps): JSX.Element => {
  const {
    editionId,
    multipleEditions,
    person,
    user,
  } = props;

  const canUpdateSchedule = checkPersonPermissions('Update', person, user);
  const [editMode, setEditMode] = useState(false);
  const [mutateSuccess, setMutateSuccess] = useState<MutateActionType>();
  const [error, setError] = useState<SerializedError | FetchBaseQueryError>();

  const {
    data: schedule,
    isFetching,
    error: scheduleError,
    refetch,
  } = useGetActivityScheduleQuery({ personId: person.id, editionId });
  const {
    data: edition,
  } = useGetEditionQuery(editionId, { skip: !multipleEditions });
  const {
    data: activities,
  } = useGetActivitiesQuery();
  const {
    data: sessions,
  } = useGetSessionsQuery();
  const [deleteActivitySchedule] = useDeleteActivityScheduleMutation();

  const onSuccess = (type: MutateActionType) => async (): Promise<void> => {
    setMutateSuccess(type);
    await refetch();
  };

  const onEditClick = (): void => {
    setEditMode((prev) => !prev);
    setMutateSuccess(undefined);
    setError(undefined);
  };

  const onSuccessfulUpdate = async (): Promise<void> => {
    setMutateSuccess('update');
    setError(undefined);
    setEditMode(false);
    await refetch();
  };

  const onUpdateError = (newError: SerializedError | FetchBaseQueryError): void => {
    setError(newError);
    setMutateSuccess(undefined);
  };

  const title = edition ? `${edition.name} Schedule` : 'Schedule';

  return (
    <DataPanelWrapper>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <h3>{title}</h3>
        {canUpdateSchedule && schedule && (
          <Stack direction="row-reverse" spacing={3}>
            {schedule?.some((item) => item.activityId) && (
              <DeleteDialog
                title="Delete activity schedule"
                description="Are you sure you want to delete all activity allocation records?"
                modelName="activity allocations"
                data={{}}
                onDelete={() => deleteActivitySchedule({ personId: person.id, editionId })}
                onSuccess={onSuccess('delete')}
              />
            )}
            <IconButton onClick={onEditClick}>
              {editMode ? <CancelIcon /> : <EditIcon />}
            </IconButton>
          </Stack>
        )}
      </Stack>

      {mutateSuccess && (
        <SuccessAlert
          modelName="Activity Allocations"
          type={mutateSuccess}
        />
      )}

      <Collapse in={error !== undefined}>
        {error && (
          <ErrorAlert modelName="activity preferences" type="update" error={error} />
        )}
      </Collapse>

      {scheduleError && (
        <ErrorAlert
          modelName="schedule"
          type="load"
          error={scheduleError}
        />
      )}

      {(activities === undefined
        || sessions === undefined
        || (schedule === undefined && !scheduleError)
        || isFetching) && (
        <div>
          <Skeleton variant="rectangular" height={30} sx={{ marginBottom: '3px' }} />
          <Skeleton variant="rectangular" height={30} sx={{ marginBottom: '3px' }} />
          <Skeleton variant="rectangular" height={30} sx={{ marginBottom: '3px' }} />
          <Skeleton variant="rectangular" height={30} sx={{ marginBottom: '3px' }} />
          <Skeleton variant="rectangular" height={30} sx={{ marginBottom: '3px' }} />
          <Skeleton variant="rectangular" height={30} sx={{ marginBottom: '3px' }} />
          <Skeleton variant="rectangular" height={30} sx={{ marginBottom: '30px' }} />
        </div>
      )}

      {activities && sessions && schedule && !editMode && !isFetching && (
        <ScheduleTable
          person={person}
          activities={activities}
          sessions={sessions}
          schedule={schedule}
        />
      )}

      {editMode && activities && sessions && schedule && (
        <ScheduleForm
          person={person}
          activities={activities}
          sessions={sessions}
          schedule={schedule}
          onSuccess={onSuccessfulUpdate}
          onError={onUpdateError}
          editionId={editionId}
        />
      )}
    </DataPanelWrapper>
  );
};

export default SchedulePanel;

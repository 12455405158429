import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete, Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import useErrorHandler from '../../helpers/useErrorHandler';
import { ReviewType, reviewTypes } from '../../models/reviewType';
import { StaffTeam, getStaffTeamLabel, staffTeams } from '../../models/staffTeam';
import { Subcamp, subcamps } from '../../models/subcamp';
import { useGetPacksQuery } from '../../state/protectedApi/packs';
import { useGetDistrictsQuery, useGetRegionsQuery } from '../../state/publicApi/reference';
import { FormGrid } from '../FormGrid';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type ReviewTypeSelectionProps = {
  reviewType: ReviewType
  packId?: number | null
  subcamp?: Subcamp | null | ''
  districtId?: number | null
  staffTeams?: StaffTeam[]
  reviewTypeOnChange: (update: ReviewType) => void
  packIdOnChange: (update: number | undefined) => void
  subcampOnChange: (update: Subcamp | undefined) => void
  districtIdOnChange: (update: number | undefined) => void
  staffTeamsOnChange: (update: StaffTeam[]) => void
};

const ReviewTypeSelection = (props: ReviewTypeSelectionProps): JSX.Element => {
  const {
    reviewType,
    packId,
    subcamp,
    districtId,
    staffTeams: selectedStaffTeams,
    reviewTypeOnChange,
    packIdOnChange,
    subcampOnChange,
    districtIdOnChange,
    staffTeamsOnChange,
  } = props;

  const {
    data: packs,
    isLoading: packsLoading,
    error: packsError,
  } = useGetPacksQuery({ bookingStatus: 'Accepted' });
  useErrorHandler(packsError, 'API Error: Failed to load packs');

  const {
    data: districts,
    isLoading: districtsLoading,
    error: districtsError,
  } = useGetDistrictsQuery();
  useErrorHandler(districtsError, 'API Error: Failed to load districts');

  const {
    data: regions,
    error: regionsError,
  } = useGetRegionsQuery();
  useErrorHandler(regionsError, 'API Error: Failed to load regions');

  const districtsForPacks = districts?.filter(
    (district) => packs?.some((pack) => pack.districtId === district.id),
  );

  return (
    <FormGrid>
      <FormControl>
        <FormLabel id="review-type-label">Type</FormLabel>
        <RadioGroup
          row
          aria-labelledby="review-type-label"
          value={reviewType}
          onChange={(event) => {
            reviewTypeOnChange(event.target.value as ReviewType);
          }}
        >
          {reviewTypes.map((option) => (
            <FormControlLabel key={option} value={option} control={<Radio />} label={option === 'PackLeader' ? 'Pack' : option} />
          ))}
        </RadioGroup>
      </FormControl>
      <Collapse in={reviewType === 'Subcamp'}>
        <FormControl>
          <FormLabel id="subcamp-label">Subcamp</FormLabel>
          <RadioGroup
            row
            aria-labelledby="subcamp-label"
            value={subcamp}
            onChange={(event) => {
              subcampOnChange(event.target.value as Subcamp);
            }}
          >
            {subcamps.map((option) => (
              <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
            ))}
          </RadioGroup>
        </FormControl>
      </Collapse>
      <Collapse in={reviewType === 'PackLeader'}>
        <FormGrid columns={2}>
          <Autocomplete
            options={packs ?? []}
            loading={packsLoading}
            getOptionLabel={(option) => `${option.reference}: ${option.name}`}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Pack"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {packsLoading ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            value={packs?.find((pack) => pack.id === packId)}
            onChange={(event, value) => {
              packIdOnChange(value?.id);
            }}
          />
        </FormGrid>
      </Collapse>
      <Collapse in={reviewType === 'Compass'}>
        <FormGrid columns={3}>
          <Autocomplete
            options={districtsForPacks ?? []}
            loading={districtsLoading || packsLoading}
            getOptionLabel={(option) => option.name}
            groupBy={(option) => regions?.find((region) => region.id === option.regionId)?.name ?? 'Unknown'}
            renderInput={(params) => (
              <TextField
                {...params}
                label="District"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {districtsLoading || packsLoading ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            value={districts?.find((district) => district.id === districtId)}
            onChange={(event, value) => {
              districtIdOnChange(value?.id);
            }}
          />
          <Autocomplete
            options={staffTeams}
            multiple
            getOptionLabel={getStaffTeamLabel}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Staff Teams"
              />
            )}
            renderOption={(renderProps, option, { selected }) => (
              <li key={option} {...renderProps}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {getStaffTeamLabel(option)}
              </li>
            )}
            value={selectedStaffTeams ?? []}
            onChange={(event, value) => staffTeamsOnChange(value)}
          />
        </FormGrid>
      </Collapse>
    </FormGrid>
  );
};

export default ReviewTypeSelection;

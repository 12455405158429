import MuiIconButton from '@mui/material/IconButton';
import styled from 'styled-components';

export const IconButton = styled(MuiIconButton)`
  margin-top: -8px;
  margin-bottom: -8px;
  margin-left: auto;
`;

export const ImageWrapper = styled.div`
  max-height: 70vh;
  overflow: hidden;
`;

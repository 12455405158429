import { useParams } from 'react-router-dom';
import useError from '../state/error/useError';

type RegistrationRouteParams = {
  registrationCode: string
};

export const registrationCodePlaceholder = ':registrationCode';

type RegistrationRouteForCode = (registrationCode: string) => string;

export const registrationsRoute = (
  subPath?: string,
): RegistrationRouteForCode => (registrationCode: string) => (
  subPath ? `/registrations/${registrationCode}/${subPath}` : `/registrations/${registrationCode}`
);

export const useRegistrationCodeOrError = (): string => {
  const [, throwError] = useError();
  const { registrationCode } = useParams<RegistrationRouteParams>();
  if (registrationCode === undefined) {
    throwError({ message: 'URL Error: registration code not set in URL' });
    return '';
  }
  return registrationCode;
};

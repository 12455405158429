import { Stack, TableBody } from '@mui/material';
import Image from 'mui-image';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ActivityRegister from '../../../components/ActivityRegister';
import BlocksRenderer from '../../../components/BlocksRenderer';
import ContentLayout from '../../../components/ContentLayout';
import DataPanel, { DataPanelDisplayProps } from '../../../components/DataPanel';
import DataPanelWrapper from '../../../components/DataPanel/DataPanelWrapper';
import LoadingText from '../../../components/LoadingText';
import PageMetaTitle from '../../../components/PageMetaTitle';
import RouterLink from '../../../components/RouterLink';
import Table from '../../../components/Table';
import TableKeyValueRow from '../../../components/TableKeyValueRow';
import TableKeyValueSectionHeader from '../../../components/TableKeyValueSectionHeader';
import { addCmsBaseUrl } from '../../../helpers/cmsImage';
import { useActivitiesContent } from '../../../helpers/content';
import { eventPhaseOrLater } from '../../../helpers/eventConfig';
import useErrorHandler from '../../../helpers/useErrorHandler';
import { userHasRole } from '../../../helpers/user';
import { Activity } from '../../../models/activity';
import { getActivityDeliveryLabel } from '../../../models/activityDelivery';
import routes from '../../../routes';
import {
  useCreateActivityMutation,
  useDeleteActivityMutation,
  useUpdateActivityMutation,
} from '../../../state/protectedApi/activities';
import { useGetCurrentUserQuery } from '../../../state/protectedApi/user';
import { publicApi, useGetEventConfigQuery } from '../../../state/publicApi';
import { useGetActivityQuery } from '../../../state/publicApi/activities';
import PossibleLeadersList from './PossibleLeadersList';
import ActivityForm from './activityForm';
import { validationSchema } from './schema';

const DisplayActivity = (props: DataPanelDisplayProps<Activity>): JSX.Element => {
  const { data: activity } = props;

  return (
    <Table size="small" sx={{ marginBottom: '30px' }}>
      <TableBody>
        <TableKeyValueRow name="ID">{activity.id}</TableKeyValueRow>
        <TableKeyValueRow name="Category">
          {activity.category}
        </TableKeyValueRow>
        <TableKeyValueRow name="Delivery">
          {activity.delivery && getActivityDeliveryLabel(activity.delivery)}
        </TableKeyValueRow>
        <TableKeyValueRow name="Target participants">
          {activity.targetParticipants}
        </TableKeyValueRow>
        <TableKeyValueRow name="Target leaders">
          {activity.targetLeaders}
        </TableKeyValueRow>
        <TableKeyValueRow name="Target adult leaders">
          {activity.targetAdultLeaders}
        </TableKeyValueRow>
      </TableBody>
    </Table>
  );
};

const ActivityAdminPage = (): JSX.Element => {
  const { activityId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: user } = useGetCurrentUserQuery();

  const { data: eventConfig } = useGetEventConfigQuery();

  const { content, error } = useActivitiesContent();
  useErrorHandler(error, 'CMS Error: Failed to load activities content');
  const activityContent = content?.find((c) => c.id === Number(activityId));
  const cmsEditUrl = addCmsBaseUrl(`/admin/content-manager/collection-types/api::activity.activity/${activityId ?? ''}`);

  const fetch = useGetActivityQuery(Number(activityId), { skip: activityId === 'new' });
  const [create] = useCreateActivityMutation();
  const [update] = useUpdateActivityMutation();
  const [remove] = useDeleteActivityMutation();

  const { data: activity } = fetch;

  const title = activity ? activity.name : 'Create Activity';
  const canCreate = userHasRole(user, ['Activities', 'Admin']);
  const canUpdate = userHasRole(user, ['Activities', 'Admin']);
  const canDelete = userHasRole(user, 'SuperUser');

  const onSuccessfulMutation = (): void => {
    // TODO: this is not working as expected when returning back to grid page post edit
    dispatch({
      type: `${publicApi.reducerPath}/invalidateTags`,
      payload: ['activities'],
    });
  };

  const onSuccessfulCreation = (newActivity: Activity): void => {
    onSuccessfulMutation();
    navigate(routes.activities.adminDetail(newActivity.id), { replace: true });
  };

  const onSuccessfulDeletion = (): void => {
    onSuccessfulMutation();
    navigate(routes.activities.grid, { replace: true });
  };

  return (
    <ContentLayout>
      <PageMetaTitle title={title} />
      <DataPanel
        title={title}
        titleTag="h1"
        modelName="Activity"
        fetch={fetch}
        prepareInitialValues={({ sessions, documents, ...data }) => ({
          ...data,
          targetLeaders: data.targetLeaders ?? 0,
          targetAdultLeaders: data.targetAdultLeaders ?? 0,
          targetParticipants: data.targetParticipants ?? 0,
          category: data.category ?? undefined,
          delivery: data.delivery ?? undefined,
        })}
        formikConfig={{
          validationSchema,
        }}
        initialMode={activityId === 'new' ? 'form' : 'display'}
        canCreate={canCreate}
        create={{
          mutation: create,
          initialValues: {
            name: '',
            targetLeaders: 0,
            targetAdultLeaders: 0,
            targetParticipants: 0,
            category: undefined,
            delivery: undefined,
          },
          onSuccess: onSuccessfulCreation,
        }}
        canUpdate={canUpdate}
        update={{
          mutation: (newValues) => update({ ...newValues, id: Number(activityId) }),
        }}
        canDelete={canDelete}
        deletion={{
          mutation: (a) => remove(a.id),
          dialogTitle: 'Delete Activity',
          dialogDescription: 'Are you sure you want to delete this activity?',
          onSuccess: onSuccessfulDeletion,
        }}
        LoadingComponent={<LoadingText lines={12} />}
        DisplayComponent={DisplayActivity}
        FormComponent={ActivityForm}
      />
      {activity && eventConfig && eventPhaseOrLater(eventConfig, 'Allocation') && (
        <DataPanelWrapper>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <div>
              <h2>Session Registers</h2>
            </div>
            <div>
              <RouterLink to={routes.activities.detail(activity.id, true)}>
                View public register (available via QR code on activity signs)
              </RouterLink>
            </div>
          </Stack>
          <ActivityRegister activityId={activity.id} type="protected" />
        </DataPanelWrapper>
      )}
      {activity && (
        <PossibleLeadersList activity={activity} />
      )}
      {activityContent && (
        <Table size="small" sx={{ marginBottom: '30px' }}>
          <TableBody>
            <TableKeyValueSectionHeader title="CMS Content" />
            <TableKeyValueRow name="Square Image">
              {activityContent?.square_image.data ? (
                <Image
                  src={addCmsBaseUrl(activityContent.square_image.data.attributes.url)}
                  alt={activityContent.square_image.data.attributes.alternativeText ?? undefined}
                  width={250}
                  height={250}
                />
              ) : (
                <RouterLink to={cmsEditUrl}>
                  Set in CMS
                </RouterLink>
              )}
            </TableKeyValueRow>
            <TableKeyValueRow name="Description">
              {activityContent?.description ? (
                <>
                  <BlocksRenderer content={activityContent.description} />
                  <RouterLink to={cmsEditUrl}>
                    Edit in CMS
                  </RouterLink>
                </>
              ) : (
                <RouterLink to={cmsEditUrl}>
                  Set in CMS
                </RouterLink>
              )}
            </TableKeyValueRow>
          </TableBody>
        </Table>
      )}
    </ContentLayout>
  );
};

export default ActivityAdminPage;

import {
  AlertTitle,
  Backdrop,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableRow,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertWithSmallBottomMargin } from '../../../components/Alert';
import ContentLayout from '../../../components/ContentLayout';
import DataPanel, { DataPanelDisplayProps } from '../../../components/DataPanel';
import EmailEventsPanel from '../../../components/EmailEventsPanel';
import LoadingText from '../../../components/LoadingText';
import PageMetaTitle from '../../../components/PageMetaTitle';
import RouterLink from '../../../components/RouterLink';
import TableCell from '../../../components/TableCell';
import TableKeyValueRow from '../../../components/TableKeyValueRow';
import TableKeyValueSectionHeader from '../../../components/TableKeyValueSectionHeader';
import { formatBooleanResponse } from '../../../helpers/boolean';
import { formatTimeRange } from '../../../helpers/datesStrings';
import { getFullName } from '../../../helpers/person';
import useErrorHandler from '../../../helpers/useErrorHandler';
import { userHasRole } from '../../../helpers/user';
import { ByPersonStatus, Pack, UpdatePackForm } from '../../../models/pack';
import { getWideningAccessStatusLabel } from '../../../models/wideningAccessStatus';
import routes from '../../../routes';
import { useGetEmailEventsByPackIdQuery } from '../../../state/protectedApi/emailEvents';
import { useDeletePackMutation, useGetPackQuery, useUpdatePackMutation } from '../../../state/protectedApi/packs';
import { useGetPeopleQuery } from '../../../state/protectedApi/people';
import { useGetCurrentUserQuery } from '../../../state/protectedApi/user';
import { useGetDistrictQuery, useGetRegionQuery } from '../../../state/publicApi/reference';
import ExcessiveRegistrationsAlert from './ExcessiveRegistrationsAlert';
import MissingRegistrationsAlert from './MissingRegistrationsAlert';
import PackForm from './PackForm';
import sanitiseOnSubmit from './PackForm/sanitise';
import PackPeopleGrid from './PackPeopleGrid';
import PackReviewTagsSummary from './PackReviewTagsSummary';

const formatTimeSlot = (start: string | null, end: string | null): string => {
  if (start === null || end === null) {
    return 'Not set';
  }
  return formatTimeRange(start, end);
};

const DisplayPack: FunctionComponent<DataPanelDisplayProps<Pack>> = (props) => {
  const { data } = props;

  const {
    data: region,
  } = useGetRegionQuery(data.regionId);
  const {
    data: district,
  } = useGetDistrictQuery(data.districtId);
  const {
    data: people,
    error: peopleError,
  } = useGetPeopleQuery({ packId: data.id });
  useErrorHandler(peopleError, 'API Error: Failed to load people for pack');

  const formUrl = window.location.origin + routes.registrations.start(data.publicId);
  const attendanceExpected = ['Accepted', 'Offered'].includes(data.bookingStatus);
  const hasRegistrationsStarted = data.registrationStatus === 'Open' || data.registrations.total > 0;
  const bookingContacts = people?.results.slice().filter((person) => person.packBookingContact);

  const registrationProgress = (status: keyof ByPersonStatus): string => {
    const booked = data.bookings[status];
    const registered = data.registrations[status];

    if (!hasRegistrationsStarted) {
      return `${booked}`;
    }
    if (booked === 0 && registered === 0) {
      return 'None';
    }
    return `${registered} / ${booked}`;
  };

  return (
    <Table size="small" sx={{ marginBottom: '30px' }}>
      <TableBody>
        <TableKeyValueRow name="Reference">{data.reference}</TableKeyValueRow>
        {!data.editionId && attendanceExpected && (
          <TableKeyValueRow name="Edition">Not set</TableKeyValueRow>
        )}
        <TableKeyValueRow name="Attended 2022">{formatBooleanResponse(data.attendedTwoYearsAgo)}</TableKeyValueRow>
        <TableKeyValueRow name="Attended 2023">{formatBooleanResponse(data.attendedLastYear)}</TableKeyValueRow>
        <TableKeyValueRow name="Widening access">{getWideningAccessStatusLabel(data.wideningAccess)}</TableKeyValueRow>
        {attendanceExpected && (
          <TableKeyValueRow name="Subcamp">{data.subcamp ?? 'Not set'}</TableKeyValueRow>
        )}
        <TableKeyValueRow name="Booking">{data.bookingStatus}</TableKeyValueRow>
        {attendanceExpected && (
          <>
            <TableKeyValueRow name="Registration">
              {data.registrationStatus === 'Open' ? (
                <Link href={formUrl} target="_blank" rel="noreferrer">
                  Open
                </Link>
              ) : data.registrationStatus}
            </TableKeyValueRow>
            <TableKeyValueRow name="Travel indication">{data.travelIndication ?? 'Not set'}</TableKeyValueRow>
            <TableKeyValueRow name="Travelling by car">
              {formatBooleanResponse(data.travellingByCar)}
            </TableKeyValueRow>
            <TableKeyValueRow name="Arrivals">{formatTimeSlot(data.arrivalStart, data.arrivalEnd)}</TableKeyValueRow>
            <TableKeyValueRow name="Departures">{formatTimeSlot(data.departureStart, data.departureEnd)}</TableKeyValueRow>
          </>
        )}

        <TableKeyValueSectionHeader title="Basic Details" />
        <TableKeyValueRow name="Booking contacts">
          {!bookingContacts || bookingContacts.length === 0 ? 'Not set' : bookingContacts?.map((contact) => (
            <div key={contact.id}>
              <RouterLink to={routes.people.detail(contact.id)}>{getFullName(contact)}</RouterLink>
              {contact.scoutingAppointment && `, ${contact.scoutingAppointment}`}
              {` (${contact.status})`}
            </div>
          ))}
        </TableKeyValueRow>
        {data.packName && (
          <TableKeyValueRow name="Pack">{data.packName}</TableKeyValueRow>
        )}
        <TableKeyValueRow name="Group">{data.groupName}</TableKeyValueRow>
        <TableKeyValueRow name="District">{district?.name}</TableKeyValueRow>
        <TableKeyValueRow name="Region">{region?.name}</TableKeyValueRow>
        <TableKeyValueRow name="Meeting Place">
          {data.addressStreet}

          {data.addressTown && <br />}
          {data.addressTown}

          {data.addressPostcode && <br />}
          {data.addressPostcode}
        </TableKeyValueRow>

        <TableKeyValueSectionHeader title={hasRegistrationsStarted ? 'Registrations' : 'Bookings'}>
          {data.excessive.total > 0 && (
            <ExcessiveRegistrationsAlert excessive={data.excessive} />
          )}
          {data.missing.total > 0 && (
            <MissingRegistrationsAlert missing={data.missing} />
          )}
          {data.missing.total === 0 && data.excessive.total === 0 && data.registrationStatus === 'Open' && (
            <AlertWithSmallBottomMargin severity="success">
              <AlertTitle>Registrations match bookings</AlertTitle>
              Perhaps we should close the registration form to prevent excessive registrations
            </AlertWithSmallBottomMargin>
          )}
        </TableKeyValueSectionHeader>
        {hasRegistrationsStarted && (
          <TableRow>
            <TableCell component="th" scope="col">
              <strong>Attendee type</strong>
            </TableCell>
            <TableCell component="th" scope="col" align="right">
              <strong>Registered / Booked</strong>
            </TableCell>
          </TableRow>
        )}
        <TableKeyValueRow
          name="Cubs"
          valueCellAlign="right"
        >
          {registrationProgress('cubs')}
        </TableKeyValueRow>
        <TableKeyValueRow
          name="Young Leaders"
          valueCellAlign="right"
        >
          {registrationProgress('youngLeaders')}
        </TableKeyValueRow>
        <TableKeyValueRow
          name="Leaders"
          valueCellAlign="right"
        >
          {registrationProgress('leaders')}
        </TableKeyValueRow>
        <TableKeyValueRow
          name="Other Children"
          valueCellAlign="right"
        >
          {registrationProgress('otherChildren')}
        </TableKeyValueRow>
      </TableBody>
    </Table>
  );
};

const PackPage = (): JSX.Element => {
  const { packId } = useParams();
  const packIdNumber = Number(packId);
  if (Number.isNaN(packIdNumber)) {
    throw new Error('Invalid pack ID');
  }

  const fetchPack = useGetPackQuery(packIdNumber);
  const {
    data: pack,
    error: packError,
  } = fetchPack;
  useErrorHandler(packError, 'API Error: Failed to load pack');

  const fetchEmailEvents = useGetEmailEventsByPackIdQuery(packIdNumber);

  const navigate = useNavigate();

  const [updatePack] = useUpdatePackMutation();
  const [deletePack] = useDeletePackMutation();

  const {
    data: user,
    error: userError,
  } = useGetCurrentUserQuery();
  useErrorHandler(userError, 'API Error: Failed to load user');
  const allowEditing = userHasRole(user, 'Admin');

  if (!pack || !user) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <ContentLayout>
      <PageMetaTitle title={pack.reference} />
      <DataPanel
        title={pack.name}
        titleTag="h1"
        modelName="pack"
        fetch={fetchPack}
        prepareInitialValues={(data): UpdatePackForm => {
          const {
            bookingContacts,
            excessive,
            registrations,
            missing,
            name,
            reference,
            publicId,
            bookings,
            longitude,
            latitude,
            ...update
          } = data;
          const { total, ...updateBookings } = bookings;

          return ({
            ...update,
            bookings: updateBookings,
            packName: data.packName ?? '',
            groupName: data.groupName ?? '',
            addressStreet: data.addressStreet ?? '',
            addressTown: data.addressTown ?? '',
            addressPostcode: data.addressPostcode ?? '',
          });
        }}
        sanitiseOnSubmit={sanitiseOnSubmit}
        canUpdate={allowEditing}
        update={{
          mutation: updatePack,
        }}
        canDelete={allowEditing}
        deletion={{
          mutation: () => deletePack(packIdNumber),
          dialogTitle: 'Delete pack',
          dialogDescription: 'Are you sure you want to delete this pack and all the people associated with it?',
          onSuccess: () => {
            navigate(routes.packs.grid);
          },
        }}
        LoadingComponent={<LoadingText lines={12} />}
        DisplayComponent={DisplayPack}
        FormComponent={PackForm}
      />
      <PackPeopleGrid packId={packIdNumber} />
      <PackReviewTagsSummary packId={packIdNumber} />
      <EmailEventsPanel fetch={fetchEmailEvents} />
    </ContentLayout>
  );
};

export default PackPage;

import BadgeIcon from '@mui/icons-material/Badge';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ContentLayout from '../../../components/ContentLayout';
import EmailEventsPanel from '../../../components/EmailEventsPanel';
import PageMetaTitle from '../../../components/PageMetaTitle';
import PersonStatusChip from '../../../components/PersonStatusChip';
import RouterLink from '../../../components/RouterLink';
import checkPersonPermissions from '../../../helpers/dataAction';
import { eventPhaseOrLater } from '../../../helpers/eventConfig';
import {
  getFullName,
  getIntroduction,
  isAdultInScouting,
  isAttending,
  isLeader,
} from '../../../helpers/person';
import useErrorHandler from '../../../helpers/useErrorHandler';
import { userHasRole } from '../../../helpers/user';
import routes from '../../../routes';
import { useGetEmailEventsByPersonIdQuery } from '../../../state/protectedApi/emailEvents';
import { useGetPackQuery } from '../../../state/protectedApi/packs';
import { useGetPersonQuery } from '../../../state/protectedApi/people';
import { useGetRegistrationQuery } from '../../../state/protectedApi/registration';
import { useGetStaffQuery } from '../../../state/protectedApi/staff';
import { useGetCurrentUserQuery } from '../../../state/protectedApi/user';
import { useGetEventConfigQuery } from '../../../state/publicApi';
import { useTheme } from '../../../theme';
import ActivityLeadershipPanel from './ActivityLeadershipPanel';
import ActivityPreferencesPanel from './ActivityPreferencesPanel';
import ActivitySupportPanel from './ActivitySupportPanel';
import ContactsPanel from './ContactsPanel';
import DeletePersonDialog from './DeletePersonDialog';
import DoctorPanel from './DoctorPanel';
import PersonPanel from './PersonPanel';
import RegistrationPanel from './RegistrationPanel';
import SchedulePanel from './SchedulePanel';
import StaffPanel from './StaffPanel';
import UserPanel from './UserPanel';
import * as Styled from './styles';

const PersonPage = (): JSX.Element => {
  const { personId } = useParams();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const theme = useTheme();
  const screenWiderThanMobile = useMediaQuery(theme.breakpoints.up('sm'));

  const { data: user } = useGetCurrentUserQuery();
  const allowEditing = userHasRole(user, 'Admin');

  const {
    currentData: person,
    error,
  } = useGetPersonQuery(Number(personId));
  useErrorHandler(error, 'API Error: Failed to load person');

  const {
    currentData: registration,
  } = useGetRegistrationQuery(Number(personId));
  const {
    currentData: staff,
  } = useGetStaffQuery(Number(personId));
  const {
    currentData: pack,
  } = useGetPackQuery(person?.packId ?? skipToken);
  const fetchEmailEvents = useGetEmailEventsByPersonIdQuery(
    Number(personId),
    { skip: person?.email === undefined },
  );

  const {
    data: eventConfig,
  } = useGetEventConfigQuery();

  const openDeleteDialog = (): void => {
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = (): void => {
    setDeleteDialogOpen(false);
  };

  const handelSuccessfulDeletion = (): void => {
    closeDeleteDialog();
    window.history.back();
  };

  if (person === undefined || eventConfig === undefined) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  // TODO: fix the table layouts for mobile displays
  return (
    <ContentLayout>
      <PageMetaTitle title={getFullName(person)} />
      <Styled.StackWithBottomMargin
        direction={screenWiderThanMobile ? 'row' : 'column'}
        spacing={2}
        justifyContent="space-between"
        alignItems={screenWiderThanMobile ? 'center' : ''}
      >
        <h1>{getFullName(person)}</h1>
        {/*  TODO: only show deletion button to users with enough permission to do so  */}
        <Stack direction="row" spacing={1}>
          {userHasRole(user, 'Admin') && isAttending(person) && eventPhaseOrLater(eventConfig, 'Allocation') && (
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<BadgeIcon />}
              onClick={() => window.open(routes.documents.lanyard(person.publicId))}
            >
              Lanyard
            </Button>
          )}
          {allowEditing && checkPersonPermissions('Delete', person, user) && (
            <Button
              color="error"
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={openDeleteDialog}
            >
              Delete
            </Button>
          )}
        </Stack>
      </Styled.StackWithBottomMargin>
      <DeletePersonDialog
        person={person}
        open={deleteDialogOpen}
        onSuccessfulDeletion={handelSuccessfulDeletion}
        onCancel={closeDeleteDialog}
      />
      <Styled.StackWithBottomMargin direction="row" spacing={3} alignItems="center">
        <PersonStatusChip status={person.status} />
        {registration && registration.mediaConsent === false && (
          <Chip label="No media" color="error" />
        )}
        {pack ? (
          <RouterLink to={routes.packs.detail(pack.id)}>
            {pack.reference}
            {': '}
            {pack.name}
          </RouterLink>
        ) : (
          <strong>{getIntroduction(staff, pack)}</strong>
        )}
      </Styled.StackWithBottomMargin>

      <PersonPanel personId={Number(personId)} user={user} />
      {isAttending(person)
        && (eventPhaseOrLater(eventConfig, 'Delivery')
          || userHasRole(user, ['Admin', 'Activities']))
        && person.editionIds
        && person.editionIds.map((editionId) => (
          <SchedulePanel
            key={editionId}
            person={person}
            user={user}
            editionId={editionId}
            multipleEditions={!!person.editionIds && person.editionIds.length > 1}
          />
        ))}
      {isAttending(person) && (
        <>
          <RegistrationPanel person={person} user={user} />
          <StaffPanel person={person} user={user} />
          <DoctorPanel person={person} user={user} />
          <ContactsPanel person={person} user={user} direction="contacts" />
          <ContactsPanel person={person} user={user} direction="relations" />
        </>
      )}
      {person.status === 'Contact' && (
        <ContactsPanel person={person} user={user} direction="relations" />
      )}
      {isAdultInScouting(person) && (
        <UserPanel person={person} currentUser={user} />
      )}
      {Boolean(person.packId) && (isLeader(person) || person.status === 'Cub') && (
        <ActivitySupportPanel person={person} user={user} />
      )}
      {isLeader(person) && Boolean(person.packId) && (
        <ActivityLeadershipPanel person={person} user={user} />
      )}
      {person.status === 'Cub' && (
        <ActivityPreferencesPanel person={person} user={user} />
      )}
      {person.email && (
        <EmailEventsPanel fetch={fetchEmailEvents} />
      )}
    </ContentLayout>
  );
};

export default PersonPage;

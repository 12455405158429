import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  targetParticipants: Yup.number().required('Target Participants is required')
    .positive('Value must be positive'),
  targetLeaders: Yup.number().required('Target Leaders is required')
    .positive('Value must be positive'),
  targetAdultLeaders: Yup.number().required('Target Adult Leaders is required')
    .positive('Value must be positive')
    .max(Yup.ref('targetLeaders'), 'Adults cannot exceed leaders'),
  category: Yup.string().required('Category is required'),
  delivery: Yup.string().required('Delivery is required'),
});

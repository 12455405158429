export const minGridColumnsWidth = 2;

export const gutterPx = 10;

export const columnWidthPx = 150;

export const rowHeightPx = 150;

export const getWidthPx = (
  columns: number,
): number => (gutterPx * (columns - 1)) + (columns * columnWidthPx);

export const getHeightPx = (
  rows: number,
): number => (gutterPx * (rows - 1)) + (rows * rowHeightPx);

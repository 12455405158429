import { createBrowserRouter } from 'react-router-dom';
import ErrorCard from './components/ErrorCard';
import PublicPage from './components/PublicLayout';
import NotFoundErrorPage from './pages/404';
import ActivitiesPage from './pages/ActivitiesPage';
import ActivityPage from './pages/ActivityPage';
import Admin from './pages/Admin';
import ActivitiesGridPage from './pages/Admin/ActivitiesGridPage';
import ActivityAdminPage from './pages/Admin/ActivityAdminPage';
import ActivityAllocationPage from './pages/Admin/ActivityAllocationPage';
import ChangePasswordPage from './pages/Admin/ChangePasswordPage';
import DashboardPage from './pages/Admin/DashboardPage';
import DutiesGridPage from './pages/Admin/DutiesGridPage';
import DutyDetailsPage from './pages/Admin/DutyDetailsPage';
import EmailEventsGridPage from './pages/Admin/EmailEventsGrid';
import MealAdminPage from './pages/Admin/MealAdminPage';
import MealsGridPage from './pages/Admin/MealsGridPage';
import PackPage from './pages/Admin/PackPage';
import PacksGridPage from './pages/Admin/PacksGridPage';
import PeopleComparePage from './pages/Admin/PeopleComparePage';
import PeopleGridPage from './pages/Admin/PeopleGridPage';
import PersonPage from './pages/Admin/PersonPage';
import PersonReviewPage from './pages/Admin/PersonReviewPage';
import ReviewTagSummary from './pages/Admin/ReviewTagSummary';
import SessionDetailsPage from './pages/Admin/SessionDetailsPage';
import SessionsGridPage from './pages/Admin/SessionsGridPage';
import Arrivals from './pages/Arrivals';
import Bookings from './pages/Bookings';
import BookingAddressFormPage from './pages/Bookings/BookingAddressFormPage';
import BookingConfirmationPage from './pages/Bookings/BookingConfirmationPage';
import BookingContactFormPage from './pages/Bookings/BookingContactFormPage';
import BookingEditionsFormPage from './pages/Bookings/BookingEditionsFormPage';
import BookingEstimatesFormPage from './pages/Bookings/BookingEstimatesFormPage';
import BookingGroupFormPage from './pages/Bookings/BookingGroupFormPage';
import BookingReviewPage from './pages/Bookings/BookingReviewPage';
import BookingStartPage from './pages/Bookings/BookingStartPage';
import BookingTravelFormPage from './pages/Bookings/BookingTravelFormPage';
import ErrorPage from './pages/ErrorPage';
import FaqPage from './pages/FaqPage';
import ForgottenPasswordPage from './pages/ForgottenPasswordPage';
import FormLayoutWrapper from './pages/FormLayoutWrapper';
import HomePage from './pages/HomePage';
import LoginPage from './pages/Login';
import MealCheckIn from './pages/MealCheckIn';
import Registrations from './pages/Registrations';
import RegistrationActivitiesFormPage from './pages/Registrations/RegistrationActivitiesFormPage';
import RegistrationAdditionalInformationFormPage
  from './pages/Registrations/RegistrationAdditionalInformationFormPage';
import RegistrationAttendeeFormPage from './pages/Registrations/RegistrationAttendeeFormPage';
import RegistrationCateringFormPage from './pages/Registrations/RegistrationCateringFormPage';
import RegistrationConfirmationPage from './pages/Registrations/RegistrationConfirmationPage';
import RegistrationContactFormPage from './pages/Registrations/RegistrationContactFormPage';
import RegistrationDoctorFormPage from './pages/Registrations/RegistrationDoctorFormPage';
import RegistrationEmergencyContactsFormPage
  from './pages/Registrations/RegistrationEmergencyContactsFormPage';
import RegistrationFirstAidFormPage from './pages/Registrations/RegistrationFirstAidFormPage';
import RegistrationMediaFormPage from './pages/Registrations/RegistrationMediaFormPage';
import RegistrationReviewPage from './pages/Registrations/RegistrationReviewPage';
import RegistrationStartPage from './pages/Registrations/RegistrationStartPage';
import SignUpPage from './pages/SignUpPage';
import Reset from './pages/reset';
import routes from './routes';
import { personIdPlaceholder } from './routes/personRoutes';
import { registrationCodePlaceholder } from './routes/registrationsRoutes';

const contentOnlyMode = process.env.REACT_APP_CMS_CONTENT_ONLY === 'true';

const router = createBrowserRouter([
  {
    path: routes.reset,
    element: <Reset />,
  },
  ...!contentOnlyMode ? [
    {
      path: routes.login,
      element: <LoginPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: routes.forgotPassword,
      element: <ForgottenPasswordPage />,
      errorElement: <ErrorPage />,
    },
    {
      element: <Admin />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: routes.dashboard,
          element: <DashboardPage />,
        },
        {
          path: routes.people.detail(personIdPlaceholder),
          element: <PersonPage />,
        },
        {
          path: routes.people.compare,
          element: <PeopleComparePage />,
        },
        {
          path: routes.packs.detail(':packId'),
          element: <PackPage />,
        },
        {
          path: routes.reviews,
          element: <PersonReviewPage />,
        },
        {
          path: routes.reviewTagSummary,
          element: <ReviewTagSummary />,
        },
        {
          path: routes.activities.sessions.detail(':sessionId'),
          element: <SessionDetailsPage />,
        },
        {
          path: routes.changePassword,
          element: <ChangePasswordPage />,
        },
        {
          path: routes.activities.adminDetail(':activityId'),
          element: <ActivityAdminPage />,
        },
        {
          path: routes.duties.detail(':dutyId'),
          element: <DutyDetailsPage />,
        },
        {
          path: routes.meals.adminDetail(':mealId'),
          element: <MealAdminPage />,
        },
      ],
    },
    {
      element: <Admin limitContentHeight />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: routes.packs.grid,
          element: <PacksGridPage />,
        },
        {
          path: routes.people.grid,
          element: <PeopleGridPage />,
        },
        {
          path: routes.activities.grid,
          element: <ActivitiesGridPage />,
        },
        {
          path: routes.activities.allocations,
          element: <ActivityAllocationPage />,
        },
        {
          path: routes.duties.grid,
          element: <DutiesGridPage />,
        },
        {
          path: routes.meals.grid,
          element: <MealsGridPage />,
        },
        {
          path: routes.emails.grid,
          element: <EmailEventsGridPage />,
        },
        {
          path: routes.activities.sessions.grid,
          element: <SessionsGridPage />,
        },
      ],
    },
    {
      element: <FormLayoutWrapper />,
      children: [
        {
          errorElement: <ErrorCard />,
          children: [
            {
              path: routes.signUp,
              element: <SignUpPage />,
            },
            {
              element: <Bookings />,
              children: [
                {
                  path: routes.bookings.start,
                  element: <BookingStartPage />,
                },
                {
                  path: routes.bookings.group,
                  element: <BookingGroupFormPage />,
                },
                {
                  path: routes.bookings.contact,
                  element: <BookingContactFormPage />,
                },
                {
                  path: routes.bookings.address,
                  element: <BookingAddressFormPage />,
                },
                {
                  path: routes.bookings.estimates,
                  element: <BookingEstimatesFormPage />,
                },
                {
                  path: routes.bookings.travel,
                  element: <BookingTravelFormPage />,
                },
                {
                  path: routes.bookings.editions,
                  element: <BookingEditionsFormPage />,
                },
                {
                  path: routes.bookings.review,
                  element: <BookingReviewPage />,
                },
                {
                  path: routes.bookings.confirmation,
                  element: <BookingConfirmationPage />,
                },
              ],
            },
            {
              element: <Registrations />,
              children: [
                {
                  path: routes.registrations.start(registrationCodePlaceholder),
                  element: <RegistrationStartPage />,
                },
                {
                  path: routes.registrations.contact(registrationCodePlaceholder),
                  element: <RegistrationContactFormPage />,
                },
                {
                  path: routes.registrations.attendee(registrationCodePlaceholder),
                  element: <RegistrationAttendeeFormPage />,
                },
                {
                  path: routes.registrations.media(registrationCodePlaceholder),
                  element: <RegistrationMediaFormPage />,
                },
                {
                  path: routes.registrations.additionalInformation(registrationCodePlaceholder),
                  element: <RegistrationAdditionalInformationFormPage />,
                },
                {
                  path: routes.registrations.firstAid(registrationCodePlaceholder),
                  element: <RegistrationFirstAidFormPage />,
                },
                {
                  path: routes.registrations.catering(registrationCodePlaceholder),
                  element: <RegistrationCateringFormPage />,
                },
                {
                  path: routes.registrations.emergencyContacts(registrationCodePlaceholder),
                  element: <RegistrationEmergencyContactsFormPage />,
                },
                {
                  path: routes.registrations.doctor(registrationCodePlaceholder),
                  element: <RegistrationDoctorFormPage />,
                },
                {
                  path: routes.registrations.activities(registrationCodePlaceholder),
                  element: <RegistrationActivitiesFormPage />,
                },
                {
                  path: routes.registrations.review(registrationCodePlaceholder),
                  element: <RegistrationReviewPage />,
                },
                {
                  path: routes.registrations.confirmation(registrationCodePlaceholder),
                  element: <RegistrationConfirmationPage />,
                },
              ],
            },
            {
              path: routes.arrivals,
              element: <Arrivals />,
            },
            {
              path: routes.activities.detail(':activityId'),
              element: <ActivityPage />,
            },
            {
              path: routes.meals.checkIn(),
              element: <MealCheckIn />,
            },
          ],
        },
      ],
    },
  ] : [] as const,
  {
    element: <PublicPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: routes.home,
        element: <HomePage />,
      },
      {
        path: routes.activities.public,
        element: <ActivitiesPage />,
      },
      {
        path: routes.faq,
        element: <FaqPage />,
      },
      {
        path: '*',
        element: <NotFoundErrorPage />,
      },
    ],
  },
]);

export default router;

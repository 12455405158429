import { NewBooking } from '../../../state/booking';

const sanitiseOnSubmit = (rawValues: NewBooking): NewBooking => {
  const contact = {
    ...rawValues.contact,
    firstName: rawValues.contact.firstName.trim(),
    knownAs: rawValues.contact.knownAs.trim(),
    lastName: rawValues.contact.lastName.trim(),
    scoutingAppointment: rawValues.contact.scoutingAppointment.trim(),
    mobile: rawValues.contact.mobile.trim(),
  };

  return { ...rawValues, contact };
};

export default sanitiseOnSubmit;

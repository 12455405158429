export const coerceToDate = (
  value: string | Date,
): Date => (value instanceof Date ? value : new Date(value));

export const addDays = (original: Date, days: number): Date => {
  const date = new Date(original);
  date.setUTCDate(original.getUTCDate() + days);
  return date;
};

export const addYears = (original: Date, years: number): Date => {
  const date = new Date(original);
  date.setFullYear(original.getUTCFullYear() + years);
  return date;
};

export const subtractDays = (original: Date, days: number): Date => addDays(original, -days);
export const subtractYears = (original: Date, years: number): Date => addYears(original, -years);

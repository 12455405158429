import {
  EmailAuthProvider,
  reauthenticateWithCredential,
} from '@firebase/auth';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button, Collapse, IconButton, InputAdornment, TextField,
} from '@mui/material';
import { updatePassword } from 'firebase/auth';
import { enqueueSnackbar } from 'notistack';
import { MouseEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertWithBottomMargin } from '../../../components/Alert';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import { FormGrid } from '../../../components/FormGrid';
import IconLink from '../../../components/IconLink';
import PageMetaTitle from '../../../components/PageMetaTitle';
import { auth } from '../../../firebase';
import { useTypeSafeFormik } from '../../../helpers/form';
import routes from '../../../routes';
import { validationSchema } from './schema';

const ChangePasswordPage = (): JSX.Element => {
  const [changePasswordError, setChangePasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const firebaseUser = auth.currentUser;
  const username = firebaseUser?.providerData
    .find((provider) => provider.providerId === 'password')?.uid;

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    setSubmitting,
  } = useTypeSafeFormik({
    initialValues: {
      current: '',
      new: '',
      confirm: '',
    },
    validationSchema,
    onSubmit: async (submittedValues) => {
      setChangePasswordError(false);
      if (firebaseUser && username) {
        await reauthenticateWithCredential(
          firebaseUser,
          EmailAuthProvider.credential(username, submittedValues.current),
        );
        await updatePassword(firebaseUser, submittedValues.new)
          .then(() => {
            if (location.key === 'default') {
              navigate(routes.dashboard);
            } else {
              navigate(-1);
            }
            enqueueSnackbar(
              'Password successfully changed',
              {
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'success',
              },
            );
          })
          .catch(() => {
            setChangePasswordError(true);
          }).finally(() => { setSubmitting(false); });
      }
    },
  });

  const showPasswordButton = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setShowPassword(!showPassword)}
        onMouseDown={(event: MouseEvent<HTMLButtonElement>) => event.preventDefault()}
        edge="end"
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <Card>
      <PageMetaTitle title="Change Password" />
      <CardHeading>Change your password</CardHeading>
      <Collapse in={changePasswordError}>
        <AlertWithBottomMargin severity="error">
          We were unable to change your password. Please check your current password then try again.
        </AlertWithBottomMargin>
      </Collapse>
      <form onSubmit={handleSubmit}>
        <FormGrid>
          <TextField
            label="Current password"
            id="current-password"
            autoComplete="current-password"
            value={values.current}
            onBlur={() => setFieldTouched('current')}
            onChange={async (event) => {
              await setFieldValue('current', event.target.value);
            }}
            error={touched.current && Boolean(errors.current)}
            helperText={(touched.current && errors.current) || ' '}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: showPasswordButton,
            }}
          />
          <TextField
            label="New password"
            id="new-password"
            autoComplete="new-password"
            value={values.new}
            onBlur={() => setFieldTouched('new')}
            onChange={async (event) => {
              await setFieldValue('new', event.target.value);
            }}
            error={touched.new && Boolean(errors.new)}
            helperText={(touched.new && errors.new) || ' '}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: showPasswordButton,
            }}
          />
          <TextField
            label="Confirm password"
            id="confirm-password"
            autoComplete="confirm-password"
            value={values.confirm}
            onBlur={() => setFieldTouched('confirm')}
            onChange={async (event) => {
              await setFieldValue('confirm', event.target.value);
            }}
            error={touched.confirm && Boolean(errors.confirm)}
            helperText={(touched.confirm && errors.confirm) || ' '}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: showPasswordButton,
            }}
          />
        </FormGrid>
        <CardFooter>
          {location.key !== 'default' && (
            <IconLink icon="Back">
              Back
            </IconLink>
          )}
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Change password
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
};

export default ChangePasswordPage;

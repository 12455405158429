import {
  Autocomplete, Backdrop, Button, CircularProgress, TextField,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import { FormGrid } from '../../../components/FormGrid';
import { useFormikWithStateSync } from '../../../helpers/form';
import useErrorHandler from '../../../helpers/useErrorHandler';
import routes from '../../../routes';
import { selectBooking, update } from '../../../state/booking';
import { useGetDistrictsByRegionQuery, useGetRegionsQuery } from '../../../state/publicApi/reference';
import sanitiseOnSubmit from './sanitise';
import { validationSchema } from './schema';

const BookingGroupFormPage: () => JSX.Element = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormikWithStateSync(
    selectBooking,
    update,
    {
      validationSchema,
      onSubmit: async () => {
        navigate(routes.bookings.contact);
      },
    },
    sanitiseOnSubmit,
  );

  const {
    data: regions,
    isLoading: regionsLoading,
    error: regionsError,
  } = useGetRegionsQuery();
  useErrorHandler(regionsError, 'API Error: Failed to fetch regions');

  const {
    currentData: districts,
    isLoading: districtsLoading,
    error: districtsError,
  } = useGetDistrictsByRegionQuery(values.meta.region?.id ?? skipToken);
  useErrorHandler(districtsError, 'API Error: Failed to fetch districts');

  return (
    <Card>
      <form onSubmit={handleSubmit} noValidate>
        <CardHeading>Your pack</CardHeading>
        <FormGrid columns={2}>
          <Autocomplete
            options={regions ?? []}
            loading={regionsLoading}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Region"
                error={touched.meta?.region && Boolean(errors.meta?.region)}
                helperText={(touched.meta?.region && errors.meta?.region) || ' '}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {regionsLoading ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            value={values.meta.region}
            onChange={async (event, value) => {
              await setFieldValue('meta.district', null);
              await setFieldValue('meta.region', value);
            }}
            onBlur={() => setFieldTouched('meta.region')}
          />
          <Autocomplete
            options={districts ?? []}
            loading={districtsLoading}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="District"
                error={touched.meta?.district && Boolean(errors.meta?.district)}
                helperText={
                  (touched.meta?.district && errors.meta?.district)
                  || (!values.meta.region && 'Select a region first')
                  || ' '
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {districtsLoading ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            value={values.meta.district}
            onChange={(event, value) => setFieldValue('meta.district', value)}
            onBlur={() => setFieldTouched('meta.district')}
            disabled={values.meta?.region === null}
          />
          <TextField
            label="Group name"
            value={values.pack.groupName}
            onBlur={() => setFieldTouched('pack.groupName')}
            onChange={async (event) => {
              await setFieldValue('pack.groupName', event.target.value);
            }}
            error={touched.pack?.groupName && Boolean(errors.pack?.groupName)}
            helperText={(touched.pack?.groupName && errors.pack?.groupName) || ' '}
          />
          <TextField
            label="Pack name"
            value={values.pack.packName}
            onBlur={() => setFieldTouched('pack.packName')}
            onChange={async (event) => {
              await setFieldValue('pack.packName', event.target.value);
            }}
            error={touched.pack?.packName && Boolean(errors.pack?.packName)}
            helperText={(touched.pack?.packName && errors.pack?.packName)
              || 'Only required if your group has more than one pack'}
          />
        </FormGrid>

        <h4>Pack Meeting Place</h4>
        <TextField
          label="Street address"
          value={values.pack.addressStreet}
          onBlur={() => setFieldTouched('pack.addressStreet')}
          onChange={async (event) => {
            await setFieldValue('pack.addressStreet', event.target.value);
          }}
          error={touched.pack?.addressStreet && Boolean(errors.pack?.addressStreet)}
          helperText={(touched.pack?.addressStreet && errors.pack?.addressStreet) || ' '}
          multiline
          minRows={3}
        />
        <FormGrid columns={2}>
          <TextField
            label="Town"
            value={values.pack.addressTown}
            onBlur={() => setFieldTouched('pack.addressTown')}
            onChange={async (event) => {
              await setFieldValue('pack.addressTown', event.target.value);
            }}
            error={touched.pack?.addressTown && Boolean(errors.pack?.addressTown)}
            helperText={(touched.pack?.addressTown && errors.pack?.addressTown) || ' '}
          />
          <TextField
            label="Postcode"
            value={values.pack.addressPostcode}
            onBlur={() => setFieldTouched('pack.addressPostcode')}
            onChange={async (event) => {
              await setFieldValue('pack.addressPostcode', event.target.value);
            }}
            error={touched.pack?.addressPostcode && Boolean(errors.pack?.addressPostcode)}
            helperText={(touched.pack?.addressPostcode && errors.pack?.addressPostcode) || ' '}
          />
        </FormGrid>
        <CardFooter>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Next
          </Button>
        </CardFooter>
      </form>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </Card>
  );
};

export default BookingGroupFormPage;

import { trimmedStringOrNull } from '../../../../helpers/sanitiseStrings';
import { UpdatePackForm } from '../../../../models/pack';

const sanitiseOnSubmit = (rawValues: UpdatePackForm): UpdatePackForm => ({
  ...rawValues,
  groupName: rawValues.groupName.trim(),
  packName: trimmedStringOrNull(rawValues.packName),
  travelIndication: trimmedStringOrNull(rawValues.travelIndication),
  addressStreet: trimmedStringOrNull(rawValues.addressStreet),
  addressTown: trimmedStringOrNull(rawValues.addressTown),
  addressPostcode: trimmedStringOrNull(rawValues.addressPostcode),
});

export default sanitiseOnSubmit;

import { OptionList } from '../helpers/options';

const wideningAccessStatuses = [
  'DidNotApply',
  'Pending',
  'NotEligible',
  'Offered',
  'Unsuccessful',
  'Accepted',
] as const;

export type WideningAccessStatus = (typeof wideningAccessStatuses)[number];

export const isValidWideningAccessStatus = (
  value: string,
): value is WideningAccessStatus => wideningAccessStatuses.includes(value as never);

export const getWideningAccessStatusLabel = (status: WideningAccessStatus): string => {
  switch (status) {
    case 'DidNotApply':
      return 'Did not apply';
    case 'NotEligible':
      return 'Not eligible';
    default:
      return status;
  }
};

export const wideningAccessStatusOptions: OptionList<
WideningAccessStatus
> = wideningAccessStatuses.map(
  (status) => ({
    label: getWideningAccessStatusLabel(status),
    value: status,
  }),
);

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import RouterLink from '../../../components/RouterLink';
import { getRelativeDayOrDayName, getShortTime } from '../../../helpers/datesStrings';
import { Meal } from '../../../models/meal';
import { mealStatusOptions } from '../../../models/mealStatus';
import routes from '../../../routes';

const alignCenter = {
  align: 'center',
  headerAlign: 'center',
} as const;

export const columns: GridColDef<Meal>[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 100,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
    renderCell: (params: GridRenderCellParams<Meal, string>) => (
      <RouterLink to={routes.meals.adminDetail(params.row.id)}>{params.row.name}</RouterLink>
    ),
  },
  {
    field: 'location',
    headerName: 'Location',
    width: 150,
  },
  {
    field: 'day',
    headerName: 'Day',
    type: 'dateTime',
    width: 120,
    valueGetter: (value, row: Meal) => new Date(row.start),
    valueFormatter: (value: Date) => getRelativeDayOrDayName(value),
    ...alignCenter,
  },
  {
    field: 'start',
    headerName: 'Start',
    type: 'dateTime',
    width: 110,
    valueGetter: (value, row: Meal) => new Date(row.start),
    valueFormatter: (value: Date) => getShortTime(value),
    ...alignCenter,
  },
  {
    field: 'end',
    headerName: 'End',
    type: 'dateTime',
    width: 110,
    valueGetter: (value, row: Meal) => new Date(row.end),
    valueFormatter: (value: Date) => getShortTime(value),
    ...alignCenter,
  },
  {
    field: 'status',
    headerName: 'Check in',
    width: 100,
    type: 'singleSelect',
    valueOptions: mealStatusOptions,
    ...alignCenter,
  },
];

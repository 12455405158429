import { objectToCamel } from 'ts-case-convert';
import { ObjectToSnake, objectToSnake } from 'ts-case-convert/lib/caseConvert';
import { ActivityPreference } from '../../models/activityPreference';
import { protectedApi } from './index';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getActivityPreferences: builder.query<ActivityPreference[], number>({
      query: (personId) => `activities/preferences/${personId}`,
      transformResponse: (response: ObjectToSnake<ActivityPreference[]>) => objectToCamel(response),
    }),
    updateActivityPreferences: builder.mutation<ActivityPreference[], {
      personId: number, data: ActivityPreference[]
    }>({
      query: (args) => {
        const { personId, data } = args;
        const body = objectToSnake(data);
        return ({
          url: `activities/preferences/${personId}`,
          method: 'PATCH',
          body,
        });
      },
    }),
    deleteActivityPreferences: builder.mutation<void, number>({
      query: (personId) => ({
        url: `activities/preferences/${personId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetActivityPreferencesQuery,
  useUpdateActivityPreferencesMutation,
  useDeleteActivityPreferencesMutation,
} = extendedApi;

import styled from 'styled-components';
import { getHeightPx } from '../Dashboard/config';

type CardStyleProps = {
  columns?: number
  rows?: number
};

const DashboardCard = styled.div<CardStyleProps>`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => (theme.palette.mode === 'light' ? theme.palette.grey.A200 : theme.palette.grey[800])};
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  
  height: ${({ rows }) => getHeightPx(rows ?? 1)}px;

  grid-row: span ${({ rows }) => rows ?? 1};
  grid-column: span ${({ columns }) => columns ?? 1};
`;

export default DashboardCard;

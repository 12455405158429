import LoginFormCard from '../../components/AuthenticatedScope/LoginFormCard';
import FormLayout from '../../components/FormLayout';

const LoginPage = (): JSX.Element => (
  <FormLayout>
    <LoginFormCard />
  </FormLayout>
);

export default LoginPage;

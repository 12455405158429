import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import apiService from '../apiService';
import type { RegistrationRequest, RegistrationResponse } from './index';

type RegistrationsClient = {
  create: (
    code: string,
    data: ObjectToSnake<RegistrationRequest>,
  ) => Promise<ObjectToSnake<RegistrationResponse>>;
};

const registrationsClient: RegistrationsClient = {
  create: async (code, data) => apiService.post(`new-registrations/${code}`, data),
};

export default registrationsClient;

import { Skeleton, Stack } from '@mui/material';
import { FC, useState } from 'react';
import DataPanelWrapper from '../../../../components/DataPanel/DataPanelWrapper';
import SuccessAlert from '../../../../components/DataPanel/SuccessAlert';
import DeleteDialog from '../../../../components/DeleteDialog';
import { MutateActionType } from '../../../../helpers/actionType';
import checkPersonPermissions from '../../../../helpers/dataAction';
import { Person } from '../../../../models/person';
import { User } from '../../../../models/user';
import {
  useDeleteActivityLeadershipsForPersonMutation,
  useGetActivityLeadershipsForPersonQuery,
} from '../../../../state/protectedApi/activityLeadership';
import ActivityLeadershipFormDialog from './ActivityLeadershipFormDialog';
import ActivityLeadershipList from './ActivityLeadershipList';

type ActivityLeadershipPanelProps = {
  person: Person
  user: User | undefined
};

const ActivityLeadershipPanel: FC<ActivityLeadershipPanelProps> = (props) => {
  const { person, user } = props;

  const canUpdateLeadership = checkPersonPermissions('Update', person, user);
  const [mutateSuccess, setMutateSuccess] = useState<MutateActionType>();

  const {
    currentData: data,
    isLoading,
    isError,
    refetch,
  } = useGetActivityLeadershipsForPersonQuery(person.id);
  const [deleteActivityLeadership] = useDeleteActivityLeadershipsForPersonMutation();

  const resetMutateTracking = (): void => {
    setMutateSuccess(undefined);
  };

  const onSuccess = (type: MutateActionType) => async (): Promise<void> => {
    setMutateSuccess(type);
    await refetch();
  };

  if (isError) {
    return null;
  }

  return (
    <DataPanelWrapper>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <h3>Activity Leadership</h3>
        {canUpdateLeadership && !isLoading && (
          <Stack direction="row-reverse" spacing={3}>
            {data && data.some((leadership) => leadership.canLead !== null) && (
              <DeleteDialog
                title="Delete activity leadership"
                description="Are you sure you want to delete all activity leadership records?"
                modelName="activity leaderships"
                data={{}}
                onOpen={resetMutateTracking}
                onDelete={() => deleteActivityLeadership(person.id)}
                onSuccess={onSuccess('delete')}
              />
            )}
            {data && (
              <ActivityLeadershipFormDialog
                personId={person.id}
                activityLeadership={data}
                onOpen={resetMutateTracking}
                onSuccess={onSuccess('update')}
              />
            )}
          </Stack>
        )}
      </Stack>

      {mutateSuccess && (
        <SuccessAlert
          modelName="Activity Leadership"
          type={mutateSuccess}
        />
      )}

      {isLoading && (
        <div>
          <Skeleton variant="rectangular" height={200} sx={{ marginBottom: '12px' }} />
          <Skeleton variant="rectangular" height={100} />
        </div>
      )}

      {data && (
        <>
          <ActivityLeadershipList activityLeadership={data} filter="canLead" />
          <ActivityLeadershipList activityLeadership={data} filter="notSet" />
          <ActivityLeadershipList activityLeadership={data} filter="wontLead" />
        </>
      )}
    </DataPanelWrapper>
  );
};

export default ActivityLeadershipPanel;

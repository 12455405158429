import { OptionList } from '../helpers/options';

export const activityCategories = [
  'Adventure',
  'Bushcraft',
  'Creative',
  'Games',
] as const;

export type ActivityCategory = typeof activityCategories[number];

export const isValidActivityCategory = (
  category: string,
): category is ActivityCategory => activityCategories.includes(category as never);

export const activityCategoryOptions: OptionList<ActivityCategory> = activityCategories.map(
  (category) => ({
    label: category,
    value: category,
  }),
);

import { Outlet } from 'react-router-dom';
import AuthenticatedScope from '../../components/AuthenticatedScope';
import PageLayout from '../../components/PageLayout';
import { useScrollToTopOnNavigation } from '../../helpers/windowScroll';

type AdminProps = {
  limitContentHeight?: boolean
};

const Admin = (props: AdminProps): JSX.Element => {
  const { limitContentHeight } = props;

  useScrollToTopOnNavigation();

  return (
    <AuthenticatedScope>
      <PageLayout limitContentHeight={limitContentHeight}>
        <Outlet />
      </PageLayout>
    </AuthenticatedScope>
  );
};

export default Admin;

import {
  GridColumnVisibilityModel, GridDensity, GridFilterModel, GridPinnedColumnFields, GridSortModel,
} from '@mui/x-data-grid-pro';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GridState, selectGrids, update } from './index';

interface GridStateProps extends GridState {
  onEditionIdChange: (editionId: number | undefined) => void
  onColumnVisibilityModelChange: (model: GridColumnVisibilityModel) => void
  onDensityChange: (model: GridDensity) => void
  onFilterModelChange: (model: GridFilterModel) => void
  onSortModelChange: (model: GridSortModel) => void
  onPinnedColumnsChange: (model: GridPinnedColumnFields) => void
}

const useGridStateProps = (key: string): GridStateProps => {
  const dispatch = useDispatch();
  const gridsState = useSelector(selectGrids);

  const stateProps = gridsState.items.find((grid) => grid.key === key);

  const updateForKey = useCallback(
    (payload: GridState): void => {
      const newItems = stateProps === undefined ? [
        ...gridsState.items,
        { key, ...payload },
      ] : gridsState.items.map(
        (item) => (item.key === key
          ? { ...item, ...payload } : item),
      );
      dispatch(update(newItems));
    },
    [stateProps, gridsState.items, key, dispatch],
  );

  return {
    ...stateProps,
    // TODO add pagination model
    onEditionIdChange: (model) => updateForKey({ editionId: model }),
    onColumnVisibilityModelChange: (model) => updateForKey({ columnVisibilityModel: model }),
    onDensityChange: (model) => updateForKey({ density: model }),
    onFilterModelChange: (model) => updateForKey({ filterModel: model }),
    onSortModelChange: (model) => updateForKey({ sortModel: model }),
    onPinnedColumnsChange: (model) => updateForKey({ pinnedColumns: model }),
  };
};

export default useGridStateProps;

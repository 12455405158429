import { QrcodeSuccessCallback } from 'html5-qrcode';
import { FC, useState } from 'react';
import QrScanner from '../../QrScanner';

type PersonPublicIdScannerProps = {
  onScan: (payload: { publicId: string }) => void;
};

const PersonPublicIdScanner: FC<PersonPublicIdScannerProps> = (props) => {
  const { onScan } = props;

  const [last, setLast] = useState<string | null>(null);

  const onScanSuccess: QrcodeSuccessCallback = (decodedText) => {
    const url = new URL(decodedText);
    const publicId = url.searchParams.get('person_public_id');
    if (publicId && publicId !== last) {
      onScan({ publicId });
      setLast(publicId);
    }
  };

  return (
    <QrScanner onSuccess={onScanSuccess} />
  );
};

export default PersonPublicIdScanner;

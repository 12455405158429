import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TableBody,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BlocksRenderer from '../../../components/BlocksRenderer';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import CardIntroduction from '../../../components/CardIntroduction';
import { FormGrid } from '../../../components/FormGrid';
import IconLink from '../../../components/IconLink';
import Table from '../../../components/Table';
import TableKeyValueRow from '../../../components/TableKeyValueRow';
import { useRegistrationsContent } from '../../../helpers/content';
import { useFormikWithStateSync } from '../../../helpers/form';
import { isParticipant } from '../../../helpers/person';
import routes from '../../../routes';
import { useRegistrationCodeOrError } from '../../../routes/registrationsRoutes';
import { useGetMealsQuery } from '../../../state/publicApi/meals';
import { selectRegistration, update } from '../../../state/registration';
import { validationSchema } from './schema';

const RegistrationCateringFormPage = (): JSX.Element => {
  const { content } = useRegistrationsContent();
  const { data: allMeals } = useGetMealsQuery();
  const registrationCode = useRegistrationCodeOrError();
  const navigate = useNavigate();

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormikWithStateSync(
    selectRegistration,
    update,
    {
      validationSchema,
      onSubmit: async () => {
        navigate(routes.registrations.emergencyContacts(registrationCode));
      },
    },
  );

  if (!content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  // TODO separate meals from editions
  const meals = allMeals?.slice()
    .filter((meal) => meal.editionId === 1);

  const backRoute = values.meta.registeringSelf
    ? routes.registrations.additionalInformation : routes.registrations.firstAid;

  const personStatus = values.attendee.status;

  // TODO: Move the sandwich options back into the CMS
  const sandwichOptions = [
    'Cheese',
    'Egg mayo',
    'Ham',
    'Tuna mayo',
    ...personStatus && !isParticipant({ status: personStatus })
      ? [
        'Coronation chicken',
        'Hummus and falafel veggie wrap',
      ] : [
        'Seedless strawberry jam',
      ],
  ].sort();

  return (
    <Card>
      <CardHeading>{content.catering.title}</CardHeading>
      <CardIntroduction>
        <BlocksRenderer content={content.catering.body} />
      </CardIntroduction>
      {content.catering.show_menu && meals && (
        <>
          <h2>Menu</h2>
          <Table size="small" sx={{ marginBottom: '30px' }}>
            <TableBody>
              {meals.map((meal) => (
                <TableKeyValueRow key={meal.id} name={meal.name} forceSingleColumn>
                  {meal.description}
                </TableKeyValueRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
      <form onSubmit={handleSubmit} noValidate>
        <FormGrid>
          <TextField
            label="Do the catering team need any additional information?"
            value={values.attendee.cateringMessage}
            onBlur={() => setFieldTouched('attendee.cateringMessage')}
            onChange={async (event) => {
              await setFieldValue('attendee.cateringMessage', event.target.value);
            }}
            error={touched.attendee?.cateringMessage
              && Boolean(errors.attendee?.cateringMessage)}
            helperText={(touched.attendee?.cateringMessage
              && errors.attendee?.cateringMessage) || ' '}
            multiline
            minRows={3}
            disabled={isSubmitting}
          />
        </FormGrid>
        <h2>{content.catering.sandwich.title}</h2>
        <BlocksRenderer content={content.catering.sandwich.body} />
        <FormGrid>
          <FormControl
            error={touched.attendee?.sandwichPreference
              && Boolean(errors.attendee?.sandwichPreference)}
          >
            <FormLabel id="sandwich-preference-label">{content.catering.sandwich.label}</FormLabel>
            <RadioGroup
              aria-labelledby="sandwich-preference-label"
              value={values.attendee.sandwichPreference}
              onChange={async (event) => {
                await setFieldValue('attendee.sandwichPreference', event.target.value);
              }}
              onBlur={() => setFieldTouched('attendee.sandwichPreference')}
            >
              {sandwichOptions.map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={option}
                  disabled={isSubmitting}
                />
              ))}
            </RadioGroup>
            <FormHelperText>
              {(touched.attendee?.sandwichPreference && errors.attendee?.sandwichPreference) || ' '}
            </FormHelperText>
          </FormControl>
        </FormGrid>
        <CardFooter>
          <IconLink icon="Back" to={backRoute(registrationCode)}>Back</IconLink>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Next
          </Button>
        </CardFooter>
      </form>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </Card>
  );
};

export default RegistrationCateringFormPage;

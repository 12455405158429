import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import RouterLink from '../../../components/RouterLink';
import { coerceToDate } from '../../../helpers/dates';
import { getRelativeDayOrDateWithTime } from '../../../helpers/datesStrings';
import { EmailEvent } from '../../../models/emailEvent';
import { emailSendStatusOptions } from '../../../models/emailSendStatus';
import { emailTemplateOptions } from '../../../models/emailTemplate';
import { Pack } from '../../../models/pack';
import routes from '../../../routes';

export const getColumns = (packs: Pack[]): GridColDef[] => [
  {
    field: 'created',
    headerName: 'Sent',
    type: 'dateTime',
    width: 125,
    valueGetter: (value: string) => value && coerceToDate(value),
    valueFormatter: (value: string) => getRelativeDayOrDateWithTime(value),
  },
  {
    field: 'recipient',
    headerName: 'Recipient',
    renderCell: (params: GridRenderCellParams<EmailEvent>) => (
      <RouterLink to={routes.people.detail(params.row.personId)}>
        {params.row.recipient}
      </RouterLink>
    ),
    width: 150,
  },
  {
    field: 'packId',
    headerName: 'Pack',
    width: 125,
    renderCell: (params: GridRenderCellParams<EmailEvent>) => params.row.packId && (
      <RouterLink to={routes.packs.detail(params.row.packId)}>
        {packs.find((pack) => pack.id === params.row.packId)?.reference ?? ''}
      </RouterLink>
    ),
  },
  {
    field: 'template',
    headerName: 'Template',
    width: 250,
    type: 'singleSelect',
    valueOptions: emailTemplateOptions,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 125,
    type: 'singleSelect',
    valueOptions: emailSendStatusOptions,
  },
  {
    field: 'statusUpdated',
    headerName: 'Updated',
    type: 'dateTime',
    width: 125,
    valueGetter: (value: string) => value && coerceToDate(value),
    valueFormatter: (value: string) => getRelativeDayOrDateWithTime(value),
  },
];

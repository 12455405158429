import { objectToCamel, objectToSnake } from 'ts-case-convert';
import { StaffTeam } from '../../models/staffTeam';
import { NewRegistration } from '../../state/registration/types';
import registrationsClient from './client';
import mapToRequest from './mapper';

export type RegistrationRequest = Omit<NewRegistration, '_persist' | 'meta' | 'attendee' | 'mainContact' | 'activityLeadership'> & {
  attendee: Omit<NewRegistration['attendee'], 'staffTeam'> & {
    regionId: number | undefined
    districtId: number | undefined
    staffTeam: StaffTeam | undefined
  }
  activityLeadership: {
    id: number
    canLead: boolean | null
  }[]
};

export type RegistrationResponse = {
  personId: number
};

type RegistrationsService = {
  create: (code: string, registration: NewRegistration) => Promise<RegistrationResponse>,
};

const registrationsService: RegistrationsService = {
  create: async (code, registration) => {
    const data = mapToRequest(registration);
    return registrationsClient.create(
      code,
      objectToSnake(data),
    ).then((response) => objectToCamel(response));
  },
};

export default registrationsService;

import { Stack } from '@mui/material';
import { FormikConfig } from 'formik';
import {
  FC, ReactNode, useRef, useState,
} from 'react';
import {
  DeleteMutation,
  MutateActionError,
  MutateActionType,
  Mutation,
} from '../../helpers/actionType';
import { DataPanelMode } from '../../helpers/dataPanelMode';
import { ReduxFetch } from '../../helpers/reduxFetch';
import DeleteDialog from '../DeleteDialog';
import { FormProps } from '../forms/types';
import DataPanelWrapper from './DataPanelWrapper';
import EditModeToggle from './EditModeToggle';
import ErrorAlert from './ErrorAlert';
import FormWrapper from './FormWrapper';
import SuccessAlert from './SuccessAlert';

export type DataPanelDisplayProps<TData extends object> = {
  data: TData
};

type PermissionCheck<TData extends object> = boolean | ((data: TData) => boolean);

export type DataPanelProps<TForm extends object, TData extends TForm> = {
  title: string | ((data: TData | undefined) => string)
  titleTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  modelName: string
  fetch: ReduxFetch<TData>
  prepareInitialValues?: (data: TData) => TForm
  formikConfig?: Omit<FormikConfig<TForm>, 'initialValues' | 'onSubmit'>
  sanitiseOnSubmit?: (values: TForm) => TForm
  initialMode?: DataPanelMode
  canCreate?: PermissionCheck<TData>
  create?: {
    mutation: Mutation<TForm, TData>,
    initialValues: TForm
    onSuccess?: (newEntity: TData) => void
  }
  canUpdate?: PermissionCheck<TData>
  update: {
    mutation: Mutation<TForm, TData>
    onSuccess?: (newEntity: TData) => void
  }
  canDelete?: PermissionCheck<TData>
  deletion?: {
    mutation: DeleteMutation<TData>
    dialogTitle: string
    dialogDescription: string
    onSuccess?: () => void
  }
  LoadingComponent: ReactNode
  DisplayComponent: FC<DataPanelDisplayProps<TData>>
  FormComponent: FC<FormProps<TForm>>
};

const checkPermissions = <TData extends object>(
  check: PermissionCheck<TData> | undefined,
  data: TData | undefined,
): boolean => {
  if (typeof check === 'boolean') {
    return check;
  }
  if (typeof check === 'function' && data) {
    return check(data);
  }
  return false;
};

const DataPanel = <
  TForm extends object,
  TData extends TForm,
>(props: DataPanelProps<TForm, TData>): JSX.Element => {
  const {
    title,
    titleTag: TitleTag = 'h3',
    modelName,
    fetch,
    prepareInitialValues = (data) => data,
    formikConfig,
    sanitiseOnSubmit,
    create,
    initialMode = 'display',
    canCreate = true,
    update,
    canUpdate = true,
    deletion,
    canDelete = true,
    LoadingComponent,
    DisplayComponent,
    FormComponent,
  } = props;

  const [mode, setMode] = useState<DataPanelMode>(initialMode);
  const displayMode = mode === 'display';
  const formMode = mode === 'form';

  const [mutateError, setMutateError] = useState<MutateActionError>();
  const [mutateSuccess, setMutateSuccess] = useState<MutateActionType>();

  const titleRef = useRef<null | HTMLDivElement>(null);
  const scrollToTitle = (): void => {
    if (titleRef.current) {
      titleRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  };

  const hasErrorOrDeleted = fetch.isError || mutateSuccess === 'delete';
  const dataExists = fetch.data && !hasErrorOrDeleted;
  const formMutation = dataExists ? update.mutation : create?.mutation;
  const initialValues = dataExists && fetch.data
    ? prepareInitialValues(fetch.data) : create?.initialValues;

  const canCreateData = checkPermissions(canCreate, fetch.data);
  const canUpdateData = checkPermissions(canUpdate, fetch.data);
  const canDeleteData = checkPermissions(canDelete, fetch.data);

  const handelStartEdit = (): void => {
    setMutateSuccess(undefined);
    setMutateError(undefined);
    setMode('form');
  };

  const handelCancelEdit = (): void => {
    setMode('display');
    scrollToTitle();
  };

  const onDeleteDialogOpen = (): void => {
    setMutateSuccess(undefined);
    setMutateError(undefined);
  };

  const onMutationSuccess = (
    successActionType: MutateActionType,
  ) => async (
    mutationResult?: TData,
  ): Promise<void> => {
    setMode('display');
    scrollToTitle();
    setMutateSuccess(successActionType);
    setMutateError(undefined);
    if (successActionType === 'create' && create?.onSuccess && mutationResult) {
      create.onSuccess(mutationResult);
    }
    if (successActionType !== 'delete') {
      fetch.refetch();
    }
    if (successActionType === 'delete' && deletion?.onSuccess) {
      deletion.onSuccess();
    }
  };

  const startEditMode = (): void => setMode('form');

  const titleString = typeof title === 'function' ? title(fetch.data) : title;

  return (
    <DataPanelWrapper>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <TitleTag ref={titleRef}>
          {titleString}
        </TitleTag>
        <Stack direction="row" spacing={3}>
          {dataExists && canUpdateData && (
            <EditModeToggle
              mode={mode}
              onEditClick={handelStartEdit}
              onCancelClick={handelCancelEdit}
            />
          )}
          {canDeleteData && deletion && dataExists && fetch.currentData && (
            <DeleteDialog
              title={deletion.dialogTitle}
              description={deletion.dialogDescription}
              modelName={modelName}
              data={fetch.currentData}
              onOpen={onDeleteDialogOpen}
              onSuccess={onMutationSuccess('delete')}
              onDelete={deletion.mutation}
            />
          )}
        </Stack>
      </Stack>

      {fetch.isLoading && LoadingComponent}

      {fetch.error && displayMode && mutateError === undefined && mutateSuccess === undefined && (
        <ErrorAlert
          modelName={modelName}
          type="load"
          error={fetch.error}
          onCreateClick={canCreateData && displayMode ? startEditMode : undefined}
        />
      )}
      {mutateError && (
        <ErrorAlert
          modelName={modelName}
          type={mutateError.type}
          error={mutateError.error}
        />
      )}

      {mutateSuccess && displayMode && (
        <SuccessAlert
          modelName={modelName}
          type={mutateSuccess}
          onCreateClick={mutateSuccess === 'delete' && canCreateData ? startEditMode : undefined}
        />
      )}

      {displayMode && dataExists && fetch.data && (
        <DisplayComponent data={fetch.data} />
      )}

      {formMode && formMutation && initialValues && (
        <FormWrapper
          mutation={formMutation}
          onSuccess={onMutationSuccess(dataExists ? 'update' : 'create')}
          onError={(error) => setMutateError({ error, type: dataExists ? 'update' : 'create' })}
          formikConfig={{
            ...formikConfig,
            initialValues,
          }}
          sanitiseOnSubmit={sanitiseOnSubmit}
          FormComponent={FormComponent}
        />
      )}
    </DataPanelWrapper>
  );
};

export default DataPanel;

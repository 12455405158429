import { FC } from 'react';
import DataPanel, { DataPanelDisplayProps } from '../../../../components/DataPanel';
import LoadingText from '../../../../components/LoadingText';
import { ParagraphWithoutTopMargin } from '../../../../components/Paragraph';
import PhoneLink from '../../../../components/PhoneLink';
import DoctorForm from '../../../../components/forms/DoctorForm';
import sanitiseOnSubmit from '../../../../components/forms/DoctorForm/sanitise';
import { validationSchema } from '../../../../components/forms/DoctorForm/schema';
import checkPersonPermissions from '../../../../helpers/dataAction';
import { getFullNamePossessive } from '../../../../helpers/person';
import { CreateDoctor, Doctor } from '../../../../models/doctor';
import { Person } from '../../../../models/person';
import { User } from '../../../../models/user';
import {
  useCreateDoctorMutation,
  useDeleteDoctorMutation,
  useGetDoctorQuery,
  useUpdateDoctorMutation,
} from '../../../../state/protectedApi/doctor';

type DoctorPanelProps = {
  person: Person
  user: User | undefined
};

const DisplayDoctor: FC<DataPanelDisplayProps<Doctor>> = (props) => {
  const {
    data: {
      name, surgeryName, addressStreet, addressTown, phone,
    },
  } = props;

  return (
    <>
      <ParagraphWithoutTopMargin>
        {name ? `${surgeryName} (${name})` : surgeryName}
      </ParagraphWithoutTopMargin>
      <p>
        {addressStreet}
        <br />
        {addressTown}
      </p>
      <p>
        <PhoneLink phoneNumber={phone} />
      </p>
    </>
  );
};

const DoctorPanel = (props: DoctorPanelProps): JSX.Element => {
  const { person, user } = props;

  const canCreate = checkPersonPermissions('Create', person, user);
  const canUpdate = checkPersonPermissions('Update', person, user);
  const canDelete = checkPersonPermissions('Delete', person, user);

  const fetchDoctor = useGetDoctorQuery(person.id);
  const [createDoctor] = useCreateDoctorMutation();
  const [updateDoctor] = useUpdateDoctorMutation();
  const [deleteDoctor] = useDeleteDoctorMutation();

  const personId = person.id;
  const initialDoctor: CreateDoctor = {
    surgeryName: '',
    name: '',
    addressStreet: '',
    addressTown: '',
    phone: '',
  };

  return (
    <DataPanel
      title="Doctor"
      modelName="doctor"
      fetch={fetchDoctor}
      prepareInitialValues={(data: Doctor) => ({
        ...data,
        name: data.name ?? '',
      })}
      formikConfig={{ validationSchema }}
      sanitiseOnSubmit={sanitiseOnSubmit}
      canCreate={canCreate}
      create={{
        mutation: (create) => createDoctor({ ...create, personId }),
        initialValues: initialDoctor,
      }}
      canUpdate={canUpdate}
      update={{
        mutation: (update) => updateDoctor({ ...update, personId }),
      }}
      canDelete={canDelete}
      deletion={{
        mutation: () => deleteDoctor(personId),
        dialogTitle: `Delete ${getFullNamePossessive(person)} doctor`,
        dialogDescription: `Are you sure you want to delete the doctor for ${person.firstName}?`,
      }}
      LoadingComponent={<LoadingText lines={4} />}
      DisplayComponent={DisplayDoctor}
      FormComponent={DoctorForm}
    />
  );
};

export default DoctorPanel;

import {
  Backdrop,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BlocksRenderer from '../../../components/BlocksRenderer';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import CardIntroduction from '../../../components/CardIntroduction';
import IconLink from '../../../components/IconLink';
import { useRegistrationsContent } from '../../../helpers/content';
import { useFormikWithStateSync } from '../../../helpers/form';
import routes from '../../../routes';
import { useRegistrationCodeOrError } from '../../../routes/registrationsRoutes';
import { selectRegistration, update } from '../../../state/registration';
import sanitiseOnSubmit from './sanitise';
import { validationSchema } from './schema';
import { ExtendedQuestion } from './styles';

const RegistrationAdditionalInformationFormPage = (): JSX.Element => {
  const { content } = useRegistrationsContent();
  const registrationCode = useRegistrationCodeOrError();
  const navigate = useNavigate();

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormikWithStateSync(
    selectRegistration,
    update,
    {
      validationSchema,
      onSubmit: async (submittedValues) => {
        const nextPage = submittedValues.meta?.registeringSelf
          ? routes.registrations.catering : routes.registrations.firstAid;
        navigate(nextPage(registrationCode));
      },
    },
    sanitiseOnSubmit,
  );

  if (!content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <Card>
      <CardHeading>{content.additional_information.title}</CardHeading>
      <CardIntroduction>
        <BlocksRenderer content={content.additional_information.body} />
      </CardIntroduction>
      <form onSubmit={handleSubmit} noValidate>
        <ExtendedQuestion>
          <FormControl
            error={touched.meta?.hasAdditionalNeeds
              && Boolean(errors.meta?.hasAdditionalNeeds)}
          >
            <FormLabel id="has-additional-label">
              {`${values.meta.registeringSelf ? 'Do you' : `Does ${values.attendee.firstName}`}
                have any disabilities, medical conditions, additional needs or cultural needs?`}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="has-additional-label"
              value={values.meta.hasAdditionalNeeds}
              onChange={async (event) => {
                await setFieldValue('meta.hasAdditionalNeeds', event.target.value === 'true');
              }}
              onBlur={() => setFieldTouched('meta.hasAdditionalNeeds')}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
            <FormHelperText>
              {(touched.meta?.hasAdditionalNeeds && errors.meta?.hasAdditionalNeeds) || ' '}
            </FormHelperText>
          </FormControl>
          <Collapse in={Boolean(values.meta.hasAdditionalNeeds)}>
            <TextField
              label="Please provide details"
              value={values.attendee.additionalNeeds}
              onBlur={() => setFieldTouched('attendee.additionalNeeds')}
              onChange={async (event) => {
                await setFieldValue('attendee.additionalNeeds', event.target.value);
              }}
              error={touched.attendee?.additionalNeeds && Boolean(errors.attendee?.additionalNeeds)}
              helperText={(touched.attendee?.additionalNeeds
                && errors.attendee?.additionalNeeds) || ' '}
              multiline
              minRows={3}
            />
          </Collapse>
        </ExtendedQuestion>
        <ExtendedQuestion>
          <FormControl
            error={touched.meta?.hasAllergies && Boolean(errors.meta?.hasAllergies)}
          >
            <FormLabel id="has-allergies-label">
              {`${values.meta.registeringSelf ? 'Do you' : `Does ${values.attendee.firstName}`}
                have any allergies, including allergies to foods, medications, plasters or face paints?`}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="has-allergies-label"
              value={values.meta.hasAllergies}
              onChange={async (event) => {
                await setFieldValue('meta.hasAllergies', event.target.value === 'true');
              }}
              onBlur={() => setFieldTouched('meta.hasAllergies')}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
            <FormHelperText>
              {(touched.meta?.hasAllergies && errors.meta?.hasAllergies) || ' '}
            </FormHelperText>
          </FormControl>
          <Collapse in={Boolean(values.meta.hasAllergies)}>
            <TextField
              label="Please provide details"
              value={values.attendee.allergies}
              onBlur={() => setFieldTouched('attendee.allergies')}
              onChange={async (event) => {
                await setFieldValue('attendee.allergies', event.target.value);
              }}
              error={touched.attendee?.allergies && Boolean(errors.attendee?.allergies)}
              helperText={(touched.attendee?.allergies
                && errors.attendee?.allergies) || ' '}
              multiline
              minRows={3}
            />
          </Collapse>
        </ExtendedQuestion>
        <ExtendedQuestion>
          <FormControl
            error={touched.meta?.hasMedications && Boolean(errors.meta?.hasMedications)}
          >
            <FormLabel id="has-medications-label">
              {`${values.meta.registeringSelf ? 'Are you' : `Is ${values.attendee.firstName}`}
              currently taking medication?`}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="has-medications-label"
              value={values.meta.hasMedications}
              onChange={async (event) => {
                await setFieldValue('meta.hasMedications', event.target.value === 'true');
              }}
              onBlur={() => setFieldTouched('meta.hasMedications')}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
            <FormHelperText>
              {(touched.meta?.hasMedications && errors.meta?.hasMedications) || ' '}
            </FormHelperText>
          </FormControl>
          <Collapse in={Boolean(values.meta.hasMedications)}>
            <TextField
              label="Please provide details (including reason for use, frequency and dose)."
              value={values.attendee.medications}
              onBlur={() => setFieldTouched('attendee.medications')}
              onChange={async (event) => {
                await setFieldValue('attendee.medications', event.target.value);
              }}
              error={touched.attendee?.medications && Boolean(errors.attendee?.medications)}
              helperText={(touched.attendee?.medications
                && errors.attendee?.medications) || ' '}
              multiline
              minRows={3}
            />
          </Collapse>
        </ExtendedQuestion>
        <ExtendedQuestion>
          <FormControl
            error={touched.meta?.hasDietaryRequirements
              && Boolean(errors.meta?.hasDietaryRequirements)}
          >
            <FormLabel id="has-dietary-label">
              {`${values.meta.registeringSelf ? 'Do you' : `Does ${values.attendee.firstName}`}
                have any dietary requirements?`}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="has-dietary-label"
              value={values.meta.hasDietaryRequirements}
              onChange={async (event) => {
                await setFieldValue('meta.hasDietaryRequirements', event.target.value === 'true');
              }}
              onBlur={() => setFieldTouched('meta.hasDietaryRequirements')}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
            <FormHelperText>
              {(touched.meta?.hasDietaryRequirements && errors.meta?.hasDietaryRequirements) || ' '}
            </FormHelperText>
          </FormControl>
          <Collapse in={Boolean(values.meta.hasDietaryRequirements)}>
            <TextField
              label="Please provide details"
              value={values.attendee.dietaryRequirements}
              onBlur={() => setFieldTouched('attendee.dietaryRequirements')}
              onChange={async (event) => {
                await setFieldValue('attendee.dietaryRequirements', event.target.value);
              }}
              error={touched.attendee?.dietaryRequirements
                && Boolean(errors.attendee?.dietaryRequirements)}
              helperText={(touched.attendee?.dietaryRequirements
                  && errors.attendee?.dietaryRequirements) || ' '}
              multiline
              minRows={3}
            />
          </Collapse>
        </ExtendedQuestion>
        <CardFooter>
          <IconLink icon="Back" to={routes.registrations.media(registrationCode)}>Back</IconLink>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Next
          </Button>
        </CardFooter>
      </form>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </Card>
  );
};

export default RegistrationAdditionalInformationFormPage;

import { Skeleton, Stack } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { FC } from 'react';
import ErrorAlert from '../../../../../components/DataPanel/ErrorAlert';
import PersonStatusChip from '../../../../../components/PersonStatusChip';
import RouterLink from '../../../../../components/RouterLink';
import { getFullName } from '../../../../../helpers/person';
import routes from '../../../../../routes';
import { useGetPersonQuery } from '../../../../../state/protectedApi/people';
import * as Styled from './styles';

type ContactPreviewProps = {
  personId: number | null
};

const ContactPreview: FC<ContactPreviewProps> = (props) => {
  const { personId } = props;

  const {
    currentData: data,
    isFetching,
    error,
  } = useGetPersonQuery(personId ?? skipToken);

  if (personId === null) {
    return (
      <Styled.Placeholder>
        <Stack alignItems="center">
          Type person ID to load preview
        </Stack>
      </Styled.Placeholder>
    );
  }

  if (isFetching) {
    return <Skeleton variant="rectangular" height={56} />;
  }

  if (error) {
    return <ErrorAlert modelName="Person" type="load" error={error} />;
  }

  if (data === undefined) {
    return <Skeleton variant="rectangular" height={56} />;
  }

  return (
    <Styled.Placeholder>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
        <RouterLink target="_blank" to={routes.people.detail(data.id)}>
          {getFullName(data)}
        </RouterLink>
        <PersonStatusChip status={data.status} />
      </Stack>
    </Styled.Placeholder>
  );
};

export default ContactPreview;

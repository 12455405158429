import { Backdrop, CircularProgress } from '@mui/material';
import { LicenseInfo } from '@mui/x-license';
import { useEffect } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import useErrorHandler from '../../helpers/useErrorHandler';
import routes from '../../routes';
import { useGetProtectedEventConfigQuery } from '../../state/protectedApi';

type AuthenticatedScopeProps = {
  children: JSX.Element
};

const AuthenticatedScope = (props: AuthenticatedScopeProps): JSX.Element => {
  const { children } = props;

  const firebaseUser = auth.currentUser;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentSearch = new URLSearchParams(location.search);
    const redirect = `${location.pathname}?${currentSearch.toString()}`;
    const redirectParams = createSearchParams({ redirect });
    const redirectToLoginPage = (): void => {
      navigate({ pathname: routes.login, search: `?${redirectParams.toString()}` }, { replace: true });
    };

    if (firebaseUser === null) {
      redirectToLoginPage();
    }
  }, [firebaseUser, location.pathname, location.search, navigate]);

  const {
    data: protectedEventConfig,
    error: protectedEventConfigError,
  } = useGetProtectedEventConfigQuery(undefined, { skip: firebaseUser === null });
  useErrorHandler(protectedEventConfigError, 'API Error: Failed to load protected event config');

  if (firebaseUser === null || !protectedEventConfig) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  LicenseInfo.setLicenseKey(protectedEventConfig.muiLicenceKey);

  return children;
};

export default AuthenticatedScope;

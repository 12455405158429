import { Subcamp } from '../../models/subcamp';
import apiService from '../apiService';

export type Arrival = {
  personId: number
  publicId: string
  name: string
  subcamp: Subcamp | null
  packReference: string
  message: string
  status: 'success' | 'info' | 'warning'
};

export type ArrivalResponse = undefined | Arrival;

type ArrivalsService = {
  notifyByPublicId: (
    publicId: string,
    editionId: number,
    bypass: boolean,
  ) => Promise<ArrivalResponse>
  notifyByPersonId: (
    personId: number,
    editionId: number,
    bypassReviewFlags: boolean,
  ) => Promise<ArrivalResponse>
};

const arrivalsService: ArrivalsService = {
  notifyByPublicId: async (
    publicId,
    editionId,
    bypassReviewFlags,
  ) => apiService.get(`arrivals/public/${publicId}/${editionId}?bypass_review_flags=${bypassReviewFlags ? 'true' : 'false'}`),
  notifyByPersonId: async (
    personId,
    editionId,
    bypassReviewFlags,
  ) => apiService.get(`arrivals/person/${personId}/${editionId}?bypass_review_flags=${bypassReviewFlags ? 'true' : 'false'}`),
};

export default arrivalsService;

import { Chip, Stack } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import PersonDetailsTable from '../../../../components/PersonDetailsTable';
import PersonRegistrationDetailsTable from '../../../../components/PersonRegistrationDetailsTable';
import RegistrationReviewTable from '../../../../components/RegistrationReviewTable';
import RouterLink from '../../../../components/RouterLink';
import { getFullName, getIntroduction } from '../../../../helpers/person';
import { getStatusColourForPersonStatus } from '../../../../helpers/statusColours';
import { getPersonStatusLabel } from '../../../../models/personStatus';
import { ReviewType } from '../../../../models/reviewType';
import routes from '../../../../routes';
import { useGetContactsForPersonQuery } from '../../../../state/protectedApi/contacts';
import { useGetPackQuery } from '../../../../state/protectedApi/packs';
import { useGetPersonQuery } from '../../../../state/protectedApi/people';
import { useGetRegistrationQuery } from '../../../../state/protectedApi/registration';
import { useGetStaffQuery } from '../../../../state/protectedApi/staff';
import * as Styled from './styles';

type PersonReviewProps = {
  reviewType: ReviewType
  personId: number
  onSave: () => void
  onPrev?: () => void
  onSkip?: () => void
};

const PersonReview = (props: PersonReviewProps): JSX.Element => {
  const {
    reviewType,
    personId,
    onSave,
    onPrev,
    onSkip,
  } = props;

  const {
    currentData: person,
    isFetching,
  } = useGetPersonQuery(personId, { refetchOnMountOrArgChange: true });
  const {
    currentData: registration,
  } = useGetRegistrationQuery(personId);
  const {
    currentData: staff,
  } = useGetStaffQuery(personId);
  const {
    currentData: pack,
  } = useGetPackQuery(person?.packId ?? skipToken);
  const {
    currentData: contacts,
  } = useGetContactsForPersonQuery(personId);

  if (isFetching || person === undefined || personId !== person.id) {
    return (
      <p>Loading person...</p>
    );
  }

  return (
    <Styled.Wrapper>
      <Stack direction="row" spacing={5}>
        <Styled.Title>
          <RouterLink to={routes.people.detail(person.id)}>
            {getFullName(person)}
          </RouterLink>
        </Styled.Title>
        <Chip
          label={getPersonStatusLabel(person.status)}
          color={getStatusColourForPersonStatus(person.status)}
          variant="outlined"
        />
      </Stack>
      <p>{getIntroduction(staff, pack)}</p>
      <PersonDetailsTable person={person} reviewMode />
      {person && registration && (
        <PersonRegistrationDetailsTable
          person={person}
          registration={registration}
          contacts={contacts ?? []}
          reviewMode
          reviewType={reviewType}
        />
      )}
      {registration && (
        <RegistrationReviewTable
          reviewType={reviewType}
          subcamp={pack?.subcamp}
          packId={person.packId}
          registration={registration}
          reviewManyMode
          onSave={onSave}
          onPrev={onPrev}
          onSkip={onSkip}
        />
      )}
    </Styled.Wrapper>
  );
};

export default PersonReview;

import { enqueueSnackbar } from 'notistack';

export const addNotification = (message?: string, status?: 'success' | 'info' | 'warning' | 'error'): void => {
  enqueueSnackbar(
    message,
    {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      variant: status,
      persist: status && ['warning', 'error'].includes(status),
    },
  );
};

import EditIcon from '@mui/icons-material/Edit';
import {
  Alert,
  Button,
  Collapse,
  IconButton,
  Skeleton,
  Stack,
} from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FC, useState } from 'react';
import { AlertTitleWithoutMargin } from '../../../../components/Alert';
import DataPanelWrapper from '../../../../components/DataPanel/DataPanelWrapper';
import ErrorAlert from '../../../../components/DataPanel/ErrorAlert';
import SuccessAlert from '../../../../components/DataPanel/SuccessAlert';
import DeleteDialog from '../../../../components/DeleteDialog';
import { MutateActionType } from '../../../../helpers/actionType';
import checkPersonPermissions from '../../../../helpers/dataAction';
import { Person } from '../../../../models/person';
import { User } from '../../../../models/user';
import {
  useDeleteActivityPreferencesMutation,
  useGetActivityPreferencesQuery,
} from '../../../../state/protectedApi/activityPreferences';
import { useGetActivitiesQuery } from '../../../../state/publicApi/activities';
import ActivityPreferencesForm from './ActivityPreferencesForm';

type ActivityPreferencesPanelProps = {
  person: Person
  user: User | undefined
};

const ActivityPreferencesPanel: FC<ActivityPreferencesPanelProps> = (props) => {
  const { person, user } = props;

  const [editMode, setEditMode] = useState(false);
  const [mutateSuccess, setMutateSuccess] = useState<MutateActionType>();
  const [error, setError] = useState<SerializedError | FetchBaseQueryError>();

  const {
    data: preferences,
    isError,
    refetch,
  } = useGetActivityPreferencesQuery(person.id);
  const {
    data: activities,
  } = useGetActivitiesQuery();
  const [deleteActivityPreferences] = useDeleteActivityPreferencesMutation();

  const canUpdatePreferences = checkPersonPermissions('Update', person, user);
  const somePreferencesSet = preferences?.some((p) => p.rank !== null) ?? false;

  const onSuccess = (type: MutateActionType) => async (): Promise<void> => {
    setMutateSuccess(type);
    await refetch();
  };

  const onEditClick = (): void => {
    setEditMode(true);
    setMutateSuccess(undefined);
    setError(undefined);
  };

  // TODO: scroll view up on success and error (to show the success or error alert)
  const onSuccessfulUpdate = (): void => {
    setMutateSuccess('update');
    setError(undefined);
    setEditMode(false);
  };

  const onUpdateError = (newError: SerializedError | FetchBaseQueryError): void => {
    setError(newError);
    setMutateSuccess(undefined);
  };

  if (isError) {
    return null;
  }

  return (
    <DataPanelWrapper>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <h3>Activity Preferences</h3>
        {canUpdatePreferences
          && preferences
          && activities
          && (somePreferencesSet || mutateSuccess)
          && (
            <Stack direction="row-reverse" spacing={3}>
              <DeleteDialog
                title="Delete activity preferences"
                description="Are you sure you want to delete all activity preference records?"
                modelName="activity preferences"
                data={{}}
                onDelete={() => deleteActivityPreferences(person.id)}
                onSuccess={onSuccess('delete')}
              />
              {!editMode && (
                <IconButton onClick={onEditClick}>
                  <EditIcon />
                </IconButton>
              )}
            </Stack>
          )}
      </Stack>

      {mutateSuccess && (
        <SuccessAlert
          modelName="Activity Preferences"
          type={mutateSuccess}
        />
      )}

      <Collapse in={error !== undefined}>
        {error && (
          <ErrorAlert modelName="activity preferences" type="update" error={error} />
        )}
      </Collapse>

      {(activities === undefined || preferences === undefined) && (
        <div>
          <Skeleton variant="rectangular" height={200} sx={{ marginBottom: '12px' }} />
          <Skeleton variant="rectangular" height={100} />
        </div>
      )}

      {!somePreferencesSet && !editMode && mutateSuccess === undefined && (
        <Alert
          severity="warning"
          action={canUpdatePreferences && preferences && preferences.length > 0 && (
            <Button color="inherit" onClick={onEditClick} size="small">
              Set preferences
            </Button>
          )}
        >
          <AlertTitleWithoutMargin>No activity preferences has been set</AlertTitleWithoutMargin>
        </Alert>
      )}

      {activities && preferences && (somePreferencesSet || editMode || mutateSuccess === 'update') && (
        <ActivityPreferencesForm
          personId={person.id}
          activities={activities}
          preferences={preferences}
          disabled={!editMode || !canUpdatePreferences}
          onSuccess={onSuccessfulUpdate}
          onError={onUpdateError}
        />
      )}
    </DataPanelWrapper>
  );
};

export default ActivityPreferencesPanel;

import { Link } from '@mui/material';

type EmailLinkProps = {
  email: string
};

const EmailLink = (props: EmailLinkProps): JSX.Element => {
  const { email } = props;
  const emailHref = `mailto:${email}`;
  return (
    <Link href={emailHref}>{email}</Link>
  );
};

export default EmailLink;

import { CircularProgress } from '@mui/material';
import { FC } from 'react';
import { SubGrid } from '../../../../../components/Dashboard/styles';
import DashboardCard from '../../../../../components/DashboardCard';
import DashboardCardLegend from '../../../../../components/DashboardCardLegend';
import DashboardCardTitle from '../../../../../components/DashboardCardTitle';
import calculatePercentage, { summaryProgress } from '../../../../../helpers/percentage';
import { Statistics } from '../../../../../models/statistics';

type PackRegistrationProgressProps = {
  stats?: Statistics
};

const PackRegistrationProgress: FC<PackRegistrationProgressProps> = (props) => {
  const { stats } = props;

  return (
    <SubGrid columns={2} rows={3}>
      <DashboardCard>
        {stats ? (
          <>
            <DashboardCardTitle>
              {calculatePercentage(stats.registered.cubs, stats.bookings.cubs)}
            </DashboardCardTitle>
            <DashboardCardLegend>
              {summaryProgress(stats.registered.cubs, stats.bookings.cubs)}
            </DashboardCardLegend>
          </>
        ) : (
          <CircularProgress />
        )}
        <DashboardCardLegend>Cubs registered</DashboardCardLegend>
      </DashboardCard>
      <DashboardCard>
        {stats ? (
          <>
            <DashboardCardTitle>
              {calculatePercentage(stats.registered.youngLeaders, stats.bookings.youngLeaders)}
            </DashboardCardTitle>
            <DashboardCardLegend>
              {summaryProgress(stats.registered.youngLeaders, stats.bookings.youngLeaders)}
            </DashboardCardLegend>
          </>
        ) : (
          <CircularProgress />
        )}
        <DashboardCardLegend>YLs registered</DashboardCardLegend>
      </DashboardCard>
      <DashboardCard>
        {stats ? (
          <>
            <DashboardCardTitle>
              {calculatePercentage(stats.registered.leaders, stats.bookings.leaders)}
            </DashboardCardTitle>
            <DashboardCardLegend>
              {summaryProgress(stats.registered.leaders, stats.bookings.leaders)}
            </DashboardCardLegend>
          </>
        ) : (
          <CircularProgress />
        )}
        <DashboardCardLegend>Leaders registered</DashboardCardLegend>
      </DashboardCard>
      <DashboardCard>
        {stats ? (
          <>
            <DashboardCardTitle>
              {calculatePercentage(stats.registered.otherChildren, stats.bookings.otherChildren)}
            </DashboardCardTitle>
            <DashboardCardLegend>
              {summaryProgress(stats.registered.otherChildren, stats.bookings.otherChildren)}
            </DashboardCardLegend>
          </>
        ) : (
          <CircularProgress />
        )}
        <DashboardCardLegend>Others registered</DashboardCardLegend>
      </DashboardCard>
      {stats?.registered.totalFromPacks && (
        <DashboardCard>
          <DashboardCardTitle>
            {calculatePercentage(stats.registered.totalFromPacks, stats.bookings.total)}
          </DashboardCardTitle>
          <DashboardCardLegend>
            {summaryProgress(stats.registered.totalFromPacks, stats.bookings.total)}
          </DashboardCardLegend>
          <DashboardCardLegend>Total registered from packs</DashboardCardLegend>
        </DashboardCard>
      )}
      <DashboardCard>
        {stats ? (
          <DashboardCardTitle>
            {stats.registered.total}
          </DashboardCardTitle>
        ) : (
          <CircularProgress />
        )}
        <DashboardCardLegend>Total registered</DashboardCardLegend>
      </DashboardCard>
    </SubGrid>
  );
};

export default PackRegistrationProgress;

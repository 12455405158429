import DashboardPieChart from '../../../../../components/DashboardPieChart';
import { formatBooleanResponse } from '../../../../../helpers/boolean';
import { HexColourCode } from '../../../../../helpers/cssTypes';
import { PreviouslyAttendedStats } from '../../../../../models/ballotStatistics';
import { CountData } from '../../../../../models/statistics';
import { useGetBallotStatisticsQuery } from '../../../../../state/protectedApi/statistics';
import colours from '../../../../../theme/colours';

const getColour = (value: boolean | null): HexColourCode => {
  if (value === null) {
    return colours.grey;
  }
  return value ? colours.blue : colours.green;
};

const processData = (
  data: PreviouslyAttendedStats[],
  year: string,
): CountData[] => data.map((status) => ({
  name: `${formatBooleanResponse(status.value)} (${year})`,
  count: status.packCount,
  colour: getColour(status.value),
}));

const BallotByPreviousAttendance = (): JSX.Element => {
  const { data } = useGetBallotStatisticsQuery(undefined);

  return (
    <DashboardPieChart
      title="Past Attendance"
      data={processData(data?.attendedLastYear ?? [], '2023')}
      dataOuterCircle={processData(data?.attendedTwoYearsAgo ?? [], '2022')}
      showLegend={false}
      halfCircle
    />
  );
};

export default BallotByPreviousAttendance;

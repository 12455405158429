import { Backdrop, CircularProgress } from '@mui/material';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import DataGrid from '../../../components/DataGrid';
import PageMetaTitle from '../../../components/PageMetaTitle';
import { eventPhaseOrEarlier, eventPhaseOrLater } from '../../../helpers/eventConfig';
import useErrorHandler from '../../../helpers/useErrorHandler';
import { Pack } from '../../../models/pack';
import { useGetPacksQuery } from '../../../state/protectedApi/packs';
import { useGetEventConfigQuery } from '../../../state/publicApi';
import { usePackColumns } from './columns';
import Toolbar from './toolbar';

const PacksGridPage = (): JSX.Element => {
  const { data: config } = useGetEventConfigQuery();
  const {
    data: packs,
    error: packsError,
    isFetching: isFetchingPacks,
    refetch: refetchPacks,
  } = useGetPacksQuery();
  useErrorHandler(packsError, 'Error loading packs');

  const columns: GridColDef<Pack>[] = usePackColumns();
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  if (!config || !packs) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  const unselectPacks = (ids: number[]): void => {
    setRowSelectionModel((prev) => prev.filter((p) => typeof p === 'number' && !ids.includes(p)));
  };

  const onSuccessfulAction = async (): Promise<void> => {
    setRowSelectionModel([]);
    await refetchPacks();
  };

  const selectedPacks = packs.filter((pack) => rowSelectionModel.includes(pack.id));

  return (
    <>
      <PageMetaTitle title="Packs" />
      <DataGrid
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        columns={columns}
        rows={packs ?? []}
        loading={isFetchingPacks}
        slots={{
          toolbar: Toolbar,
        }}
        slotProps={{
          toolbar: {
            selectedPacks,
            onSuccess: onSuccessfulAction,
            unselectPacks,
          },
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              reference: true,
              name: true,
              districtId: eventPhaseOrEarlier(config, 'Allocation'),
              regionId: eventPhaseOrEarlier(config, 'Allocation'),
              bookingStatus: eventPhaseOrEarlier(config, 'Registration'),
              registrationStatus: eventPhaseOrLater(config, 'Registration'),
              subcamp: eventPhaseOrLater(config, 'Allocation'),
              travelIndication: eventPhaseOrEarlier(config, 'Allocation'),
              cubs: true,
              youngLeaders: true,
              leaders: true,
              otherChildren: true,
            } as const,
          },
          pinnedColumns: {
            left: ['reference'],
          },
          sorting: {
            sortModel: [{ field: 'bookingStatus', sort: 'asc' }],
          },
          filter: {
            filterModel: eventPhaseOrLater(config, 'Registration') ? {
              items: [{
                field: 'bookingStatus',
                operator: 'isAnyOf',
                value: [
                  'Accepted',
                  'Offered',
                  'Pending',
                  ...eventPhaseOrEarlier(config, 'Registration') ? ['WaitListed'] : [],
                ],
              }],
            } : undefined,
          },
        }}
      />
    </>
  );
};

export default PacksGridPage;

import { SkipToken } from '@reduxjs/toolkit/query';
import { Contact } from '../../../models/contact';
import { useGetActivityLeadershipsForPersonQuery } from '../../../state/protectedApi/activityLeadership';
import { useGetActivityPreferencesQuery } from '../../../state/protectedApi/activityPreferences';
import { useGetContactsForPersonQuery } from '../../../state/protectedApi/contacts';
import { useGetDoctorQuery } from '../../../state/protectedApi/doctor';
import { useGetPersonQuery } from '../../../state/protectedApi/people';
import { useGetRegistrationQuery } from '../../../state/protectedApi/registration';
import { useGetStaffQuery } from '../../../state/protectedApi/staff';
import { useGetUserQuery } from '../../../state/protectedApi/user';

const removeUnsetProperties = <TData extends object>(obj: Partial<TData>): Partial<TData> => {
  const result: Partial<TData> = {};

  Object.keys(obj).forEach((key) => {
    const typedKey = key as keyof TData;

    if (obj[typedKey] !== null && obj[typedKey] !== undefined) {
      result[typedKey] = obj[typedKey];
    }
  });

  return result;
};

const removeNamedProperties = <TData extends object>(
  obj: Partial<TData>, keysToDelete: Array<keyof TData>,
): Partial<TData> => {
  const result: Partial<TData> = {};

  Object.keys(obj).forEach((key) => {
    const typedKey = key as keyof TData;

    if (!keysToDelete.includes(typedKey)) {
      result[typedKey] = obj[typedKey];
    }
  });

  return result;
};

const reportQuery = <TData extends object>(query: {
  data?: TData,
  isError: boolean,
  isLoading: boolean,
},
  propertiesToDelete?: Array<keyof TData>,
): Partial<TData> | 'Loading...' | 'Not found' => {
  const { data, isError, isLoading } = query;
  if (isLoading) {
    return 'Loading...';
  }

  const cleanedData = data
    && removeUnsetProperties(propertiesToDelete
      ? removeNamedProperties(data, propertiesToDelete) : data);

  if (isError || !cleanedData) {
    return 'Not found';
  }

  return cleanedData;
};

const reportArrayQuery = <TData extends object>(query: {
  data?: TData[],
  isError: boolean,
  isLoading: boolean,
},
  propertiesToDelete?: Array<keyof TData>,
): Partial<TData>[] | 'Loading...' | 'Not found' => {
  const { data, isError, isLoading } = query;
  if (isLoading) {
    return 'Loading...';
  }

  const cleanedData = data && data.map((element) => {
    if (propertiesToDelete) {
      return removeUnsetProperties(removeNamedProperties(element, propertiesToDelete));
    }
    return removeUnsetProperties(element);
  });

  if (isError || !cleanedData) {
    return 'Not found';
  }
  return cleanedData;
};

const useContactsReport = (personId: number | SkipToken): Partial<Contact>[] | 'Loading...' | 'Not found' => {
  const { data, isError, isLoading } = useGetContactsForPersonQuery(personId);
  if (isLoading) {
    return 'Loading...';
  }

  const cleanedData = data && data.map((contact) => {
    const keysToDelete: Array<keyof Contact> = [
      'id',
      ...contact.personId === personId ? ['personId'] as const : [],
      ...contact.relatedPersonId === personId ? ['relatedPersonId'] as const : [],
    ];
    return removeUnsetProperties(removeNamedProperties(contact, keysToDelete));
  });

  if (isError || !cleanedData) {
    return 'Not found';
  }
  return cleanedData;
};

export const usePersonSummary = (id: number | SkipToken): object => Object.fromEntries([
  ['1_person', reportQuery(useGetPersonQuery(id), ['id'])] as const,
  ['2_registration', reportQuery(useGetRegistrationQuery(id), ['personId'])] as const,
  ['2_staff', reportQuery(useGetStaffQuery(id), ['personId'])] as const,
  ['3_contacts', useContactsReport(id)] as const,
  ['4_doctor', reportQuery(useGetDoctorQuery(id), ['personId'])] as const,
  ['5_user', reportQuery(useGetUserQuery(id))] as const,
  ['6_leadership', reportArrayQuery(useGetActivityLeadershipsForPersonQuery(id))] as const,
  ['6_preferences', reportArrayQuery(useGetActivityPreferencesQuery(id))] as const,
].filter((item) => item[1] !== 'Not found')) as object;

import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  attendee: Yup.object()
    .when('meta.registeringSelf', {
      is: false,
      then: Yup.object().shape({
        antihistamineConsent: Yup.mixed().required('Response is required'),
        painkillerConsent: Yup.mixed().required('Response is required'),
        suncreamConsent: Yup.mixed().required('Response is required'),
        afterSunConsent: Yup.mixed().required('Response is required'),
      }),
    }),
});

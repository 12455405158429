import { OptionList } from '../helpers/options';

const packBookingStatuses = [
  'Pending',
  'Unsuccessful',
  'WaitListed',
  'Offered',
  'Accepted',
  'Rejected',
] as const;

export type PackBookingsStatus = (typeof packBookingStatuses)[number];

export const isValidPackBookingsStatus = (
  value: string,
): value is PackBookingsStatus => packBookingStatuses.includes(value as never);

export const getPackBookingsStatusLabel = (status: PackBookingsStatus): string => {
  switch (status) {
    case 'WaitListed':
      return 'Waiting list';
    default:
      return status;
  }
};

export const packBookingStatusOptions: OptionList<PackBookingsStatus> = packBookingStatuses.map(
  (status) => ({
    label: getPackBookingsStatusLabel(status),
    value: status,
  }),
);

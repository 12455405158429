import { Backdrop, Button, CircularProgress } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import bookingsService from '../../../api/bookings';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import IconLink from '../../../components/IconLink';
import ReviewTable from '../../../components/ReviewTable';
import ReviewHeaderRow from '../../../components/ReviewTable/ReviewHeaderRow';
import ReviewRow from '../../../components/ReviewTable/ReviewRow';
import { sortEditions } from '../../../helpers/edition';
import routes from '../../../routes';
import useBooking from '../../../state/booking/useBooking';
import useError from '../../../state/error/useError';
import { useGetEditionsQuery } from '../../../state/publicApi/editions';
import { useGetRegionQuery } from '../../../state/publicApi/reference';

const BookingReviewPage: () => JSX.Element = () => {
  const navigate = useNavigate();
  const [booking, setBooking] = useBooking();
  const [, throwError] = useError();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bookingCreationErrored, setBookingCreationErrored] = useState(false);

  const {
    currentData: region,
  } = useGetRegionQuery(booking.meta.district?.regionId ?? skipToken);
  const { data: editions } = useGetEditionsQuery();

  useEffect(() => {
    if (bookingCreationErrored) {
      throwError({ message: 'API Error: Failed to create booking' });
    }
  }, [bookingCreationErrored, throwError]);

  const submitBooking = async (): Promise<void> => {
    setIsSubmitting(true);
    await bookingsService.create(booking)
      .then((bookingResponse) => {
        setBooking({
          ...booking,
          meta: {
            ...booking.meta,
            reference: bookingResponse.packReference,
          },
        });
        navigate(routes.bookings.confirmation);
      })
      .catch(() => {
        // TODO: deal with server-side validation errors gracefully
        setBookingCreationErrored(true);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const backRoute = booking.meta.showEditionsChoice
    ? routes.bookings.editions : routes.bookings.travel;

  return (
    <Card>
      <CardHeading>Review</CardHeading>
      <hr />
      <ReviewTable>
        <ReviewHeaderRow>Pack details</ReviewHeaderRow>
        <ReviewRow name="Region" route={routes.bookings.group}>
          {region?.name}
        </ReviewRow>
        <ReviewRow name="District" route={routes.bookings.group}>
          {booking.meta.district?.name}
        </ReviewRow>
        <ReviewRow name="Group" route={routes.bookings.group}>{booking.pack.groupName}</ReviewRow>
        {booking.pack.packName && (
          <ReviewRow name="Pack name" route={routes.bookings.group}>{booking.pack.packName}</ReviewRow>
        )}
        <ReviewRow name="Meeting place" route={routes.bookings.group}>
          {booking.pack.addressStreet}
          <br />
          {booking.pack.addressTown}
          <br />
          {booking.pack.addressPostcode}
        </ReviewRow>

        <ReviewHeaderRow>Your details</ReviewHeaderRow>
        <ReviewRow name="Name" route={routes.bookings.contact}>
          {`${booking.contact.firstName} ${booking.contact.lastName}`}
        </ReviewRow>
        <ReviewRow name="Position" route={routes.bookings.contact}>{booking.contact.scoutingAppointment}</ReviewRow>
        <ReviewRow name="Membership number" route={routes.bookings.contact}>{booking.contact.membershipNumber}</ReviewRow>
        <ReviewRow name="Mobile" route={routes.bookings.contact}>{booking.contact.mobile}</ReviewRow>
        <ReviewRow name="Email" route={routes.bookings.contact}>{booking.contact.email}</ReviewRow>
        <ReviewRow name="Address" route={routes.bookings.address}>
          {booking.contact.addressStreet}
          <br />
          {booking.contact.addressTown}
          <br />
          {booking.contact.addressPostcode}
        </ReviewRow>
        <ReviewHeaderRow>Estimated numbers</ReviewHeaderRow>
        <ReviewRow name="Cubs" route={routes.bookings.estimates}>{booking.pack.cubs}</ReviewRow>
        <ReviewRow name="Young leaders" route={routes.bookings.estimates}>{booking.pack.youngLeaders}</ReviewRow>
        <ReviewRow name="Leader" route={routes.bookings.estimates}>{booking.pack.leaders}</ReviewRow>
        <ReviewRow name="Other children" route={routes.bookings.estimates}>{booking.pack.otherChildren}</ReviewRow>

        <ReviewHeaderRow>Travel</ReviewHeaderRow>
        <ReviewRow name="Method" route={routes.bookings.travel}>{booking.pack.travelIndication}</ReviewRow>

        {editions && booking.meta.showEditionsChoice && (
          <>
            <ReviewHeaderRow>Event editions</ReviewHeaderRow>
            <ReviewRow name="Selected" route={routes.bookings.editions}>
              {sortEditions(
                editions.filter((edition) => booking.preferredEditionIds.includes(edition.id)),
              ).map((edition) => (
                <div key={edition.id}>{edition.name}</div>
              ))}
            </ReviewRow>
          </>
        )}
      </ReviewTable>
      <CardFooter>
        <IconLink icon="Back" to={backRoute}>Back</IconLink>
        <Button onClick={submitBooking} variant="contained">Submit Booking</Button>
      </CardFooter>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </Card>
  );
};

export default BookingReviewPage;

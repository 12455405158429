import formatBoolean from './formatBoolean';
import formatNumber from './formatNumber';
import formatString from './formatString';

const formatValue = (
  value: unknown,
  format: string | undefined,
): string => {
  if (typeof value === 'string') {
    return formatString(value, format);
  }
  if (typeof value === 'number') {
    return formatNumber(value, format);
  }
  if (typeof value === 'boolean') {
    return formatBoolean(value, format);
  }
  if (Array.isArray(value)) {
    const match = format?.match(/^(?<itemFormat>[^|@]+)?(\|(?<separator>[^|@]+))?(\|(?<lastSeparator>[^|@]+))?(@(?<multipleSuffix>.+))?$/);
    const {
      itemFormat, separator, lastSeparator, multipleSuffix,
    } = match?.groups ?? {
      itemFormat: undefined,
      separator: undefined,
      lastSeparator: undefined,
      multipleSuffix: undefined,
    };
    if (itemFormat === 'length') {
      return value.length.toString();
    }
    const stringValues = value.map((v) => formatValue(v, itemFormat)).filter((v) => v);
    if (stringValues.length === 1) {
      return stringValues[0];
    }
    const suffix = multipleSuffix ?? '';
    if (!separator) {
      return stringValues.join(', ') + suffix;
    }
    if (!lastSeparator) {
      return stringValues.join(separator) + suffix;
    }
    return stringValues.slice(0, -1).join(separator)
      + lastSeparator
      + stringValues[stringValues.length - 1]
      + suffix;
  }
  return '';
};

export default formatValue;

import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { FC } from 'react';
import CardFooter from '../../../../../components/CardFooter';
import { FormGrid } from '../../../../../components/FormGrid';
import { FormProps } from '../../../../../components/forms/types';
import { getFullName } from '../../../../../helpers/person';
import useErrorHandler from '../../../../../helpers/useErrorHandler';
import {
  ActivitySupport,
  activitySupportStatuses,
  getActivitySupportStatusLabel,
  isValidActivitySupportStatus,
} from '../../../../../models/activitySupport';
import { useGetPeopleQuery } from '../../../../../state/protectedApi/people';

const ActivitySupportForm: FC<FormProps<ActivitySupport>> = (props) => {
  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = props;

  const {
    data: leaders,
    isLoading: leadersLoading,
    error: leadersError,
  } = useGetPeopleQuery({
    packId: values.packId,
    filter: {
      items: [{
        field: 'status',
        operator: 'isAnyOf',
        value: ['Leader', 'YoungLeader'],
      }],
    },
  });
  useErrorHandler(leadersError, 'Failed to load leaders for pack');

  return (
    <form onSubmit={handleSubmit} noValidate>
      <FormGrid columns={2}>
        <FormControl
          error={touched.activityAdditionalSupport && Boolean(errors.activityAdditionalSupport)}
          disabled={isSubmitting}
        >
          <FormLabel id="activity-support-label">
            Additional Support
          </FormLabel>
          <RadioGroup
            aria-labelledby="activity-support-label"
            value={values.activityAdditionalSupport}
            onChange={async (event) => {
              if (isValidActivitySupportStatus(event.target.value)) {
                await setFieldValue('activityAdditionalSupport', event.target.value);
              }
            }}
            onBlur={() => setFieldTouched('activityAdditionalSupport')}
          >
            {activitySupportStatuses.map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={getActivitySupportStatusLabel(option)}
              />
            ))}
          </RadioGroup>
          <FormHelperText>
            {(touched.activityAdditionalSupport && errors.activityAdditionalSupport) || ' '}
          </FormHelperText>
        </FormControl>
        <Autocomplete
          options={leaders?.results.map((leader) => leader.id) ?? []}
          loading={leadersLoading}
          getOptionLabel={(option) => {
            const leader = leaders?.results.find((item) => item.id === option);
            if (!leader) {
              return 'Loading...';
            }
            const fullName = getFullName(leader);
            return `${fullName} (${leader.status}, ${leader.id})`;
          }}
          multiple
          renderInput={(params) => (
            <TextField
              {...params}
              label="Supporters"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {leadersLoading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          value={values.activityAdditionalSupport === 'NotRequired' ? [] : values.leaderIds}
          disabled={isSubmitting || values.activityAdditionalSupport === 'NotRequired'}
          onChange={async (event, value) => {
            await setFieldValue('leaderIds', value);
          }}
        />
      </FormGrid>
      <CardFooter>
        <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </CardFooter>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </form>
  );
};

export default ActivitySupportForm;

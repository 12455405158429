import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { QrcodeSuccessCallback } from 'html5-qrcode/src/core';
import { useEffect, useId, useRef } from 'react';

type QrScannerProps = {
  onSuccess: QrcodeSuccessCallback
};

// TODO: Fix the weirdness seen when you change the browser zoom - perhaps we should force a
//  rerender when the window is resized
const QrScanner = (props: QrScannerProps): JSX.Element => {
  const { onSuccess } = props;

  const previewId = useId();
  const lastResult = useRef<string>();

  useEffect(() => {
    const scanner = new Html5Qrcode(previewId);
    const config = {
      fps: 10,
      qrbox: 250,
      formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
      showTorchButtonIfSupported: true,
    };

    const handleOnSuccess: QrcodeSuccessCallback = (decodedText, result) => {
      if (decodedText !== lastResult.current) {
        lastResult.current = decodedText;
        onSuccess(decodedText, result);

        // Stop repeated calls for ten seconds to wait for the first call to return.
        setTimeout(() => {
          lastResult.current = undefined;
        }, 10000);
      }
    };

    const didStart = scanner
      .start(
        { facingMode: 'environment' },
        config,
        handleOnSuccess,
        undefined,
      )
      .then(() => true);

    return () => {
      didStart
        .then(() => scanner.stop())
        .catch(() => {
          console.error('Error stopping scanner');
        });
    };
  }, [onSuccess, previewId]);

  return (
    <div id={previewId} />
  );
};

export default QrScanner;

import { TextField as MuiTextField } from '@mui/material';
import styled from 'styled-components';

export const TextField = styled(MuiTextField)`
  margin: 10px;
  background-color: ${({ theme }) => (theme.palette.mode === 'light' ? theme.palette.grey.A200 : theme.palette.grey[800])};
  border-radius: 10px;
  border: none;

  :hover, :focus {
    border: none;
  },
`;

import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import Image from 'mui-image';
import { useState } from 'react';
import { addCmsBaseUrl } from '../../helpers/cmsImage';
import { useActivitiesContent } from '../../helpers/content';
import useErrorHandler from '../../helpers/useErrorHandler';
import { useTheme } from '../../theme';
import BlocksRenderer from '../BlocksRenderer';
import * as Styled from './styles';

type ActivityDialogProps = {
  id: number | string
  name: string
  disabled?: boolean
  hideImageOnMobile?: boolean
};

const ActivityDialog = (props: ActivityDialogProps): JSX.Element => {
  const {
    id,
    name,
    disabled = false,
    hideImageOnMobile = false,
  } = props;

  const { content, error } = useActivitiesContent();
  useErrorHandler(error, 'CMS Error: Failed to load activities content');

  const activity = content?.find((a) => a.id === Number(id));

  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const screenWiderThanMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const showImage = !hideImageOnMobile || screenWiderThanMobile;

  const handleOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Styled.IconButton data-no-dnd="true" aria-label={`${name} - more information`} onClick={handleOpen} color={disabled ? 'default' : 'info'} disabled={disabled}>
        <InfoIcon />
      </Styled.IconButton>
      <Dialog data-no-dnd="true" open={open} onClose={handleClose} scroll="body" maxWidth="sm" fullWidth>
        <DialogTitle>
          {name}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {showImage && activity?.square_image.data && (
          <Styled.ImageWrapper>
            <Image
              alt={name}
              src={addCmsBaseUrl(activity.square_image.data.attributes.url)}
              duration={1000}
            />
          </Styled.ImageWrapper>
        )}
        <DialogContent>
          <DialogContentText>
            {activity?.description && (
              <BlocksRenderer content={activity.description} />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActivityDialog;

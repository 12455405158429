import { Table, TableBody } from '@mui/material';
import { ReactNode } from 'react';

export type ReviewTableProps = {
  children: ReactNode
};

const ReviewTable = (props: ReviewTableProps): JSX.Element => {
  const { children } = props;

  return (
    <Table size="small">
      <TableBody>
        {children}
      </TableBody>
    </Table>
  );
};

export default ReviewTable;

import { objectToCamel, objectToSnake } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { Activity, CreateActivity, UpdateActivity } from '../../models/activity';
import { RegisterItem, UpdateActivityRegisterPayload } from '../../models/register';
import { protectedApi } from './index';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    createActivity: builder.mutation<Activity, CreateActivity>({
      query: (args) => {
        const body = objectToSnake(args);
        return ({
          url: 'activities/',
          method: 'POST',
          body,
        });
      },
      transformResponse: (response: ObjectToSnake<Activity>) => objectToCamel(response),
    }),
    updateActivity: builder.mutation<Activity, UpdateActivity>({
      query: (args) => {
        const { id, ...update } = args;
        const body = objectToSnake(update);
        return ({
          url: `activities/${id}`,
          method: 'PATCH',
          body,
        });
      },
      transformResponse: (response: ObjectToSnake<Activity>) => objectToCamel(response),
    }),
    deleteActivity: builder.mutation<void, number>({
      query: (id) => ({
        url: `activities/${id}`,
        method: 'DELETE',
      }),
    }),
    getActivityRegisterProtected: builder.query<RegisterItem[], {
      activityId: number, sessionId: number,
    }>({
      query: (payload) => `activities/register/${payload.activityId}/${payload.sessionId}`,
      transformResponse: (
        response: ObjectToSnake<RegisterItem[]>,
      ) => objectToCamel(response),
      providesTags: (result, error, { activityId, sessionId }) => (result
        ? [{ type: 'activityRegister', id: `${activityId}-${sessionId}` }]
        : ['activityRegister']),
    }),
    updateActivityRegisterProtected: builder.mutation<void, UpdateActivityRegisterPayload>({
      query: ({ activityId, sessionId, ...body }) => ({
        url: `activities/register/${activityId}/${sessionId}`,
        method: 'PUT',
        body: objectToSnake(body),
      }),
      invalidatesTags: (result, error, { activityId, sessionId }) => [{ type: 'activityRegister', id: `${activityId}-${sessionId}` }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateActivityMutation,
  useUpdateActivityMutation,
  useDeleteActivityMutation,
  useGetActivityRegisterProtectedQuery,
  useUpdateActivityRegisterProtectedMutation,
} = extendedApi;

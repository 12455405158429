import {
  Backdrop,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useState } from 'react';
import ErrorAlert from '../../../../../components/DataPanel/ErrorAlert';
import { FormGridWithTopPadding } from '../../../../../components/FormGrid';
import { useTypeSafeFormik } from '../../../../../helpers/form';
import { CreatePerson } from '../../../../../models/person';
import { useCreatePersonMutation } from '../../../../../state/protectedApi/people';
import sanitiseOnSubmit from './sanitise';
import { validationSchema } from './schema';

type PersonFormDialogProps = {
  packId: number | null
  onSuccess: (personId: number) => void
};

const PersonFormDialog = (props: PersonFormDialogProps): JSX.Element => {
  const { onSuccess, packId } = props;

  const [open, setOpen] = useState(false);
  const [error, setError] = useState<SerializedError | FetchBaseQueryError>();

  const [mutation] = useCreatePersonMutation();

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    resetForm,
    dirty,
  } = useTypeSafeFormik<CreatePerson>({
    initialValues: {
      status: 'Contact',
      packId,
      firstName: '',
      lastName: '',
      mobile: '',
      email: '',
      packBookingContact: false,
      onsite: 'NotAttending',
    },
    validationSchema,
    onSubmit: async (formValues) => {
      const sanitisedValues = sanitiseOnSubmit(formValues);
      await mutation(sanitisedValues).then(async (result) => {
        if ('error' in result) {
          setError(result.error);
        } else {
          onSuccess(result.data.id);
          setOpen(false);
          resetForm();
        }
      });
    },
  });

  const onOpen = (): void => {
    setError(undefined);
    setOpen(true);
  };

  const onClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="outlined" onClick={onOpen}>
        Create new person to add as contact
      </Button>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <form onSubmit={handleSubmit} noValidate>
          <DialogTitle>
            Create Person
          </DialogTitle>
          <DialogContent sx={{ paddingTop: '5px' }}>
            <Collapse in={error !== undefined}>
              {error && (
                <ErrorAlert modelName="person" type="create" error={error} />
              )}
            </Collapse>
            <FormGridWithTopPadding columns={2}>
              <TextField
                label="First name"
                value={values.firstName}
                onChange={(event) => setFieldValue('firstName', event.target.value)}
                onBlur={() => setFieldTouched('firstName')}
                helperText={(touched.firstName && errors.firstName) || ' '}
                error={touched.firstName && Boolean(errors.firstName)}
                disabled={isSubmitting}
              />
              <TextField
                label="Last name"
                value={values.lastName}
                onChange={(event) => setFieldValue('lastName', event.target.value)}
                onBlur={() => setFieldTouched('lastName')}
                helperText={(touched.lastName && errors.lastName) || ' '}
                error={touched.lastName && Boolean(errors.lastName)}
                disabled={isSubmitting}
              />
              <TextField
                label="Mobile number"
                value={values.mobile}
                onBlur={() => setFieldTouched('mobile')}
                onChange={async (event) => {
                  await setFieldValue('mobile', event.target.value);
                }}
                error={touched.mobile && Boolean(errors.mobile)}
                helperText={(touched.mobile && errors.mobile) || ' '}
                inputProps={{ inputMode: 'tel' }}
              />
              <TextField
                label="Email address"
                value={values.email}
                onBlur={() => setFieldTouched('email')}
                onChange={async (event) => {
                  await setFieldValue('email', event.target.value);
                }}
                error={touched.email && Boolean(errors.email)}
                helperText={(touched.email && errors.email) || ' '}
                inputProps={{ inputMode: 'email' }}
              />
            </FormGridWithTopPadding>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting || !dirty}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
          </DialogActions>
          <Backdrop open={isSubmitting}>
            <CircularProgress />
          </Backdrop>
        </form>
      </Dialog>
    </>
  );
};

export default PersonFormDialog;

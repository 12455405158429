import DescriptionIcon from '@mui/icons-material/Description';
import WestIcon from '@mui/icons-material/West';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Button } from '@mui/material';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { isString } from '../../helpers/typeValidation';
import RouterLink from '../RouterLink';
import * as Styled from './styles';

type Icon =
  | 'Back'
  | 'Document'
  | 'WhatsApp';

type IconLinkProps = {
  to?: string
  icon: Icon
  children: ReactNode
};

const getIcon = (name: Icon): ReactNode => {
  switch (name) {
    case 'Back':
      return <WestIcon />;
    case 'Document':
      return <DescriptionIcon />;
    case 'WhatsApp':
      return <WhatsAppIcon />;
    default:
      return undefined;
  }
};

const IconLink = (props: IconLinkProps): JSX.Element => {
  const { to, icon, children } = props;
  const navigate = useNavigate();

  const content = (
    <Styled.Link>
      <Styled.Icon>
        {getIcon(icon)}
      </Styled.Icon>
      <div>
        {children}
      </div>
    </Styled.Link>
  );

  return (
    <Styled.Wrapper>
      {isString(to) ? (
        <RouterLink to={to}>
          {content}
        </RouterLink>
      ) : (
        <Button onClick={() => navigate(-1)}>
          {content}
        </Button>
      )}
    </Styled.Wrapper>
  );
};

export default IconLink;

import keys from '../typedKeys';

// These values should match `protectedApi/config/database.py`
const lengths = {
  default: 250,
  short: 100,
  medium: 1500,
  long: 3000,
  extraLong: 5000,
};

type StringLengths = keyof typeof lengths;
type ValidationParams = [max: number, message: string];

const getValidationParams = (size: StringLengths): ValidationParams => {
  const max = lengths[size];
  const message = `Cannot exceed length of ${max} characters`;
  return [max, message];
};

type MaxLength = {
  [size in StringLengths]: ValidationParams;
};

export const maxLength: MaxLength = <MaxLength>Object.fromEntries(keys(lengths).map(
  (size) => [size, getValidationParams(size)],
));

import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  current: Yup.string().required('Current password is required'),
  new: Yup.string().required('New password is required')
    .min(6, 'Password should be at least 6 characters'),
  confirm: Yup.string().required('You must confirm your new password'),
}).test((values, ctx) => {
  if (values.current === values.new) {
    return ctx.createError({ path: 'new', message: 'New password must be different' });
  }
  if (values.new !== values.confirm) {
    return ctx.createError({ path: 'confirm', message: 'Passwords must match' });
  }
  return true;
});

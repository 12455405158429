import { NewRegistration } from '../../../state/registration/types';

const sanitiseOnSubmit = (rawValues: NewRegistration): NewRegistration => {
  const contacts = rawValues.contacts.map((contact) => ({
    ...contact,
    firstName: contact.firstName.trim(),
    knownAs: contact.knownAs.trim(),
    lastName: contact.lastName.trim(),
    relationship: contact.relationship.trim(),
    mobile: contact.mobile.trim(),
    updates: contact.updates ?? false,
  }));

  return { ...rawValues, contacts };
};

export default sanitiseOnSubmit;

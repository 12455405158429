import { Registration, Review } from '../models/registration';

export const reviewRequired = (
  review: Review | undefined,
): boolean => review !== undefined && review.status !== 'NotRequired';

export const reviewIfRequired = (
  review: Review | undefined,
  ...conditions: boolean[]
): Review | undefined => (
  (reviewRequired(review) && conditions.every((x) => x)) ? review : undefined
);

export const anyReviewRequired = (registration: Registration): boolean => [
  registration.adminReview,
  registration.compassReview,
  registration.activitiesReview,
  registration.cateringReview,
  registration.subcampReview,
  registration.packLeaderReview,
].some(reviewRequired);

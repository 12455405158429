import { objectToCamel, objectToSnake } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { Doctor } from '../../models/doctor';
import { protectedApi } from './index';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getDoctor: builder.query<Doctor, number>({
      query: (personId) => `/doctor/${personId}`,
      transformResponse: (response: ObjectToSnake<Doctor>) => objectToCamel(response),
      providesTags: (result, error, personId) => [{ type: 'doctor', id: personId }],
    }),
    createDoctor: builder.mutation<Doctor, Doctor>({
      query: (args) => {
        const { personId, ...create } = args;
        const body = objectToSnake(create);
        return ({
          url: `doctor/${personId}`,
          method: 'POST',
          body,
        });
      },
      invalidatesTags: (result, error, args) => [{ type: 'doctor', id: args.personId }],
      transformResponse: (response: ObjectToSnake<Doctor>) => objectToCamel(response),
    }),
    updateDoctor: builder.mutation<Doctor, Partial<Doctor> & { personId: number }>({
      query: (args) => {
        const { personId, ...update } = args;
        const body = objectToSnake(update);
        return ({
          url: `doctor/${personId}`,
          method: 'PATCH',
          body,
        });
      },
      invalidatesTags: (result, error, args) => [{ type: 'doctor', id: args.personId }],
      transformResponse: (response: ObjectToSnake<Doctor>) => objectToCamel(response),
    }),
    deleteDoctor: builder.mutation<void, number>({
      query: (personId) => ({
        url: `doctor/${personId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, personId) => [{ type: 'doctor', id: personId }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDoctorQuery,
  useCreateDoctorMutation,
  useUpdateDoctorMutation,
  useDeleteDoctorMutation,
} = extendedApi;

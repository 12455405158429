import {
  Backdrop,
  Button,
  CircularProgress,
  Collapse,
  MenuItem,
  TextField,
} from '@mui/material';
import { FC } from 'react';
import CardFooter from '../../../../../components/CardFooter';
import { FormGrid } from '../../../../../components/FormGrid';
import { FormProps } from '../../../../../components/forms/types';
import { isValidSubcamp, subcamps } from '../../../../../models/subcamp';
import { UserFormValues } from '../../../../../models/user';
import { getUserRoleLabel, isValidUserRole, userRoles } from '../../../../../models/userRole';

const UserForm: FC<FormProps<UserFormValues>> = (props) => {
  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = props;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <FormGrid columns={2}>
        <TextField
          select
          label="User role"
          value={values.role}
          onChange={async (event) => {
            if (isValidUserRole(event.target.value)) {
              await setFieldValue('role', event.target.value);
            }
          }}
          onBlur={() => setFieldTouched('role')}
          helperText={(touched.role && errors.role) || ' '}
          error={touched.role && Boolean(errors.role)}
          disabled={isSubmitting}
        >
          {userRoles.map((option) => (
            <MenuItem key={option} value={option}>
              {getUserRoleLabel(option)}
            </MenuItem>
          ))}
        </TextField>
        <Collapse in={values.role === 'Subcamp'}>
          <TextField
            select
            label="Subcamp"
            value={values.subcamp}
            onChange={async (event) => {
              if (isValidSubcamp(event.target.value)) {
                await setFieldValue('subcamp', event.target.value);
              }
            }}
            onBlur={() => setFieldTouched('subcamp')}
            helperText={(touched.subcamp && errors.subcamp) || ' '}
            error={touched.subcamp && Boolean(errors.subcamp)}
            disabled={isSubmitting || values.role !== 'Subcamp'}
          >
            {subcamps.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Collapse>
      </FormGrid>

      <CardFooter>
        <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </CardFooter>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </form>
  );
};

export default UserForm;

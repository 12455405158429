// TODO use common person model across registration and booking contact
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import { Address } from '../../models/address';
import { District } from '../../models/district';
import { CreateDoctor } from '../../models/doctor';
import { Gender } from '../../models/gender';
import { AttendeeStatus, PersonStatus } from '../../models/personStatus';
import { Region } from '../../models/region';
import { RegistrationType } from '../../models/registrationTypes';
import { StaffTeam } from '../../models/staffTeam';

type NewContact = {
  key: string
  firstName: string
  knownAs: string
  lastName: string
  relationship: string
  mobile: string
  email: string
  attending: boolean | null
  emergency: boolean | null
  updates: boolean | null
};

export interface NewRegistration extends Partial<PersistPartial> {
  meta: {
    type: RegistrationType | null
    pack: string | undefined
    registeringSelf: boolean | null
    region: Region | null
    district: District | null
    hasAdditionalNeeds: boolean | null
    hasAllergies: boolean | null
    hasDietaryRequirements: boolean | null
    hasMedications: boolean | null
    termsAccepted: boolean
  }
  mainContact: NewContact
  attendee: Address & {
    status: AttendeeStatus | null
    staffTeam: StaffTeam | null
    editions: number[]
    firstName: string
    knownAs: string
    lastName: string
    gender: Gender | ''
    dateOfBirth: string | null
    mobile: string
    email: string
    scoutingAppointment: string
    membershipNumber: string
    groupName: string
    mediaConsent: boolean | null
    antihistamineConsent: boolean | null
    painkillerConsent: boolean | null
    suncreamConsent: boolean | null
    afterSunConsent: boolean | null
    additionalNeeds: string
    allergies: string
    dietaryRequirements: string
    medications: string
    sandwichPreference: string
    cateringMessage: string
    activitiesMessage: string
    message: string
  }
  contacts: NewContact[]
  doctor: CreateDoctor
  activityPreferences: {
    id: number | string
    name: string
    rank: number
  }[]
  activityLeadership: {
    [id: number]: {
      name: string
      canLead: boolean | null
    }
  }
}

export type NewPackRegistration = NewRegistration & {
  meta: NewRegistration['meta'] & {
    type: 'pack'
    pack: string
  }
  attendee: NewRegistration['attendee'] & {
    staffTeam: null
  }
};

export const isPackRegistration = (
  value: NewRegistration,
): value is NewPackRegistration => value.meta.type === 'pack' && value.meta.pack !== undefined;

export type NewStaffRegistration = NewRegistration & {
  meta: NewRegistration['meta'] & {
    type: 'staff'
    pack: undefined
  }
  attendee: NewRegistration['attendee'] & {
    status: Omit<'cubs', PersonStatus> | undefined
  }
};

export const isStaffRegistration = (
  value: NewRegistration,
): value is NewStaffRegistration => value.meta.type === 'staff';

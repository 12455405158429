import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { forwardRef } from 'react';
import ActivityDialog from '../../ActivityDialog';
import * as Styled from './styles';

type BaseOptionProps = {
  status: 'active' | 'inactive' | 'placeholder'
  name: string
  rank?: number
  disabled?: boolean
};

export type RankOptionProps = BaseOptionProps & {
  id: UniqueIdentifier
};

export type OptionContentProps = RankOptionProps & {
  transition: string | undefined
  transform: string | undefined
};

const OptionContent = (props: OptionContentProps): JSX.Element => {
  const {
    status,
    transition,
    transform,
    id,
    name,
    rank,
    disabled,
  } = props;

  const placeholderStatus = status === 'placeholder';

  return (
    <Styled.DragOption
      status={status}
      transition={transition}
      transform={transform}
      disabled={disabled}
    >
      <Styled.DragOptionIndex>{placeholderStatus || rank}</Styled.DragOptionIndex>
      {placeholderStatus || (
        <Styled.DragOptionName>
          {name}
        </Styled.DragOptionName>
      )}
      {disabled !== true && (
        <ActivityDialog
          id={id}
          name={name}
          disabled={status === 'placeholder'}
        />
      )}
    </Styled.DragOption>
  );
};

const ListOption = (props: RankOptionProps): JSX.Element => {
  const { id } = props;

  const {
    setNodeRef,
    transform,
    transition,
    attributes,
    listeners,
  } = useSortable({
    id,
  });

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <OptionContent
        transition={transition}
        transform={CSS.Transform.toString(transform)}
        {...props}
      />
    </div>
  );
};

const ActiveOption = forwardRef<HTMLDivElement, RankOptionProps>((
  props,
  ref,
) => (
  <div ref={ref}>
    <OptionContent transition={undefined} transform={undefined} {...props} />
  </div>
));

const RankOption = (props: RankOptionProps): JSX.Element => {
  const {
    status,
  } = props;

  if (status === 'active') {
    return (
      <ActiveOption {...props} />
    );
  }
  return (
    <ListOption {...props} />
  );
};

export default RankOption;

import { onAuthStateChanged, signInWithEmailAndPassword } from '@firebase/auth';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button, Collapse, IconButton, InputAdornment, Stack, TextField,
} from '@mui/material';
import { MouseEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../../firebase';
import { isFirebaseError } from '../../../helpers/errorTypes';
import { useTypeSafeFormik } from '../../../helpers/form';
import routes from '../../../routes';
import { AlertWithBottomMargin } from '../../Alert';
import Card from '../../Card';
import CardHeading from '../../CardHeading';
import { FormGrid } from '../../FormGrid';
import RouterLink from '../../RouterLink';
import { validationSchema } from './schema';

const LoginFormCard = (): JSX.Element => {
  const [firebaseError, setFirebaseError] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search);

  let redirect = urlParams.get('redirect') ?? routes.dashboard;
  if (redirect === routes.login) {
    redirect = routes.dashboard;
  }

  useEffect(() => onAuthStateChanged(auth, (user) => {
    if (user) {
      navigate(redirect, { replace: true });
    }
  }), [navigate, redirect]);

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    setSubmitting,
  } = useTypeSafeFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async () => {
      setFirebaseError(undefined);
      signInWithEmailAndPassword(auth, values.email, values.password)
        .then(() => {
          navigate(redirect, { replace: true });
        })
        .catch((error) => {
          const errorMessage = isFirebaseError(error) && error.message;
          setFirebaseError(errorMessage || 'Unexpected firebase error.');
        }).finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <Card>
      <CardHeading>Login</CardHeading>
      <Collapse in={firebaseError !== undefined}>
        <AlertWithBottomMargin severity="error">
          We were unable to log you in.
          <hr />
          {firebaseError}
        </AlertWithBottomMargin>
      </Collapse>
      <form onSubmit={handleSubmit} noValidate>
        <FormGrid>
          <TextField
            label="Email"
            autoComplete="email"
            value={values.email}
            onBlur={() => setFieldTouched('email')}
            onChange={async (event) => {
              await setFieldValue('email', event.target.value);
            }}
            error={touched.email && Boolean(errors.email)}
            helperText={(touched.email && errors.email) || ' '}
          />
          <TextField
            label="Password"
            id="current-password"
            autoComplete="current-password"
            value={values.password}
            onBlur={() => setFieldTouched('password')}
            onChange={async (event) => {
              await setFieldValue('password', event.target.value);
            }}
            error={touched.password && Boolean(errors.password)}
            helperText={(touched.password && errors.password) || ' '}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(event: MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormGrid>
        <Stack justifyContent="space-between" direction="row">
          <RouterLink to={routes.forgotPassword}>
            Forgot your password?
          </RouterLink>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Login
          </Button>
        </Stack>
      </form>
    </Card>
  );
};

export default LoginFormCard;

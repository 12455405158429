import {
  TableRow, Typography, useMediaQuery,
} from '@mui/material';
import { useTheme } from '../../../theme';
import * as Styled from './styles';

type ReviewHeaderRowProps = {
  children: string
};

const ReviewHeaderRow = (props: ReviewHeaderRowProps): JSX.Element => {
  const { children } = props;

  const theme = useTheme();
  const screenWiderThanMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const tableWidth = screenWiderThanMobile ? 3 : 2;

  return (
    <TableRow>
      <Styled.TableHeaderCell colSpan={tableWidth}>
        <Typography variant="h6" component="h2">{children}</Typography>
      </Styled.TableHeaderCell>
    </TableRow>
  );
};

export default ReviewHeaderRow;

import styled from 'styled-components';
import { responsiveSpacing } from '../../../helpers/responsiveSpacing';

export const RadioFieldWrapper = styled.div`
  ${responsiveSpacing('margin-bottom', 'xs')}
`;

export const DialogButton = styled.div`
  display: inline-block;
  margin-left: 20px;
`;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Activity } from '../../models/activity';
import { CreateDoctor } from '../../models/doctor';
import type { RootState } from '../store';
import { NewRegistration } from './types';

const initialState: NewRegistration = {
  meta: {
    type: 'pack',
    pack: undefined,
    registeringSelf: null,
    region: null,
    district: null,
    hasAdditionalNeeds: null,
    hasAllergies: null,
    hasDietaryRequirements: null,
    hasMedications: null,
    termsAccepted: false,
  },
  mainContact: {
    key: 'main',
    firstName: '',
    knownAs: '',
    lastName: '',
    relationship: '',
    email: '',
    mobile: '',
    emergency: null,
    attending: null,
    updates: true,
  },
  attendee: {
    status: null,
    staffTeam: null,
    editions: [],
    firstName: '',
    knownAs: '',
    lastName: '',
    gender: '',
    dateOfBirth: null,
    mobile: '',
    email: '',
    addressStreet: '',
    addressTown: '',
    addressPostcode: '',
    membershipNumber: '',
    scoutingAppointment: '',
    groupName: '',
    mediaConsent: null,
    antihistamineConsent: null,
    painkillerConsent: null,
    suncreamConsent: null,
    afterSunConsent: null,
    additionalNeeds: '',
    allergies: '',
    dietaryRequirements: '',
    medications: '',
    sandwichPreference: '',
    cateringMessage: '',
    activitiesMessage: '',
    message: '',
  },
  contacts: [],
  doctor: {
    surgeryName: '',
    name: '',
    phone: '',
    addressStreet: '',
    addressTown: '',
  },
  activityPreferences: [],
  activityLeadership: {},
};

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    reset: (
      state,
      action: PayloadAction<Activity[]>,
    ) => ({
      ...initialState,
      meta: {
        ...initialState.meta,
        type: state.meta.type,
        pack: state.meta.pack,
      },
      activityPreferences: action.payload.slice()
        // We randomise the initial order of activities to avoid the starting order impacting the
        // average rank for an activity
        .sort(() => ((Math.random() > 0.5) ? 1 : -1))
        .map((activity, index) => ({
          id: activity.id,
          name: activity.name,
          rank: index + 1,
        })),
      activityLeadership: Object.fromEntries(
        action.payload.slice()
          .filter((activity) => activity.delivery === 'PackLeader')
          .map((activity) => [activity.id, {
            name: activity.name,
            canLead: null,
          }]) ?? [],
      ),
    }),
    update: (
      state,
      action: PayloadAction<Partial<NewRegistration>>,
    ) => ({ ...state, ...action.payload }),
    updateDoctor: (
      state,
      action: PayloadAction<Partial<CreateDoctor>>,
    ) => ({ ...state, doctor: { ...state.doctor, ...action.payload } }),
    updateMeta: (
      state,
      action: PayloadAction<Partial<NewRegistration['meta']>>,
    ) => ({ ...state, meta: { ...state.meta, ...action.payload } }),
  },
});

export const {
  reset, update, updateDoctor, updateMeta,
} = registrationSlice.actions;

export const selectRegistration = (state: RootState): NewRegistration => state.registration;
export const selectDoctorFromRegistration = (
  state: RootState,
): CreateDoctor => state.registration.doctor;

export default registrationSlice.reducer;

import styled from 'styled-components';
import { responsiveSpacing } from '../../../../../helpers/responsiveSpacing';

export const Placeholder = styled.div`
  background-color: ${({ theme }) => theme.palette.grey[200]};
  padding: 16px 20px;
  ${responsiveSpacing('margin-bottom', 'm')}
  min-height: 56px;
  min-width: 263px;
`;

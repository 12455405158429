import { NewRegistration } from '../../../state/registration/types';

const sanitiseOnSubmit = (rawValues: NewRegistration): NewRegistration => {
  const { meta, attendee: rawAttendee, mainContact } = rawValues;
  const attendee = {
    ...rawAttendee,
    staffTeam: meta.type === 'staff' ? rawAttendee.staffTeam : null,
    status: meta.registeringSelf ? 'Leader' : rawAttendee.status,
    firstName: meta.registeringSelf ? mainContact.firstName : rawAttendee.firstName.trim(),
    knownAs: meta.registeringSelf ? mainContact.knownAs : rawAttendee.knownAs.trim(),
    lastName: meta.registeringSelf ? mainContact.lastName : rawAttendee.lastName.trim(),
    mobile: meta.registeringSelf ? mainContact.mobile : rawAttendee.mobile.trim(),
    email: meta.registeringSelf ? mainContact.email : rawAttendee.email,
    membershipNumber: meta.registeringSelf ? rawAttendee.membershipNumber : '',
    scoutingAppointment: meta.registeringSelf ? rawAttendee.scoutingAppointment.trim() : '',
    groupName: meta.type === 'staff' ? rawAttendee.groupName.trim() : '',
    addressStreet: rawAttendee.addressStreet.trim(),
    addressTown: rawAttendee.addressTown.trim(),
    addressPostcode: rawAttendee.addressPostcode.toUpperCase().trim(),
  };

  return { ...rawValues, attendee };
};

export default sanitiseOnSubmit;

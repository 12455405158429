import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
} from '@mui/material';
import ContentLayout from '../../../components/ContentLayout';
import { FormGrid } from '../../../components/FormGrid';
import PageMetaTitle from '../../../components/PageMetaTitle';
import ReviewTagsTable from '../../../components/ReviewTagsTable';
import ReviewTypeSelection from '../../../components/ReviewTypeSelection';
import useErrorHandler from '../../../helpers/useErrorHandler';
import {
  useUrlBooleanParam,
  useUrlOptionalNumberParam,
  useUrlOptionalStringParam,
  useUrlStringListParam,
  useUrlStringParam,
} from '../../../helpers/useUrlParam';
import { userHasRole } from '../../../helpers/user';
import { ReviewType } from '../../../models/reviewType';
import { StaffTeam } from '../../../models/staffTeam';
import { Subcamp } from '../../../models/subcamp';
import { useGetReviewTagSummaryQuery } from '../../../state/protectedApi/reviews';
import { useGetCurrentUserQuery } from '../../../state/protectedApi/user';
import { useGetEditionsQuery } from '../../../state/publicApi/editions';

const ReviewTagSummaryPage = (): JSX.Element => {
  const { data: user } = useGetCurrentUserQuery();

  const [reviewType, setReviewType] = useUrlStringParam<ReviewType>('type', user?.role === undefined || user?.role === 'SuperUser' ? 'Admin' : user.role);
  const [subcamp, setSubcamp] = useUrlOptionalStringParam<Subcamp | ''>('subcamp', user?.subcamp ?? undefined);
  const [packId, setPackId] = useUrlOptionalNumberParam('pack', user?.packId ?? undefined);
  const [districtId, setDistrictId] = useUrlOptionalNumberParam('district');
  const [editionId, setEditionId] = useUrlOptionalNumberParam('edition');
  const [staffTeams, setStaffTeams] = useUrlStringListParam<StaffTeam>('teams', []);
  const [combinations, setCombinations] = useUrlBooleanParam('combinations', false);

  const {
    currentData: data,
    isFetching,
    error,
  } = useGetReviewTagSummaryQuery({
    reviewType, subcamp, packId, districtId, editionId, combinations, staffTeams,
  });
  useErrorHandler(error, 'API Error: Failed to load review tag summary');

  const reviewTagData = data?.slice().filter((tag) => tag.people.length > 0);

  const showEditionSelection = userHasRole(user, ['Admin', 'Activities', 'Subcamp', 'Catering']);
  const {
    data: editions,
    isLoading: editionsLoading,
  } = useGetEditionsQuery(undefined, { skip: !showEditionSelection });

  const missingSubcampSelection = userHasRole(user, 'Admin') && reviewType === 'Subcamp' && subcamp === undefined && !isFetching;
  const missingPackSelection = userHasRole(user, 'Admin') && reviewType === 'PackLeader' && packId === undefined && !isFetching;

  return (
    <ContentLayout>
      <PageMetaTitle title="Review Tag Summary" />
      <h1>Review Tag Summary</h1>
      <FormGrid>
        <FormControlLabel
          label="View unique tag combinations"
          control={(
            <Checkbox
              checked={combinations}
              onChange={(event) => setCombinations(event.target.checked)}
            />
          )}
        />

        {showEditionSelection && (
          <Autocomplete
            options={editions?.map((edition) => edition.id) ?? []}
            loading={editionsLoading}
            getOptionLabel={(id) => editions?.find((edition) => edition.id === id)?.name ?? ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Edition"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {editionsLoading ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            value={editionId ?? null}
            onChange={(event, value) => {
              setEditionId(value ?? undefined);
            }}
          />
        )}
      </FormGrid>
      {userHasRole(user, 'Admin') && (
        <ReviewTypeSelection
          reviewType={reviewType}
          packId={packId}
          districtId={districtId}
          subcamp={subcamp}
          staffTeams={staffTeams}
          reviewTypeOnChange={setReviewType}
          packIdOnChange={setPackId}
          districtIdOnChange={setDistrictId}
          subcampOnChange={setSubcamp}
          staffTeamsOnChange={setStaffTeams}
        />
      )}
      <hr />
      {isFetching && (
        <>
          <p>Loading review tag summary...</p>
          <CircularProgress />
        </>
      )}
      {!missingPackSelection
        && !missingSubcampSelection
        && !isFetching
        && reviewTagData && reviewTagData.length === 0 && (
        <p>No tags have been found</p>
      )}
      {!isFetching && reviewTagData && reviewTagData.length > 0 && (
        <ReviewTagsTable data={reviewTagData} />
      )}
    </ContentLayout>
  );
};

export default ReviewTagSummaryPage;

import * as Yup from 'yup';
import { emailRegex, membershipNumberRegex, phoneNumberRegex } from '../regex';
import { maxLength } from './maxLength';

// TODO: type to only keys of a shared person model
const personRules = {
  firstName: Yup.string().max(...maxLength.short),
  knownAs: Yup.string().max(...maxLength.short).nullable(),
  lastName: Yup.string().max(...maxLength.short),
  scoutingAppointment: Yup.string().max(...maxLength.default),
  membershipNumber: Yup.string()
    .matches(membershipNumberRegex, 'Must be between 4 and 10 digits without spaces')
    .max(...maxLength.short),
  email: Yup.string().matches(emailRegex, 'Invalid email format').max(...maxLength.default),
  mobile: Yup.string().matches(phoneNumberRegex, 'Invalid phone number format')
    .max(...maxLength.short),
  addressStreet: Yup.string().max(...maxLength.default),
  addressTown: Yup.string().max(...maxLength.default),
  addressPostcode: Yup.string().max(...maxLength.short),
  additionalNeeds: Yup.string().max(...maxLength.long),
  allergies: Yup.string().max(...maxLength.medium),
  dietaryRequirements: Yup.string().max(...maxLength.medium),
  medications: Yup.string().max(...maxLength.long),
  activitiesMessage: Yup.string().max(...maxLength.medium),
  cateringMessage: Yup.string().max(...maxLength.medium),
  message: Yup.string().max(...maxLength.medium),
  internalNotes: Yup.string().max(...maxLength.medium),
  relationship: Yup.string().max(...maxLength.default),
  groupName: Yup.string().nullable().max(...maxLength.default),
};

export default personRules;

import ErrorCard from '../../components/ErrorCard';
import PageLayout from '../../components/PageLayout';
import PageMetaTitle from '../../components/PageMetaTitle';

const ErrorPage = (): JSX.Element => (
  <PageLayout>
    <PageMetaTitle title="Error" />
    <ErrorCard />
  </PageLayout>
);

export default ErrorPage;

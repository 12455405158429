import {
  ExecutionProps,
  Interpolation,
  css,
} from 'styled-components';
import { Breakpoint, breakpoints, minWidthQuery } from './responsive';
import { SpacingSet, spacingSetByBreakpoint } from './responsiveSpacing';
import keys from './typedKeys';

type DeviceSizeDetail = {
  totalColumns: number;
  breakpoint?: Breakpoint;
};

export const deviceSizeDetails: Record<string, DeviceSizeDetail> = {
  mobile: { totalColumns: 4 },
  tablet: { totalColumns: 8, breakpoint: 'medium' },
  desktop: { totalColumns: 12, breakpoint: 'large' },
};

export type Device = keyof typeof deviceSizeDetails;

const gutterSpacing = 'xs';
const pageMarginSpacing = 's';

const contentWidthMinimum = 320;
const contentWidthMaximum = 1920;

const deviceByMinWidth = (minWidth: number): [
  Device, DeviceSizeDetail,
] => Object.entries(deviceSizeDetails)
  .sort(
    ([, a], [, b]) => (b.breakpoint ? breakpoints[b.breakpoint] : 0)
        - (a.breakpoint ? breakpoints[a.breakpoint] : 0),
  )
  .filter(
    ([, { breakpoint }]) => minWidth >= (breakpoint ? breakpoints[breakpoint] : 0),
  )[0];

const calculateComponentWidth = (
  columnRatio: number,
  spacingSet: SpacingSet,
): Interpolation<ExecutionProps> => {
  // This width equation is refactored to one line, (inc. `100vw`) in CSS:
  //
  //   columnWidth = (bounded(100vw - margins) - totalGutterWidth) / totalColumns
  //   width = numberOfComponentColumns * columnWidth + additionalGutterWidth
  //
  // NB: bounds adjusted for just 100vw & enforced with `clamp`.

  const gutterWidth = spacingSet[gutterSpacing];
  const marginWidth = spacingSet[pageMarginSpacing];

  // This is the margin, corrected for fewer gutters, before scaling by the columnRatio
  const relativeMarginWidth = marginWidth - 0.5 * gutterWidth * (1 - 1 / columnRatio);

  return css`
    width: calc(
      ${columnRatio} *
        (
          clamp(${contentWidthMinimum}px, 100vw, ${contentWidthMaximum}px) - 2 *
            ${relativeMarginWidth}px
        )
    );
  `;
};

const componentWidthByBreakpoint = (
  columnRatio: number,
  breakpoint?: Breakpoint,
): Interpolation<ExecutionProps> => {
  const spacingSet = spacingSetByBreakpoint[breakpoint ?? 'small'];
  const widthCss = calculateComponentWidth(columnRatio, spacingSet);

  if (breakpoint) {
    return minWidthQuery(breakpoints[breakpoint])`${widthCss}`;
  }
  return widthCss;
};

export const responsiveWidth = (
  componentColumnsByDevice: Record<Device, number>,
): Interpolation<object> => [
  undefined, ...keys(breakpoints),
].map((breakpoint) => {
  const minWidth = breakpoint ? breakpoints[breakpoint] : 0;
  const [device, { totalColumns }] = deviceByMinWidth(minWidth);

  return componentWidthByBreakpoint(
    componentColumnsByDevice[device] / totalColumns || 1,
    breakpoint,
  );
});

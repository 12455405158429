import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { DataPanelMode } from '../../../helpers/dataPanelMode';

type EditModeToggleProps = {
  mode: DataPanelMode
  onEditClick: () => void
  onCancelClick: () => void
};

const EditModeToggle = (props: EditModeToggleProps): JSX.Element => {
  const { mode, onEditClick, onCancelClick } = props;
  const editMode = mode === 'form';

  return (
    <IconButton aria-label={editMode ? 'cancel editing' : 'start editing'} onClick={editMode ? onCancelClick : onEditClick}>
      {editMode ? <CancelIcon /> : <EditIcon />}
    </IconButton>
  );
};

export default EditModeToggle;

import { Bar, Rectangle } from 'recharts';
import DashboardHorizontalBarChart, {
  DashboardHorizontalBarChartProps,
} from '../../../../../components/DashboardHorizontalBarChart';
import { BallotStatistics } from '../../../../../models/ballotStatistics';
import { District } from '../../../../../models/district';
import { Region } from '../../../../../models/region';
import { CountData } from '../../../../../models/statistics';
import { useGetBallotStatisticsQuery } from '../../../../../state/protectedApi/statistics';
import {
  useGetDistrictsQuery,
  useGetRegionsQuery,
} from '../../../../../state/publicApi/reference';
import colours from '../../../../../theme/colours';
import * as Styled from './styles';

const CustomTooltip = (stats?: BallotStatistics, regions?: Region[], districts?: District[]): DashboardHorizontalBarChartProps<number, string>['tooltipContent'] => (props) => {
  const { payload } = props;
  if (!payload || !payload.length) {
    return null;
  }
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
  const regionId = payload[0].payload.id;
  if (!regionId || typeof regionId !== 'number') {
    return null;
  }
  const regionStats = stats?.regions.find((r) => r.id === regionId);
  const region = regions?.find((r) => r.id === regionId);
  if (!region || !regionStats) {
    return null;
  }
  const districtsForRegion = districts?.filter((district) => district.regionId === regionId) ?? [];

  const displayData = districtsForRegion.map((district) => {
    const districtStats = stats?.districts.find((d) => d.id === district.id);
    return {
      id: district.id,
      name: district.name,
      value: districtStats?.packCount ?? 0,
    };
  })
    .sort((a, b) => (b.name < a.name ? 1 : -1))
    .sort((a, b) => b.value - a.value);

  return (
    <Styled.TooltipContainer>
      <Styled.TooltipTitle>{region.name}</Styled.TooltipTitle>
      <table>
        <thead>
          <tr>
            <th>District</th>
            <th>Packs</th>
          </tr>
        </thead>
        <tbody>
          {displayData.map((district) => (
            <tr key={district.id}>
              <Styled.NameCell>{district.name}</Styled.NameCell>
              <Styled.ValueCell>
                {district.value}
              </Styled.ValueCell>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <Styled.TotalCell>
              Total
            </Styled.TotalCell>
            <Styled.TotalValueCell>
              {regionStats.packCount}
            </Styled.TotalValueCell>
          </tr>
        </tfoot>
      </table>
    </Styled.TooltipContainer>
  );
};

const BallotByLocation = (): JSX.Element => {
  const { data: stats } = useGetBallotStatisticsQuery(undefined);
  const { data: regions } = useGetRegionsQuery();
  const { data: districts } = useGetDistrictsQuery();

  const data: CountData[] = stats?.regions.map((item) => {
    const region = regions?.find((r) => r.id === item.id);
    return {
      id: region?.id,
      name: region?.name ?? '...',
      count: item.packCount,
    };
  }) ?? [];

  return (
    <DashboardHorizontalBarChart
      title="Regions"
      data={data}
      tooltipContent={CustomTooltip(stats, regions, districts)}
      yAxisDataKey="name"
      noDataMessage="No ballot entries!"
    >
      <Bar
        dataKey="count"
        animationDuration={1000}
        fill={colours.teal}
        shape={(
          <Rectangle
            radius={[0, 20, 20, 0]}
          />
        )}
      />
    </DashboardHorizontalBarChart>
  );
};

export default BallotByLocation;

import { Table as MuiTable } from '@mui/material';
import styled from 'styled-components';

const Table = styled(MuiTable)`
  .MuiTableCell-head {
    font-weight: bold;
  }
`;

export default Table;

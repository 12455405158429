import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Backdrop,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { MouseEvent, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AlertWithBottomMargin } from '../../components/Alert';
import Card from '../../components/Card';
import CardFooter from '../../components/CardFooter';
import CardHeading from '../../components/CardHeading';
import EmailLink from '../../components/EmailLink';
import { FormGrid } from '../../components/FormGrid';
import PageMetaTitle from '../../components/PageMetaTitle';
import { auth } from '../../firebase';
import { useTypeSafeFormik } from '../../helpers/form';
import useErrorHandler from '../../helpers/useErrorHandler';
import routes from '../../routes';
import { useAttachFirebaseIdMutation, useCheckUserCodeQuery } from '../../state/protectedApi/user';
import { useGetEventConfigQuery } from '../../state/publicApi';
import { validationSchema } from './schema';

const SignUpPage = (): JSX.Element => {
  const { userCode } = useParams();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string>();

  const {
    data: eventConfig,
    isLoading: eventConfigIsLoading,
    error: eventConfigError,
  } = useGetEventConfigQuery();
  useErrorHandler(eventConfigError, 'Error loading event config');

  const {
    isLoading: userCodeIsLoading,
    error: userCodeError,
    isSuccess: userCodeIsSuccess,
  } = useCheckUserCodeQuery(userCode ?? skipToken);

  useEffect(() => {
    if (userCodeError) {
      navigate(routes.dashboard);
    }
  }, [navigate, userCodeError]);

  const [attach] = useAttachFirebaseIdMutation();

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    setSubmitting,
  } = useTypeSafeFormik({
    initialValues: {
      email: searchParams.get('email') || '',
      password: '',
      confirm: '',
    },
    validationSchema,
    onSubmit: async (submittedValues) => {
      setError(undefined);
      createUserWithEmailAndPassword(auth, submittedValues.email, submittedValues.password)
        .then(async (userCredential) => {
          await attach({
            firebaseId: userCredential.user.uid,
            code: userCode || '',
          }).then(() => {
            navigate(routes.dashboard);
          }).catch(async (attachError) => {
            const { message } = attachError as { message: string };
            setError(message);
          });
        })
        .catch((createError) => {
          const { message } = createError as { message: string };
          setError(message);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const showPasswordButton = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setShowPassword(!showPassword)}
        onMouseDown={(event: MouseEvent<HTMLButtonElement>) => event.preventDefault()}
        edge="end"
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  if (!eventConfig || eventConfigIsLoading || userCodeIsLoading) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <Card>
      <PageMetaTitle title="User Sign Up" />
      <CardHeading>Create your account</CardHeading>
      <Collapse in={error !== undefined}>
        <AlertWithBottomMargin severity="error">
          {error}
          <hr />
          We were unable to create your account. Please try again, and if your issue persists then
          email us on
          {' '}
          <EmailLink email={eventConfig.email} />
        </AlertWithBottomMargin>
      </Collapse>
      {userCodeIsSuccess && (
        <form onSubmit={handleSubmit}>
          <FormGrid>
            <TextField
              label="Username (email address)"
              id="email"
              autoComplete="email"
              value={values.email}
              onBlur={() => setFieldTouched('email')}
              onChange={async (event) => {
                await setFieldValue('email', event.target.value);
              }}
              error={touched.email && Boolean(errors.email)}
              helperText={(touched.email && errors.email) || ' '}
            />
            <TextField
              label="New password"
              id="new-password"
              autoComplete="new-password"
              value={values.password}
              onBlur={() => setFieldTouched('password')}
              onChange={async (event) => {
                await setFieldValue('password', event.target.value);
              }}
              error={touched.password && Boolean(errors.password)}
              helperText={(touched.password && errors.password) || ' '}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: showPasswordButton,
              }}
            />
            <TextField
              label="Confirm password"
              id="confirm-password"
              autoComplete="confirm-password"
              value={values.confirm}
              onBlur={() => setFieldTouched('confirm')}
              onChange={async (event) => {
                await setFieldValue('confirm', event.target.value);
              }}
              error={touched.confirm && Boolean(errors.confirm)}
              helperText={(touched.confirm && errors.confirm) || ' '}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: showPasswordButton,
              }}
            />
          </FormGrid>
          <CardFooter>
            <Button color="primary" variant="contained" type="submit" disabled={isSubmitting || !userCodeIsSuccess}>
              Create account
            </Button>
          </CardFooter>
        </form>
      )}
    </Card>
  );
};

export default SignUpPage;

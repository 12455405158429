type ApiHttpError = {
  data: {
    message?: string
  }
  status: number
};

export const isApiHttpError = (error: unknown): error is ApiHttpError => typeof error === 'object' && error !== null && 'data' in error && 'status' in error;

type ApiPayloadValidationError = ApiHttpError & {
  data: {
    errors: {
      [attribute: string]: string
    }
    message: string
  }
};

export const isApiPayloadValidationError = (error: unknown): error is ApiPayloadValidationError => isApiHttpError(error) && typeof error.data === 'object' && error.data !== null && 'errors' in error.data && 'message' in error.data && error.data.message === 'Input payload validation failed';

export const getPayloadValidationErrors = (error: ApiPayloadValidationError): string[] => Object.keys(error.data.errors).map((attribute) => `${attribute}: ${error.data.errors[attribute]}`);

type FirebaseError = {
  code: string
  message: string
};

export const isFirebaseError = (error: unknown): error is FirebaseError => typeof error === 'object' && error !== null && 'code' in error && 'message' in error;

import SVG from 'react-inlinesvg';
import { auth } from '../../firebase';
import { useScrollToTopOnNavigation } from '../../helpers/windowScroll';
import PageLayout from '../PageLayout';
import howlLogo from './howl-logo.svg';
import * as Styled from './styles';

type FormLayoutProps = {
  children: JSX.Element
};

const FormLayout = (props: FormLayoutProps): JSX.Element => {
  const { children } = props;

  useScrollToTopOnNavigation();

  const content = (
    <Styled.Layout>
      <Styled.Logo>
        <SVG src={howlLogo} />
      </Styled.Logo>
      {children}
    </Styled.Layout>
  );

  if (auth.currentUser === null) {
    return content;
  }

  return (
    <PageLayout limitContentWidth={false}>
      {content}
    </PageLayout>
  );
};

export default FormLayout;

import {
  AlertTitle,
  Backdrop,
  Button,
  CircularProgress, Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel, Radio, RadioGroup,
} from '@mui/material';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { useNavigate } from 'react-router-dom';
import { AlertWithBottomMargin } from '../../../components/Alert';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import CardIntroduction from '../../../components/CardIntroduction';
import { FormGrid } from '../../../components/FormGrid';
import IconLink from '../../../components/IconLink';
import { useBookingsContent } from '../../../helpers/content';
import formatNumber from '../../../helpers/content/formatNumber';
import { useFormikWithStateSync } from '../../../helpers/form';
import routes from '../../../routes';
import { selectBooking, update } from '../../../state/booking';
import { useGetEventConfigQuery } from '../../../state/publicApi';
import { useGetEditionsQuery } from '../../../state/publicApi/editions';
import { validationSchema } from './schema';

const BookingTravelFormPage = (): JSX.Element => {
  const navigate = useNavigate();

  const { content } = useBookingsContent();
  const { data: eventConfig } = useGetEventConfigQuery();
  const { data: editions } = useGetEditionsQuery();

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormikWithStateSync(
    selectBooking,
    update,
    {
      validationSchema,
      onSubmit: async (submittedValues) => {
        if (submittedValues.meta.showEditionsChoice) {
          navigate(routes.bookings.editions);
        } else {
          navigate(routes.bookings.review);
        }
      },
    },
  );

  if (!eventConfig || !editions || !content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  const rebateEligible = content.travel.options.find(
    (opt) => opt.label === values.pack.travelIndication,
  )?.rebate_eligible;

  const {
    pack: {
      cubs, youngLeaders, otherChildren, leaders,
    },
  } = values;
  const totalAttendance = cubs + youngLeaders + otherChildren + leaders;
  const rebateValue = formatNumber(eventConfig.prices.groupTravelRebatePerPerson * totalAttendance, 'currency');

  return (
    <Card>
      <form onSubmit={handleSubmit} noValidate>
        <CardHeading>{content.travel.title}</CardHeading>
        <CardIntroduction>
          <BlocksRenderer content={content.travel.body} />
        </CardIntroduction>
        <Collapse in={rebateEligible === true}>
          <AlertWithBottomMargin severity="success">
            <AlertTitle>You are eligible for the rebate</AlertTitle>
            {`Your pack will receive a rebate of ${rebateValue} after the event for traveling as a group.`}
          </AlertWithBottomMargin>
        </Collapse>
        <Collapse in={rebateEligible === false}>
          <AlertWithBottomMargin severity="warning">
            <AlertTitle>You are not eligible for the rebate</AlertTitle>
            {`Your pack is missing out on a rebate of ${rebateValue} because you have selected a travel option that is
              not eligible for a rebate. If you are successful in the ballot you will be able to change your response
              to a group travel method.`}
          </AlertWithBottomMargin>
        </Collapse>
        <FormGrid>
          <FormControl
            error={touched.pack?.travelIndication && Boolean(errors.pack?.travelIndication)}
          >
            <FormLabel id="travel-label">{content.travel.label}</FormLabel>
            <RadioGroup
              aria-labelledby="travel-label"
              value={values.pack.travelIndication}
              onChange={async (event) => {
                await setFieldValue('pack.travelIndication', event.target.value);
              }}
              onBlur={() => setFieldTouched('pack.travelIndication')}
            >
              {content.travel.options.map((option) => (
                <FormControlLabel
                  key={option.id}
                  value={option.label}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
            <FormHelperText>
              {(touched.pack?.travelIndication && errors.pack?.travelIndication) || ' '}
            </FormHelperText>
          </FormControl>
        </FormGrid>
        <CardFooter>
          <IconLink icon="Back" to={routes.bookings.estimates}>Back</IconLink>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Next
          </Button>
        </CardFooter>
      </form>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </Card>
  );
};

export default BookingTravelFormPage;

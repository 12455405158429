import { skipToken } from '@reduxjs/toolkit/query';
import { FC } from 'react';
import { getFullName, isAttending } from '../../../helpers/person';
import toSentenceCase from '../../../helpers/sentenceCase';
import { Contact } from '../../../models/contact';
import { Person } from '../../../models/person';
import { getPersonStatusLabel } from '../../../models/personStatus';
import routes from '../../../routes';
import { useGetPackQuery } from '../../../state/protectedApi/packs';
import { useGetPersonQuery } from '../../../state/protectedApi/people';
import { useGetStaffQuery } from '../../../state/protectedApi/staff';
import EmailLink from '../../EmailLink';
import PhoneLink from '../../PhoneLink';
import RouterLink from '../../RouterLink';
import TableKeyValueRow from '../../TableKeyValueRow';

type ContactRowProps = {
  contact: Contact
};

const formatContactName = (contact: Contact, person: Person): string => {
  let name = toSentenceCase(contact.relationship);
  if (isAttending(person)) {
    name += ' (also attending)';
  }
  return name;
};

const ContactRow: FC<ContactRowProps> = (props) => {
  const { contact } = props;

  const {
    data: person,
  } = useGetPersonQuery(contact.personId ?? skipToken);
  const {
    data: staff,
  } = useGetStaffQuery(contact.personId ?? skipToken);
  const {
    data: pack,
  } = useGetPackQuery(person?.packId ?? skipToken);

  if (person === undefined) {
    return null;
  }

  return (
    <TableKeyValueRow key={contact.id} name={formatContactName(contact, person)}>
      {contact.personId && (
        <>
          <RouterLink to={routes.people.detail(contact.personId)}>
            {getFullName(person)}
          </RouterLink>
          <br />
        </>
      )}
      {person.mobile && (
        <>
          <PhoneLink phoneNumber={person.mobile} />
          <br />
        </>
      )}
      {person.email && (
        <>
          <EmailLink email={person.email} />
          <br />
        </>
      )}
      {isAttending(person) ? (
        <>
          <strong>{staff?.customRole ?? getPersonStatusLabel(person.status)}</strong>
          {staff?.staffTeam && (
            <>
              <br />
              {`Staff - ${staff.staffTeam}`}
            </>
          )}
          {pack && (
            <>
              <br />
              {`${pack.reference}: ${pack.name}`}
            </>
          )}
        </>
      ) : (
        <>
          Possibly attending:&nbsp;&nbsp;&nbsp;
          <strong>{contact.possiblyAttending ? 'Yes' : 'No'}</strong>
        </>
      )}
      <br />
      Send general updates:&nbsp;&nbsp;&nbsp;
      <strong>{contact.updates ? 'Yes' : 'No'}</strong>
      <br />
      Contact in an emergency:&nbsp;&nbsp;&nbsp;
      <strong>{contact.emergency ? 'Yes' : 'No'}</strong>
    </TableKeyValueRow>
  );
};

export default ContactRow;

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import bookingReducer from './booking';
import { strapiContent } from './content';
import errorReducer from './error';
import gridsReducer from './grids';
import { protectedApi } from './protectedApi';
import { publicApi } from './publicApi';
import registrationReducer from './registration';

const rootReducer = combineReducers({
  booking: persistReducer({
    key: 'booking',
    storage,
  }, bookingReducer),
  grids: persistReducer({
    key: 'grids',
    storage,
  }, gridsReducer),
  registration: persistReducer({
    key: 'registration',
    storage,
  }, registrationReducer),
  error: errorReducer,
  [protectedApi.reducerPath]: protectedApi.reducer,
  [publicApi.reducerPath]: publicApi.reducer,
  [strapiContent.reducerPath]: strapiContent.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  // Adding the protectedApi middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(protectedApi.middleware)
    .concat(publicApi.middleware)
    .concat(strapiContent.middleware)
    .concat(thunk),
});

// export type RootState = ReturnType<typeof store.getState>;

export type RootState = ReturnType<typeof rootReducer>;

export const persistor = persistStore(store);

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

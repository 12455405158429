import { FC } from 'react';
import DataGrid from '../../../../components/DataGrid';
import { useGetAllocationForSessionQuery } from '../../../../state/protectedApi/activitySessions';
import { columns } from './columns';

type SessionRegisterProps = {
  sessionId: number;
};

const SessionRegister: FC<SessionRegisterProps> = (props) => {
  const { sessionId } = props;

  const { currentData, isFetching } = useGetAllocationForSessionQuery(sessionId);
  const processedData = currentData?.map((row) => ({
    ...row,
    checkedInAt: row.checkedInAt ? new Date(row.checkedInAt) : null,
  }));

  return (
    <div>
      <h2>Session Register</h2>
      <DataGrid
        columns={columns}
        rows={processedData ?? []}
        loading={isFetching}
        disableExternalMargin
      />
    </div>
  );
};

export default SessionRegister;

import { objectToCamel } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { Edition, EditionWithProtected } from '../../models/edition';
import { protectedApi } from './index';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getEditionsWithProtected: builder.query<EditionWithProtected[], void>({
      query: () => 'editions/protected',
      transformResponse: (
        response: ObjectToSnake<EditionWithProtected[]>,
      ) => objectToCamel(response),
    }),
    getEditionsForCurrentUser: builder.query<EditionWithProtected[], void>({
      query: () => 'editions/user',
      transformResponse: (response: ObjectToSnake<Edition[]>) => objectToCamel(response),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetEditionsWithProtectedQuery,
  useGetEditionsForCurrentUserQuery,
} = extendedApi;

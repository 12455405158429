import { objectToCamel } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { Meal } from '../../models/meal';
import { publicApi } from './index';

const extendedApi = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    getMeal: builder.query<Meal, number>({
      query: (id) => `meals/${id}`,
      transformResponse: (response: ObjectToSnake<Meal>) => objectToCamel(response),
      providesTags: (meal) => (meal ? [{ type: 'meals', id: meal.id }] : ['meals']),
    }),
    getMeals: builder.query<Meal[], void>({
      query: () => 'meals/',
      transformResponse: (response: ObjectToSnake<Meal[]>) => objectToCamel(response),
      providesTags: (meals) => (meals && meals.length
        ? meals.map((meal) => ({ type: 'meals', id: meal.id }))
        : ['meals']),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMealQuery,
  useGetMealsQuery,
} = extendedApi;

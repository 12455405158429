import { TableRow, useMediaQuery } from '@mui/material';
import { ReactNode } from 'react';
import { useTheme } from '../../../theme';
import MultiLineText from '../../MultiLineText';
import RouterLink from '../../RouterLink';
import TableCell from '../../TableCell';

type ReviewRowProps = {
  name: string | undefined
  children: ReactNode
  route: string
};

const ReviewRow = (props: ReviewRowProps): JSX.Element => {
  const { name, children, route } = props;

  const theme = useTheme();
  const screenWiderThanMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const childrenColumns = name === undefined ? 2 : 1;

  return (
    <TableRow>
      {name && (
        <TableCell>{name}</TableCell>
      )}
      <TableCell colSpan={childrenColumns}>
        {typeof children === 'string' ? (
          <MultiLineText>
            {children}
          </MultiLineText>
        ) : children}
      </TableCell>
      {screenWiderThanMobile && (
        <TableCell>
          <RouterLink to={route}>Change</RouterLink>
        </TableCell>
      )}
    </TableRow>
  );
};

export default ReviewRow;

import { ReactElement, ReactNode, cloneElement } from 'react';
import { coerceToList } from '../../helpers/arrays';
import useElementSize from '../../helpers/useElementSize';
import { columnWidthPx, gutterPx, minGridColumnsWidth } from './config';
import * as Styled from './styles';

type ChildElement = ReactElement<{ columns?: number }> | false | undefined;

type DashboardProps = {
  children: ChildElement | ChildElement[];
  filterBar?: ReactNode | ReactNode[]
};

const renderChild = (
  child: ChildElement,
  maxColumns: number,
  key: number,
): DashboardProps['children'] => {
  if (child) {
    const defaultColumns: number = child.props.columns ?? 1;
    return cloneElement(child, { columns: Math.min(defaultColumns, maxColumns), key });
  }
  return child;
};

const Dashboard = (props: DashboardProps): JSX.Element => {
  const { children, filterBar } = props;

  const [wrapperRef, { width: wrapperWidthPx }] = useElementSize();

  const maxColumns = Math.max(
    minGridColumnsWidth,
    Math.floor((wrapperWidthPx - gutterPx) / (columnWidthPx + gutterPx)),
  );
  // Only allow even numbers of columns
  const columns = maxColumns % 2 === 0 ? maxColumns : maxColumns - 1;

  return (
    <Styled.Wrapper ref={wrapperRef}>
      <Styled.Grid columns={columns}>
        {filterBar && (
          <Styled.FilterBar columns={columns}>
            {filterBar}
          </Styled.FilterBar>
        )}
        {coerceToList(children).map((child, index) => renderChild(child, columns, index))}
      </Styled.Grid>
    </Styled.Wrapper>
  );
};

export default Dashboard;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ActivitiesPageContent } from '../../models/content/activitiesPage';
import { ActivityContent } from '../../models/content/activityContent';
import { BookingPagesContent } from '../../models/content/bookingPages';
import { FaqPageContent } from '../../models/content/faqPage';
import { HomePageContent } from '../../models/content/homePage';
import { RegistrationPagesContent } from '../../models/content/registrationPages';

const baseUrl = process.env.REACT_APP_CMS_BASE_URL ?? '';
const apiToken = process.env.REACT_APP_CMS_API_TOKEN ?? '';

type ListResponse<T> = {
  data: { id: number, attributes: Omit<T, 'id'> }[];
};

type EntryResponse<T> = {
  data: { attributes: T };
};

export const strapiContent = createApi({
  reducerPath: 'strapiContent',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/cms/`,
    prepareHeaders: async (headers) => {
      if (apiToken) {
        headers.set('Authorization', `Bearer ${apiToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getHomeContent: builder.query<HomePageContent, void>({
      query: () => 'home-page',
      transformResponse: (response: EntryResponse<HomePageContent>) => response.data.attributes,
    }),
    getActivitiesContent: builder.query<ActivityContent[], void>({
      query: () => {
        const populate = [
          'square_image',
        ].join(',');
        return ({ url: 'activities', params: { populate, 'pagination[pageSize]': 100 } });
      },
      transformResponse: (response: ListResponse<ActivityContent>) => response.data.map((a) => ({
        id: a.id,
        ...a.attributes,
      })),
    }),
    getActivitiesPageContent: builder.query<ActivitiesPageContent, void>({
      query: () => 'activities-page',
      transformResponse: (
        response: EntryResponse<ActivitiesPageContent>,
      ) => response.data.attributes,
    }),
    getFaqContent: builder.query<FaqPageContent, void>({
      query: () => ({ url: 'faq-page', params: { populate: 'categories.items' } }),
      transformResponse: (response: EntryResponse<FaqPageContent>) => response.data.attributes,
    }),
    getBookingsContent: builder.query<BookingPagesContent, void>({
      query: () => {
        const populate = [
          'closed',
          'complete',
          'start',
          'travel.options',
          'editions',
          'confirmation',
        ].join(',');
        return ({ url: 'booking-form', params: { populate } });
      },
      transformResponse: (response: EntryResponse<BookingPagesContent>) => response.data.attributes,
    }),
    getRegistrationsContent: builder.query<RegistrationPagesContent, void>({
      query: () => {
        const populate = [
          'closed',
          'complete',
          'start.pack',
          'start.staff',
          'contact',
          'attendee.self',
          'attendee.other',
          'media.self',
          'media.other',
          'additional_information',
          'first_aid.medications',
          'first_aid.sun_protection',
          'catering.sandwich.options',
          'emergency_contacts',
          'doctor',
          'pack_activities.cub',
          'pack_activities.young_leader',
          'pack_activities.adult_leader',
          'review.message',
          'review.pack_terms',
          'review.staff_terms',
          'confirmation.self',
          'confirmation.other',
        ].join(',');
        return { url: 'registration-form', params: { populate } };
      },
      transformResponse: (
        response: EntryResponse<RegistrationPagesContent>,
      ) => response.data.attributes,
    }),
  }),
});

export const {
  useGetHomeContentQuery,
  useGetActivitiesContentQuery,
  useGetActivitiesPageContentQuery,
  useGetFaqContentQuery,
  useGetBookingsContentQuery,
  useGetRegistrationsContentQuery,
} = strapiContent;

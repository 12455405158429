import styled from 'styled-components';

export const TooltipContainer = styled.div`
  background-color: white;
  color: black;
  padding: 10px;
  text-align: left;
  border: 1px #ccc solid;
`;

export const TooltipTitle = styled.div`
  color: black;
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 15px;
`;

export const NameCell = styled.td`
  padding-right: 5px;
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: none;
  white-space: nowrap;
  font-size: 0.9em;
`;

export const ValueCell = styled.td`
  text-align: right;
  font-size: 0.9em;
`;

export const TotalCell = styled.td`
  padding-top: 15px;
  font-weight: bold;
`;

export const TotalValueCell = styled.td`
  text-align: right;
  padding-top: 15px;
  font-weight: bold;
`;

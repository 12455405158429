import { OptionList } from '../helpers/options';

export const activitySessionStatuses = [
  'Closed',
  'Open',
  'Complete',
] as const;

export type ActivitySessionStatus = (typeof activitySessionStatuses)[number];

export const isValidActivitySessionStatus = (
  value: string,
): value is ActivitySessionStatus => activitySessionStatuses.includes(value as never);

export const activitySessionStatusOptions: OptionList<
ActivitySessionStatus
> = activitySessionStatuses.map(
  (status) => ({
    label: status,
    value: status,
  }),
);

import {
  Skeleton,
  Stack,
  TableBody,
} from '@mui/material';
import { FC, useState } from 'react';
import DataPanelWrapper from '../../../../components/DataPanel/DataPanelWrapper';
import SuccessAlert from '../../../../components/DataPanel/SuccessAlert';
import Table from '../../../../components/Table';
import { MutateActionType } from '../../../../helpers/actionType';
import checkPersonPermissions from '../../../../helpers/dataAction';
import { Person } from '../../../../models/person';
import { User } from '../../../../models/user';
import { useCreateContactMutation, useGetContactsForPersonQuery } from '../../../../state/protectedApi/contacts';
import ContactFormDialog from './ContactFormDialog';
import DisplayContactRow from './DisplayContactRow';

type ContactsPanelProps = {
  person: Person
  user: User | undefined
  direction: 'contacts' | 'relations'
};

const ContactsPanel: FC<ContactsPanelProps> = (props) => {
  const { person, user, direction } = props;

  const canUpdateContacts = checkPersonPermissions('Update', person, user) && direction === 'contacts';
  const [mutateSuccess, setMutateSuccess] = useState<MutateActionType>();

  const {
    data,
    isLoading,
    refetch,
  } = useGetContactsForPersonQuery(person.id);
  const [createContact] = useCreateContactMutation();

  const contacts = data?.filter(
    (contact) => (direction === 'contacts' && contact.relatedPersonId === person.id)
      || (direction === 'relations' && contact.personId === person.id),
  );

  const onSuccess = (type: MutateActionType) => async (): Promise<void> => {
    setMutateSuccess(type);
    await refetch();
  };

  const resetMutateTracking = (): void => {
    setMutateSuccess(undefined);
  };

  const title = direction === 'contacts' ? 'Contacts' : 'Relations Attending';
  const zeroMessage = direction === 'contacts' ? 'No contacts found.' : 'No relations found.';

  // TODO test that the contacts that are displayed are of a specific direction
  // TODO only show contacts section if the person is of attendee status
  return (
    <DataPanelWrapper>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <h3>{title}</h3>
        {canUpdateContacts && (
          <ContactFormDialog
            title="Create Contact"
            type="create"
            contact={{
              personId: null,
              relatedPersonId: person.id,
              relationship: '',
              emergency: null,
              updates: null,
              possiblyAttending: null,
            }}
            mutation={createContact}
            onOpen={resetMutateTracking}
            onSuccess={onSuccess('create')}
            packId={person.packId ?? null}
          />
        )}
      </Stack>

      {mutateSuccess && (
        <SuccessAlert
          modelName="Contact"
          type={mutateSuccess}
        />
      )}

      {isLoading && (
        <div>
          <Skeleton variant="rectangular" height={160} sx={{ marginBottom: '12px' }} />
          <Skeleton variant="rectangular" height={160} />
        </div>
      )}

      {contacts && contacts.length === 0 && (
        <p>{zeroMessage}</p>
      )}

      {contacts && contacts.length > 0 && (
        <Table>
          <TableBody>
            {contacts.map((contact) => (
              <DisplayContactRow
                key={contact.id}
                contact={contact}
                primary={person}
                readOnly={!canUpdateContacts}
                onStartEdit={resetMutateTracking}
                onStartDelete={resetMutateTracking}
                onDeleteSuccess={onSuccess('delete')}
                onUpdateSuccess={onSuccess('update')}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </DataPanelWrapper>
  );
};

export default ContactsPanel;

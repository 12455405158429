import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useMatch, useNavigate } from 'react-router-dom';
import { MenuItem, MenuPageLink, isPageLink } from '../types';
import * as Styled from './styles';

type MenuProps = {
  appBarHeight: number
  menuItems: MenuItem[]
  open: boolean
  onClose: () => void
};

type MenuItemButtonProps = MenuPageLink & {
  onClick: () => void
};

const MenuItemButton = (props: MenuItemButtonProps): JSX.Element => {
  const { route, label, onClick } = props;

  const current = useMatch(route);
  const navigate = useNavigate();

  const handelOnClick = (): void => {
    if (route.startsWith('http')) {
      window.open(route, '_blank');
    } else {
      navigate(route);
    }
    onClick();
  };

  return (
    <ListItem disablePadding>
      <ListItemButton selected={Boolean(current)} onClick={handelOnClick}>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  );
};

const Menu = (props: MenuProps): JSX.Element => {
  const {
    appBarHeight,
    open,
    onClose,
    menuItems,
  } = props;

  return (
    <Styled.Drawer
      open={open}
      variant="temporary"
      anchor="left"
      onClose={onClose}
    >
      <Styled.Spacer height={`${appBarHeight}px`} />
      <Styled.ListWrapper>
        <List dense>
          {menuItems.map((menuItem: MenuItem) => (isPageLink(menuItem) ? (
            <MenuItemButton
              key={menuItem.label}
              label={menuItem.label}
              route={menuItem.route}
              onClick={onClose}
            />
          ) : (
            <Divider key={menuItem.id} />
          )))}
        </List>
      </Styled.ListWrapper>
    </Styled.Drawer>
  );
};

export default Menu;

import { objectToCamel } from 'ts-case-convert';
import { ObjectToSnake } from 'ts-case-convert/lib/caseConvert';
import { EmailEvent } from '../../models/emailEvent';
import { protectedApi } from './index';

const extendedApi = protectedApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmailEvents: builder.query<EmailEvent[], void>({
      query: () => 'email-events/',
      transformResponse: (response: ObjectToSnake<EmailEvent[]>) => objectToCamel(response),
    }),
    getEmailEventsByPackId: builder.query<EmailEvent[], number>({
      query: (packId: number) => `email-events/pack/${packId}`,
      transformResponse: (response: ObjectToSnake<EmailEvent[]>) => objectToCamel(response),
    }),
    getEmailEventsByPersonId: builder.query<EmailEvent[], number>({
      query: (personId: number) => `email-events/person/${personId}`,
      transformResponse: (response: ObjectToSnake<EmailEvent[]>) => objectToCamel(response),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetEmailEventsQuery,
  useGetEmailEventsByPackIdQuery,
  useGetEmailEventsByPersonIdQuery,
} = extendedApi;

import { CircularProgress, useMediaQuery } from '@mui/material';
import { ReactElement, ReactNode, cloneElement } from 'react';
import {
  CartesianGrid,
  BarChart as LibBarChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { ContentType } from 'recharts/types/component/Tooltip';
import useElementSize from '../../helpers/useElementSize';
import { useTheme } from '../../theme';
import colours from '../../theme/colours';
import { getHeightPx, getWidthPx } from '../Dashboard/config';
import DashboardCard from '../DashboardCard';
import DashboardCardLegend from '../DashboardCardLegend';
import DashboardCardTitle from '../DashboardCardTitle';
import * as Styled from './styles';

export type DashboardHorizontalBarChartProps<TValue extends ValueType, TName extends NameType> = {
  title?: string
  controls?: ReactElement
  legend?: string
  tooltipContent?: ContentType<TValue, TName>;
  data: Array<object>
  yAxisDataKey: string
  yAxisLabelWidth?: number
  isLoading?: boolean
  noDataMessage?: string
  children: ReactNode
};

const DashboardHorizontalBarChart = <
  TValue extends ValueType,
  TName extends NameType,
>(props: DashboardHorizontalBarChartProps<TValue, TName>): JSX.Element => {
  const {
    title,
    controls,
    legend,
    tooltipContent: TooltipContent,
    data,
    yAxisDataKey,
    yAxisLabelWidth = 110,
    isLoading = false,
    noDataMessage = 'No data available',
    children,
  } = props;

  const [controlsRef, { height: controlsHeight }] = useElementSize();

  const theme = useTheme();
  const screenWiderThanMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const columns = screenWiderThanMobile ? 4 : 2;

  const rows = Math.ceil((data.length + 5) / 5);

  const nonGraphHeight = (title ? 100 : 20) + (legend ? 30 : 0) + (controls ? controlsHeight : 0);
  const graphHeight = getHeightPx(rows) - nonGraphHeight;
  const graphWidth = getWidthPx(columns) - 40;

  const noDataPlaceholder = isLoading ? (
    <CircularProgress />
  ) : (
    <Styled.Placeholder>{noDataMessage}</Styled.Placeholder>
  );

  return (
    <DashboardCard columns={columns} rows={Math.max(rows, 2)}>
      {title && (
        <DashboardCardTitle>{title}</DashboardCardTitle>
      )}
      {controls && cloneElement(controls, { ref: controlsRef })}
      {data.length ? (
        <LibBarChart
          width={graphWidth}
          height={graphHeight}
          data={data}
          layout="vertical"
          barGap={1}
          maxBarSize={20}
          margin={{
            top: 20,
          }}
        >
          <CartesianGrid
            horizontal={false}
            stroke={colours.blue}
            strokeWidth={0.5}
          />
          <XAxis
            type="number"
            orientation="top"
            tickMargin={10}
            stroke={colours.blue}
            strokeWidth={0.5}
          />
          <YAxis
            type="category"
            dataKey={yAxisDataKey}
            width={yAxisLabelWidth}
            stroke={colours.blue}
            interval={0}
          />
          <Tooltip content={TooltipContent} />
          {children}
        </LibBarChart>
      ) : noDataPlaceholder}
      {legend && (
        <DashboardCardLegend>{legend}</DashboardCardLegend>
      )}
    </DashboardCard>
  );
};

export default DashboardHorizontalBarChart;

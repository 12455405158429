import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BlocksRenderer from '../../../components/BlocksRenderer';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import CardIntroduction from '../../../components/CardIntroduction';
import { FormGrid } from '../../../components/FormGrid';
import IconLink from '../../../components/IconLink';
import { useRegistrationsContent } from '../../../helpers/content';
import { useFormikWithStateSync } from '../../../helpers/form';
import routes from '../../../routes';
import { useRegistrationCodeOrError } from '../../../routes/registrationsRoutes';
import { selectRegistration, update } from '../../../state/registration';
import { validationSchema } from './schema';

const RegistrationFirstAidFormPage = (): JSX.Element => {
  const { content } = useRegistrationsContent();
  const registrationCode = useRegistrationCodeOrError();
  const navigate = useNavigate();

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormikWithStateSync(
    selectRegistration,
    update,
    {
      validationSchema,
      onSubmit: async () => {
        navigate(routes.registrations.catering(registrationCode));
      },
    },
  );

  if (!content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <Card>
      <CardHeading>{content.first_aid.title}</CardHeading>
      <CardIntroduction>
        <BlocksRenderer content={content.first_aid.body} />
      </CardIntroduction>
      <form onSubmit={handleSubmit} noValidate>
        <h2>{content.first_aid.medications.title}</h2>
        <BlocksRenderer content={content.first_aid.medications.body} />
        <FormGrid>
          <FormControl
            error={touched.attendee?.antihistamineConsent
              && Boolean(errors.attendee?.antihistamineConsent)}
          >
            <FormLabel id="antihistamine-consent-label">
              {`Do you give permission for a first aider to administer anti-histamine to ${values.attendee.firstName} if required?`}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="antihistamine-consent-label"
              value={values.attendee.antihistamineConsent}
              onChange={async (event) => {
                await setFieldValue('attendee.antihistamineConsent', event.target.value === 'true');
              }}
              onBlur={() => setFieldTouched('attendee.antihistamineConsent')}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
            <FormHelperText>
              {(touched.attendee?.antihistamineConsent && errors.attendee?.antihistamineConsent) || ' '}
            </FormHelperText>
          </FormControl>
          <FormControl
            error={touched.attendee?.painkillerConsent
              && Boolean(errors.attendee?.painkillerConsent)}
          >
            <FormLabel id="painkiller-consent-label">
              {`Do you give permission for a first aider to administer paracetamol to ${values.attendee.firstName} if required?`}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="painkiller-consent-label"
              value={values.attendee.painkillerConsent}
              onChange={async (event) => {
                await setFieldValue('attendee.painkillerConsent', event.target.value === 'true');
              }}
              onBlur={() => setFieldTouched('attendee.painkillerConsent')}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
            <FormHelperText>
              {(touched.attendee?.painkillerConsent && errors.attendee?.painkillerConsent) || ' '}
            </FormHelperText>
          </FormControl>
        </FormGrid>
        <h2>{content.first_aid.sun_protection.title}</h2>
        <BlocksRenderer content={content.first_aid.sun_protection.body} />
        <FormGrid>
          <FormControl
            error={touched.attendee?.suncreamConsent
              && Boolean(errors.attendee?.suncreamConsent)}
          >
            <FormLabel id="sun-cream-consent-label">
              {`Do you give permission for us to supply sun cream to ${values.attendee.firstName} if deemed necessary?`}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="sun-cream-consent-label"
              value={values.attendee.suncreamConsent}
              onChange={async (event) => {
                await setFieldValue('attendee.suncreamConsent', event.target.value === 'true');
              }}
              onBlur={() => setFieldTouched('attendee.suncreamConsent')}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
            <FormHelperText>
              {(touched.attendee?.suncreamConsent && errors.attendee?.suncreamConsent) || ' '}
            </FormHelperText>
          </FormControl>
          <FormControl
            error={touched.attendee?.afterSunConsent
              && Boolean(errors.attendee?.afterSunConsent)}
          >
            <FormLabel id="after-sun-consent-label">
              {`Do you give permission for us to supply after sun to ${values.attendee.firstName} if deemed necessary?`}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="after-sun-consent-label"
              value={values.attendee.afterSunConsent}
              onChange={async (event) => {
                await setFieldValue('attendee.afterSunConsent', event.target.value === 'true');
              }}
              onBlur={() => setFieldTouched('attendee.afterSunConsent')}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
            <FormHelperText>
              {(touched.attendee?.afterSunConsent && errors.attendee?.afterSunConsent) || ' '}
            </FormHelperText>
          </FormControl>
        </FormGrid>
        <CardFooter>
          <IconLink icon="Back" to={routes.registrations.additionalInformation(registrationCode)}>Back</IconLink>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Next
          </Button>
        </CardFooter>
      </form>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </Card>
  );
};

export default RegistrationFirstAidFormPage;

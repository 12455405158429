import { CircularProgress } from '@mui/material';
import { FC } from 'react';
import DashboardCard from '../../../../../components/DashboardCard';
import DashboardCardLegend from '../../../../../components/DashboardCardLegend';
import DashboardCardTitle from '../../../../../components/DashboardCardTitle';
import { getRelativeDayOrDate, getShortTime } from '../../../../../helpers/datesStrings';
import { Statistics } from '../../../../../models/statistics';

type LastGeneratedTimestampProps = {
  stats?: Statistics
};

const LastGeneratedTimestamp: FC<LastGeneratedTimestampProps> = (props) => {
  const { stats } = props;
  const title = stats && `${getShortTime(stats.timestamp)} ${getRelativeDayOrDate(stats.timestamp)}`;

  return (
    <DashboardCard>
      <DashboardCardTitle>
        {title || (
          <CircularProgress />
        )}
      </DashboardCardTitle>
      <DashboardCardLegend>
        Last generated stats
      </DashboardCardLegend>
    </DashboardCard>
  );
};

export default LastGeneratedTimestamp;

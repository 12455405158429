import { Link } from '@mui/material';

type PhoneLinkProps = {
  phoneNumber: string
};

const PhoneLink = (props: PhoneLinkProps): JSX.Element => {
  const { phoneNumber } = props;
  const telHref = `tel:${phoneNumber}`;
  return (
    <Link href={telHref}>{phoneNumber}</Link>
  );
};

export default PhoneLink;

import * as Styled from './styles';

type CardHeadingProps = {
  children: string
};

const CardHeading = (props: CardHeadingProps): JSX.Element => {
  const { children } = props;

  return <Styled.HeadingWithNoTopMargin>{children}</Styled.HeadingWithNoTopMargin>;
};

export default CardHeading;

import {
  Autocomplete,
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { DateTimeRangePicker } from '@mui/x-date-pickers-pro';
import moment from 'moment/moment';
import { ChangeEvent, FC } from 'react';
import CardFooter from '../../../components/CardFooter';
import { FormGrid } from '../../../components/FormGrid';
import ReviewTagsSelector from '../../../components/ReviewTagsSelector';
import { FormProps } from '../../../components/forms/types';
import { CreateMeal } from '../../../models/meal';
import { isValidMealStatus, mealStatusOptions } from '../../../models/mealStatus';
import { useGetEditionsQuery } from '../../../state/publicApi/editions';

const MealForm: FC<FormProps<CreateMeal>> = (props) => {
  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = props;

  const {
    data: editions,
    isLoading: editionsLoading,
  } = useGetEditionsQuery();

  return (
    <form onSubmit={handleSubmit} noValidate>
      <FormGrid columns={3}>
        <TextField
          label="Name"
          value={values.name}
          onBlur={() => setFieldTouched('name')}
          onChange={async (event) => {
            await setFieldValue('name', event.target.value);
          }}
          error={touched.name && Boolean(errors.name)}
          helperText={(touched.name && errors.name) || ' '}
        />
        <Autocomplete
          options={editions?.map((edition) => edition.id) ?? []}
          loading={editionsLoading}
          getOptionLabel={(id) => editions?.find((edition) => edition.id === id)?.name ?? ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Edition"
              error={touched.editionId && Boolean(errors.editionId)}
              helperText={(touched.editionId && errors.editionId) || ' '}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {editionsLoading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          value={values.editionId}
          onChange={async (event, value) => {
            await setFieldValue('editionId', value);
          }}
          onBlur={() => setFieldTouched('editionId')}
          disabled={isSubmitting}
        />
        <TextField
          label="Location"
          value={values.location}
          onBlur={() => setFieldTouched('location')}
          onChange={async (event) => {
            await setFieldValue('location', event.target.value);
          }}
          error={touched.name && Boolean(errors.name)}
          helperText={(touched.name && errors.name) || ' '}
        />
      </FormGrid>
      <FormGrid>
        <FormControl error={touched.start && touched.end
          && (Boolean(errors.start) || Boolean(errors.end))}
        >
          <DateTimeRangePicker
            value={[moment(values.start), moment(values.end)]}
            onChange={async (dateTimeRange) => {
              const [start, end] = dateTimeRange;
              const setStart = setFieldValue('start', start?.toISOString(true) ?? null);
              const setEnd = setFieldValue('end', end?.toISOString(true) ?? null);
              await Promise.all([setStart, setEnd]);
            }}
            disabled={isSubmitting}
          />
          <FormHelperText>
            {(touched.start && touched.end && (errors.start || errors.end)) || ' '}
          </FormHelperText>
        </FormControl>
      </FormGrid>
      <FormGrid columns={2}>
        <FormControl>
          <FormLabel>Equipment</FormLabel>
          <FormGroup>
            <FormControlLabel
              label="Bottle"
              control={(
                <Checkbox
                  checked={values.bottle}
                  onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                    await setFieldValue('bottle', event.target.checked);
                  }}
                  disabled={isSubmitting}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              )}
            />
            <FormControlLabel
              label="Plate"
              control={(
                <Checkbox
                  checked={values.plate}
                  onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                    await setFieldValue('plate', event.target.checked);
                  }}
                  disabled={isSubmitting}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              )}
            />
            <FormControlLabel
              label="Bowl"
              control={(
                <Checkbox
                  checked={values.bowl}
                  onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                    await setFieldValue('bowl', event.target.checked);
                  }}
                  disabled={isSubmitting}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              )}
            />
            <FormControlLabel
              label="Fork"
              control={(
                <Checkbox
                  checked={values.fork}
                  onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                    await setFieldValue('fork', event.target.checked);
                  }}
                  disabled={isSubmitting}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              )}
            />
            <FormControlLabel
              label="Knife"
              control={(
                <Checkbox
                  checked={values.knife}
                  onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                    await setFieldValue('knife', event.target.checked);
                  }}
                  disabled={isSubmitting}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              )}
            />
            <FormControlLabel
              label="Spoon"
              control={(
                <Checkbox
                  checked={values.spoon}
                  onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                    await setFieldValue('spoon', event.target.checked);
                  }}
                  disabled={isSubmitting}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              )}
            />
          </FormGroup>
          <FormHelperText>
            {' '}
          </FormHelperText>
        </FormControl>
        <FormControl error={touched.status && Boolean(errors.status)}>
          <FormLabel id="check-in-status-label">Check in status</FormLabel>
          <RadioGroup
            aria-labelledby="check-in-status-label"
            value={values.status}
            onChange={async (event) => {
              if (isValidMealStatus(event.target.value)) {
                await setFieldValue('status', event.target.value);
              }
            }}
            onBlur={() => setFieldTouched('status')}
          >
            {mealStatusOptions.map((status) => (
              <FormControlLabel
                key={status.value}
                value={status.value}
                control={<Radio />}
                label={status.label}
              />
            ))}
          </RadioGroup>
          <FormHelperText>
            {(touched.status && errors.status) || ' '}
          </FormHelperText>
        </FormControl>
      </FormGrid>
      <FormGrid>
        <TextField
          label="Description"
          value={values.description}
          onBlur={() => setFieldTouched('description')}
          onChange={async (event) => {
            await setFieldValue('description', event.target.value);
          }}
          error={touched.description && Boolean(errors.description)}
          helperText={(touched.description && errors.description) || ' '}
          multiline
          minRows={5}
        />
      </FormGrid>
      <ReviewTagsSelector
        disabled={isSubmitting}
        onChange={(newTags) => setFieldValue('reviewTags', newTags)}
        tags={values.reviewTags}
        type="Catering"
      />

      <CardFooter>
        <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </CardFooter>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </form>
  );
};

export default MealForm;

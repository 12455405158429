import styled, { css } from 'styled-components';
import { responsiveSpacing } from '../../../helpers/responsiveSpacing';

type DragOptionProps = {
  status: 'active' | 'inactive' | 'placeholder'
  transform?: string | undefined
  transition?: string | undefined
  disabled?: boolean
};

export const DragOption = styled.div<DragOptionProps>`
  display: flex;
  align-items: center;
  
  ${responsiveSpacing('padding', 'xxs')}
  ${responsiveSpacing('margin-bottom', 'xxs')}
 
  border-radius: 5px;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${({ transform }) => transform && css`
    transform: ${transform};
  `}
  ${({ transition }) => transition && css`
    transition: ${transition};
  `}

  background-color: ${({ theme }) => theme.palette.background.default};
  border: solid 1px;

  ${({ status }) => {
    switch (status) {
      case 'placeholder': {
        return css`
          border-color: ${({ theme }) => theme.palette.grey.A400};
          background-color: ${({ theme }) => theme.palette.grey.A700};
          opacity: 0.2;
          z-index: 1;
        `;
      }
      case 'active': {
        return css`
          border-color: ${({ theme }) => theme.palette.info.main};
          background-color: ${({ theme }) => theme.palette.background.default};
          z-index: 3;
        `;
      }
      case 'inactive':
      default: {
        return css`
          border-color: ${({ theme }) => theme.palette.secondary.main};
          z-index: 2;
          &:hover {
            border-color: ${({ theme }) => theme.palette.text.primary};
          }
        `;
      }
    }
  }}

  touch-action: manipulation;

  ${({ disabled }) => disabled && css`
    touch-action: unset;
    border-color: ${({ theme }) => theme.palette.grey.A400};
    &:hover {
      border-color: ${({ theme }) => theme.palette.grey.A400};
    }
  `}
`;

export const DragOptionName = styled.div`
  ${responsiveSpacing('margin-right', 'xs')}
`;

export const DragOptionIndex = styled.div`
  height: 30px;
  width: 30px;
  line-height: 30px;
  vertical-align: center;
  text-align: center;
  
  color: ${({ theme }) => (theme.palette.mode === 'light' ? 'white' : 'black')};
  background-color: ${({ theme }) => (theme.palette.mode === 'light' ? 'black' : 'white')};
  font-weight: bold;

  border-radius: 15px;
  ${responsiveSpacing('margin-right', 'xs')}
`;

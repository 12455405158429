import { Checkbox, FormControlLabel } from '@mui/material';
import { FC } from 'react';
import DataPanelWrapper from '../../../../components/DataPanel/DataPanelWrapper';
import { FormGrid } from '../../../../components/FormGrid';
import ReviewTagsTable from '../../../../components/ReviewTagsTable';
import useErrorHandler from '../../../../helpers/useErrorHandler';
import { useUrlBooleanParam } from '../../../../helpers/useUrlParam';
import { userHasRole } from '../../../../helpers/user';
import { useGetReviewTagSummaryQuery } from '../../../../state/protectedApi/reviews';
import { useGetCurrentUserQuery } from '../../../../state/protectedApi/user';

type PackReviewTagsSummaryProps = {
  packId: number;
};

const PackReviewTagsSummary: FC<PackReviewTagsSummaryProps> = (props) => {
  const { packId } = props;
  const [combinations, setCombinations] = useUrlBooleanParam('combinations', false);

  const {
    data,
    error,
  } = useGetReviewTagSummaryQuery({
    reviewType: 'PackLeader',
    packId,
    combinations,
  });
  useErrorHandler(error, 'API Error: Failure to load review tag summary for pack');

  const { data: user } = useGetCurrentUserQuery();

  if (data === undefined || data.length === 0) {
    return null;
  }

  return (
    <DataPanelWrapper>
      <h2>
        {userHasRole(user, 'PackLeader', false) ? 'Review Tags Summary' : 'Pack Leader\'s Review'}
      </h2>
      <FormGrid>
        <FormControlLabel
          label="View unique tag combinations"
          control={(
            <Checkbox
              checked={combinations}
              onChange={(event) => setCombinations(event.target.checked)}
            />
          )}
        />
      </FormGrid>
      <ReviewTagsTable data={data} />
    </DataPanelWrapper>
  );
};

export default PackReviewTagsSummary;

// Note: 'Pending' & 'WaitListed' statuses are excluded from both active and cancelled statuses
import { PackBookingsStatus } from '../models/packBookingStatus';

const activeBookingStatuses: PackBookingsStatus[] = ['Offered', 'Accepted'];
const cancelledBookingStatuses: PackBookingsStatus[] = ['Unsuccessful', 'Rejected'];

export const isActiveBookingStatus = (
  status: PackBookingsStatus,
): boolean => activeBookingStatuses.includes(status);

export const isCancelledBookingStatus = (
  status: PackBookingsStatus,
): boolean => cancelledBookingStatuses.includes(status);

export const orderedPhases = [
  'Configuration',
  'Booking',
  'Registration',
  'Allocation',
  'Delivery',
  'Review',
] as const;

export type Phase = (typeof orderedPhases)[number];

import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import emailsClient from '../../../api/emails';
import BlocksRenderer from '../../../components/BlocksRenderer';
import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import CardHeading from '../../../components/CardHeading';
import CardIntroduction from '../../../components/CardIntroduction';
import { FormGrid } from '../../../components/FormGrid';
import { useRegistrationsContent } from '../../../helpers/content';
import { getLongDateWithoutYear } from '../../../helpers/datesStrings';
import { getFirstEdition } from '../../../helpers/edition';
import { useFormikWithStateSync } from '../../../helpers/form';
import routes from '../../../routes';
import { useRegistrationCodeOrError } from '../../../routes/registrationsRoutes';
import { useGetEventConfigQuery } from '../../../state/publicApi';
import { useGetEditionsForRegistrationCodeQuery } from '../../../state/publicApi/editions';
import { selectRegistration, update } from '../../../state/registration';
import sanitiseOnSubmit from './sanitise';
import { validationSchema } from './schema';

const RegistrationContactFormPage = (): JSX.Element => {
  const { content } = useRegistrationsContent();
  const registrationCode = useRegistrationCodeOrError();
  const navigate = useNavigate();
  const [externalEmailError, setExternalEmailError] = useState<string | undefined>();

  const {
    currentData: editions,
  } = useGetEditionsForRegistrationCodeQuery(registrationCode ?? skipToken);
  const eventStart = getFirstEdition(editions ?? [])?.start;

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormikWithStateSync(
    selectRegistration,
    update,
    {
      validationSchema,
      onSubmit: async ({ mainContact }) => {
        if (mainContact?.email) {
          await emailsClient.validate(mainContact.email)
            .then(async (fullResponse) => {
              const response = fullResponse
                .find((validation) => validation.input_email === mainContact.email);
              if (response?.valid) {
                await setFieldValue('mainContact.email', response.normalised_email ?? '');
                navigate(routes.registrations.attendee(registrationCode));
              } else {
                setExternalEmailError(response?.error);
              }
            })
            .catch(() => {
              console.error('API Error: Could not check if email is valid');
              navigate(routes.registrations.attendee(registrationCode));
            });
        }
      },
    },
    sanitiseOnSubmit,
  );

  const {
    data: eventConfig,
  } = useGetEventConfigQuery();
  if (!eventConfig || !content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <Card>
      <CardHeading>{content.contact.title}</CardHeading>
      <CardIntroduction>
        <BlocksRenderer content={content.contact.body} />
      </CardIntroduction>
      <form onSubmit={handleSubmit} noValidate>
        <FormGrid columns={3}>
          <TextField
            label="First name"
            value={values.mainContact.firstName}
            onBlur={() => setFieldTouched('mainContact.firstName')}
            onChange={async (event) => {
              await setFieldValue('mainContact.firstName', event.target.value);
            }}
            error={touched.mainContact?.firstName && Boolean(errors.mainContact?.firstName)}
            helperText={(touched.mainContact?.firstName && errors.mainContact?.firstName) || ' '}
          />
          <TextField
            label="Known as (optional)"
            value={values.mainContact.knownAs}
            onBlur={() => setFieldTouched('mainContact.knownAs')}
            onChange={async (event) => {
              await setFieldValue('mainContact.knownAs', event.target.value);
            }}
            error={touched.mainContact?.knownAs && Boolean(errors.mainContact?.knownAs)}
            helperText={(touched.mainContact?.knownAs && errors.mainContact?.knownAs) || ' '}
          />
          <TextField
            label="Last name"
            value={values.mainContact.lastName}
            onBlur={() => setFieldTouched('mainContact.lastName')}
            onChange={async (event) => {
              await setFieldValue('mainContact.lastName', event.target.value);
            }}
            error={touched.mainContact?.lastName && Boolean(errors.mainContact?.lastName)}
            helperText={(touched.mainContact?.lastName && errors.mainContact?.lastName) || ' '}
          />
        </FormGrid>
        <FormGrid columns={2}>
          <TextField
            label="Mobile"
            value={values.mainContact.mobile}
            onBlur={() => setFieldTouched('mainContact.mobile')}
            onChange={async (event) => {
              await setFieldValue('mainContact.mobile', event.target.value);
            }}
            error={touched.mainContact?.mobile && Boolean(errors.mainContact?.mobile)}
            helperText={(touched.mainContact?.mobile && errors.mainContact?.mobile) || ' '}
            inputProps={{ inputMode: 'tel' }}
          />
          <TextField
            label="Email"
            value={values.mainContact.email}
            onBlur={() => setFieldTouched('mainContact.email')}
            onChange={async (event) => {
              await setFieldValue('mainContact.email', event.target.value.trim());
            }}
            error={Boolean(externalEmailError)
              || (touched.mainContact?.email && Boolean(errors.mainContact?.email))}
            helperText={externalEmailError
              || (touched.mainContact?.email && errors.mainContact?.email) || ' '}
            inputProps={{ inputMode: 'email' }}
          />
          <FormControl
            error={touched.meta?.registeringSelf && Boolean(errors.meta?.registeringSelf)}
          >
            <FormLabel id="registering-self-label">Who are you registering to attend?</FormLabel>
            <RadioGroup
              aria-labelledby="registering-self-label"
              value={values.meta.registeringSelf}
              onChange={async (event) => {
                await setFieldValue('meta.registeringSelf', event.target.value === 'true');
              }}
              onBlur={() => setFieldTouched('meta.registeringSelf')}
            >
              <FormControlLabel
                value="false"
                control={<Radio />}
                label={`Someone under 18 years old on ${eventStart ? getLongDateWithoutYear(eventStart) : 'the first day of the event'}`}
              />
              <FormControlLabel value="true" control={<Radio />} label="Myself" />
            </RadioGroup>
            <FormHelperText>
              {(touched.meta?.registeringSelf && errors.meta?.registeringSelf) || ' '}
            </FormHelperText>
          </FormControl>
          {values.meta.registeringSelf === false && (
            <TextField
              label="Relationship to attendee"
              value={values.mainContact.relationship}
              onBlur={() => setFieldTouched('mainContact.relationship')}
              onChange={async (event) => {
                await setFieldValue('mainContact.relationship', event.target.value);
              }}
              error={touched.mainContact?.relationship && Boolean(errors.mainContact?.relationship)}
              helperText={(touched.mainContact?.relationship && errors.mainContact?.relationship) || ' '}
            />
          )}
        </FormGrid>
        <CardFooter>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Next
          </Button>
        </CardFooter>
      </form>
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </Card>
  );
};

export default RegistrationContactFormPage;

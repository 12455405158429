import { ExecutionProps, Interpolation } from 'styled-components';
import { Breakpoint, breakpoints, minWidthQuery } from './responsive';
import keys from './typedKeys';

const defaultSpacing = {
  xxs: 10,
  xs: 15,
  s: 20,
  m: 25,
  l: 30,
  xl: 40,
  xxl: 50,
};

export type SpacingSet = typeof defaultSpacing;
export type Spacing = keyof SpacingSet;

export type PropertySize = Spacing | `-${Spacing}` | '0' | 'auto';

export const spacingSetByBreakpoint: Record<Breakpoint, SpacingSet> = {
  small: { ...defaultSpacing, xxl: 55 },
  medium: {
    ...defaultSpacing,
    s: 25,
    m: 30,
    l: 35,
    xl: 50,
    xxl: 65,
  },
  large: {
    xxs: 15,
    xs: 20,
    s: 25,
    m: 30,
    l: 40,
    xl: 60,
    xxl: 80,
  },
  xLarge: {
    xxs: 15,
    xs: 20,
    s: 30,
    m: 40,
    l: 50,
    xl: 70,
    xxl: 100,
  },
  xxLarge: {
    xxs: 15,
    xs: 20,
    s: 30,
    m: 40,
    l: 60,
    xl: 80,
    xxl: 120,
  },
};

const valueForSpacingAtBreakpoint = (
  spacing: Spacing,
  breakpoint?: Breakpoint,
): number => (breakpoint
  ? spacingSetByBreakpoint[breakpoint][spacing]
  : defaultSpacing[spacing]);

const valueAtBreakpoint = (
  size: PropertySize,
  breakpoint?: Breakpoint,
): string => {
  if (['0', 'auto'].includes(size)) {
    return size;
  }
  if (size.startsWith('-')) {
    return `-${valueForSpacingAtBreakpoint(
      size.substring(1) as Spacing,
      breakpoint,
    )}px`;
  }
  return `${valueForSpacingAtBreakpoint(size as Spacing, breakpoint)}px`;
};

const buildCssProperty = (
  property: string,
  sizes: PropertySize[],
  breakpoint?: Breakpoint,
): string => `${property}: ${sizes
  .map((size) => valueAtBreakpoint(size, breakpoint))
  .join(' ')};`;

const propertyForBreakpoint = (
  property: string,
  breakpoint: Breakpoint,
  sizes: PropertySize[],
): Interpolation<ExecutionProps> => minWidthQuery(
  breakpoints[breakpoint],
)`
  ${buildCssProperty(property, sizes, breakpoint)}
`;

export const responsiveSpacing = (
  property: string,
  ...sizes: PropertySize[]
): Interpolation<object> => [
  buildCssProperty(property, sizes),
  ...keys(breakpoints).map((breakpoint) => propertyForBreakpoint(property, breakpoint, sizes)),
];

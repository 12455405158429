import { Stack, TableRow } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { FC } from 'react';
import DeleteDialog from '../../../../../components/DeleteDialog';
import EmailLink from '../../../../../components/EmailLink';
import LoadingText from '../../../../../components/LoadingText';
import PersonStatusChip from '../../../../../components/PersonStatusChip';
import PhoneLink from '../../../../../components/PhoneLink';
import RouterLink from '../../../../../components/RouterLink';
import TableCell from '../../../../../components/TableCell';
import { getFullName, isAttending } from '../../../../../helpers/person';
import toSentenceCase from '../../../../../helpers/sentenceCase';
import { Contact } from '../../../../../models/contact';
import { Person } from '../../../../../models/person';
import routes from '../../../../../routes';
import { useDeleteContactMutation, useUpdateContactMutation } from '../../../../../state/protectedApi/contacts';
import { useGetPersonQuery } from '../../../../../state/protectedApi/people';
import ContactFormDialog from '../ContactFormDialog';

type DisplayContactRowProps = {
  contact: Contact
  primary: Person
  readOnly: boolean
  onStartEdit?: () => void
  onStartDelete?: () => void
  onUpdateSuccess?: () => void
  onDeleteSuccess?: (contact: Contact, person: Person) => void
};

const DisplayContactRow: FC<DisplayContactRowProps> = (props) => {
  const {
    contact,
    primary,
    readOnly,
    onStartEdit,
    onStartDelete,
    onUpdateSuccess,
    onDeleteSuccess,
  } = props;

  const secondaryPersonId = contact.personId === primary.id
    ? contact.relatedPersonId
    : contact.personId;

  const {
    currentData: secondaryPerson,
  } = useGetPersonQuery(secondaryPersonId ?? skipToken);
  const [updateContact] = useUpdateContactMutation();
  const [deleteContact] = useDeleteContactMutation();

  return (
    <TableRow>
      <TableCell>
        <strong>
          {toSentenceCase(contact.relationship)}
        </strong>
        {secondaryPerson && isAttending(secondaryPerson) && (
          <p style={{ marginLeft: '-12px' }}>
            <PersonStatusChip status={secondaryPerson.status} />
          </p>
        )}
      </TableCell>
      <TableCell>
        {secondaryPerson ? (
          <>
            <p>
              <RouterLink to={routes.people.detail(secondaryPerson.id)}>
                {getFullName(secondaryPerson)}
              </RouterLink>
            </p>
            {secondaryPerson.mobile && (
              <p>
                <PhoneLink phoneNumber={secondaryPerson.mobile} />
              </p>
            )}
            {secondaryPerson.email && (
              <p>
                <EmailLink email={secondaryPerson.email} />
              </p>
            )}
            Send general updates:&nbsp;
            <strong>{contact.updates ? 'Yes' : 'No'}</strong>
            <br />
            Contact in an emergency:&nbsp;
            <strong>{contact.emergency ? 'Yes' : 'No'}</strong>
            <br />
            Possibly attending:&nbsp;
            <strong>{contact.possiblyAttending ? 'Yes' : 'No'}</strong>
          </>
        ) : (
          <LoadingText />
        )}
      </TableCell>
      {!readOnly && secondaryPerson && (
        <TableCell sx={{ paddingRight: 0 }}>
          <Stack direction="row-reverse" spacing={3}>
            <DeleteDialog
              title="Delete Contact"
              description="Are you sure you want to delete this contact?"
              modelName="contact"
              data={contact}
              onOpen={onStartDelete}
              onDelete={(data) => deleteContact(data.id)}
              onSuccess={() => onDeleteSuccess?.(contact, secondaryPerson)}
            />
            <ContactFormDialog
              title="Update contact"
              type="update"
              contact={contact}
              mutation={(update) => updateContact({ id: contact.id, ...update })}
              onOpen={onStartEdit}
              onSuccess={onUpdateSuccess}
            />
          </Stack>
        </TableCell>
      )}
    </TableRow>
  );
};

export default DisplayContactRow;

import moment from 'moment';
import formatValue from './formatValue';

const isIndexable = (data: unknown): data is Record<string, unknown> => !!data && typeof data === 'object';

const getValueAtPath = (data: unknown, path: string): unknown => {
  if (!isIndexable(data)) {
    return null;
  }
  const [property, ...rest] = path.split('.');
  if (!Array.isArray(data)) {
    return rest.length
      ? getValueAtPath(data[property], rest.join('.'))
      : data[property];
  }

  const match = property.match(
    /^\[(?<index>[\d*]+)]$/,
  );

  if (!match || !match.groups) {
    console.warn(`Invalid list selector: {${path}}'`);
    return null;
  }
  const { index } = match.groups;

  if (index === '*' && rest.length) {
    return data.map((v) => getValueAtPath(v, rest.join('.')));
  }
  if (index === '*') {
    return data;
  }

  const indexAsNumber = parseInt(index, 10);
  if (Number.isNaN(indexAsNumber)) {
    throw new Error(`Invalid index: ${index}`);
  }
  return rest ? getValueAtPath(data[indexAsNumber], rest.join('.')) : data[indexAsNumber];
};

const getSubstitution = (
  data: unknown,
  reference: string,
): string | null => {
  const match = reference.match(
    /^(?<path>[^:\s]+)(:(?<format>[^/]+))?(\/(?<placeholder>.*))?$/,
  );

  if (!match || !match.groups) {
    console.warn(`Invalid substitution string: {${reference}}'`);
    return null;
  }

  const { path, format, placeholder } = match.groups;

  const value = getValueAtPath(data, path);

  if (Array.isArray(value) && value.every((item) => moment(item as string).isValid())) {
    const momentsArray: moment.Moment[] = value.map((dateTime) => moment(dateTime as string));
    const distinctMomentsSet: Set<string> = new Set(momentsArray.map((m) => m.format()));
    const sortedDistinctMomentsArray = [...distinctMomentsSet].sort();
    return formatValue(sortedDistinctMomentsArray, format);
  }

  if (!value) {
    return placeholder ?? null;
  }
  return formatValue(value, format);
};

export default getSubstitution;

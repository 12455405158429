import { Badge, Button, ButtonGroup } from '@mui/material';
import { FC } from 'react';

type ModeSwitchBarProps = {
  mode: 'scanner' | 'manual' | 'history';
  setMode: (mode: 'scanner' | 'manual' | 'history') => void;
  historyLength: number;
};

const ModeSwitchBar: FC<ModeSwitchBarProps> = (props) => {
  const {
    mode,
    setMode,
    historyLength,
  } = props;

  return (
    <ButtonGroup
      variant="outlined"
      aria-label="Switch between scan, manual and history views"
      fullWidth
      sx={{ marginBottom: '20px' }}
    >
      <Button
        onClick={() => setMode('scanner')}
        variant={mode === 'scanner' ? 'contained' : 'outlined'}
      >
        Scanner
      </Button>
      <Button
        onClick={() => setMode('manual')}
        variant={mode === 'manual' ? 'contained' : 'outlined'}
      >
        Manual
      </Button>
      <Badge badgeContent={historyLength} color="primary">
        <Button
          onClick={() => setMode('history')}
          disabled={historyLength === 0}
          variant={mode === 'history' ? 'contained' : 'outlined'}
        >
          History
        </Button>
      </Badge>
    </ButtonGroup>
  );
};

export default ModeSwitchBar;

import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0 10px 0 0;
`;

export const Link = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 10px 10px 10px 0;
`;

export const Icon = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
`;

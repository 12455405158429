import * as Yup from 'yup';
import personRules from '../../../helpers/validation/person';

export const validationSchema = Yup.object().shape({
  contact: Yup.object().shape({
    firstName: personRules.firstName.required('Your first name is required'),
    knownAs: personRules.knownAs,
    lastName: personRules.lastName.required('Your last name is required'),
    scoutingAppointment: personRules.scoutingAppointment
      .required('Your scouting position is required'),
    membershipNumber: personRules.membershipNumber.required('Your membership number is required'),
    mobile: personRules.mobile.required('Your mobile number is required'),
    email: personRules.email.required('Your email is required'),
  }),
});

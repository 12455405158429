import { Backdrop, CircularProgress } from '@mui/material';
import DataGrid from '../../../components/DataGrid';
import ToolbarEditionFilter from '../../../components/DataGridToolbar/ToolbarEditionFilter';
import ExpandingSpace from '../../../components/ExpandingSpace';
import PageMetaTitle from '../../../components/PageMetaTitle';
import { useDefaultEditionForUser } from '../../../helpers/useDefaultEdition';
import useErrorHandler from '../../../helpers/useErrorHandler';
import routes from '../../../routes';
import { useGetActivitiesQuery, useGetSessionsQuery } from '../../../state/publicApi/activities';
import columnGroupings from './columnGroupings';
import columns from './columns';

const ActivitiesGridPage = (): JSX.Element => {
  const [edition, setEditionById] = useDefaultEditionForUser();

  const {
    data: activities,
    error: activitiesError,
    isFetching: isFetchingActivities,
    refetch: refetchActivities,
  } = useGetActivitiesQuery(undefined, { skip: edition === undefined });
  useErrorHandler(activitiesError, 'API Error: Failed to load activities');

  const {
    data: sessions,
    error: sessionsError,
  } = useGetSessionsQuery();
  useErrorHandler(sessionsError, 'API Error: Failed to load session times');

  if (!sessions || !edition) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <>
      <PageMetaTitle title="Activities" />
      <DataGrid
        columns={columns(sessions, edition.id)}
        loading={isFetchingActivities}
        rows={activities ?? []}
        columnGroupingModel={columnGroupings(sessions)}
        initialState={{
          pinnedColumns: { left: ['id', 'name'] },
        }}
        slotProps={{
          toolbar: {
            createRoute: routes.activities.adminDetail('new'),
            refetch: refetchActivities,
            children: (
              <>
                <ExpandingSpace />
                <ToolbarEditionFilter
                  editionId={edition.id}
                  onEditionChange={setEditionById}
                />
              </>
            ),
          },
        }}
      />
    </>
  );
};

export default ActivitiesGridPage;

import {
  Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis,
} from 'recharts';
import { getHeightPx } from '../../../../../components/Dashboard/config';
import DashboardCard from '../../../../../components/DashboardCard';
import DashboardCardTitle from '../../../../../components/DashboardCardTitle';
import { getColourForAttendeeStatus } from '../../../../../helpers/statusColours';
import { useGetBallotStatisticsQuery } from '../../../../../state/protectedApi/statistics';
import { useGetEditionsQuery } from '../../../../../state/publicApi/editions';

const BallotByEdition = (): JSX.Element | null => {
  const { data: stats } = useGetBallotStatisticsQuery(undefined);
  const { data: editions } = useGetEditionsQuery();

  const getEditionName = (id: number): string => editions?.find((e) => e.id === id)?.name || 'Unknown';

  const data = stats && editions && [
    {
      name: 'Total',
      Cubs: stats.summary.cubs,
      Leaders: stats.summary.leaders,
      YLs: stats.summary.youngLeaders,
    },
    ...stats.editions.map((edition) => ({
      name: getEditionName(edition.id),
      Cubs: edition.cubs,
      Leaders: edition.leaders,
      YLs: edition.youngLeaders,
    })),
  ];

  const rows = 3;
  const columns = 2;
  const graphWidth = getHeightPx(columns);
  const graphHeight = getHeightPx(rows) - 80;

  return (
    <DashboardCard columns={columns} rows={rows}>
      <DashboardCardTitle>Editions</DashboardCardTitle>
      <BarChart
        width={graphWidth}
        height={graphHeight}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Cubs" fill={getColourForAttendeeStatus('Cub')} />
        <Bar dataKey="Leaders" fill={getColourForAttendeeStatus('Leader')} />
        <Bar dataKey="YLs" fill={getColourForAttendeeStatus('YoungLeader')} />
      </BarChart>
    </DashboardCard>
  );
};

export default BallotByEdition;

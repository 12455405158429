export const activityDeliveries = [
  'Instructor',
  'CampStaff',
  'PackLeader',
] as const;

export type ActivityDelivery = typeof activityDeliveries[number];

export const isValidActivityDelivery = (
  delivery: string,
): delivery is ActivityDelivery => activityDeliveries.includes(delivery as never);

export const getActivityDeliveryLabel = (delivery: ActivityDelivery): string => {
  switch (delivery) {
    case 'CampStaff':
      return 'Camp Staff';
    case 'PackLeader':
      return 'Pack Leader';
    default:
      return delivery;
  }
};

export const activityDeliveryOptions = activityDeliveries.map((delivery) => ({
  label: getActivityDeliveryLabel(delivery),
  value: delivery,
}));

import DataGrid from '../../../components/DataGrid';
import PageMetaTitle from '../../../components/PageMetaTitle';
import useErrorHandler from '../../../helpers/useErrorHandler';
import { useGetEmailEventsQuery } from '../../../state/protectedApi/emailEvents';
import { useGetPacksQuery } from '../../../state/protectedApi/packs';
import { getColumns } from './columns';

const EmailEventsGridPage = (): JSX.Element => {
  const { data: packs } = useGetPacksQuery();
  const {
    data: emailEvents,
    error: emailEventsError,
    isFetching,
    refetch,
  } = useGetEmailEventsQuery();
  useErrorHandler(emailEventsError, 'API Error loading email events');

  const columns = getColumns(packs ?? []);

  return (
    <>
      <PageMetaTitle title="Email Tracking" />
      <DataGrid
        columns={columns}
        rows={emailEvents ?? []}
        loading={isFetching}
        slotProps={{
          toolbar: {
            refetch,
          },
        }}
      />
    </>
  );
};

export default EmailEventsGridPage;

export const asDefined = <T>(
  value: T | undefined | null,
): T => {
  if (typeof value === 'undefined' || value === null) {
    throw new Error('Object is not defined');
  }
  return value;
};

export const isString = (value: unknown): value is string => typeof value === 'string';

export const asString = (value: unknown): string => {
  if (!isString(value)) {
    throw new Error('value expected to of type string');
  }
  return value;
};

export const isNumber = (value: unknown): value is number => typeof value === 'number';

export const asNumber = (value: unknown): number => {
  if (!isNumber(value)) {
    throw new Error('value expected to of type number');
  }
  return value;
};

import { AlertTitle } from '@mui/material';
import { FunctionComponent } from 'react';
import { AlertWithSmallBottomMargin } from '../../../../components/Alert';
import { ByPersonStatus } from '../../../../models/pack';

type MissingRegistrationsAlertProps = {
  missing: ByPersonStatus;
};

const MissingRegistrationsAlert: FunctionComponent<MissingRegistrationsAlertProps> = (props) => {
  const { missing } = props;

  return (
    <AlertWithSmallBottomMargin severity="warning">
      <AlertTitle>Missing registrations</AlertTitle>
      <ul style={{ margin: 0 }}>
        {missing.cubs > 0 && (
          <li>
            {`${missing.cubs} missing Cubs`}
          </li>
        )}
        {missing.youngLeaders > 0 && (
          <li>
            {`${missing.youngLeaders} missing Young Leaders`}
          </li>
        )}
        {missing.leaders > 0 && (
          <li>
            {`${missing.leaders} missing Leaders`}
          </li>
        )}
        {missing.otherChildren > 0 && (
          <li>
            {`${missing.otherChildren} missing Other Children`}
          </li>
        )}
      </ul>
    </AlertWithSmallBottomMargin>
  );
};

export default MissingRegistrationsAlert;

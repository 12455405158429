import { HexColourCode } from './cssTypes';

const hexToRgb = (hex: string): number[] => [
  parseInt(hex.slice(1, 3), 16),
  parseInt(hex.slice(3, 5), 16),
  parseInt(hex.slice(5, 7), 16),
];

// eslint-disable-next-line no-bitwise
const rgbToHex = (r: number, g: number, b: number): HexColourCode => `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;

export const getColourRange = (
  startColor: HexColourCode,
  endColor: HexColourCode,
  steps: number,
): HexColourCode[] => {
  const startRGB = hexToRgb(startColor);
  const endRGB = hexToRgb(endColor);

  if (steps < 3) {
    return [startColor, endColor];
  }

  const step = 1 / (steps - 1);
  const shades: HexColourCode[] = [startColor];

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < steps; i++) {
    const r = Math.round(startRGB[0] + (endRGB[0] - startRGB[0]) * i * step);
    const g = Math.round(startRGB[1] + (endRGB[1] - startRGB[1]) * i * step);
    const b = Math.round(startRGB[2] + (endRGB[2] - startRGB[2]) * i * step);
    shades.push(rgbToHex(r, g, b));
  }

  shades.push(endColor);
  return shades;
};

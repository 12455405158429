import { NewBooking } from '../../../state/booking';

const sanitiseOnSubmit = (rawValues: NewBooking): NewBooking => {
  const contact = {
    ...rawValues.contact,
    addressStreet: rawValues.contact.addressStreet.trim(),
    addressTown: rawValues.contact.addressTown.trim(),
    addressPostcode: rawValues.contact.addressPostcode.toUpperCase().trim(),
  };

  return { ...rawValues, contact };
};

export default sanitiseOnSubmit;

import { alpha } from '@mui/system';
import styled from 'styled-components';
import { Pixels } from '../../helpers/cssTypes';

type DataGridWrapperProps = {
  width: Pixels
  disableExternalMargin: boolean
};

export const DataGridWrapper = styled.div<DataGridWrapperProps>`
  ${({ disableExternalMargin }) => (disableExternalMargin ? '' : 'margin-right: auto;')}
  ${({ disableExternalMargin }) => (disableExternalMargin ? '' : 'margin-left: auto;')}
  width: ${(props) => props.width};
  max-width: 100%;

  .success-cell {
    font-weight: bold;
    background-color: ${({ theme }) => alpha(theme.palette.mode === 'light'
    ? theme.palette.success.main
    : theme.palette.success.dark, 0.5)};
  }
  
  .warning-cell {
    font-weight: bold;
    background-color: ${({ theme }) => alpha(theme.palette.mode === 'light'
    ? theme.palette.warning.main
    : theme.palette.warning.dark, 0.5)};
  }

  .null-cell {
    background-color: ${({ theme }) => alpha(theme.palette.grey.A400, 0.25)};
    color: ${({ theme }) => theme.palette.grey.A400};
  }
`;

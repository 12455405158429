export const isEmptyOrWhitespace = (
  value: string | null,
): boolean => value === null || value.match(/^ *$/) !== null;

export const coerceStringToNull = (
  value: string | null | undefined,
): string | null => (value === undefined || isEmptyOrWhitespace(value) ? null : value);

export const toTitleCase = (value: string): string => value.replace(/([a-z])([A-Z])/g, '$1 $2');

export const toSentenceCase = (value: string): string => {
  const firstChar = value.charAt(0).toUpperCase();
  return `${firstChar}${value.slice(1)}`;
};

import {
  Backdrop, Button, CircularProgress, TextField,
} from '@mui/material';
import { ReactNode } from 'react';
import { CreateDoctor } from '../../../models/doctor';
import CardFooter from '../../CardFooter';
import { FormGrid } from '../../FormGrid';
import { FormProps } from '../types';

type DoctorFormProps = FormProps<CreateDoctor> & {
  children?: ReactNode
};

const DoctorForm = (props: DoctorFormProps): JSX.Element => {
  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    children,
  } = props;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <FormGrid columns={2}>
        <TextField
          label="Surgery name"
          value={values.surgeryName}
          onBlur={() => setFieldTouched('surgeryName')}
          onChange={async (event) => {
            await setFieldValue('surgeryName', event.target.value);
          }}
          error={touched.surgeryName && Boolean(errors.surgeryName)}
          helperText={(touched.surgeryName && errors.surgeryName) || ' '}
        />
        <TextField
          label="Doctor name (if known)"
          value={values.name}
          onBlur={() => setFieldTouched('name')}
          onChange={async (event) => {
            await setFieldValue('name', event.target.value);
          }}
          error={touched.name && Boolean(errors.name)}
          helperText={(touched.name && errors.name) || ' '}
        />
        <TextField
          label="Phone number"
          value={values.phone}
          onBlur={() => setFieldTouched('phone')}
          onChange={async (event) => {
            await setFieldValue('phone', event.target.value);
          }}
          error={touched.phone && Boolean(errors.phone)}
          helperText={(touched.phone && errors.phone) || ' '}
          inputProps={{ inputMode: 'tel' }}
        />
      </FormGrid>
      <FormGrid>
        <TextField
          label="Street address"
          value={values.addressStreet}
          onBlur={() => setFieldTouched('addressStreet')}
          onChange={async (event) => {
            await setFieldValue('addressStreet', event.target.value);
          }}
          error={touched.addressStreet && Boolean(errors.addressStreet)}
          helperText={(touched.addressStreet && errors.addressStreet) || ' '}
          multiline
          minRows={3}
        />
      </FormGrid>
      <FormGrid columns={2}>
        <TextField
          label="Town"
          value={values.addressTown}
          onBlur={() => setFieldTouched('addressTown')}
          onChange={async (event) => {
            await setFieldValue('addressTown', event.target.value);
          }}
          error={touched.addressTown && Boolean(errors.addressTown)}
          helperText={(touched.addressTown && errors.addressTown) || ' '}
        />
      </FormGrid>
      {children ?? (
        <CardFooter>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </CardFooter>
      )}
      <Backdrop open={isSubmitting}>
        <CircularProgress />
      </Backdrop>
    </form>
  );
};

export default DoctorForm;

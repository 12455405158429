import { AccountCircle } from '@mui/icons-material';
import {
  IconButton,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../../../firebase';
import { getShortFullName } from '../../../../helpers/person';
import routes from '../../../../routes';
import { reset as resetGridState } from '../../../../state/grids';
import { protectedApi } from '../../../../state/protectedApi';
import { useGetPersonQuery } from '../../../../state/protectedApi/people';
import { useGetCurrentUserQuery } from '../../../../state/protectedApi/user';
import { useTheme } from '../../../../theme';
import * as Styled from './styles';

type UserMenuProps = {
  onNavigate: () => void
};

const UserMenu = (props: UserMenuProps): JSX.Element => {
  const { onNavigate } = props;

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const screenWiderThanMobile = useMediaQuery(theme.breakpoints.up('sm'));

  const { data: user } = useGetCurrentUserQuery(undefined, {
    skip: auth.currentUser === null,
  });
  const {
    data: person,
  } = useGetPersonQuery(user?.personId ?? skipToken);

  const openMenu = (event: MouseEvent<HTMLElement>): void => {
    setAnchorElement(event.currentTarget);
  };

  const closeMenu = (): void => {
    setAnchorElement(null);
  };

  const login = (): void => {
    const currentSearch = new URLSearchParams(location.search);
    const redirect = location.pathname === routes.home ? routes.dashboard : location.pathname;
    const params = createSearchParams({ ...currentSearch, redirect });
    navigate({ pathname: routes.login, search: `?${params.toString()}` });
  };

  const logOut = async (): Promise<void> => {
    closeMenu();
    navigate(routes.home);
    await auth.signOut();
    dispatch(protectedApi.util.resetApiState());
    dispatch(resetGridState());
  };

  const navigateOnClick = (route: string) => (): void => {
    closeMenu();
    navigate(route);
    onNavigate();
  };

  if (auth.currentUser === null) {
    return (
      <div>
        <Styled.UsernameButton
          size="large"
          startIcon={<AccountCircle />}
          onClick={login}
        >
          Login
        </Styled.UsernameButton>
      </div>
    );
  }

  return (
    <Styled.Wrapper>
      {person && screenWiderThanMobile ? (
        <Styled.UsernameButton
          size="large"
          startIcon={<AccountCircle />}
          aria-label="user settings"
          aria-controls="menu-user-settings"
          aria-haspopup="true"
          onClick={openMenu}
        >
          {getShortFullName(person)}
        </Styled.UsernameButton>
      ) : (
        <IconButton
          size="large"
          aria-label="user settings"
          aria-controls="menu-user-settings"
          aria-haspopup="true"
          onClick={openMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
      )}
      <Styled.Menu
        id="menu-user-settings"
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElement)}
        onClose={closeMenu}
      >
        <MenuItem onClick={navigateOnClick(routes.changePassword)}>Change password</MenuItem>
        <MenuItem onClick={logOut}>Log out</MenuItem>
      </Styled.Menu>
    </Styled.Wrapper>
  );
};

export default UserMenu;

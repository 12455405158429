import { Table, TableBody } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { FunctionComponent } from 'react';
import DataPanel, { DataPanelDisplayProps } from '../../../../components/DataPanel';
import EmailLink from '../../../../components/EmailLink';
import LoadingText from '../../../../components/LoadingText';
import PhoneLink from '../../../../components/PhoneLink';
import TableKeyValueRow from '../../../../components/TableKeyValueRow';
import checkPersonPermissions from '../../../../helpers/dataAction';
import { getFullName } from '../../../../helpers/person';
import { getOnsiteStatusLabel } from '../../../../models/onsiteStatus';
import { Person } from '../../../../models/person';
import { getPersonStatusLabel } from '../../../../models/personStatus';
import { User } from '../../../../models/user';
import { useGetPackQuery } from '../../../../state/protectedApi/packs';
import {
  useDeletePersonMutation,
  useGetPersonQuery,
  useUpdatePersonMutation,
} from '../../../../state/protectedApi/people';
import PersonForm from './PersonForm';
import sanitiseOnSubmit from './PersonForm/sanitise';
import { validationSchema } from './PersonForm/schema';

type PersonPanelProps = {
  personId: number
  user: User | undefined
};

const DisplayPerson: FunctionComponent<DataPanelDisplayProps<Person>> = (props) => {
  const { data } = props;

  const {
    data: pack,
  } = useGetPackQuery(data.packId ?? skipToken);

  return (
    <Table size="small" sx={{ marginBottom: '30px' }}>
      <TableBody>
        <TableKeyValueRow name="ID">{data.id}</TableKeyValueRow>
        <TableKeyValueRow name="Status">{getPersonStatusLabel(data.status)}</TableKeyValueRow>
        {pack && (
          <TableKeyValueRow name="Pack">{pack?.name}</TableKeyValueRow>
        )}
        {pack && pack.subcamp && (
          <TableKeyValueRow name="Subcamp">{pack.subcamp}</TableKeyValueRow>
        )}
        <TableKeyValueRow name="Name">{getFullName(data)}</TableKeyValueRow>
        {data.scoutingAppointment && (
          <TableKeyValueRow name="Scouting appointment">{data.scoutingAppointment}</TableKeyValueRow>
        )}
        {data.membershipNumber && (
          <TableKeyValueRow name="Membership number">{data.membershipNumber}</TableKeyValueRow>
        )}
        {data.mobile && (
          <TableKeyValueRow name="Mobile">
            <PhoneLink phoneNumber={data.mobile} />
          </TableKeyValueRow>
        )}
        {data.email && (
          <TableKeyValueRow name="Email">
            <EmailLink email={data.email} />
          </TableKeyValueRow>
        )}
        <TableKeyValueRow name="Postal Address">
          {data.addressStreet}

          {data.addressTown && <br />}
          {data.addressTown}

          {data.addressPostcode && <br />}
          {data.addressPostcode}
        </TableKeyValueRow>
        <TableKeyValueRow name="Pack booking contact">
          {data.packBookingContact ? 'Yes' : 'No'}
        </TableKeyValueRow>
        <TableKeyValueRow name="Onsite status">
          {getOnsiteStatusLabel(data.onsite)}
        </TableKeyValueRow>
      </TableBody>
    </Table>
  );
};

const PersonPanel = (props: PersonPanelProps): JSX.Element => {
  const { personId, user } = props;

  const fetchPerson = useGetPersonQuery(personId);
  const [updatePerson] = useUpdatePersonMutation();
  const [deletePerson] = useDeletePersonMutation();

  return (
    <DataPanel
      title="Person"
      modelName="person"
      fetch={fetchPerson}
      prepareInitialValues={(data: Person) => ({
        ...data,
        mobile: data.mobile ?? '',
        email: data.email ?? '',
        scoutingAppointment: data.scoutingAppointment ?? '',
        membershipNumber: data.membershipNumber ?? '',
      })}
      formikConfig={{ validationSchema }}
      sanitiseOnSubmit={sanitiseOnSubmit}
      canUpdate={(data: Person) => checkPersonPermissions('Update', data, user)}
      update={{
        mutation: updatePerson,
      }}
      canDelete={(data: Person) => checkPersonPermissions('Delete', data, user)}
      deletion={{
        mutation: () => deletePerson(personId),
        dialogTitle: 'Delete person',
        dialogDescription: 'Are you sure you want to delete the person?',
      }}
      LoadingComponent={<LoadingText lines={7} />}
      DisplayComponent={DisplayPerson}
      FormComponent={PersonForm}
    />
  );
};

export default PersonPanel;

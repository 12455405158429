import { OptionList } from '../helpers/options';

export const subcamps = [
  'Blue',
  'Green',
  'Red',
  'Yellow',
] as const;

export type Subcamp = (typeof subcamps)[number];

export const isValidSubcamp = (
  value: string,
): value is Subcamp => subcamps.includes(value as never);

export const subcampOptions: OptionList<Subcamp> = subcamps.map(
  (subcamp) => ({
    label: subcamp,
    value: subcamp,
  }),
);

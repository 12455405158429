import {
  Button,
  Collapse,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { anyReviewRequired, reviewIfRequired } from '../../helpers/reviews';
import {
  Registration,
  Review,
  Reviews,
} from '../../models/registration';
import { ReviewType } from '../../models/reviewType';
import { Subcamp } from '../../models/subcamp';
import { useUpdateRegistrationMutation } from '../../state/protectedApi/registration';
import { useTheme } from '../../theme';
import Table from '../Table';
import ReviewTableRow from './ReviewTableRow';
import * as Styled from './styles';

type RegistrationReviewTableProps = {
  registration: Registration
  reviewType?: ReviewType
  packId: number | undefined | null
  subcamp: Subcamp | undefined
  reviewManyMode?: boolean
  onSave?: () => void
  onPrev?: () => void
  onSkip?: () => void
};

const includeReview = (
  filter: ReviewType | undefined,
  current: ReviewType,
): boolean => filter === undefined || current === filter;

const RegistrationReviewTable = (props: RegistrationReviewTableProps): JSX.Element => {
  const {
    registration,
    reviewType,
    subcamp,
    packId,
    reviewManyMode,
    onSave,
    onPrev,
    onSkip,
  } = props;
  const {
    adminReview,
    compassReview,
    activitiesReview,
    cateringReview,
    subcampReview,
    packLeaderReview,
  } = registration;

  const [updateRegistration] = useUpdateRegistrationMutation();

  const theme = useTheme();
  const stackButtons = useMediaQuery(theme.breakpoints.down('md'));
  const [changesNotYetSaved, setChangesNotYetSaved] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [reviewTypeWithNewTags, setReviewTypeWithNewTags] = useState<ReviewType[]>([]);

  const initialUpdateModel: Reviews = useMemo(() => ({
    personId: registration.personId,
    adminReview: reviewIfRequired(adminReview, includeReview(reviewType, 'Admin')),
    compassReview: reviewIfRequired(compassReview, includeReview(reviewType, 'Compass')),
    activitiesReview: reviewIfRequired(activitiesReview, includeReview(reviewType, 'Activities')),
    cateringReview: reviewIfRequired(cateringReview, includeReview(reviewType, 'Catering')),
    subcampReview: reviewIfRequired(subcampReview, includeReview(reviewType, 'Subcamp'), subcamp !== undefined),
    packLeaderReview: reviewIfRequired(packLeaderReview, includeReview(reviewType, 'PackLeader'), packId !== undefined),
  }), [
    activitiesReview,
    adminReview,
    compassReview,
    cateringReview,
    packId,
    packLeaderReview,
    registration.personId,
    reviewType,
    subcamp,
    subcampReview,
  ]);

  const [updateModel, setUpdateModel] = useState<Reviews>(initialUpdateModel);

  useEffect(() => {
    setUpdateModel(initialUpdateModel);
    setChangesNotYetSaved(false);
    setDisabled(false);
  }, [initialUpdateModel]);

  if (!anyReviewRequired(registration)) {
    return (
      <div>No reviews are required</div>
    );
  }

  const handleChange = (review: keyof Omit<Reviews, 'personId'>) => (update: Review) => {
    setChangesNotYetSaved(true);
    const newUpdateModel = { ...updateModel };
    newUpdateModel[review] = update;
    setUpdateModel(newUpdateModel);
  };

  const handleTagCreation = (creationForReviewType: ReviewType) => () => {
    if (!reviewTypeWithNewTags.includes(creationForReviewType)) {
      setReviewTypeWithNewTags([...reviewTypeWithNewTags, creationForReviewType]);
    }
  };

  const handleSubmit = (): void => {
    if (!disabled) {
      setDisabled(true);
      updateRegistration({ ...updateModel, reviewTypeWithNewTags }).then(
        (response) => {
          const updateSuccessful = 'data' in response;
          const message = `Update of reviews ${updateSuccessful ? 'successful' : 'failed'}`;
          enqueueSnackbar(
            message,
            {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              variant: updateSuccessful ? 'success' : 'error',
              style: { whiteSpace: 'pre-line' },
              persist: !updateSuccessful,
            },
          );
          if (!updateSuccessful) {
            throw new Error('Update failed');
          } else {
            setChangesNotYetSaved(false);
            if (onSave) {
              onSave();
            }
          }
          setDisabled(false);
          return response.data;
        },
      ).catch((error) => {
        setDisabled(false);
        console.error(error);
      });
    }
  };

  return (
    <>
      <Table sx={{ marginBottom: '30px' }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Review</strong>
            </TableCell>
            <TableCell>
              <strong>Change</strong>
            </TableCell>
            <TableCell>
              <strong>Tags</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {updateModel.adminReview && (
            <ReviewTableRow
              name="Core Team"
              type="Admin"
              review={updateModel.adminReview}
              disabled={disabled}
              onChange={handleChange('adminReview')}
              onTagCreation={handleTagCreation('Admin')}
              buttonOrientation={stackButtons ? 'vertical' : 'horizontal'}
            />
          )}
          {updateModel.compassReview && (
            <ReviewTableRow
              name="Compass"
              type="Compass"
              review={updateModel.compassReview}
              disabled={disabled}
              onChange={handleChange('compassReview')}
              onTagCreation={handleTagCreation('Compass')}
              buttonOrientation={stackButtons ? 'vertical' : 'horizontal'}
            />
          )}
          {updateModel.cateringReview && (
            <ReviewTableRow
              name="Catering"
              type="Catering"
              review={updateModel.cateringReview}
              disabled={disabled}
              onChange={handleChange('cateringReview')}
              onTagCreation={handleTagCreation('Catering')}
              buttonOrientation={stackButtons ? 'vertical' : 'horizontal'}
            />
          )}
          {updateModel.activitiesReview && (
            <ReviewTableRow
              name="Activities"
              type="Activities"
              review={updateModel.activitiesReview}
              disabled={disabled}
              onChange={handleChange('activitiesReview')}
              onTagCreation={handleTagCreation('Activities')}
              buttonOrientation={stackButtons ? 'vertical' : 'horizontal'}
            />
          )}
          {updateModel.subcampReview && (
            <ReviewTableRow
              name="Subcamp"
              type="Subcamp"
              review={updateModel.subcampReview}
              disabled={disabled}
              onChange={handleChange('subcampReview')}
              onTagCreation={handleTagCreation('Subcamp')}
              buttonOrientation={stackButtons ? 'vertical' : 'horizontal'}
            />
          )}
          {updateModel.packLeaderReview && (
            <ReviewTableRow
              name="Pack Leader"
              type="PackLeader"
              review={updateModel.packLeaderReview}
              disabled={disabled}
              onChange={handleChange('packLeaderReview')}
              onTagCreation={handleTagCreation('PackLeader')}
              buttonOrientation={stackButtons ? 'vertical' : 'horizontal'}
            />
          )}
        </TableBody>
      </Table>
      {reviewManyMode ? (
        <Stack direction="row" spacing={5}>
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" size="large" disabled={onPrev === undefined} onClick={onPrev}>Back</Button>
            <Button variant="outlined" size="large" disabled={onSkip === undefined} onClick={onSkip}>Skip</Button>
          </Stack>
          <Button variant="contained" size="large" disabled={disabled || !changesNotYetSaved} onClick={handleSubmit}>
            Save
          </Button>
        </Stack>
      ) : (
        <Collapse in={changesNotYetSaved}>
          <Styled.ButtonWrapper>
            <Button variant="contained" size="large" disabled={disabled} onClick={handleSubmit}>
              Save Reviews
            </Button>
          </Styled.ButtonWrapper>
        </Collapse>
      )}
    </>
  );
};

export default RegistrationReviewTable;

import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { objectToSnake } from 'ts-case-convert/lib/caseConvert';
import { ActivitiesPageContent } from '../../models/content/activitiesPage';
import { ActivityContent } from '../../models/content/activityContent';
import { BookingPagesContent } from '../../models/content/bookingPages';
import { FaqPageContent } from '../../models/content/faqPage';
import { HomePageContent } from '../../models/content/homePage';
import { RegistrationPagesContent } from '../../models/content/registrationPages';
import useBooking from '../../state/booking/useBooking';
import {
  useGetActivitiesContentQuery,
  useGetActivitiesPageContentQuery,
  useGetBookingsContentQuery,
  useGetFaqContentQuery,
  useGetHomeContentQuery,
  useGetRegistrationsContentQuery,
} from '../../state/content';
import { useGetEventConfigQuery } from '../../state/publicApi';
import { useGetEditionsQuery } from '../../state/publicApi/editions';
import useRegistration from '../../state/registration/useRegistration';
import applyContext from './applyContext';

type FormattedContent<T> = {
  content?: T;
  isLoading: boolean;
  isError: boolean;
  error?: SerializedError | FetchBaseQueryError;
};

type ReduxQueryResult<T> = {
  data?: T
  isLoading: boolean
  isError: boolean
  error?: SerializedError | FetchBaseQueryError
};

const useContent = <T>(
  contentQuery: ReduxQueryResult<T>,
  contextQueries: {
    [key: string]: ReduxQueryResult<unknown>,
  },
  baseContext?: object,
): FormattedContent<T> => {
  const context = objectToSnake({
    ...baseContext,
    ...Object.fromEntries(
      Object.entries(contextQueries).map(([k, v]) => [k, v.data]),
    ),
  });

  const isLoading = contentQuery.isLoading
    || Object.values(contextQueries).some((q) => q.isLoading);
  const isError = contentQuery.isError
    || Object.values(contextQueries).some((q) => q.isError);
  const error = contentQuery.error
    ?? Object.values(contextQueries).find((q) => q.error)?.error;

  return {
    content: isLoading || isError ? undefined : applyContext(contentQuery.data, context),
    isLoading,
    isError,
    error,
  };
};

export const useHomeContent = (): FormattedContent<HomePageContent> => {
  const content = useGetHomeContentQuery();
  const event = useGetEventConfigQuery();
  const editions = useGetEditionsQuery();
  return useContent(content, { event, editions });
};

export const useActivitiesContent = (): FormattedContent<ActivityContent[]> => {
  const content = useGetActivitiesContentQuery();
  const event = useGetEventConfigQuery();
  return useContent(content, { event });
};

export const useActivitiesPageContent = (): FormattedContent<ActivitiesPageContent> => {
  const content = useGetActivitiesPageContentQuery();
  const event = useGetEventConfigQuery();
  const editions = useGetEditionsQuery();
  return useContent(content, { event, editions });
};

export const useFaqContent = (): FormattedContent<FaqPageContent> => {
  const content = useGetFaqContentQuery();
  const event = useGetEventConfigQuery();
  const editions = useGetEditionsQuery();
  return useContent(content, { event, editions });
};

export const useBookingsContent = (): FormattedContent<BookingPagesContent> => {
  const content = useGetBookingsContentQuery();
  const event = useGetEventConfigQuery();
  const editions = useGetEditionsQuery();
  const [booking] = useBooking();
  return useContent(content, { event, editions }, { booking });
};

export const useRegistrationsContent = (): FormattedContent<RegistrationPagesContent> => {
  const content = useGetRegistrationsContentQuery();
  const event = useGetEventConfigQuery();
  const [registration] = useRegistration();
  return useContent(content, { event }, { registration });
};

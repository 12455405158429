import { Outlet } from 'react-router-dom';
import PageLayout from '../PageLayout';

const PublicPage = (): JSX.Element => (
  <PageLayout>
    <Outlet />
  </PageLayout>
);

export default PublicPage;

import { Person } from '../models/person';
import { User } from '../models/user';

export type DataAction =
  | 'Read'
  | 'Create'
  | 'Update'
  | 'Delete';

const checkPersonPermissions = (
  action: DataAction,
  targetPerson: Person,
  user: User | undefined,
): boolean => {
  if (!user) {
    return false;
  }
  if (user.role === 'SuperUser' || user.role === 'Admin') {
    return true;
  }
  if (user.role === 'Subcamp' && user.subcamp !== targetPerson.subcamp) {
    return false;
  }
  if (user.role === 'PackLeader' && user.packId !== targetPerson.packId) {
    return false;
  }

  if (user.role === 'PackLeader') {
    return action === 'Read';
  }
  return action !== 'Delete';
};

export default checkPersonPermissions;

import { Backdrop, CircularProgress } from '@mui/material';
import { Outlet } from 'react-router-dom';
import BlocksRenderer from '../../components/BlocksRenderer';
import Card from '../../components/Card';
import CardHeading from '../../components/CardHeading';
import { useBookingsContent } from '../../helpers/content';
import useErrorHandler from '../../helpers/useErrorHandler';
import { useGetEventConfigQuery } from '../../state/publicApi';

const Bookings = (): JSX.Element => {
  const { content, error: contentError } = useBookingsContent();
  useErrorHandler(contentError, 'CMS Error: Failed to load bookings page content');

  const {
    data: eventConfig,
    error: eventConfigError,
  } = useGetEventConfigQuery();
  useErrorHandler(eventConfigError, 'API Error: Failed to load event configuration');

  if (!eventConfig || !content) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  const { phases: { bookings: { launch, deadline } } } = eventConfig;
  const isClosed = new Date() < new Date(launch);
  const isComplete = new Date() > new Date(deadline);

  if (isClosed) {
    return (
      <Card>
        <CardHeading>{content.closed.title}</CardHeading>
        <BlocksRenderer content={content.closed.body} />
      </Card>
    );
  }

  if (isComplete) {
    return (
      <Card>
        <CardHeading>{content.complete.title}</CardHeading>
        <BlocksRenderer content={content.complete.body} />
      </Card>
    );
  }

  return <Outlet />;
};

export default Bookings;

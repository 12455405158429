import { ReactNode } from 'react';
import * as Styled from './styles';

type CardProps = {
  children: ReactNode;
};

const Card = (props: CardProps): JSX.Element => {
  const { children } = props;

  return <Styled.Card>{children}</Styled.Card>;
};

export default Card;

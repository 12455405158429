import { FC, ReactNode } from 'react';
import * as Styles from './styles';

type DataPanelWrapperProps = {
  children: ReactNode | ReactNode[]
};

const DataPanelWrapper: FC<DataPanelWrapperProps> = (props) => {
  const { children } = props;

  return (
    <Styles.ColumnWithBottomPadding desktop={8} tablet={6}>
      {children}
    </Styles.ColumnWithBottomPadding>
  );
};

export default DataPanelWrapper;

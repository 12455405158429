import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  AlertTitle,
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/query';
import { useFormik } from 'formik';
import { closeSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { uniqueItems } from '../../helpers/arrays';
import { getShortTime } from '../../helpers/datesStrings';
import { addNotification } from '../../helpers/notification';
import { getFullName } from '../../helpers/person';
import useDefaultSession from '../../helpers/useDefaultSession';
import useErrorHandler, { getMessageForError } from '../../helpers/useErrorHandler';
import { useUrlOptionalNumberParam } from '../../helpers/useUrlParam';
import { getActivityLeaderRoleLabel } from '../../models/activityLeaderRole';
import { RegisterItem } from '../../models/register';
import { Subcamp, isValidSubcamp } from '../../models/subcamp';
import routes from '../../routes';
import {
  useGetActivityRegisterProtectedQuery,
  useUpdateActivityRegisterProtectedMutation,
} from '../../state/protectedApi/activities';
import {
  useGetActivityRegisterQuery,
  useGetSessionsQuery,
  useUpdateActivityRegisterMutation,
} from '../../state/publicApi/activities';
import { useGetEditionsQuery } from '../../state/publicApi/editions';
import { FormGrid } from '../FormGrid';
import LoadingText from '../LoadingText';
import PersonStatusChip from '../PersonStatusChip';
import RouterLink from '../RouterLink';
import * as Styled from './styles';

type ActivityRegisterTableContentProps = {
  sessionId: number
  activityId: number
  autoSave: boolean
  type: 'public' | 'protected'
  register: RegisterItem[]
  isFetching: boolean
  refetch: () => void
  countSubcampPrefix?: string
};

const ActivityRegisterTableContent: FC<ActivityRegisterTableContentProps> = (props) => {
  const {
    sessionId,
    activityId,
    autoSave,
    type,
    register,
    isFetching,
    refetch,
    countSubcampPrefix,
  } = props;
  const linkToDetailsPages = type === 'protected';

  const sortedRegister = register.slice()
    .sort((a, b) => getFullName(a).localeCompare(getFullName(b)))
    .sort((a, b) => b.status.localeCompare(a.status));

  const [updateProtected] = useUpdateActivityRegisterProtectedMutation();
  const [updatePublic] = useUpdateActivityRegisterMutation();
  const update = type === 'protected' ? updateProtected : updatePublic;

  const [alertMode, setAlertMode] = useState<'success' | 'error' | 'warning'>();
  const [apiError, setApiError] = useState<FetchBaseQueryError | SerializedError>();

  const alreadyCheckedIn = register?.slice()
    .filter((person) => person.checkedInAt !== undefined)
    .map((person) => person.id) ?? [];

  const {
    values,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    submitForm,
  } = useFormik({
    initialValues: {
      people: alreadyCheckedIn,
    },
    onSubmit: async (newValues) => {
      setAlertMode(undefined);
      setApiError(undefined);
      closeSnackbar();
      const added = newValues.people.filter((id) => !alreadyCheckedIn.includes(id));
      const removed = alreadyCheckedIn.filter((id) => !newValues.people.includes(id));
      await update({
        activityId: Number(activityId),
        sessionId,
        checkedIn: added,
        revertCheckIn: removed,
      }).then((response) => {
        const addedNames = register.slice()
          .filter((person) => added.includes(person.id))
          .map((person) => `${getFullName(person)} (${person.id})`)
          .join(', ');
        const removedNames = register.slice()
          .filter((person) => removed.includes(person.id))
          .map((person) => `${getFullName(person)} (${person.id})`)
          .join(', ');

        let actionMessage = 'error' in response ? 'Failure: ' : 'Success: ';
        if (addedNames) {
          actionMessage += `adding ${addedNames}`;
        }
        if (addedNames && removedNames) {
          actionMessage += ' and ';
        }
        if (removedNames) {
          actionMessage += `removing ${removedNames}`;
        }

        if ('error' in response) {
          setApiError(response.error);
          setAlertMode('error');
        } else if (!autoSave) {
          setAlertMode('success');
          refetch();
        } else if (removedNames) {
          addNotification(actionMessage, 'warning');
          refetch();
        } else {
          addNotification(actionMessage, 'success');
          refetch();
        }
      });
    },
  });

  const dirty = values.people.length !== alreadyCheckedIn.length
    || values.people.some((id) => !alreadyCheckedIn.includes(id))
    || alreadyCheckedIn.some((id) => !values.people.includes(id));

  if (dirty && alertMode === undefined) {
    setAlertMode('warning');
  } else if (!dirty && alertMode === 'warning') {
    setAlertMode(undefined);
  }

  const disabled = isFetching || isSubmitting;

  const togglePerson = async (personId: number): Promise<void> => {
    if (disabled) {
      return;
    }
    if (alertMode !== 'warning') {
      setAlertMode('warning');
    }
    if (values.people.includes(personId)) {
      await setFieldValue('people', values.people.filter((id) => id !== personId));
    } else {
      await setFieldValue('people', [...values.people, personId]);
    }
    if (autoSave) {
      await submitForm();
    }
  };

  const participantIds = register.slice().filter((person) => ['Cub', 'OtherChild'].includes(person.status))
    .map((person) => person.id);
  const leaderIds = register.slice().filter((person) => ['Leader', 'YoungLeader'].includes(person.status))
    .map((person) => person.id);

  const countOfParticipants = values.people.slice()
    .filter((id) => participantIds.includes(id)).length;
  const countOfLeaders = values.people.slice()
    .filter((id) => leaderIds.includes(id)).length;

  // TODO: add an alert if there are not enough leaders
  // TODO: add activity review tags to register
  // TODO: change to two column layout for mobiles (checkbox + all the info)
  // TODO: add button to save the register (reveal the save button when changes are made)
  return (
    <form noValidate onSubmit={handleSubmit}>
      <Styled.StickyWrapper>
        <Collapse in={!autoSave && alertMode !== undefined && !isSubmitting}>
          <Styled.AlertWrapper>
            <Alert
              variant="filled"
              severity={alertMode}
              action={!alertMode || alertMode === 'success' ? (
                <IconButton onClick={() => setAlertMode(undefined)}>
                  <CloseIcon />
                </IconButton>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '10px' }}
                >
                  {alertMode === 'error' ? 'Retry save' : 'Save changes'}
                </Button>
              )}
            >
              <AlertTitle>
                <strong>
                  {alertMode === 'success' && 'Save successful'}
                  {alertMode === 'warning' && 'You have unsaved changes'}
                  {alertMode === 'error' && 'An error occurred when trying to save register'}
                </strong>
              </AlertTitle>
              {alertMode === 'success' && 'Latest check in status for register has been saved'}
              {alertMode === 'warning' && 'Please click the button to save the changes and then wait for confirmation.'}
              {alertMode === 'error' && apiError && getMessageForError(apiError)}
            </Alert>
          </Styled.AlertWrapper>
        </Collapse>
      </Styled.StickyWrapper>
      {countOfParticipants || participantIds.length ? (
        <p>
          <strong>
            {countSubcampPrefix ? `${countSubcampPrefix} Participants:` : 'Participants:'}
          </strong>
          {` ${countOfParticipants} of ${participantIds.length}`}
        </p>
      ) : null}
      {countOfLeaders || leaderIds.length ? (
        <p>
          <strong>
            {countSubcampPrefix ? `${countSubcampPrefix} Leaders:` : 'Leaders:'}
          </strong>
          {` ${countOfLeaders} of ${leaderIds.length}`}
        </p>
      ) : null}
      {sortedRegister && sortedRegister.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <Styled.CheckBoxTableCell>
                <Checkbox
                  checked={values.people.length === register.length}
                  indeterminate={values.people.length > 0
                    && values.people.length < register.length}
                  disabled={disabled}
                  onChange={async (event) => {
                    if (disabled) {
                      return;
                    }
                    if (alertMode !== 'warning') {
                      setAlertMode('warning');
                    }
                    if (event.target.checked) {
                      await setFieldValue('people', register.map((person) => person.id));
                    } else {
                      await setFieldValue('people', []);
                    }
                    if (autoSave) {
                      await submitForm();
                    }
                  }}
                />
              </Styled.CheckBoxTableCell>
              <TableCell>Name</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRegister.map((person) => (
              <TableRow
                key={`activity-person-row-${person.id}`}
                onClick={() => !linkToDetailsPages && togglePerson(person.id)}
              >
                <Styled.CheckBoxTableCell>
                  <Checkbox
                    id={`activity-person-name-${person.id}`}
                    checked={values.people.includes(person.id)}
                    onChange={() => togglePerson(person.id)}
                    disabled={disabled}
                  />
                </Styled.CheckBoxTableCell>
                <TableCell>
                  <Stack
                    justifyContent="space-between"
                    direction="row"
                    alignItems="center"
                  >
                    <div>
                      <Styled.NameWrapper>
                        <strong>
                          {`${getFullName(person)} `}
                        </strong>
                        {linkToDetailsPages ? (
                          <>
                            (
                            <RouterLink to={routes.people.detail(person.id)}>
                              {`ID: ${person.id}`}
                            </RouterLink>
                            )
                          </>
                        ) : `(ID: ${person.id})`}
                      </Styled.NameWrapper>
                      {person.reviewTags && (
                        <Styled.ReviewTagsWrapper>
                          {person.reviewTags.map((tag) => (
                            <Styled.ReviewTagChip
                              key={`activity-person-tag-${person.id}-${tag.id}`}
                              size="small"
                              label={tag.text}
                              color="error"
                              variant="filled"
                            />
                          ))}
                        </Styled.ReviewTagsWrapper>
                      )}
                      {person.isStaff && (
                        <div>
                          {person.isStaff && 'Staff'}
                        </div>
                      )}
                      {person.subcamp && (
                        <div>
                          {`${person.subcamp} Subcamp`}
                        </div>
                      )}
                      {person.reference && person.packId && person.packName && (
                        <div>
                          {`${person.packName} (`}
                          {linkToDetailsPages ? (
                            <RouterLink to={routes.packs.detail(person.packId)}>
                              {person.reference}
                            </RouterLink>
                          ) : person.reference}
                          )
                        </div>
                      )}
                      {person.leaderRole && (
                        <div>
                          {'Role: '}
                          <strong>
                            {getActivityLeaderRoleLabel(person.leaderRole)}
                          </strong>
                        </div>
                      )}
                    </div>
                    <div>
                      <PersonStatusChip status={person.status} size="small" />
                    </div>
                  </Stack>
                </TableCell>
                <TableCell align="right">
                  {person.checkedInAt ? getShortTime(person.checkedInAt) : 'Missing'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <Backdrop open={disabled} invisible>
        <CircularProgress />
      </Backdrop>
    </form>
  );
};

type ActivityRegisterProps = {
  activityId: number
  type: 'public' | 'protected'
};

const ActivityRegister: FC<ActivityRegisterProps> = (props) => {
  const { activityId, type } = props;

  const [autoSave, setAutoSave] = useState(true);
  const [editionId, setEditionId] = useState<number>();
  const [subcamp, setSubcamp] = useState<Subcamp | 'All' | 'Staff'>('All');

  const [sessionId, setSessionId] = useUrlOptionalNumberParam('session');
  const defaultSession = useDefaultSession();

  const {
    data: sessions,
    isLoading: sessionsIsLoading,
  } = useGetSessionsQuery();
  const selectedSession = sessions?.find((s) => s.id === sessionId);

  useEffect(() => {
    if (!editionId && !sessionId && defaultSession) {
      setSessionId(defaultSession.id);
      setEditionId(defaultSession.editionId);
    } else if (!editionId && selectedSession) {
      setEditionId(selectedSession.editionId);
    }
  }, [defaultSession, editionId, selectedSession, sessionId, setSessionId]);

  const {
    data: editions,
    isLoading: editionsIsLoading,
    error: editionsError,
  } = useGetEditionsQuery();
  useErrorHandler(editionsError, 'API Error: Failed to load editions');

  const fetchProtected = useGetActivityRegisterProtectedQuery(
    sessionId && type === 'protected' ? { activityId, sessionId } : skipToken,
  );
  const fetchPublic = useGetActivityRegisterQuery(
    sessionId && type === 'public' ? { activityId, sessionId } : skipToken,
  );
  const fetch = type === 'protected' ? fetchProtected : fetchPublic;
  const {
    currentData: rawActivityRegister,
    isLoading,
    isFetching,
    refetch,
    error,
  } = fetch;

  const activityRegister = rawActivityRegister?.slice()
    .filter((item) => {
      if (subcamp === 'All') {
        return true;
      }
      if (item.subcamp === undefined) {
        return subcamp === 'Staff';
      }
      return item.subcamp === subcamp;
    }) ?? [];

  const subcampsRepresented = uniqueItems<Subcamp | 'Staff'>(
    rawActivityRegister?.slice().map((item) => item.subcamp ?? 'Staff') ?? [],
  );
  subcampsRepresented.sort();

  const getCountForSubcamp = (subcampName: Subcamp | 'Staff'): number => {
    if (subcampName === 'Staff') {
      return rawActivityRegister?.slice().filter((item) => item.subcamp === undefined).length ?? 0;
    }
    return rawActivityRegister?.slice()
      .filter((item) => item.subcamp === subcampName).length ?? 0;
  };

  const sessionOptions = sessions?.slice().filter((s) => !editionId || s.editionId === editionId)
    .sort((a, b) => a.order - b.order) ?? [];
  const selectedSessionAvailable = sessionOptions.some((s) => s.id === sessionId);
  return (
    <>
      <FormGrid columns={2}>
        <FormControl fullWidth>
          <InputLabel>Edition</InputLabel>
          <Select
            value={editionId ?? ''}
            onChange={(event) => {
              setEditionId(Number(event.target.value));
              setSessionId(undefined);
              setSubcamp('All');
            }}
            disabled={editionsIsLoading}
            label="Edition"
          >
            {editions?.slice().map((edition) => (
              <MenuItem key={edition.id} value={edition.id}>
                {edition.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Activity Session</InputLabel>
          <Select
            value={selectedSessionAvailable ? sessionId : ''}
            label="Activity Session"
            onChange={(event) => {
              setSessionId(Number(event.target.value));
              setSubcamp('All');
            }}
            disabled={sessionsIsLoading}
          >
            {sessionOptions.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {`Session ${s.order}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {subcampsRepresented.length > 1 && (
          <FormControl fullWidth>
            <InputLabel>Subcamp</InputLabel>
            <Select
              value={subcamp}
              label="Subcamp"
              onChange={(event) => {
                const newValue = event.target.value;
                if (isValidSubcamp(newValue) || newValue === 'All' || newValue === 'Staff') {
                  setSubcamp(newValue);
                }
              }}
            >
              <MenuItem value="All">
                All
              </MenuItem>
              {subcampsRepresented.map((subcampItem) => (
                <MenuItem key={subcampItem} value={subcampItem}>
                  {`${subcampItem} (${getCountForSubcamp(subcampItem)})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormGroup>
          <FormControlLabel
            control={(
              <Switch
                checked={autoSave}
                onChange={(event, checked) => {
                  closeSnackbar();
                  setAutoSave(checked);
                }}
              />
            )}
            label="Save changes automatically"
          />
        </FormGroup>
      </FormGrid>
      {error && (
        <Alert severity="error">
          <AlertTitle>
            {getMessageForError(error) ?? 'An unexpected error occurred when trying to fetch register'}
          </AlertTitle>
        </Alert>
      )}
      {isLoading && (
        <LoadingText lines={10} />
      )}
      {!isLoading && sessionId && activityRegister && (
        <ActivityRegisterTableContent
          sessionId={sessionId}
          activityId={activityId}
          autoSave={autoSave}
          type={type}
          register={activityRegister}
          isFetching={isFetching}
          refetch={refetch}
          countSubcampPrefix={subcamp && subcamp !== 'All' ? subcamp : undefined}
        />
      )}
    </>
  );
};

export default ActivityRegister;

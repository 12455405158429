import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  AlertTitle,
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useFormik } from 'formik';
import { closeSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { getShortTime } from '../../helpers/datesStrings';
import { addNotification } from '../../helpers/notification';
import { getFullName } from '../../helpers/person';
import { ReduxFetch } from '../../helpers/reduxFetch';
import { getMessageForError } from '../../helpers/useErrorHandler';
import { getActivityLeaderRoleLabel } from '../../models/activityLeaderRole';
import { RegisterItem, UpdateRegisterPayload } from '../../models/register';
import routes from '../../routes';
import { FormGrid } from '../FormGrid';
import LoadingText from '../LoadingText';
import ManualPersonIdForm from '../ManualPersonIdForm';
import PersonStatusChip from '../PersonStatusChip';
import RouterLink from '../RouterLink';
import * as Styled from './styles';

// TODO this file needs refactored to move subcomponents into their own files

type MutationReturn = Promise<{ data: void } | { error: SerializedError | FetchBaseQueryError }>;

type DeletePersonButtonProps = {
  personId: number
  removePerson: (personId: number) => MutationReturn
  onSuccess: () => void
  disabled: boolean
  disabledMessage: string
};

// TODO: consider adding a revert button to undo the last change
const DeletePersonButton: FC<DeletePersonButtonProps> = (props) => {
  const {
    personId,
    removePerson,
    onSuccess,
    disabled,
    disabledMessage,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRemovePerson = async (): Promise<void> => {
    if (disabled || isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await removePerson(personId).then((response) => {
      if ('error' in response) {
        addNotification(getMessageForError(response.error), 'error');
      } else {
        addNotification('Person removed from register', 'success');
        onSuccess();
      }
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  return (
    <Tooltip title={disabled ? disabledMessage : 'Remove person from register'}>
      <IconButton
        onClick={async (event) => {
          event.stopPropagation();
          await handleRemovePerson();
        }}
        size="small"
        disabled={disabled || isSubmitting}
      >
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

type AddPersonSearchProps = {
  editionId?: number
  addPerson: (personId: number) => MutationReturn
  onSuccess: () => void
  disabled: boolean
};

const AddPersonSearch: FC<AddPersonSearchProps> = (props) => {
  const {
    editionId,
    addPerson,
    onSuccess,
    disabled,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAddPerson = async (payload: { personId: number }): Promise<void> => {
    const { personId } = payload;
    if (disabled || isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await addPerson(personId).then((response) => {
      if ('error' in response) {
        addNotification(getMessageForError(response.error), 'error');
      } else {
        addNotification('Person added to register', 'success');
        onSuccess();
      }
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  // TODO: add messaging to explain why the inputs are disabled
  return (
    <>
      <h3>Add person to register</h3>
      <ManualPersonIdForm
        edition={editionId}
        onSubmit={handleAddPerson}
        disabled={disabled || isSubmitting}
        submitButtonText="Add person"
      />
    </>
  );
};

type RegisterContentProps = {
  editionId?: number
  autoSave: boolean
  filter?: (item: RegisterItem) => boolean
  register: RegisterItem[]
  isFetching: boolean
  refetch: () => void
  updateCheckIn: (value: UpdateRegisterPayload) => MutationReturn
  addPerson: (personId: number) => MutationReturn
  removePerson: (personId: number) => MutationReturn
};

const RegisterContent: FC<RegisterContentProps> = (props) => {
  const {
    editionId,
    autoSave,
    filter,
    register,
    isFetching,
    refetch,
    updateCheckIn,
    addPerson,
    removePerson,
  } = props;

  const [alertMode, setAlertMode] = useState<'success' | 'error' | 'warning'>();
  const [apiError, setApiError] = useState<FetchBaseQueryError | SerializedError>();

  const sortedRegister = register?.slice()
    .filter(filter ?? (() => true))
    .sort((a, b) => getFullName(a).localeCompare(getFullName(b)))
    .sort((a, b) => b.status.localeCompare(a.status)) ?? [];

  const alreadyCheckedIn = register?.slice()
    .filter((person) => person.checkedInAt !== undefined)
    .map((person) => person.id) ?? [];

  const {
    values,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    submitForm,
  } = useFormik({
    initialValues: {
      people: alreadyCheckedIn,
    },
    onSubmit: async (newValues) => {
      if (!register) {
        return;
      }
      setAlertMode(undefined);
      setApiError(undefined);
      closeSnackbar();
      const added = newValues.people.filter((id) => !alreadyCheckedIn.includes(id));
      const removed = alreadyCheckedIn.filter((id) => !newValues.people.includes(id));
      await updateCheckIn({
        checkedIn: added,
        revertCheckIn: removed,
      }).then((response) => {
        const addedNames = register.slice()
          .filter((person) => added.includes(person.id))
          .map((person) => `${getFullName(person)} (${person.id})`)
          .join(', ');
        const removedNames = register.slice()
          .filter((person) => removed.includes(person.id))
          .map((person) => `${getFullName(person)} (${person.id})`)
          .join(', ');

        let actionMessage = 'error' in response ? 'Failure: ' : 'Success: ';
        if (addedNames) {
          actionMessage += `adding ${addedNames}`;
        }
        if (addedNames && removedNames) {
          actionMessage += ' and ';
        }
        if (removedNames) {
          actionMessage += `removing ${removedNames}`;
        }

        if ('error' in response) {
          setApiError(response.error);
          setAlertMode('error');
        } else if (!autoSave) {
          setAlertMode('success');
          refetch();
        } else if (removedNames) {
          addNotification(actionMessage, 'warning');
          refetch();
        } else {
          addNotification(actionMessage, 'success');
          refetch();
        }
      });
    },
  });

  const dirty = values.people.length !== alreadyCheckedIn.length
    || values.people.some((id) => !alreadyCheckedIn.includes(id))
    || alreadyCheckedIn.some((id) => !values.people.includes(id));

  if (dirty && alertMode === undefined) {
    setAlertMode('warning');
  } else if (!dirty && alertMode === 'warning') {
    setAlertMode(undefined);
  }

  const disabled = isFetching || isSubmitting;

  const togglePerson = async (personId: number): Promise<void> => {
    if (disabled) {
      return;
    }
    if (alertMode !== 'warning') {
      setAlertMode('warning');
    }
    if (values.people.includes(personId)) {
      await setFieldValue('people', values.people.filter((id) => id !== personId));
    } else {
      await setFieldValue('people', [...values.people, personId]);
    }
    if (autoSave) {
      await submitForm();
    }
  };

  const participantIds = register?.slice().filter((person) => ['Cub', 'OtherChild'].includes(person.status))
    .map((person) => person.id) ?? [];
  const leaderIds = register?.slice().filter((person) => ['Leader', 'YoungLeader'].includes(person.status))
    .map((person) => person.id) ?? [];

  const countOfParticipants = values.people.slice()
    .filter((id) => participantIds.includes(id)).length;
  const countOfLeaders = values.people.slice()
    .filter((id) => leaderIds.includes(id)).length;

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <Styled.StickyWrapper>
          <Collapse in={!autoSave && alertMode !== undefined && !isSubmitting}>
            <Styled.AlertWrapper>
              <Alert
                variant="filled"
                severity={alertMode}
                action={!alertMode || alertMode === 'success' ? (
                  <IconButton onClick={() => setAlertMode(undefined)}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '10px' }}
                  >
                    {alertMode === 'error' ? 'Retry save' : 'Save changes'}
                  </Button>
                )}
              >
                <AlertTitle>
                  <strong>
                    {alertMode === 'success' && 'Save successful'}
                    {alertMode === 'warning' && 'You have unsaved changes'}
                    {alertMode === 'error' && 'An error occurred when trying to save register'}
                  </strong>
                </AlertTitle>
                {alertMode === 'success' && 'Latest check in status for register has been saved'}
                {alertMode === 'warning' && 'Please click the button to save the changes and then wait for confirmation.'}
                {alertMode === 'error' && apiError && getMessageForError(apiError)}
              </Alert>
            </Styled.AlertWrapper>
          </Collapse>
        </Styled.StickyWrapper>
        {participantIds.length > 0 && (
        <p>
          <strong>Participants: </strong>
          {countOfParticipants}
          {' of '}
          {participantIds.length}
        </p>
        )}
        {leaderIds.length > 0 && (
        <p>
          <strong>Leaders: </strong>
          {countOfLeaders}
          {' of '}
          {leaderIds.length}
        </p>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <Styled.CheckBoxTableCell>
                <Checkbox
                  checked={values.people.length === register.length}
                  indeterminate={values.people.length > 0
                        && values.people.length < register.length}
                  disabled={disabled}
                  onChange={async (event) => {
                    if (disabled) {
                      return;
                    }
                    if (alertMode !== 'warning') {
                      setAlertMode('warning');
                    }
                    if (event.target.checked) {
                      await setFieldValue('people', register.map((person) => person.id));
                    } else {
                      await setFieldValue('people', []);
                    }
                    if (autoSave) {
                      await submitForm();
                    }
                  }}
                />
              </Styled.CheckBoxTableCell>
              <TableCell>Name</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRegister.map((person) => (
              <TableRow
                key={`activity-person-row-${person.id}`}
              >
                <Styled.CheckBoxTableCell>
                  <Checkbox
                    id={`activity-person-name-${person.id}`}
                    checked={values.people.includes(person.id)}
                    onChange={() => togglePerson(person.id)}
                    disabled={disabled}
                  />
                </Styled.CheckBoxTableCell>
                <TableCell>
                  <Stack
                    justifyContent="space-between"
                    direction="row"
                    alignItems="center"
                  >
                    <div>
                      <Styled.NameWrapper>
                        <strong>
                          {`${getFullName(person)} `}
                        </strong>
                        (
                        <RouterLink to={routes.people.detail(person.id)}>
                          {`ID: ${person.id}`}
                        </RouterLink>
                        )
                      </Styled.NameWrapper>
                      {person.isStaff && (
                        <div>
                          {person.isStaff && 'Staff'}
                        </div>
                      )}
                      {person.subcamp && (
                        <div>
                          {`${person.subcamp} Subcamp`}
                        </div>
                      )}
                      {person.reference && person.packId && person.packName && (
                        <div>
                          {`${person.packName} (`}
                          <RouterLink to={routes.packs.detail(person.packId)}>
                            {person.reference}
                          </RouterLink>
                          )
                        </div>
                      )}
                      {person.leaderRole && (
                        <div>
                          {'Role: '}
                          <strong>
                            {getActivityLeaderRoleLabel(person.leaderRole)}
                          </strong>
                        </div>
                      )}
                    </div>
                    <div>
                      <PersonStatusChip status={person.status} size="small" />
                    </div>
                  </Stack>
                </TableCell>
                <TableCell align="right">
                  {person.checkedInAt ? getShortTime(person.checkedInAt) : (
                    <DeletePersonButton
                      personId={person.id}
                      removePerson={removePerson}
                      onSuccess={refetch}
                      disabled={disabled || dirty}
                      disabledMessage="Save changes before removing people"
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Backdrop open={disabled} invisible>
          <CircularProgress />
        </Backdrop>
      </form>
      <AddPersonSearch
        editionId={editionId}
        addPerson={addPerson}
        onSuccess={refetch}
        disabled={disabled || dirty}
      />
    </>
  );
};

type RegisterProps = {
  editionId?: number
  fetch: ReduxFetch<RegisterItem[]>
  filter?: (item: RegisterItem) => boolean
  updateCheckIn: (value: UpdateRegisterPayload) => MutationReturn
  addPerson: (personId: number) => MutationReturn
  removePerson: (personId: number) => MutationReturn
};

const Register: FC<RegisterProps> = (props) => {
  const {
    editionId,
    fetch,
    filter,
    updateCheckIn,
    addPerson,
    removePerson,
  } = props;

  const [autoSave, setAutoSave] = useState(true);
  const {
    data: register,
    error: fetchError,
    isLoading,
    isFetching,
    refetch,
  } = fetch;

  return (
    <>
      <FormGrid columns={2}>
        <FormGroup>
          <FormControlLabel
            control={(
              <Switch
                checked={autoSave}
                onChange={(event, checked) => {
                  closeSnackbar();
                  setAutoSave(checked);
                }}
              />
            )}
            label="Save changes automatically"
          />
        </FormGroup>
      </FormGrid>
      {fetchError && (
        <Alert severity="error">
          <AlertTitle>
            {getMessageForError(fetchError) ?? 'An unexpected error occurred when trying to fetch register'}
          </AlertTitle>
        </Alert>
      )}
      {isLoading && (
        <LoadingText lines={10} />
      )}
      {!isLoading && register && (
        <RegisterContent
          editionId={editionId}
          autoSave={autoSave}
          filter={filter}
          register={register}
          isFetching={isFetching}
          refetch={refetch}
          updateCheckIn={updateCheckIn}
          addPerson={addPerson}
          removePerson={removePerson}
        />
      )}
    </>
  );
};

export default Register;

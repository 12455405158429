// eslint-disable-next-line max-classes-per-file
import {
  KeyboardSensor as LibKeyboardSensor,
  MouseSensor as LibMouseSensor,
  TouchSensor as LibTouchSensor,
} from '@dnd-kit/core';
import type { KeyboardEvent, MouseEvent, TouchEvent } from 'react';

const shouldHandleEvent = (element: HTMLElement | null): boolean => {
  let current = element;

  while (current) {
    if (current.dataset && current.dataset.noDnd) {
      return false;
    }
    current = current.parentElement;
  }

  return true;
};

export class KeyboardSensor extends LibKeyboardSensor {
  static activators = [
    {
      eventName: 'onKeyDown' as const,
      handler: (
        { nativeEvent: event }: KeyboardEvent,
      ) => shouldHandleEvent(event.target as HTMLElement),
    },
  ];
}

export class MouseSensor extends LibMouseSensor {
  static activators = [
    {
      eventName: 'onMouseDown' as const,
      handler: (
        { nativeEvent: event }: MouseEvent,
      ) => shouldHandleEvent(event.target as HTMLElement),
    },
  ];
}

export class TouchSensor extends LibTouchSensor {
  static activators = [
    {
      eventName: 'onTouchStart' as const,
      handler: (
        { nativeEvent: event }: TouchEvent,
      ) => shouldHandleEvent(event.target as HTMLElement),
    },
  ];
}

import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useEffect } from 'react';
import useError from '../state/error/useError';
import { isApiHttpError } from './errorTypes';

export const getMessageForError = (
  error: SerializedError | FetchBaseQueryError | Error,
): string => {
  if ('error' in error) {
    return error.status;
  }
  if (isApiHttpError(error) && error.data.message) {
    return error.data.message;
  }
  return JSON.stringify(error);
};

const useErrorHandler = (
  error: SerializedError | FetchBaseQueryError | Error | undefined,
  message?: string,
): void => {
  const [, throwError] = useError();
  useEffect(() => {
    if (error) {
      throwError({ message: message || getMessageForError(error) });
    }
  }, [error, message, throwError]);
};

export default useErrorHandler;

import { Drawer as MuiDrawer } from '@mui/material';
import styled from 'styled-components';
import { Pixels } from '../../../helpers/cssTypes';

const desktopMenuWidth = '280px';

export const Drawer = styled(MuiDrawer)`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: ${desktopMenuWidth};
  }
  flex-shrink: 0;

  & .MuiDrawer-paper {
    width: 100%;
    ${({ theme }) => theme.breakpoints.up('sm')} {
      width: ${desktopMenuWidth};
    }
    box-sizing: border-box;
    overflow: unset !important;
  }
`;

type SpacerStyleProps = {
  height: Pixels
};

export const Spacer = styled.div<SpacerStyleProps>`
  height: ${(props) => props.height};
`;

export const ListWrapper = styled.div`
  overflow-y: auto;
`;

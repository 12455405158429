import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  Backdrop,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useState } from 'react';
import ErrorAlert from '../../../../../components/DataPanel/ErrorAlert';
import { FormGrid, FormGridWithTopPadding } from '../../../../../components/FormGrid';
import { Mutation } from '../../../../../helpers/actionType';
import { useTypeSafeFormik } from '../../../../../helpers/form';
import { CreateContact } from '../../../../../models/contact';
import ContactPreview from '../ContactPreview';
import PersonFormDialog from '../PersonFormDialog';
import sanitiseOnSubmit from './sanitise';
import { validationSchema } from './schema';

type ContactFormDialogProps = {
  title: string
  type: 'create' | 'update'
  contact: CreateContact
  onOpen?: () => void
  mutation: Mutation<CreateContact>
  onSuccess?: () => void
  packId?: number | null
};

// TODO: Replace person id with a person search field
// TODO: Allow the creation of a new person record at the same time as making a contact

const ContactFormDialog = (props: ContactFormDialogProps): JSX.Element => {
  const {
    title,
    type,
    contact,
    onOpen: afterOpen,
    mutation,
    onSuccess,
    packId = null,
  } = props;

  const [open, setOpen] = useState(false);
  const [error, setError] = useState<SerializedError | FetchBaseQueryError>();

  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    resetForm,
    dirty,
  } = useTypeSafeFormik<CreateContact>({
    initialValues: contact,
    validationSchema,
    onSubmit: async (formValues) => {
      const sanitisedValues = sanitiseOnSubmit(formValues);
      await mutation(sanitisedValues).then(async (result) => {
        if ('error' in result) {
          setError(result.error);
        } else {
          onSuccess?.();
          setOpen(false);
          resetForm();
        }
      });
    },
  });

  const onOpen = (): void => {
    setError(undefined);
    setOpen(true);
    afterOpen?.();
  };

  const onClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        aria-label={type === 'create' ? 'Create Contact' : 'Update Contact'}
        onClick={onOpen}
      >
        {type === 'create' ? <AddIcon /> : <EditIcon />}
      </IconButton>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <form onSubmit={handleSubmit} noValidate>
          <DialogTitle>
            {title}
          </DialogTitle>
          <DialogContent sx={{ paddingTop: '5px' }}>
            <Collapse in={error !== undefined}>
              {error && (
                <ErrorAlert modelName="contact" type="create" error={error} />
              )}
            </Collapse>
            <FormGridWithTopPadding columns={2}>
              {type === 'create' && (
                <>
                  <PersonFormDialog
                    packId={packId}
                    onSuccess={(personId) => setFieldValue('personId', personId)}
                  />
                  <div />
                </>
              )}
              <TextField
                label="Person ID"
                type="number"
                value={values.personId ?? ''}
                onChange={(event) => setFieldValue('personId', Number(event.target.value))}
                onBlur={() => setFieldTouched('personId')}
                helperText={(touched.personId && errors.personId) || ' '}
                error={touched.personId && Boolean(errors.personId)}
                disabled={isSubmitting}
              />
              <ContactPreview personId={values.personId} />
            </FormGridWithTopPadding>
            <FormGrid>
              <TextField
                label="Relationship"
                value={values.relationship}
                onChange={(event) => setFieldValue('relationship', event.target.value)}
                onBlur={() => setFieldTouched('relationship')}
                helperText={(touched.relationship && errors.relationship) || ' '}
                error={touched.relationship && Boolean(errors.relationship)}
                disabled={isSubmitting}
              />
            </FormGrid>
            <FormGrid>
              <FormControl
                error={touched.emergency && Boolean(errors.emergency)}
                disabled={isSubmitting}
              >
                <FormLabel id="emergency-label">
                  Are they an emergency contact?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="emergency-label"
                  value={values.emergency}
                  onChange={async (event) => {
                    await setFieldValue('emergency', event.target.value === 'true');
                  }}
                  onBlur={() => setFieldTouched('emergency')}
                >
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
                <FormHelperText>
                  {(touched.emergency && errors.emergency) || ' '}
                </FormHelperText>
              </FormControl>
            </FormGrid>
            <FormGrid>
              <FormControl
                error={touched.updates && Boolean(errors.updates)}
                disabled={isSubmitting}
              >
                <FormLabel id="general-updates-label">
                  Include them in general updates?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="general-updates-label"
                  value={values.updates}
                  onChange={async (event) => {
                    await setFieldValue('updates', event.target.value === 'true');
                  }}
                  onBlur={() => setFieldTouched('updates')}
                >
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
                <FormHelperText>
                  {(touched.updates && errors.updates) || ' '}
                </FormHelperText>
              </FormControl>
              <FormControl
                error={touched.possiblyAttending && Boolean(errors.possiblyAttending)}
                disabled={isSubmitting}
              >
                <FormLabel id="possibly-attending-label">
                  Are they possibly attending?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="possibly-attending-label"
                  value={values.possiblyAttending}
                  onChange={async (event) => {
                    await setFieldValue('possiblyAttending', event.target.value === 'true');
                  }}
                  onBlur={() => setFieldTouched('possiblyAttending')}
                >
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
                <FormHelperText>
                  {(touched.possiblyAttending && errors.possiblyAttending) || ' '}
                </FormHelperText>
              </FormControl>
            </FormGrid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting || !dirty}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
          </DialogActions>
          <Backdrop open={isSubmitting}>
            <CircularProgress />
          </Backdrop>
        </form>
      </Dialog>
    </>
  );
};

export default ContactFormDialog;
